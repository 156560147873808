import { css, Theme } from '@emotion/react'
import { CellContext, ColumnDef, HeaderContext } from '@tanstack/react-table'
import { IAccount } from 'api/account.types'
import { sum } from 'lodash'
import { USD } from 'shared/components/Formatting'
import { CustodianType } from '../../features/AccountSelector/types'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { taxStatusLookup } from '../../shared/taxStatus'
import { TodaysChange } from '../../shared/TodaysChange'
import { EnhancedBalanceDetailResponseValue } from '../../store/rdot360Context/useRdot360BalancesContext'
import { AccountNumberCell } from '../shared/DetailTables/AccountNumberCell'

export type BalanceItem = {
  custodianType: CustodianType
  account?: IAccount
  cag?: string
} & EnhancedBalanceDetailResponseValue

const AmountCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return value ? (
    <USD value={value} fractionDigits={2} currencySign={'standard'} />
  ) : (
    <>--</>
  )
}

const SumFooter: React.FC<HeaderContext<BalanceItem, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USD value={total} fractionDigits={2} currencySign={'standard'} />
}

export const getBalanceSummaryDetailsClasses = (theme: Theme) => ({
  container: css({
    padding: theme.size.sm,
    fontSize: theme.size.md
  }),
  tootlTipContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.size.xs,
    ':nth-child(odd)': {
      background: theme.colors.tertiaryGray5
    }
  }),
  extraGreen1: css({
    color: theme.colors.extraGreen1
  })
})

const AccountNumberColumnCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  getValue
}) => {
  const value = getValue<string>()
  const { searchText } = useBalanceSummaryDetailsUiState()

  return <AccountNumberCell accountNumber={value} search={searchText} />
}

export type BalancesTableColumnName =
  keyof typeof balancesDetailTableColumnNames

export const balancesDetailTableGroupingColumnNames = {
  custodianType: 'Institution / Custodian',
  taxable: 'Taxable / Tax-deferred',
  legalEntityId: 'Entity',
  accountRegistration: 'Account Type',
  ausClass: 'Managed / Brokerage',
  cag: 'Custom Account Groups'
}
export const balancesDetailTableColumnNames = {
  accountNumber: 'Account',
  cashmoneyaccounts: 'Cash',
  pricedinvestments: 'Priced Investments',
  annuity: 'Annuities and Insurance',
  other: 'Other',
  accruedIncome: 'Accrued Income',
  loans: 'Loans',
  netBalance: 'Total',
  todaysChange: `Day's Change`,
  availableToWithdraw: 'Available to Withdraw',
  availableToInvest: 'Available to Invest',
  ...balancesDetailTableGroupingColumnNames
}

export const getBalancesDetailTableColumns = (): ColumnDef<BalanceItem>[] => [
  {
    header: balancesDetailTableColumnNames.taxable,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.taxstatus &&
      (taxStatusLookup[account.taxstatus] || account.taxstatus)
  },
  {
    header: balancesDetailTableColumnNames.legalEntityId,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.LegalEntityName
  },
  {
    header: balancesDetailTableColumnNames.accountRegistration,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.registrationDesc || account?.registrationtype
  },
  {
    header: balancesDetailTableColumnNames.ausClass,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.accounttype
  },
  {
    id: balancesDetailTableColumnNames.custodianType,
    aggregationFn: 'unique',
    accessorFn: ({ custodianType }) => custodianType
  },
  {
    id: balancesDetailTableColumnNames.cag,
    aggregationFn: 'unique',
    accessorFn: ({ cag }) => cag
  },
  {
    id: balancesDetailTableColumnNames.accountNumber,
    header: balancesDetailTableColumnNames.accountNumber,
    accessorFn: ({ accountaumber }) => accountaumber,
    cell: AccountNumberColumnCell,
    footer: 'Total',
    minSize: 240,
    enableGlobalFilter: true
  },
  {
    id: balancesDetailTableColumnNames.cashmoneyaccounts,
    header: balancesDetailTableColumnNames.cashmoneyaccounts,
    accessorFn: ({ cash }) => cash,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.pricedinvestments,
    header: balancesDetailTableColumnNames.pricedinvestments,
    accessorFn: ({ pricedinvestments }) => pricedinvestments,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 140
  },
  {
    id: balancesDetailTableColumnNames.annuity,
    header: balancesDetailTableColumnNames.annuity,
    accessorFn: ({ annuity }) => annuity,
    aggregationFn: 'sum',
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 160
  },
  {
    id: balancesDetailTableColumnNames.other,
    header: balancesDetailTableColumnNames.other,
    accessorFn: () => 0,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.accruedIncome,
    header: balancesDetailTableColumnNames.accruedIncome,
    accessorFn: ({ fixedincomeaccruedinterest }) => fixedincomeaccruedinterest,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.loans,
    header: balancesDetailTableColumnNames.loans,
    accessorFn: ({ totalLiabilities }) => totalLiabilities,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.netBalance,
    header: balancesDetailTableColumnNames.netBalance,
    accessorFn: ({ netWorth }) => netWorth,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.todaysChange,
    header: balancesDetailTableColumnNames.todaysChange,
    accessorFn: ({ netWorthChange }) => netWorthChange,
    enableGlobalFilter: false,
    cell: ({ getValue }) => {
      const value = getValue<number>()
      return (
        <>
          {value ? (
            <div
              css={{
                color: value > 0 ? '#008000' : '',
                display: 'inline-flex'
              }}
            >
              <TodaysChange />
              <USD value={value} fractionDigits={2} currencySign={'standard'} />
            </div>
          ) : (
            '--'
          )}
        </>
      )
    },
    footer: SumFooter,
    minSize: 120
  },
  {
    id: balancesDetailTableColumnNames.availableToWithdraw,
    header: balancesDetailTableColumnNames.availableToWithdraw,
    accessorFn: ({ availableToWithdraw }) => availableToWithdraw,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 160
  },
  {
    id: balancesDetailTableColumnNames.availableToInvest,
    header: balancesDetailTableColumnNames.availableToInvest,
    accessorFn: ({ availableToInvest }) => availableToInvest,
    enableGlobalFilter: false,
    cell: AmountCell,
    footer: SumFooter,
    minSize: 150
  }
]
