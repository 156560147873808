import { MessageBarType } from '@fluentui/react'
import { trim, uniq } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { INfsProfileJson } from '../../../../../api/datahub'
import {
  AccountLinkingRequestStatusEnum,
  IAccountLinkingRequest
} from '../../../../../api/dynamics'
import { IGraphApiSendMailRequest, sendMail } from '../../../../../api/graph'
import {
  accountLinkingRequestListDataActions,
  accountLinkingRequestListDataSelectors
} from '../../../../../modules/Advisory/modules/Clients/modules/AccountLinkingRequests/features/AccountLinkingRequestList/store'
import { isNotNullOrEmpty } from '../../../../../shared/gaurds'
import { AppState } from '../../../../../store'
import { getMicrosoftGraphApiOptions } from '../../../../../store/shared/sagas/auth'
import { getEnvironmentName } from '../../../../../store/system'
import { getRdotUsername } from '../../../../../store/user/selectors'
import { pushNotification } from '../../../../Notifications'
import { IOdataListChunkPayload } from '../../../../OdataList/common/IOdataListDataActions'
import { accountLinkingPostActions } from '../../../store/accountLinkingPost'
import {
  accountLinkingValidationActions,
  getAccountLinkingValidations,
  getAccountLinkingValidationSelectedClients
} from '../../../store/accountLinkingValidation'
import { getAccountLinkingClientHouseholdFetchLoading } from '../../../store/clientHouseholdFetch'
import {
  accountLinkingNfsProfileFetchActions,
  getIsAccountLinkingNfsProfileLoading
} from '../../../store/nfsProfileFetch'
import {
  getAccountLinkingValidationAccountsFetchLoading,
  getAccountLinkingValidationAccountsFetchResult
} from '../../../store/validationAccountsFetch'
import { generateAccountLinkingRejectEmail } from '../AccountLinkingRejectEmail'
import {
  accountLinkingRequestFetchActions,
  getAccountLinkingRequestFetchResult,
  getIsAccountLinkingRequestFetchLoading
} from './accountLinkingRequestFetch'
import { requestOwnerFetchActions } from './requestOwnerFetch'

const OPEN =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/OPEN'
const CLOSE =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/CLOSE'
const APPROVE =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/APPROVE'
const REJECT =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/REJECT'
const RESUBMIT =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/RESUBMIT'
const ERROR =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/ERROR'
const UPDATE_STATUS =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/UPDATE_STATUS'
const UPDATE_STATUS_SUCCESS =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/UPDATE_STATUS_SUCCESS'
const SET_PORTAL_ID =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/SET_PORTAL_ID'
const ADD_NOTE =
  '@features/@accountLinking/@features/@accountLinkingRequestView/@accountLinkingRequestViewPanel/ADD_NOTE'
export const accountLinkingRequestViewPanelActions = {
  open: createAction(OPEN)<string>(),
  close: createAction(CLOSE)(),
  approve: createAction(APPROVE)<string>(),
  reject: createAction(REJECT)<string>(),
  resubmit: createAction(RESUBMIT)<string>(),
  error: createAction(ERROR)<Error>(),
  updateStatus: createAction(UPDATE_STATUS)<string | undefined>(),
  updateStatusSuccess: createAction(
    UPDATE_STATUS_SUCCESS
  )<IAccountLinkingRequest>(),
  setPortalId: createAction(SET_PORTAL_ID)<ISetPortalIdRequest>(),
  addNote: createAction(ADD_NOTE)<IAddNoteRequest>()
}
export interface IAddNoteRequest {
  requestId?: string
  note?: string
}
export interface ISetPortalIdRequest {
  requestId?: string
  portalId?: string
}
export interface IAccountLinkingRequestViewPanelState {
  isPanelOpen: boolean
  status?: string
  error?: Error
}

const initialState: IAccountLinkingRequestViewPanelState = {
  isPanelOpen: false
}

export const accountLinkingRequestViewPanelReducer = createReducer<
  IAccountLinkingRequestViewPanelState,
  ActionType<typeof accountLinkingRequestViewPanelActions>
>(initialState)
  .handleAction(accountLinkingRequestViewPanelActions.open, () => ({
    ...initialState,
    isPanelOpen: true
  }))
  .handleAction(accountLinkingRequestViewPanelActions.close, () => ({
    ...initialState
  }))
  .handleAction(accountLinkingRequestViewPanelActions.approve, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(accountLinkingRequestViewPanelActions.reject, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(accountLinkingRequestViewPanelActions.resubmit, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(
    accountLinkingRequestViewPanelActions.updateStatusSuccess,
    (state) => ({
      ...state,
      error: undefined
    })
  )
  .handleAction(
    accountLinkingRequestViewPanelActions.updateStatus,
    (state, action) => ({
      ...state,
      status: action.payload
    })
  )
  .handleAction(accountLinkingRequestViewPanelActions.setPortalId, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(
    accountLinkingRequestViewPanelActions.error,
    (state, action) => ({
      ...state,
      status: undefined,
      error: action.payload
    })
  )
  .handleAction(accountLinkingRequestViewPanelActions.addNote, (state) => ({
    ...state,
    error: undefined
  }))

const rootSelector = (state: AppState) =>
  state.features.accountLinking.features.accountLinkingRequestView
    .accountLinkingRequestViewPanel

export const getIsAccountLinkingRequestViewPanelOpen = flow(
  rootSelector,
  ({ isPanelOpen }) => isPanelOpen
)

export const getAccountLinkingRequestViewPanelError = flow(
  rootSelector,
  ({ error }) => error
)

export const getIsAccountLinkingRequestPanelProcessing = flow(
  rootSelector,
  ({ status }) => !!status
)

export const getAccountLinkingRequestViewPanelStatus = flow(
  rootSelector,
  ({ status }) => status
)

export const getAccountLinkingRequestViewPanelNfsProfile = createSelector(
  [getAccountLinkingValidationSelectedClients],
  (clients) => clients?.[0]
)

export const getAccountLinkingRequestViewPanelNfsProfileAccounts =
  createSelector([getAccountLinkingRequestViewPanelNfsProfile], (profile) => {
    const { profilejson } = profile || {}
    if (!profilejson) {
      return []
    }

    try {
      return uniq(
        (JSON.parse(profilejson) as INfsProfileJson).profile?.accounts
          ?.map(({ number }) => number)
          .filter(isNotNullOrEmpty)
      )
    } catch (e) {
      return []
    }
  })

export const getAccountLinkingRequestViewPanelRequest =
  getAccountLinkingRequestFetchResult
export const getAccountLinkingPanelValidations = getAccountLinkingValidations
export const getAccountLinkingRequestViewPanelAccounts =
  getAccountLinkingValidationAccountsFetchResult
export const getIsAccountLinkingRequestViewPanelLoading = createSelector(
  [
    getIsAccountLinkingRequestFetchLoading,
    getIsAccountLinkingNfsProfileLoading,
    getAccountLinkingValidationAccountsFetchLoading,
    getAccountLinkingClientHouseholdFetchLoading
  ],
  (request, profile, accounts, household) =>
    [request, profile, accounts, household].some((x) => x)
)

export const accountLinkingRequestViewPanelSagas = [
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.open,
      function* (
        action: ReturnType<typeof accountLinkingRequestViewPanelActions.open>
      ) {
        yield put(accountLinkingRequestFetchActions.request(action.payload))
        const { success, failure } = yield* race({
          success: take(accountLinkingRequestFetchActions.success),
          failure: take(accountLinkingRequestFetchActions.failure)
        })

        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred.')
            )
          )
          return
        }

        const {
          rcm_accountstoadd,
          rcm_newclient,
          rcm_newclientrole,
          rcm_newclientemailid,
          rcm_client,
          rcm_clientmethod,
          rcm_cliendid,
          rcm_verificationdate,
          rcm_verificationtime,
          rcm_requesttype,
          rcm_mfaphone
        } = success.payload

        yield put(
          accountLinkingValidationActions.setRequestType(
            rcm_requesttype || 'existing'
          )
        )

        const accounts = rcm_accountstoadd?.split(',').map(trim) || []
        yield* put(accountLinkingValidationActions.setInputAccounts(accounts))
        yield* put(
          accountLinkingValidationActions.setValidatedAccounts(accounts)
        )

        yield* put(
          accountLinkingValidationActions.setVerification({
            client: rcm_client,
            method: rcm_clientmethod,
            date: rcm_verificationdate
              ? new Date(rcm_verificationdate)
              : undefined,
            time: rcm_verificationtime
          })
        )

        if (rcm_cliendid && rcm_requesttype === 'existing') {
          yield* put(accountLinkingNfsProfileFetchActions.request(rcm_cliendid))

          const { success, failure } = yield* race({
            success: take(accountLinkingNfsProfileFetchActions.success),
            failure: take(accountLinkingNfsProfileFetchActions.failure)
          })

          if (failure || !success) {
            yield put(
              accountLinkingRequestViewPanelActions.error(
                failure?.payload || new Error('An unknown error occurred.')
              )
            )
            return
          }

          yield put(
            accountLinkingValidationActions.setSelectedClients([
              { ...success.payload, role: rcm_newclientrole }
            ])
          )
        } else {
          yield* put(
            accountLinkingValidationActions.setSelectedClients([
              {
                loginid: rcm_newclientemailid,
                emailprimary: rcm_newclientemailid,
                fullname: rcm_newclient,
                role: rcm_newclientrole,
                wsportaluserid: rcm_cliendid,
                mfaPhones: rcm_mfaphone
              }
            ])
          )
        }
      }
    ),
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.approve,
      function* (
        action: ReturnType<typeof accountLinkingRequestViewPanelActions.approve>
      ) {
        yield put(
          accountLinkingRequestViewPanelActions.updateStatus(
            'Submitting for Approval'
          )
        )
        yield put(
          accountLinkingPostActions.request([
            {
              rcm_accountlinkingrequestid: action.payload,
              rcm_status: AccountLinkingRequestStatusEnum.APPROVED
            }
          ])
        )

        const { success, failure } = yield* race({
          success: take(accountLinkingPostActions.success),
          failure: take(accountLinkingPostActions.failure)
        })

        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
        }

        yield put(accountLinkingRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully approved request.',
          type: MessageBarType.success
        })

        yield put(accountLinkingRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.reject,
      function* (
        action: ReturnType<typeof accountLinkingRequestViewPanelActions.reject>
      ) {
        yield put(
          accountLinkingRequestViewPanelActions.updateStatus(
            'Submitting for Rejection'
          )
        )
        yield put(
          accountLinkingPostActions.request([
            {
              rcm_accountlinkingrequestid: action.payload,
              rcm_status: AccountLinkingRequestStatusEnum.REJECTED
            }
          ])
        )

        const { success, failure } = yield* race({
          success: take(accountLinkingPostActions.success),
          failure: take(accountLinkingPostActions.failure)
        })

        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
          return
        }
        if (!success.payload._owninguser_value) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              new Error('Unable to determine request owner')
            )
          )
          return
        }
        yield put(
          requestOwnerFetchActions.request(success.payload._owninguser_value)
        )
        const { ownerFetchSuccess, ownerFetchFailure } = yield* race({
          ownerFetchSuccess: take(requestOwnerFetchActions.success),
          ownerFetchFailure: take(requestOwnerFetchActions.failure)
        })
        if (ownerFetchFailure || !ownerFetchSuccess) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              ownerFetchFailure?.payload ||
                new Error('An unknown error occurred')
            )
          )
          return
        }
        const environment = yield* select(getEnvironmentName)
        const currentUsername = yield* select(getRdotUsername)
        const isProd = environment === 'prod'
        const origin = window.origin

        if (!currentUsername) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              new Error('Unable to discover the logged in user')
            )
          )
          return
        }
        if (!ownerFetchSuccess.payload.domainname) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              new Error('Unable to determine owner of request')
            )
          )
          return
        }

        const subject = `Account Linking Request for ${success.payload.rcm_newclient}: ${success.payload['rcm_status@OData.Community.Display.V1.FormattedValue']}`

        const body = yield* call(generateAccountLinkingRejectEmail, {
          request: success.payload,
          origin
        })

        const emailRequest: IGraphApiSendMailRequest = {
          message: {
            toRecipients: isProd
              ? [
                  {
                    emailAddress: {
                      address: ownerFetchSuccess.payload.domainname
                    }
                  }
                ]
              : [{ emailAddress: { address: currentUsername } }],
            ccRecipients: [{ emailAddress: { address: currentUsername } }],
            body: {
              contentType: 'HTML',
              content: body
            },
            subject
          }
        }

        const graphApiOptions = yield* call(getMicrosoftGraphApiOptions)
        yield* call(sendMail, graphApiOptions, emailRequest)

        yield put(accountLinkingRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully rejected request.',
          type: MessageBarType.success
        })

        yield put(accountLinkingRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.resubmit,
      function* (
        action: ReturnType<
          typeof accountLinkingRequestViewPanelActions.resubmit
        >
      ) {
        yield put(
          accountLinkingRequestViewPanelActions.updateStatus('Resubmitting')
        )
        yield put(
          accountLinkingPostActions.request([
            {
              rcm_accountlinkingrequestid: action.payload,
              rcm_status: AccountLinkingRequestStatusEnum.REQUESTED
            }
          ])
        )

        const { success, failure } = yield* race({
          success: take(accountLinkingPostActions.success),
          failure: take(accountLinkingPostActions.failure)
        })

        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
        }

        yield put(accountLinkingRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully resubmitted.',
          type: MessageBarType.success
        })

        yield put(accountLinkingRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.setPortalId,
      function* (
        action: ReturnType<
          typeof accountLinkingRequestViewPanelActions.setPortalId
        >
      ) {
        yield put(
          accountLinkingRequestViewPanelActions.updateStatus(
            'Setting Portal Id for Request'
          )
        )
        yield put(
          accountLinkingPostActions.request([
            {
              rcm_accountlinkingrequestid: action.payload.requestId,
              rcm_cliendid: action.payload.portalId
            }
          ])
        )

        const { success, failure } = yield* race({
          success: take(accountLinkingPostActions.success),
          failure: take(accountLinkingPostActions.failure)
        })

        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
          return
        }

        yield put(accountLinkingRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully set portal id.',
          type: MessageBarType.success
        })

        yield put(accountLinkingRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeEvery(
      accountLinkingRequestViewPanelActions.updateStatusSuccess,
      function* (
        action: ReturnType<
          typeof accountLinkingRequestViewPanelActions.updateStatusSuccess
        >
      ) {
        const updatedItem = action.payload
        const chunks = yield* select(
          accountLinkingRequestListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        let updateChunkPayload:
          | IOdataListChunkPayload<IAccountLinkingRequest>
          | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) =>
              x.rcm_accountlinkingrequestid ===
              updatedItem?.rcm_accountlinkingrequestid
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          itemsCopy.splice(itemIndex, 1, updatedItem)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          accountLinkingRequestListDataActions.updateChunk(updateChunkPayload)
        )
      }
    ),
  () =>
    takeLatest(accountLinkingRequestViewPanelActions.close, function* () {
      yield put(accountLinkingValidationActions.reset())
    }),
  () =>
    takeLatest(
      accountLinkingRequestViewPanelActions.addNote,
      function* (
        action: ReturnType<typeof accountLinkingRequestViewPanelActions.addNote>
      ) {
        if (!action.payload.requestId) {
          return
        }
        yield put(
          accountLinkingRequestViewPanelActions.updateStatus('Adding Note')
        )
        yield put(
          accountLinkingPostActions.request([
            {
              rcm_accountlinkingrequestid: action.payload.requestId,
              rcm_notes: action.payload.note
            }
          ])
        )

        const { success, failure } = yield* race({
          success: take(accountLinkingPostActions.success),
          failure: take(accountLinkingPostActions.failure)
        })
        if (failure || !success) {
          yield put(
            accountLinkingRequestViewPanelActions.error(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
        }
        yield put(
          accountLinkingRequestFetchActions.request(action.payload.requestId)
        )

        yield put(accountLinkingRequestViewPanelActions.updateStatus(undefined))
      }
    )
]
