import Admin from './Admin'
import Advisory from './Advisory'
import Compliance from './Compliance'
import Fees from './Fees'
import GFO from './GFO'
import Home from './Home'
import Households from './Households'
import Lists from './Lists'
import Login from './Login'
import Performance from './Operations/modules/Performance'
import Payments from './Payments'
import Philanthropy from './Philanthropy'
import Products from './Products'
import Reports from './Reports'
import Security from './Security'
import Teams from './Teams'
import Transfers from './Transfers'
import Trust from './Trusts'

export * from './store'

export default [
  Advisory,
  Home,
  Login,
  Lists,
  Payments,
  Fees,
  Trust,
  Philanthropy,
  GFO,
  Households,
  Reports,
  Compliance,
  Products,
  Transfers,
  Admin,
  Teams,
  Security,
  Performance
]
