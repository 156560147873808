import { combineReducers } from 'redux'
import { createInteractionPanelReducer } from './createInteractionPanel'
import { emailPhonePanelReducer } from './emailPhonePanel'
import { interactionSettingsCalloutReducer } from './interactionSettingsCallout'
import { interactionsPanelReducer } from './interactionsPanel'

export const interactionsReducer = combineReducers({
  interactionsPanel: interactionsPanelReducer,
  createInteractionPanel: createInteractionPanelReducer,
  emailPhonePanel: emailPhonePanelReducer,
  interactionSettingsCallout: interactionSettingsCalloutReducer
})
