import { Icon } from '../../../features/Icons/Icon'

export const Searchbox: React.FC<{
  searchText?: string
  onChange?: (searchText?: string) => void
  title?: string
}> = ({ searchText, onChange, title }) => {
  return (
    <div
      css={{
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #AFB1B6',
        width: '250px',
        height: '48px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        ':focus-within': { outline: '2px solid black' }
      }}
      title={title}
    >
      <div title={title} aria-hidden="true" css={{ padding: '10px' }}>
        <Icon type="Search" height={14} width={14} title="title" />
      </div>
      <input
        autoComplete="off"
        id="SearchBox"
        type="search"
        css={{
          border: 0,
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          ':focus': {
            outline: 0
          },
          '&:focus::placeholder': {
            color: 'transparent'
          }
        }}
        placeholder="Find"
        value={searchText}
        onChange={(ev) => {
          onChange && onChange(ev.target.value)
        }}
      />
    </div>
  )
}
