import axios, { CancelTokenSource } from 'axios'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { call, cancelled } from 'typed-redux-saga'
import {
  IUpcomingCapitalCall,
  getUpcomingCapitalCalls
} from '../../../../../../../../../api/datahub'
import { IOdataRequest } from '../../../../../../../../../api/odata.types'
import { IListsFilter } from '../../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from '../../../../../../../../../features/OdataList/common/service'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../../../../../../../../../features/OdataList/common/types'
import { IApiOptions } from '../../../../../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../../../../../store'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export type UpcomingCapitalCallsColumnName =
  | '# Days Past Due'
  | 'Cash Balance'
  | 'Account Number'
  | 'Advisor'
  | 'Advisor ID'
  | 'Asset Class Detail (L3)'
  | 'Call Amount'
  | 'Trade Date'
  | 'Call Status'
  | 'Committed Capital'
  | 'CUSIP'
  | 'Description'
  | 'Household Name'
  | 'Legal Entity Name'
  | 'Margin (Y/N)'
  | 'NFS Due Date'
  | 'Notes'
  | 'Net Amount Due'
  | 'Past Due Reason'
  | 'Product Sub Type'
  | 'Settle Date'
  | 'Sponsor Due Date'
  | 'Trade Mainframe Status'
  | 'Trade Status Code'
  | 'Vintage Year'
  | 'Call Received Date'
  | 'Remaining Capital'

export interface IUpcomingCapitalCallsColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IUpcomingCapitalCall> {
  name: UpcomingCapitalCallsColumnName
}

const commonCollumnProps: Partial<IUpcomingCapitalCallsColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const upcomingCapitalCallColumns: IUpcomingCapitalCallsColumnDefinition[] =
  [
    {
      ...commonCollumnProps,
      name: 'Account Number',
      dataPath: 'accountNumber',
      type: 'string',
      width: 125,
      getValue: ({ accountNumber }) => accountNumber,
      searchFields: ['accountNumber']
    },
    {
      ...commonCollumnProps,
      name: 'Legal Entity Name',
      dataPath: 'legalEntityname',
      type: 'string',
      width: 250,
      searchFields: ['legalEntityname'],
      getValue: ({ legalEntityname }) => legalEntityname
    },
    {
      ...commonCollumnProps,
      name: 'Description',
      dataPath: 'Description',
      type: 'string',
      width: 300,
      getValue: ({ description }) => description,
      searchFields: ['Description']
    },
    {
      ...commonCollumnProps,
      name: 'CUSIP',
      dataPath: 'cusip',
      type: 'string',
      width: 100,
      getValue: ({ cusip }) => cusip,
      searchFields: ['cusip']
    },
    {
      ...commonCollumnProps,
      name: 'Committed Capital',
      dataPath: 'committedCapital',
      type: 'number',
      width: 120,
      getValue: ({ committedCapital }) => committedCapital
    },
    {
      ...commonCollumnProps,
      name: 'Remaining Capital',
      dataPath: 'unfundedCommitment',
      type: 'number',
      width: 120,
      getValue: ({ unfundedCommitment }) => unfundedCommitment
    },
    {
      ...commonCollumnProps,
      name: 'Call Amount',
      dataPath: 'capitalCallAmount',
      type: 'number',
      width: 120,
      getValue: ({ capitalCallAmount }) => capitalCallAmount
    },
    {
      ...commonCollumnProps,
      name: 'NFS Due Date',
      dataPath: 'nfsDueDate',
      type: 'date-only',
      width: 100,
      getValue: ({ nfsDueDate }) => nfsDueDate
    },
    {
      ...commonCollumnProps,
      name: 'Sponsor Due Date',
      dataPath: 'sponsorDueDate',
      type: 'date-only',
      width: 100,
      getValue: ({ sponsorDueDate }) => sponsorDueDate
    },
    {
      ...commonCollumnProps,
      name: 'Cash Balance',
      dataPath: 'accountCashBalance',
      type: 'number',
      width: 120,
      getValue: ({ accountCashBalance }) => accountCashBalance
    },
    {
      ...commonCollumnProps,
      name: 'Margin (Y/N)',
      dataPath: 'marginAgreementOnFile',
      type: 'string',
      width: 100,
      getValue: ({ marginAgreementOnFile }) => marginAgreementOnFile,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Call Status',
      dataPath: 'capitalCallStatus',
      type: 'string',
      width: 100,
      getValue: ({ capitalCallStatus }) => capitalCallStatus,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Past Due Reason',
      dataPath: 'pastDueReason',
      type: 'string',
      width: 200,
      getValue: ({ pastDueReason }) => pastDueReason,
      select: ['pastDueReason', 'capitalCallId'],
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Notes',
      dataPath: 'notes',
      type: 'string',
      width: 200,
      getValue: ({ notes }) => notes,
      select: ['notes', 'capitalCallId']
    },
    {
      ...commonCollumnProps,
      name: '# Days Past Due',
      dataPath: 'daysPastDue',
      type: 'number',
      width: 75,
      getValue: ({ daysPastDue }) => daysPastDue
    },
    {
      ...commonCollumnProps,
      name: 'Call Received Date',
      dataPath: 'originalTradeDate',
      type: 'date-only',
      width: 100,
      getValue: ({ originalTradeDate }) => originalTradeDate
    },
    {
      ...commonCollumnProps,
      name: 'Net Amount Due',
      dataPath: 'cashAmountDue',
      type: 'number',
      width: 120,
      getValue: ({ cashAmountDue }) => cashAmountDue
    },
    {
      ...commonCollumnProps,
      name: 'Advisor',
      dataPath: 'advisorName',
      type: 'string',
      width: 175,
      getValue: ({ advisorName }) => advisorName,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Advisor ID',
      dataPath: 'clientAdvisorId',
      type: 'string',
      width: 65,
      getValue: ({ clientAdvisorId }) => clientAdvisorId,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Asset Class Detail (L3)',
      dataPath: 'assetClassDetailL3',
      type: 'string',
      width: 150,
      getValue: ({ assetClassDetailL3 }) => assetClassDetailL3,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Household Name',
      dataPath: 'HouseholdName',
      type: 'string',
      width: 200,
      getValue: ({ householdName }) => householdName
    },
    {
      ...commonCollumnProps,
      name: 'Product Sub Type',
      dataPath: 'productSubType',
      type: 'string',
      width: 150,
      getValue: ({ productSubType }) => productSubType,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Trade Mainframe Status',
      dataPath: 'tradeMainframeStatus',
      type: 'string',
      width: 100,
      getValue: ({ tradeMainframeStatus }) => tradeMainframeStatus,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Trade Status Code',
      dataPath: 'tradeStatusCode',
      type: 'string',
      width: 100,
      getValue: ({ tradeStatusCode }) => tradeStatusCode,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Vintage Year',
      dataPath: 'vintageYear',
      type: 'string',
      width: 65,
      getValue: ({ vintageYear }) => vintageYear,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Trade Date',
      dataPath: 'capitalCallReceivedDate',
      type: 'date-only',
      width: 100,
      getValue: ({ capitalCallReceivedDate }) => capitalCallReceivedDate
    },
    {
      ...commonCollumnProps,
      name: 'Settle Date',
      dataPath: 'settleDate',
      type: 'date-only',
      width: 100,
      getValue: ({ settleDate }) => settleDate
    }
  ]

export const defaultColumnsIds: UpcomingCapitalCallsColumnName[] =
  upcomingCapitalCallColumns.map((x) => x.name)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchUpcomingCapitalCalls = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getUpcomingCapitalCalls, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.features
    .aiUpcomingCapitalCalls

const uiFilters = keyBy(
  upcomingCapitalCallColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = [...defaultColumnsIds].splice(0, 16)

const store = createOdataListWithFacetsStore({
  prefix:
    '@modules/@advisory/@modules/@ai/@modules/@dashboard/@features/@aiUpcomingCapitalCalls',
  getOdataResults: fetchUpcomingCapitalCalls,
  initialState: {
    data: {},
    ui: {
      columns: upcomingCapitalCallColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters,
      sortBy: {
        direction: 'desc',
        name: 'Call Received Date'
      }
    },
    facets: {}
  },
  rootSelector
})

export const { actions, selectors, reducer, sagas } = store
