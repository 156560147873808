import { keyBy, sumBy } from 'lodash'
import { ICategoryPostionResponseValue } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import { FC, useMemo } from 'react'
import { HighchartsComponent } from 'shared/highcharts'
import assetTaxonomy from './../../../../../../../../metadata/asset-taxonomy.json'
const getAssetsAllocationOptions = (
  data: ICategoryPostionResponseValue[] = [],
  totalValue = 0,
  colors: string[] = []
): Highcharts.Options => ({
  title: {
    text:
      totalValue === 0
        ? ''
        : totalValue > 0
        ? 'Total<br> $' + Math.round(totalValue).toLocaleString('en-US')
        : 'Total<br> -$' +
          Math.round(Math.abs(totalValue)).toLocaleString('en-US'),
    align: 'center',
    verticalAlign: 'middle',
    x: 0,
    y: 10
  },
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '<p>${point.y}</p>' + '<br>{point.percentage:.1f}%</br>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },

  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        }
      }
    },
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      point: {
        events: {
          legendItemClick: () => false
        }
      },
      showInLegend: false,
      borderWidth: 0,
      colors: colors,
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [
    {
      type: 'pie',
      innerSize: '80%',
      data: data
        .map((item) => ({
          name:
            item.assetcat === 'Cash' ? 'Cash and Equivalents' : item.assetcat,
          y: Math.round(item.mktval || 0),
          color: `#${
            keyBy(assetTaxonomy, (x) => x.description)[
              item.assetcat || 'Others'
            ]?.color ||
            keyBy(assetTaxonomy, (x) => x.description)['Others']?.color
          }`
        }))
        .sort((a, b) => (b.y || 0) - (a.y || 0))
    }
  ],
  credits: {
    enabled: false
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    enabled: true,
    symbolRadius: 0,
    layout: 'vertical',
    align: 'right',
    itemWidth: 150,
    verticalAlign: 'middle'
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        }
      }
    ]
  }
})
const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: 350, flex: '0.3' }
}
export const InvestmentsChart: FC<{
  data?: ICategoryPostionResponseValue[]
}> = ({ data = [] }) => {
  const totalValue = useMemo(
    () => sumBy(data, ({ mktval = 0 }) => mktval),
    [data]
  )
  const assetsAllocationOptions = useMemo(
    () => getAssetsAllocationOptions(data, totalValue),
    [data, totalValue]
  )
  return (
    <>
      <HighchartsComponent
        containerProps={containerProps}
        options={assetsAllocationOptions}
      />
    </>
  )
}
