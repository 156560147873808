import { combineReducers } from 'redux'
import Hurdles, { hurdlesModuleReducer, hurdlesModuleSagas } from './Hurdles'
import PeerReview, { peerReviewModuleReducer } from './PeerReview'

export const teamsModulesReducer = combineReducers({
  hurdles: hurdlesModuleReducer,
  peerReview: peerReviewModuleReducer
})

export const teamsModulesSagas = [...hurdlesModuleSagas]

export default [Hurdles, PeerReview]
