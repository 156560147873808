import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useGetHouseholdFromIdQuery } from '../datahub'
import { useRdot360Context } from './rdot360Context'

export const useRdot360HouseholdContext = () => {
  const { selectedHouseholdId } = useRdot360Context()

  const { data: household, isFetching: isHouseholdFetching } =
    useGetHouseholdFromIdQuery(
      selectedHouseholdId ? selectedHouseholdId : skipToken
    )

  return {
    household,
    isHouseholdFetching
  }
}
