import { createSlice } from '@reduxjs/toolkit'
import { RowSelectionState, Updater } from '@tanstack/react-table'
import { flow, isFunction } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRootState } from 'store/shared'
import { ActionType } from 'typesafe-actions'
import { IDropdownOption } from '../../../components/shared/Dropdown/Dropdown'

export interface IDocumentFilterState {
  selectedDateRange: string
  searchDateBegin: { [key: string]: string }
  searchDateEnd: { [key: string]: string }
  selectedDateRangeDropDownOption: { [key: string]: IDropdownOption }
  statementTypeToggleValue: boolean
  isDownloading: boolean
  selection: string[]
  showAllAccounts: boolean
}

const initialState: IDocumentFilterState = {
  selectedDateRange: '',
  searchDateBegin: {
    Statements: '',
    'Tax Documents': '',
    'Trade Confirmations': '',
    Correspondence: ''
  },
  searchDateEnd: {
    Statements: '',
    'Tax Documents': '',
    'Trade Confirmations': '',
    Correspondence: ''
  },
  selectedDateRangeDropDownOption: {
    Statements: { key: 'prior3Months', text: 'Prior 3 Months' },
    'Tax Documents': {
      key: new Date().getFullYear(),
      text: `${new Date().getFullYear()}`
    },
    Correspondence: { key: 'prior3Months', text: 'Prior 3 Months' },
    'Trade Confirmations': { key: 'prior3Months', text: 'Prior 3 Months' }
  },
  statementTypeToggleValue: false,
  selection: [],
  isDownloading: false,
  showAllAccounts: false
}

export const documentFilterSlice = createSlice({
  name: '@features/@rdot360/@DocumentFilter',
  initialState,
  reducers: {
    setSelectedDateRange: (state, action) => {
      state.selectedDateRange = action.payload
    },
    setSearchDateBegin: (state, action) => {
      state.searchDateBegin[action.payload.documentTabType] =
        action.payload.selectedDate
    },
    setSearchDateEnd: (state, action) => {
      state.searchDateEnd[action.payload.documentTabType] =
        action.payload.selectedDate
    },
    setDateRange: (state, action) => {
      state.searchDateBegin[action.payload.documentTabType] =
        action.payload.selectBeginDate
      state.searchDateEnd[action.payload.documentTabType] =
        action.payload.selectedEndDate
    },
    setSelectedDateRangeDropDownOption: (state, action) => {
      state.selectedDateRangeDropDownOption[action.payload.documentTabType] =
        action.payload.selectedOption
    },
    setStatementTypeToggleValue: (state, action) => {
      state.statementTypeToggleValue = action.payload
    },
    setIsDownloading: (state, action) => {
      state.isDownloading = action.payload
    },
    setSelection: (state, action) => {
      state.selection = action.payload
    },
    setShowAllAccounts: (state, action) => {
      state.showAllAccounts = action.payload.show
    }
  }
})

export const getFilterState = flow(getRootState, (x) => {
  return x.modules.advisory.modules.rdot360.documentFilter
})

export const getSelectedDateRange = flow(
  getFilterState,
  (x) => x.selectedDateRange
)

export const getSearchDateBegin = (documentTabType: string) => {
  return flow(getFilterState, (x) => x.searchDateBegin[documentTabType])
}

export const getSearchDateEnd = (documentTabType: string) => {
  return flow(getFilterState, (x) => x.searchDateEnd[documentTabType])
}

export const getDateRange = (documentTabType: string) => {
  return flow(getFilterState, (x) => ({
    searchDateBegin: x.searchDateBegin[documentTabType],
    searchDateEnd: x.searchDateEnd[documentTabType]
  }))
}

export const getSelectedDateRangeDropDownOption = (documentTabType: string) => {
  return flow(
    getFilterState,
    (x) => x.selectedDateRangeDropDownOption[documentTabType]
  )
}
export const getStatementTypeToggleValue = flow(
  getFilterState,
  (x) => x.statementTypeToggleValue
)

export const getShowAllAccounts = () => {
  return flow(getFilterState, (x) => x.showAllAccounts)
}

export const getIsDownloading = flow(getFilterState, (x) => x.isDownloading)
export const getDocumentSelection = flow(getFilterState, (x) => x.selection)

export const documentFilterActions = documentFilterSlice.actions
export const documentFilterReducer = documentFilterSlice.reducer

export type DocumentFilterActionTypes = ActionType<typeof documentFilterActions>

export const useDocumentModuleStore = () => {
  const dispatch = useDispatch()
  const selection = useSelector(getDocumentSelection)
  const rowSelection = useMemo(
    () =>
      selection.reduce(
        (a, x) => ({ ...a, [x]: true }),
        {} as RowSelectionState
      ),
    [selection]
  )

  const updateSelection = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const value = isFunction(updater) ? updater(rowSelection) : updater
      const selection = Object.entries(value)
        .filter(([, value]) => value)
        .map(([key]) => key)
      dispatch(documentFilterActions.setSelection(selection))
    },
    [dispatch, rowSelection]
  )

  const clearSelection = useCallback(() => {
    dispatch(documentFilterActions.setSelection([]))
  }, [dispatch])

  return {
    updateSelection,
    clearSelection,
    selection,
    rowSelection
  }
}
