import { FC, useCallback, useMemo } from 'react'
import { useRdot360Preferences } from '../../hooks'
// import { PositionCategoryType } from '../../store/holdingsApi/holdingsApi'
// import { ICategoryPostionResponseValue } from '../../store/holdingsApi/ICategoryPostitionResponse'
import { useGetPositionByCategoryQueryForSelectedAccounts } from '../../store/rdot360Context/useRdot360HoldingsContext'
import {
  IndeterminateProgressIndicator
  // IRadioButtonGroupOption,
  // RadioButtonGroup
} from '../shared'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps, ViewType } from '../TileHeading'
import { AssetSummaryTable } from './AssetAllocationSummaryTable'
import { AssetsAllocationChart } from './AssetsAllocationChart'

// const assetTypes: IRadioButtonGroupOption[] = [
//   { key: 'L1', text: 'Asset Class' },
//   { key: 'NFS', text: 'Product Type' }
// ]

const preferenceName = 'assetAllocationTile'

const AssetsAllocation: FC<BaseHeaderProps> = ({ onExpand }) => {
  const { getPreference, setPreference } = useRdot360Preferences<{
    [key: string]: ViewType
  }>(preferenceName, ViewType.pie)
  const viewType = getPreference(preferenceName)

  const handleViewTypeChange = useCallback(
    (value: ViewType) => {
      setPreference({ [preferenceName]: value })
    },
    [setPreference]
  )

  // const [type, setType] = useState<PositionCategoryType>('L1')
  const {
    data,
    isUninitialized: isAssetUninitialized,
    isFetching: isAssetFetching,
    isLoading: isAssetLoading,
    error: isAssetError
  } = useGetPositionByCategoryQueryForSelectedAccounts('L1')
  // const onAssetTypeChange = (event: any) => {
  //   const selectedType: string = event.target.value
  //   if (selectedType === 'L1') {
  //     setType('L1')
  //   } else {
  //     setType('NFS')
  //   }
  // }

  const isError = useMemo(() => !!isAssetError, [isAssetError])
  const errorMessage =
    (isAssetError as Error)?.message || 'An unknown Error Occurred'
  const sortedData = useMemo(() => {
    const sortedData = data
      ?.slice()
      .sort((a: any, b: any) => b?.mktval - a?.mktval)
    return sortedData
  }, [data])

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div css={{ marginBottom: '10px' }}>
        <TileHeading
          title="Asset Allocation"
          viewType={viewType}
          onExpand={onExpand}
          onViewtypeChange={handleViewTypeChange}
          graphIconType="pieIcon"
        />
      </div>
      {isError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar message={errorMessage} type="Failure" />
        </div>
      )}
      {/* <div
        css={{
          fontSize: '14px',
          lineHeight: '19px',
          marginTop: '10px',
          marginBottom: '5px'
        }}
      >
        <RadioButtonGroup
          name="assetType"
          align="horizontal"
          options={assetTypes}
          value={type}
          onChange={onAssetTypeChange}
        />
      </div> */}

      <div style={{ flexGrow: 1, minHeight: 0 }}>
        {viewType === ViewType.pie && (
          <AssetsAllocationChart data={data} selectedType={'L1'} />
        )}
        {viewType === ViewType.table && (
          <AssetSummaryTable
            data={sortedData}
            selectedType={'L1'}
            isLoading={isAssetLoading}
            isUninitialized={isAssetUninitialized}
          />
        )}
      </div>
      <div
        css={{
          fontSize: '10px',
          fontWeight: 300,
          marginTop: 6
        }}
      >
        Total values may differ from more timely amounts displayed in Balances
        and the Account Selector due to pending activity and the timing of data
        updates. This will be addressed in coming months.
      </div>
      {(isAssetLoading || isAssetFetching) && (
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      )}
    </div>
  )
}

export default AssetsAllocation
