import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotes } from 'api/dynamics'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'noteForm',
  initialState: {} as INoteFormState,
  reducers: {
    show: (state, action: PayloadAction<INotes | undefined>) => ({
      note: action.payload,
      showCallout: true
    }),
    hide: () => ({
      showCallout: false,
      note: undefined
    })
  }
})

export { reducer as noteFormReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes.noteForm

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getNote = flow(rootSelector, (x) => x.note)

export interface INoteFormState {
  showCallout?: boolean
  error?: Error
  note?: INotes
}

export const useNoteForm = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const setShow = useCallback(
    (note?: INotes) => {
      dispatch(actions.show(note))
    },
    [dispatch]
  )

  const show = useSelector(getShouldShowCallout)

  const note = useSelector(getNote)

  return {
    hide,
    show,
    setShow,
    note
  }
}
