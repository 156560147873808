import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import {
  useLazyCombinedRetrieveDocumentQuery,
  useLazyRetrieveDocumentQuery,
  useRetrieveDocumentListQuery
} from '../documentsApi'
import { IRetrieveDocumentListRequest } from '../documentsApi/IRetrieveDocumentListRequest'
import { IDocumentApiRetrieveDocumentRequest } from '../documentsApi/types'
import {
  useRdot360HouseholdAccountsApiContext,
  useRdot360SelectedAccountsApiContext
} from './apiContext'

const useDocumentsApiSelectedAccountsBaseRequest = (
  showAllAccounts: boolean
) => {
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()
  const { apiContextAccounts: allAccounts, apiKey: allAccountsKey } =
    useRdot360HouseholdAccountsApiContext()

  if (showAllAccounts) {
    return allAccounts?.length
      ? {
          accounts: allAccounts,
          contextId: allAccountsKey
        }
      : false
  }

  return apiContextAccounts?.length
    ? {
        accounts: apiContextAccounts,
        contextId: apiKey
      }
    : false
}

export const useRetrieveDocumentListQueryForSelectedAccounts = (
  request:
    | Omit<IRetrieveDocumentListRequest, 'PrimaryType' | 'PrimaryValue'>
    | false,
  showAllAccounts: boolean
) => {
  const base = useDocumentsApiSelectedAccountsBaseRequest(showAllAccounts)
  return useRetrieveDocumentListQuery(
    (base &&
      request && {
        ...base,
        payload: {
          ...request,
          PrimaryType: 'Account',
          PrimaryValue: base.accounts
        }
      }) ||
      skipToken
  )
}

export const useLazyRetrieveDocumentQueryForSelectedAccounts = () => {
  const [triggerRetrieveDocument] = useLazyRetrieveDocumentQuery()
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()

  const trigger = useCallback(
    (payload: IDocumentApiRetrieveDocumentRequest) => {
      const result = triggerRetrieveDocument(
        {
          accounts: apiContextAccounts,
          contextId: apiKey,
          payload
        },
        false
      )
      result.unsubscribe()
      return result
    },
    [apiContextAccounts, apiKey, triggerRetrieveDocument]
  )

  return [trigger]
}

export const useLazyRetrieveCombinedDocumentQueryForSelectedAccounts = () => {
  const [triggerRetrieveCombinedDocument] =
    useLazyCombinedRetrieveDocumentQuery()
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()

  const trigger = useCallback(
    async (payload: IDocumentApiRetrieveDocumentRequest) => {
      const result = triggerRetrieveCombinedDocument(
        {
          accounts: apiContextAccounts,
          contextId: apiKey,
          payload
        },
        false
      )

      result.unsubscribe()
      return result
    },
    [apiContextAccounts, apiKey, triggerRetrieveCombinedDocument]
  )

  return [trigger]
}
