import { ActionType, createAction } from 'typesafe-actions'
import {
  IClientSettings,
  IClientSettingsDocReq,
  IPaymentClient,
  IUpdatePaymentAccountReq,
  IUploadLoaDocRequest
} from './types'

export const FETCH_CLIENT_SETTINGS_LIST =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST'
export const FETCH_CLIENT_SETTINGS_LIST_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST_SUCCESS'
export const FETCH_CLIENT_SETTINGS_LIST_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST_FAILURE'

export const SET_SELECTED_CLIENT_SETTING =
  '@modules/@payments/@admin/@clientsettings/SET_SELECTED_CLIENT_SETTING'

export const FETCH_CLIENT_SETTINGS_DOC =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC'
export const FETCH_CLIENT_SETTINGS_DOC_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC_SUCCESS'
export const FETCH_CLIENT_SETTINGS_DOC_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC_FAILURE'

export const FETCH_CLIENT_LIST =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST'
export const FETCH_CLIENT_LIST_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST_SUCCESS'
export const FETCH_CLIENT_LIST_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST_FAILURE'

export const UPDATE_PAYMENT_ACCOUNT =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT'
export const UPDATE_PAYMENT_ACCOUNT_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT_SUCCESS'
export const UPDATE_PAYMENT_ACCOUNT_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT_FAILURE'

export const UPLOAD_LOA_DOC =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC'
export const UPLOAD_LOA_DOC_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC_SUCCESS'
export const UPLOAD_LOA_DOC_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC_FAILURE'

export const fetchClientSettingsListActions = {
  request: createAction(FETCH_CLIENT_SETTINGS_LIST)(),
  success: createAction(FETCH_CLIENT_SETTINGS_LIST_SUCCESS)<
    IClientSettings[]
  >(),
  failure: createAction(FETCH_CLIENT_SETTINGS_LIST_FAILURE)<Error>()
}

export const setSelectedClientSettingActions = {
  set: createAction(SET_SELECTED_CLIENT_SETTING)<IClientSettings>()
}

export const fetchClientSettingsDocActions = {
  request: createAction(FETCH_CLIENT_SETTINGS_DOC)<IClientSettingsDocReq>(),
  success: createAction(FETCH_CLIENT_SETTINGS_DOC_SUCCESS)<string>(),
  failure: createAction(FETCH_CLIENT_SETTINGS_DOC_FAILURE)<Error>()
}

export const fetchClientListActions = {
  request: createAction(FETCH_CLIENT_LIST)<IClientSettings>(),
  success: createAction(FETCH_CLIENT_LIST_SUCCESS)<IPaymentClient[]>(),
  failure: createAction(FETCH_CLIENT_LIST_FAILURE)<Error>()
}

export const updatePaymentAccountActions = {
  request: createAction(UPDATE_PAYMENT_ACCOUNT)<IUpdatePaymentAccountReq>(),
  success: createAction(UPDATE_PAYMENT_ACCOUNT_SUCCESS)(),
  failure: createAction(UPDATE_PAYMENT_ACCOUNT_FAILURE)<Error>()
}

export const uploadLoaDocActions = {
  request: createAction(UPLOAD_LOA_DOC)<IUploadLoaDocRequest>(),
  success: createAction(UPLOAD_LOA_DOC_SUCCESS)(),
  failure: createAction(UPLOAD_LOA_DOC_FAILURE)<Error>()
}

export type FetchClientSettingsListActionType = ActionType<
  typeof fetchClientSettingsListActions
>

export type SetSelectedClientSettingsActionType = ActionType<
  typeof setSelectedClientSettingActions
>

export type FetchClientSettingsDocActionType = ActionType<
  typeof fetchClientSettingsDocActions
>

export type FetchClientListActionType = ActionType<
  typeof fetchClientListActions
>

export type UpdatePaymentAccountActionType = ActionType<
  typeof updatePaymentAccountActions
>

export type UploadLoaDocActionType = ActionType<typeof uploadLoaDocActions>

export type ClientSettingsActions =
  | FetchClientSettingsListActionType
  | SetSelectedClientSettingsActionType
  | FetchClientSettingsDocActionType
  | FetchClientListActionType
  | UpdatePaymentAccountActionType
  | UploadLoaDocActionType
