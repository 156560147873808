import { Icon, Stack, Text } from '@fluentui/react'
import React, { memo } from 'react'
import { IAdvisorDetailResult } from '../../../api/advisor.types'
import { USD } from '../../../shared/components/Formatting'
import { Highlight } from './Highlight'

export interface IAdvisorSearchResultsProps {
  className?: string
  advisors: IAdvisorDetailResult[]
  onAdvisorClick: (account: IAdvisorDetailResult) => void
  query?: string
}

export const AdvisorSearchResults: React.FC<IAdvisorSearchResultsProps> = memo(
  ({ className, advisors, onAdvisorClick, query }) => {
    return (
      <table className={className}>
        <thead>
          <tr>
            <td />
            <td />
            <td>
              <Text variant="small">
                <b>AUS</b>
              </Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {advisors.map((x) => {
            const onClick = () => onAdvisorClick(x)
            return (
              <tr key={x.id} onClick={onClick}>
                <td>
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                  >
                    <Stack.Item verticalFill={true} align="center">
                      <Icon iconName="ContactCard" />
                    </Stack.Item>
                    <Stack.Item
                      grow={1}
                      align="start"
                      styles={{ root: { minWidth: 0 } }}
                    >
                      <Text nowrap={true} block={true} variant="medium">
                        <Highlight
                          text={x.ClientAdvisor || ''}
                          highlight={query}
                        />
                      </Text>

                      <Text nowrap={true} block={true} variant="small">
                        <Highlight
                          text={x.ClientAdvisorTeam || ''}
                          highlight={query}
                        />
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td>
                  <Stack horizontal={true} horizontalAlign="space-between">
                    <Stack.Item>
                      <Text nowrap={true} block={true} variant="small">
                        {`${x.total?.noofclient || 0}`} Clients
                      </Text>

                      <Text nowrap={true} block={true} variant="small">
                        {`${x.total?.noofaccounts || 0}`} Accounts
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text nowrap={true} block={true} variant="small">
                        <Highlight
                          text={x.ClientAdvisorID || ''}
                          highlight={query}
                        />
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td>
                  <Text variant="small">
                    <USD value={x.AdvisorKPI?.KPIs?.AumTotal || 0} />
                  </Text>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)
