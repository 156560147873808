import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'noteCategoryCallout',
  initialState: {} as INoteCategoryCalloutState,
  reducers: {
    show: (state, action: PayloadAction<string>) => ({
      showCallout: true,
      targetId: action.payload
    }),
    hide: () => ({
      showCallout: false,
      error: undefined,
      targetId: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as noteCategoryCalloutReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes
    .noteCategoryCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getPanelError = flow(rootSelector, (x) => x.error)
const getTargetId = flow(rootSelector, (x) => x.targetId)

export interface INoteCategoryCalloutState {
  showCallout?: boolean
  error?: Error
  targetId?: string
}

export const useNoteCategoryCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(
    (id: string) => {
      dispatch(actions.show(id))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  const targetId = useSelector(getTargetId)

  return {
    hide,
    show,
    setError,
    showCallout,
    error,
    targetId
  }
}
