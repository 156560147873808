import {
  Callout,
  DirectionalHint,
  ITheme,
  ProgressIndicator,
  SearchBox,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAccount } from '../../api/account.types'
import { IAdvisorDetailResult } from '../../api/advisor.types'
import { IClient } from '../../api/client.types'
import { useClasses } from '../../shared/hooks/useClasses'
import { AccountSearchResults } from './components/AccountSearchResults'
import { AdvisorSearchResults } from './components/AdvisorSearchResults'
import { ClientSearchResults } from './components/ClientSearchResults'
import { SearchTotalDivider } from './components/SearchTotalDivider'
import {
  getIsSearchLoading,
  getSearchResults,
  getSearchText,
  searchBoxActions,
  searchResultActions
} from './store'

const getThemedClasses = (theme: ITheme) => {
  return {
    searchInput: {
      backgroundColor: theme.palette.neutralLight,
      border: 0
    },
    searchContainer: {
      maxWidth: 500,
      margin: '0 auto',
      position: 'relative'
    },
    searchResults: {
      padding: 0,
      margin: 0,
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      width: '100%',
      selectors: {
        '& tr:hover': {
          backgroundColor: theme.palette.neutralLighter
        },
        tr: {
          cursor: 'pointer'
        },
        td: {
          padding: '5px',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        },
        '& td:nth-child(1)': {
          width: '200px'
        },
        '& td:nth-child(2)': {
          width: '150px'
        },
        '& td:nth-child(3)': {
          verticalAlign: 'top',
          textAlign: 'right'
        }
      }
    },
    seperator: {
      flexGrow: 1,
      position: 'relative',
      selectors: {
        div: {
          backgroundColor: theme.palette.neutralDark,
          height: '1px',
          content: '',
          display: 'block',
          position: 'absolute',
          top: '50%',
          bottom: '0px',
          left: '0px',
          right: '0px'
        }
      }
    }
  }
}

export const SearchContainer: React.FC = () => {
  const classNames = useClasses(getThemedClasses)

  const isSearchLoading = useSelector(getIsSearchLoading)
  const searchResults = useSelector(getSearchResults)
  const searchText = useSelector(getSearchText)

  const [isSearchCalloutOpen, setIsSearchCalloutOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (searchResults || !isSearchCalloutOpen) {
      return
    }

    dispatch(searchBoxActions.updateSearchText(''))
  }, [dispatch, isSearchCalloutOpen, searchResults])

  const updateSearchText = useCallback(
    (text?: string) => dispatch(searchBoxActions.updateSearchText(text || '')),
    [dispatch]
  )
  const clearSearchValue = () => updateSearchText() && closeSearchCallout()
  const openSearchCallout = () => setIsSearchCalloutOpen(true)
  const closeSearchCallout = () => setIsSearchCalloutOpen(false)

  const onSearchBoxValueChanged = (event?: any, newValue?: string) => {
    updateSearchText(newValue || undefined)
    if (newValue) {
      openSearchCallout()
    }
  }

  const searchContainer = useRef<HTMLDivElement>(null)

  const onClientSelected = (client: IClient) =>
    dispatch(searchResultActions.clientSelected(client))
  const onAccountSelected = (account: IAccount) =>
    dispatch(searchResultActions.accountSelected(account))
  const onAdvisorSelected = (advisor: IAdvisorDetailResult) =>
    dispatch(searchResultActions.advisorSelected(advisor))

  useEffect(() => {
    if (isSearchCalloutOpen && searchText === undefined) {
      updateSearchText('')
    }
  }, [isSearchCalloutOpen, searchText, updateSearchText])

  return (
    <div className={classNames.searchContainer} ref={searchContainer}>
      {isSearchLoading ? (
        <ProgressIndicator
          styles={{
            root: {
              position: 'absolute',
              bottom: '-4px',
              left: 0,
              right: 0
            },
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      ) : null}
      <SearchBox
        styles={{
          clearButton: { display: 'none' }
        }}
        autoComplete="off"
        className={classNames.searchInput}
        placeholder="Search"
        value={searchText}
        onClear={clearSearchValue}
        onChange={onSearchBoxValueChanged}
        onFocus={openSearchCallout}
        onClick={openSearchCallout}
        disableAnimation={true}
      />
      {isSearchCalloutOpen ? (
        <Callout
          styles={{
            root: {
              zIndex: 3,
              width: '100%',
              marginTop: '5px'
            }
          }}
          target={searchContainer}
          isBeakVisible={false}
          setInitialFocus={true}
          directionalHint={DirectionalHint.bottomAutoEdge}
          doNotLayer={true}
          onDismiss={closeSearchCallout}
        >
          <Stack
            onClick={closeSearchCallout}
            styles={{
              root: {
                padding: 5,
                maxHeight: 'calc(100vh - 100px)',
                paddingBottom: '10px'
              }
            }}
          >
            <SearchTotalDivider
              className={classNames.seperator}
              title="Clients"
              count={searchResults?.clients?.value?.length || 0}
              total={searchResults?.clients?.['@odata.count'] || 0}
            />
            {searchResults?.clients?.value?.length ? (
              <ClientSearchResults
                clients={searchResults?.clients?.value || []}
                className={classNames.searchResults}
                onClientClick={onClientSelected}
                query={searchText}
              />
            ) : (
              <Text
                styles={{
                  root: { textAlign: 'center', padding: '20px' }
                }}
                variant="small"
              >
                No Results
              </Text>
            )}

            <SearchTotalDivider
              className={classNames.seperator}
              title="Accounts"
              count={searchResults?.accounts?.value?.length || 0}
              total={searchResults?.accounts?.['@odata.count'] || 0}
            />

            {searchResults?.accounts?.value?.length ? (
              <AccountSearchResults
                accounts={searchResults?.accounts?.value || []}
                className={classNames.searchResults}
                onAccountClick={onAccountSelected}
                query={searchText}
              />
            ) : (
              <Text
                styles={{
                  root: { textAlign: 'center', padding: '20px' }
                }}
                variant="small"
              >
                No Results
              </Text>
            )}

            <SearchTotalDivider
              className={classNames.seperator}
              title="Advisors"
              count={searchResults?.advisors?.value?.length || 0}
              total={searchResults?.advisors?.['@odata.count'] || 0}
            />

            {searchResults?.advisors?.value?.length ? (
              <AdvisorSearchResults
                advisors={searchResults?.advisors?.value || []}
                className={classNames.searchResults}
                onAdvisorClick={onAdvisorSelected}
                query={searchText}
              />
            ) : (
              <Text
                styles={{
                  root: { textAlign: 'center', padding: '20px' }
                }}
                variant="small"
              >
                No Results
              </Text>
            )}
          </Stack>
        </Callout>
      ) : null}
    </div>
  )
}
