import { sumBy } from 'lodash'
import { IColumn } from 'modules/Advisory/modules/Rdot360/components/shared/DataTable/DataTable'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { FC, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { IncomeTableData } from '../useEstimatedIncomeTableData'
import { getDates, IncomeType } from '../util'
import DataTable from './DataTable'

export interface ProjectedIncomeDetailTableProps {
  data?: IncomeTableData
  searchString: string
  financialYear: number
  incomeType: IncomeType
}

const commonColumnStyle = {
  height: 60,
  fontSize: 13,
  lineHeight: '15px'
}

const commonHeaderStyle = {
  height: 80,
  fontWeight: 500,
  fontSize: 13
}

const ProjectedIncomeDetailTable: FC<ProjectedIncomeDetailTableProps> = ({
  data,
  searchString,
  financialYear,
  incomeType
}) => {
  const projectedIncomeDetailTableData = useMemo(() => {
    if (data) {
      const search = searchString.toLowerCase()

      const projectedIncomeDetailTableData = data
        .filter(
          (item) =>
            (item.SECDESCRIPTION || '').toLocaleLowerCase().includes(search) ||
            (item.SECNAME || '').toLocaleLowerCase().includes(search)
        )
        .map((item) => {
          const [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec] =
            item.months
          return {
            Security: item.SECDESCRIPTION,
            SECNAME: item.SECNAME,
            Jan,
            Feb,
            Mar,
            Apr,
            May,
            Jun,
            Jul,
            Aug,
            Sep,
            Oct,
            Nov,
            Dec,
            Total: item.total
          }
        })

      projectedIncomeDetailTableData.push({
        Security: 'Total',
        SECNAME: '',
        Jan: sumBy(projectedIncomeDetailTableData, ({ Jan }) => Jan),
        Feb: sumBy(projectedIncomeDetailTableData, ({ Feb }) => Feb),
        Mar: sumBy(projectedIncomeDetailTableData, ({ Mar }) => Mar),
        Apr: sumBy(projectedIncomeDetailTableData, ({ Apr }) => Apr),
        May: sumBy(projectedIncomeDetailTableData, ({ May }) => May),
        Jun: sumBy(projectedIncomeDetailTableData, ({ Jun }) => Jun),
        Jul: sumBy(projectedIncomeDetailTableData, ({ Jul }) => Jul),
        Aug: sumBy(projectedIncomeDetailTableData, ({ Aug }) => Aug),
        Sep: sumBy(projectedIncomeDetailTableData, ({ Sep }) => Sep),
        Oct: sumBy(projectedIncomeDetailTableData, ({ Oct }) => Oct),
        Nov: sumBy(projectedIncomeDetailTableData, ({ Nov }) => Nov),
        Dec: sumBy(projectedIncomeDetailTableData, ({ Dec }) => Dec),
        Total: sumBy(projectedIncomeDetailTableData, ({ Total }) => Total)
      })

      return projectedIncomeDetailTableData
    } else {
      return []
    }
  }, [data, searchString])

  const dates: string[] = getDates(incomeType, financialYear)

  const columns = useMemo(() => {
    const columnDefs: IColumn[] = [
      {
        sortable: true,
        field: 'Security',
        headerName: 'Symbol/CUSIP Description',
        format: (value, row) => {
          return (
            <div>
              <div css={{ color: '#1A75C9' }}>
                <HighlightSearchText
                  text={row?.SECNAME}
                  search={searchString}
                />
              </div>
              <div
                css={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                <HighlightSearchText text={value} search={searchString} />
              </div>
            </div>
          )
        },
        headerStyle: {
          ...commonHeaderStyle,
          width: 250,
          paddingLeft: 8
        },
        columnStyle: {
          ...commonColumnStyle,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          paddingLeft: 8
        }
      },
      ...dates.map(
        (month) =>
          ({
            field: month.split(' ')[0],
            align: 'right',
            headerName: month,
            headerStyle: commonHeaderStyle,
            columnStyle: commonColumnStyle,
            format: (value: number) =>
              value > 0 ? <USD value={value} fractionDigits={0} /> : '-'
          } as IColumn)
      ),
      {
        field: 'Total',
        headerName: 'Total Income',
        align: 'right',
        headerStyle: {
          ...commonHeaderStyle,
          paddingRight: 8
        },
        columnStyle: {
          ...commonColumnStyle,
          paddingRight: 8
        },
        format: (value: number) =>
          value > 0 ? <USD value={value} fractionDigits={0} /> : '-'
      }
    ]

    return columnDefs
  }, [dates, searchString])

  return <DataTable columns={columns} data={projectedIncomeDetailTableData} />
}

export default ProjectedIncomeDetailTable
