import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'store'

interface IAiDasbhoardState {
  selectedHouseholds?: string[]
}
const initialState = {} as IAiDasbhoardState

export const { actions: aiDashboardActions, reducer: aiDashboardReducer } =
  createSlice({
    name: '@modules/@advisory/@modules/@ai/',
    initialState,
    reducers: {
      setSelectedHouseholds: (
        state,
        action: PayloadAction<string[] | undefined>
      ) => ({
        ...state,
        selectedHouseholds: action.payload
      })
    }
  })

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.dashboard

export const selectSelectedHouseholds = createSelector(
  rootSelector,
  ({ selectedHouseholds }) => selectedHouseholds
)
