import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { isNotNullOrUndefined } from 'shared/gaurds'
import {
  HighchartsComponent,
  HighchartsImport,
  highcharts
} from 'shared/highcharts'
import {
  useInvestmentsTableStore,
  investmentsTableColumnNames
} from '../../modules/Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../modules/Investments/InvestmentsDetailsUIState'
import { IHoldings } from '../../store/types'

const getHoldingOptions = (
  onClick: (value?: string) => void,
  highcharts?: HighchartsImport,
  data: IHoldings[] = []
) => ({
  chart: {
    type: 'bar',
    height: 240
  },
  title: {
    text: ''
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  xAxis: {
    title: {
      text: ''
    },
    categories: data.map((item) => item.symbol)
  },
  yAxis: {
    title: {
      text: ''
    },
    softMin: 0
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    bar: {
      events: {
        click: (ev: any) => {
          ev?.preventDefault && ev.preventDefault()
          onClick(ev?.point?.category)
        }
      },
      pointWidth: 8,
      cursor: 'pointer'
    },
    series: {
      borderWidth: 0
    }
  },

  tooltip: {
    pointFormat: '$<b>{point.y:,.0f}</b>',
    formatter: function formatTooltip(this: any) {
      if (this.y >= 0) {
        return [
          `${data[this.point.x].SECNAME}`,
          `<b>$${highcharts?.numberFormat(this.y, 0, '.', ',')}</b>`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      } else {
        return [
          `${data[this.point.x].SECNAME}`,
          `<b>-$${highcharts?.numberFormat(this.y * -1, 0, '.', ',')}</b>`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      }
    }
  },

  series: [
    {
      type: 'bar',
      name: 'Browsers',
      colorByPoint: false,
      color: '#478bc8',
      data: data.map((item) => item.recentmktval)
    }
  ]
})

export const HoldingsChart: FC<{ data: IHoldings[] }> = ({ data = [] }) => {
  const navigate = useNavigate()
  const { setGrouping } = useInvestmentsTableStore()
  const { setSearchText } = useInvestmentsDetailsUIState()

  const onClick = useCallback(
    (value?: string) => {
      navigate('../investments')
      setGrouping([investmentsTableColumnNames.secid])
      setSearchText(value)
    },
    [navigate, setGrouping, setSearchText]
  )
  const holdingOptions = useMemo(
    () => getHoldingOptions(onClick, highcharts, data),
    [data, onClick]
  )
  return <HighchartsComponent options={holdingOptions} />
}
