import { CellContext } from '@tanstack/react-table'
import { USD } from 'shared/components/Formatting'

export const ChangeCell: React.FC<CellContext<any, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  const isPositive = (value || 0) > 0
  return (
    <span style={{ color: isPositive ? '#008000' : undefined }}>
      <USD value={value} currencySign="standard" />
    </span>
  )
}
