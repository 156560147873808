import { createColumnHelper } from '@tanstack/react-table'
import { groupBy, sortBy, sumBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  NumberCell,
  USDCell,
  WidgetTable
} from '../../../features/WidgetTable/WidgetTable'
import {
  IGroupedActivitySummary,
  IGroupedActivityType
} from '../../../store/types'
import { IActivityDurationOptions } from '../../Activity/ActivityDetailView'
import { useSharedActivityStore } from '../../shared/activity'
import { useActivityMappings } from './activityMappings'

interface IActivitySummaryRow extends IGroupedActivityType {
  key?: string
  order?: number
  color?: string
}

export const ActivitySummaryTable: React.FC<{
  data?: IGroupedActivitySummary
  dateRange?: string
  isLoading?: boolean
  isUninitialized?: boolean
}> = ({ data, dateRange, isUninitialized, isLoading }) => {
  const { categoryLookup, colorLookup, orderLookup, categoryCodeLookup } =
    useActivityMappings()
  const { setCategories, setDateSelection } = useSharedActivityStore()
  const navigate = useNavigate()
  const onClick = useCallback(
    (categories?: string[]) => {
      setDateSelection(dateRange as IActivityDurationOptions)
      setCategories(categories)
      navigate('../activity')
    },
    [dateRange, navigate, setCategories, setDateSelection]
  )

  const columnHelper = createColumnHelper<IActivitySummaryRow>()

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ type }) => type, {
        id: 'type',
        cell: (props) => (
          <div css={{ display: 'flex' }} title={props.getValue()}>
            <div
              css={{
                width: '10px',
                height: '10px',
                backgroundColor: props.row.original.color,
                marginTop: '2px'
              }}
            />
            <div
              css={{
                color: '#1D679D',
                cursor: 'pointer',
                paddingLeft: '15px'
              }}
              onClick={() =>
                onClick(categoryCodeLookup?.[props.getValue() || ''] || [])
              }
            >
              {props.getValue()}
            </div>
          </div>
        ),
        header: () => <div css={{ textAlign: 'left' }}>Activity Type</div>,
        size: 220
      }),
      columnHelper.accessor(({ totalActivities }) => totalActivities, {
        id: 'count',
        cell: (props) => <NumberCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'right' }}>Count</div>,
        size: 100
      }),
      columnHelper.accessor(({ totalAmount }) => totalAmount, {
        id: 'amount',
        cell: (props) => <USDCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'right' }}>$</div>,
        size: 130
      })
    ],
    [categoryCodeLookup, columnHelper, onClick]
  )
  const summaryData = useMemo(() => {
    if (!data || !data?.groupedActivities?.length) {
      return undefined
    }
    const activity = data?.groupedActivities?.flatMap(
      (x) => x.groupedActivityType
    )
    const mappedActivity = activity?.map((x) => ({
      ...x,
      mappedCategory:
        (x?.type && categoryLookup[x.type]) || categoryLookup['Other']
    }))
    const groupedActivity = groupBy(mappedActivity, (x) => x.mappedCategory)
    return sortBy(
      Object.entries(groupedActivity)?.map(
        ([key, value]) =>
          ({
            type: key,
            totalActivities: sumBy(value, (x) => x?.totalActivities || 0),
            totalAmount: sumBy(value, (x) => x?.totalAmount || 0),
            color:
              (value?.[0]?.type && colorLookup[value?.[0]?.type]) ||
              colorLookup['Other'],
            order: orderLookup[key] || orderLookup['Other']
          } as IActivitySummaryRow)
      ),
      (x) => x.order
    )
  }, [categoryLookup, colorLookup, data, orderLookup])

  return (
    <WidgetTable
      columns={columns}
      data={summaryData}
      isLoading={isLoading}
      isUninitialized={isUninitialized}
    />
  )
}
