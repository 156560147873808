import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SnackBar } from '../../../components/shared/Snackbar'
import { useRetrieveDocumentListQueryForSelectedAccounts } from '../../../store/rdot360Context/useRdot360DocumentsContext'
import LoaderContainer from '../DocumentFilter/LoaderContainer'
import {
  getSearchDateBegin,
  getSearchDateEnd,
  getIsDownloading,
  getStatementTypeToggleValue,
  useDocumentModuleStore,
  getShowAllAccounts
} from '../DocumentFilter/store'
import StatementsDetailTable from './StatementsDetailTable'

const StatementsTab = () => {
  const tab = 'Statements'
  const selectedSearchDateEnd = useSelector(getSearchDateEnd(tab))
  const selectedSearchDateBegin = useSelector(getSearchDateBegin(tab))
  const showAllAccounts = useSelector(getShowAllAccounts())
  const isDownloading = useSelector(getIsDownloading)
  const { data, isLoading, isFetching, isUninitialized } =
    useRetrieveDocumentListQueryForSelectedAccounts(
      !!(selectedSearchDateBegin && selectedSearchDateEnd) && {
        VirtualTable: 'NF_STATEMENTS',
        SearchDateComparison: 'R',
        SearchDateBegin: selectedSearchDateBegin,
        SearchDateEnd: selectedSearchDateEnd,
        SearchDateEndSpecified: true,
        SearchAllVersions: true,
        IncludeRelationships: false,
        RenditionFilter: '*',
        DateRange: ''
      },
      showAllAccounts
    )

  const { clearSelection } = useDocumentModuleStore()
  useEffect(() => {
    clearSelection()
  }, [data, clearSelection])

  const toggleValue = useSelector(getStatementTypeToggleValue)
  const items = useMemo(
    () =>
      data?.DocumentList?.filter((x) =>
        toggleValue
          ? x.DocumentType !== 'STATEMENT'
          : x.DocumentType === 'STATEMENT'
      ) || [],
    [data?.DocumentList, toggleValue]
  )

  return (
    <>
      <LoaderContainer
        isLoading={isLoading}
        isFetching={isFetching}
        isDownloading={isDownloading}
        dataListCount={items.length}
      />
      <StatementsDetailTable items={items} />
      {!isUninitialized && !isFetching && items?.length === 0 ? (
        <SnackBar type="Info" message="No Data Available" />
      ) : (
        ''
      )}
    </>
  )
}

export default StatementsTab
