const IncomeDisclamer = () => {
  return (
    <div
      css={{
        width: 920,
        padding: 72,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        fontWeight: 500
      }}
    >
      <div css={{ marginBottom: 24 }}>
        Note: Projected-income calculations are hypothetical estimates, not
        guaranteed, and are not intended to replace your official account
        statements. These projections are based on current holdings and their
        most recent, regular income distributions. Capital gain distributions
        are not considered income for estimating purposes; these calculations
        only reflect interest and dividend distributions. They also may include
        estimated monthly cash flows from current holdings based on an “as of”
        date and are subject to change.
      </div>
      <div css={{ marginBottom: 24 }}>
        All calculations shown here, including charts, figures and graphs, rely
        upon the quality and accuracy of data supplied from other sources. While
        this information is believed to be true and accurate as of the date
        received, no guarantee is made as to its completeness nor its accuracy.
        There is no guarantee that these projected income estimates will be
        achieved, nor that past performance is indicative of future results.
      </div>
      <div>
        Rockefeller Global Family Office does not provide legal or tax advice.
        For tax-reporting purposes, please refer to annual tax statements,
        consisting of various 1099 forms and other documents, and consult with a
        tax or legal professional to address your particular situation.
      </div>
    </div>
  )
}

export default IncomeDisclamer
