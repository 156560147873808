import { Callout } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '../../../features/Icons/Icon'

export interface ISelectableOption<T = any> {
  /**
   * Arbitrary string associated with this option.
   */
  key: string | number
  /**
   * ID attribute associated with this option
   */
  id?: string
  /**
   * Text to render for this option
   */
  text: string
  /**
   * Title attribute (built in tooltip) for a given option.
   */
  title?: string
  /**
   * Text to render for this option
   * Note: the SelectAll itemType is only supported on one option in multiselect components
   */
  itemType?: SelectableOptionMenuItemType
  /**
   * Index for this option
   */
  index?: number
  /**
   * The aria label for the dropdown option. If not present, the `text` will be used.
   */
  ariaLabel?: string
  /** If option is selected. */
  selected?: boolean
  /**
   * Whether the option is disabled
   * @defaultvalue false
   */
  disabled?: boolean
  /**
   * Defines whether the option is hidden or not.
   * @defaultvalue false
   */
  hidden?: boolean
  /**
   * Data available to custom onRender functions.
   */
  data?: T
}

export declare enum SelectableOptionMenuItemType {
  Normal = 0,
  Divider = 1,
  Header = 2,
  SelectAll = 3
}

export interface IDropdownOption<T = any> extends ISelectableOption<T> {
  /**
   * @deprecated Use `selected` instead. Deprecated at v.65.1.
   */
  isSelected?: boolean
}

export interface IDropdownProps {
  /**
   * All selected options
   */
  readonly selectedOption?: IDropdownOption
  /**
   * Default selected option
   */
  readonly defaultSelectedOption?: IDropdownOption
  /**
   * Input placeholder text. Displayed until option is selected.
   * @deprecated Use `placeholder`
   */
  placeHolder?: string
  /**
   * Options for the dropdown. If using `defaultSelectedKey` or `defaultSelectedKeys`, options must be
   * pure for correct behavior.
   */
  options: IDropdownOption[]
  /**
   * Callback issued when the selected option changes.
   */
  onChange?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void
  customColor?: string
  disabled?: boolean
  height?: number
  placeholder?: string
}

const Dropdown: FC<IDropdownProps> = ({
  disabled = false,
  options = [],
  defaultSelectedOption,
  selectedOption,
  onChange,
  customColor,
  height,
  placeholder
}) => {
  const dropdownRef: any = useRef()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    IDropdownOption | undefined
  >(selectedOption || defaultSelectedOption)

  const toggleDropdown = useCallback((event: any) => {
    event.stopPropagation()
    setOpen((prev) => !prev)
  }, [])

  const closeDropdown = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDropdownitemClick = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      const selectedKey = event.currentTarget.id
      const selectedIndex = options.findIndex(
        ({ key }) => key.toString() === selectedKey
      )
      const selectedOption = options[selectedIndex]

      setSelectedDropdownOption(selectedOption)

      if (onChange) {
        onChange(event, selectedOption, selectedIndex)
      }

      closeDropdown()
    },
    [closeDropdown, onChange, options]
  )

  useEffect(() => {
    setSelectedDropdownOption(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    setSelectedDropdownOption(defaultSelectedOption)
  }, [defaultSelectedOption])

  useEffect(() => {
    const clickHandler = (event: any) => {
      if (event.target !== dropdownRef.current) {
        closeDropdown()
      }
    }
    document.addEventListener('click', clickHandler)

    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [closeDropdown])
  const dropdownId = useId('Callout')

  return (
    <div
      css={{
        position: 'relative'
      }}
    >
      <div
        tabIndex={0}
        ref={dropdownRef}
        id={dropdownId}
        style={{
          pointerEvents: disabled ? 'none' : 'all',
          cursor: disabled ? 'not-allowed' : 'pointer',
          background: disabled
            ? '#eeeeee'
            : customColor
            ? customColor
            : '#ffffff'
        }}
        css={{
          border: '1px solid #AFB1B6',
          borderRadius: 8,
          padding: '12px',
          color: '#000000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          height: height ? `${height}px` : undefined,
          columnGap: '10px'
        }}
        onClick={toggleDropdown}
      >
        <span
          css={selectedDropdownOption?.text ? undefined : { color: 'grey' }}
        >
          {selectedDropdownOption?.text
            ? selectedDropdownOption?.text
            : placeholder}
        </span>
        <Icon type="ChevronDown" width={16} height={21} />
      </div>
      {open && (
        <Callout
          target={`#${dropdownId}`}
          onDismiss={toggleDropdown}
          isBeakVisible={false}
          style={{
            border: '1px solid #AFB1B64D',
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 4,
            background: customColor ? customColor : '#ffffff',
            width: `${dropdownRef.current.offsetWidth}px`
          }}
        >
          <div>
            {options.map((item) => (
              <div
                css={{
                  cursor: 'pointer',
                  padding: '8px 20px',
                  width: '100%',
                  background:
                    selectedDropdownOption?.key === item.key
                      ? '#CBE0F0'
                      : customColor
                      ? customColor
                      : '#ffffff',
                  ':hover': {
                    background: '#eaeaea'
                  }
                }}
                key={item.key}
                id={item.key.toString()}
                onClick={handleDropdownitemClick}
              >
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </Callout>
      )}
    </div>
  )
}

export default Dropdown
