import { useMasking } from 'store/shared/hooks/useMaskedText'
import { useRdot360AccountContext } from '../../../store/rdot360Context/useRdot360AccountContext'
import { detailTable } from '../DataTable/DetailTable'
import { HighlightSearchText } from './HighlightSearchText'

export const AccountNumberCell: React.FC<{
  accountNumber?: string
  search?: string
}> = ({ accountNumber, search }) => {
  const { accountLookup } = useRdot360AccountContext()
  const { mask } = useMasking()
  const account = accountNumber ? accountLookup[accountNumber] : {}
  const nicknameWithMask = mask(account?.AccountNickname, 'nickname')
  const accountNumberWithMask = mask(accountNumber, 'account')

  return nicknameWithMask ? (
    <div>
      {nicknameWithMask && (
        <div css={[detailTable.ellipsis]} title={nicknameWithMask}>
          <HighlightSearchText text={nicknameWithMask} search={search} />
        </div>
      )}
      <div
        css={{
          display: 'flex',
          gridColumnGap: 4
        }}
      >
        <div
          css={(theme) => [
            detailTable.ellipsis,
            {
              color: theme.colors.tertiaryBlue1
            }
          ]}
          title={accountNumberWithMask}
        >
          <HighlightSearchText text={accountNumberWithMask} search={search} />
        </div>
        {account?.registrationtype && <div>{' | '}</div>}
        <div css={detailTable.ellipsis} title={account?.registrationtype}>
          {' '}
          {account?.registrationtype || ''}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div
        css={(theme) => [
          detailTable.ellipsis,
          {
            color: theme.colors.tertiaryBlue1
          }
        ]}
        title={accountNumberWithMask}
      >
        <HighlightSearchText text={accountNumberWithMask} search={search} />
      </div>
      {account?.registrationtype && (
        <div css={detailTable.ellipsis} title={account?.registrationDesc}>
          {account?.registrationDesc || ''}
        </div>
      )}
    </div>
  )
}
