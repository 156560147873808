import axios, { CancelTokenSource } from 'axios'
import { orderBy } from 'lodash'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getHurdlesWithProgress,
  IHurdle
} from '../../../../../../../../../api/datahub'
import { IOdataRequest } from '../../../../../../../../../api/odata.types'
import { IApiOptions } from '../../../../../../../../../shared/contracts/IApiOptions'
import { isNotNullOrUndefined } from '../../../../../../../../../shared/gaurds'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'
import { getAllPagedOdataApiResults } from '../../../../../../../../../store/shared/sagas/odata'

export const hurdlesWithProgressFetchActions = createAsyncAction(
  '@modules/@revenue/@hurdles/@hurdlesWithProgressFetch/REQUEST',
  '@modules/@revenue/@hurdles/@hurdlesWithProgressFetch/SUCCESS',
  '@modules/@revenue/@hurdles/@hurdlesWithProgressFetch/FAILURE'
)<string[] | undefined, IHurdle[] | undefined, Error>()

export const hurdlesWithProgressFetchReducer = createAsyncReducer(
  hurdlesWithProgressFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.hurdles.features
    .advisorHurdleTable.hurdlesWithProgressFetch

export const {
  getError: getHurdlesWithProgressFetchError,
  getIsLoading: getIsHurdlesWithProgressFetchLoading,
  getResult: getHurdlesWithProgressFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof hurdlesWithProgressFetchActions.request>
) {
  try {
    const pagedResults = yield* call(() =>
      getAllPagedOdataApiResults(
        {
          ...(action.payload && {
            filters: [`entityId in ('${action.payload.join(`','`)}')`]
          })
        },
        fetchHurdlesWithProgress
      )
    )

    const result = orderBy(pagedResults, ({ index }) => index)
      .map(({ result }) => result?.value)
      .filter(isNotNullOrUndefined)
      .flat()

    yield put(hurdlesWithProgressFetchActions.success(result || []))
  } catch (e: any) {
    yield put(hurdlesWithProgressFetchActions.failure(e))
  }
}

export const hurdlesWithProgressFetchSagas = [
  () => takeLatest(hurdlesWithProgressFetchActions.request, onRequest)
]

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

const fetchHurdlesWithProgress = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    const result = yield* call(getHurdlesWithProgress, apiOptions, request)
    return result
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}
