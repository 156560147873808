import { combineReducers, Reducer } from 'redux'
import {
  adminReducer,
  IAdminModuleState
} from '../features/Admin/store/reducer'
import {
  IAnticipatedBalanceState,
  anticipatedBalanceReducer
} from '../features/AnticipatedBalance/store/reducer'
import {
  dashboardReducer,
  IPaymentsState
} from '../features/Dashboard/store/reducer'

import {
  IInvoiceState,
  invoiceReducer
} from '../features/Invoices/store/reducer'
import {
  IPayeesState,
  payeesReducer
} from '../features/Ledger/modules/Payee/store/reducer'
import {
  IPersonState,
  personReducer
} from '../features/Ledger/modules/Person/store/reducer'
import {
  postingRulesReducer,
  IPostingRulesState
} from '../features/Ledger/modules/PostingRules/store/reducer'
import {
  IProjectState,
  projectReducer
} from '../features/Ledger/modules/Project/store/reducer'
import {
  ISubAccountState,
  subAccountReducer
} from '../features/Ledger/modules/SubAccount/store/reducer'
import {
  ILedgerPaymentState,
  ledgerPaymentReducer
} from '../features/LedgerPayments/store/reducer'

import {
  failedPaymentReducer,
  IFailedPaymentState
} from '../features/PaymentsForAction/store/reducer'
import {
  IPaymentTransactionsModuleState,
  paymentTransactionsReducer
} from '../features/PaymentTransactions/store/reducers'
import { vendorReducer, IVendorState } from '../features/Vendors/store/reducer'
import { IWireState, wireReducer } from '../features/Wire/store/reducer'
export interface IPaymentModuleState {
  dashboard: IPaymentsState
  vendor: IVendorState
  wire: IWireState
  invoice: IInvoiceState
  failedPayment: IFailedPaymentState
  anticipatedBalance: IAnticipatedBalanceState
  postingRules: IPostingRulesState
  payees: IPayeesState
  person: IPersonState
  project: IProjectState
  subAccount: ISubAccountState
  admin: IAdminModuleState
  ledgerPayment: ILedgerPaymentState
  paymentTransactions: IPaymentTransactionsModuleState
}

export const paymentreducer: Reducer<IPaymentModuleState> = combineReducers({
  dashboard: dashboardReducer,
  vendor: vendorReducer,
  wire: wireReducer,
  invoice: invoiceReducer,
  failedPayment: failedPaymentReducer,
  anticipatedBalance: anticipatedBalanceReducer,
  payees: payeesReducer,
  person: personReducer,
  project: projectReducer,
  subAccount: subAccountReducer,
  postingRules: postingRulesReducer,
  admin: adminReducer,
  ledgerPayment: ledgerPaymentReducer,
  paymentTransactions: paymentTransactionsReducer
})
