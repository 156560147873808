import { DetailsListLayoutMode, Stack, Text } from '@fluentui/react'
import { xor } from 'lodash'
import { constants } from 'modules/Advisory/shared/theme'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDomainStore } from '../../../../../../../../features/Domain/store/domain'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { IListsFacetFilter } from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { IListsUiState } from '../../../../../../../../features/Lists/core/contracts/IListsUIState'
import { AllocatedAmountCell } from './AllocatedAmountCell'
import { CreditEventColumnIds } from './ColumnDefinitions'
import {
  createCreditEventCell,
  CreditEventCellComponents
} from './CreditEventCell'
import { PayoutCell } from './PayoutCell'
import {
  constructInitialCreditEventListColumnState,
  actions,
  selectors,
  createCreditEventListFilter
} from './store'

const initialColumns: (keyof CreditEventColumnIds)[] = [
  'periodTimestamp',
  'tradeOrRevenueDate',
  'accountNumber',
  'partyName',
  'householdName',
  'description',
  'feeAndCommission',
  'allocatedAmount',
  'grossPayout',
  'revenueCategoryLvl1',
  'revenueCategoryLvl2',
  'repId'
]

const initialUiState: IListsUiState = {
  columnState: constructInitialCreditEventListColumnState(initialColumns),
  orderBy: {
    columnId: CreditEventColumnIds.periodTimestamp,
    direction: 'desc'
  }
}

const CreditEventListComponents = createListsComponents(
  actions,
  selectors,
  createCreditEventCell({
    ...CreditEventCellComponents,
    [CreditEventColumnIds.allocatedAmount]: ({ creditEvent }) => (
      <AllocatedAmountCell creditEvent={creditEvent} />
    ),
    [CreditEventColumnIds.grossPayout]: ({ creditEvent }) => (
      <PayoutCell creditEvent={creditEvent} />
    )
  })
)

export const CreditEventList: React.FC = () => {
  const { setConfig, setFilter, removeFilters } = actions.uiActions
  const { getFilters } = selectors.uiSelectors
  const dispatch = useDispatch()
  const { selectedAccountRepIds, isFullDomainSelected } = useDomainStore()
  const filters = useSelector(getFilters)

  useEffect(() => {
    const repIdFilter = filters?.[CreditEventColumnIds.repId] as
      | IListsFacetFilter
      | undefined
    const currentRepIdFilterValues = repIdFilter?.values

    const diff = xor(
      currentRepIdFilterValues || [],
      selectedAccountRepIds || []
    )

    // if there's no difference, or the request is for the full domain, and no filter exists
    // we are set and don't need to change anything
    if (
      !diff.length ||
      (isFullDomainSelected && !currentRepIdFilterValues?.length)
    ) {
      return
    }

    // if there's a difference, we might need to remove the filter
    if (!selectedAccountRepIds?.length || isFullDomainSelected) {
      dispatch(removeFilters([CreditEventColumnIds.repId]))
      return
    }

    const filter = createCreditEventListFilter(CreditEventColumnIds.repId, {
      type: 'facet',
      values: selectedAccountRepIds
    })

    dispatch(setFilter(filter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountRepIds])

  useEffect(() => {
    dispatch(setConfig(initialUiState))
  }, [dispatch, setConfig])

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <CreditEventListComponents.ListsSearch />
        <Stack.Item grow={1}>
          <CreditEventListComponents.ListsFilterStatusList />
        </Stack.Item>

        <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
          <CreditEventListComponents.ListsResultsCount /> Results
        </Text>
      </Stack>
      <Stack.Item>
        <Stack>
          <CreditEventListComponents.ListsCommandBar />
          <CreditEventListComponents.ListsProgress />
          <CreditEventListComponents.ListsInfiniteScrollDataTable
            layoutMode={DetailsListLayoutMode.justified}
            stickyHeaderOffset={constants.headerHeight}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
