import { css, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Toggle } from '@fluentui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import CorrespondenceTab from './Correspondence/CorrespondenceTab'
import DocumentFilter from './DocumentFilter/DocumentFilter'
import {
  documentFilterActions,
  useDocumentModuleStore
} from './DocumentFilter/store'
import StatementsTab from './Statements/StatementsTab'
import TaxDocumentsTab from './TaxDocuments/TaxDocumentsTab'
import TradeConfirmationsTab from './TradeConfirmations/TradeConfirmationsTab'

export const getClasses = (theme: Theme) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '24px'
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  margin2: css({
    margin: '0px 6px'
  }),
  tabStyle: css({
    padding: '5px',
    maxWidth: '190px',
    border: 'none',
    fontSize: '17px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer'
  }),
  statementsTabStyle: css({
    minWidth: '110px'
  }),
  taxDocumentTabStyle: css({
    minWidth: '140px'
  }),
  correspondenceTabStyle: css({
    minWidth: '140px'
  }),
  tradeConfTabStyle: css({
    minWidth: '170px'
  }),
  tabSelected: css({
    borderBottomStyle: 'solid',
    fontWeight: 'bold'
  }),
  tabUnselected: css({
    borderBottomStyle: 'none'
  }),
  checkboxLabel: css({
    marginLeft: '5px',
    fontSize: '16px'
  }),
  toggleStyle: css({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    marginLeft: 'auto'
  }),
  toggleContainer: css({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '21px'
  })
})

const DocumentToggle = styled.div`
  .ms-Toggle {
    margin: auto 0;
    .ms-Toggle-background {
      background-color: #b0d6f1;
      width: 36px;
      height: 14px;
      border: 0;
      padding: 0;
      margin-top: 3px;
      .ms-Toggle-thumb {
        background: #2a769d;
        height: 16px;
        width: 16px;
      }
    }
  }
`

const DocumentsContainer: React.FC = () => {
  type DocumentsTabType =
    | 'Statements'
    | 'Tax Documents'
    | 'Correspondence'
    | 'Trade Confirmations'
  const theme = useTheme()
  const dispatch = useDispatch()
  const [documentTabType, setDocumentTabType] =
    useState<DocumentsTabType>('Statements')
  const { setStatementTypeToggleValue } = documentFilterActions
  const classes = useMemo(() => getClasses(theme), [theme])
  const [isStatementTypeToggle, setIsStatementTypeToggle] =
    useState<boolean>(false)

  const { clearSelection } = useDocumentModuleStore()

  useEffect(() => {
    clearSelection()
  }, [dispatch, documentTabType, clearSelection])

  const handleStatementTypeToggleSwitch = () => {
    setIsStatementTypeToggle(!isStatementTypeToggle)
    dispatch(setStatementTypeToggleValue(!isStatementTypeToggle))
  }

  return (
    <>
      <div css={classes.container}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: 20,
            alignItems: 'flex-end'
          }}
        >
          <DetailsNavigator />
          <div
            css={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <button
              css={[
                classes.tabStyle,
                classes.statementsTabStyle,
                documentTabType === 'Statements'
                  ? classes.tabSelected
                  : classes.tabUnselected
              ]}
              onClick={useCallback(() => setDocumentTabType('Statements'), [])}
            >
              Statements
            </button>
            <button
              css={[
                classes.tabStyle,
                classes.taxDocumentTabStyle,
                documentTabType === 'Tax Documents'
                  ? classes.tabSelected
                  : classes.tabUnselected
              ]}
              onClick={useCallback(
                () => setDocumentTabType('Tax Documents'),
                []
              )}
            >
              Tax Documents
            </button>
            <button
              css={[
                classes.tabStyle,
                classes.correspondenceTabStyle,
                documentTabType === 'Correspondence'
                  ? classes.tabSelected
                  : classes.tabUnselected
              ]}
              onClick={useCallback(
                () => setDocumentTabType('Correspondence'),
                []
              )}
            >
              Correspondence
            </button>
            <button
              css={[
                classes.tabStyle,
                classes.tradeConfTabStyle,
                documentTabType === 'Trade Confirmations'
                  ? classes.tabSelected
                  : classes.tabUnselected
              ]}
              onClick={useCallback(
                () => setDocumentTabType('Trade Confirmations'),
                []
              )}
            >
              Trade Confirmations
            </button>
          </div>

          {documentTabType === 'Statements' && (
            <div css={classes.toggleStyle}>
              <div css={{ marginLeft: '6px' }}>
                <label>Statement type:</label>
              </div>
              <div css={classes.toggleContainer}>
                <div
                  css={[
                    classes.margin2,
                    !isStatementTypeToggle
                      ? classes.primaryDarkBlue
                      : classes.tertiaryGray1
                  ]}
                >
                  Detail
                </div>
                <DocumentToggle>
                  <Toggle
                    checked={isStatementTypeToggle}
                    onChange={handleStatementTypeToggleSwitch}
                    role="checkbox"
                  />
                </DocumentToggle>
                <div
                  css={[
                    classes.margin2,
                    isStatementTypeToggle
                      ? classes.primaryDarkBlue
                      : classes.tertiaryGray1
                  ]}
                >
                  Summary
                </div>
              </div>
            </div>
          )}
          <div
            css={{
              marginLeft: documentTabType !== 'Statements' ? 'auto' : '',
              display: 'flex',
              alignItems: 'flex-end',
              gap: 20
            }}
          >
            <DocumentFilter documentTabType={documentTabType} />
          </div>
        </div>
      </div>
      {documentTabType === 'Statements' ? <StatementsTab /> : ''}
      {documentTabType === 'Tax Documents' ? <TaxDocumentsTab /> : ''}
      {documentTabType === 'Correspondence' ? <CorrespondenceTab /> : ''}
      {documentTabType === 'Trade Confirmations' ? (
        <TradeConfirmationsTab />
      ) : (
        ''
      )}
    </>
  )
}

export default DocumentsContainer
