import axios from 'axios'
import { call, cancelled, select } from 'typed-redux-saga'
import {
  ISearchParams,
  ISearchResult,
  SearchIndices,
  SearchResponseType
} from '../../../api/common.types'
import {
  getRandomCapitalLetter,
  getRandomName,
  getRandomShortName
} from '../../../api/random'
import * as api from '../../../api/search'
import { getEnableDataMaskingPreference } from '../../user/selectors'
import { getRockefellerApiOptions } from './auth'

export const search = function* <T extends SearchResponseType>(
  index: SearchIndices,
  params: ISearchParams
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const results = yield* call(api.search, index, params, apiOptions)
    const shouldMask = yield* select(getEnableDataMaskingPreference)

    if (shouldMask) {
      const masked = results.value.map((x: any) => {
        const advisorId =
          getRandomCapitalLetter() +
          (x.ClientAdvisorID || x.AdvisorID || x.registeredrep || '').slice(-2)
        return {
          ...x,
          ClientAdvisor: getRandomName(),
          AdvisorID: advisorId,
          ClientAdvisorID: advisorId,
          registeredrep: advisorId,
          ClientAdvisorTeam: getRandomName() + ' Team',
          AccountNickname: x.AccountNickname ? getRandomName() : undefined,
          Shortname: x.Shortname ? getRandomShortName() : undefined,
          householdName: getRandomName() + ' Relationship'
        }
      })

      results.value = masked
    }

    return results as ISearchResult<T>
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const safeSearch = function* <T extends SearchResponseType>(
  index: SearchIndices,
  params: ISearchParams
) {
  try {
    const results = yield* call(search, index, params)
    return results as ISearchResult<T>
  } catch {
    return undefined
  }
}
