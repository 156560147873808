import { css } from '@emotion/react'

export const revenueTable = {
  table: css({
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    ['th, td']: {
      padding: '2px 8px',
      textAlign: 'right',
      width: '110px'
    },
    ['td.total']: {
      fontWeight: 'bolder'
    },
    ['tr:first-child th']: {},
    ['td.category']: {
      paddingLeft: '25px'
    },
    ['td.categoryTotal, th.categoryTotal']: {
      fontWeight: 'bolder',
      position: 'sticky',
      left: '175px',
      zIndex: 1
    },
    ['tr th:first-child, tr td:first-child']: {
      fontWeight: 'bold',
      textAlign: 'left',
      width: '175px',
      position: 'sticky',
      left: 0,
      zIndex: 1
    },
    ['thead th']: {
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    ['tr:hover td']: {},
    ['td.actionable:hover']: {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  }),
  resizerContainer: css({
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '6px',
    opacity: 0,
    cursor: 'ew-resize',
    ['&:hover']: { opacity: 0.5 }
  }),
  resizer: css({
    position: 'absolute',
    top: 5,
    bottom: 5,
    right: 3,
    background: 'black',
    width: '1px',
    opacity: 1
  }),
  isResizing: css({
    opacity: 1,
    boxShadow: 'black 0px 0px 10px'
  }),
  ellipsis: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  gainLossesWrapper: css({
    table: {
      ['tfoot']: {
        ['tr']: {
          minHeight: '260px !important',
          ['td']: {
            background: 'none'
          }
        }
      }
    }
  }),
  rightAlign: css({
    textAlign: 'end'
  })
}
