import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import {
  PositionCategoryType,
  useGetCategoryPositionQuery,
  useGetPositionByCategoryQuery,
  useGetTop10HoldingsQuery
} from '../holdingsApi'
import { useRdot360SelectedAccountsApiContext } from './apiContext'

const useHoldingsApiSelectedAccountsBaseRequest = () => {
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()
  return apiContextAccounts?.length
    ? {
        accounts: apiContextAccounts,
        contextId: apiKey
      }
    : false
}

export const useGetTop10HoldingsQueryForSelectedAccounts = () => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  return useGetTop10HoldingsQuery(request || skipToken)
}

export const useGetPositionByCategoryQueryForSelectedAccounts = (
  category: PositionCategoryType = 'L1'
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  return useGetPositionByCategoryQuery(
    request ? { ...request, category } : skipToken
  )
}

export const useGetCategoryPositionQueryForSelectedAccounts = (
  category: PositionCategoryType = 'L1'
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  return useGetCategoryPositionQuery(
    request ? { ...request, category } : skipToken
  )
}
