import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { IOdataListFacetResult } from '../store/odataListFacetStore'

const apiVersion = '1.0'
const defaultHeaders = {
  'api-version': apiVersion
}

export const getODataFacets = <T>(
  options: IApiOptions,
  property: string,
  filters?: string
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/UpcomingCapitalCalls?$apply=${
    filters ? `${filters}/` : ''
  }groupby((${property}),aggregate($count as count))`

  return axios
    .get<IOdataResult<IOdataListFacetResult<T>>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}
