import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IClient } from '../../api/client.types'
import { getMostRecentLoginDetails } from '../../shared/client'
import { navigationActions } from '../../store/ui/actions'
import { contactNavigationActions } from '../Contact/store'
import {
  IRelatedPartyDataRequestActionPayload,
  relatedPartyPanelActions
} from '../RelatedPartyPanel/store'
import { ActivitiesModalComponent } from './ActivitiesModalComponent'
import { ClientMenu } from './ClientMenu'

export interface IClientMenuContainerProps {
  client: IClient
}

export const ClientMenuContainer: React.FC<IClientMenuContainerProps> = ({
  client
}) => {
  const dispatch = useDispatch()
  const launchClientOnlineByWealthscapeId = useCallback(
    (id?: string) => {
      const { loginDetails, LegalEntityID } = client
      const wealthscapeId =
        id || getMostRecentLoginDetails(loginDetails)?.WealthscapeID
      dispatch(
        navigationActions.launchClientOnline({
          wealthscapeId,
          partyId: LegalEntityID
        })
      )
    },
    [client, dispatch]
  )

  const openRelatedPartyPanel = useCallback(
    (request: IRelatedPartyDataRequestActionPayload) =>
      dispatch(relatedPartyPanelActions.openPanel(request)),
    [dispatch]
  )

  const launchTeams = useCallback(
    (loginId: string) => dispatch(navigationActions.launchTeams(loginId)),
    [dispatch]
  )

  const launchWealthscape = useCallback(
    (path: string) => dispatch(navigationActions.launchWealthscape(path)),
    [dispatch]
  )

  const onLaunchDynamics = useCallback(
    async (client: IClient) => {
      dispatch(contactNavigationActions.navigateToContact(client))
    },
    [dispatch]
  )

  const [email, setEmail] = useState<string[]>()
  const getContact = useCallback((email: string[]) => {
    setEmail(email)
    setIsModalOpen(true)
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const onDismiss = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <>
      <ClientMenu
        client={client}
        onLaunchClientOnline={launchClientOnlineByWealthscapeId}
        onRelatedPartiesSelected={openRelatedPartyPanel}
        onLaunchTeams={launchTeams}
        onLaunchWealthscape={launchWealthscape}
        onLaunchDynamics={onLaunchDynamics}
        getContact={getContact}
      />
      <ActivitiesModalComponent
        email={email}
        isModalOpen={isModalOpen}
        onDismiss={onDismiss}
      />
    </>
  )
}
