import { keyBy, sum, uniq } from 'lodash'
import { useMemo } from 'react'
import {
  GetTableProjectedIncomeSummary,
  IncomeSubTotal
} from '../../../store/types'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const getTotalForMonth = (income?: Partial<IncomeSubTotal>, month?: string) =>
  sum(['Div', 'Int'].map((x) => income?.[`${month}${x}`] || 0))

export type IncomeTableData = ReturnType<typeof useEstimatedIncomeTableData>
const useEstimatedIncomeTableData = (
  estimatedIncomeData?: GetTableProjectedIncomeSummary,
  lastYearEstimatedIncomeData?: GetTableProjectedIncomeSummary
) => {
  const data = useMemo(() => {
    if (!lastYearEstimatedIncomeData || !estimatedIncomeData) {
      return (
        estimatedIncomeData?.IncomeSubTotal.map(
          ({ CUSIPNUM, ACCT, SECNAME, SECDESCRIPTION, ...x }) => {
            const monthTotals = months.map((month) =>
              getTotalForMonth(x, month)
            )
            return {
              CUSIPNUM,
              ACCT,
              SECNAME,
              SECDESCRIPTION,
              months: monthTotals,
              total: sum(monthTotals)
            }
          }
        ) || []
      )
    }

    const currentMonth = new Date().getMonth()
    const lastYearMonths = months.slice(currentMonth + 1)

    const allSecNames = uniq([
      ...estimatedIncomeData.IncomeSubTotal.map(({ SECNAME }) => SECNAME),
      ...lastYearEstimatedIncomeData.IncomeSubTotal.filter((subTotal) =>
        sum(lastYearMonths.map((month) => getTotalForMonth(subTotal, month)))
      ).map(({ SECNAME }) => SECNAME)
    ])

    const lookup1 = keyBy(
      estimatedIncomeData.IncomeSubTotal,
      ({ SECNAME }) => SECNAME
    )
    const lookup2 = keyBy(
      lastYearEstimatedIncomeData.IncomeSubTotal,
      ({ SECNAME }) => SECNAME
    )

    const lastYearMonthLookup = keyBy(lastYearMonths)
    return allSecNames.map((x) => {
      const currentPeriod = lookup1[x] && { ...lookup1[x] }
      const previousPeriod = lookup2[x] && { ...lookup2[x] }

      const { CUSIPNUM, ACCT, SECNAME, SECDESCRIPTION } =
        currentPeriod || previousPeriod || {}
      const monthTotals = months.map((month) =>
        getTotalForMonth(
          lastYearMonthLookup[month] ? previousPeriod : currentPeriod,
          month
        )
      )
      return {
        CUSIPNUM,
        ACCT,
        SECNAME,
        SECDESCRIPTION,
        months: monthTotals,
        total: sum(monthTotals)
      }
    })
  }, [estimatedIncomeData, lastYearEstimatedIncomeData])

  return data
}
export default useEstimatedIncomeTableData
