import {
  SearchBox,
  Callout,
  DirectionalHint,
  Stack,
  ITheme,
  ProgressIndicator,
  MessageBar,
  ISearchBox
} from '@fluentui/react'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { isNotNullOrFalse } from '../gaurds'
import { useClasses } from '../hooks/useClasses'

export interface IAutoCompleteProps<T> {
  className?: string
  placeholder?: string
  value?: string
  width?: string
  showLoadingIndicator?: boolean
  items: T[]
  itemComponent: React.FC<{ item?: T }>
  onItemSelected: (item?: T) => void
  onSearchTextChanged: (text?: string) => void
  onBlur?: (item?: T) => void
  autoFocus?: boolean
  searchBoxRef?: React.RefObject<ISearchBox>
}

const getThemedClassesForItem = (theme: ITheme) => ({
  autoCompleteItem: {
    selectors: {
      '&.active, &:hover': {
        backgroundColor: theme.palette.neutralLight,
        cursor: 'pointer'
      }
    }
  }
})

const AutoCompleteItem: React.FC<
  PropsWithChildren<{
    onItemSelected: () => void
    isActive?: boolean
  }>
> = ({ children, onItemSelected, isActive }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useClasses(getThemedClassesForItem)
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        return
      }
      onItemSelected()
    },
    [onItemSelected]
  )

  useEffect(() => {
    if (!isActive) {
      return
    }

    containerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }, [isActive])

  return (
    <div
      ref={containerRef}
      className={[classes.autoCompleteItem, isActive && 'active']
        .filter(isNotNullOrFalse)
        .join(' ')}
      onClick={onItemSelected}
      onKeyDown={onKeyDown}
    >
      {children}
    </div>
  )
}

export const AutoComplete = <T,>({
  className,
  placeholder,
  width = '100%',
  items,
  onItemSelected,
  itemComponent: ItemComponent,
  onSearchTextChanged,
  showLoadingIndicator = false,
  value = '',
  onBlur,
  autoFocus = false,
  searchBoxRef
}: IAutoCompleteProps<T>): React.ReactElement<IAutoCompleteProps<T>> => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isCalloutVisible, setIsCalloutVisible] = useState(false)
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const hideCallout = useCallback(() => setIsCalloutVisible(false), [])
  const showCallout = useCallback(() => setIsCalloutVisible(true), [])
  const selectionNotified = useRef(false)

  useEffect(() => {
    setActiveItemIndex(0)
  }, [items])

  const setSelectionNotified = useCallback(() => {
    selectionNotified.current = true
    setTimeout(() => (selectionNotified.current = false))
  }, [])

  const handleItemSelected = useCallback(
    (item: T) => {
      hideCallout()
      setSelectionNotified()
      onItemSelected(item)
    },
    [hideCallout, onItemSelected, setSelectionNotified]
  )

  const onSearchKeyDown = useCallback(
    ({ key }: React.KeyboardEvent<HTMLElement>): void => {
      setIsCalloutVisible(true)
      switch (key) {
        case 'ArrowDown':
          setActiveItemIndex(activeItemIndex + 1)
          break
        case 'ArrowUp':
          setActiveItemIndex(activeItemIndex - 1)
          break
        case 'Tab':
          handleItemSelected(items[activeItemIndex])
          break
      }
    },
    [activeItemIndex, handleItemSelected, items]
  )

  const onSearchBoxTextChange = useCallback(
    (_: unknown, newValue?: string) => {
      onSearchTextChanged(newValue)
    },
    [onSearchTextChanged]
  )

  const onSearchBoxSearch = useCallback(() => {
    handleItemSelected(items[activeItemIndex])
  }, [activeItemIndex, handleItemSelected, items])

  const onCalloutDismiss = useCallback(() => {
    hideCallout()
    if (!selectionNotified.current) {
      onBlur?.(items?.[activeItemIndex])
    }
  }, [activeItemIndex, hideCallout, items, onBlur])

  return (
    <div ref={containerRef} style={{ position: 'relative', width }}>
      <SearchBox
        className={className}
        styles={{
          root: { width: '100%' }
        }}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        onSearch={onSearchBoxSearch}
        onChange={onSearchBoxTextChange}
        onKeyDown={onSearchKeyDown}
        onEscape={onCalloutDismiss}
        onFocus={showCallout}
        onClick={showCallout}
        autoFocus={autoFocus}
        componentRef={searchBoxRef}
      />
      {showLoadingIndicator ? (
        <ProgressIndicator
          styles={{
            root: {
              position: 'absolute',
              bottom: '-2px',
              left: 0,
              right: 0
            },
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      ) : null}
      {isCalloutVisible && value && (
        <Callout
          styles={{
            root: containerRef?.current?.offsetWidth
              ? { width: `${containerRef?.current?.offsetWidth}px` }
              : undefined
          }}
          gapSpace={2}
          coverTarget={false}
          onDismiss={onCalloutDismiss}
          setInitialFocus={false}
          calloutMaxHeight={300}
          target={containerRef}
          directionalHint={DirectionalHint.bottomLeftEdge}
          isBeakVisible={false}
        >
          {!!items?.length && (
            <Stack styles={{ root: { width: '100%' } }}>
              {items.map((item, i) => {
                return (
                  <AutoCompleteItem
                    key={i}
                    onItemSelected={() => handleItemSelected(item)}
                    isActive={activeItemIndex === i}
                  >
                    <ItemComponent item={item} />
                  </AutoCompleteItem>
                )
              })}
            </Stack>
          )}
          {!items?.length && !showLoadingIndicator && (
            <MessageBar>No results found</MessageBar>
          )}
        </Callout>
      )}
    </div>
  )
}
