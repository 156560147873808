import { createColumnHelper } from '@tanstack/table-core'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PercentCell,
  TextCell,
  USDCell,
  WidgetTable
} from '../../features/WidgetTable/WidgetTable'
import {
  useInvestmentsTableStore,
  investmentsTableColumnNames
} from '../../modules/Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../modules/Investments/InvestmentsDetailsUIState'
import { ITop10HoldingDetail } from '../../store/holdingsApi/ITop10HoldingResponse'
import { HeaderContainer } from '../shared/DetailTables/HeaderContainer'

const HoldingsTable: FC<{
  data?: ITop10HoldingDetail[]
  isUninitialized?: boolean
  isLoading?: boolean
}> = ({ data, isUninitialized, isLoading }) => {
  const navigate = useNavigate()
  const { setGrouping } = useInvestmentsTableStore()
  const { setSearchText } = useInvestmentsDetailsUIState()
  const onClick = useCallback(
    (value?: string) => {
      navigate('../investments')
      setGrouping([investmentsTableColumnNames.secid])
      setSearchText(value)
    },
    [navigate, setGrouping, setSearchText]
  )

  const columnHelper = createColumnHelper<ITop10HoldingDetail>()
  const columns = useMemo(
    () => [
      columnHelper.accessor(({ SECNAME }) => SECNAME, {
        id: 'top10Investments',
        cell: (props) => <TextCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'left' }}>Top 10 Investments</div>,
        size: 160
      }),
      columnHelper.accessor(({ symbol }) => symbol, {
        id: 'symbol',
        cell: (props) => (
          <div css={{ display: 'flex' }} title={props.getValue()}>
            <div
              css={{
                color: '#1D679D',
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              onClick={() => onClick(props.getValue())}
            >
              {props.getValue()}
            </div>
          </div>
        ),
        header: () => <div css={{ textAlign: 'left' }}>Symbol</div>,
        size: 60
      }),
      columnHelper.accessor(({ recentmktval }) => recentmktval, {
        id: 'amount',
        cell: (props) => <USDCell value={props.getValue()} />,
        header: (props) => (
          <HeaderContainer
            title="Value"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        size: 100
      }),
      columnHelper.accessor(({ todaytotalpct }) => todaytotalpct, {
        id: 'percent',
        cell: (props) => <PercentCell value={(props.getValue() || 0) * 100} />,
        header: (props) => (
          <HeaderContainer
            title="Portfolio %"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        size: 100
      })
    ],
    [columnHelper, onClick]
  )

  return (
    <WidgetTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isUninitialized={isUninitialized}
    />
  )
}

export default HoldingsTable
