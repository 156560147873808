import { Dialog } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SimpleOverlay } from 'shared/components/SimpleOverlay'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { useRdot360_createCustomAccountGroupMutation } from '../../store/dynamics'
import {
  useRdot360AccountContext,
  useRdot360Context
} from '../../store/rdot360Context'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { useAccountSelectorTableStore } from '../AccountSelector'
import { AccountSelectorAccount } from '../AccountSelector/AccountSelectorAccount'
import { accountSelectorStyles } from '../AccountSelector/styles'

export const CustomAccountGroupModal: React.FC<{
  hidden?: boolean
  selectedAccounts?: string[]
  hide: () => void
}> = ({ hidden, hide, selectedAccounts }) => {
  const { setGrouping, setSelectedIds } = useAccountSelectorTableStore()
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  const { selectedHouseholdId } = useRdot360Context()
  const [groupName, setGroupName] = useState('')
  const onDismiss = useCallback(() => {
    hide()
    setGroupName('')
  }, [hide])
  const { accountLookup, setSelectedAccountIds } = useRdot360AccountContext()
  const accounts = useMemo(
    () =>
      selectedAccounts
        ?.map((x) => accountLookup[x])
        ?.filter(isNotNullOrUndefined),
    [accountLookup, selectedAccounts]
  )
  const [uiSelected, setUiSelected] = useState(selectedAccounts)
  const onCheckboxClicked = useCallback(
    (accountNumber?: string) => {
      if (!accountNumber) {
        return
      }
      if (uiSelected?.includes(accountNumber)) {
        setUiSelected(uiSelected?.filter((x) => x !== accountNumber))
      } else {
        setUiSelected([...(uiSelected || []), accountNumber])
      }
    },
    [uiSelected]
  )
  const [createGroup, createGroupResult] =
    useRdot360_createCustomAccountGroupMutation()
  const onCreateGroup = useCallback(() => {
    const accountIds = uiSelected
      ?.map((x) => accountLookup?.[x]?.accountId)
      .filter(isNotNullOrUndefined)
    if (!accountIds || !selectedHouseholdId) {
      return
    }
    createGroup({
      name: groupName,
      householdId: selectedHouseholdId,
      accountIds
    })
  }, [accountLookup, createGroup, groupName, selectedHouseholdId, uiSelected])
  const { error, isSuccess, isLoading } = createGroupResult
  const header = useMemo(
    () =>
      isCreateSuccess
        ? `Successfully created '${groupName}' group`
        : 'Save as Custom Account Group',
    [groupName, isCreateSuccess]
  )
  useEffect(() => {
    if (isSuccess) {
      setIsCreateSuccess(true)
    }
  }, [isSuccess])
  const onViewGroup = useCallback(() => {
    setGrouping(['cag'])
    setSelectedIds(uiSelected || [])
    setSelectedAccountIds(uiSelected || [])
    onDismiss()
  }, [
    onDismiss,
    setGrouping,
    setSelectedAccountIds,
    setSelectedIds,
    uiSelected
  ])

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      minWidth={'450px'}
      dialogContentProps={{
        styles: {
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            height: 0,
            padding: 0
          },
          content: {
            maxHeight: '700px',
            minHeight: '350px',
            backgroundColor: '#F5F5F5'
          },
          inner: { padding: 0 }
        }
      }}
    >
      {isLoading && <SimpleOverlay />}
      <div
        css={(theme) => ({
          fontSize: theme.size.lg1,
          fontWeight: theme.fontWeights.demi,
          color: isCreateSuccess
            ? theme.colors.extraGreen1
            : theme.colors.primaryDarkBlue,
          height: '60px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 10px'
        })}
      >
        {header}
      </div>
      {isLoading && <IndeterminateProgressIndicator />}
      <div css={{ padding: '0 24px 24px' }}>
        {isCreateSuccess ? (
          <>
            <div
              css={(theme) => ({
                padding: '12px 0',
                fontWeight: theme.fontWeights.demi
              })}
            >
              With the following accounts selected
            </div>
            <div
              css={[
                {
                  padding: '12px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 5,
                  maxHeight: '400px',
                  minHeight: '200px'
                },
                advisoryModuleStyles.fancyScroll
              ]}
            >
              {accounts?.map((account, i) => (
                <div key={i}>{account?.AccountNickname || account?.id}</div>
              ))}
            </div>
            <div
              css={{
                padding: '20px 0 20px',
                display: 'flex',
                justifyContent: 'center',
                gridColumnGap: 5
              }}
            >
              <button
                onClick={onDismiss}
                css={[buttonStyles.secondary, { width: '150px' }]}
              >
                Stay on Page
              </button>
              <button
                onClick={onViewGroup}
                css={[
                  buttonStyles.primary,
                  {
                    width: '150px'
                  }
                ]}
              >
                View Group
              </button>
            </div>
          </>
        ) : (
          <>
            <div css={{ padding: '12px 0' }}>
              <label css={inputClasses.label}>Custom Account Group Name</label>
              <input
                css={[inputClasses.input]}
                placeholder="Enter group name"
                value={groupName}
                onChange={(ev) => setGroupName(ev.target.value)}
              />
            </div>
            <div css={{ padding: '12px 0' }}>
              <label css={inputClasses.label}>
                Accounts Selected ({uiSelected?.length})
              </label>
              <div
                css={(theme) => [
                  {
                    backgroundColor: theme.colors.primaryWhite,
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 5,
                    maxHeight: '400px',
                    minHeight: '200px'
                  },
                  advisoryModuleStyles.fancyScroll
                ]}
              >
                {accounts?.map((account, i) => (
                  <AccountRow
                    key={i}
                    account={account}
                    isSelected={
                      account?.id ? uiSelected?.includes(account?.id) : false
                    }
                    onChange={onCheckboxClicked}
                  />
                ))}
              </div>
            </div>
            {error ? (
              <SnackBar
                type="Failure"
                message={
                  (error as Error)?.message || 'An unknown error occurred'
                }
              />
            ) : null}
            <div
              css={{
                padding: '20px 0 20px',
                display: 'flex',
                justifyContent: 'end',
                gridColumnGap: 5
              }}
            >
              <button
                onClick={onDismiss}
                css={[buttonStyles.secondary, { width: '100px' }]}
              >
                Cancel
              </button>
              <button
                onClick={onCreateGroup}
                css={[
                  buttonStyles.primary,
                  {
                    width: '100px'
                  }
                ]}
                disabled={!groupName || (uiSelected?.length || 0) < 2}
              >
                Create
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}

const AccountRow: React.FC<{
  account: IAccount
  isSelected?: boolean
  onChange: (accountNumber: string) => void
}> = ({ account, isSelected, onChange }) => {
  const onCheckboxClicked = useCallback(() => {
    if (!account?.id) {
      return
    }
    onChange(account?.id)
  }, [account?.id, onChange])
  const { balanceLookup } = useRdot360BalancesContext()
  const accountBalances = useMemo(
    () => balanceLookup?.[account?.id || ''],
    [account?.id, balanceLookup]
  )
  const { netWorth, netWorthChange } = accountBalances || {}
  return (
    <div css={accountSelectorStyles.accountItem}>
      <AccountSelectorAccount
        checked={isSelected}
        onChange={onCheckboxClicked}
        nickname={account?.AccountNickname}
        accountNumber={account?.id}
        registrationDesc={account?.registrationDesc}
        registrationtype={account?.registrationtype}
        balance={netWorth}
        change={netWorthChange}
      />
    </div>
  )
}
