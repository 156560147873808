import { Row, flexRender } from '@tanstack/react-table'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { FC, useCallback } from 'react'
import { ITableHeaderColumnSize } from '../../../Investments/shared/ITableHeaderColumnSize'

const TableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            css={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}

const ChildRowTable: FC<{
  subRow: Row<any>
  subIndex: number
  rowIndex: number
  sizes: ITableHeaderColumnSize[]
}> = ({ subRow, rowIndex, subIndex, sizes }) => {
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <table css={[rdot360TableStyles.table, themedStyles.tableBody]}>
      <thead>
        <TableHeaderSizesRow sizes={sizes} />
      </thead>
      <tbody>
        <tr
          style={{
            backgroundColor: subIndex % 2 === 0 ? '#ffffff' : '#FAFAFA'
          }}
        >
          {subRow.getVisibleCells().map((subCell: any) =>
            subCell.id.includes('secName') ||
            subCell.id.includes('description') ? (
              <td key={subCell.id}>{''}</td>
            ) : (
              <td
                key={subCell.id}
                className={subCell.id}
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '8px 10px',
                  '&:last-of-type': {
                    paddingRight: '20px'
                  },
                  fontSize: 14,
                  fontWeight: 'bold'
                }}
                align={subCell.id.includes('account') ? 'left' : 'right'}
                valign={'top'}
                title={subCell.getValue()}
              >
                {flexRender(
                  subCell.column.columnDef.cell,
                  subCell.getContext()
                )}
              </td>
            )
          )}
        </tr>
        {subRow.subRows.map((item: any, itemIntex: any) => (
          <tr
            key={`${rowIndex}${subIndex + itemIntex}`}
            style={{
              backgroundColor: subIndex % 2 === 0 ? '#ffffff' : '#FAFAFA'
            }}
          >
            {item.getVisibleCells().map((subCell: any) =>
              subCell.id.includes('account') ||
              subCell.id.includes('secName') ||
              subCell.id.includes('description') ? (
                <td key={subCell.id}>{''}</td>
              ) : (
                <td
                  key={subCell.id}
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '4px 10px',
                    '&:last-of-type': {
                      paddingRight: '20px'
                    },
                    fontSize: 14
                  }}
                  align={'right'}
                  title={subCell.getValue()}
                >
                  {flexRender(
                    subCell.column.columnDef.cell,
                    subCell.getContext()
                  )}
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const DetailsRow: FC<{
  row: Row<any>
  rowIndex: number
  sizes: ITableHeaderColumnSize[]
}> = ({ row, rowIndex, sizes }) => {
  const hasSubRows = useCallback((row: any) => !!row.subRows, [])
  const isTotalRow = useCallback((value: any) => value === 'Total', [])
  const expanded = row.getIsExpanded()

  const getBackgroundColor = (row: Row<any>) => {
    const color = hasSubRows(row) ? '#ebf6fc' : '#ffffff'

    return isTotalRow(row.getVisibleCells()[0].getValue()) ? '#e3edf5' : color
  }

  const getHeight = (row: Row<any>) => {
    return isTotalRow(row.getVisibleCells()[0].getValue()) ? '50px' : '60px'
  }

  const geFontWeight = (row: Row<any>) => {
    return isTotalRow(row.getVisibleCells()[0].getValue()) || hasSubRows(row)
      ? 600
      : 500
  }

  return (
    <table
      css={{
        border: isTotalRow(row.getVisibleCells()[0].getValue())
          ? '1px solid #E6E6E6'
          : 'solid 1px #dddddd',
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: '100%'
      }}
    >
      <thead>
        <TableHeaderSizesRow sizes={sizes} />
        <tr>
          {row.getVisibleCells().map((cell: any, colIndex: number) => {
            const isDescription = cell.id.includes('description')
            const isAccount = cell.id.includes('account')
            if (isAccount) {
              return null
            }
            return (
              <td
                key={cell.id}
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 14,
                  textAlign: colIndex > 0 ? 'right' : 'left',
                  fontWeight: geFontWeight(row),
                  height: getHeight(row),
                  backgroundColor: getBackgroundColor(row),
                  cursor:
                    hasSubRows(row) && colIndex === 0 ? 'pointer' : 'default',
                  padding: hasSubRows(row)
                    ? '8px 10px 8px 8px'
                    : '8px 10px 8px 20px',
                  '&:last-of-type': {
                    paddingRight: '20px'
                  }
                }}
                colSpan={isDescription ? 2 : 1}
                title={cell.getValue() as string}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {expanded &&
          hasSubRows(row) &&
          row.subRows.map((subRow: any, subIndex: number) => (
            <tr
              key={`${rowIndex}${subIndex}`}
              css={{
                border: 'solid 1px #dddddd',
                height: subIndex === 0 ? '60px' : 40
              }}
            >
              <td
                key={`subRow${subIndex}`}
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '8px 10px',
                  fontSize: 14
                }}
                style={{ padding: 0 }}
                colSpan={subRow.getVisibleCells().length}
              >
                <ChildRowTable
                  subRow={subRow}
                  rowIndex={rowIndex}
                  sizes={sizes}
                  subIndex={subIndex}
                  key={subIndex}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default DetailsRow
