import { IColumn, Checkbox } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { AccountNumberCell } from 'features/Lists/shared/AccountNumberCell'
import { keyBy, uniq } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { DataTable } from 'shared/components/DataTable'
import { isNotNullOrEmpty } from 'shared/gaurds'

const defaultColumn: Partial<IColumn> = {
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

export const OptionsIncomeAndExpenseReportAccountList: React.FC<{
  accounts?: IAccount[]
  loading?: boolean
  selectedAccounts?: string[]
  onSelectionChanged?: (accounts?: string[]) => void
}> = ({ accounts, loading, selectedAccounts, onSelectionChanged }) => {
  const selectedAccountLookup = useMemo(
    () => keyBy(selectedAccounts),
    [selectedAccounts]
  )
  const onAccountCheckboxChange = useCallback(
    (id?: string, checked?: boolean) => {
      const selected = uniq([...(selectedAccounts || []), id])
        .filter(isNotNullOrEmpty)
        .filter((x) => checked || x !== id)
      onSelectionChanged?.(selected)
    },
    [onSelectionChanged, selectedAccounts]
  )

  const allAreSelected = useMemo(
    () =>
      !!selectedAccounts?.length &&
      selectedAccounts?.length === accounts?.length,
    [accounts, selectedAccounts]
  )

  const noneAreSelected = useMemo(
    () => !selectedAccounts?.length,
    [selectedAccounts]
  )

  const onHeaderCheckboxClicked = useCallback(() => {
    if (allAreSelected) {
      onSelectionChanged?.([])
      return
    }

    onSelectionChanged?.(
      accounts?.map((x) => x.id).filter(isNotNullOrEmpty) || []
    )
  }, [accounts, allAreSelected, onSelectionChanged])

  const columns: IColumn[] = useMemo(
    () => [
      {
        name: 'selection',
        key: 'seleection',
        minWidth: 20,
        maxWidth: 20,
        onRenderHeader: () => (
          <Checkbox
            checked={allAreSelected}
            indeterminate={!allAreSelected && !noneAreSelected}
            onChange={onHeaderCheckboxClicked}
          />
        ),
        onRender: (account: IAccount) => (
          <Checkbox
            checked={!!account.id && !!selectedAccountLookup[account.id]}
            onChange={(_, checked) =>
              account.id && onAccountCheckboxChange(account.id, checked)
            }
          />
        )
      },
      {
        ...defaultColumn,
        name: 'Account',
        key: 'account',
        maxWidth: 160,
        minWidth: 140,
        onRender: (account: IAccount) => (
          <AccountNumberCell account={account} menu={null} />
        )
      },
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        maxWidth: 160,
        minWidth: 140,
        onRender: ({ LegalEntityName }: IAccount) => (
          <TextCell>{LegalEntityName}</TextCell>
        )
      },
      {
        ...defaultColumn,
        maxWidth: 120,
        minWidth: 90,
        key: 'aus',
        className: 'align-right',
        headerClassName: 'align-right',
        name: 'Assets Under Supervision',
        onRender: ({ AccountKPIs }: IAccount) => (
          <USDCell value={AccountKPIs?.AccountTotal} fractionDigits={0} />
        )
      }
    ],
    [
      allAreSelected,
      noneAreSelected,
      onAccountCheckboxChange,
      onHeaderCheckboxClicked,
      selectedAccountLookup
    ]
  )

  return (
    <DataTable
      columns={columns}
      items={accounts}
      enableShimmer={loading}
      shimmerLines={5}
    />
  )
}
