import { GetGainLossesIncomeSummaryResponse } from '../../store/types'

export enum GainLossDescriptionEnum {
  realizedGains = 'Realized Gains',
  realizedLosses = 'Realized Losses',
  netRealized = 'Net Realized (current year)',
  unrealizedGains = 'Unrealized Gains',
  unrealizedLosses = 'Unrealized Losses',
  netUnrealized = 'Net Unrealized (cumulative)'
}

export const mapGainLossIncome = (
  data?: GetGainLossesIncomeSummaryResponse
) => {
  const realizedUnrealizedData = {
    realizedGainTableTypeList: data
      ? [
          {
            description: GainLossDescriptionEnum.realizedGains,
            longTerm: data?.RealizedLONGGAIN,
            shortTerm: data?.RealizedSHRTGAIN,
            total: data?.RealizedGAINTOTAL
          },
          {
            description: GainLossDescriptionEnum.realizedLosses,
            longTerm: data?.RealizedLONGLOSS,
            shortTerm: data?.RealizedSHRTLOSS,
            total: data?.RealizedLOSSTOTAL
          },
          {
            description: GainLossDescriptionEnum.netRealized,
            longTerm: data?.LNGGAINLOSS,
            shortTerm: data?.SHRTGAINLOSS,
            total: data?.GRANDPROFIT
          }
        ]
      : [],
    unrealizedGainTableTypeList: data
      ? [
          {
            description: GainLossDescriptionEnum.unrealizedGains,
            longTerm: data?.UnrealizedLONGGAIN,
            shortTerm: data?.UnrealizedSHRTGAIN,
            total: data?.UnrealizedGAINTOTAL
          },
          {
            description: GainLossDescriptionEnum.unrealizedLosses,
            longTerm: data?.UnrealizedLONGLOSS,
            shortTerm: data?.UnrealizedSHRTLOSS,
            total: data?.UnrealizedLOSSTOTAL
          },
          {
            description: GainLossDescriptionEnum.netUnrealized,
            longTerm: data?.UnrealizedLONGTOTAL,
            shortTerm: data?.UnrealizedSHRTTOTAL,
            total: data?.UnrealizedTOTAL
          }
        ]
      : []
  }
  return realizedUnrealizedData
}
