import { format } from 'date-fns'
import { IOfacScreenRecord, ScanStateEnum } from './types'

export const resultContextMenuItems = [
  {
    key: 'All',
    text: 'All'
  },
  {
    key: 'Closed',
    text: 'Closed'
  },
  {
    key: 'Good',
    text: 'Good'
  },
  {
    key: 'Not Good',
    text: 'Not Good'
  },
  {
    key: 'Need Review',
    text: 'Need Review'
  },
  {
    key: 'Failed',
    text: 'Failed'
  },
  {
    key: 'In Progress',
    text: 'In Progress'
  }
]

export const categoryContextMenuItems = [
  {
    key: 'All',
    text: 'All'
  },
  {
    key: 'Pending',
    text: 'Pending Transaction'
  },
  {
    key: 'Approved',
    text: 'Approved Transaction'
  }
]

export const filterComplianceByText = (
  records: IOfacScreenRecord[] | null | undefined,
  text: string | null | undefined
) => {
  let filtered: IOfacScreenRecord[] = []
  if (records && text && Array.isArray(records)) {
    const lowerCaseText = text.toLowerCase()
    filtered = records.filter((p) => {
      if (
        p?.accountno?.toLowerCase()?.startsWith(lowerCaseText) ||
        p?.trantype?.toLowerCase()?.startsWith(lowerCaseText) ||
        p?.accountownername?.toLowerCase()?.includes(lowerCaseText) ||
        `${p?.bankname} (${p?.bankcode})`
          .toLowerCase()
          ?.startsWith(lowerCaseText) ||
        format(new Date(p?.tranentrydate || ''), 'MM/dd/yyyy')?.startsWith(
          text
        ) ||
        p?.trancategory?.toLowerCase()?.startsWith(lowerCaseText) ||
        (
          p?.beneentityname ||
          `${p?.benenametitle || ''} ${p?.benefirstname || ''} ${
            p?.benemiddlename || ''
          } ${p?.benelastname || ''} ${p?.benenamesuffix || ''}`
        )
          .toLowerCase()
          ?.includes(lowerCaseText) ||
        p?.tranamount?.toFixed(2)?.toString()?.startsWith(text) ||
        p?.transourcetypecd?.toLowerCase()?.startsWith(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterOfacTransByReview = (
  ofacRecordList: IOfacScreenRecord[],
  resultType: string
): IOfacScreenRecord[] => {
  let filterByReview: IOfacScreenRecord[] = []
  switch (resultType) {
    case 'All': {
      filterByReview = ofacRecordList || []
      break
    }

    case 'Good': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.scandstate === ScanStateEnum.Good
        ) || []
      break
    }

    case 'Not Good': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.scandstate === ScanStateEnum.NotGood
        ) || []
      break
    }

    case 'Need Review': {
      filterByReview =
        ofacRecordList?.filter(
          (record) =>
            record?.isreviewneeded &&
            record?.scandstate === ScanStateEnum.NeedReview
        ) || []
      break
    }

    case 'Closed': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.scandstate === ScanStateEnum.Closed
        ) || []
      break
    }

    case 'Failed': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.scandstate === ScanStateEnum.Failed
        ) || []
      break
    }

    case 'In Progress': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.scandstate === ScanStateEnum.InProgress
        ) || []
      break
    }

    default: {
      filterByReview = ofacRecordList || []
    }
  }

  return filterByReview
}

export const filterOfacTransByCategory = (
  ofacRecordList: IOfacScreenRecord[],
  resultType: string
): IOfacScreenRecord[] => {
  let filterByReview: IOfacScreenRecord[] = []
  switch (resultType) {
    case 'All': {
      filterByReview = ofacRecordList || []
      break
    }

    case 'Pending': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record?.trancategory === 'Pending Tran'
        ) || []
      break
    }

    case 'Approved': {
      filterByReview =
        ofacRecordList?.filter(
          (record) => record.scandstate === 'Approved Tran'
        ) || []
      break
    }

    default: {
      filterByReview = ofacRecordList || []
    }
  }

  return filterByReview
}

export const getResultTypeText = (resultType: string) => {
  switch (resultType) {
    case 'InProgress': {
      return 'In Progress'
    }

    case 'NotGood': {
      return 'Not Good'
    }

    case 'NeedReview': {
      return 'Need Review'
    }

    default: {
      return resultType
    }
  }
}

export const onFormatDate = (date?: Date | undefined): string => {
  return date ? format(date, 'MM/dd/yyyy') : ''
}
