import { DetailsListLayoutMode, Stack } from '@fluentui/react'
import { throttle } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { IListsCellComponent } from '../components/ListsDataTable'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'
import { createConnectedListsDataTable } from './ListsDataTable'

export interface IConnectedListsInfiniteScrollDataTableComponentProps {
  layoutMode?: DetailsListLayoutMode
  stickyHeaderOffset?: number
}

export const createConnectedListsInfiniteScrollDataTable = <
  T extends SearchResponseType
>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>,
  cell: IListsCellComponent<T>
) => {
  const { dataActions } = listActions
  const { dataSelectors } = listSelectors
  const ListsDataTable = createConnectedListsDataTable(
    listActions,
    listSelectors,
    cell
  )
  const ListsInfiniteScrollDataTable: React.FC<
    IConnectedListsInfiniteScrollDataTableComponentProps
  > = ({
    layoutMode = DetailsListLayoutMode.fixedColumns,
    stickyHeaderOffset
  }) => {
    const dispatch = useDispatch()
    const loading = useSelector(dataSelectors.getIsLoading)
    const progress = useSelector(dataSelectors.getProgress)
    const totalCount = useSelector(dataSelectors.getTotalCount)

    useEffect(() => {
      if (loading || progress >= (totalCount || 0)) {
        return
      }

      const handleWindowScroll = throttle(() => {
        const scrollPosition = window.innerHeight + window.pageYOffset
        const pageHeight = document.body.offsetHeight
        if (scrollPosition >= pageHeight - 300) {
          dispatch(dataActions.loadMore())
        }
      }, 1000)

      window.addEventListener('scroll', handleWindowScroll)

      return () => {
        window.removeEventListener('scroll', handleWindowScroll)
        handleWindowScroll.cancel()
      }
    }, [dispatch, loading, progress, totalCount])

    return (
      <Stack>
        <Stack.Item grow={1}>
          <ListsDataTable
            stickyHeaderOffset={stickyHeaderOffset}
            layoutMode={layoutMode}
          />
        </Stack.Item>
        {loading && <LoadingComponent />}
      </Stack>
    )
  }

  return ListsInfiniteScrollDataTable
}
