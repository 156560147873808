import { IStyle, ITheme, makeStyles, Stack, Text } from '@fluentui/react'
import { startOfMonth, subMonths } from 'date-fns/fp'
import { groupBy, sumBy } from 'lodash'
import { flow } from 'lodash/fp'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ErrorComponent } from '../../../../shared/components/Error'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { getMostRecentRevenueDate } from '../../../../store/context/advisor'
import { SmallAccountList } from '../../../Accounts/SmallAccountList'
import { AUSSummaryComponent } from '../../../Charts/AUSSummary'
import { RevenueSummaryComponent } from '../../../Charts/RevenueSummary'
import { SmallClientList } from '../../../Clients/SmallClientList'
import { getIsAccountsLoading } from '../../store/householdAccountsFetch'
import { getIsClientsLoading } from '../../store/householdClientsFetch'
import { getIsHouseholdLoading } from '../../store/householdFetch'
import {
  getHouseholdDetail,
  getHouseholdDetailError,
  getHouseholdDetailUiHouseholdFilter,
  getHouseholdSortedAusHistory,
  getHouseholdSortedRevenueHistory
} from './store'

const chartRootStyle: IStyle = {
  maxWidth: '100%',
  minWidth: '350px',
  flexBasis: 0,
  height: '250px'
}

const useClasses = makeStyles((theme: ITheme) => {
  const detailSection = {
    border: `solid 1px ${theme.palette.neutralLight}`,
    boxShadow: theme.effects.elevation4,
    '& header': {
      backgroundColor: theme.palette.neutralLighterAlt,
      padding: '5px 10px'
    }
  }

  return {
    detailSection
  }
})

const MemoAUSSummaryComponent = memo(AUSSummaryComponent)
const MemoRevenueSummaryComponent = memo(RevenueSummaryComponent)

export const HouseholdDetail: React.FC = () => {
  const { clients, accounts } = useSelector(getHouseholdDetail) || {}
  const isHouseholdLoading = useSelector(getIsHouseholdLoading)
  const isClientsLoading = useSelector(getIsClientsLoading)
  const isAccountsLoading = useSelector(getIsAccountsLoading)
  const error = useSelector(getHouseholdDetailError)
  const ausHistory = useSelector(getHouseholdSortedAusHistory)
  const revenueHistory = useSelector(getHouseholdSortedRevenueHistory)
  const filterText = useSelector(getHouseholdDetailUiHouseholdFilter)
  const classes = useClasses()
  const dedupedClients = useMemo(() => {
    const groupedByLegalEntityId = groupBy(
      clients,
      ({ LegalEntityID }) => LegalEntityID || ''
    )

    return Object.values(groupedByLegalEntityId).map((items) => {
      const [first] = items
      return {
        ...first,
        revenueDet: {
          ttmrevenue: sumBy(items, (x) => x.revenueDet?.ttmrevenue || 0)
        },
        ClientKPI: {
          AumTotal: sumBy(items, (x) => x.ClientKPI?.AumTotal || 0)
        }
      }
    })
  }, [clients])

  const mostRecentRevenueDate = useSelector(getMostRecentRevenueDate)
  const minDate = useMemo(
    () =>
      flow(startOfMonth, subMonths(11))(mostRecentRevenueDate || Date.now()),
    [mostRecentRevenueDate]
  )
  const ausChartData = useMemo(
    () => ausHistory.filter((x) => x.date && x.date >= minDate),
    [minDate, ausHistory]
  )

  const revenueChartData = useMemo(
    () => revenueHistory?.filter((x) => x.date && x.date >= minDate),
    [minDate, revenueHistory]
  )

  const filteredClients = useMemo(
    () =>
      filterText && dedupedClients
        ? dedupedClients.filter(
            ({ LegalEntityName = '', Account = [] }) =>
              [LegalEntityName, ...Account]
                .join('|')
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) >= 0
          )
        : dedupedClients,
    [dedupedClients, filterText]
  )

  const orderedAndFilteredIndividuals = useMemo(
    () =>
      filteredClients?.filter(({ legalEntityType }) =>
        ['Individual', 'P'].includes(legalEntityType || '')
      ),

    [filteredClients]
  )

  const orderedAndFilteredOtherEntities = useMemo(
    () =>
      filteredClients?.filter(
        ({ legalEntityType }) =>
          !['Individual', 'P'].includes(legalEntityType || '')
      ),

    [filteredClients]
  )

  const filteredAccounts = useMemo(() => {
    return filterText && accounts
      ? accounts.filter(
          ({
            CustodyAccount = '',
            AccountNickname = '',
            Shortname = '',
            LegalEntityName = '',
            gfoCustodyAccount = ''
          }) =>
            [
              CustodyAccount,
              AccountNickname,
              Shortname,
              LegalEntityName,
              gfoCustodyAccount
            ]
              .filter((x) => x)
              .join('|')
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) >= 0
        )
      : accounts
  }, [accounts, filterText])

  return (
    <Stack styles={{ root: { minHeight: '500px' } }}>
      <Stack tokens={{ childrenGap: 20 }} styles={{ root: { paddingTop: 10 } }}>
        {error && <ErrorComponent errorMessage={error.message} />}
        <Stack horizontal={true} tokens={{ childrenGap: 10 }} wrap={true}>
          <Stack
            grow={1}
            tokens={{ childrenGap: 5 }}
            styles={{
              root: chartRootStyle
            }}
            className={classes.detailSection}
          >
            <header>
              <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
                AUS
              </Text>
            </header>
            {!isHouseholdLoading && (
              <MemoAUSSummaryComponent ausHistory={ausChartData} />
            )}
            {isHouseholdLoading && <LoadingComponent />}
          </Stack>
          <Stack
            grow={1}
            tokens={{ childrenGap: 5 }}
            styles={{
              root: chartRootStyle
            }}
            className={classes.detailSection}
          >
            <header>
              <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
                REVENUE
              </Text>
            </header>
            {!isHouseholdLoading && (
              <MemoRevenueSummaryComponent
                chartType="column"
                revenueHistory={revenueChartData}
                pivotProperty="category"
                chartValueType="revenue"
              />
            )}
            {isHouseholdLoading && <LoadingComponent />}
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }} className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Clients
            </Text>
          </header>
          <SmallClientList
            clients={orderedAndFilteredIndividuals}
            loading={isClientsLoading}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 5 }} className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Other Entities
            </Text>
          </header>
          <SmallClientList
            clients={orderedAndFilteredOtherEntities}
            loading={isClientsLoading}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 5 }} className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Accounts
            </Text>
          </header>
          <SmallAccountList
            accounts={filteredAccounts}
            loading={isAccountsLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
