import { MessageBar, Stack } from '@fluentui/react'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  notificationActions,
  getNotifications,
  useNotificationFeatureStore
} from './store'

export const NotificationBanner: React.FC = () => {
  useNotificationFeatureStore()
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)
  const onNotificationDismiss = useCallback(
    (id: string) => {
      dispatch(notificationActions.remove(id))
    },
    [dispatch]
  )
  return (
    <>
      {!!notifications?.length && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 10000,
            right: 0,
            left: 0
          }}
        >
          <Stack styles={{ root: { maxWidth: '1000px', margin: '0 auto' } }}>
            {notifications.map(({ message, type, id, detailLink }, i) => (
              <MessageBar
                key={id || i}
                messageBarType={type}
                onDismiss={() => id && onNotificationDismiss(id)}
                dismissButtonAriaLabel="Close"
              >
                <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                  <span>{message}</span>
                  {detailLink && <Link to={detailLink}>View Detail</Link>}
                </Stack>
              </MessageBar>
            ))}
          </Stack>
        </div>
      )}
    </>
  )
}
