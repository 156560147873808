import { useTheme } from '@emotion/react'
import { USD } from 'shared/components/Formatting'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import { TodaysChange } from '../../shared/TodaysChange'
import { Icon } from '../Icons/Icon'
import { accountSelectorStyles } from './styles'

export const AccountSelectorGroup: React.FC<{
  title?: string
  checked?: boolean
  indeterminate?: boolean
  onChange: () => void
  selectedSubrowsCount: number
  subrowsCount: number
  groupBalance?: number
  groupBalanceChange?: number
  onDeleteGroup?: () => void
  isExpanded?: boolean
  toggleExpanded: () => void
}> = ({
  title,
  checked,
  indeterminate,
  onChange,
  selectedSubrowsCount,
  subrowsCount,
  groupBalance,
  groupBalanceChange,
  onDeleteGroup,
  isExpanded,
  toggleExpanded
}) => {
  const theme = useTheme()
  return (
    <div css={[accountSelectorStyles.flexRow, { columnGap: '5px' }]}>
      <div
        css={[
          accountSelectorStyles.flexColumn,
          {
            justifyContent: 'space-between'
          }
        ]}
      >
        <div
          css={[
            accountSelectorStyles.groupTitle,
            {
              textAlign: 'justify',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-all'
            }
          ]}
          title={title}
        >
          {title}
        </div>
        <div
          css={[accountSelectorStyles.flexRow, accountSelectorStyles.itemTitle]}
        >
          <IndeterminateCheckbox
            type="checkbox"
            css={[accountSelectorStyles.checkbox]}
            checked={checked || false}
            indeterminate={indeterminate || false}
            onChange={onChange}
          />
          <div style={{ marginLeft: '5px' }}>
            {selectedSubrowsCount} / {subrowsCount} selected
          </div>
        </div>
      </div>
      <div
        css={[
          accountSelectorStyles.flexColumn,
          {
            flexGrow: 1,
            '& > div': {
              marginLeft: 'auto',
              marginBottom: '3px',
              '&:last-of-type': { marginBottom: 0 }
            }
          }
        ]}
      >
        <div css={accountSelectorStyles.groupBalance}>
          {groupBalance != null ? (
            <USD
              value={groupBalance}
              fractionDigits={0}
              currencySign="standard"
            />
          ) : (
            <>--</>
          )}
        </div>
        <div
          css={[
            accountSelectorStyles.itemBalanceChange,
            groupBalanceChange && groupBalanceChange > 0
              ? accountSelectorStyles.positiveBalanceChange
              : undefined,
            { display: 'flex' }
          ]}
        >
          {!!groupBalanceChange && <TodaysChange />}
          {groupBalanceChange != null ? (
            <USD
              value={groupBalanceChange}
              fractionDigits={0}
              currencySign="standard"
            />
          ) : (
            <>--</>
          )}
        </div>
        <div css={[accountSelectorStyles.flexRow, { columnGap: 5 }]}>
          {onDeleteGroup && (
            <Icon
              type="Delete"
              width={16}
              height={16}
              color={theme.colors.primaryDarkBlue}
              onClick={onDeleteGroup}
            />
          )}
          <div
            css={[
              accountSelectorStyles.chevron,
              {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '1px'
              }
            ]}
            onClick={toggleExpanded}
          >
            <div css={{ width: '7px', height: '7px' }}>
              <Icon type={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
