import { ExpandedState, createColumnHelper } from '@tanstack/react-table'
import { keyBy, sum } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import assetTaxonomy from '../../../../../../metadata/asset-taxonomy.json'
import {
  WidgetTable,
  USDCell,
  PercentCell
} from '../../features/WidgetTable/WidgetTable'
import {
  investmentsTableColumnNames,
  useInvestmentsTableStore
} from '../../modules/Investments/features/InvestmentsTable'
import { ICategoryPostionResponseValue } from '../../store/holdingsApi/ICategoryPostitionResponse'
import { HeaderContainer } from '../shared/DetailTables/HeaderContainer'

export const AssetSummaryTable: React.FC<{
  data?: ICategoryPostionResponseValue[]
  isLoading?: boolean
  isUninitialized?: boolean
  value?: string
  selectedType: string
}> = ({ data, isUninitialized, isLoading, selectedType }) => {
  const navigate = useNavigate()
  const { setGrouping, setDefaultExpanded } = useInvestmentsTableStore()
  const onClick = useCallback(
    (value?: string) => {
      if (selectedType === 'L1') {
        const parameter: ExpandedState = {
          [`${investmentsTableColumnNames.assetClass}:${value}`]: true
        }
        navigate('../investments')
        setDefaultExpanded(parameter)
        setGrouping([
          investmentsTableColumnNames.assetClass,
          investmentsTableColumnNames.secid
        ])
      }
    },
    [navigate, selectedType, setDefaultExpanded, setGrouping]
  )
  const columnHelper = createColumnHelper<ICategoryPostionResponseValue>()

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ assetcat }) =>
          assetcat === 'Cash' ? 'Cash and Equivalents' : assetcat,
        {
          id: 'assetcat',
          cell: (props) => (
            <div
              css={{
                display: 'flex'
              }}
              title={props.getValue()}
            >
              <div
                css={{
                  width: '10px',
                  height: '10px',
                  backgroundColor:
                    props.getValue() === 'Total'
                      ? 'none'
                      : `#${
                          keyBy(assetTaxonomy, (x) => x.description)[
                            props.getValue() || 'Others'
                          ]?.color ||
                          keyBy(assetTaxonomy, (x) => x.description)['Others']
                            ?.color
                        }`,
                  marginTop: '2px'
                }}
              />
              <div
                css={{
                  color: '#1D679D',
                  cursor: 'pointer',
                  paddingLeft: '15px'
                }}
                onClick={() => onClick(props.getValue())}
              >
                {props.getValue()}
              </div>
            </div>
          ),
          header: () => (
            <div css={{ textAlign: 'left' }}>
              {selectedType === 'L1' ? 'Asset Class' : 'Product Type'}
            </div>
          ),
          footer: () => <div css={{ fontWeight: 'bold' }}>Total</div>,
          size: 200
        }
      ),
      columnHelper.accessor(({ mktval }) => mktval, {
        id: 'mktval',
        cell: (props) => <USDCell value={props.getValue()} />,
        header: (props) => (
          <HeaderContainer
            title="Value"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        footer: (props) => {
          const total = sum(
            props.table
              .getRowModel()
              .rows.map(
                ({ getValue }) => getValue<number>(props.column.id) || 0
              )
          )
          return (
            <div css={{ fontWeight: 'bold' }}>
              {total !== 0 ? <USDCell value={total} /> : ''}
            </div>
          )
        },
        size: 100
      }),
      columnHelper.accessor(({ mktvalpct }) => mktvalpct, {
        id: 'mktvalpct',
        cell: (props) =>
          props.getValue() !== undefined ? (
            <PercentCell value={props.getValue()} />
          ) : (
            ''
          ),
        header: (props) => (
          <HeaderContainer
            title="Portfolio %"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        size: 100
      })
    ],
    [columnHelper, onClick, selectedType]
  )

  return (
    <WidgetTable
      data={data}
      columns={columns}
      isUninitialized={isUninitialized}
      isLoading={isLoading}
      isFooter={!isUninitialized && data && data?.length > 0}
    />
  )
}
