import { push } from 'connected-react-router'
import { get } from 'lodash'
import { getLocation } from 'shared/services/dom'
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../../../../shared/services/environment/IEnvironmentConfiguration'
import { exportDataToExcel } from '../../../../../shared/xlsx'
import { tryAcquireAccessToken } from '../../../../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../../../../store/system'
import { downloadFile } from '../../../../Reports/features/Dashboard/Utilities'
import {
  fetchRetirementProductPlansService,
  fetchRetirementPlanTransactionsService,
  fetchRetirementPlanTransactionsByClientService,
  updateRetirementCashReceiptsService,
  fetchProjectedAmountByPlanIdService,
  updateProjectedAmountByPlanService,
  downloadD365ExcelService,
  fetchPlanProvidersService,
  insertUpdatePlanProviderService,
  viewStatementService,
  fetchPlansToDistributeCashService,
  updateCashReceiptToPlansService,
  uploadProductDocumentService,
  fetchPlansByPlanProviderService,
  closeMonthService,
  fetchRetirementPlanReportService,
  updatePlanByproviderService,
  fetchRetirementPlanAllocationsService,
  fetchLookUpValuesService
} from '../api/RetirementProductService'
import {
  fetchRetirementPlanTransactionsAction,
  fetchRetirementProductPlans,
  retirementProductNavigationActions,
  updateRetirementCashReceiptActions,
  fetchRetirementPlanTransactionsByClientActions,
  setSelectedRetirementPlan,
  fetchProjectedAmountByPlansActions,
  updateProjectedAmountByPlansActions,
  downloadD365ExcelActions,
  fetchPlanProvidersActions,
  updatePlanProviderActions,
  viewStatementActions,
  exportRetirementPlansToExcelActions,
  fetchPlansToDistributeCashActions,
  updateCashReceiptToPlanActions,
  uploadProductDocumentActions,
  fetchPlansByProviderActions,
  closeMonthActions,
  fetchRetirementPlanReportActions,
  updatePlanPlanByProviderActions,
  exportReportRetirementPlansToExcelActions,
  fetchRetirementPlanAllocationActions,
  getLookUPFieldsActions
} from './actions'
import {
  getPlansToDistributeCashState,
  getProjectedAmountByPlanState,
  getRetirementProductPlanTransactionsState,
  getSelectedMonthYear,
  getSelectedRetirementPlan,
  getSelectedRetirementPlanType
} from './selectors'
import { ICashAllocation, ICashAllocationWithIds } from './types'
import {
  getChangedCashAllocations,
  getMonthYearStringFromSelectedMonth
} from './utility'

function* handleFetchRetirementProductPayments() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = yield select(getSelectedMonthYear)
    const retirementPlanType: string = yield select(
      getSelectedRetirementPlanType
    )
    const data = yield* call(() =>
      fetchRetirementProductPlansService(monthYear, retirementPlanType, options)
    )
    yield put(fetchRetirementProductPlans.success(data || []))
  } catch (e: any) {
    yield put(fetchRetirementProductPlans.failure(e))
  }
}

function* handleNavigateToRetirementTransaction(
  action: ReturnType<
    typeof retirementProductNavigationActions.navigatetoRetirementTransaction
  >
) {
  const payload = action.payload
  yield put(setSelectedRetirementPlan.selectedPlan(payload))
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/transactions/id/${payload?.retirementplanid}`
  yield put(push({ pathname: path }))
}

function* handleNavigateToRetirementTransactionByClient(
  action: ReturnType<
    typeof retirementProductNavigationActions.naviagteToRetirementTransactionByClient
  >
) {
  const payload = action.payload
  yield put(setSelectedRetirementPlan.selectedPlan(payload))
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/transactions/client/${payload?.clientname}`
  yield put(push({ pathname: path }))
}

function* handleNavigateToProjectedAmountbyPlan(
  action: ReturnType<
    typeof retirementProductNavigationActions.navigateToProjectedAmountByPlan
  >
) {
  const payload = action.payload
  yield put(setSelectedRetirementPlan.selectedPlan(payload))
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/projectedamount/plan/${payload?.retirementplanid}`
  yield put(push({ pathname: path }))
}

function* handleNavigateToRetirementPlan() {
  const path = `/products/retirement`
  yield put(push({ pathname: path }))
}

function* handleFetchRetirementProductPlanTransactions(
  action: ReturnType<typeof fetchRetirementPlanTransactionsAction.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchRetirementPlanTransactionsService(action.payload, options)
    )

    yield put(fetchRetirementPlanTransactionsAction.success(data?.[0] || {}))
  } catch (e: any) {
    yield put(fetchRetirementPlanTransactionsAction.failure(e))
  }
}

function* handleFetchRetirementProductPlanTransactionsByClient(
  action: ReturnType<
    typeof fetchRetirementPlanTransactionsByClientActions.request
  >
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchRetirementPlanTransactionsByClientService(action.payload, options)
    )

    yield put(
      fetchRetirementPlanTransactionsByClientActions.success(data || [])
    )
  } catch (e: any) {
    yield put(fetchRetirementPlanTransactionsByClientActions.failure(e))
  }
}

function* handleUpdateCashReceipts(
  action: ReturnType<typeof updateRetirementCashReceiptActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const selectedPlan = yield* select(getSelectedRetirementPlan)
    const selectedMonthYear = yield* select(getSelectedMonthYear)

    //filter only updated onces
    const { cashreceipts } =
      (yield* select(getRetirementProductPlanTransactionsState)) || {}

    // updated code to identify the changed cash allocations
    let changedCashAllocations: ICashAllocation[] = []
    action.payload?.map((cashReceipt) => {
      const originalCashReceiptRecord = cashreceipts?.filter(
        (x) => x.cashreceiptid === cashReceipt.cashreceiptid
      )?.[0]
      const changedAllocationsByCashReceipt =
        getChangedCashAllocations(
          cashReceipt?.cashallocations || [],
          originalCashReceiptRecord?.cashallocations || []
        ) || []

      changedCashAllocations = changedCashAllocations.concat(
        changedAllocationsByCashReceipt
      )
    })

    yield* call(() =>
      updateRetirementCashReceiptsService(
        selectedPlan,
        changedCashAllocations,
        options
      )
    )

    yield put(
      fetchRetirementPlanTransactionsAction.request({
        planid: selectedPlan?.retirementplanid || '',
        selectedPeriod: selectedMonthYear || ''
      })
    )
  } catch (e: any) {
    yield put(updateRetirementCashReceiptActions.failure(e))
  }
}

function* handleFetchProjectedAmountByPlan(
  action: ReturnType<typeof fetchProjectedAmountByPlansActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchProjectedAmountByPlanIdService(action.payload, options)
    )
    yield put(fetchProjectedAmountByPlansActions.success(data || []))
  } catch (e: any) {
    yield put(fetchProjectedAmountByPlansActions.failure(e))
  }
}

function* handleUpdateProjectedAmountByPlan(
  action: ReturnType<typeof updateProjectedAmountByPlansActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const { projectedAmounts } =
      (yield* select(getProjectedAmountByPlanState)) || {}
    const filteredData = action.payload?.filter(
      (x) =>
        !projectedAmounts?.some(
          (y) =>
            y.amount === x.amount &&
            y.noncompensableamount === x.noncompensableamount &&
            y.description === x.description &&
            x.projecteddate === y.projecteddate
        )
    )

    yield* call(() => updateProjectedAmountByPlanService(filteredData, options))
    yield put(updateProjectedAmountByPlansActions.success())
    const selectedPlan = yield* select(getSelectedRetirementPlan)
    yield put(
      fetchProjectedAmountByPlansActions.request(
        selectedPlan?.retirementplanid || ''
      )
    )
  } catch (e: any) {
    yield put(updateProjectedAmountByPlansActions.failure(e))
  }
}

function* handleDownloadD365Excel() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = yield select(getSelectedMonthYear)
    const data = yield* call(() => downloadD365ExcelService(monthYear, options))

    downloadFile(data || '', 'D356-Retirement', '.xlsx')
    yield put(downloadD365ExcelActions.success())
  } catch (e: any) {
    yield put(downloadD365ExcelActions.failure(e))
  }
}

function* handleFetchPlanProviders() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => fetchPlanProvidersService(options))
    yield put(fetchPlanProvidersActions.success(data || []))
  } catch (e: any) {
    yield put(fetchPlanProvidersActions.failure(e))
  }
}

function* handleUpdatePlanProvider(
  action: ReturnType<typeof updatePlanProviderActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() => insertUpdatePlanProviderService(action.payload, options))
    yield put(updatePlanProviderActions.success())
    yield put(fetchPlanProvidersActions.request())
  } catch (e: any) {
    yield put(updatePlanProviderActions.failure(e))
  }
}

function* handleViewStatement(
  action: ReturnType<typeof viewStatementActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      viewStatementService(action.payload, options)
    )

    yield put(viewStatementActions.success())
    const pdfWindow = window.open('', 'statementWindow')
    pdfWindow?.document.write(
      `<iframe width='100%' height='100%' src=
        ${encodeURI(data)}
        ></iframe>`
    )
  } catch (e: any) {
    yield put(viewStatementActions.failure(e))
  }
}

function* handleExportRetirementPlansToExcel(
  action: ReturnType<typeof exportRetirementPlansToExcelActions.request>
) {
  try {
    const filename = 'Retirement_Plans.xlsx'
    const wsName = 'Plans'

    const { plans, columns } = action.payload

    const data = plans?.map((plan) => {
      const excelPlan = {
        ...plan,
        accruedamount: plan.accrualamount,
        projectedamount: plan.projectedamount || '',
        plansponcerstate: plan?.plansponser?.state || ''
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.fieldName !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportRetirementPlansToExcelActions.success())
  } catch (e: any) {
    yield put(exportRetirementPlansToExcelActions.failure(e))
  }
}

function* handleFetchPlansToDistributeCash(
  action: ReturnType<typeof fetchPlansToDistributeCashActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const selectedPlan = yield* select(getSelectedRetirementPlan)
    const selectedMonthYear = yield* select(getSelectedMonthYear)

    const data = yield* call(() =>
      fetchPlansToDistributeCashService(
        action.payload,
        selectedPlan,
        selectedMonthYear,
        options
      )
    )
    yield put(fetchPlansToDistributeCashActions.success(data || []))
  } catch (e: any) {
    yield put(fetchPlansToDistributeCashActions.failure(e))
  }
}

function* handleUpdateCashReceiptToPlan(
  action: ReturnType<typeof updateCashReceiptToPlanActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const selectedPlan = yield* select(getSelectedRetirementPlan)

    //filter only updated onces
    const { plans } = (yield* select(getPlansToDistributeCashState)) || {}

    // updated code to identify the changed cash allocations
    let changedCashAllocations: ICashAllocationWithIds[] = []
    action.payload?.plans?.map((plan) => {
      const originalPlanRecord = plans?.filter(
        (x) => x.retirementplanid === plan.retirementplanid
      )?.[0]
      const changedAllocationsByPlan =
        getChangedCashAllocations(
          plan?.cashallocations || [],
          originalPlanRecord?.cashallocations || []
        ) || []

      const changedAllocationsByPlanWithId = changedAllocationsByPlan.map(
        (x) =>
          ({
            ...x,
            retirementplanid: originalPlanRecord?.retirementplanid
          } as ICashAllocationWithIds)
      )

      changedCashAllocations = changedCashAllocations.concat(
        changedAllocationsByPlanWithId
      )
    })

    yield* call(() =>
      updateCashReceiptToPlansService(
        action.payload.cashReceipt,
        selectedPlan,
        changedCashAllocations,
        options
      )
    )

    yield put(updateCashReceiptToPlanActions.success())
    yield put(
      fetchPlansToDistributeCashActions.request(action.payload.cashReceipt)
    )
  } catch (e: any) {
    yield put(updateCashReceiptToPlanActions.failure(e))
  }
}

function* handleUploadProductDoc(
  action: ReturnType<typeof uploadProductDocumentActions.uploadDoc>
) {
  const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
    getRockefellerApiConfig
  )
  const token = yield* call(tryAcquireAccessToken, rockefellerApiConfig.scopes)
  const options: IApiOptions = {
    apiRoot: rockefellerApiConfig.root,
    accessToken: token
  }

  const { onUploadSuccess, onUploadFail, onUploadProgress, productDoc } =
    action.payload
  try {
    yield call(
      uploadProductDocumentService,
      productDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(productDoc.file)
  } catch (e) {
    onUploadFail(productDoc.file)
  }
}

function* handleFetchPlansByProvider(
  action: ReturnType<typeof fetchPlansByProviderActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchPlansByPlanProviderService(action.payload, options)
    )
    yield put(
      fetchPlansByProviderActions.success({
        planproviderId: action.payload.planproviderid || 0,
        plans: data || []
      })
    )
  } catch (e: any) {
    yield put(
      fetchPlansByProviderActions.failure({
        planproviderId: action.payload.planproviderid || 0,
        error: e
      })
    )
  }
}

function* handleCloseMonth(
  action: ReturnType<typeof closeMonthActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() => closeMonthService(action.payload, options))
    yield put(
      closeMonthActions.success(
        `Month ${getMonthYearStringFromSelectedMonth(
          action.payload
        )} has been closed successfully`
      )
    )
  } catch (e: any) {
    yield put(closeMonthActions.failure(e))
  }
}

function* handleFetchRetirementPlanRpt(
  action: ReturnType<typeof fetchRetirementPlanReportActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const { period, producttype } = action.payload

    const data = yield* call(() =>
      fetchRetirementPlanReportService(period, producttype, options)
    )
    yield put(fetchRetirementPlanReportActions.success(data || []))
  } catch (e: any) {
    yield put(fetchRetirementPlanReportActions.failure(e))
  }
}

function* handleUpdatePlanByProvider(
  action: ReturnType<typeof updatePlanPlanByProviderActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() =>
      updatePlanByproviderService(
        action.payload.plan,
        action.payload.provider,
        options
      )
    )
    yield put(
      updatePlanPlanByProviderActions.success(
        `Plan ${action.payload.plan?.planname} has been closed successfully`
      )
    )
    yield put(fetchPlansByProviderActions.request(action.payload.provider))
  } catch (e: any) {
    yield put(updatePlanPlanByProviderActions.failure(e))
  }
}

function* handleExportReportRetirementPlansToExcel(
  action: ReturnType<typeof exportReportRetirementPlansToExcelActions.request>
) {
  try {
    const filename = 'Retirement_Plans.xlsx'
    const wsName = 'Plans'

    const { plans, columns } = action.payload

    const data = plans?.map((plan) => {
      const excelPlan = {
        ...plan,
        accruedamount: plan.accrualamount,
        projectedamount: plan.projectedamount || '',
        plansponcerstate: plan?.plansponser?.state || '',
        jan: `${plan?.cashallocations?.[0]?.accruedamount || 0}/${
          plan?.cashallocations?.[0]?.cashallocatedamt || 0
        }`,
        feb: `${plan?.cashallocations?.[1]?.accruedamount || 0}/${
          plan?.cashallocations?.[1]?.cashallocatedamt || 0
        }`,
        mar: `${plan?.cashallocations?.[2]?.accruedamount || 0}/${
          plan?.cashallocations?.[2]?.cashallocatedamt || 0
        }`,
        apr: `${plan?.cashallocations?.[3]?.accruedamount || 0}/${
          plan?.cashallocations?.[3]?.cashallocatedamt || 0
        }`,
        may: `${plan?.cashallocations?.[4]?.accruedamount || 0}/${
          plan?.cashallocations?.[4]?.cashallocatedamt || 0
        }`,
        jun: `${plan?.cashallocations?.[5]?.accruedamount || 0}/${
          plan?.cashallocations?.[5]?.cashallocatedamt || 0
        }`,
        jul: `${plan?.cashallocations?.[6]?.accruedamount || 0}/${
          plan?.cashallocations?.[6]?.cashallocatedamt || 0
        }`,
        aug: `${plan?.cashallocations?.[7]?.accruedamount || 0}/${
          plan?.cashallocations?.[7]?.cashallocatedamt || 0
        }`,
        sep: `${plan?.cashallocations?.[8]?.accruedamount || 0}/${
          plan?.cashallocations?.[8]?.cashallocatedamt || 0
        }`,
        oct: `${plan?.cashallocations?.[9]?.accruedamount || 0}/${
          plan?.cashallocations?.[9]?.cashallocatedamt || 0
        }`,
        nov: `${plan?.cashallocations?.[10]?.accruedamount || 0}/${
          plan?.cashallocations?.[10]?.cashallocatedamt || 0
        }`,
        dec: `${plan?.cashallocations?.[11]?.accruedamount || 0}/${
          plan?.cashallocations?.[11]?.cashallocatedamt || 0
        }`
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(columns.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportReportRetirementPlansToExcelActions.success())
  } catch (e: any) {
    yield put(exportReportRetirementPlansToExcelActions.failure(e))
  }
}

function* handleFetchRetirementProductPlanAllocations(
  action: ReturnType<typeof fetchRetirementPlanAllocationActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchRetirementPlanAllocationsService(action.payload, options)
    )

    yield put(fetchRetirementPlanAllocationActions.success(data?.[0] || {}))
  } catch (e: any) {
    yield put(fetchRetirementPlanAllocationActions.failure(e))
  }
}

function* handleFetchLookUpFieldValues() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => fetchLookUpValuesService(options))

    yield put(getLookUPFieldsActions.success(data || {}))
  } catch (e: any) {
    yield put(getLookUPFieldsActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchRetirementProductPlans.request,
      handleFetchRetirementProductPayments
    ),
  () =>
    takeLatest(
      retirementProductNavigationActions.navigatetoRetirementTransaction,
      handleNavigateToRetirementTransaction
    ),
  () =>
    takeLatest(
      retirementProductNavigationActions.naviagteToRetirementTransactionByClient,
      handleNavigateToRetirementTransactionByClient
    ),
  () =>
    takeLatest(
      retirementProductNavigationActions.navigateToProjectedAmountByPlan,
      handleNavigateToProjectedAmountbyPlan
    ),
  () =>
    takeLatest(
      retirementProductNavigationActions.navigateToRetirementPlan,
      handleNavigateToRetirementPlan
    ),
  () =>
    takeLatest(
      fetchRetirementPlanTransactionsAction.request,
      handleFetchRetirementProductPlanTransactions
    ),

  () =>
    takeLatest(
      updateRetirementCashReceiptActions.request,
      handleUpdateCashReceipts
    ),
  () =>
    takeLatest(
      fetchRetirementPlanTransactionsByClientActions.request,
      handleFetchRetirementProductPlanTransactionsByClient
    ),
  () =>
    takeLatest(
      fetchProjectedAmountByPlansActions.request,
      handleFetchProjectedAmountByPlan
    ),
  () =>
    takeLatest(
      updateProjectedAmountByPlansActions.request,
      handleUpdateProjectedAmountByPlan
    ),
  () => takeLatest(downloadD365ExcelActions.request, handleDownloadD365Excel),
  () => takeLatest(fetchPlanProvidersActions.request, handleFetchPlanProviders),
  () => takeLatest(updatePlanProviderActions.request, handleUpdatePlanProvider),
  () => takeLatest(viewStatementActions.request, handleViewStatement),
  () =>
    takeLatest(
      exportRetirementPlansToExcelActions.request,
      handleExportRetirementPlansToExcel
    ),
  () =>
    takeLatest(
      fetchPlansToDistributeCashActions.request,
      handleFetchPlansToDistributeCash
    ),
  () =>
    takeLatest(
      updateCashReceiptToPlanActions.request,
      handleUpdateCashReceiptToPlan
    ),
  () =>
    takeEvery(uploadProductDocumentActions.uploadDoc, handleUploadProductDoc),
  () =>
    takeEvery(fetchPlansByProviderActions.request, handleFetchPlansByProvider),

  () => takeLatest(closeMonthActions.request, handleCloseMonth),
  () =>
    takeLatest(
      fetchRetirementPlanReportActions.request,
      handleFetchRetirementPlanRpt
    ),
  () =>
    takeLatest(
      updatePlanPlanByProviderActions.request,
      handleUpdatePlanByProvider
    ),
  () =>
    takeLatest(
      exportReportRetirementPlansToExcelActions.request,
      handleExportReportRetirementPlansToExcel
    ),
  () =>
    takeLatest(
      fetchRetirementPlanAllocationActions.request,
      handleFetchRetirementProductPlanAllocations
    ),

  () => takeLatest(getLookUPFieldsActions.request, handleFetchLookUpFieldValues)
]
