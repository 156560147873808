import axios from 'axios'
import { IDragDropFile } from '../../../../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IClientSettings,
  IClientSettingsDocReq,
  IClientSettingsDocRes,
  IPaymentClient,
  IUpdatePaymentAccountReq
} from '../store/types'

export const fetchClientSettingService = (
  email: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/getMatrixinfo?rcmUser=${email}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IClientSettings[]>(url, null, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchClientSettingDocService = (
  req: IClientSettingsDocReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/GetLOADoc`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IClientSettingsDocRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchClientListService = (
  req: IClientSettings,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Accounting/GetAccountParty?accountno=${req.payAcct}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPaymentClient[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updatePaymentAccountService = (
  req: IUpdatePaymentAccountReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/PaymentAccount/Update`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadLoaDocService = (
  file: IDragDropFile,
  clientid: number,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/payment/api/payment/UploadLOADoc`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('paymentloaContent', file.file)
  data.append('isforpaymentloa', 'true')
  data.append('clientid', clientid?.toString())

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, file)
          }
        : undefined
    })
    .then((res) => res.data)
}
