import { combineReducers } from 'redux'
import { createTaskPanelReducer } from './createTaskPanel'
import { taskFilterCalloutReducer } from './taskFilterCallout'
import { taskPanelReducer } from './taskPanel'

export const taskReducer = combineReducers({
  taskPanel: taskPanelReducer,
  createTaskPanel: createTaskPanelReducer,
  taskFilterCallout: taskFilterCalloutReducer
})
