import { call, select } from 'typed-redux-saga'
import { IServicePrincipal } from '../../../api/graph'
import { isNotNullOrEmpty, isNotNullOrFalse } from '../../../shared/gaurds'
import { navigate as nav } from '../../../shared/services/navigation'
import { getEnvironmentConfigSites } from '../../system'
import {
  getPreferenceUserPreferences,
  getRdotUsername
} from '../../user/selectors'
import { getApplication, getTeamURL } from './graph'

export const launchClientPortal = function* (
  wealthscapeId?: string,
  partyId?: string,
  path?: string,
  additionalParams?: Record<string, string>
) {
  const sites = yield* select(getEnvironmentConfigSites)
  const username = yield* select(getRdotUsername)

  if (!sites || !username) {
    throw new Error('Invalid state: sites or username is undefined')
  }

  const query = [
    'simulate=true',
    `loginId=${wealthscapeId}`,
    `simulationUser=${username}`,
    !!partyId && `pid=${partyId}`,
    ...Object.entries(additionalParams || {}).map(
      ([key, value]) => `${key}=${value}`
    )
  ]
    .filter(isNotNullOrFalse)
    .join('&')

  yield call(
    navigate,
    `${[sites.clientonline, path].filter(isNotNullOrEmpty).join('/')}?${query}`
  )
}

export const navigate = function* (
  url?: string | undefined,
  target?: string | undefined
) {
  const preferences = yield* select(getPreferenceUserPreferences)
  yield call(nav, url, {
    target,
    openInNewWindow: preferences?.navigateInNewWindow
  })
}

export const launchTeams = function* (email: string) {
  try {
    const teamUrl = yield* call(getTeamURL, email)
    yield call(launchTeamsUrl, teamUrl)
  } catch (e) {
    alert(`Failed to launch teams for client with email: ${email}`)
  }
}

export const launchTeamsUrl = function (teamUrl: string) {
  window.location.href = teamUrl.replace(
    'https://teams.microsoft.com/',
    'msteams:/'
  )
}

export const getApplicationSSOUrl = function* (
  app: IServicePrincipal | string
) {
  let servicePrincipal = app as IServicePrincipal
  if (typeof app === 'string') {
    servicePrincipal = yield* call(getApplication, app)
  }

  if (!servicePrincipal) {
    throw new Error(
      `User does not have access to requested application: ${app}`
    )
  }

  const rootApplicationUrl = `https://myapps.microsoft.com/signin/${servicePrincipal.appId}`

  return rootApplicationUrl
}
