import { format, subDays } from 'date-fns'
import { ITimeSeriesChartProps } from 'features/Charts/TimeSeriesChart'
import { groupBy, sortBy } from 'lodash'
import { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DailyTimeSeriesChart,
  ITimeSeriesChartSeries
} from '../../../components/shared/charts/DailyTimeSeriesChart'
import { IGroupedActivitySummary } from '../../../store/types'
import { IActivityDurationOptions } from '../../Activity/ActivityDetailView'
import { useSharedActivityStore } from '../../shared/activity'
import { useActivityMappings } from './activityMappings'

export const ActivitySummaryChart: React.FC<{
  data?: IGroupedActivitySummary
  dateRange?: string
  days?: number
}> = ({ data, dateRange, days }) => {
  const { setCategories, setDateSelection, setRangeForDay } =
    useSharedActivityStore()
  const navigate = useNavigate()
  const { colorLookup, categoryCodeLookup, categoryLookup, orderLookup } =
    useActivityMappings()
  const onLegendClick = useCallback(
    (categories?: string[], range?: string) => {
      setDateSelection(range as IActivityDurationOptions)
      setCategories(categories)
      navigate('../activity')
    },
    [navigate, setCategories, setDateSelection]
  )
  const onItemClick = useCallback(
    (categories?: string[], date?: string) => {
      if (!date) {
        return
      }
      setRangeForDay(date)
      setCategories(categories)
      navigate('../activity')
    },
    [navigate, setCategories, setRangeForDay]
  )
  const series: ITimeSeriesChartSeries[] = useMemo(() => {
    const allActivities = data?.groupedActivities?.flatMap((groupedActivity) =>
      groupedActivity.groupedActivityType?.map((activityType) => ({
        date: groupedActivity?.date
          ? new Date(groupedActivity.date)
          : undefined,
        type: activityType?.type,
        mappedType:
          (activityType?.type && categoryLookup[activityType.type]) ||
          categoryLookup['Other'],
        value: activityType?.totalActivities
      }))
    )
    const activityGroups = groupBy(allActivities, (x) => x?.mappedType)
    return sortBy(
      Object.entries(activityGroups)?.map(([key, value]) => ({
        name: key,
        data: value?.map((x) => ({ date: x?.date, value: x?.value })),
        color:
          (value?.[0]?.type && colorLookup[value?.[0]?.type]) ||
          colorLookup['Other'],
        legendIndex: orderLookup[key] || orderLookup['Other']
      })),
      (x) => x.legendIndex
    )
  }, [categoryLookup, colorLookup, data?.groupedActivities, orderLookup]).map(
    (x, i) => ({ ...x, legendIndex: i })
  )
  const options: Highcharts.Options = useMemo(
    () => ({
      xAxis: {
        min: days
          ? Date.parse(subDays(new Date(), days).toISOString())
          : undefined,
        max: Date.parse(new Date().toISOString())
      },
      plotOptions: {
        column: {
          events: {
            click: (ev?: any) => {
              onItemClick(
                categoryCodeLookup?.[ev?.point?.series?.name] || [],
                format(ev.point.x, 'yyyy-MM-dd')
              )
            }
          }
        },
        series: {
          events: {
            legendItemClick: (ev?: any) => {
              ev?.preventDefault && ev.preventDefault()
              onLegendClick(
                categoryCodeLookup?.[ev?.target?.name] || [],
                dateRange
              )
            }
          }
        }
      },
      tooltip: {
        formatter: function formatTooltip(
          this: Highcharts.TooltipFormatterContextObject
        ) {
          return `<b>${format(this.x as number, `dd-MMM`)}</b><br/>${
            this.series.name
          }: ${this.y as number}
        <br/>TOTAL: ${this.total}`
        }
      }
    }),
    [categoryCodeLookup, dateRange, days, onItemClick, onLegendClick]
  )

  return (
    <MemoActivityChart chartType="column" series={series} options={options} />
  )
}

const ActivityChart: React.FC<ITimeSeriesChartProps> = ({
  series,
  options,
  chartType
}) => {
  return (
    <DailyTimeSeriesChart
      chartType={chartType}
      series={series}
      options={options}
    />
  )
}

const MemoActivityChart = memo(ActivityChart)
