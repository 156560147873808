import { uniq } from 'lodash'
import { createReducer } from 'typesafe-actions'
import { ITop10PositionItem } from '../../../../../api/common.types'
import { IListsUiState } from '../../../../../features/Lists/core/contracts/IListsUIState'
import { ListTypes } from '../shared/types'
import { dashboardActions, DashboardActionTypes } from './actions'
import { AllocationType } from './types'

export interface IDashboardState {
  allocationType: AllocationType
  selectedProductAllocations?: string[]
  selectedAssetAllocations?: string[]
  selectedPositions?: ITop10PositionItem[]
  isNetNewMoneySelected?: boolean
  isNetNewAssetsSelected?: boolean
  lists: Partial<Record<ListTypes, IListsUiState>>
}

const initialState: IDashboardState = {
  allocationType: 'asset',
  lists: {}
}

export const dashboardReducer = createReducer<
  IDashboardState,
  DashboardActionTypes
>(initialState)
  .handleAction(dashboardActions.setSelectedPositions, (state, action) => ({
    ...state,
    selectedPositions: action.payload
  }))
  .handleAction(
    dashboardActions.setSelectedProductAllocations,
    (state, action) => ({
      ...state,
      selectedProductAllocations: uniq(action.payload)
    })
  )
  .handleAction(
    dashboardActions.setSelectedAssetAllocations,
    (state, action) => ({
      ...state,
      selectedAssetAllocations: uniq(action.payload)
    })
  )
  .handleAction(dashboardActions.toggleNetNewAssetsSelected, (state) => ({
    ...state,
    isNetNewAssetsSelected: !state.isNetNewAssetsSelected
  }))
  .handleAction(dashboardActions.toggleNetNewMoneySelected, (state) => ({
    ...state,
    isNetNewMoneySelected: !state.isNetNewMoneySelected
  }))
  .handleAction(dashboardActions.setAllocationType, (state, action) => ({
    ...state,
    allocationType: action.payload
  }))
  .handleAction(dashboardActions.setListState, (state, action) => ({
    ...state,
    lists: { ...state.lists, [action.payload.listType]: action.payload.state }
  }))
