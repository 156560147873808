import { saveBlobAsFile } from 'shared/downloads'

export const useDownloadAttachment = () => {
  const downloadAttachment = (
    base64Data: string,
    contentType: string,
    filename: string
  ) => {
    contentType = contentType || ''
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    const blob = new Blob(byteArrays, { type: contentType })
    saveBlobAsFile(blob, filename)
  }
  return {
    downloadAttachment
  }
}
