import { css, Theme, useTheme } from '@emotion/react'
import { Toggle } from '@fluentui/react'
import { FC, useCallback, useMemo, useState } from 'react'
import { USD } from 'shared/components/Formatting'
import { useRdot360Preferences } from '../../hooks'
import { ProjectedIncomeDetailsChart } from '../../modules/Income/feature/ProjectedIncomeChart/ProjectedIncomeChart'
import useEstimatedIncomeChartData from '../../modules/Income/feature/useEstimatedIncomeChartData'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import {
  useGetChartEstimatedIncomeSummaryQuery,
  useGetChartProjectedIncomeSummaryQuery,
  useGetIncomeSummaryChartViewQuery
} from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import { GetIncomeSummaryChartView } from '../../store/types'
import { IndeterminateProgressIndicator } from '../shared'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps, ViewType } from '../TileHeading'
import ProjectedIncomeTable from './ProjectedIncomeTable'

const preferenceName = 'projectedIncomeTile'

export const getClasses = (theme: Theme) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }),
  subHeaderContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3px 0'
  }),
  subHeaderLabel: css({
    display: 'flex',
    marginTop: '4'
  }),
  toggleContainer: css({
    display: 'flex'
  }),
  totalHeader: css({
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '4px 8px 8px 0',
    textOverflow: 'ellipsis'
  }),
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  margin2: css({
    margin: 2
  }),
  checkboxContainer: css({
    marginBottom: '8px',
    marginTop: '4px',
    display: 'flex'
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  }),
  toggleWrapper: css(`
    margin: auto 0;
    .ms-Toggle {
      margin: auto 0;
      .ms-Toggle-background {
        background-color: ${theme.colors.primaryLightBlue1};
        width: 36px;
        height: 13px;
        border: 0;
        padding: 0;
        .ms-Toggle-thumb {
          background: ${theme.colors.extraBlue2};
          height: 16px;
          width: 16px;
        }
      }
    }
  `)
})
const ProjectedIncome: FC<BaseHeaderProps> = ({ onExpand }) => {
  const theme = useTheme()
  const [includeOptionPremiums, setIncludeOptionPremiums] = useState(true)
  const classes = useMemo(() => getClasses(theme), [theme])
  const [financialYear] = useState<number>(new Date().getFullYear())
  const [isEstimated, setIsEstimated] = useState<boolean>(false)
  const { getPreference, setPreference } = useRdot360Preferences<{
    [key: string]: ViewType
  }>(preferenceName, ViewType.graph)
  const viewType = getPreference(preferenceName)
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const {
    data: estimatedIncomeData,
    isLoading,
    isFetching,
    isUninitialized: estimatedChartIsUninitialized,
    error: estimatedChartError,
    refetch: estimatedChartRefetch
  } = useGetChartEstimatedIncomeSummaryQuery(
    { financialYear, accounts: apiContextAccounts },
    {
      skip:
        !apiContextAccounts?.length ||
        !apiContextAccounts ||
        isEstimated ||
        viewType !== ViewType.graph
    }
  )

  const {
    data: projectedIncomeData,
    isLoading: isProjectedIncomeLoading,
    isFetching: isProjectedIncomeFetching,
    error: projectedIncomeError,
    refetch: projectedIncomeRefetch,
    isUninitialized: projectedIncomeIsUninitialized
  } = useGetChartProjectedIncomeSummaryQuery(apiContextAccounts, {
    skip:
      !apiContextAccounts?.length ||
      !apiContextAccounts ||
      !isEstimated ||
      viewType !== ViewType.graph
  })

  const {
    data,
    isUninitialized,
    isLoading: isYTDLoading,
    isFetching: isYTDFetching,
    error: ytdError,
    refetch: ytdRefetch
  } = useGetIncomeSummaryChartViewQuery(apiContextAccounts, {
    skip:
      !apiContextAccounts?.length ||
      !apiContextAccounts ||
      viewType !== ViewType.table
  })

  const handleViewTypeChange = useCallback(
    (value: ViewType) => {
      setPreference({ [preferenceName]: value })
    },
    [setPreference]
  )

  const handleIncomeTypeChangeClick = useCallback(() => {
    setIsEstimated(!isEstimated)
  }, [isEstimated])

  const handleCheckboxChange = useCallback((e: any) => {
    setIncludeOptionPremiums(e.target.checked)
  }, [])

  const isError = useMemo(
    () => !!estimatedChartError || !!projectedIncomeError || !!ytdError,
    [estimatedChartError, projectedIncomeError, ytdError]
  )
  const errorMessage =
    (estimatedChartError as Error)?.message ||
    (projectedIncomeError as Error)?.message ||
    (ytdError as Error)?.message ||
    'An unknown Error Occurred'

  const { estimatedIncomeTotal } = useEstimatedIncomeChartData(
    includeOptionPremiums,
    estimatedIncomeData
  )

  const ytdIncomeData = useMemo(() => {
    if (isUninitialized || isLoading) {
      return {
        IncomeCategories: [
          {
            id: 'interestPurchasedSold',
            Description: 'Interest Purchased (-) / Sold',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          },
          {
            id: 'interestReceived',
            Description: 'Interest Received',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          },
          {
            id: 'dividends',
            Description: 'Dividends',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          },
          {
            id: 'principal',
            Description: 'Principal Payments',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          },
          {
            id: 'optionPremiumsPaid',
            Description: 'Option Premiums Paid',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          },
          {
            id: 'optionPremiumsReceived',
            Description: 'Option Premiums Received',
            YTD: null,
            Last12Months: null,
            Next12Months: null
          }
        ],
        TotalExcludingOptionPremiums: {
          YTD: null,
          Last12Months: null,
          Next12Months: null
        },
        Total: {
          YTD: null,
          Last12Months: null,
          Next12Months: null
        }
      } as unknown as GetIncomeSummaryChartView
    } else {
      return data
    }
  }, [data, isLoading, isUninitialized])

  const isOptionsPremiumsAvailable =
    isUninitialized ||
    !!ytdIncomeData?.IncomeCategories.some((x) => {
      return (
        (x.id === 'optionPremiumsPaid' &&
          (x.YTD || x.Last12Months || x.Next12Months)) ||
        (x.id === 'optionPremiumsReceived' &&
          (x.YTD || x.Last12Months || x.Next12Months))
      )
    })

  const displayOptionsPremiumsCheckbox =
    !isEstimated &&
    !!(
      estimatedIncomeData?.optionPremiumsPaid ||
      estimatedIncomeData?.optionPremiumsReceived
    )

  return (
    <div css={classes.container}>
      <TileHeading
        viewType={viewType}
        onViewtypeChange={handleViewTypeChange}
        graphIconType="chart"
        onExpand={onExpand}
      >
        <div>Income</div>
      </TileHeading>
      {isError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar
            message={errorMessage}
            type="Failure"
            actionButtonLabel="click to retry"
            onActionButtonClicked={
              estimatedChartError
                ? estimatedChartRefetch
                : projectedIncomeError
                ? projectedIncomeRefetch
                : ytdRefetch
            }
          />
        </div>
      )}

      {viewType === 'graph' && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 12
          }}
        >
          <div css={classes.subHeaderContainer}>
            <div css={classes.subHeaderLabel}>
              12 months {!isEstimated ? 'Historical' : 'Projected'} Income:{' '}
              <div css={{ fontWeight: 'bold' }}>
                <USD
                  value={
                    isEstimated
                      ? projectedIncomeData?.total || 0
                      : estimatedIncomeTotal
                  }
                  currencySign="standard"
                  fractionDigits={0}
                />
              </div>
            </div>
            <div css={classes.toggleContainer}>
              <div
                css={[
                  classes.margin2,
                  isEstimated ? classes.primaryDarkBlue : classes.tertiaryGray1
                ]}
              >
                Historical
              </div>
              <div css={classes.toggleWrapper}>
                <Toggle
                  checked={isEstimated}
                  onChange={handleIncomeTypeChangeClick}
                  role="checkbox"
                />
              </div>
              <div
                css={[
                  classes.margin2,
                  !isEstimated ? classes.primaryDarkBlue : classes.tertiaryGray1
                ]}
              >
                Projected
              </div>
            </div>
          </div>

          <div
            css={{
              display: 'flex',
              marginBottom: 4,
              flexDirection: 'row',
              flexWrap: 'wrap',
              flexGrow: 1
            }}
          >
            <span css={classes.totalHeader}>
              Total Interest :
              <div css={{ fontWeight: 'bold' }}>
                <USD
                  value={
                    isEstimated
                      ? projectedIncomeData?.interest || 0
                      : estimatedIncomeData?.interestReceived || 0
                  }
                  currencySign="standard"
                  fractionDigits={0}
                />
              </div>
            </span>
            <span css={classes.totalHeader}>
              Total Dividends :
              <div css={{ fontWeight: 'bold' }}>
                <USD
                  value={
                    isEstimated
                      ? projectedIncomeData?.dividend || 0
                      : estimatedIncomeData?.dividend || 0
                  }
                  currencySign="standard"
                  fractionDigits={0}
                />
              </div>
            </span>
            {displayOptionsPremiumsCheckbox && (
              <div css={classes.checkboxContainer}>
                <IndeterminateCheckbox
                  id="optionPremiumsLabelCheckbox"
                  className="checkbox"
                  checked={includeOptionPremiums}
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor="optionPremiumsLabelCheckbox"
                  css={classes.checkboxLabel}
                >
                  Include Option Premiums
                </label>
              </div>
            )}
          </div>
        </div>
      )}
      <>
        {viewType === ViewType.graph && (
          <ProjectedIncomeDetailsChart
            incomeType={!isEstimated ? 'historical' : 'projected'}
            includeOptionPremiums={includeOptionPremiums}
            data={!isEstimated ? estimatedIncomeData : projectedIncomeData}
            noDataMsg={
              isEstimated
                ? projectedIncomeIsUninitialized ||
                  isProjectedIncomeLoading ||
                  isProjectedIncomeFetching
                : estimatedChartIsUninitialized || isLoading || isFetching
            }
          />
        )}
        {viewType === ViewType.table && (
          <ProjectedIncomeTable
            data={ytdIncomeData}
            isOptionsPremiumsAvailable={isOptionsPremiumsAvailable}
          />
        )}
      </>
      {(isLoading ||
        isFetching ||
        isYTDFetching ||
        isYTDLoading ||
        isProjectedIncomeFetching ||
        isProjectedIncomeLoading) && (
        <div css={classes.progressIndicatorContainer}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </div>
  )
}

export default ProjectedIncome
