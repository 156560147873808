import {
  GetChartEstimatedIncomeSummary,
  GetChartProjectedIncomeSummary
} from 'modules/Advisory/modules/Rdot360/store/types'
import { FC, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { HighchartsComponent, highcharts } from 'shared/highcharts'
import useProjectedIncomeChartData, {
  ChartData
} from '../useProjectedIncomeChartData'
import { IncomeType } from '../util'

const getProjectedIncomeOptions = (data: ChartData): Highcharts.Options => ({
  chart: {
    type: 'column',
    height: '420',
    zooming: {
      type: 'xy'
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: data.dates
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  tooltip: {
    formatter: function formatTooltip(this: any) {
      if (this.y >= 0) {
        return [
          `${this.x}`,
          `${this.series.name} : $${highcharts?.numberFormat(
            this.y,
            0,
            '.',
            ','
          )}`,
          `Total : $${highcharts?.numberFormat(this.total, 0, '.', ',')}`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      } else {
        return [
          `${this.x}`,
          `${this.series.name} : -$${highcharts?.numberFormat(
            this.y * -1,
            0,
            '.',
            ','
          )}`,
          `Total : -$${highcharts?.numberFormat(this.total * -1, 0, '.', ',')}`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      }
    }
  },
  plotOptions: {
    column: {
      pointWidth: 40,
      borderRadius: 0,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    symbolRadius: 0
  },
  series: data.chartData as Highcharts.SeriesOptionsType[]
})

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%', minWidth: 700 }
}
export const ProjectedIncomeDetailsChart: FC<{
  data?: GetChartEstimatedIncomeSummary | GetChartProjectedIncomeSummary
  includeOptionPremiums: boolean
  incomeType: IncomeType
  financialYear: number
}> = ({ data, incomeType, includeOptionPremiums, financialYear }) => {
  const chartData = useProjectedIncomeChartData(
    includeOptionPremiums,
    incomeType,
    financialYear,
    data
  )
  const projectedIncomeOptions = useMemo(
    () => getProjectedIncomeOptions(chartData),
    [chartData]
  )

  return (
    <>
      {projectedIncomeOptions.series?.length ? (
        <HighchartsComponent
          options={projectedIncomeOptions}
          containerProps={containerProps}
        />
      ) : (
        <div
          css={{
            textAlign: 'center',
            margin: 'auto 0'
          }}
        >
          No data available
        </div>
      )}
    </>
  )
}
