import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  IAccountProperties,
  IAttensionResponse,
  IForeignCurrencyPosition,
  IGenericResponse,
  IgetWireTypeResponse,
  IMMDeviceInfoResponse,
  IMMValidateAPIResponse,
  IMMValidateRequest,
  IPastPaymentsRequest,
  IPastPaymentsResponse,
  IPaymentApproveDetailsResponse,
  IPaymentOPSAssignmentRequest,
  IPaymentReviewRequest,
  IPaymentReviewResponse,
  IUpdateFedRefResponse,
  IWireRequest,
  IWireRiskAckRequest,
  IWireRiskAckResponse
} from '../store/types'
import {
  IAbaResponse,
  IWirePaymentResponse,
  PartyPhoneResponse,
  FetchInstructionsListRequest,
  IStandingInstructionsResponse,
  ICurrencyResponse,
  ICountryResponse,
  ICreatePaymentResponse,
  IInsuffundSolResponse,
  IWireDocTypeListResponse,
  IWireUploadDocRequest,
  IWireSaveDocRequest,
  IWireUploadmetadata,
  IReprocessResponse
} from './types'

export const fetchAbaDetails = (
  aba: string,
  country: string | undefined,
  options: IApiOptions,
  isSwift?: boolean | undefined
): Promise<AxiosResponse<IAbaResponse | undefined>> => {
  const str = (country && country !== 'US') || isSwift ? 'WIRES' : 'ATOCH'
  const url = `${
    options.apiRoot
  }/cashmanagement/mm/odata/GetABA('${str},${aba.replace(/\s+/g, '')}')`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.value?.[0])
}

export const fetchWirePayments = (
  fromDate: string,
  toDate: string,
  options: IApiOptions
): Promise<AxiosResponse<IWirePaymentResponse | undefined>> => {
  const url = `${options.apiRoot}/payment/api/enterprise/getBillInfo?fromDate=${fromDate}&toDate=${toDate}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const reprocessTransaction = (
  tranId: string,
  options: IApiOptions
): Promise<AxiosResponse<IReprocessResponse | undefined>> => {
  const url = `${options.apiRoot}/payment/api/payment/reProcessTransactionToCustodian?tranId=${tranId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const declineTransaction = (
  tranId: string,
  options: IApiOptions
): Promise<AxiosResponse<IGenericResponse | undefined>> => {
  const url = `${options.apiRoot}/payment/api/payment/declineTransaction?tranId=${tranId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const closeTransaction = (
  tranId: string,
  options: IApiOptions
): Promise<AxiosResponse<IGenericResponse | undefined>> => {
  const url = `${options.apiRoot}/payment/api/payment/closeTransaction?tranId=${tranId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchholidayList = (year: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getHolidays?year=${year}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchInsufFundSolutionListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getInsufFundSolutionList`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IInsuffundSolResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadWireDocument = (
  options: IApiOptions,
  payload: IWireUploadDocRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/FileData/StoreDocument`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  data.append('filecontents', payload?.filecontents)
  data.append('channel', 'Payment')
  // data.append('metadata', JSON.stringify(payload.metadata))
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadWireDocumentMetadata = (
  options: IApiOptions,
  metadata: IWireUploadmetadata,
  fileId: string
) => {
  const url = `${options.apiRoot}/documentsapi/api/FileData/UpdateMetadata`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = { metadata, fileid: fileId.toString() }
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchAccountBalance = (options: IApiOptions, account: string) => {
  const url = `${options.apiRoot}/balance/odata/balancedetails?AccountList=${account}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => {
      return res.data.value?.length ? res.data.value[0] : undefined
    })
}

export const saveWireDocument = (
  options: IApiOptions,
  payload: IWireSaveDocRequest
) => {
  const url = `${options.apiRoot}/payment/api/payment/saveTranDocMetaInfo`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, [payload], {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchWireDocToView = (options: IApiOptions, docId: string) => {
  const url = `${options.apiRoot}/documentsapi/api/FileData/GetDocument?storagedocid=${docId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const handleWireDocDelete = (
  options: IApiOptions,
  tranId: string,
  fileId: string
) => {
  const url = `${options.apiRoot}/payment/api/payment/deleteTranDocMetaInfo?tranId=${tranId}&fileId=${fileId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const handleWireDocDeleteFromStorage = (
  options: IApiOptions,
  storagedocid: string
) => {
  const url = `${options.apiRoot}/documentsapi/api/Filedata/DeleteDocument`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(
      url,
      { storagedocid },
      {
        headers,
        cancelToken: options.cancelToken
      }
    )
    .then((res) => res.data)
}

export const fetchRequiredDocTypes = (
  options: IApiOptions,
  type?: string,
  tranId?: string
) => {
  const url = `${
    options.apiRoot
  }/payment/api/payment/getRequiredDocs?TranType=${
    type || 'WD'
  }&tranId=${tranId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IWireDocTypeListResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchWireEligibleCountriesListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getWireCountryCodes`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ICountryResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchCurrencyCodeListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getWireCurrencyCodes`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ICurrencyResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchPartyPhoneNumbersService = (
  accountNo: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/getclientphonelist?acctNo=${accountNo}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<PartyPhoneResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchMMDeviceInfo = (accountNo: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/processMMDeviceInfo?acctNo=${accountNo}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IMMDeviceInfoResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchMethodofInstructionsService = (): string[] => {
  return [
    'In-Person / Video Conference',
    'Incoming call from client',
    'Outbound call to trusted phone number',
    'Other'
  ]
}

export const createPaymentService = (
  payment: IWireRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/processMMInstruction`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<ICreatePaymentResponse>(url, payment, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getStandingInstructionsList = (
  account: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cashmanagement/mm/odata/GetWireSI`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const payload: FetchInstructionsListRequest = {
    AcctInfos: [
      { acctNum: null, acctCategory: null, acctType: null, isPledged: null },
      { acctNum: account, acctCategory: null, acctType: null, isPledged: null }
    ],
    RequestorId: ''
  }
  return axios
    .post<IStandingInstructionsResponse>(url, payload, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getPastPayments = (
  request: IPastPaymentsRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/getPastPayments?AccountNo=${request.accountNo}&tranType=${request.tranType}&lookBackDays=${request.lookBackDays}&role=${request.role}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPastPaymentsResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getWireType = (
  request: { country?: string; account?: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/getWireTypeCondition?Destination=${request.country}&AccountNumber=${request.account}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IgetWireTypeResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchForeignCurrencyBalance = (
  account: string,
  currency: string,
  options: IApiOptions
): Promise<AxiosResponse<IForeignCurrencyPosition | undefined>> => {
  const url = `${options.apiRoot}/holdings/odata/Position/GetPosition?noacct=${account}&cusip=${currency}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.[0])
}

export const mmValidateService = (
  request: IMMValidateRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/mm/odata/MMValidate`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<IMMValidateAPIResponse>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => (res.data.value?.length ? res.data.value[0] : undefined))
}

export const submitPaymentReview = (
  request: IPaymentReviewRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/SubmitReviewResponse`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IPaymentReviewResponse>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const SubmitOPSAssigment = (
  request: IPaymentOPSAssignmentRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/SubmitOPSAssigment `
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IGenericResponse>(url, [request], {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updateFedRefNo = (
  request: IPaymentReviewRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/updateFRXStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IUpdateFedRefResponse>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getTranApproveDetail = (
  tranRefId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/getTranApproveDetail?tranRefId=${tranRefId}&allHistory=Y`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPaymentApproveDetailsResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const evaluateTransactionRiskSerivce = (
  request: IWireRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/evaluateTranRisk`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<IAttensionResponse>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const submitRiskAcknowledgementService = (
  request: IWireRiskAckRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/SubmitRiskAck`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IWireRiskAckResponse>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getRiskEvalResultSerivce = (
  refid: string,
  role: string,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/payment/api/payment/getRiskEvalResult?callerRefId=${refid}${
    role ? `&role=${role}` : ''
  }`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<IAttensionResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getAccountProperties = (request: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/profileapi/api/AccountInfo/GetAccountProperties  `
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IAccountProperties[]>(url, [request], {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
