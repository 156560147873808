import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface ISidePanelState {
  isOpen?: boolean
}

const initialState = {
  isOpen: true
} as ISidePanelState

const sidePanelFeatureSlice = createSlice({
  name: '@modules/@rdot360/@features/@accountSelector',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpen: action.payload
    })
  }
})

export const { reducer: sidePanelFeatureReducer } = sidePanelFeatureSlice
const { actions } = sidePanelFeatureSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.sidePanel

export const useSidePanelStore = () => {
  const dispatch = useDispatch()
  const { isOpen } = useSelector(rootSelector)

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(actions.setIsOpen(isOpen))
    },
    [dispatch]
  )

  return {
    isOpen,
    setIsOpen
  }
}
