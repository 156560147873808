import {
  ListsFilterEdit,
  getFilerComponent
} from 'features/Lists/core/features/filter/components/ListsFilterEdit'
import { IListsFilterEditProps } from 'features/Lists/core/features/filter/contracts/IListsFilterEditProps'
import { IOdataListFacetActions } from 'features/OdataList/common/IODataListFacetActions'
import { IOdataListFacetSelectors } from 'features/OdataList/common/IODataListFacetSelectors'
import React, { PropsWithChildren, memo, useMemo } from 'react'
import { createConnectedODataListFacetFilter } from './ODataListDynamicFacetFilter'

export interface IOdataListsFilterEditProps<T, U>
  extends IListsFilterEditProps {
  facetActions?: IOdataListFacetActions<T>
  facetSelectors?: IOdataListFacetSelectors<T, U>
}

export const ODataListsFilterEdit = <T, U>({
  filter,
  facetActions,
  facetSelectors,
  ...rest
}: PropsWithChildren<
  IOdataListsFilterEditProps<T, U>
>): React.ReactElement | null => {
  const { type } = filter
  const FilterComponent: React.FC<IListsFilterEditProps> | null =
    useMemo(() => {
      if (
        facetActions &&
        facetSelectors &&
        (type === 'facet' || type === 'facet-search')
      ) {
        return memo(
          createConnectedODataListFacetFilter(
            facetActions,
            facetSelectors,
            filter.dataPath
          ) as React.FC<IListsFilterEditProps>
        )
      }
      return getFilerComponent(type)
    }, [facetActions, facetSelectors, filter.dataPath, type])

  return (
    <ListsFilterEdit filter={filter} {...rest} customFilter={FilterComponent} />
  )
}
