const windowArguments =
  'status,menubar,toolbar,centerscreen,left=20,top=20,height=800,width=1200'
export const navigate = (
  url: string | undefined,
  {
    target = '_blank',
    openInNewWindow = false
  }: {
    target?: string
    openInNewWindow?: boolean
  }
) => {
  if (openInNewWindow) {
    window.open(url, target, windowArguments)
  } else {
    window.open(url, target)
  }
}
