import { useTheme } from '@emotion/react'
import { useMemo } from 'react'

export const useActivityMappings = () => {
  const theme = useTheme()
  const colorLookup: Record<string, string> = useMemo(
    () => ({
      BUY: theme.colors.tertiaryBlue1,
      SELL: theme.colors.secondaryGreen3,
      DIVIDEND: theme.colors.tertiaryBlue2,
      INTEREST: theme.colors.tertiaryBlue2,
      'EXCHANGE/TRANSFER': theme.colors.secondaryYellow1,
      Fee: theme.colors.secondaryGreen1,
      DEPOSIT: theme.colors.tertiaryBlue3,
      WITHDRAWAL: theme.colors.secondaryTurquoise1,
      Other: theme.colors.secondaryGreen3
    }),
    [
      theme.colors.secondaryGreen1,
      theme.colors.secondaryGreen3,
      theme.colors.secondaryTurquoise1,
      theme.colors.secondaryYellow1,
      theme.colors.tertiaryBlue1,
      theme.colors.tertiaryBlue2,
      theme.colors.tertiaryBlue3
    ]
  )
  const categoryLookup: Record<string, string> = useMemo(
    () => ({
      BUY: 'Buys',
      SELL: 'Sells',
      DIVIDEND: 'Dividends/Interest',
      INTEREST: 'Dividends/Interest',
      'EXCHANGE/TRANSFER': 'Transfers',
      FEE: 'Fee',
      DEPOSIT: 'Deposits',
      'DEPOSIT/CONTRIBUTION': 'Deposits',
      WITHDRAWAL: 'Withdrawals',
      Other: 'Other'
    }),
    []
  )
  const categoryCodeLookup: Record<string, string[]> = useMemo(
    () => ({
      Buys: ['BY'],
      Sells: ['SL'],
      'Dividends/Interest': ['DV', 'IN'],
      Transfers: ['EX'],
      Fee: ['FE'],
      Deposits: ['DP'],
      Withdrawals: ['WD'],
      Other: ['JN', 'CA', 'ZZ']
    }),
    []
  )
  const orderLookup: Record<string, number> = useMemo(
    () => ({
      Buys: 1,
      Sells: 2,
      'Dividends/Interest': 3,
      Transfers: 4,
      Fee: 5,
      Deposits: 6,
      Withdrawals: 7,
      Other: 8
    }),
    []
  )
  return {
    colorLookup,
    categoryLookup,
    categoryCodeLookup,
    orderLookup
  }
}
