const names = [
  'Isaiah Sher',
  'Roxana Parker',
  'Latoya Crepeau',
  'Gwenda Mccaffery',
  'Tenesha Fogal',
  'Ewa Phares',
  'Margarete Rezac',
  'Otilia Lafler',
  'Juan Vogel',
  'Rogelio Renfrew',
  'Ginger Digiacomo',
  'Marilyn Pettyjohn',
  'Rolanda Hornback',
  'Rodger Ulloa',
  'Veta Hutton',
  'Lindsay Dubay',
  'Guy Schroer',
  'Indira Panzer',
  'Fredrick Thorn',
  'Marshall Gamache',
  'Michiko Gundlach',
  'Drema Inskeep',
  'Samatha Reves',
  'Jan Goates',
  'Tamala Worley',
  'Scot Doyon',
  'Marc Deans',
  'Charlsie Sigman',
  'Edward Larsen',
  'Stephan Alber',
  'Augustus Cothern',
  'Lakita Millwood',
  'Jared Hodgkinson',
  'Cher Aceto',
  'Kamala Tremble',
  'Hilda Slusser',
  'Angel Dano',
  'Aurelia Okelley',
  'Randall Boettcher',
  'Sparkle Eidson',
  'Carolina Heffington',
  'Adeline Pundt',
  'Brandon Dorrough',
  'Cecil Zufelt',
  'Johnette Harkness',
  'Julianne Rosenfield',
  'Latina Woodworth',
  'Lana Esquilin',
  'Dierdre Utsey',
  'Verona Brockett'
]

const shortNames = ['BASE', 'LOAN', 'CHECKING', 'OPERATING', 'BROKERAGE']

function random(max: number) {
  return Math.floor(Math.random() * max)
}

export const getRandomName = () => names[random(names.length)]

export const getRandomShortName = () => shortNames[random(shortNames.length)]

export const getRandomCapitalLetter = () =>
  String.fromCharCode(65 + Math.floor(Math.random() * 26))
