import {
  DefaultButton,
  Link,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../shared/components/Loading'
import { navigationActions } from '../../store/ui/actions'
import { MarginRequestContent } from './MarginRequestContent'
import { useMarginRateAdjustmentFeatureStore } from './store'
import {
  getMarginRateRequestType,
  getMoreAccountsFromHouseholdId
} from './store/householdAccountsDetails'
import {
  getRateJustification,
  isInvalidMarginRate
} from './store/marginRateEmailContents'

import {
  getIsMarginRatePanelLoading,
  getIsMarginRatePanelOpen,
  getMarginRatePanelError,
  getSelectedHousehold,
  marginRatePanelActions
} from './store/marginRatePanel'

export const MarginRequestRatePanel: React.FC = () => {
  useMarginRateAdjustmentFeatureStore()
  const isPanelOpen = useSelector(getIsMarginRatePanelOpen)
  const isLoading = useSelector(getIsMarginRatePanelLoading)
  const dispatch = useDispatch()
  const onSubmit = useCallback(() => {
    dispatch(marginRatePanelActions.submit())
  }, [dispatch])
  const onDismiss = useCallback(
    () => dispatch(marginRatePanelActions.close()),
    [dispatch]
  )
  const error = useSelector(getMarginRatePanelError)

  const marginRateRequestType = useSelector(getMarginRateRequestType)
  const customRateJustification = useSelector(getRateJustification)
  const invalidMarginRate = useSelector(isInvalidMarginRate)
  const selectedAccounts = useSelector(getMoreAccountsFromHouseholdId)
  const selectedHousehold = useSelector(getSelectedHousehold)

  const canSubmit =
    !!selectedHousehold &&
    !(marginRateRequestType === 'Custom' && !customRateJustification) &&
    !invalidMarginRate &&
    selectedAccounts?.length &&
    selectedAccounts.length > 0

  const onCutOffClicked = useCallback(() => {
    dispatch(
      navigationActions.launchUrl({
        url: 'https://rockco.sharepoint.com/:b:/r/sites/RockcoCommunications/Strategic%20Client%20Solutions/Credit%20Advisory%20Third%20Party/NFS%20-%20Margin%20%26%20Multi-Margin/2023%20Margin%20Change%20Cut%20Off.pdf?csf=1&web=1'
      })
    )
  }, [dispatch])

  const onMarginPricingClicked = useCallback(() => {
    dispatch(
      navigationActions.launchUrl({
        url: 'https://rockco.sharepoint.com/:b:/r/sites/RockcoCommunications/Strategic%20Client%20Solutions/Credit%20Advisory%20Third%20Party/NFS%20-%20Margin%20%26%20Multi-Margin/RCM%20Margin%20Pricing%20Overview_2023.pdf?csf=1&web=1&e=SDMsHQ'
      })
    )
  }, [dispatch])

  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            {error.message}
          </MessageBar>
        )}
        <MessageBar
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel="Close"
        >
          <Stack>
            <Text>
              Reminder: Rates submitted after 2:30pm EST on the designated date
              of the month will be effective for the next interest cycle.
            </Text>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Link onClick={onCutOffClicked}>Margin Rate Cut-Off</Link>
              <Text>|</Text>
              <Link onClick={onMarginPricingClicked}>Margin Pricing</Link>
            </Stack>
          </Stack>
        </MessageBar>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={onSubmit} disabled={!canSubmit || isLoading}>
            Submit For Approval
          </PrimaryButton>
          <DefaultButton onClick={onDismiss} disabled={isLoading}>
            Cancel
          </DefaultButton>
        </Stack>
      </Stack>
    )
  }, [
    canSubmit,
    error,
    isLoading,
    onCutOffClicked,
    onDismiss,
    onMarginPricingClicked,
    onSubmit
  ])
  return (
    <>
      <Panel
        headerText={`Margin Rate Adjustment`}
        onDismiss={onDismiss}
        type={PanelType.medium}
        isOpen={isPanelOpen}
        isLightDismiss={true}
        isFooterAtBottom={true}
        onRenderFooterContent={onRenderFooterContent}
        styles={{ content: { flexGrow: 1 } }}
      >
        {isLoading && (
          <Overlay styles={{ root: { zIndex: 5 } }}>
            <LoadingComponent status={'loading'} />
          </Overlay>
        )}
        <MarginRequestContent />
      </Panel>
    </>
  )
}
