import {
  Dropdown,
  ISelectableOption,
  Stack,
  Label,
  IDropdownOption
} from '@fluentui/react'
import { useCallback } from 'react'
import {
  accountSelectorColumnNames,
  useAccountSelectorTableStore
} from '../AccountSelector'

const filterOptions: ISelectableOption[] = [
  { key: accountSelectorColumnNames.legalEntityId, text: 'Entity' },
  {
    key: accountSelectorColumnNames.custodianType,
    text: 'Institution / Custodian'
  },
  { key: accountSelectorColumnNames.accountRegistration, text: 'Account Type' },
  { key: accountSelectorColumnNames.taxable, text: 'Taxable / Tax-deferred' },
  { key: accountSelectorColumnNames.ausClass, text: 'Managed / Brokerage' },
  { key: 'cag', text: 'Custom Account Group' }
]
export const SidePanelFilter = () => {
  const {
    setGrouping,
    grouping: [firstGroup]
  } = useAccountSelectorTableStore()
  const onFilterChange = useCallback(
    (_e: any, option?: IDropdownOption<any>) => {
      if (option?.key === 'cag') {
        setGrouping(['cag'])
        return
      }
      const column = option?.key as keyof typeof accountSelectorColumnNames
      if (!column) {
        return
      }

      setGrouping([column])
    },
    [setGrouping]
  )

  return (
    <Stack>
      <Stack.Item grow={1}>
        <Stack>
          <Label>View By/Filter</Label>
        </Stack>
      </Stack.Item>
      <Stack.Item grow={1}>
        <Dropdown
          options={filterOptions}
          onChange={onFilterChange}
          selectedKey={firstGroup}
        />
      </Stack.Item>
    </Stack>
  )
}
