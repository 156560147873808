import { CommandBarButton, IButtonStyles, Stack } from '@fluentui/react'
import { useClientInvitePanel } from 'features/ClientInvite/store/clientInvitePanel'
import { PhonesMenuSection } from 'features/Clients/PhonesMenuSection'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Separator } from 'shared/components/Separator'
import { navigationActions } from 'store/ui/actions'
import { useRelatedPartyNavigation } from './store'

const buttonStyles: IButtonStyles = {
  root: { textAlign: 'left', height: '36px' }
}

export const RelatedPartyMenu: React.FC<{
  wsportaluserid?: string
  loginid?: string
  partyId?: string
  displayName?: string
  onItemClicked?: () => void
}> = ({ wsportaluserid, loginid, displayName, partyId, onItemClicked }) => {
  const dispatch = useDispatch()
  const { open } = useClientInvitePanel()

  const onResendInviteClicked = useCallback(() => {
    if (!wsportaluserid) {
      return
    }
    open(wsportaluserid, 'resend')
  }, [open, wsportaluserid])

  const onSendEmailClicked = useCallback(() => {
    window.open(`mailto:${loginid}`)
  }, [loginid])
  const onCollaborationClicked = useCallback(() => {
    if (!loginid) {
      return
    }
    dispatch(navigationActions.launchTeams(loginid))
  }, [dispatch, loginid])

  const { launchClientOnline, openRelatedPartyPanel } =
    useRelatedPartyNavigation()
  const onSimulateClientOnline = useCallback(
    () => launchClientOnline({ wealthscapeId: wsportaluserid, partyId }),
    [launchClientOnline, partyId, wsportaluserid]
  )

  const onInviteNow = useCallback(() => {
    if (!wsportaluserid) {
      return
    }
    open(wsportaluserid, 'invite')
  }, [open, wsportaluserid])

  const onRelatedPartiesClicked = useCallback(() => {
    openRelatedPartyPanel({
      idType: 'wealthscapeId',
      id: wsportaluserid || '',
      displayName: displayName || 'Client'
    })
  }, [displayName, openRelatedPartyPanel, wsportaluserid])

  return (
    <Stack onClick={onItemClicked}>
      <Stack.Item styles={{ root: { padding: '10px', paddingBottom: '0px' } }}>
        <PhonesMenuSection WealthscapeId={wsportaluserid} />
        <Separator />
      </Stack.Item>
      <Stack>
        {!loginid && wsportaluserid && (
          <CommandBarButton
            iconProps={{ iconName: 'Send' }}
            text="Invite Now"
            onClick={onInviteNow}
            styles={buttonStyles}
          />
        )}
        {loginid && (
          <CommandBarButton
            iconProps={{ iconName: 'Mail' }}
            text="Send Email"
            onClick={onSendEmailClicked}
            styles={buttonStyles}
          />
        )}
        {loginid && (
          <CommandBarButton
            iconProps={{ iconName: 'MailRepeat' }}
            text="Resend Invitation"
            onClick={onResendInviteClicked}
            styles={buttonStyles}
          />
        )}
        {wsportaluserid && (
          <CommandBarButton
            iconProps={{ iconName: 'ArrowUpRight' }}
            text="Simulate Client Online"
            onClick={onSimulateClientOnline}
            styles={buttonStyles}
          />
        )}
        {wsportaluserid && (
          <CommandBarButton
            iconProps={{ iconName: 'Relationship' }}
            text="View Related Parties"
            onClick={onRelatedPartiesClicked}
            styles={buttonStyles}
          />
        )}
        {loginid && (
          <CommandBarButton
            iconProps={{ iconName: 'TeamsLogo' }}
            text="Collaboration"
            onClick={onCollaborationClicked}
            styles={buttonStyles}
          />
        )}
      </Stack>
    </Stack>
  )
}
