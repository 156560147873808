import { createReducer } from 'typesafe-actions'
import {
  fetchPaymentListDataActions,
  DashboardActionTypes,
  setUserRoleActions,
  setSelectedPaymentItemActions,
  setPaymentSearchSortFilterActions,
  setPaymentAutoRefreshFlagActions
} from './actions'
import {
  PaymentMM,
  PaymentRow,
  RefreshByRole,
  SearchSortFilter,
  Statistics
} from './types'

export interface IDashboardState {
  paymentListState?: {
    isLoading: boolean
    error?: Error
    data?: PaymentRow[]
    statistics?: Statistics[]
  }
  userRole?: string
  paymentSearchSortFilterData?: SearchSortFilter
  paymentAutoRefreshState?: RefreshByRole[]
  selectedPaymentItem?: PaymentMM
}

const initialState: IDashboardState = {}

export const dashboardReducer = createReducer<
  IDashboardState,
  DashboardActionTypes
>(initialState)
  .handleAction(fetchPaymentListDataActions.request, (state) => ({
    ...state,
    paymentListState: {
      isLoading: true,
      error: undefined,
      data: undefined,
      statistics: undefined
    }
  }))
  .handleAction(fetchPaymentListDataActions.success, (state, action) => ({
    ...state,
    paymentListState: {
      isLoading: false,
      data: action.payload.paymentList,
      statistics: action.payload.statistics,
      error: undefined
    }
  }))
  .handleAction(fetchPaymentListDataActions.failure, (state, action) => ({
    ...state,
    paymentListState: {
      isLoading: false,
      data: undefined,
      statistics: undefined,
      error: action.payload
    }
  }))
  .handleAction(setUserRoleActions.setrole, (state, action) => ({
    ...state,
    userRole: action.payload
  }))
  .handleAction(
    setPaymentSearchSortFilterActions.setSearchSortFilter,
    (state, action) => ({
      ...state,
      paymentSearchSortFilterData: action.payload
    })
  )
  .handleAction(
    setPaymentAutoRefreshFlagActions.setAutoRefreshFlag,
    (state, action) => ({
      ...state,
      paymentAutoRefreshState: action.payload
    })
  )
  .handleAction(
    setSelectedPaymentItemActions.setPaymentItem,
    (state, action) => ({
      ...state,
      selectedPaymentItem: action.payload
    })
  )
