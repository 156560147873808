import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { IAccount } from 'api/account.types'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { useGetAccountsFromHouseholdIdQuery } from '../datahub'
import { useRdot360Context } from './rdot360Context'

export const useRdot360AccountContext = () => {
  const {
    selectedHouseholdId,
    selectedAccountIds,
    setSelectedAccounts,
    includeClosedAccounts,
    setIncludeClosedAccounts
  } = useRdot360Context()

  const { data: householdAccounts, isFetching: isAccountsFetching } =
    useGetAccountsFromHouseholdIdQuery(
      selectedHouseholdId ? selectedHouseholdId : skipToken
    )

  const accountLookup = useMemo(() => {
    const dict: { [id: string]: IAccount | undefined } = {}
    householdAccounts?.map((account) => {
      dict[account?.id || ''] = account
    })
    return dict
  }, [householdAccounts])

  const accountLookupById = useMemo(() => {
    const dict: { [id: string]: IAccount | undefined } = {}
    householdAccounts?.map((account) => {
      dict[account?.accountId || ''] = account
    })
    return dict
  }, [householdAccounts])

  const selectedAccounts = useMemo(() => {
    selectedAccountIds.map((x) => accountLookup[x]).filter(isNotNullOrUndefined)
  }, [accountLookup, selectedAccountIds])

  const mapAccountIdsToAccounts = useCallback(
    (ids: string[]) => {
      return ids.map((x) => accountLookup[x]).filter(isNotNullOrUndefined)
    },
    [accountLookup]
  )

  const setSelectedAccountIds = useCallback(
    (ids: string[]) => flow(mapAccountIdsToAccounts, setSelectedAccounts)(ids),
    [mapAccountIdsToAccounts, setSelectedAccounts]
  )

  const accounts = useMemo(
    () =>
      householdAccounts?.filter(
        (x) => includeClosedAccounts || x.accountStatus === 'Open'
      ),
    [householdAccounts, includeClosedAccounts]
  )

  return {
    householdAccounts,
    accounts,
    accountLookup,
    selectedAccountIds,
    selectedAccounts,
    isAccountsFetching,
    setSelectedAccountIds,
    includeClosedAccounts,
    setIncludeClosedAccounts,
    accountLookupById
  }
}
