import { format } from 'date-fns'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Masked } from '../../../../../../shared/components/MaskedText'
import { DateRanges } from '../../../../../../shared/dates'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter,
  IListsNumberRangeFilter,
  IListsSearchFilter,
  NumberFilterTypes
} from '../../../contracts/IListsFilter'

export const ListsFilterStatus: React.FC<{
  filter: IListsFilter
  shouldMask?: boolean
}> = ({ filter, shouldMask }) => {
  const { type, blankIndicator, displayValue } = filter

  if (blankIndicator) {
    return <>{blankIndicator === 'exclude' && 'not'} (Blank)</>
  }

  if (displayValue) {
    return <>{displayValue}</>
  }

  switch (type) {
    case 'date':
    case 'date-only': {
      const { from, to, range } = filter as IListsDateRangeFilter
      if (range !== DateRanges.Custom) {
        return <>{range}</>
      }

      return (
        <>
          <span>{from ? format(from, 'MM/dd/yyyy') : '(min)'}</span> to{' '}
          <span>{to ? format(to, 'MM/dd/yyyy') : '(max)'}</span>
        </>
      )
    }
    case 'number': {
      const {
        min,
        max,
        filterType = 'range',
        value: numberFilterValue
      } = filter as IListsNumberRangeFilter
      return filterType === 'range' ? (
        <>
          {min != null ? <FormattedNumber value={min} /> : <>(min)</>}
          <> to </>
          {max != null ? <FormattedNumber value={max} /> : <>(max)</>}
        </>
      ) : (
        <>
          {NumberFilterTypes[filterType]}{' '}
          {numberFilterValue != null && (
            <FormattedNumber value={numberFilterValue} />
          )}
        </>
      )
    }
    case 'search': {
      const { value } = filter as IListsSearchFilter
      return <>{value}</>
    }
    case 'facet-search':
    case 'facet': {
      const { values, mask } = filter as IListsFacetFilter
      if (!values?.length) {
        return null
      }
      if (values.length === 1) {
        const [firstValue] = values
        return (
          <>
            {firstValue ? (
              <Masked
                text={firstValue}
                shouldMask={(shouldMask && mask) || false}
                unmaskedCount={1}
              />
            ) : (
              '(Blank)'
            )}
          </>
        )
      }
      return <>{values.length} Selected</>
    }
  }
}
