import { IProcessedStyleSet, ITheme } from '@fluentui/react'
import React from 'react'
import { compose } from 'redux'
import { IWithThemeProps, withTheme } from './withTheme'
type ObjectOnly<TArg> = TArg extends object ? TArg : object
export interface IWithClassesProps<TStyleSet> {
  classes: IProcessedStyleSet<ObjectOnly<TStyleSet>>
}

const withClassesHoc: <
  TStyleSet,
  K extends IWithClassesProps<TStyleSet> = IWithClassesProps<TStyleSet>
>(
  styles: (theme: ITheme) => IProcessedStyleSet<ObjectOnly<TStyleSet>>
) => (
  WrappedComponent: React.ComponentType<K>
) => React.FC<K & IWithThemeProps> =
  (styles) =>
  (WrappedComponent) =>
  ({ theme, ...props }) => {
    const classes = styles(theme)

    return <WrappedComponent classes={classes} {...(props as any)} />
  }

export const withClasses: typeof withClassesHoc = (styles) =>
  compose(withTheme, withClassesHoc(styles)) as any
