import { datahubApi } from 'store/api/datahub'

export interface IPeerCompAnalyticsResponse {
  meta?: ITeamAnalyticsMeta
  peerGroupDefinition?: PeerGroupDefinition
  teamWithRank?: ITeamAnalyticsWithRank
  peers?: ITeamAnalyticsTeam
  division?: ITeamAnalyticsTeam
  pwm?: ITeamAnalyticsTeam
}
export interface ITeamAnalyticsMeta {
  peers: string[]
  division: string[]
  pwm: string[]
  asOfDate: string
  minDate: Date
}
export interface PeerGroupDefinition {
  name: string
  min: number
  max: number
}
export interface ITeamAnalyticsWithRank {
  team?: ITeamAnalyticsTeam
  aumRank?: ITeamAnalyticsRank
  t12RevenueRank?: ITeamAnalyticsRank
  annualizedRevenueRank?: ITeamAnalyticsRank
}
export interface ITeamAnalyticsRank {
  teamName?: string
  total?: number
  totalRank?: number
  conversionRate?: number
  conversionRateRank?: number
}
export interface ITeamAnalyticsTeam {
  totalBorrowingCertificateAUM?: number
  teamName?: string
  aumDetHistory: IAumCategoryItem[]
  t12RevenueDetHistory: IRevenueCategoryItem[]
  annualizedRevenueDetHistory: IRevenueCategoryItem[]
  totalProdRevenue?: number
  onBoardingDate?: string
  regionName?: string
  dataAsOf?: string
}

export interface IAumCategoryItem {
  productType?: string
  accountType?: string
  aum?: number
}
export interface IRevenueCategoryItem {
  assetType?: string
  revenue?: number
}

export interface ITeam {
  teamName: string
  regionName: string
}

export interface ISummaryItem {
  category?: string
  marketvalue?: number
  percentOfTotal?: number
}

export interface IBusinessSummary {
  asOfDate?: string
  totalAum?: number
  managedTotal?: number
  brokerageTotal?: number
  aiTotal?: number
  otherTotal?: number
  managedSplit?: ISummaryItem[]
  managedGrouped?: ISummaryItem[]
  brokerageSplit?: ISummaryItem[]
  brokerageGrouped?: ISummaryItem[]
  managedAi?: ISummaryItem[]
  brokerageAi?: ISummaryItem[]
  other?: ISummaryItem[]
  allGroups?: ISummaryItem[]
}

export type TeamsResponse = ITeam[]

export const peerReviewAnalyticsApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<TeamsResponse, void>({
      query: () => `teams/visibility`
    }),
    getPeerGroupAnalyticsByTeamName: builder.query<
      IPeerCompAnalyticsResponse,
      { teamName: string; asOfdate?: Date }
    >({
      query: (payload) =>
        `teams/${payload.teamName}/peerGroupAnalytics${
          payload.asOfdate ? `d=${payload.asOfdate.toISOString()}` : ''
        }`
    }),
    getBusinessSummaryByTeamName: builder.query<
      IBusinessSummary,
      { teamName: string }
    >({
      query: (payload) => `teams/${payload.teamName}/businessSummary`
    }),
    getPWMAverageBusinessSummary: builder.query<IBusinessSummary, undefined>({
      query: () => `teams/pwmAverageSummary`
    })
  })
})

export const {
  useGetTeamsQuery,
  useGetPeerGroupAnalyticsByTeamNameQuery,
  useGetBusinessSummaryByTeamNameQuery,
  useGetPWMAverageBusinessSummaryQuery
} = peerReviewAnalyticsApi
