import axios from 'axios'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { isNotNullOrUndefined } from '../../../shared/gaurds'

export interface ITeamAnalyticsResponse {
  teams?: ITeamAnalyticsWithRank[]
  total?: ITeamAnalyticsTeam
  asOfDate?: string
}

export interface ITeamAnalyticsWithRank {
  team?: ITeamAnalyticsTeam
  aumRank?: ITeamAnalyticsRank
  t12RevenueRank?: ITeamAnalyticsRank
  annualizedRevenueRank?: ITeamAnalyticsRank
}

export interface ITeamAnalyticsRank {
  teamName?: string
  total?: number
  totalRank?: number
  conversionRate?: number
  conversionRateRank?: number
}

export interface ITeamAnalyticsTeam {
  totalBorrowingCertificateAUM?: number
  teamName?: string
  aumDetHistory?: IAumCategoryItem[]
  t12RevenueDetHistory?: IRevenueCategoryItem[]
  annualizedRevenueDetHistory?: IRevenueCategoryItem[]
  totalProdRevenue?: number
  onBoardingDate?: string
  regionName?: string
  priorFirm?: string
  dataAsOf?: string
}

export interface IAumCategoryItem {
  productType?: string
  accountType?: string
  aum?: number
}

export interface IRevenueCategoryItem {
  assetType?: string
  revenue?: number
}

export const getTeamAnalytics = (options: IApiOptions, asOfdate?: Date) => {
  const url = [
    `${options.apiRoot}/datahub/teams/analytics`,
    asOfdate && `d=${asOfdate.toISOString()}`
  ]
    .filter(isNotNullOrUndefined)
    .join('?')

  return axios
    .get<ITeamAnalyticsResponse>(url, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
