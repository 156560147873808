import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const accountFetchActions = createAsyncAction(
  '@features/@households/@householdDetail/@accounts/REQUEST',
  '@features/@households/@householdDetail/@accounts/SUCCESS',
  '@features/@households/@householdDetail/@accounts/FAILURE'
)<string, IAccount[] | undefined, Error>()

export const householdAccountsFetchReducer =
  createAsyncReducer(accountFetchActions)

const rootSelector = (state: AppState) =>
  state.features.households.householdAccountsFetch

export const {
  getError: getAccountsError,
  getIsLoading: getIsAccountsLoading,
  getResult: getAccountsResult
} = createAsyncSelectors(rootSelector)

const fetchAccounts = function* (
  action: ReturnType<typeof accountFetchActions.request>
) {
  if (!action.payload) {
    yield put(accountFetchActions.success(undefined))
    return
  }
  try {
    const result = yield* call(search, 'account' as const, {
      orderBy: [
        { dataPath: 'AccountKPIs/AccountTotal', direction: 'desc' as const }
      ],
      select: [
        'AccountKPIs',
        'AccountNickname',
        'ClientAdvisor',
        'ClientAdvisorID',
        'LegalEntityID',
        'LegalEntityName',
        'RelatedParties',
        'Shortname',
        'VendorTamarac',
        'accountStatus',
        'accounttype',
        'householdId',
        'householdName',
        'id',
        'revenueDet',
        'CustodyAccount',
        'registrationtype',
        'CustodianType',
        'CustodianName',
        'gfoCustodyAccount'
      ],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: action.payload,
              path: 'householdId',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    const accounts = result?.value

    if (!accounts) {
      throw new Error('Accounts for household not found')
    }

    yield put(accountFetchActions.success(accounts as IAccount[]))
  } catch (e: any) {
    yield put(accountFetchActions.failure(e))
  }
}

export const householdAccountsFetchSagas = [
  () => takeLatest(accountFetchActions.request, fetchAccounts)
]
