import styled from '@emotion/styled'

export const StyledTableWrapperTop = styled.div`
  table {
    tfoot {
      tr {
        min-height: 260px !important;
      }
    }
  }
`
export const StyledTableWrapperBottom = styled.div`
  table {
    tfoot {
      tr {
        min-height: 260px !important;
        margin-top: 10px !important;
      }
    }
  }
`
