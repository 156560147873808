import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty } from 'shared/gaurds'
import { trackException } from 'shared/services/telemetry'
import { AppState, useSelector } from 'store/shared'
import { getEnvironmentName } from 'store/system'
import { getRdotWsid } from 'store/user/selectors'
import { call, delay, put, select } from 'typed-redux-saga'
import { v4 as uuidv4 } from 'uuid'
import { IProfileApiContext } from './IProfileApiContext'
import { profileApi, useSetContextMutation } from './profileApi'

export interface ISetContextAccountPayloadItem {
  number: string
  repcode: string
  custodian: string
}

export interface ISetContextPayload {
  wsid: string
  accounts: ISetContextAccountPayloadItem[]
}
const createContextPayload = (req: ISetContextPayload) => {
  const { wsid, accounts: requestedAccounts } = req
  const accounts =
    requestedAccounts
      .filter((x) => x.custodian === 'nfs')
      .map(({ number, repcode }) => ({
        number,
        key: number,
        repcode,
        accountsourcetypecd: 'Rockefeller'
      })) || []

  const clientProfile: IProfileApiContext = {
    profile: {
      wsportaluserid: wsid,
      loggedInUserWealthscapePortalId: wsid,
      accounts,
      preferencejson: {
        pilotfeatures: [
          {
            componentname: 'BalSummaryV2',
            active: 'true'
          }
        ]
      }
    }
  }

  return clientProfile
}

interface IProfileApiContextState {
  id: string
  apiKey: string
  lastUpdated?: number
  context?: IProfileApiContext
  error?: Error
  isLoading: boolean
}

const generateContextId = (id: string) => `cd_${id}_${uuidv4()}`

export const createProfileApiContextSlice = (id: string) => {
  const apiKey = generateContextId(id)

  const initialState: IProfileApiContextState = {
    id,
    apiKey,
    isLoading: false
  }

  const slice = createSlice({
    name: `@features/@rdot360/@serverContextSlice/${id}`,
    initialState,
    reducers: {
      setContext: (state, action: PayloadAction<IProfileApiContext>) => ({
        ...state,
        lastUpdated: Date.now(),
        context: action.payload,
        error: undefined,
        isLoading: false
      }),
      setError: (state, action: PayloadAction<Error>) => ({
        ...state,
        error: action.payload,
        context: undefined,
        isLoading: false
      }),
      setIsLoading: (state, action: PayloadAction<boolean>) => ({
        ...state,
        isLoading: action.payload
      }),
      setLastUpdated: (state) => ({
        ...state,
        lastUpdated: Date.now()
      })
    }
  })

  return { slice, apiKey }
}

const selectWsid = createSelector(
  [getEnvironmentName, getRdotWsid],
  (environment, claimsWsid) =>
    environment === 'preprod' || environment === 'prod'
      ? claimsWsid
      : '5511000024'
)

const contextTimeout = 30 * 60 * 1000
const contextCheckInterval = 5 * 60 * 1000
const empty: string[] = []
export const createProfileApiContextStore = (
  rootSelector: (state: AppState) => IProfileApiContextState,
  actions: ReturnType<typeof createProfileApiContextSlice>['slice']['actions']
) => {
  const selectApiContext = createSelector(
    rootSelector,
    ({ context }) => context
  )

  const selectApiContextAccounts = createSelector(
    selectApiContext,
    (context) =>
      context?.profile.accounts
        ?.map(({ number }) => number)
        .filter(isNotNullOrEmpty)
        .sort() || empty
  )

  const selectLastUpdated = createSelector(
    rootSelector,
    ({ lastUpdated }) => lastUpdated
  )

  const selectApiKey = createSelector(rootSelector, ({ apiKey }) => apiKey)

  const selectApiContextError = createSelector(
    rootSelector,
    ({ error }) => error
  )

  const selectIsSetApiContextLoading = createSelector(
    rootSelector,
    ({ isLoading }) => isLoading || false
  )

  const saga_setContext = function* (key: string, context: IProfileApiContext) {
    const action = profileApi.endpoints.setContext.initiate({ key, context })
    const promise = yield* put(action)
    promise.unsubscribe()
    return yield* call(promise.unwrap)
  }

  const saga_refreshStaleContext = function* () {
    const lastUpdated = yield* select(selectLastUpdated)
    const apiKey = yield* select(selectApiKey)
    const context = yield* select(selectApiContext)
    const isLoading = yield* select(selectIsSetApiContextLoading)

    const isStale = lastUpdated && Date.now() - lastUpdated > contextTimeout

    if (isLoading || !isStale || !context) {
      return
    }

    console.info('api context is stale', apiKey)

    try {
      yield* call(saga_setContext, apiKey, context)
      yield* put(actions.setLastUpdated())
    } catch (e) {
      yield* call(trackException, e as Error)
    }
  }

  const saga_watchContext = function* () {
    while (true) {
      yield* call(saga_refreshStaleContext)
      yield* call(delay, contextCheckInterval)
    }
  }

  const useProfileApiContext = () => {
    const dispatch = useDispatch()
    const { context: currentContext, apiKey } = useSelector(rootSelector)
    const [setApiContext] = useSetContextMutation()
    const wsid = useSelector(selectWsid)

    const setError = useCallback(
      (error: Error) => {
        dispatch(actions.setError(error))
      },
      [dispatch]
    )

    const setIsLoading = useCallback(
      (isLoading: boolean) => dispatch(actions.setIsLoading(isLoading)),
      [dispatch]
    )

    const setStoreContext = useCallback(
      (context: IProfileApiContext) => dispatch(actions.setContext(context)),
      [dispatch]
    )

    const setContextInternal = useCallback(
      async (context: IProfileApiContext) => {
        setIsLoading(true)

        try {
          await setApiContext({ key: apiKey, context })
        } catch (e: unknown) {
          setError(
            (e as Error) ||
              new Error('Unknown error occurred while setting API Context')
          )
        }
        setStoreContext(context)
      },
      [apiKey, setApiContext, setError, setIsLoading, setStoreContext]
    )

    const setContext = useCallback(
      async (accounts: ISetContextAccountPayloadItem[]) => {
        if (!wsid) {
          setError(new Error(`Unable to determine user's wsid`))
          return
        }
        const context = createContextPayload({ wsid, accounts })
        setContextInternal(context)
      },
      [wsid, setContextInternal, setError]
    )

    const apiContextAccounts = useSelector(selectApiContextAccounts)
    const isLoading = useSelector(selectIsSetApiContextLoading)
    const error = useSelector(selectApiContextError)

    const result = useMemo(
      () => ({
        context: currentContext,
        apiContextAccounts,
        isLoading,
        error,
        setContext,
        apiKey
      }),
      [apiContextAccounts, apiKey, currentContext, error, isLoading, setContext]
    )

    return result
  }

  return { useProfileApiContext, saga_watchContext }
}
