import { IColumnDefinition } from '../core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  accountNumber = 'accountNumber',
  symbol = 'symbol',
  description = 'description',
  cusip = 'cusip',
  securitytype = 'securitytype',
  securitySubtype = 'securitySubtype',
  underlyingoptionquantity = 'underlyingoptionquantity',
  tradedatequantity = 'tradedatequantity',
  price = 'price',
  marketvalue = 'marketvalue',
  unrealizedgainloss = 'unrealizedgainloss',
  costBasis = 'costBasis',
  accruedinterest = 'accruedinterest',
  modelallocation = 'modelallocation',
  washsellindicator = 'washsellindicator',
  accountallocation = 'accountallocation',
  modelmember = 'modelmember',
  businesssegment = 'businesssegment',
  registeredrep = 'registeredrep',
  holdingdate = 'holdingdate',
  LastUpdatedAt = 'LastUpdatedAt',
  driftvalue = 'driftvalue',
  optioncontractid = 'optioncontractid',
  optionsymbolid = 'optionsymbolid',
  partyname = 'partyname',
  optioncallputind = 'optioncallputind',
  optionexpdate = 'optionexpdate',
  drift = 'drift',
  custodianName = 'custodianName',
  custodianType = 'custodianType',
  advisorName = 'advisorName',
  householdId = 'householdId',
  householdName = 'householdName',
  nickname = 'nickname',
  shortname = 'shortname',
  registrationType = 'registrationType',
  assetType = 'assetType',
  assetTypeDetail = 'assetTypeDetail',
  DrawdownNonDrawdown = 'DrawdownNonDrawdown',
  OriginalOrderAmount = 'OriginalOrderAmount',
  CapitalCall = 'CapitalCall',
  RemainingOriginalAmount = 'RemainingOriginalAmount',
  Distributions = 'Distributions',
  TotalValue = 'TotalValue',
  capitalCommitment = 'capitalCommitment',
  Unfunded_Value = 'Unfunded_Value',
  symbolCusipDesc = 'symbolCusipDesc',
  ValuationDate = 'ValuationDate',
  netInvestmentGainLoss = 'netInvestmentGainLoss',
  originalOrderDate = 'originalOrderDate',
  tvpi = 'tvpi',
  vintageYear = 'vintageYear',
  ai_divReinvest = 'ai_divReinvest',
  ai_cleanFundName = 'ai_cleanFundName',
  ai_sponsorName = 'ai_sponsorName',
  ai_dividends = 'ai_dividends',
  ai_dividends_cash = 'ai_dividends_cash',
  ai_dividends_reinvest = 'ai_dividends_reinvest',
  ai_totalReturnPercentage = 'ai_totalReturnPercentage',
  ai_valuationReceivedDate = 'ai_valuationReceivedDate',
  ai_expectedTiming = 'ai_expectedTiming',
  ai_k1EstimateAvailable = 'ai_k1EstimateAvailable',
  ai_taxReporting = 'ai_taxReporting'
}

export const PositionColumnIds = { ...ColumnIdsEnum }
export type PositionColumnIds = typeof PositionColumnIds

export const PositionColumnDefinitions: Record<string, IColumnDefinition> = {
  [PositionColumnIds.accountNumber]: {
    id: PositionColumnIds.accountNumber,
    select: ['accountnumber', 'nickname', 'shortName', 'registrationType'],
    name: 'Account Number',
    type: 'string',
    width: 175,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'accountnumber'
  },
  [PositionColumnIds.symbol]: {
    id: PositionColumnIds.symbol,
    name: 'Symbol',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 75,
    dataPath: 'symbol'
  },
  [PositionColumnIds.description]: {
    id: PositionColumnIds.description,
    name: 'Description',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 250,
    dataPath: 'description'
  },
  [PositionColumnIds.cusip]: {
    id: PositionColumnIds.cusip,
    select: ['cusip', 'securitytype'],
    name: 'CUSIP',
    type: 'string',
    width: 95,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'cusip'
  },
  [PositionColumnIds.marketvalue]: {
    id: PositionColumnIds.marketvalue,
    name: 'Market Value',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'marketvalue'
  },
  [PositionColumnIds.securitytype]: {
    id: PositionColumnIds.securitytype,
    name: 'Product Type',
    type: 'string',
    width: 130,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'securitytype'
  },
  [PositionColumnIds.assetType]: {
    id: PositionColumnIds.assetType,
    name: 'Asset Class',
    type: 'string',
    width: 130,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'assetClassLevel1'
  },
  [PositionColumnIds.assetTypeDetail]: {
    id: PositionColumnIds.assetTypeDetail,
    name: 'Asset Class Detail',
    type: 'string',
    width: 130,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'assetClassLevel3'
  },
  [PositionColumnIds.securitySubtype]: {
    id: PositionColumnIds.securitySubtype,
    name: 'Product Sub Type',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'securitySubtype'
  },
  [PositionColumnIds.underlyingoptionquantity]: {
    id: PositionColumnIds.underlyingoptionquantity,
    name: 'Underlying Option Quantity',
    type: 'number',
    width: 115,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'underlyingoptionquantity'
  },
  [PositionColumnIds.tradedatequantity]: {
    id: PositionColumnIds.tradedatequantity,
    name: 'Current Quantity',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'tradedatequantity'
  },
  [PositionColumnIds.unrealizedgainloss]: {
    id: PositionColumnIds.unrealizedgainloss,
    name: 'Unrealized Gain / Loss',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'unrealizedgainloss'
  },
  [PositionColumnIds.costBasis]: {
    id: PositionColumnIds.costBasis,
    name: 'Cost Basis',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'costBasisAmount'
  },
  [PositionColumnIds.price]: {
    id: PositionColumnIds.price,
    name: 'COB Price',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'price'
  },
  [PositionColumnIds.accruedinterest]: {
    id: PositionColumnIds.accruedinterest,
    name: 'Accrued Interest',
    type: 'number',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'accruedinterest'
  },
  [PositionColumnIds.modelallocation]: {
    id: PositionColumnIds.modelallocation,
    name: 'Model Allocation',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'modelallocation'
  },
  [PositionColumnIds.washsellindicator]: {
    id: PositionColumnIds.washsellindicator,
    name: 'Wash Sale',
    type: 'string',
    width: 50,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'washsellindicator'
  },
  [PositionColumnIds.accountallocation]: {
    id: PositionColumnIds.accountallocation,
    name: 'Account Allocation',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'accountallocation'
  },
  [PositionColumnIds.modelmember]: {
    id: PositionColumnIds.modelmember,
    name: 'Model Member',
    type: 'string',
    width: 60,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'modelmember'
  },
  [PositionColumnIds.businesssegment]: {
    id: PositionColumnIds.businesssegment,
    name: 'Business Segment',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'businesssegment'
  },
  [PositionColumnIds.registeredrep]: {
    id: PositionColumnIds.registeredrep,
    name: 'Advisor Id',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'registeredrep',
    mask: true
  },
  [PositionColumnIds.holdingdate]: {
    id: PositionColumnIds.holdingdate,
    name: 'As Of Date',
    type: 'date',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'holdingdate'
  },
  [PositionColumnIds.LastUpdatedAt]: {
    id: PositionColumnIds.LastUpdatedAt,
    name: 'Last Updated Date',
    type: 'date',
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'LastUpdatedAt'
  },
  [PositionColumnIds.driftvalue]: {
    id: PositionColumnIds.driftvalue,
    name: 'Drift Value',
    type: 'number',
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'driftvalue'
  },
  [PositionColumnIds.drift]: {
    id: PositionColumnIds.drift,
    name: 'Model Drift',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'drift'
  },
  [PositionColumnIds.optioncontractid]: {
    id: PositionColumnIds.optioncontractid,
    name: 'Option Contract Id',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'optioncontractid'
  },
  [PositionColumnIds.optioncallputind]: {
    id: PositionColumnIds.optioncallputind,
    name: 'Call / Put',
    type: 'string',
    width: 60,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'optioncallputind'
  },
  [PositionColumnIds.optionexpdate]: {
    id: PositionColumnIds.optionexpdate,
    name: 'Option Expiration Date',
    type: 'date',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'optionexpdate'
  },
  [PositionColumnIds.optionsymbolid]: {
    id: PositionColumnIds.optionsymbolid,
    name: 'Option Symbol Id',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'optionsymbolid'
  },
  [PositionColumnIds.partyname]: {
    id: PositionColumnIds.partyname,
    name: 'Legal Entity Name',
    type: 'string',
    width: 220,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'LegalEntityName'
  },
  [PositionColumnIds.custodianName]: {
    id: PositionColumnIds.custodianName,
    name: 'Custodian Name',
    dataPath: 'CustodianName',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [PositionColumnIds.custodianType]: {
    id: PositionColumnIds.custodianType,
    name: 'Custodian Type',
    dataPath: 'CustodianType',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [PositionColumnIds.advisorName]: {
    id: PositionColumnIds.advisorName,
    name: 'Advisor',
    dataPath: 'advisorName',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    searchable: true,
    mask: true
  },
  [PositionColumnIds.householdId]: {
    id: PositionColumnIds.householdId,
    name: 'Household Id',
    dataPath: 'householdId',
    type: 'string',
    width: 120,
    filterable: true,
    sortable: true,
    facetable: false,
    searchable: true
  },
  [PositionColumnIds.householdName]: {
    id: PositionColumnIds.householdName,
    name: 'Household Name',
    dataPath: 'householdName',
    type: 'string',
    width: 180,
    filterable: true,
    sortable: true,
    facetable: false,
    searchable: true
  },
  [PositionColumnIds.registrationType]: {
    id: PositionColumnIds.registrationType,
    name: 'Registration Type',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'registrationType'
  },
  [PositionColumnIds.nickname]: {
    id: PositionColumnIds.nickname,
    name: 'Nickname',
    dataPath: 'nickname',
    type: 'string',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [PositionColumnIds.shortname]: {
    id: PositionColumnIds.shortname,
    name: 'Short Name',
    dataPath: 'shortName',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [PositionColumnIds.DrawdownNonDrawdown]: {
    id: PositionColumnIds.DrawdownNonDrawdown,
    name: 'Drawdown',
    dataPath: 'DrawdownNonDrawdown',
    type: 'string',
    width: 50,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [PositionColumnIds.OriginalOrderAmount]: {
    id: PositionColumnIds.OriginalOrderAmount,
    name: 'Original Subscription',
    dataPath: 'OriginalOrderAmount',
    select: ['OriginalOrderAmount', 'DrawdownNonDrawdown'],
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.CapitalCall]: {
    id: PositionColumnIds.CapitalCall,
    name: 'Capital Called*',
    dataPath: 'CapitalCall',
    select: ['CapitalCall', 'DrawdownNonDrawdown'],
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.RemainingOriginalAmount]: {
    id: PositionColumnIds.RemainingOriginalAmount,
    name: 'Total Investment Amount',
    dataPath: 'RemainingOriginalAmount',
    select: ['RemainingOriginalAmount', 'DrawdownNonDrawdown'],
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.Distributions]: {
    id: PositionColumnIds.Distributions,
    name: 'Distributions to Date',
    dataPath: 'Distributions',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.TotalValue]: {
    id: PositionColumnIds.TotalValue,
    name: 'Total Value',
    dataPath: 'TotalValue',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.capitalCommitment]: {
    id: PositionColumnIds.capitalCommitment,
    name: 'Committed Capital',
    dataPath: 'capitalCommitment',
    select: ['capitalCommitment', 'DrawdownNonDrawdown'],
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.Unfunded_Value]: {
    id: PositionColumnIds.Unfunded_Value,
    name: 'Remaining Capital',
    dataPath: 'Unfunded_Value',
    select: ['Unfunded_Value', 'DrawdownNonDrawdown'],
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.netInvestmentGainLoss]: {
    id: PositionColumnIds.netInvestmentGainLoss,
    name: 'Cumulative Investment Return',
    dataPath: 'UnrealizedGainOrloss',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.symbolCusipDesc]: {
    id: PositionColumnIds.symbolCusipDesc,
    name: 'Description / Symbol / CUSIP',
    select: ['symbol', 'cusip', 'description'],
    searchFields: ['symbol', 'cusip', 'description'],
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 250,
    dataPath: 'description'
  },
  [PositionColumnIds.ValuationDate]: {
    id: PositionColumnIds.ValuationDate,
    name: 'Valuation Date',
    type: 'date-only',
    width: 90,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'ENTRY_DATE'
  },
  [PositionColumnIds.originalOrderDate]: {
    id: PositionColumnIds.originalOrderDate,
    name: 'Original Order Date',
    type: 'date',
    dataPath: 'OriginalOrderDate',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: false
  },
  [PositionColumnIds.vintageYear]: {
    id: PositionColumnIds.vintageYear,
    select: ['VintageYear', 'OriginalOrderDate'],
    name: 'Vintage Year',
    type: 'date-only',
    dataPath: 'VintageYear',
    width: 80,
    filterable: false,
    sortable: false,
    searchable: false
  },
  [PositionColumnIds.tvpi]: {
    id: PositionColumnIds.tvpi,
    name: 'TVPI',
    type: 'number',
    width: 60,
    filterable: false,
    sortable: false,
    searchable: false
  },
  [PositionColumnIds.ai_divReinvest]: {
    id: PositionColumnIds.ai_divReinvest,
    name: 'Div Reinvest',
    dataPath: 'DivReInvest',
    type: 'string',
    width: 60,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_cleanFundName]: {
    id: PositionColumnIds.ai_cleanFundName,
    name: 'Fund Name',
    dataPath: 'CleanFundName',
    type: 'string',
    width: 180,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false
  },
  [PositionColumnIds.ai_sponsorName]: {
    id: PositionColumnIds.ai_sponsorName,
    name: 'Fund Sponsor',
    dataPath: 'SponsorName',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_dividends]: {
    id: PositionColumnIds.ai_dividends,
    name: 'Total Dividends',
    dataPath: 'dividends',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_dividends_cash]: {
    id: PositionColumnIds.ai_dividends_cash,
    name: 'Dividends - Cash',
    dataPath: 'DivCash',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_dividends_reinvest]: {
    id: PositionColumnIds.ai_dividends_reinvest,
    name: 'Dividends - Reinvested',
    dataPath: 'DivReinvested',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_totalReturnPercentage]: {
    id: PositionColumnIds.ai_totalReturnPercentage,
    name: 'Return %',
    dataPath: 'TotalReturnPercentage',
    type: 'number',
    width: 60,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_valuationReceivedDate]: {
    id: PositionColumnIds.ai_valuationReceivedDate,
    name: 'Valuation Received Date',
    type: 'date-only',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    dataPath: 'ValuationReceivedDate'
  },
  [PositionColumnIds.ai_expectedTiming]: {
    id: PositionColumnIds.ai_expectedTiming,
    name: 'Expected Timing',
    dataPath: 'ExpectedTiming',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_k1EstimateAvailable]: {
    id: PositionColumnIds.ai_k1EstimateAvailable,
    name: 'K1 Estimate Avlb (Y/N)',
    dataPath: 'K1EstimateAvailable',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  },
  [PositionColumnIds.ai_taxReporting]: {
    id: PositionColumnIds.ai_taxReporting,
    name: 'Tax Reporting',
    dataPath: 'TaxReporting',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true
  }
}
