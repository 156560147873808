import {
  Checkbox,
  DefaultButton,
  FontSizes,
  ITheme,
  makeStyles,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { format } from 'date-fns'
import { isString } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentSystemuserQuery } from 'store/api/dynamics'
import { AccountLinkingRequestStatusEnum } from '../../../../api/dynamics'
import { LoadingComponent } from '../../../../shared/components/Loading'
import {
  getIsClientOnboardingAdmin,
  getRdotUserDisplayName,
  getIsInRoleAdvisoryOlt
} from '../../../../store/user/selectors'
import { AccountLinkingAccountList } from '../../AccountLinkingAccountList'
import {
  getAccountLinkingValidationSubject,
  getAccountLinkingValidationVerification
} from '../../store/accountLinkingValidation'
import {
  accountLinkingRequestViewPanelActions,
  getAccountLinkingPanelValidations,
  getAccountLinkingRequestViewPanelAccounts,
  getAccountLinkingRequestViewPanelError,
  getAccountLinkingRequestViewPanelNfsProfile,
  getAccountLinkingRequestViewPanelRequest,
  getAccountLinkingRequestViewPanelStatus,
  getIsAccountLinkingRequestPanelProcessing,
  getIsAccountLinkingRequestViewPanelLoading,
  getIsAccountLinkingRequestViewPanelOpen
} from './store/accountLinkingRequestViewPanel'

const useClasses = makeStyles((theme: ITheme) => {
  const detailSection = {
    border: `solid 1px ${theme.palette.neutralLight}`,
    boxShadow: theme.effects.elevation4,
    '& header': {
      backgroundColor: theme.palette.neutralLighterAlt,
      padding: '5px 10px'
    }
  }

  return {
    detailSection
  }
})

const KeyValueInfoList: React.FC<{
  items: Record<string, any>
  minWidth?: number
}> = ({ items, minWidth = 100 }) => {
  return (
    <Stack horizontal={true} wrap={true} tokens={{ padding: 10 }}>
      {Object.entries(items).map(([key, value]) => {
        const valueIsString = isString(value)
        return (
          <Stack.Item
            grow={1}
            key={key}
            styles={{
              root: {
                maxWidth: '100%',
                minWidth: `${minWidth}px`,
                flexBasis: 0,
                margin: '3px'
              }
            }}
          >
            <Stack>
              <Text
                styles={{
                  root: {
                    fontWeight: 'bold',
                    fontSize: FontSizes.size12
                  }
                }}
              >
                {key}
              </Text>
              <Text
                title={valueIsString ? (value as string) : undefined}
                nowrap={true}
              >
                {value || '--'}
              </Text>
            </Stack>
          </Stack.Item>
        )
      })}
    </Stack>
  )
}

const NotesSection: React.FC<{
  notes?: string
  requestId?: string
}> = () => {
  const request = useSelector(getAccountLinkingRequestViewPanelRequest)
  const dispatch = useDispatch()
  const [showNoteField, setShowNoteField] = useState(false)
  const [newNoteValue, setNewNoteValue] = useState('')
  const onNewNoteChange = useCallback((ev: any, text?: string) => {
    setNewNoteValue(text || '')
  }, [])
  const userDisplayName = useSelector(getRdotUserDisplayName)
  const submitNote = useCallback(() => {
    if (!request?.rcm_accountlinkingrequestid) {
      return
    }
    const newNote =
      (request.rcm_notes || '') +
      (request.rcm_notes?.length || 0 > 0 ? '\n' : '') +
      userDisplayName +
      ' - ' +
      format(new Date(), 'M/d/yyyy h:mm a') +
      '\n' +
      newNoteValue +
      '\n'
    dispatch(
      accountLinkingRequestViewPanelActions.addNote({
        requestId: request.rcm_accountlinkingrequestid,
        note: newNote
      })
    )
    setNewNoteValue('')
    setShowNoteField(false)
  }, [
    dispatch,
    newNoteValue,
    request?.rcm_accountlinkingrequestid,
    request?.rcm_notes,
    userDisplayName
  ])
  const displayText = (request?.rcm_notes?.split('\n\n') || []).map((note) =>
    note.split('\n')
  )
  return (
    <Stack tokens={{ childrenGap: 5, padding: 10 }}>
      <Stack tokens={{ childrenGap: 10 }}>
        {displayText.map((note, index) => (
          <Stack key={index}>
            {note.map((note, index2) => (
              <div key={index2}>
                {index2 === 0 ? (
                  <Text
                    key={index2}
                    variant="small"
                    style={{ fontWeight: 'bold' }}
                  >
                    {note}
                  </Text>
                ) : (
                  <Text key={index2}>{note}</Text>
                )}
              </div>
            ))}{' '}
          </Stack>
        ))}{' '}
      </Stack>
      {showNoteField ? (
        <Stack tokens={{ childrenGap: 5 }}>
          <TextField value={newNoteValue} onChange={onNewNoteChange} />
          <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
            <PrimaryButton onClick={() => submitNote()}>Submit</PrimaryButton>
            <DefaultButton onClick={() => setShowNoteField(false)}>
              Cancel
            </DefaultButton>
          </Stack>
        </Stack>
      ) : (
        <PrimaryButton onClick={() => setShowNoteField(true)}>
          Add Note
        </PrimaryButton>
      )}
    </Stack>
  )
}

export const AccountLinkingRequestsViewPanel: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useClasses()
  const isPanelOpen = useSelector(getIsAccountLinkingRequestViewPanelOpen)
  const isOlt = useSelector(getIsInRoleAdvisoryOlt)
  const isClientOnboardingAdmin = useSelector(getIsClientOnboardingAdmin)
  const validations = useSelector(getAccountLinkingPanelValidations)
  const request = useSelector(getAccountLinkingRequestViewPanelRequest)
  const nfsProfile = useSelector(getAccountLinkingRequestViewPanelNfsProfile)
  const { data: systemuser } = useGetCurrentSystemuserQuery()
  const error = useSelector(getAccountLinkingRequestViewPanelError)
  const isLoading = useSelector(getIsAccountLinkingRequestViewPanelLoading)
  const isProcessing = useSelector(getIsAccountLinkingRequestPanelProcessing)
  const status = useSelector(getAccountLinkingRequestViewPanelStatus)
  const verification = useSelector(getAccountLinkingValidationVerification)
  const subject = useSelector(getAccountLinkingValidationSubject)
  const validationAccounts = useSelector(
    getAccountLinkingRequestViewPanelAccounts
  )
  const isOwner = useMemo(
    () => systemuser?.systemuserid === request?._owninguser_value,
    [request, systemuser]
  )
  const [portalId, setPortalId] = useState<string | undefined>('')
  const [attestationIsChecked, setAttestationIsChecked] = useState(false)
  useEffect(() => {
    if (!isPanelOpen) {
      return
    }

    setAttestationIsChecked(false)
  }, [isPanelOpen])
  const handleCheckboxChange = useCallback(
    () => setAttestationIsChecked(!attestationIsChecked),
    [attestationIsChecked]
  )
  const showAttestation = useMemo(
    () =>
      (request?.rcm_status === AccountLinkingRequestStatusEnum.REQUESTED &&
        isOlt) ||
      (request?.rcm_status === AccountLinkingRequestStatusEnum.REJECTED &&
        isOwner),
    [isOlt, isOwner, request?.rcm_status]
  )
  const onPortalIdChange = useCallback((ev: any, text?: string) => {
    setPortalId(text)
  }, [])
  const onDismiss = useCallback(() => {
    dispatch(accountLinkingRequestViewPanelActions.close())
  }, [dispatch])

  const onApprove = useCallback(() => {
    if (!isOlt || !request?.rcm_accountlinkingrequestid) {
      return
    }

    dispatch(
      accountLinkingRequestViewPanelActions.approve(
        request.rcm_accountlinkingrequestid
      )
    )
  }, [dispatch, isOlt, request?.rcm_accountlinkingrequestid])
  const onResubmit = useCallback(() => {
    if (!isOwner || !request?.rcm_accountlinkingrequestid) {
      return
    }

    dispatch(
      accountLinkingRequestViewPanelActions.resubmit(
        request.rcm_accountlinkingrequestid
      )
    )
  }, [dispatch, isOwner, request?.rcm_accountlinkingrequestid])

  const onSubmitPortalId = useCallback(() => {
    if (
      !isClientOnboardingAdmin ||
      !request?.rcm_accountlinkingrequestid ||
      !portalId
    ) {
      return
    }

    dispatch(
      accountLinkingRequestViewPanelActions.setPortalId({
        requestId: request?.rcm_accountlinkingrequestid,
        portalId
      })
    )
  }, [
    dispatch,
    isClientOnboardingAdmin,
    portalId,
    request?.rcm_accountlinkingrequestid
  ])

  const onReject = useCallback(() => {
    if (!isOlt || !request?.rcm_accountlinkingrequestid) {
      return
    }

    dispatch(
      accountLinkingRequestViewPanelActions.reject(
        request.rcm_accountlinkingrequestid
      )
    )
  }, [dispatch, isOlt, request?.rcm_accountlinkingrequestid])

  const onRenderFooterContent = useCallback(() => {
    return (
      <>
        {(isProcessing || isLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }} />
        )}
        <Stack tokens={{ childrenGap: 10 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              An error occurred while submitting the request
              {error.message ? `: ${error.message}` : ''}
            </MessageBar>
          )}
          {showAttestation && (
            <Stack horizontal={true}>
              <Checkbox
                checked={attestationIsChecked}
                onChange={handleCheckboxChange}
              />
              <MessageBar messageBarType={MessageBarType.warning}>
                Linking these accounts will give the third party access to the
                client’s household summary statement, which may include accounts
                the owner did not intend for the third party to view. By
                submitting this request you acknowledge that you have reviewed
                the household statement linking for accounts being linked and
                that there are no privacy concerns.
              </MessageBar>
            </Stack>
          )}

          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            {request?.rcm_status ===
              AccountLinkingRequestStatusEnum.REQUESTED &&
              isOlt && (
                <PrimaryButton
                  onClick={onApprove}
                  disabled={!attestationIsChecked}
                >
                  Approve
                </PrimaryButton>
              )}
            {request?.rcm_status === AccountLinkingRequestStatusEnum.REJECTED &&
              isOwner && (
                <PrimaryButton
                  onClick={onResubmit}
                  disabled={!attestationIsChecked}
                >
                  Resubmit
                </PrimaryButton>
              )}
            {request?.rcm_requesttype === 'new' &&
              request?.rcm_status ===
                AccountLinkingRequestStatusEnum.APPROVED &&
              isClientOnboardingAdmin && (
                <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                  <TextField
                    placeholder="Set Portal Id"
                    onChange={onPortalIdChange}
                  />
                  <PrimaryButton
                    onClick={onSubmitPortalId}
                    disabled={!portalId}
                  >
                    Submit
                  </PrimaryButton>
                </Stack>
              )}
            {(request?.rcm_status ===
              AccountLinkingRequestStatusEnum.REQUESTED ||
              request?.rcm_status ===
                AccountLinkingRequestStatusEnum.APPROVED) &&
              isOlt && <PrimaryButton onClick={onReject}>Reject</PrimaryButton>}
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </>
    )
  }, [
    attestationIsChecked,
    error,
    handleCheckboxChange,
    isOlt,
    isClientOnboardingAdmin,
    isLoading,
    isOwner,
    isProcessing,
    onApprove,
    onDismiss,
    onPortalIdChange,
    onReject,
    onResubmit,
    onSubmitPortalId,
    portalId,
    request?.rcm_requesttype,
    request?.rcm_status,
    showAttestation
  ])

  const verificationItems = {
    Date: verification?.date && format(verification.date, 'MM/dd/yyyy'),
    Time: verification?.time,
    Client: verification?.client,
    Method: verification?.method
  }

  const clientItems = {
    Name: nfsProfile?.fullname,
    Email: nfsProfile?.loginid,
    Id: nfsProfile?.wsportaluserid,
    Role: nfsProfile?.role,
    'Rep Code': nfsProfile?.repcode,
    'MFA Phone': nfsProfile?.mfaPhones
  }

  return (
    <Panel
      headerText={subject}
      isOpen={isPanelOpen}
      onOuterClick={() => {
        return
      }}
      onDismiss={onDismiss}
      isBlocking={true}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      type={PanelType.custom}
      customWidth="780px"
      styles={{
        content: { flexGrow: 1 },
        commands: { paddingBottom: '10px' }
      }}
    >
      {(isProcessing || isLoading) && (
        <Overlay styles={{ root: { zIndex: 1 } }}>
          <LoadingComponent status={status} />
        </Overlay>
      )}

      <Stack tokens={{ childrenGap: 20 }}>
        {request?.rcm_requesttype !== 'delink' && (
          <Stack className={classes.detailSection}>
            <header>
              <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
                Warnings
              </Text>
            </header>
            {validations &&
              validations.length > 0 &&
              validations.map((validation, i) => (
                <MessageBar
                  messageBarType={MessageBarType.severeWarning}
                  key={i}
                >
                  {validation}
                </MessageBar>
              ))}
            {!validations.length && (
              <MessageBar messageBarType={MessageBarType.success}>
                No validation warnings
              </MessageBar>
            )}
          </Stack>
        )}

        <Stack className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Notes
            </Text>
          </header>
          <NotesSection
            notes={request?.rcm_notes || ''}
            requestId={request?.rcm_accountlinkingrequestid}
          />
        </Stack>

        <Stack className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Client
            </Text>
          </header>
          <KeyValueInfoList items={clientItems} minWidth={200} />
        </Stack>

        {request?.rcm_requesttype !== 'delink' && (
          <Stack className={classes.detailSection}>
            <header>
              <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
                Verification
              </Text>
            </header>
            <KeyValueInfoList items={verificationItems} />
          </Stack>
        )}

        <Stack className={classes.detailSection}>
          <header>
            <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
              Accounts
            </Text>
          </header>
          <AccountLinkingAccountList
            accounts={validationAccounts}
            loading={isLoading}
          />
        </Stack>
      </Stack>
    </Panel>
  )
}
