import { ActionType, createAction } from 'typesafe-actions'
import {
  PaymentMM,
  PaymentRow,
  RefreshByRole,
  SearchSortFilter,
  Statistics
} from './types'

export const FETCH_PAYMENT_LIST =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST'
export const FETCH_PAYMENT_LIST_SUCCESS =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST_SUCCESS'
export const FETCH_PAYMENT_LIST_FAILURE =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST_FAILURE'

export const SET_SELECTED_USER_ROLE =
  '@modules/@transfers/@dashboard/SET_SELECTED_USER_ROLE'

export const SET_SELECTED_PAYMENTITEM =
  '@modules/@transfers/@dashboard/SET_SELECTED_PAYMENTITEM'

export const NAVIGATE_TO_PAYMENT_EDIT =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_EDIT'
export const NAVIGATE_TO_PAYMENT_REVIEW =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_REVIEW'
export const NAVIGATE_TO_PAYMENT_VIEW =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_VIEW'
export const NAVIGATE_TO_PAYMENT_UPDATE_FED_REF =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_FED_REF'
export const NAVIGATE_TO_PAYMENT_UPDATE_DFD =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_DFD'
export const NAVIGATE_TO_PAYMENT_UPDATE_DFACK =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_DFACK'

export const SET_PAYMENT_SEARCH_SORT_FILTER =
  '@modules/@transfers/@dashboard/SET_PAYMENT_SEARCH_SORT_FILTER'
export const SET_PAYMENT_AUTOREFRESH_FLAG =
  '@modules/@transfers/@dashboard/SET_PAYMENT_AUTOREFRESH_FLAG'

export const fetchPaymentListDataActions = {
  request: createAction(FETCH_PAYMENT_LIST)<{
    role: string
    fromDate?: string
    toDate?: string
    useTrnDate?: string
  }>(),
  success: createAction(FETCH_PAYMENT_LIST_SUCCESS)<{
    paymentList: PaymentRow[]
    statistics: Statistics[]
  }>(),
  failure: createAction(FETCH_PAYMENT_LIST_FAILURE)<Error>()
}

export const setUserRoleActions = {
  setrole: createAction(SET_SELECTED_USER_ROLE)<string>()
}

export const setPaymentSearchSortFilterActions = {
  setSearchSortFilter: createAction(
    SET_PAYMENT_SEARCH_SORT_FILTER
  )<SearchSortFilter>()
}
export const setPaymentAutoRefreshFlagActions = {
  setAutoRefreshFlag: createAction(SET_PAYMENT_AUTOREFRESH_FLAG)<
    RefreshByRole[]
  >()
}

export const setSelectedPaymentItemActions = {
  setPaymentItem: createAction(SET_SELECTED_PAYMENTITEM)<PaymentMM>()
}

export const paymentDashboardNavigationActions = {
  navigateToEdit: createAction(NAVIGATE_TO_PAYMENT_EDIT)(),
  navigateToReview: createAction(NAVIGATE_TO_PAYMENT_REVIEW)(),
  navigateToView: createAction(NAVIGATE_TO_PAYMENT_VIEW)(),
  navigateToUpdateFedRef: createAction(NAVIGATE_TO_PAYMENT_UPDATE_FED_REF)(),
  navigateToUpdateDFDisclosure: createAction(NAVIGATE_TO_PAYMENT_UPDATE_DFD)(),
  navigateToUpdateDFAcknowledge: createAction(
    NAVIGATE_TO_PAYMENT_UPDATE_DFACK
  )()
}

export type FetchPaymentListActionType = ActionType<
  typeof fetchPaymentListDataActions
>
export type setSelectedPaymentItemActionType = ActionType<
  typeof setSelectedPaymentItemActions
>
export type SetUserRoleActionType = ActionType<typeof setUserRoleActions>
export type setPaymentSearchSortFilterActionType = ActionType<
  typeof setPaymentSearchSortFilterActions
>

export type DashboardActionTypes =
  | FetchPaymentListActionType
  | SetUserRoleActionType
  | setPaymentSearchSortFilterActionType
  | setSelectedPaymentItemActionType
  | ActionType<typeof setPaymentAutoRefreshFlagActions>
