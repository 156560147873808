import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { keyBy, orderBy } from 'lodash'
import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { useGetIndividualsFromHouseholdQuery } from '../datahub'
import { useGetContactsFromHouseholdQuery } from '../dynamics'
import { useRdot360Context } from './rdot360Context'

export const useRdot360ClientContext = () => {
  const { selectedHouseholdId } = useRdot360Context()

  const { data: clients, isFetching: isClientsFetching } =
    useGetIndividualsFromHouseholdQuery(
      selectedHouseholdId ? selectedHouseholdId : skipToken
    )

  const clientLookup = useMemo(() => keyBy(clients, 'LegalEntityID'), [clients])

  const emails = useMemo(
    () =>
      clients
        ?.map((client) => client.contactdetails?.emailid)
        .filter(isNotNullOrUndefined)
        .sort(),
    [clients]
  )

  const {
    data,
    isFetching: isContactsFetching,
    error: contactsError
  } = useGetContactsFromHouseholdQuery(selectedHouseholdId || '', {
    skip: !selectedHouseholdId
  })

  const contacts = useMemo(
    () => orderBy(data, (x) => x.rpm_headofhousehold, 'desc'),
    [data]
  )
  const contactsIds = useMemo(
    () =>
      contacts
        ?.map((x) => x?.contactid)
        ?.filter(isNotNullOrUndefined)
        ?.sort() || [],
    [contacts]
  )

  const headOfHousehold = useMemo(
    () =>
      contacts?.[0]?.rpm_headofhousehold
        ? clientLookup[contacts?.[0]?.rcm_rockcodbpartyid || '']
        : undefined,
    [clientLookup, contacts]
  )

  const isContactsExist = useMemo(
    () => contacts && contacts.length > 0,
    [contacts]
  )

  return {
    clients,
    isClientsFetching,
    emails,
    contacts,
    isContactsFetching,
    contactsIds,
    contactsError,
    clientLookup,
    headOfHousehold,
    isContactsExist
  }
}
