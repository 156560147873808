import { skipToken } from '@reduxjs/toolkit/dist/query'
import { IAccount } from 'api/account.types'
import { IHousehold } from 'api/household.types'
import { fromPairs, groupBy, keyBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { makeArrayFromString } from 'shared'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/gaurds'
import { AppState } from 'store'
import {
  selectNexGenCouncilAccounts,
  useGetNextGenCouncilAccountsQuery
} from './nextGenCouncilAccountApi'
import {
  selectNextGenCouncilConfig,
  useGetNextGenCouncilConfigQuery
} from './nextGenCouncilConfigApi'
import {
  selectNexGenCouncilHouseholds,
  useGetNextGenCouncilHouseholdsQuery
} from './useNextGenCouncilHouseholdApi'

const emptyArray: [] = []
const selectMemberAccounts = createSelector(
  selectNextGenCouncilConfig,
  selectNexGenCouncilAccounts,
  (config, accounts) => {
    if (!config?.value?.length || !accounts.length) {
      return
    }

    const configMembers = config.value

    const memberToHouseholdDefinitions = configMembers.map(
      ({
        rcm_nextgencouncilmemberid,
        rcm_rcm_nextgenxref_member
      }): [string, string[]] => [
        rcm_nextgencouncilmemberid || '',
        rcm_rcm_nextgenxref_member
          ?.map(({ rcm_householdid }) => rcm_householdid)
          .filter(isNotNullOrEmpty) ?? emptyArray
      ]
    )

    const memberToHouseholdLookup = fromPairs(memberToHouseholdDefinitions)
    const accountLookupByHouseholdId = groupBy(
      accounts,
      ({ householdId }) => householdId
    )
    const accountLookupByLegalEntityId = groupBy(
      accounts,
      ({ LegalEntityID }) => LegalEntityID
    )

    const memberWithAccounts = configMembers
      .map(
        ({
          rcm_nextgencouncilmemberid,
          rcm_legalentityid
        }): [
          string,
          (
            | { householdAccounts: IAccount[]; entityAccounts: IAccount[] }
            | undefined
          )
        ] => {
          const households =
            (rcm_nextgencouncilmemberid &&
              memberToHouseholdLookup[rcm_nextgencouncilmemberid]) ||
            emptyArray
          const householdAccounts = households.flatMap(
            (x) => accountLookupByHouseholdId[x] || emptyArray
          )

          const legalEntityIds = makeArrayFromString(rcm_legalentityid)
          const entityAccounts = legalEntityIds.flatMap(
            (x) => accountLookupByLegalEntityId[x] || emptyArray
          )

          return [
            rcm_nextgencouncilmemberid || '',
            { householdAccounts, entityAccounts }
          ]
        }
      )
      .filter(([key]) => key)

    const memberLookupById = fromPairs(memberWithAccounts)
    return memberLookupById
  }
)

const selectHouseholdsByMember = createSelector(
  selectNextGenCouncilConfig,
  selectNexGenCouncilHouseholds,
  (config, households) => {
    if (!config?.value?.length || !households.length) {
      return
    }

    const householdLookupByHouseholdId = keyBy(
      households,
      ({ householdId }) => householdId || ''
    )

    const pairs = config.value.map(
      ({
        rcm_nextgencouncilmemberid,
        rcm_rcm_nextgenxref_member
      }): [string, IHousehold[]] => [
        rcm_nextgencouncilmemberid || '',
        (rcm_rcm_nextgenxref_member || emptyArray)
          .map((x) =>
            x.rcm_householdid
              ? householdLookupByHouseholdId[x.rcm_householdid]
              : undefined
          )
          .filter(isNotNullOrUndefined)
      ]
    )

    return fromPairs(pairs)
  }
)

export const useNextGenCouncil = () => {
  const {
    data: nextGenCouncilConfig,
    isFetching: isNextGenCouncilConfigFetching,
    error: nextGenCouncilConfigError
  } = useGetNextGenCouncilConfigQuery()

  const householdIds = useMemo(
    () =>
      nextGenCouncilConfig
        ? nextGenCouncilConfig.value
            ?.flatMap((x) =>
              x.rcm_rcm_nextgenxref_member?.map((x) => x.rcm_householdid)
            )
            .filter(isNotNullOrEmpty)
        : undefined,
    [nextGenCouncilConfig]
  )

  const {
    data: nextGenHouseholdAccounts,
    isFetching: isNextGenHouseholdAccountsFetching,
    error: nextGenHouseholdAccountsError
  } = useGetNextGenCouncilAccountsQuery(householdIds ? householdIds : skipToken)

  const {
    data: nextGenHouseholds,
    isFetching: isNextGenHouseholdsFetching,
    error: nextGenHouseholdsError
  } = useGetNextGenCouncilHouseholdsQuery(
    householdIds ? householdIds : skipToken
  )

  const cachedAccountsByMemberSelector = useCallback(
    (state: AppState) =>
      householdIds ? selectMemberAccounts(state, householdIds) : undefined,
    [householdIds]
  )

  const cachedHouseholdsByMemberSelector = useCallback(
    (state: AppState) =>
      householdIds ? selectHouseholdsByMember(state, householdIds) : undefined,
    [householdIds]
  )

  const accountsByMember = useSelector(cachedAccountsByMemberSelector)
  const householdsByMember = useSelector(cachedHouseholdsByMemberSelector)

  return {
    isFetching:
      isNextGenCouncilConfigFetching ||
      isNextGenHouseholdAccountsFetching ||
      isNextGenHouseholdsFetching,
    error:
      nextGenCouncilConfigError ||
      nextGenHouseholdAccountsError ||
      nextGenHouseholdsError,
    nextGenCouncilConfig,
    nextGenHouseholdAccounts,
    nextGenHouseholds,
    accountsByMember,
    householdsByMember
  }
}
