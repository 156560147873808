import { Theme, css } from '@emotion/react'

export const getGainLossesIncomeDataTableClasses = (theme: Theme) => ({
  container: css({ height: '100%', fontSize: '12px', marginTop: '10px' }),
  tableContainer: css({
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    padding: '0px 10px',
    border: '2px solid',
    borderColor: theme.colors.secondaryWhite
  }),
  tableRow: css({
    borderBottom: `1px solid ${theme.colors.secondaryWhite}`,
    paddingRight: '5px',
    paddingLeft: '5px'
  }),
  column: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px'
  }),
  fixedColumn: css({
    position: 'sticky',
    left: 0,
    zIndex: 9,
    background: theme.colors.primaryWhite
  }),
  staticColumn: css({
    position: 'static',
    left: 'unset',
    zIndex: 0,
    background: 'unset'
  })
})
