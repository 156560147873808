import { IPosition } from 'api/position.types'
import { PositionColumnDefinitions } from 'features/Lists/positions/ColumnDefinitions'
import { postionExportDefinitions } from 'features/Lists/positions/ExportDefinitions'
import { PositionCell } from 'features/Lists/positions/PositionCell'
import { partial } from 'lodash/fp'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { createListsStore } from '../../../../../../../../features/Lists/core/store'
import {
  createDefaultColumnState,
  createFilter,
  mapColumnsToFilterDefinitions
} from '../../../../../../../../features/Lists/shared/lists'
import { AppState } from '../../../../../../../../store'

export const constructInitialAiPositionListColumnState = partial(
  createDefaultColumnState,
  [PositionColumnDefinitions]
)

export const positiontListFilterDefinitions = mapColumnsToFilterDefinitions(
  PositionColumnDefinitions
)

export const aiPositionListStore = createListsStore<IPosition>(
  'position',
  '@features/@lists/@aipositions',
  {
    columnDefinitions: PositionColumnDefinitions,
    filterDefinitions: positiontListFilterDefinitions
  },
  (state: AppState) =>
    state.modules.advisory.modules.ai.modules.dashboard.features.aiPositionList,
  postionExportDefinitions
)

export const { reducer, sagas, actions, selectors } = aiPositionListStore

export const createAiPositionListFilter = partial(createFilter, [
  PositionColumnDefinitions
])

export const AiPositionListComponents = createListsComponents(
  actions,
  selectors,
  PositionCell
)
