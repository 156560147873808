import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addBusinessDays, endOfDay, startOfDay } from 'date-fns'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export type TimeRange = 'immediate' | 'future' | 'past' | 'all'

const { actions, reducer } = createSlice({
  name: 'taskPanel',
  initialState: {} as ITaskPanelState,
  reducers: {
    open: (_, action: PayloadAction<TimeRange>) => ({
      isOpen: true,
      timeRange: action.payload
    }),
    close: (state) => ({
      ...state,
      isOpen: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setTimeRange: (state, action: PayloadAction<TimeRange>) => ({
      ...state,
      timeRange: action.payload
    }),
    setItemToReassign: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      itemToReassign: action.payload
    })
  }
})

export { reducer as taskPanelReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.tasks.taskPanel

const getIsPanelOpen = flow(rootSelector, (x) => x.isOpen)
const getPanelError = flow(rootSelector, (x) => x.error)
const getType = flow(rootSelector, (x) => x.timeRange)
const getItemToReassign = flow(rootSelector, (x) => x.itemToReassign)

export interface ITaskPanelState {
  isOpen?: boolean
  error?: Error
  timeRange: TimeRange
  itemToReassign?: string
}

export const useTaskPanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(
    (range: TimeRange) => {
      dispatch(actions.open(range))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setTimeRange = useCallback(
    (range: TimeRange) => {
      dispatch(actions.setTimeRange(range))
    },
    [dispatch]
  )

  const setItemToReassign = useCallback(
    (item?: string) => {
      dispatch(actions.setItemToReassign(item))
    },
    [dispatch]
  )

  const isOpen = useSelector(getIsPanelOpen)

  const error = useSelector(getPanelError)

  const range = useSelector(getType)

  const itemToReassign = useSelector(getItemToReassign)

  const getStartDate = useCallback((range: TimeRange) => {
    switch (range) {
      case 'immediate':
      case 'future':
        return startOfDay(new Date()).toISOString()
      default:
        return undefined
    }
  }, [])

  const getEndDate = useCallback((range: TimeRange) => {
    switch (range) {
      case 'immediate':
        return endOfDay(addBusinessDays(new Date(), 5)).toISOString()
      case 'past':
        return new Date().toISOString()
      default:
        return undefined
    }
  }, [])

  const startDate = useMemo(() => {
    return getStartDate(range)
  }, [getStartDate, range])
  const endDate = useMemo(() => {
    return getEndDate(range)
  }, [getEndDate, range])

  return {
    close,
    open,
    setError,
    setTimeRange,
    isOpen,
    error,
    range,
    getStartDate,
    getEndDate,
    startDate,
    endDate,
    itemToReassign,
    setItemToReassign
  }
}
