import { ExpandedState } from '@tanstack/react-table'
import { keyBy, sumBy } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { HighchartsComponent } from 'shared/highcharts'
import {
  investmentsTableColumnNames,
  useInvestmentsTableStore
} from '../../modules/Investments/features/InvestmentsTable/store'
import { ICategoryPostionResponseValue } from '../../store/holdingsApi/ICategoryPostitionResponse'
import assetTaxonomy from './../../../../../../metadata/asset-taxonomy.json'

const getAssetsAllocationOptions = (
  data: ICategoryPostionResponseValue[] = [],
  totalValue = 0,
  onLegendClick: (assetClass: string) => void,
  colors: string[] = []
): Highcharts.Options => ({
  title: {
    text:
      totalValue === 0
        ? ''
        : totalValue > 0
        ? 'Total<br> $' + Math.round(totalValue).toLocaleString('en-US')
        : 'Total<br> -$' +
          Math.round(Math.abs(totalValue)).toLocaleString('en-US'),
    align: 'center',
    verticalAlign: 'middle',
    x: -90,
    y: 10
  },
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '<p>${point.y}</p>' + '<br>{point.percentage:.1f}%</br>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },

  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        }
      }
    },
    pie: {
      events: {
        click: (ev) => {
          ev?.preventDefault && ev.preventDefault()
          const assetClassChartValue = ev?.point?.name
          onLegendClick(assetClassChartValue)
        }
      },
      allowPointSelect: true,
      cursor: 'pointer',
      point: {
        events: {
          legendItemClick: (ev) => {
            ev?.preventDefault && ev.preventDefault()
            const assetClassValue = ev?.target?.name
            onLegendClick(assetClassValue)
          }
        }
      },
      showInLegend: true,
      borderWidth: 0,
      colors: colors,
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [
    {
      type: 'pie',
      innerSize: '80%',
      data: data
        ?.map((item) => ({
          name:
            item.assetcat === 'Cash' ? 'Cash and Equivalents' : item.assetcat,
          y: Math.round(item.mktval || 0),
          color: `#${
            keyBy(assetTaxonomy, (x) => x.description)[
              item.assetcat || 'Others'
            ]?.color ||
            keyBy(assetTaxonomy, (x) => x.description)['Others']?.color
          }`
        }))
        .sort((a, b) => (b.y || 0) - (a.y || 0))
    }
  ],
  credits: {
    enabled: false
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    enabled: true,
    symbolRadius: 0,
    layout: 'vertical',
    align: 'right',
    itemWidth: 150,
    verticalAlign: 'middle'
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 300
        }
      }
    ]
  }
})

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}
export const AssetsAllocationChart: FC<{
  data?: ICategoryPostionResponseValue[]
  selectedType: string
}> = ({ data = [], selectedType }) => {
  const navigate = useNavigate()
  const { setGrouping, setDefaultExpanded } = useInvestmentsTableStore()
  const totalValue = useMemo(
    () => sumBy(data, ({ mktval = 0 }) => mktval),
    [data]
  )
  const onLegendClick = useCallback(
    (assetClassValue: string) => {
      if (assetClassValue && selectedType === 'L1') {
        const parameter: ExpandedState = {
          [`${investmentsTableColumnNames.assetClass}:${assetClassValue}`]: true
        }
        navigate('../investments')
        setDefaultExpanded(parameter)
        setGrouping([
          investmentsTableColumnNames.assetClass,
          investmentsTableColumnNames.secid
        ])
      }
    },
    [navigate, selectedType, setDefaultExpanded, setGrouping]
  )

  const assetsAllocationOptions = useMemo(
    () => getAssetsAllocationOptions(data, totalValue, onLegendClick),
    [data, totalValue, onLegendClick]
  )

  return (
    <HighchartsComponent
      containerProps={containerProps}
      options={assetsAllocationOptions}
    />
  )
}
