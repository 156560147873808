import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { IBalanceDetailResponseValue } from './IBalanceDetailResponse'

export interface IBalancesApiBaseValueResponse<T> {
  value: T[]
}

export interface IBalancesApiBaseRequest {
  accounts?: string[]
  contextId: string
}

const { cacheTime } = apiConstants

type BalancesApiTagType = 'balancesDetail'
const balancesApiTags: BalancesApiTagType[] = ['balancesDetail']
const balancesApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: balancesApiTags
})

const getDefaultTags = (
  type: BalancesApiTagType,
  req: IBalancesApiBaseRequest
): TagDescription<BalancesApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const baseTransformResponse = <T>(response: IBalancesApiBaseValueResponse<T>) =>
  response?.value?.[0]

const providesTags =
  (type: BalancesApiTagType) =>
  (_: unknown, _1: unknown, req: IBalancesApiBaseRequest) =>
    getDefaultTags(type, req)

const balancesApi = balancesApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBalancesDetail: builder.query<
      IBalanceDetailResponseValue | undefined,
      IBalancesApiBaseRequest
    >({
      query: ({ contextId }) => {
        return {
          url: 'balance/odata/balancedetails',
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse,
      providesTags: providesTags('balancesDetail')
    })
  })
})

export const { useGetBalancesDetailQuery } = balancesApi

export const useBalancesApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<BalancesApiTagType>[]) =>
      dispatch(balancesApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
