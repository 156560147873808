import styled from '@emotion/styled'

export const ToolTipWrapper = styled.div`
  /*tooltip Box*/
  .con-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  /*tooltip */
  .tooltip {
    min-width: 250px;
    display: none;
    z-index: 1;
    opacity: 0.4;
    padding: 0px 6px;
    background: #ffffff;
    color: #000;
    position: absolute;
    top: -140%;
    left: -25%;
    border-radius: 4px;
    font: 16px;
    transform: translateY(9px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  }
  .con-tooltip:hover .tooltip {
    display: block;
    opacity: 1;
  }
  .bottom .tooltip {
    top: 100%;
    left: -200%;
  }
`
