import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { endOfYear, format, startOfYear, subDays, subYears } from 'date-fns'
import { flow } from 'lodash/fp'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { IActivityDurationOptions } from './ActivityDetailView'

const { actions, reducer } = createSlice({
  name: 'activityDetailsUiState',
  initialState: {
    dateSelection: 'Last 10 Days',
    searchText: '',
    isDownloading: false
  } as IActivityDetailUiState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | undefined>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string | undefined>) => {
      state.endDate = action.payload
    },
    setDateSelection: (
      state,
      action: PayloadAction<IActivityDurationOptions>
    ) => {
      state.dateSelection = action.payload
    },
    setCategories: (state, action: PayloadAction<string[] | undefined>) => {
      state.categories = action.payload
    },
    setCustomRange: (state, action: PayloadAction<string>) => {
      state.customRange = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setDateRangeString: (state, action: PayloadAction<string>) => {
      state.dateRangeString = action.payload
    },
    setIsDownloading: (state, action: PayloadAction<boolean | undefined>) => {
      state.isDownloading = action.payload
    }
  }
})

export { reducer as activityDetailUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.activity.activityDetailUiState

const getStartDate = flow(rootSelector, (x) => x.startDate)
const getEndDate = flow(rootSelector, (x) => x.endDate)
const getDateSelection = flow(rootSelector, (x) => x.dateSelection)
const getCategories = flow(rootSelector, (x) => x.categories)
const getCustomRange = flow(rootSelector, (x) => x.customRange)
const getSearchText = flow(rootSelector, (x) => x.searchText)
const getDateRangeString = flow(rootSelector, (x) => x.dateRangeString)
const getIsDownloading = flow(rootSelector, (x) => x.isDownloading)
export interface IActivityDetailUiState {
  startDate?: string
  endDate?: string
  dateSelection: IActivityDurationOptions
  categories?: string[]
  customRange?: string
  searchText: string
  dateRangeString?: string
  isDownloading?: boolean
}

export const useActivityDetailUiState = () => {
  const dispatch = useDispatch()

  const setStartDate = useCallback(
    (startDate?: string) => {
      dispatch(actions.setStartDate(startDate))
    },
    [dispatch]
  )

  const setEndDate = useCallback(
    (endDate?: string) => {
      dispatch(actions.setEndDate(endDate))
    },
    [dispatch]
  )

  const setDateSelection = useCallback(
    (selection?: IActivityDurationOptions) => {
      if (!selection) {
        return
      }
      dispatch(actions.setDateSelection(selection))
    },
    [dispatch]
  )

  const setCategories = useCallback(
    (categories?: string[]) => {
      dispatch(actions.setCategories(categories))
    },
    [dispatch]
  )

  const startDate = useSelector(getStartDate)

  const endDate = useSelector(getEndDate)

  const dateSelection = useSelector(getDateSelection)

  const categories = useSelector(getCategories)

  const customRange = useSelector(getCustomRange)

  const formatDate = useCallback((date: Date) => format(date, 'yyy-MM-dd'), [])

  const setCustomRange = useCallback(() => {
    if (startDate && endDate) {
      dispatch(actions.setCustomRange(`${startDate},${endDate}`))
    }
  }, [dispatch, endDate, startDate])

  const dateRangeString = useSelector(getDateRangeString)

  const setDateRangeString = useCallback(
    (dateSring: string) => {
      dispatch(actions.setDateRangeString(dateSring))
    },
    [dispatch]
  )

  const setIsDownloading = useCallback(
    (value?: boolean) => {
      dispatch(actions.setIsDownloading(value))
    },
    [dispatch]
  )

  const setRangeForDay = useCallback(
    async (date: string) => {
      setDateSelection('Custom Range')
      setStartDate(date)
      setEndDate(date)
      setDateRangeString(`FromTo,${date},${date}`)
    },
    [setDateRangeString, setDateSelection, setEndDate, setStartDate]
  )

  useEffect(() => {
    const currentDate = new Date()
    switch (dateSelection) {
      case 'Last 10 Days':
        setDateRangeString(
          `FromTo,${formatDate(subDays(currentDate, 9))},${formatDate(
            currentDate
          )}`
        )
        return
      case 'Last 30 Days':
        setDateRangeString(
          `FromTo,${formatDate(subDays(currentDate, 29))},${formatDate(
            currentDate
          )}`
        )
        return
      case 'Last 90 Days':
        setDateRangeString(
          `FromTo,${formatDate(subDays(currentDate, 89))},${formatDate(
            currentDate
          )}`
        )
        return
      case 'Previous Year':
        setDateRangeString(
          `FromTo,${formatDate(
            startOfYear(subYears(currentDate, 1))
          )},${formatDate(endOfYear(subYears(currentDate, 1)))}`
        )
        return
      case 'Year to Date':
        setDateRangeString(
          `FromTo,${formatDate(startOfYear(currentDate))},${formatDate(
            currentDate
          )}`
        )
        return
      case 'Custom Range':
        if (customRange) {
          setDateRangeString(`FromTo,${customRange}`)
        }
        return
    }
  }, [customRange, dateSelection, formatDate, setDateRangeString])

  const searchText = useSelector(getSearchText)
  const isDownloading = useSelector(getIsDownloading)
  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )
  return {
    setStartDate,
    setEndDate,
    setDateSelection,
    setCategories,
    startDate,
    endDate,
    dateSelection,
    categories,
    setCustomRange,
    dateRangeString,
    searchText,
    setSearchText,
    setRangeForDay,
    isDownloading,
    setIsDownloading
  }
}
