import { MessageBar, Stack, Text, useTheme } from '@fluentui/react'
import { groupBy, keyBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { Separator } from '../../../../shared/components/Separator'
import { isNotNullOrUndefined } from '../../../../shared/gaurds'
import { useDomainStore } from '../../store/domain'
import { AdvisorRepTreeHeader } from './AdvisorRepTreeHeader'
import { RepSelectorListItem } from './RepSelectorListItem'
import { ITreeLeaf, ITreeNode, SelectableTree } from './SelectableTree'
import { useAdvisorRepTreeStore } from './store/advisorRepTree'

export const AdvisorRepTree: React.FC = () => {
  const {
    selected,
    updateSelected,
    collapsed,
    updateCollapsed,
    filteredBusinessUnits,
    filteredAdvisorReps,
    enableSelection
  } = useAdvisorRepTreeStore()

  const { userAdvisorReps, teamAdvisorReps, rootBusinessunitIds } =
    useDomainStore()

  const onTreeSelectedChanged = useCallback(
    (newSelected: string[]) => {
      updateSelected(newSelected)
    },
    [updateSelected]
  )

  const onTreeCollapsedChanged = useCallback(
    (newCollapsed: string[]) => {
      updateCollapsed(newCollapsed)
    },
    [updateCollapsed]
  )

  const onAdvisorClicked = useCallback(() => {
    updateSelected(userAdvisorReps?.map(({ rcm_repid }) => rcm_repid))
  }, [userAdvisorReps, updateSelected])

  const onTeamClicked = useCallback(() => {
    updateSelected(teamAdvisorReps?.map(({ rcm_repid }) => rcm_repid))
  }, [teamAdvisorReps, updateSelected])

  const onSelectAll = useCallback(() => {
    updateSelected(filteredAdvisorReps.map(({ id }) => id))
  }, [updateSelected, filteredAdvisorReps])

  const filteredLeafs = useMemo(
    () =>
      filteredAdvisorReps?.map(
        ({ id, team }): ITreeLeaf => ({ id, parentId: team?.id || 'OTHER' })
      ),
    [filteredAdvisorReps]
  )

  const filteredNodes = useMemo(() => {
    const buParentGroups = groupBy(
      filteredBusinessUnits,
      ({ parentId }) => parentId
    )

    const mapChildren = (businessUnitId?: string): ITreeNode[] => {
      const buChildren =
        (businessUnitId &&
          buParentGroups[businessUnitId]
            ?.map((x) => ({
              id: x.id || '',
              children: mapChildren(x.id)
            }))
            .filter(isNotNullOrUndefined)) ||
        []

      return buChildren
    }

    return [
      ...rootBusinessunitIds.flatMap((id) => mapChildren(id)),
      { id: 'OTHER' }
    ]
  }, [filteredBusinessUnits, rootBusinessunitIds])

  const contextRepsLookup = useMemo(
    () => keyBy(filteredAdvisorReps, ({ id }) => id),
    [filteredAdvisorReps]
  )

  const contextBusinessUnitsLookup = useMemo(
    () => keyBy(filteredBusinessUnits, ({ id }) => id),
    [filteredBusinessUnits]
  )

  const TreeNodeComponent = useMemo(
    (): React.FC<{ id: string }> =>
      ({ id }) => {
        const businessUnit = contextBusinessUnitsLookup[id]
        if (businessUnit) {
          return <Text>{businessUnit.name}</Text>
        }

        if (id === 'OTHER') {
          return <Text>Other Advisors</Text>
        }

        const rep = contextRepsLookup[id]
        if (rep) {
          return (
            <Text nowrap={true} title={rep.name}>
              {rep.id} - {rep.name}
            </Text>
          )
        }

        return null
      },
    [contextBusinessUnitsLookup, contextRepsLookup]
  )

  const theme = useTheme()

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <Stack.Item
        styles={{
          root: {
            paddingBottom: '10px',
            position: 'sticky',
            top: '82px',
            zIndex: 2,
            backgroundColor: theme.semanticColors.bodyBackground
          }
        }}
      >
        <AdvisorRepTreeHeader />
      </Stack.Item>
      {!!userAdvisorReps?.length && (
        <RepSelectorListItem
          text="Select My Rep IDs"
          iconName="Contact"
          onClick={onAdvisorClicked}
        />
      )}

      {!!teamAdvisorReps?.length && (
        <RepSelectorListItem
          text="Select My Team's Rep IDs"
          iconName="Teamwork"
          onClick={onTeamClicked}
        />
      )}
      <RepSelectorListItem
        text="View Everything"
        subtext="View all available recipients"
        iconName="BoxCheckmarkSolid"
        onClick={onSelectAll}
      />
      <Separator />
      <SelectableTree
        leafs={filteredLeafs}
        nodes={filteredNodes}
        selected={selected}
        TreeNodeComponent={TreeNodeComponent}
        collapsed={collapsed}
        onCollapsedChanged={onTreeCollapsedChanged}
        onSelectedChanged={onTreeSelectedChanged}
        disabled={!enableSelection}
      />
      {!filteredAdvisorReps.length && <MessageBar>No results found</MessageBar>}
    </Stack>
  )
}
