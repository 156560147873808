import {
  Icon,
  Label,
  Link,
  makeStyles,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { INfsProfile } from '../../api/datahub'
import { AutoComplete } from '../../shared/components/AutoComplete'
import { isNotNullOrUndefined } from '../../shared/gaurds'
import {
  accountLinkingValidationActions,
  getAccountLinkingValidationRequestType,
  getAccountLinkingValidationSelectedClients
} from './store/accountLinkingValidation'
import {
  accountLinkingClientSearchFetchActions,
  getAccountLinkingClientSearchFetchLoading,
  getAccountLinkingClientSearchFetchResult
} from './store/clientSearchFetch'

const useClasses = makeStyles((theme) => ({
  clientListContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    padding: '2px 5px'
  },
  clientListItem: {
    borderBottom: `solid 1px ${theme.palette.neutralLight}`,
    paddingTop: '2px',
    paddingBottom: '2px'
  }
}))

export const ExistingClient3rdPartySearch: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useClasses()
  const [parsedInputAccounts] = useState<string[]>([])

  const validInputAccounts = useMemo(
    () => parsedInputAccounts.sort().filter((number) => number.length === 9),
    [parsedInputAccounts]
  )

  const validInputAccountsKey = useMemo(
    () => validInputAccounts?.join(''),
    [validInputAccounts]
  )

  useEffect(() => {
    dispatch(
      accountLinkingValidationActions.setInputAccounts(validInputAccounts)
    )
    dispatch(accountLinkingValidationActions.setValidatedAccounts([]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, validInputAccountsKey])

  const selectedClients = useSelector(
    getAccountLinkingValidationSelectedClients
  )
  const [clientSearchText, setClientSearchText] = useState<string>()
  const onClientSearchTextChanged = useCallback((text?: string) => {
    setClientSearchText(text)
  }, [])

  const requestType = useSelector(getAccountLinkingValidationRequestType)

  useEffect(() => {
    dispatch(accountLinkingClientSearchFetchActions.request(clientSearchText))
  }, [clientSearchText, dispatch])

  const onClientSearchItemSelected = useCallback(
    (item?: INfsProfile) => {
      const newSelectedClients = uniqBy(
        [...(selectedClients || []), item].filter(isNotNullOrUndefined),
        (x) => x.id
      )
      setClientSearchText('')
      dispatch(accountLinkingValidationActions.setValidatedAccounts([]))

      dispatch(
        accountLinkingValidationActions.setSelectedClients(newSelectedClients)
      )
    },
    [dispatch, selectedClients]
  )

  const onClientRemoved = useCallback(
    (clientId?: number) => {
      if (clientId == null) {
        return
      }

      const index = selectedClients?.findIndex(({ id }) => clientId === id)
      if (index == null || index < 0) {
        return
      }

      const selectedClientsCopy = [...(selectedClients || [])]
      selectedClientsCopy.splice(index, 1)
      dispatch(
        accountLinkingValidationActions.setSelectedClients(selectedClientsCopy)
      )
    },
    [dispatch, selectedClients]
  )

  const clientSearchItems = useSelector(
    getAccountLinkingClientSearchFetchResult
  )
  const isClientSearchLoading = useSelector(
    getAccountLinkingClientSearchFetchLoading
  )

  const onSelectedClientRoleChange = useCallback(
    (editedId?: number, role?: string) => {
      if (!selectedClients?.length) {
        return
      }
      const selectedClientIndex = selectedClients?.findIndex(
        ({ id }) => id === editedId
      )
      if (selectedClientIndex == null || selectedClientIndex < 0) {
        return
      }

      const newSelectedClient = {
        ...selectedClients[selectedClientIndex],
        role
      }

      const selectedClientsCopy = [...selectedClients]
      selectedClientsCopy.splice(selectedClientIndex, 1, newSelectedClient)
      dispatch(
        accountLinkingValidationActions.setSelectedClients(selectedClientsCopy)
      )
    },
    [dispatch, selectedClients]
  )

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack>
        <Label required>
          2. Search for and Select an Existing Client or 3rd Party
        </Label>
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar>
            For 3rd Parties, enter the relationship of the 3rd party to the
            client, i.e.: CPA, Attorney, Family Member
          </MessageBar>
          {(requestType !== 'delink' || !selectedClients?.length) && (
            <AutoComplete<INfsProfile>
              value={clientSearchText}
              onSearchTextChanged={onClientSearchTextChanged}
              items={clientSearchItems || []}
              onItemSelected={onClientSearchItemSelected}
              itemComponent={ClientSearchAutoCompleteItem}
              showLoadingIndicator={isClientSearchLoading}
              placeholder={'Search'}
            />
          )}
        </Stack>
      </Stack>

      <div>
        {!selectedClients?.length && (
          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            No clients selected.
          </MessageBar>
        )}
        {!!selectedClients?.length && (
          <Stack className={classes.clientListContainer}>
            {selectedClients.map(
              ({ fullname, loginid, emailprimary, role, id }) => (
                <Stack
                  key={id}
                  horizontal={true}
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  tokens={{ childrenGap: 10 }}
                  className={classes.clientListItem}
                >
                  <Stack.Item grow={1}>
                    <Stack horizontal={true} horizontalAlign="space-between">
                      <Stack>
                        <Text>{fullname}</Text>
                        <Text variant="small">
                          <MailTo email={loginid || emailprimary} />
                        </Text>
                      </Stack>
                      <TextField
                        value={role}
                        placeholder="Role"
                        onChange={(ev, newValue) =>
                          onSelectedClientRoleChange(id, newValue)
                        }
                      />
                    </Stack>
                  </Stack.Item>
                  <div style={{ width: '14px' }}>
                    <Icon
                      title="Remove"
                      iconName="Cancel"
                      onClick={() => onClientRemoved(id)}
                      styles={{ root: { cursor: 'pointer' } }}
                    />
                  </div>
                </Stack>
              )
            )}
          </Stack>
        )}
      </div>
    </Stack>
  )
}

const MailTo: React.FC<{ email?: string }> = ({ email }) =>
  email ? (
    <Link
      title={email}
      href={`mailto:${email}`}
      onClick={(e) => e.stopPropagation()}
    >
      {email}
    </Link>
  ) : null

const ClientSearchAutoCompleteItem: React.FC<{ item?: INfsProfile }> = ({
  item
}) => {
  const { fullname, role, loginid, emailprimary } = item || {}
  return (
    <Stack styles={{ root: { padding: '5px 10px', minWidth: 0 } }}>
      <Stack horizontal={true} horizontalAlign="space-between">
        <Text>{fullname}</Text>
        <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
          {role}
        </Text>
      </Stack>
      <Text variant="small">{loginid || emailprimary}</Text>
    </Stack>
  )
}
