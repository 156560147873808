import { trim } from 'lodash'
import { flow } from 'lodash/fp'
import { all, call, delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAccount } from '../../api/account.types'
import { IAdvisorDetailResult } from '../../api/advisor.types'
import { IClient } from '../../api/client.types'
import { ISearchParams, ISearchResult } from '../../api/common.types'
import { IServicePrincipal } from '../../api/graph'
import { notNullOrEmpty } from '../../shared'
import { AppState } from '../../store'
import { accountContextSelectionActions } from '../../store/context/account'
import { clientContextUpdateActions } from '../../store/context/client'
import { domainContextActions } from '../../store/context/domain'
import { safeSearch } from '../../store/shared/sagas'
import { navigationActions } from '../../store/ui/actions'
import { ICombinedSearchResults } from './shared/ICombinedSearchResults'

const UPDATE_SEARCH_TEXT = '@features/@search/UPDATE_SEARCH_TEXT'

export const searchBoxActions = {
  updateSearchText: createAction(UPDATE_SEARCH_TEXT)<string | undefined>()
}

const REQUESTED = '@features/@search/REQUESTED'
const SUCCESS = '@features/@search/SUCCESS'
const FAILURE = '@features/@search/FAILURE'

export const searchActions = {
  request: createAction(REQUESTED)<string>(),
  success: createAction(SUCCESS)<ICombinedSearchResults>(),
  failure: createAction(FAILURE)<Error>()
}

const CLIENT_SELECTED = '@features/@search/CLIENT_SELECTED'
const ACCOUNT_SELECTED = '@features/@search/ACCOUNT_SELECTED'
const ADVISOR_SELECTED = '@features/@search/ADVISOR_SELECTED'
const APPLICATION_SELECTED = '@features/@search/APPLICATION_SELECTED'

export const searchResultActions = {
  clientSelected: createAction(CLIENT_SELECTED)<IClient>(),
  accountSelected: createAction(ACCOUNT_SELECTED)<IAccount>(),
  advisorSelected: createAction(ADVISOR_SELECTED)<IAdvisorDetailResult>(),
  applicationSelected: createAction(APPLICATION_SELECTED)<IServicePrincipal>()
}

export type HeaderActionTypes =
  | ActionType<typeof searchBoxActions>
  | ActionType<typeof searchActions>

export interface ISearchState {
  searchText?: string
  searchLoading: boolean
  searchResults?: ICombinedSearchResults
  loadedSearchText?: string
}

const initialState: ISearchState = {
  searchLoading: false
}

export const searchReducer = createReducer<ISearchState, HeaderActionTypes>(
  initialState
)
  .handleAction(searchBoxActions.updateSearchText, (state, action) => {
    return action.payload === undefined
      ? { ...initialState }
      : {
          ...state,
          searchText: action.payload
        }
  })
  .handleAction(searchActions.request, (state) => {
    return {
      ...state,
      searchLoading: true
    }
  })
  .handleAction(searchActions.success, (state, action) => {
    return {
      ...state,
      searchResults: action.payload,
      searchLoading: false,
      loadedSearchText: state.searchText
    }
  })

const rootSelector = (state: AppState) => state.features.search
export const getIsSearchLoading = flow(rootSelector, (x) => x.searchLoading)
export const getSearchText = flow(rootSelector, (x) => x.searchText)
export const getLoadedSearchText = flow(rootSelector, (x) => x.loadedSearchText)
export const getSearchResults = flow(rootSelector, (x) => x.searchResults)
export const searchSelectors = {
  getIsSearchLoading,
  getLoadedSearchText,
  getSearchResults,
  getSearchText
}

const fetchSearchResults = function* (query: string) {
  yield delay(300)

  query = (query || '').trim()

  try {
    const params: ISearchParams = {
      top: 5,
      count: true,
      query
    }

    const [clients, accounts, advisors] = yield* all([
      call(safeSearch, 'client' as const, {
        ...params,
        searchFields: ['LegalEntityName', 'srcClientNumber'],
        select: [
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'LegalEntityName',
          'LegalEntityID',
          'ClientKPI/AumTotal',
          'loginDetails',
          'Account',
          'srcClientNumber',
          'id'
        ]
      }),
      call(safeSearch, 'account' as const, {
        ...params,
        searchFields: [
          'CustodyAccount',
          'LegalEntityName',
          'AccountNickname',
          'Shortname',
          'gfoCustodyAccount'
        ],
        select: [
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'CustodyAccount',
          'LegalEntityName',
          'LegalEntityID',
          'AccountKPIs/AccountTotal',
          'AccountNickname',
          'Shortname',
          'CustodianType',
          'CustodianName',
          'registrationtype',
          'gfoCustodyAccount',
          'id',
          'registrationDesc'
        ]
      }),
      call(safeSearch, 'advisor' as const, {
        ...params,
        searchFields: ['ClientAdvisorID', 'ClientAdvisor', 'ClientAdvisorTeam'],
        select: [
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'total',
          'AdvisorKPI',
          'id'
        ]
      })
    ])

    yield put(
      searchActions.success({
        clients: clients as ISearchResult<IClient>,
        accounts: accounts as ISearchResult<IAccount>,
        advisors: advisors as ISearchResult<IAdvisorDetailResult>
      })
    )
  } catch (e: any) {
    yield put(searchActions.failure(e))
  }
}

export const searchSagas = [
  () =>
    takeLatest(
      searchBoxActions.updateSearchText,
      function* (action: ReturnType<typeof searchBoxActions.updateSearchText>) {
        if (action.payload === undefined) {
          return
        }
        const loadedSearchText: string | undefined = yield select(
          getLoadedSearchText
        )

        if (
          loadedSearchText &&
          trim(loadedSearchText) === trim(action.payload)
        ) {
          return
        }

        yield put(searchActions.request(action.payload))
      }
    ),
  () =>
    takeLatest(
      searchActions.request,
      function* (action: ReturnType<typeof searchActions.request>) {
        yield call(fetchSearchResults, action.payload)
      }
    ),
  () =>
    takeLatest(
      searchResultActions.accountSelected,
      function* (
        action: ReturnType<typeof searchResultActions.accountSelected>
      ) {
        const { ClientAdvisorID, LegalEntityID, id } = action.payload

        if (LegalEntityID) {
          yield put(
            clientContextUpdateActions.request({
              legalEntityIds: [LegalEntityID],
              clientAdvisorId: ClientAdvisorID
            })
          )
        }

        if (id) {
          yield put(accountContextSelectionActions.update([id]))
        }
      }
    ),
  () =>
    takeLatest(
      searchResultActions.advisorSelected,
      function* (
        action: ReturnType<typeof searchResultActions.advisorSelected>
      ) {
        const { id } = action.payload
        if (!id) {
          return
        }

        yield put(domainContextActions.setSelectedDomainReps([id]))
      }
    ),
  () =>
    takeLatest(
      searchResultActions.clientSelected,
      function* (
        action: ReturnType<typeof searchResultActions.clientSelected>
      ) {
        if (!action.payload) {
          return
        }

        const { LegalEntityID, ClientAdvisorID } = action.payload

        yield put(
          clientContextUpdateActions.request({
            legalEntityIds: [LegalEntityID].filter(notNullOrEmpty),
            clientAdvisorId: ClientAdvisorID
          })
        )
      }
    ),
  () =>
    takeLatest(
      searchResultActions.applicationSelected,
      function* (
        action: ReturnType<typeof searchResultActions.applicationSelected>
      ) {
        yield put(navigationActions.launchApplication(action.payload))
      }
    )
]
