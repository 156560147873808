import { escapeRegExp } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { MaskedText } from '../../../shared/components/MaskedText'
import { getEnableDataMaskingPreference } from '../../../store/user/selectors'

export interface IHighlightProps {
  text?: string
  highlight?: string
}
export const Highlight: React.FC<IHighlightProps> = ({
  text = '',
  highlight = ''
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }
  const regex = new RegExp(
    `(${highlight.trim().split(/[ ,]+/).map(escapeRegExp).join('|')})`,
    'gi'
  )
  const parts = (text || '').split(regex)
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <b style={{ fontWeight: 700 }} key={i}>
              {part}
            </b>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  )
}

export const ConnectedMaskedHighlight: React.FC<IHighlightProps> = (props) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  return shouldMask ? (
    <MaskedText text={props.text} />
  ) : (
    <Highlight {...props} />
  )
}
