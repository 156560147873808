import axios, { CancelTokenSource, Method } from 'axios'
import { difference } from 'lodash'
import { call, cancelled } from 'typed-redux-saga'
import { batch, IOdataBatchRequest } from '../../../api/datahub'
import {
  getHousehold,
  getHouseholdChangeRequestById,
  getHouseholdChangeRequests,
  IDatahubHousehold,
  IHouseholdChangeRequest,
  IHouseholdChangeRequestAccount,
  searchHouseholds,
  updateHousehold
} from '../../../api/households'
import { IOdataRequest } from '../../../api/odata.types'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { isNotNullOrUndefined } from '../../../shared/gaurds'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export const getHouseholdApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchHouseholdSearchResultsFromApi = function* (
  request: IOdataRequest
) {
  const [apiOptions, source] = yield* call(getHouseholdApiOptions)
  try {
    return yield* call(searchHouseholds, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const fetchHouseholdFromApi = function* (id: string) {
  const [apiOptions, source]: [IApiOptions, CancelTokenSource] = yield call(
    getHouseholdApiOptions
  )

  try {
    return yield* call(getHousehold, apiOptions, id)
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const createOrUpdateHouseholdChangeRequestFromApi = function* (
  request: IHouseholdChangeRequest
) {
  const [apiOptions, source] = yield* call(getHouseholdApiOptions)

  const requests: IOdataBatchRequest[] = []

  if (request.id) {
    let currentAccounts = []
    try {
      const currentRequest = yield* call(
        getHouseholdChangeRequestById,
        apiOptions,
        request.id
      )
      currentAccounts =
        currentRequest.accounts?.map((x) => x.accountNumber) || []
    } finally {
      if (yield* cancelled()) {
        source.cancel()
      }
    }

    const newAccounts = request.accounts?.map((x) => x.accountNumber) || []
    const remove = difference(currentAccounts, newAccounts)
    const add = difference(newAccounts, currentAccounts)

    requests.push(
      ...[
        ...remove.map((x) => ({
          url: `householdchangerequestaccounts(accountNumber='${encodeURIComponent(
            x || ''
          )}',requestId=${request.id})`,
          method: 'DELETE' as Method
        })),
        ...add.map((x) => ({
          url: 'householdchangerequestaccounts',
          method: 'POST' as Method,
          body: {
            requestId: request.id,
            accountNumber: encodeURIComponent(x || '')
          } as IHouseholdChangeRequestAccount
        })),
        {
          url: `householdchangerequests/${request.id}`,
          method: 'PATCH' as Method,
          body: {
            ...request,
            status: 'REQUESTED',
            id: undefined,
            accounts: undefined
          } as IHouseholdChangeRequest
        }
      ].filter(isNotNullOrUndefined)
    )
  } else {
    requests.push({
      url: 'householdchangerequests',
      method: 'POST',
      body: { ...request, status: 'REQUESTED' } as IHouseholdChangeRequest
    })
  }

  try {
    const results = yield* call(batch, apiOptions, requests)

    return results
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const updateHouseholdFromApi = function* (
  request: Partial<IDatahubHousehold>
) {
  const [apiOptions, source] = yield* call(getHouseholdApiOptions)

  try {
    const response = yield* call(updateHousehold, apiOptions, request)

    return response
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const checkForAccountsInExistingRequest = function* (
  accountNumbers: string[]
) {
  if (!accountNumbers.length) {
    return undefined
  }
  const [apiOptions, source] = yield* call(getHouseholdApiOptions)
  try {
    return yield* call(getHouseholdChangeRequests, apiOptions, {
      filters: [
        `accounts/any(a: a/accountNumber in (${accountNumbers
          .map((x) => `'${x}'`)
          .join(',')}))`,
        `status ne 'COMPLETED' and status ne 'REJECTED'`
      ],
      top: 1
    })
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}
