import { css, useTheme } from '@emotion/react'
import { Link } from '@fluentui/react'
import { difference, intersection, xor } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/gaurds'
import { buttonStyles } from '../../components/shared/Buttons'
import {
  useRdot360SelectedAccountsApiContext,
  useRdot360AccountContext
} from '../../store/rdot360Context'
import {
  AccountSelector,
  useAccountSelectorTableStore
} from '../AccountSelector'
import { CustomGroupSelector } from '../AccountSelector/CustomGroupSelector'
import { Icon } from '../Icons/Icon'
import { CustomAccountGroupModal } from './CustomAccountGroupModal'
import { SidePanelFilter } from './SidePanelFilter'
import { useSidePanelStore } from './store'

const classes = {
  container: css({
    backgroundColor: 'white',
    height: '100%'
  }),
  wrapper: css({
    margin: '0 9px'
  })
}

export const SidePanel = () => {
  const {
    setSelectedAccountIds,
    selectedAccountIds: contextSelectedIds,
    accounts,
    includeClosedAccounts,
    setIncludeClosedAccounts
  } = useRdot360AccountContext()
  const { isOpen, setIsOpen } = useSidePanelStore()
  const {
    selectedIds,
    setSelectedIds,
    grouping: [firstGroup]
  } = useAccountSelectorTableStore()
  const { isLoading: isContextLoading } = useRdot360SelectedAccountsApiContext()
  useEffect(() => {
    setSelectedIds(contextSelectedIds)
  }, [contextSelectedIds, setSelectedIds])

  const applicableSelectedIds = useMemo(
    () =>
      intersection(selectedIds, accounts?.map((x) => x.id) || []).filter(
        isNotNullOrUndefined
      ),
    [accounts, selectedIds]
  )

  const handleFilterApplyClick = useCallback(() => {
    setSelectedAccountIds(applicableSelectedIds)
  }, [applicableSelectedIds, setSelectedAccountIds])

  const [isCreateCAGHidden, setIsCreateCAGHidden] = useState(true)
  const hideCreateCAG = useCallback(() => setIsCreateCAGHidden(true), [])

  const handleCreateCustom = useCallback(() => {
    setIsCreateCAGHidden(false)
  }, [])

  const handleCancelClick = useCallback(() => {
    setSelectedIds(contextSelectedIds)
  }, [contextSelectedIds, setSelectedIds])

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const toggleIncludeClosedAccounts = useCallback(() => {
    setIncludeClosedAccounts(!includeClosedAccounts)
  }, [includeClosedAccounts, setIncludeClosedAccounts])

  const selectionHasChanged = useMemo(
    () => xor(contextSelectedIds, applicableSelectedIds).length > 0,
    [applicableSelectedIds, contextSelectedIds]
  )

  const isAllSelected = useMemo(
    () =>
      difference(
        accounts?.map((x) => x.id),
        applicableSelectedIds
      ).length === 0,
    [accounts, applicableSelectedIds]
  )

  const handleSelectAll = () => {
    isAllSelected
      ? setSelectedIds([])
      : setSelectedIds(
          accounts?.map((x) => x.CustodyAccount).filter(isNotNullOrEmpty)
        )
  }

  const theme = useTheme()

  return (
    <>
      <div css={classes.container}>
        <div
          css={[
            advisoryModuleStyles.fancyScroll,
            { height: '100%', width: isOpen ? 346 : 40 }
          ]}
        >
          <aside>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                css={{
                  height: '18px',
                  width: '18px',
                  margin: '8px',
                  alignSelf: 'flex-end'
                }}
              >
                <Icon
                  type={isOpen ? 'CollapseAccount' : 'ExpandAccount'}
                  height={18}
                  width={18}
                  onClick={toggleIsOpen}
                  color={
                    isOpen
                      ? theme.colors.primaryDarkBlue
                      : theme.colors.primaryWhite
                  }
                  fillColor={isOpen ? undefined : theme.colors.primaryDarkBlue}
                />
              </div>
              {!isOpen ? (
                <div
                  css={{
                    transform: 'rotate(180deg)',
                    textOrientation: 'mixed',
                    writingMode: 'vertical-lr',
                    marginRight: '10px',
                    whiteSpace: 'nowrap',
                    textDecoration: 'underline',
                    color: '#1D679D'
                  }}
                >
                  {`${contextSelectedIds?.length || 0}/${
                    accounts?.length || 0
                  } Accounts Selected`}
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              css={[classes.wrapper, { display: isOpen ? 'block' : 'none' }]}
            >
              <SidePanelFilter />
              {firstGroup === 'cag' ? (
                <CustomGroupSelector />
              ) : (
                <>
                  <div
                    css={{
                      marginLeft: '0.5rem',
                      padding: '1rem 0rem',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ cursor: 'pointer', margin: 0 }}
                      checked={includeClosedAccounts}
                      onChange={toggleIncludeClosedAccounts}
                    />
                    <label
                      css={{
                        marginLeft: '5px',
                        flex: '0.55',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      Include Closed Accounts
                    </label>
                  </div>
                  <div css={{ marginBottom: '5px' }}>Select Account(s)</div>
                  <div
                    css={{
                      marginLeft: '7px',
                      float: 'left'
                    }}
                  >
                    <Link onClick={handleSelectAll}>
                      {isAllSelected ? 'Deselect All' : 'Select All'}
                    </Link>
                  </div>
                  <AccountSelector />
                </>
              )}
              <div
                css={{
                  position: 'sticky',
                  backgroundColor: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 10,
                  bottom: 0,
                  paddingTop: 12,
                  paddingBottom: 5
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gridColumnGap: 10
                  }}
                >
                  <button
                    css={[buttonStyles.secondary, { flexGrow: 1 }]}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    css={[buttonStyles.primary, { flexGrow: 2 }]}
                    onClick={handleFilterApplyClick}
                    disabled={
                      isContextLoading ||
                      !selectionHasChanged ||
                      !applicableSelectedIds.length
                    }
                  >
                    {isContextLoading ? <LoadingComponent /> : 'Apply'}
                  </button>
                </div>
                {firstGroup !== 'cag' && (
                  <button
                    css={[buttonStyles.secondary, { flexGrow: 1 }]}
                    onClick={handleCreateCustom}
                    disabled={applicableSelectedIds?.length < 2}
                  >
                    Save as Custom Account Group
                  </button>
                )}
              </div>
            </div>
          </aside>
        </div>
      </div>
      {!isCreateCAGHidden && (
        <CustomAccountGroupModal
          hidden={isCreateCAGHidden}
          hide={hideCreateCAG}
          selectedAccounts={applicableSelectedIds}
        />
      )}
    </>
  )
}
