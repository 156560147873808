import styled from '@emotion/styled'

export const FilterWrapper = styled.div`
  .checkbox {
    width: 15px;
    height: 15px;
  }
`

export const GroupItem = styled.div`
  background-color: #eff6fb;
  color: #13272b;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  padding: 10px 5px;
  border: 1px solid #6fa3c5;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  padding-left: 20px;

  input {
    width: 15px;
    height: 15px;
  }
  input[type='checkbox' i] {
    background-color: white;
  }
`

export const CheveronCircle = styled.span`
  border: 1px solid #b8d5ed;
  border-radius: 9px;
  height: 18px;
  width: 18px;
  font-size: 8px;
  text-align: center;
  background-color: white;
  margin-right: 12px;
  display: inline-block;
`

export const AccountItemOutline = styled.div`
  border-left: 1px solid #cde2f2;
  border-right: 1px solid #cde2f2;
`

export const AccountItem = styled.div`
  display: flex;
  background: #e8ecef;
  color: #13272b;
  margin-bottom: 3px;
  padding: 10px 0px 10px 12px;
  width: 100%;

  input {
    width: 15px;
    height: 15px;
    margin-left: 28px;
    margin-top: 8px;
  }
`

export const Label = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
`

export const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  width: 100%;
  flex: 1;
`
export const AccountName = styled.div`
  flex: 0.4;
  max-width: 210px;
  text-align: end;
`
export const AccountValue = styled.div`
  flex: 0.6;
  max-width: 240px;
  text-align: end;
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'right',
  width: '100%',
  textOverflow: 'ellipsis'
`

export const AccounItemtHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding-left: 15px;
`
export const AccounItemtFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-top: 5px;
  width: 240px;
`

export const SearchContainer = styled.div`
  padding: 10px;
  border: solid 1px #ddd;
  border-radius: 4px;

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #afb1b6;
    border-radius: 8px;
  }
`

export const ClearFilterButton = styled.a`
  cursor: pointer;
  font-size: 14px;
  line-height: 32px;
  color: #2e536f;
`

export const SelectCountButton = styled.span`
  font-size: 12px;
  line-height: 32px;
  text-align: right;
  color: #8e9da8;
`
