import { IAccount } from 'api/account.types'
import { sumBy, keyBy, orderBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { SnackBar } from '../../components/shared/Snackbar'
import { ICustomAccountGroup } from '../../store/dynamics'
import { useRdot360AccountContext } from '../../store/rdot360Context'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import { useDeleteCustomGroupStore } from '../DeleteCustomAccountGroup'
import { DeleteCustomGroupModal } from '../DeleteCustomAccountGroup/DeleteCustomGroupModal'
import { AccountSelectorAccount } from './AccountSelectorAccount'
import { AccountSelectorGroup } from './AccountSelectorGroup'
import { useAccountSelectorTableStore } from './store'
import { accountSelectorStyles } from './styles'

export const CustomGroupSelector: React.FC = () => {
  const { cagsWithUngrouped, isFetching, error } = useRdot360CagsContext()
  const { groupToDelete } = useDeleteCustomGroupStore()
  return (
    <>
      <div css={[{ marginTop: '1rem' }]}>
        {isFetching && <IndeterminateProgressIndicator />}
        {error ? (
          <div css={{ paddingBottom: '10px' }}>
            <SnackBar
              type="Failure"
              message={(error as Error)?.message || 'An unknown error occurred'}
            />
          </div>
        ) : null}
        {!cagsWithUngrouped && (
          <div css={accountSelectorStyles.container}>
            <div css={[accountSelectorStyles.groupItem]}>
              <AccountSelectorGroup
                onChange={() => undefined}
                subrowsCount={0}
                selectedSubrowsCount={0}
                toggleExpanded={() => undefined}
                groupBalance={0}
                groupBalanceChange={0}
              />
            </div>
          </div>
        )}
        {cagsWithUngrouped && (
          <>
            {cagsWithUngrouped?.map((group, i) => (
              <CustomGroupRow group={group} key={i} />
            ))}
          </>
        )}
      </div>
      {groupToDelete && <DeleteCustomGroupModal />}
    </>
  )
}

const CustomGroupRow: React.FC<{ group: ICustomAccountGroup }> = ({
  group
}) => {
  const [showAccounts, setShowAccounts] = useState(false)
  const { selectedIdsLookup, selectedIds, setSelectedIds } =
    useAccountSelectorTableStore()
  const { setGroupToDelete } = useDeleteCustomGroupStore()
  const { accountLookupById } = useRdot360AccountContext()
  const groupAccounts = useMemo(
    () =>
      group?.rcm_cag_rcm_financialaccount
        ?.map((x) => accountLookupById[x.rcm_cdmaccountid || ''])
        ?.filter(isNotNullOrUndefined),
    [accountLookupById, group?.rcm_cag_rcm_financialaccount]
  )
  const groupAccountsSelected = useMemo(
    () => groupAccounts?.filter((x) => selectedIdsLookup[x.id || '']),
    [groupAccounts, selectedIdsLookup]
  )
  const { balanceLookup } = useRdot360BalancesContext()
  const balances = useMemo(
    () => groupAccountsSelected?.map((x) => balanceLookup[x.id || '']),
    [balanceLookup, groupAccountsSelected]
  )
  const totalBalance = useMemo(
    () => sumBy(balances, (x) => x?.netWorth || 0),
    [balances]
  )
  const totalChange = useMemo(
    () => sumBy(balances, (x) => x?.netWorthChange || 0),
    [balances]
  )

  const accountIds = useMemo(
    () => groupAccounts?.map((x) => x?.id)?.filter(isNotNullOrUndefined),
    [groupAccounts]
  )

  const isAllSelected = useMemo(
    () => groupAccountsSelected?.length === accountIds?.length,
    [accountIds?.length, groupAccountsSelected?.length]
  )
  const onSelectionChanged = useCallback(() => {
    const otherAccounts = selectedIds.filter((x) => !accountIds?.includes(x))
    if (isAllSelected) {
      setSelectedIds(otherAccounts)
    } else {
      setSelectedIds([...otherAccounts, ...(accountIds || [])])
    }
  }, [accountIds, isAllSelected, selectedIds, setSelectedIds])

  const onDelete = useCallback(
    () => setGroupToDelete(group),
    [group, setGroupToDelete]
  )
  const toggleShowAccounts = useCallback(
    () => setShowAccounts(!showAccounts),
    [showAccounts]
  )

  const orderedAccounts = useMemo(
    () =>
      orderBy(
        groupAccounts,
        (x) => balanceLookup[x.id || '']?.netWorth || 0,
        'desc'
      ),
    [balanceLookup, groupAccounts]
  )

  return (
    <div css={accountSelectorStyles.container}>
      <div
        css={[
          accountSelectorStyles.groupItem,
          showAccounts &&
            groupAccounts?.length &&
            accountSelectorStyles.expandedGroupItem
        ]}
      >
        <AccountSelectorGroup
          title={group.rcm_name}
          checked={isAllSelected}
          indeterminate={!isAllSelected && !!groupAccountsSelected?.length}
          onChange={onSelectionChanged}
          selectedSubrowsCount={groupAccountsSelected?.length || 0}
          subrowsCount={groupAccounts?.length || 0}
          groupBalance={totalBalance}
          groupBalanceChange={totalChange}
          onDeleteGroup={group?.rcm_customaccountgroupid ? onDelete : undefined}
          isExpanded={showAccounts}
          toggleExpanded={toggleShowAccounts}
        />
      </div>

      {showAccounts && (
        <div css={accountSelectorStyles.itemsContainer}>
          {orderedAccounts?.map((account, i) => (
            <AccountRow account={account} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

const AccountRow: React.FC<{
  account: IAccount
}> = ({ account }) => {
  const { householdAccountBalances } = useRdot360BalancesContext()
  const { setSelectedIds, selectedIdsLookup, selectedIds } =
    useAccountSelectorTableStore()
  const isSelected = useMemo(
    () => selectedIdsLookup?.[account?.id || ''] || false,
    [account?.id, selectedIdsLookup]
  )
  const balanceLookup = keyBy(
    householdAccountBalances,
    (x) => x.accountaumber || ''
  )
  const onCheckboxClicked = useCallback(() => {
    if (!account?.id) {
      return
    }
    if (isSelected) {
      setSelectedIds(selectedIds?.filter((x) => x !== account?.id))
    } else {
      setSelectedIds([...selectedIds, account?.id])
    }
  }, [account?.id, isSelected, selectedIds, setSelectedIds])
  const balance = balanceLookup[account?.id || '']
  const { netWorth, netWorthChange } = balance || {}
  return (
    <div css={accountSelectorStyles.accountItem}>
      <AccountSelectorAccount
        checked={isSelected}
        onChange={onCheckboxClicked}
        nickname={account?.AccountNickname}
        accountNumber={account?.id}
        registrationDesc={account?.registrationDesc}
        registrationtype={account?.registrationtype}
        balance={netWorth}
        change={netWorthChange}
      />
    </div>
  )
}
