import { Theme, css, useTheme } from '@emotion/react'
import { Icon, Label } from '@fluentui/react'
import {
  CheveronCircle,
  GroupItem
} from 'modules/Advisory/modules/Rdot360/components/filters/Filter.styles'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { useGetPositionByCategoryQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { FC, useMemo, useState } from 'react'
import { useInvestmentsTableStore } from '../InvestmentsTable/store'
import { InvestmentsChart } from './InvestmentsChart'
import InvestmentsChartLegendDetails from './InvestmentsChartLegendDetails'
export const getClasses = (theme: Theme) => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  chartContainer: css({
    display: 'flex',
    backgroundColor: theme.colors.primaryWhite,
    maxHeight: '350px',
    border: `solid 1px ${theme.colors.extraGray1}`,
    borderTop: 'none',
    paddingRight: '10px'
  }),

  iconStyle: css({
    display: 'inline-block'
  })
})
export const InvestmentsChartContainer: FC = () => {
  const {
    data,
    isFetching,
    isError: L1error,
    error,
    refetch
  } = useGetPositionByCategoryQueryForSelectedAccounts('L1')
  const { grouping } = useInvestmentsTableStore()
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const toggleAccount = () => {
    setIsExpanded(!isExpanded)
  }
  const chartErrorMessage =
    (error as Error)?.message || 'An unknown Error Occurred'

  const isChartError = useMemo(() => L1error || error, [L1error, error])

  const sortedData = useMemo(() => {
    return data?.map((x) => x).sort((a: any, b: any) => b?.mktval - a?.mktval)
  }, [data])
  return grouping && grouping[0] === 'assetClass' ? (
    <div>
      {isFetching && <IndeterminateProgressIndicator />}
      <GroupItem>
        <Label
          onClick={(event) => {
            toggleAccount()
            event.preventDefault()
            return false
          }}
        >
          {isExpanded ? (
            <CheveronCircle css={classes.iconStyle}>
              <Icon iconName="ChevronUpSmall" />
            </CheveronCircle>
          ) : (
            <CheveronCircle css={classes.iconStyle}>
              <Icon iconName="ChevronDownSmall" />
            </CheveronCircle>
          )}
          <span>Asset Allocation</span>
        </Label>
      </GroupItem>
      {!!isChartError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar
            message={chartErrorMessage}
            type="Failure"
            actionButtonLabel="click to retry"
            onActionButtonClicked={refetch}
          />
        </div>
      )}
      {isExpanded && (
        <div css={[classes.chartContainer, advisoryModuleStyles.fancyScroll]}>
          {!data?.length ? (
            <div
              css={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                margin: 'auto 0'
              }}
            >
              No data available
            </div>
          ) : (
            <div
              css={{ height: '320px', marginTop: '15px', marginLeft: '50px' }}
            >
              <InvestmentsChart data={data} />
            </div>
          )}
          <div
            css={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignContent: 'flex-start',
              columnGap: '100px',
              rowGap: '25px',
              flexWrap: 'wrap',
              margin: '80px 0px 60px 20px',
              minWidth: 0
            }}
          >
            {sortedData?.map((item, index) => (
              <InvestmentsChartLegendDetails data={item} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
