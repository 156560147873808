import { datahubApi } from 'store/api/datahub'

export interface IClientUpdateRequest {
  email?: string
  cc?: string
  from?: string
  invitedBy?: string
  subject?: string
  message?: string
  firstName?: string
  lastName?: string
}

export const clientInviteDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    clientInvite_inviteProfile: builder.mutation<
      void,
      { id: string; invite: IClientUpdateRequest }
    >({
      query: ({ id, invite }) => ({
        url: `/nfsProfiles/${id}/invite`,
        method: 'post',
        data: { request: invite }
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: 'profile', id }] : []
    }),
    clientInvite_resendInvitation: builder.mutation<
      void,
      { id: string; invite: IClientUpdateRequest }
    >({
      query: ({ id, invite }) => ({
        url: `/nfsProfiles/${id}/resendInvitation`,
        method: 'post',
        data: { request: invite }
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: 'profile', id }] : []
    })
  })
})

export const {
  useClientInvite_inviteProfileMutation,
  useClientInvite_resendInvitationMutation
} = clientInviteDatahubApi
