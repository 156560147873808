import { combineReducers } from 'redux'
import { secondaryHeaderReducer } from '../components/SecondaryHeader/features'
import { createClientProfileContactCalloutReducer } from '../components/SecondaryHeader/features/Profiles/store/ClientProfileContactListSection'
import { createClientProfileCalloutReducer } from '../components/SecondaryHeader/features/Profiles/store/ClientProfileHeaderSection'
import { rdot360FeaturesReducer } from '../features'
import { rdot360ModulesReducer } from '../modules'
import { documentFilterReducer } from '../modules/Documents/DocumentFilter/store'
import { rdot360ContextReducer } from './rdot360Context'
import { apiContextReducer } from './rdot360Context/apiContext'

export const rdot360ModuleReducer = combineReducers({
  apiContext: apiContextReducer,
  context: rdot360ContextReducer,
  createClientProfileCallout: createClientProfileCalloutReducer,
  createClientProfileContactCallout: createClientProfileContactCalloutReducer,
  secondaryHeader: secondaryHeaderReducer,
  modules: rdot360ModulesReducer,
  documentFilter: documentFilterReducer,
  features: rdot360FeaturesReducer
})
