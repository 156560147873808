import axios from 'axios'
import { IDragDropFile } from '../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../shared/contracts/IApiOptions'
import { IUploadedFiles } from '../Setting/store/types'
import {
  IFetchPlanToDistributeCash,
  IPlanProvider,
  IProductPlan,
  IRetirementProductCashReceipt,
  IPlanByProvider,
  FetchRetirementPlanTransactionType,
  IRetirementPlanTransactionsResponse,
  IGetMasterLookupResponse,
  ICashAllocationWithIds,
  IProductDocument,
  IProjectedAmount,
  IProjectedAmountFetchRequestJson,
  IPlanAllocations,
  IFetchProductPlanTransactionTypeReq,
  IAddPlanProviderResponse,
  IFundDocsRequest,
  IFetchDocType,
  IFundDocTypeRequest,
  IUploadFundDocRequest,
  IUploadFundres,
  IFetchPlanProviderDocsReq,
  IPlanProviderDocs,
  IPlanProviderForExcel,
  IUpdatePlanProviderDocReq,
  IAltsCashReceiptTrendReport,
  IAltsAccrualReport,
  IAccuralReportDrilldownReq,
  IAccrualReportDrilldown,
  IClassification,
  IDraftPlanByProvider,
  IApprovalDashboard ////approval dashboard
} from '../store/types'
import { showNumericPeriodFromDate } from '../store/utility'
import { IworkflowHistory } from '../store/workflowHistoryApprovalDashboardTypes'

const fetchProductPlansService = (
  monthyear: string,
  options: IApiOptions,
  source: string,
  filter: string
) => {
  const monthyearString =
    monthyear !== undefined && monthyear !== null ? monthyear : ''
  const filterString = filter !== undefined && filter !== null ? filter : ''
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetPlans?MonthYear=${monthyearString}&filter=feetype=${filterString}&producttype=${source}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IProductPlan[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlansToDistributeCashService = (
  cashReceipt: IRetirementProductCashReceipt,
  plan: IProductPlan | undefined,
  options: IApiOptions,
  productType: string,
  feetype: string,
  monthyear: string
) => {
  const url = `${
    options.apiRoot
  }/trustapi/api/Retirement/DistributeCashToPlans?planproviderid=${
    plan?.planproviderid
  }&cashreceiptid=${
    cashReceipt?.cashreceiptid
  }&numprevpriods=6&feetype=${feetype}&producttype=${productType}&monthyear=${
    monthyear || ''
  }&cashreceiptproviderid=${cashReceipt?.cashreceiptproviderid}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<IFetchPlanToDistributeCash[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchPlanProvidersService = (
  options: IApiOptions,
  producttype?: string
) => {
  const url = producttype
    ? `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlanProviders?producttype=${producttype}`
    : `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlanProviders`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanProvider[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchFundProvidersExcelService = (
  options: IApiOptions,
  producttype?: string
) => {
  const url = producttype
    ? `${options.apiRoot}/trustapi/api/Retirement/ExportFunds?producttype=alts`
    : `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlanProviders?producttype=${producttype}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanProviderForExcel[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

////approval dashboard service
const fetchAIPlansApprovalDashboardService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/WorkflowPlan/GetWorkflowPlans?productType=alts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IApprovalDashboard>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.workflowplans)
}

////approval dashboard service
const fetchWorkflowHistoryApprovalDashboardService = (
  options: IApiOptions,
  draftId: number
) => {
  const url = `${options.apiRoot}/trustapi/api/WorkflowPlan/getWorkflowStatusbyDraft?draftId=${draftId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IworkflowHistory[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlansByPlanProviderService = (
  planprovider: IPlanProvider,
  productType: string,
  options: IApiOptions,
  selectedOption?: string,
  planId?: number
) => {
  let url = ''
  if (selectedOption === 'D') {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetDraftPlansByProvider?providerid=${planprovider.planproviderid}&producttype=${productType}&selectedStatus=${selectedOption}`
  } else if (selectedOption === 'viewCommittedMode') {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetPlansByProvider?providerid=${planprovider.planproviderid}&producttype=${productType}&planid=${planId}`
  } else if (productType && productType !== '' && productType === 'alts') {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetPlansByProvider?providerid=${planprovider.planproviderid}&producttype=${productType}`
  } else {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlansByProvider?providerid=${planprovider.planproviderid}`
  }

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return selectedOption === 'D'
    ? axios
        .get<IDraftPlanByProvider>(url, {
          headers,
          cancelToken: options.cancelToken
        })
        .then((res) => res.data.result)
    : axios
        .get<IPlanByProvider[]>(url, {
          headers,
          cancelToken: options.cancelToken
        })
        .then((res) => res.data)
}

const fetchCommittedPlanService = (
  planproviderId: number,
  productType: string,
  planId: number,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetPlansByProvider?providerid=${planproviderId}&producttype=${productType}&planid=${planId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanByProvider[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdatePlanProviderService = (
  planProvider: IPlanProvider,
  productType: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateRetirementPlanProviders`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const request = {
    ...planProvider,
    producttype: productType
  }
  return axios
    .post<void>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

// const updatePlanByproviderService = (
//   plan: IPlanByProvider,
//   provider: IPlanProvider,
//   options: IApiOptions,
//   producttype?: string
// ) => {
//   const url =
//     producttype && producttype === 'alts'
//       ? `${
//           options.apiRoot
//         }/trustapi/api/Retirement/${'InsertUpdateAIPlanDraft'}`
//       : `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateRetirementPlan`

//   const headers = {
//     Authorization: `Bearer ${options.accessToken}`
//   }

//   const requestData = {
//     ...plan,
//     planproviderid: provider.planproviderid
//   }

//   return axios
//     .post<IAddPlanProviderResponse>(url, requestData, {
//       headers,
//       cancelToken: options.cancelToken
//     })
//     .then((res) => res.data)
// }

//TODO: save as draft and submit
const updatePlanByproviderService = (
  plan: IPlanByProvider,
  provider: IPlanProvider,
  options: IApiOptions,
  producttype?: string
) => {
  const url =
    producttype && producttype === 'alts'
      ? `${
          options.apiRoot
        }/trustapi/api/Retirement/${'InsertUpdateAIPlanDraft'}`
      : `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateRetirementPlan`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = {
    ...plan,
    planproviderid: provider.planproviderid
  }

  if (plan.actionindicator === 'Draft') {
    return axios
      .post<IAddPlanProviderResponse>(url, requestData, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) => res.data)
  } else {
    return axios
      .post<IAddPlanProviderResponse>(url, requestData, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) =>
        res?.data?.funddetails?.draftid &&
        res?.data?.funddetails?.draftid > 0 &&
        res?.data?.status !== 'Failed'
          ? handleSubmit(res.data, options)?.then((r) => r)
          : res.data
      )
  }
}

const handleSubmit = (data: IAddPlanProviderResponse, options: IApiOptions) => {
  const submitUrl = `${options.apiRoot}/trustapi/api/Retirement/OnSubmitUpdateDraftStatus?draftid=${data?.funddetails?.draftid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IAddPlanProviderResponse>(
      submitUrl,
      {},
      {
        headers,
        cancelToken: options.cancelToken
      }
    )
    .then((res) => res.data)
}

const fetchProductPlanTransactionsService = (
  request: FetchRetirementPlanTransactionType,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementTransactions?retplanid=${request?.planid}&monthyear=${request?.selectedPeriod}&producttype=${request?.source}&numprevpriods=6`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRetirementPlanTransactionsResponse[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchMasterLookupService = (options: IApiOptions, type: string) => {
  let url = ''
  if (type === 'FeeType') {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetMasterLookup?lookup=${type}&producttype=alts`
  } else {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetMasterLookup?lookup=${type}&producttype=alts`
  }

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetMasterLookupResponse | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchDocListService = (options: IApiOptions, req: IFundDocsRequest) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetFundDocs?planid=${req.planId}&producttype=${req.source}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFetchDocType[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchDocTypeService = (
  options: IApiOptions,
  req: IFundDocTypeRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetMasterLookupV1?lookup=${req.type}&producttype=alts`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFetchDocType[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateCashReceiptToPlansService = (
  cashReceipt: IRetirementProductCashReceipt,
  selectedPlan: IProductPlan | undefined,
  changedCashAllocations: ICashAllocationWithIds[],
  options: IApiOptions,
  selecetedFeeType: string
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UpdateAITrans`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = changedCashAllocations.map((y) => {
    return {
      cashreceivedamount: y.cashallocatedamt,
      cashreceiptid: cashReceipt.cashreceiptid,
      retirementplanid: y.retirementplanid,
      revenueid: y.revenueid,
      planproviderid: selectedPlan?.planproviderid,
      period: showNumericPeriodFromDate(y.accrueddate || ''),
      accountnumber: y.accountnumber,
      cusip: y.cusip,
      feetype: selecetedFeeType,
      cashreceiptproviderid: cashReceipt.cashreceiptproviderid
    }
  })

  return axios
    .post<IRetirementPlanTransactionsResponse[] | undefined>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const downloadD365ExcelService = (
  monthyear: string,
  options: IApiOptions,
  producttype: string
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetD365Report?monthyear=${monthyear}&producttype=${producttype}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const closeMonthService = (
  period: string,
  producttype: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/CloseMonth`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = {
    period,
    productcode: producttype,
    isclosed: true
  }

  return axios
    .post<void>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const viewStatementService = (
  req: {
    planid: string
    period: string
    producttype: string
    plandocumentid?: number
  },
  options: IApiOptions
) => {
  let url = ''
  if (req?.producttype === 'alts') {
    url = `${options.apiRoot}/trustapi/api/Retirement/DownloadPlanDocument?planproviderid=${req?.planid}&producttype=${req?.producttype}&plandocumentid=${req?.plandocumentid}`
  } else {
    url = `${options.apiRoot}/trustapi/api/Retirement/DownloadPlanDocument?planid=${req?.planid}&period=${req?.period}&producttype=${req?.producttype}`
  }

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const uploadProductDocumentService = (
  doc: IProductDocument,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UploadPlanDocument`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  data.append('filecontents', doc.file.file)
  data.append('filename', doc.fileName)
  if (doc.monthYear) {
    data.append('month', doc.monthYear)
  }
  if (doc.producttype === 'alts') {
    data.append('planproviderid', doc?.planproviderid || '')
    if (doc.source && doc.source === 'multiupload') {
      data.append('planid', doc?.planid || '')
    }
  } else {
    data.append('planid', doc?.planid || '')
  }
  data.append('documenttype', doc.documenttype)
  data.append('producttype', doc.producttype)

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, doc.file)
          }
        : undefined
    })
    .then((res) => res.data)
}
const fetchProjectedAmountByPlanIdService = (
  requestJson: IProjectedAmountFetchRequestJson,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetPlanAccruedumount`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IProjectedAmount[] | undefined>(url, requestJson, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateProjectedAmountByPlanService = (
  projectedAmounts: IProjectedAmount[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/InsertUpdatePlanAccruedamount`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, projectedAmounts, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchUploadedFilesService = (
  producttype: string,
  cashreceiptid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetAllCashReceiptDocuments?cashreceiptid=${cashreceiptid}&producttypeid=${producttype}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IUploadedFiles[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchProductPlanAllocationsService = (
  request: IFetchProductPlanTransactionTypeReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetPlanAllocations?retplanid=${request?.planid}&monthyear=${request?.selectedPeriod}&producttype=${request?.producttype}&numprevpriods=6`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanAllocations[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const downloadFundDocService = (
  storagedocdraftid: string,
  storagedocid: string,
  options: IApiOptions,
  draftid?: number
) => {
  let url = `${options.apiRoot}/trustapi/api/Retirement/`
  if (storagedocid !== '0' && storagedocid !== '') {
    url += `DownloadFundFile?storagedocid=${storagedocid}&fileformat=pdf`
  } else if (storagedocdraftid !== '0' && storagedocdraftid !== '') {
    url += `DownloadFundFileDraft?storagedocid=${storagedocdraftid}&draftid=${draftid}&fileformat=pdf`
  }

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const uploadFundDocService = (
  doc: IUploadFundDocRequest,
  options: IApiOptions
) => {
  //const url = `${options.apiRoot}/trustapi/api/Retirement/UploadFundFile`
  const url = `${options.apiRoot}/trustapi/api/Retirement/UploadFundFileDraft`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  const draftid = doc?.draftid?.toString() || ''
  data.append('filecontents', doc.uploadfile)
  data.append('planid', doc.planid)
  data.append('draftid', draftid)
  data.append('producttype', doc.producttype)
  data.append('doctypecode', doc.doctypecode)
  data.append('storagedocid', doc?.storagedocid?.toString() || '0')

  return axios
    .post<IUploadFundres>(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlanProviderDocsService = (
  options: IApiOptions,
  request: IFetchPlanProviderDocsReq
) => {
  let url = ''
  if (request?.planid) {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetProviderDocs?planproviderid=${request?.planproviderid}&producttype=${request?.producttype}&planid=${request?.planid}`
  } else {
    url = `${options.apiRoot}/trustapi/api/Retirement/GetProviderDocs?planproviderid=${request?.planproviderid}&producttype=${request?.producttype}`
  }

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanProviderDocs[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const deleteStatementService = (
  options: IApiOptions,
  plandocumentid?: number
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/DeletePlanProviderDocument?plandocumentid=${plandocumentid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<boolean | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const updatePlanProviderDocService = (
  requestJson: IUpdatePlanProviderDocReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UpdatePlanDocument `
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const updatePlanProviderDocRequestJson = {
    plandocumentid: requestJson.planProviderDoc.plandocumentid,
    planproviderid: requestJson.planProviderDoc.planproviderid,
    planid: requestJson.planProviderDoc.planid
  }
  return axios
    .post<void>(url, updatePlanProviderDocRequestJson, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAccrualReportService = (
  monthyear: string,
  options: IApiOptions,
  feetype: string
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetMonthlyAccrualsTrendReport?monthyear=${monthyear}&feetypecode=${feetype}&numprevperiod=3`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAltsAccrualReport | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchClassificationTypesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetClassification?producttype=alts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IClassification[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchDraftPlansByProviderService = (
  options: IApiOptions,
  draftId?: number,
  planproviderid?: number
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetDraftPlansByProvider?providerid=${planproviderid}&producttype=alts&draftId=${draftId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDraftPlanByProvider>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.result)
}

const fetchOpenReceivablesReportService = (
  monthyear: string,
  options: IApiOptions,
  feetype: string
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetMonthlyOpenReceivablesTrendReport?monthyear=${monthyear}&feetypecode=${feetype}&numprevperiod=3`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<any | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchCashReceiptTrendReportService = (
  monthyear: string,
  options: IApiOptions,
  feetype: string
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetMonthlyCashReceiptsTrendReport?monthyear=${monthyear}&feetypecode=${feetype}&numprevperiod=3`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAltsCashReceiptTrendReport | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAccrualReportDrilldownService = (
  apiRequest: IAccuralReportDrilldownReq,
  options: IApiOptions
) => {
  const { planid, feetypecode, monthyear, numprevperiod } = apiRequest
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetAccrualsTrendReportDetails?planid=${planid}&monthyear=${monthyear}&feetypecode=${feetypecode}&numprevperiod=${numprevperiod}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAccrualReportDrilldown[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchSponsorNamesService = (
  searchtext: string | undefined | null,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetSponsorNames?searchtext=${searchtext}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchProductPlansService,
  fetchPlansToDistributeCashService,
  fetchPlanProvidersService,
  fetchFundProvidersExcelService,
  fetchAIPlansApprovalDashboardService, ////approval dashboard
  fetchPlansByPlanProviderService,
  insertUpdatePlanProviderService,
  updatePlanByproviderService,
  fetchProductPlanTransactionsService,
  fetchMasterLookupService,
  updateCashReceiptToPlansService,
  downloadD365ExcelService,
  closeMonthService,
  viewStatementService,
  uploadProductDocumentService,
  fetchProjectedAmountByPlanIdService,
  updateProjectedAmountByPlanService,
  fetchUploadedFilesService,
  fetchProductPlanAllocationsService,
  fetchDocTypeService,
  fetchDocListService,
  uploadFundDocService,
  downloadFundDocService,
  fetchPlanProviderDocsService,
  deleteStatementService,
  updatePlanProviderDocService,
  fetchCashReceiptTrendReportService,
  fetchAccrualReportService,
  fetchOpenReceivablesReportService,
  fetchAccrualReportDrilldownService,
  fetchSponsorNamesService,
  fetchClassificationTypesService,
  fetchDraftPlansByProviderService,
  handleSubmit,
  fetchCommittedPlanService,
  fetchWorkflowHistoryApprovalDashboardService
}
