import { createReducer } from 'typesafe-actions'
import {
  ClientVisibilityActions,
  fetchClientListActions,
  fetchUserListActions,
  manageRCMUserOrgActions,
  setSelectedUserActions
} from './actions'
import { IClient, IUser } from './types'

export interface IClientVisibilityState {
  clients?: {
    isLoading: boolean
    error?: Error
    list?: IClient[]
  }
  users?: {
    isLoading: boolean
    error?: Error
    list?: IUser[]
  }
  selectedUser?: IUser
}

export const clientVisibilityReducer = createReducer<
  IClientVisibilityState,
  ClientVisibilityActions
>({})
  .handleAction(fetchUserListActions.request, (state) => ({
    ...state,
    users: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchUserListActions.success, (state, action) => ({
    ...state,
    users: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchUserListActions.failure, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchClientListActions.request, (state) => ({
    ...state,
    clients: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchClientListActions.success, (state, action) => ({
    ...state,
    clients: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchClientListActions.failure, (state, action) => ({
    ...state,
    clients: {
      ...state.clients,
      isLoading: true,
      error: action.payload
    }
  }))
  .handleAction(setSelectedUserActions.setSelectedUser, (state, action) => ({
    ...state,
    selectedUser: action.payload
  }))
  .handleAction(manageRCMUserOrgActions.request, (state, action) => {
    const clientListShallowCopy = [...(state?.clients?.list || [])]
    const index = clientListShallowCopy.findIndex(
      (x) => x.pKid === action.payload.pKid
    )
    if (index >= 0) {
      const copyListItem: IClient = {
        ...clientListShallowCopy[index],
        isUpdating: true,
        error: undefined
      }
      clientListShallowCopy.splice(index, 1, copyListItem)
    }
    return {
      ...state,
      clients: {
        isLoading: false,
        error: undefined,
        list: clientListShallowCopy
      }
    }
  })
  .handleAction(manageRCMUserOrgActions.failure, (state, action) => {
    const clientListShallowCopy = [...(state?.clients?.list || [])]
    const index = clientListShallowCopy.findIndex(
      (x) => x.pKid === action.payload?.req?.pKid
    )
    if (index >= 0) {
      const copyListItem: IClient = {
        ...clientListShallowCopy[index],
        isUpdating: false,
        error: action.payload?.error
      }
      clientListShallowCopy.splice(index, 1, copyListItem)
    }
    return {
      ...state,
      clients: {
        isLoading: false,
        error: undefined,
        list: clientListShallowCopy
      }
    }
  })
  .handleAction(manageRCMUserOrgActions.success, (state, action) => {
    const clientListShallowCopy = [...(state?.clients?.list || [])]
    const index = clientListShallowCopy.findIndex(
      (x) => x.pKid === action.payload?.pKid
    )
    if (index >= 0) {
      const copyListItem: IClient = {
        ...clientListShallowCopy[index],
        isUpdating: false,
        error: undefined,
        isUserBelongToOrg: action.payload?.mode === 'ADD' ? 'Y' : 'N'
      }
      clientListShallowCopy.splice(index, 1, copyListItem)
    }
    return {
      ...state,
      clients: {
        isLoading: false,
        error: undefined,
        list: clientListShallowCopy
      }
    }
  })
