import {
  addMonths,
  compareDesc,
  differenceInCalendarMonths,
  endOfMonth,
  isWithinInterval,
  startOfMonth,
  subMonths
} from 'date-fns'
import { groupBy, keyBy, orderBy, range, sortBy, sumBy } from 'lodash'
import { createSelector } from 'reselect'
import {
  IHurdle,
  IHurdleMetric,
  IHurdleStatus
} from '../../../../../../../../../api/datahub'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../../shared'
import {
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../../../../../../../../../shared/gaurds'
import { getHurdlesFetchResult, getIsHurdlesFetchLoading } from './hurdleFetch'
import {
  getIsMostRecentClosedMonthFetchLoading,
  getMostRecentClosedMonthFetchResult
} from './mostRecentClosedMonthFetch'
import { getGroupByType } from './payoutsDashboardUi'

export const getPossiblePayouts = createSelector(
  [getHurdlesFetchResult, getMostRecentClosedMonthFetchResult],
  (hurdles, mostRecentClosedDate) =>
    mostRecentClosedDate
      ? hurdles
          ?.map((hurdle) => getPossiblePayout(hurdle, mostRecentClosedDate))
          .filter(isNotNullOrUndefined)
          .flat()
      : []
)

export const getNext18MonthsPayouts = createSelector(
  [
    getPossiblePayouts,
    getMostRecentClosedMonthFetchResult,
    getIsHurdlesFetchLoading,
    getIsMostRecentClosedMonthFetchLoading
  ],
  (
    payouts,
    mostRecentClosedDate,
    hurdlesFetchLoading,
    getIsMostRecentClosedMonthFetchLoading
  ) =>
    hurdlesFetchLoading || getIsMostRecentClosedMonthFetchLoading
      ? []
      : payouts
          ?.filter((hurdle) =>
            isWithinInterval(new Date(hurdle.date || ''), {
              start: new Date(mostRecentClosedDate || ''),
              end: addMonths(new Date(mostRecentClosedDate || ''), 18)
            })
          )
          .filter((x) => x.status !== 'Missed')
)

export interface PossiblePayout {
  date?: string
  name?: string
  entityName?: string
  person?: string
  amount?: number
  type?: string
  division?: string
  status?: IHurdleStatus
}

const getPossiblePayout = (hurdle: IHurdle, mostRecentClosedDate?: string) => {
  if (!mostRecentClosedDate) {
    throw new Error('')
  }

  // All Or Nothing Hurdles
  if (hurdle.completionType === 'AllOrNothing') {
    return getAllOrNothingPayout(hurdle)
  }

  const achievedMetric = hurdle.measurements
    ?.flatMap(({ metrics = [] }) => metrics)
    .find(({ progressMeasurementStatuses = [] }) =>
      progressMeasurementStatuses.some(
        ({ status } = {}) => status === 'Achieved'
      )
    )

  const achievedMetricStatus =
    achievedMetric?.progressMeasurementStatuses?.filter(
      (x) => x.status === 'Achieved'
    )?.[0]

  const achieved = achievedMetric?.payouts?.map(
    (payout): PossiblePayout => ({
      name: hurdle.name,
      entityName: hurdle.entityName,
      date: achievedMetricStatus?.currentPeriodDate,
      person: payout.userFullName,
      amount: payout.payoutValue,
      type: payout.payoutType,
      status: 'Achieved'
    })
  )

  const allMissed = hurdle.measurements
    ?.flatMap((measurement) => {
      const highMetric = orderBy(
        measurement.metrics,
        (x) => sumBy(x.payouts, (x) => x.payoutValue || 0),
        'desc'
      )?.[0]
      return measurement.metrics?.flatMap((metric) =>
        metric.progressMeasurementStatuses?.flatMap((status) => ({
          measurement,
          metric,
          status,
          isHigh: highMetric.metricId === metric.metricId
        }))
      )
    })
    .filter(isNotNullOrUndefined)
    .filter((x) => x.status.status === 'Missed' && x.isHigh)
    .flatMap((x) =>
      x.metric.payouts?.map(
        (payout): PossiblePayout => ({
          name: hurdle.name,
          entityName: hurdle.entityName,
          date: x.status.currentPeriodDate,
          person: payout.userFullName,
          amount: payout.payoutValue,
          type: payout.payoutType,
          status: 'Missed'
        })
      )
    )
    .filter(isNotNullOrUndefined)

  const allMissedGroupedByYear = groupBy(allMissed, (x) =>
    parseDateISOStringInLocalTimezone(x?.date || '').getFullYear()
  )
  const missed = Object.entries(allMissedGroupedByYear)
    .map(([, val]) => orderBy(val, (val) => val?.date, 'desc')?.[0])
    .filter(isNotNullOrUndefined)

  const parsedMostRecentClosedDate = parseDateISOStringInLocalTimezone(
    mostRecentClosedDate || ''
  )

  const pending = achievedMetric
    ? []
    : hurdle.measurements
        ?.flatMap((measurement) => {
          const highMetric = orderBy(
            measurement.metrics,
            (x) => sumBy(x.payouts, (x) => x.payoutValue || 0),
            'desc'
          )?.[0]

          const { intervalOfMeasurement, targetDate } = measurement
          const parsedTargetDate = parseDateISOStringInLocalTimezone(
            targetDate || ''
          )
          const hurdleDates = [
            intervalOfMeasurement === 'Annual' && [parsedTargetDate],
            intervalOfMeasurement === 'Quarterly' && [
              subMonths(parsedTargetDate, 9),
              subMonths(parsedTargetDate, 6),
              subMonths(parsedTargetDate, 3),
              parsedTargetDate
            ],
            intervalOfMeasurement === 'Monthly' &&
              range(12).map((i) => subMonths(parsedTargetDate, i))
          ]
            .filter(isNotNullOrFalse)
            .flat()
            .filter((date) => date >= parsedMostRecentClosedDate)

          return measurement.metrics?.flatMap((metric) => {
            const statusLookup = keyBy(
              metric.progressMeasurementStatuses,
              (x) =>
                parseDateISOStringInLocalTimezone(
                  x.currentPeriodDate || ''
                ).toISOString()
            )
            const statuses = hurdleDates
              .map((x) => ({
                date: x,
                status: statusLookup[x.toISOString()]?.status || 'Pending'
              }))
              .filter((x) => x.status === 'Pending')

            const closestPendingStatus = orderBy(
              statuses,
              (x) => x.date,
              'asc'
            )?.[0]

            return {
              measurement,
              metric,
              closestPendingStatus,
              isHigh: metric.metricId === highMetric.metricId
            }
          })
        })
        .filter(isNotNullOrUndefined)
        .filter((x) => x.isHigh && x.closestPendingStatus)

  const closestPending = orderBy(
    pending,
    (x) => x.closestPendingStatus.date,
    'asc'
  )?.[0]

  const closestPendingPossiblePayouts = closestPending?.metric?.payouts?.map(
    (payout): PossiblePayout => ({
      name: hurdle.name,
      entityName: hurdle.entityName,
      date: closestPending.closestPendingStatus?.date?.toISOString(),
      person: payout.userFullName,
      amount: payout.payoutValue,
      type: payout.payoutType,
      status: 'Pending'
    })
  )

  return [
    ...(closestPendingPossiblePayouts || []),
    ...missed,
    ...(achieved || [])
  ].filter(isNotNullOrUndefined)
}

const isMetricMissed = (metric: IHurdleMetric, targetDate: Date) => {
  const lastProgressStatus = metric.progressMeasurementStatuses?.filter(
    (status) =>
      isWithinInterval(
        parseDateISOStringInLocalTimezone(status.currentPeriodDate || ''),
        { start: startOfMonth(targetDate), end: endOfMonth(targetDate) }
      )
  )
  if (
    lastProgressStatus?.length &&
    lastProgressStatus?.[0]?.status === 'Missed'
  ) {
    return true
  } else {
    return false
  }
}

const isAllOrNothingMissed = (hurdle: IHurdle) => {
  return hurdle?.measurements?.some((measurement) =>
    measurement?.metrics?.some((metric) =>
      isMetricMissed(
        metric,
        parseDateISOStringInLocalTimezone(measurement?.targetDate || '')
      )
    )
  )
}

const isAllOrNothingAchieved = (hurdle: IHurdle) => {
  return hurdle?.measurements?.every((measurement) =>
    measurement?.metrics?.some((metric) =>
      metric.progressMeasurementStatuses?.some(
        (status) => status.status === 'Achieved'
      )
    )
  )
}

const getAllOrNothingPayout = (hurdle: IHurdle) => {
  const sortedMeasurements = hurdle?.measurements?.sort((a, b) =>
    compareDesc(
      parseDateISOStringInLocalTimezone(a.targetDate || ''),
      parseDateISOStringInLocalTimezone(b.targetDate || '')
    )
  )
  const sortedMetrics = sortedMeasurements?.[0]?.metrics?.sort(
    (a, b) => (a.metricValue || 0) - (b.metricValue || 0)
  )
  return sortedMetrics?.[0]?.payouts?.map((payout) => {
    return {
      name: hurdle.name,
      entityName: hurdle.entityName,
      date: sortedMeasurements?.[0]?.targetDate,
      person: payout.userFullName,
      amount: payout.payoutValue,
      type: payout.payoutType,
      status: isAllOrNothingAchieved(hurdle)
        ? 'Achieved'
        : isAllOrNothingMissed(hurdle)
        ? 'Missed'
        : 'Pending'
    } as PossiblePayout
  })
}

export interface PayoutRow extends PossiblePayout {
  group: string
  amount?: number
  amounts: number[]
  index: number
}
export const getPayoutRows = createSelector(
  [getNext18MonthsPayouts, getMostRecentClosedMonthFetchResult, getGroupByType],
  (payouts, mostRecentClosedDate, groupByType) => {
    if (!mostRecentClosedDate) {
      return []
    }
    let groupedPayouts = []
    if (groupByType === 'hurdle') {
      groupedPayouts = Object.entries(groupBy(payouts, ({ name }) => name))
    } else if (groupByType === 'team') {
      groupedPayouts = Object.entries(
        groupBy(payouts, ({ entityName }) => entityName)
      )
    } else {
      groupedPayouts = Object.entries(
        groupBy(payouts, ({ division }) => division)
      )
    }
    const payoutRows: PayoutRow[] = []
    groupedPayouts?.map(([key, payouts]) => {
      const amounts = new Array(18).fill(0)
      const payoutRow: PayoutRow = {
        ...payouts[0],
        index: 0,
        amounts: amounts,
        group: key
      } as PayoutRow
      payouts?.map((payout) => {
        payoutRow.amounts[
          differenceInCalendarMonths(
            parseDateISOStringInLocalTimezone(payout.date || ''),
            parseDateISOStringInLocalTimezone(mostRecentClosedDate || '')
          )
        ] += payout.amount || 0
      })
      payoutRows.push(payoutRow)
    })
    return sortBy(payoutRows, ({ entityName, name }) =>
      groupByType === 'hurdle' ? name : entityName
    )
  }
)

export const getPayoutDates = createSelector(
  [getMostRecentClosedMonthFetchResult],
  (mostRecentClosedDate) => {
    return mostRecentClosedDate
      ? range(18).map((x) =>
          addMonths(
            startOfMonth(
              parseDateISOStringInLocalTimezone(mostRecentClosedDate)
            ),
            x
          )
        )
      : []
  }
)

export const getPayoutTotals = createSelector([getPayoutRows], (payouts) => {
  const amounts = payouts.map((payout) => payout.amounts)

  const totals = amounts.length
    ? amounts.reduce((a, b) => a.map((c, i) => c + b[i]))
    : []
  return totals
})
