import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import {
  ICustomAccountGroup,
  useRdot360_getCustomAccountGroupsQuery
} from '../dynamics'
import { useRdot360Context } from './rdot360Context'
import { useRdot360AccountContext } from './useRdot360AccountContext'

export const useRdot360CagsContext = (skip?: typeof skipToken) => {
  const { selectedHouseholdId } = useRdot360Context()
  const {
    data: cags,
    isFetching,
    error
  } = useRdot360_getCustomAccountGroupsQuery(
    skip || selectedHouseholdId || skipToken
  )
  const { accountLookupById, accounts } = useRdot360AccountContext()

  const accountsInCags = useMemo(
    () =>
      cags
        ?.flatMap((x) => x.rcm_cag_rcm_financialaccount)
        ?.map((x) => accountLookupById[x?.rcm_cdmaccountid || ''])
        ?.map((x) => x?.id)
        ?.filter(isNotNullOrUndefined),
    [accountLookupById, cags]
  )

  const ungroupedAccounts = useMemo(
    () =>
      isFetching
        ? []
        : accounts?.filter((x) => x?.id && !accountsInCags?.includes(x?.id)),
    [accounts, accountsInCags, isFetching]
  )

  const ungroupedCag = useMemo(
    () =>
      ({
        rcm_name: 'Ungrouped Accounts',
        rcm_cag_rcm_financialaccount: ungroupedAccounts?.map((x) => ({
          rcm_accountnumber: x?.id,
          rcm_cdmaccountid: x?.accountId
        }))
      } as ICustomAccountGroup),
    [ungroupedAccounts]
  )

  const cagsWithUngrouped = useMemo(
    () => [...(cags || []), ungroupedCag],
    [cags, ungroupedCag]
  )

  return {
    cags,
    isFetching,
    error,
    cagsWithUngrouped
  }
}
