import {
  Checkbox,
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IColumn,
  IPlainCardProps,
  MessageBar,
  MessageBarType
} from '@fluentui/react'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IAccount } from '../../api/account.types'
import { DataTable } from '../../shared/components/DataTable'
import { isNotNullOrUndefined } from '../../shared/gaurds'
import { TextCell } from '../Lists/core/components/TextCell'
import { AccountNumberCell } from '../Lists/shared/AccountNumberCell'
import { getAccountLinkingLinkedAccounts } from './store/accountLinkingValidation'
const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

export const AccountLinkingAccountList: React.FC<{
  accounts?: IAccount[]
  selectedAccounts?: string[]
  onSelectionChanged?: (accounts?: string[]) => void
  loading?: boolean
}> = ({
  accounts = [],
  loading = false,
  selectedAccounts = [],
  onSelectionChanged
}) => {
  const linkedAccounts = useSelector(getAccountLinkingLinkedAccounts)

  const selectedAccountLookup = useMemo(
    () =>
      selectedAccounts.reduce(
        (a, x) => ((a[x] = true), a),
        {} as Record<string, boolean | undefined>
      ),
    [selectedAccounts]
  )

  const onAccountCheckboxChange = useCallback(
    (account: string, checked?: boolean) => {
      const lookupCopy = { ...selectedAccountLookup }
      lookupCopy[account] = checked
      const selected = Object.entries(lookupCopy)
        .filter(([, checked]) => checked)
        .map(([account]) => account)

      onSelectionChanged?.(selected)
    },
    [onSelectionChanged, selectedAccountLookup]
  )

  const alreadyLinked = useCallback(
    (account: string) => {
      return linkedAccounts?.includes(account)
    },
    [linkedAccounts]
  )

  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <MessageBar messageBarType={MessageBarType.error}>
          Account is already linked to Selected Client
        </MessageBar>
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: false
      }
    }),
    []
  )

  const columns: IColumn[] = useMemo(
    () =>
      [
        onSelectionChanged && {
          ...defaultColumn,
          name: '',
          key: 'approve',
          maxWidth: 20,
          onRender: (account: IAccount) =>
            account.id && (
              <HoverCard
                plainCardProps={plainCardProps}
                cardOpenDelay={0}
                instantOpenOnClick={false}
                type={HoverCardType.plain}
                shouldBlockHoverCard={() => !alreadyLinked(account.id || '')}
              >
                <Checkbox
                  checked={!!selectedAccountLookup[account.id]}
                  onChange={(_, checked) =>
                    account.id && onAccountCheckboxChange(account.id, checked)
                  }
                  disabled={alreadyLinked(account.id)}
                />
              </HoverCard>
            )
        },
        {
          ...defaultColumn,
          name: 'Account',
          key: 'account',
          maxWidth: 180,
          onRender: (account: IAccount) => (
            <AccountNumberCell account={account} />
          )
        },
        {
          ...defaultColumn,
          key: 'name',
          name: 'Legal Entity Name',
          maxWidth: 180,
          onRender: ({ LegalEntityName }: IAccount) => (
            <TextCell>{LegalEntityName}</TextCell>
          )
        },
        {
          ...defaultColumn,
          key: 'household',
          maxWidth: 180,
          name: 'Household',
          onRender: ({ householdName }: IAccount) => (
            <TextCell>{householdName}</TextCell>
          )
        },
        {
          ...defaultColumn,
          key: 'rep',
          maxWidth: 20,
          name: 'Rep Code',
          onRender: ({ ClientAdvisorID }: IAccount) => (
            <TextCell>{ClientAdvisorID}</TextCell>
          )
        }
      ].filter(isNotNullOrUndefined),
    [
      alreadyLinked,
      onAccountCheckboxChange,
      onSelectionChanged,
      plainCardProps,
      selectedAccountLookup
    ]
  )
  return (
    <DataTable
      columns={columns}
      items={accounts}
      enableShimmer={loading}
      shimmerLines={5}
    />
  )
}
