import { combineReducers } from 'redux'
import { insightsPanelReducer } from './Insights/store/insightsPanel'
import { interactionsReducer } from './Interactions/store'
import { secondaryHeaderNotesReducer } from './Notes/store'
import { taskReducer } from './Tasks/store'

export const secondaryHeaderReducer = combineReducers({
  tasks: taskReducer,
  insightsPanel: insightsPanelReducer,
  interactions: interactionsReducer,
  notes: secondaryHeaderNotesReducer
})
