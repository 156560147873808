import { css } from '@emotion/react'
import { balancesDetailTableColumnNames } from './BalanceSummaryTableColumns'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right'
  }),
  ellipsis: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  })
}

export const cellStyles = {
  [balancesDetailTableColumnNames.accountNumber]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.cashmoneyaccounts]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.pricedinvestments]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.annuity]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.other]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.accruedIncome]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.loans]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.netBalance]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.todaysChange]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.availableToWithdraw]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [balancesDetailTableColumnNames.availableToInvest]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ])
}
