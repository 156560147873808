import {
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getStorageSettings,
  setStorageSettings
} from 'shared/services/settings'
import { getIsEnvironmentProduction } from '../../../store/system'
import { uiActions } from '../../../store/ui/actions'
import { getThemeType } from '../../../store/ui/selectors'
import { preferenceUserActions } from '../../../store/user/preferenceUser'
import { getPreferenceUserPreferences } from '../../../store/user/selectors'

export const SettingsContainer: React.FC = () => {
  const preferences = useSelector(getPreferenceUserPreferences)
  const themeType = useSelector(getThemeType)
  const navigateInNewWindow = !!preferences?.navigateInNewWindow
  const enablePreviewFeatures = !!preferences?.enablePreviewFeatures
  const enableDataMasking = !!preferences?.enableDataMasking
  const isProd = useSelector(getIsEnvironmentProduction)

  const [addVersionHeaderForRockcoApis, setAddVersionHeaderForRockcoApis] =
    useState(getStorageSettings().addVersionHeaderForRockcoApis)

  const [useCdmV2SearchIndexes, setUseCdmV2SearchIndexes] = useState(
    getStorageSettings().useCdmV2SearchIndexes
  )

  const dispatch = useDispatch()

  const onNavigateInNewWindowChanged = useCallback(() => {
    dispatch(
      preferenceUserActions.set({
        key: 'navigateInNewWindow',
        value: !navigateInNewWindow
      })
    )
  }, [dispatch, navigateInNewWindow])

  const onEnablePreviewFeaturesChanged = useCallback(() => {
    dispatch(
      preferenceUserActions.set({
        key: 'enablePreviewFeatures',
        value: !enablePreviewFeatures
      })
    )
  }, [dispatch, enablePreviewFeatures])

  const onEnableDataMaskingChanged = useCallback(() => {
    dispatch(
      preferenceUserActions.set({
        key: 'enableDataMasking',
        value: !enableDataMasking
      })
    )
  }, [dispatch, enableDataMasking])

  const onThemeChanged = useCallback(
    (_: any, option?: IChoiceGroupOption) => {
      if (!option) {
        return
      }

      dispatch(uiActions.updateTheme(option.key as any))
    },
    [dispatch]
  )

  const onAddApiVersionHeaderChanged = useCallback(() => {
    const newValue = !addVersionHeaderForRockcoApis
    setStorageSettings({
      addVersionHeaderForRockcoApis: newValue
    })
    setAddVersionHeaderForRockcoApis(newValue)
  }, [addVersionHeaderForRockcoApis])
  const onUseCdmV2SearchIndexesChanged = useCallback(() => {
    const newValue = !useCdmV2SearchIndexes
    setStorageSettings({ useCdmV2SearchIndexes: newValue })
    setUseCdmV2SearchIndexes(newValue)
  }, [useCdmV2SearchIndexes])

  return (
    <Stack
      styles={{ root: { padding: '10px', minWidth: '220px' } }}
      tokens={{ childrenGap: 20 }}
    >
      <header>
        <h3>Settings</h3>
      </header>
      <div>
        <ChoiceGroup
          label="Choose a theme"
          selectedKey={themeType}
          options={[
            {
              key: 'light',
              text: 'light' // This text is long to show text wrapping.
            },
            {
              key: 'dark',
              text: 'dark'
            }
          ]}
          onChange={onThemeChanged}
        />
      </div>
      <Checkbox
        label="Navigate in new window"
        onChange={onNavigateInNewWindowChanged}
        checked={navigateInNewWindow}
      />
      <Checkbox
        onRenderLabel={maskingLabel}
        onChange={onEnableDataMaskingChanged}
        checked={enableDataMasking}
      />
      {!isProd && (
        <>
          <Checkbox
            label="Enable Preview Features"
            onChange={onEnablePreviewFeaturesChanged}
            checked={enablePreviewFeatures}
          />
          <Checkbox
            label="Add API Version Header"
            onChange={onAddApiVersionHeaderChanged}
            checked={addVersionHeaderForRockcoApis}
          />

          <Checkbox
            label="Use CDM v1 Search Indexes"
            onChange={onUseCdmV2SearchIndexesChanged}
            checked={useCdmV2SearchIndexes}
          />
        </>
      )}
    </Stack>
  )
}

const maskingLabel = () => (
  <Stack>
    <Text>Enable Data Masking / Obfuscation</Text>
    <Text variant="small">requires refresh</Text>
  </Stack>
)
