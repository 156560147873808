import { getRandomName, getRandomShortName } from 'api/random'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getEnableDataMaskingPreference } from 'store/user/selectors'

const maskText = ({ text = '', unmaskedCount = 2, maskCharacter = '*' }) => {
  const safeText = text || ''

  if (unmaskedCount >= safeText.length) {
    return new Array(safeText.length).fill(maskCharacter).join('')
  }

  const unmasked = safeText.slice(-1 * unmaskedCount)
  const maskedCount = safeText.length - unmaskedCount
  const masked = new Array(maskedCount).fill(maskCharacter).join('')

  return masked + unmasked
}

export const useMasking = () => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)

  const mask = useCallback(
    <T extends string | undefined>(
      text: T,
      type?: 'account' | 'nickname' | 'name'
    ) => {
      if (!shouldMask) {
        return text
      }

      if (type === 'nickname') {
        return getRandomShortName()
      }

      if (type === 'name') {
        return getRandomName()
      }

      return maskText({ text })
    },
    [shouldMask]
  )

  return { shouldMask, mask }
}
