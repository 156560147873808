import { ITheme } from '@fluentui/react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { AppState } from '../../store'
import { getTheme } from '../../store/ui/selectors'

export interface IWithThemeProps {
  theme: ITheme
}

export const withThemeHoc = <T extends IWithThemeProps = IWithThemeProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  class WithThemeComponent extends Component<T> {
    public render() {
      return <WrappedComponent {...(this.props as any)} />
    }
  }

  return WithThemeComponent
}

const mapStateToProps = (state: AppState): IWithThemeProps => ({
  theme: getTheme(state)
})

export const withTheme: typeof withThemeHoc = compose(
  connect(mapStateToProps),
  withThemeHoc
)
