import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { INfsProfile } from 'api/datahub'
import { usePushNotification } from 'features/Notifications'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ErrorComponent } from 'shared/components/Error'
import { LoadingComponent } from 'shared/components/Loading'
import { useFetchNfsProfileByWealthscapeIdQuery } from 'store/api/datahub'
import { getEnvironmentConfigSites } from 'store/system'
import { getRdotUsername } from 'store/user/selectors'
import {
  IClientUpdateRequest,
  useClientInvite_inviteProfileMutation,
  useClientInvite_resendInvitationMutation
} from './api/datahub'
import { ClientInviteForm } from './ClientInviteForm'
import { useClientInvitePanel } from './store/clientInvitePanel'

export interface IClientInviteFormFields extends IClientUpdateRequest {
  ssn?: string
  phones?: NfsPhone[]
}
export interface IClientInviteForm {
  invite: IClientInviteFormFields
}
export interface NfsPhone {
  Phone?: string
  telType?: string
  smscapable?: string
}

const getInvite = (
  cc: string,
  url: string,
  profile?: INfsProfile
): IClientInviteFormFields => ({
  cc,
  from: 'RCM-Onboarding@rockco.com',
  invitedBy: 'Rockefeller Capital Management',
  subject: `You're invited to the Rockefeller Capital Management online experience`,
  message:
    'Hello and welcome to the new Rockefeller Capital Management Client Portal! ' +
    'Please click the Get Started link below to begin using online capabilities. ' +
    `After logging in for the first time, please bookmark this location: ${url}. ` +
    'A mobile application is also available and can be downloaded from the ' +
    'Apple App Store and Google Play Store. If you have any questions, reply ' +
    'to this email or contact your Advisor team for assistance.',
  email: profile?.loginid || profile?.emailprimary || '',
  firstName: profile?.firstname || '',
  lastName: profile?.lastname || '',
  ssn: profile?.ssn?.slice(-4) || '',
  phones: JSON.parse(profile?.phones || '[]')
})

export const ClientInvitePanel: React.FC = () => {
  const {
    close,
    isOpen,
    error: panelError,
    panelType,
    setError
  } = useClientInvitePanel()
  const currentUsername = useSelector(getRdotUsername)
  const sites = useSelector(getEnvironmentConfigSites)
  const url = useMemo(
    () => sites?.clientonline || 'https://clientonline.rockco.com',
    [sites]
  )
  const { selectedWealthscapeId } = useClientInvitePanel()

  const {
    currentData: data,
    isFetching: isFetchLoading,
    error: fetchError
  } = useFetchNfsProfileByWealthscapeIdQuery(selectedWealthscapeId || skipToken)

  const invite = useMemo(
    () =>
      getInvite(currentUsername || '', url, isFetchLoading ? undefined : data),
    [currentUsername, data, isFetchLoading, url]
  )

  const methods = useForm<IClientInviteForm>()

  const { handleSubmit, reset } = methods

  const { pushNotification } = usePushNotification()

  const onDismiss = useCallback(() => {
    close()
  }, [close])

  useEffect(() => {
    reset({ invite })
  }, [invite, reset])

  const [showValidationError, setShowValidationError] = useState(false)
  const onError = useCallback(() => {
    setShowValidationError(true)
  }, [])
  useEffect(() => {
    if (!isOpen) {
      return
    }
    setShowValidationError(false)
  }, [isOpen])
  const [inviteProfile, inviteResult] = useClientInvite_inviteProfileMutation()
  const [resendInvite, resendResult] =
    useClientInvite_resendInvitationMutation()
  const { isLoading: isResendLoading } = resendResult
  const { isLoading: isInviteLoading } = inviteResult

  const onSubmit = useCallback(
    async (form?: IClientInviteForm) => {
      if (!form || !data?.id) {
        return
      }
      const {
        email,
        cc,
        from,
        invitedBy,
        subject,
        message,
        firstName,
        lastName
      } = form.invite
      const invite = {
        email,
        cc,
        from,
        invitedBy,
        subject,
        message,
        firstName,
        lastName
      }
      try {
        if (panelType === 'invite') {
          await inviteProfile({
            id: data?.id.toString(),
            invite: invite
          }).unwrap()
        }
        if (panelType === 'resend') {
          await resendInvite({
            id: data?.id.toString(),
            invite: invite
          }).unwrap()
        }
      } catch (e) {
        setError(e as Error)
        return
      }

      onDismiss()
      pushNotification({
        message:
          panelType === 'invite'
            ? 'Successfully Invited'
            : 'Successfully Reinvited',
        type: MessageBarType.success
      })
    },
    [
      data?.id,
      inviteProfile,
      onDismiss,
      panelType,
      pushNotification,
      resendInvite,
      setError
    ]
  )

  const error = useMemo(
    () => (fetchError as Error | undefined) || panelError,
    [fetchError, panelError]
  )

  const isAlreadyInvited = useMemo(
    () => panelType === 'invite' && !!data?.loginid && !error,
    [data?.loginid, error, panelType]
  )

  const onRenderFooterContent = useCallback(() => {
    return (
      <>
        {(isFetchLoading || isResendLoading || isInviteLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }} />
        )}
        <Stack tokens={{ childrenGap: 10 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              {error?.message}
            </MessageBar>
          )}
          {isAlreadyInvited && (
            <MessageBar messageBarType={MessageBarType.error}>
              The profile was already invited
            </MessageBar>
          )}
          {showValidationError && (
            <ErrorComponent errorMessage="Please fill in all required fields." />
          )}
          {panelType === 'reinvite' && (
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              Are you sure you want to delete and reinvite client. All
              Collaboration folder contents will be deleted.
            </MessageBar>
          )}
          {!isFetchLoading && !data?.emailprimary && (
            <MessageBar messageBarType={MessageBarType.warning}>
              An email for this client is not configured in Wealthscape
            </MessageBar>
          )}
          {!isFetchLoading && !invite?.phones?.length && (
            <MessageBar messageBarType={MessageBarType.warning}>
              A phone number for this client is not configured in Wealthscape
            </MessageBar>
          )}
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            horizontalAlign="space-between"
          >
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <PrimaryButton
                type="submit"
                form="hurdleForm"
                disabled={
                  isAlreadyInvited ||
                  !data ||
                  isFetchLoading ||
                  !invite?.phones?.length
                }
              >
                Submit
              </PrimaryButton>

              <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
            </Stack>
          </Stack>
        </Stack>
      </>
    )
  }, [
    isFetchLoading,
    isResendLoading,
    isInviteLoading,
    error,
    isAlreadyInvited,
    showValidationError,
    panelType,
    data,
    invite?.phones?.length,
    onDismiss
  ])

  return (
    <Panel
      headerText={
        panelType === 'invite' ? 'Client Invite' : 'Resend Invitation'
      }
      isOpen={isOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="400px"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      styles={{ content: { flexGrow: 1 } }}
    >
      <>
        {(isFetchLoading || isResendLoading || isInviteLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }}>
            <LoadingComponent />
          </Overlay>
        )}
        <FormProvider {...methods}>
          <form id="hurdleForm" onSubmit={handleSubmit(onSubmit, onError)}>
            <ClientInviteForm mode={panelType} />
          </form>
        </FormProvider>
      </>
    </Panel>
  )
}
