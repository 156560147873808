import { css, Theme, useTheme } from '@emotion/react'
import { Label, Icon } from '@fluentui/react'
import {
  GroupItem,
  CheveronCircle
} from 'modules/Advisory/modules/Rdot360/components/filters/Filter.styles'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { IDropdownOption } from 'modules/Advisory/modules/Rdot360/components/shared/Dropdown/Dropdown'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import {
  useGetChartEstimatedIncomeSummaryQuery,
  useGetChartProjectedIncomeSummaryQuery,
  useGetTableEstimatedIncomeSummaryQuery,
  useGetTableProjectedIncomeSummaryQuery
} from 'modules/Advisory/modules/Rdot360/store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useState, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { ProjectedIncomeDetailsChart } from '../ProjectedIncomeDetailsChart/ProjectedIncomeDetailsChart'
import ProjectedIncomeDetailTable from '../ProjectedIncomeDetailTable/ProjectedIncomeDetailTable'
import useEstimatedIncomeChartData from '../useEstimatedIncomeChartData'
import useEstimatedIncomeTableData from '../useEstimatedIncomeTableData'
import ProjectedIncomeDetailsHeader from './ProjectedIncomeDetailsHeader'

export const getClasses = (theme: Theme) => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  chartContainer: css({
    backgroundColor: theme.colors.primaryWhite,
    border: `solid 1px ${theme.colors.extraGray1}`,
    paddingTop: '20px',
    paddingLeft: 224,
    paddingRight: 88,
    overflow: 'auto'
  }),
  tableContainer: css({
    minWidth: '1140px',
    marginTop: '34px'
  }),
  iconStyle: css({
    display: 'inline-block',
    cursor: 'pointer'
  })
})

const ProjectedIncomeDetails: React.FC = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [includeOptionPremiums, setIncludeOptionPremiums] = useState(true)
  const [searchString, setSearchString] = useState<string>('')
  const [isEstimated, setIsEstimated] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [financialYear, setFinancialYear] = useState<number>(currentYear)
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const {
    data: estimatedChartIncomeData,
    isLoading: isEstimatedchartDataLoading,
    isFetching: isEstimatedChartDataFetching,
    isError: isEstimatedChartError,
    error: estimatedChartError,
    refetch: refetchEstimatedChartIncomeData
  } = useGetChartEstimatedIncomeSummaryQuery(
    { financialYear, accounts: apiContextAccounts },
    {
      skip: !apiContextAccounts?.length || isEstimated
    }
  )
  const {
    data: projectedChartIncomeData,
    isLoading: isProjectedChartDataLoading,
    isFetching: isProjectedChartDataFetching,
    error: projectedChartError,
    refetch: refetchProjectedChartIncomeData
  } = useGetChartProjectedIncomeSummaryQuery(apiContextAccounts, {
    skip: !apiContextAccounts?.length || !isEstimated
  })
  const {
    data: projectedTableIncomeData,
    isLoading: isProjectedTableDataLoading,
    isFetching: isProjectedTableDataFetching,
    isError: isProjectedTableError,
    error: projectedTableError,
    refetch: refetchProjectedTableIncomeData
  } = useGetTableProjectedIncomeSummaryQuery(apiContextAccounts, {
    skip: !apiContextAccounts?.length || !isEstimated
  })
  const {
    data: estimatedTableIncomeData,
    isLoading: isEstimatedTableDataLoading,
    isFetching: isEstimatedTableDataFetching,
    error: estimatedTableError,
    refetch: refetchEstimatedTableIncomeData
  } = useGetTableEstimatedIncomeSummaryQuery(
    { financialYear, accounts: apiContextAccounts },
    {
      skip: !apiContextAccounts?.length || isEstimated
    }
  )
  const {
    data: lastYearEstimatedTableIncomeData,
    isLoading: isLastYearEstimatedTableDataLoading,
    isFetching: isLastYearEstimatedTableDataFetching,
    error: lastYearEstimatedTableError,
    refetch: refetchLastYearEstimatedTableIncomeData
  } = useGetTableEstimatedIncomeSummaryQuery(
    { financialYear: financialYear - 1, accounts: apiContextAccounts },
    {
      skip:
        !apiContextAccounts?.length ||
        isEstimated ||
        financialYear !== currentYear ||
        currentMonth === 11
    }
  )
  const estimatedIncomeData = useEstimatedIncomeTableData(
    estimatedTableIncomeData,
    lastYearEstimatedTableIncomeData
  )

  const projectedIncomeData = useEstimatedIncomeTableData(
    projectedTableIncomeData
  )
  const refetchEstimatedTableData = useCallback(() => {
    refetchEstimatedTableIncomeData()
    if (financialYear === currentYear && currentMonth !== 11) {
      refetchLastYearEstimatedTableIncomeData()
    }
  }, [
    currentMonth,
    currentYear,
    financialYear,
    refetchEstimatedTableIncomeData,
    refetchLastYearEstimatedTableIncomeData
  ])
  const toggleAccount = () => {
    setIsExpanded(!isExpanded)
  }
  const handleIncomeTypeChangeClick = useCallback(() => {
    setIsEstimated(!isEstimated)
  }, [isEstimated])
  const onDateRangeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>
  ) => {
    if (option) {
      setSearchString('')
      setFinancialYear(+option.key)
    }
  }
  const onSearchInputChange = useCallback((value: string) => {
    setSearchString(value)
  }, [])
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const handleCheckboxChange = useCallback((e: any) => {
    setIncludeOptionPremiums(e.target.checked)
  }, [])
  const isChartError = useMemo(
    () => !!estimatedChartError || !!projectedChartError,
    [estimatedChartError, projectedChartError]
  )
  const chartErrorMessage =
    (estimatedChartError as Error)?.message ||
    (projectedChartError as Error)?.message ||
    'An unknown Error Occurred'
  const isTableError = useMemo(
    () =>
      !!estimatedTableError ||
      !!projectedTableError ||
      !!lastYearEstimatedTableError,
    [estimatedTableError, projectedTableError, lastYearEstimatedTableError]
  )
  const tableErrorMessage =
    (estimatedTableError as Error)?.message ||
    (projectedTableError as Error)?.message ||
    'An unknown Error Occurred'

  const { estimatedIncomeTotal } = useEstimatedIncomeChartData(
    includeOptionPremiums,
    estimatedChartIncomeData
  )

  return (
    <div>
      <ProjectedIncomeDetailsHeader
        searchString={searchString}
        selectedDataRange={financialYear.toString()}
        isEstimated={isEstimated}
        isOptionsPremiumsAvailable={
          !!(
            estimatedChartIncomeData?.optionPremiumsPaid ||
            estimatedChartIncomeData?.optionPremiumsReceived
          )
        }
        onIncomeTypeChangeClick={handleIncomeTypeChangeClick}
        onDateRangeChange={onDateRangeChange}
        onSearchInputChange={onSearchInputChange}
        includeOptionPremiums={includeOptionPremiums}
        onHandleCheckboxChange={handleCheckboxChange}
      />
      {(isEstimatedChartDataFetching ||
        isEstimatedchartDataLoading ||
        isProjectedChartDataFetching ||
        isProjectedChartDataLoading) && <IndeterminateProgressIndicator />}
      <div>
        <GroupItem>
          <Label
            onClick={(event) => {
              toggleAccount()
              event.preventDefault()
              return false
            }}
          >
            {!isExpanded ? (
              <CheveronCircle css={classes.iconStyle}>
                <Icon iconName="ChevronUpSmall" />
              </CheveronCircle>
            ) : (
              <CheveronCircle css={classes.iconStyle}>
                <Icon iconName="ChevronDownSmall" />
              </CheveronCircle>
            )}
            <span>
              12 Months {!isEstimated ? 'Historical' : 'Projected'} Income:{' '}
              <strong>
                <USD
                  value={
                    !isEstimated
                      ? estimatedIncomeTotal
                      : projectedTableIncomeData?.Total || 0
                  }
                />
              </strong>
            </span>
          </Label>
        </GroupItem>
        {isChartError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar
              message={chartErrorMessage}
              type="Failure"
              actionButtonLabel="click to retry"
              onActionButtonClicked={
                isEstimatedChartError
                  ? refetchEstimatedChartIncomeData
                  : refetchProjectedChartIncomeData
              }
            />
          </div>
        )}
        {isExpanded && (
          <>
            <div css={classes.chartContainer}>
              <ProjectedIncomeDetailsChart
                includeOptionPremiums={includeOptionPremiums}
                incomeType={!isEstimated ? 'historical' : 'projected'}
                financialYear={financialYear}
                data={
                  !isEstimated
                    ? estimatedChartIncomeData
                    : projectedChartIncomeData
                }
              />
            </div>
            <div css={{ height: 24, padding: '6px 0' }}>
              {includeOptionPremiums && (
                <div css={{ fontSize: 12, fontWeight: 500 }}>
                  *For informational purposes only. Option premiums are taxed as
                  capital gains/losses and not as ordinary income.
                </div>
              )}
            </div>
          </>
        )}
        <HorizontalScrollContainer>
          <div css={classes.tableContainer}>
            {(isProjectedTableDataLoading ||
              isProjectedTableDataFetching ||
              isLastYearEstimatedTableDataLoading ||
              isLastYearEstimatedTableDataFetching ||
              isEstimatedTableDataLoading ||
              isEstimatedTableDataFetching) && (
              <div>
                <IndeterminateProgressIndicator />
              </div>
            )}
            <ProjectedIncomeDetailTable
              financialYear={financialYear}
              incomeType={!isEstimated ? 'historical' : 'projected'}
              searchString={searchString}
              data={!isEstimated ? estimatedIncomeData : projectedIncomeData}
            />
            {isTableError && (
              <div css={{ paddingBottom: '5px' }}>
                <SnackBar
                  message={tableErrorMessage}
                  type="Failure"
                  actionButtonLabel="click to retry"
                  onActionButtonClicked={
                    isProjectedTableError
                      ? refetchProjectedTableIncomeData
                      : refetchEstimatedTableData
                  }
                />
              </div>
            )}
          </div>
        </HorizontalScrollContainer>
      </div>
    </div>
  )
}
export default ProjectedIncomeDetails
