import { css } from '@emotion/react'
import { IconButton, Modal } from '@fluentui/react'
import { FC, useCallback, useMemo, useState } from 'react'
import { USD } from 'shared/components/Formatting'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import { GetIncomeSummaryChartView, IncomeCategory } from '../../store/types'
import { DataTable } from '../shared'
import IncomeDisclamer from './IncomeDisclamer'
import columns from './ProjectedIncomeTableColumns'

export const getClasses = () => ({
  checkboxContainer: css({
    margin: '4px 0px 8px 0px',
    display: 'flex',
    flexDirection: 'row'
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  }),
  incomeTable: css({
    minHeight: 0,
    table: {
      'td:first-of-type': { paddingLeft: 6 },
      'td:last-of-type': { paddingRight: 6 }
    },
    thead: {
      th: {
        padding: '12px 4px'
      }
    },
    tbody: {
      td: {
        textTransform: 'capitalize',
        padding: '8px 4px'
      }
    }
  }),
  disclaimerContainer: css({
    margin: '4px 0',
    fontSize: 10,
    marginTop: 'auto'
  }),
  disclaimerButton: css({
    fontSize: 10,
    padding: '0 3px',
    color: '#2A769D',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  })
})

const belowTheLine = ['principal', 'interestPurchasedSold']

const ProjectedIncomeTable: FC<{
  data?: GetIncomeSummaryChartView
  isOptionsPremiumsAvailable: boolean
}> = ({ data, isOptionsPremiumsAvailable }) => {
  const [includeOptionPremiums, setIncludeOptionPremiums] = useState(true)
  const classes = useMemo(() => getClasses(), [])
  const principalData = useMemo(
    () =>
      data?.IncomeCategories.filter((item) => belowTheLine.includes(item.id)),
    [data]
  )

  const [showModal, setShowModal] = useState(false)

  const onDismissModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const onLinkClicked = useCallback(() => {
    setShowModal(true)
  }, [])

  const tableData = useMemo(() => {
    let incomeData: IncomeCategory[] = []

    if (data) {
      const { IncomeCategories } = data

      if (includeOptionPremiums && isOptionsPremiumsAvailable) {
        incomeData = IncomeCategories.map((item) => ({
          ...item
        }))
      } else {
        incomeData = IncomeCategories.filter(
          (item) => !item.id.includes('optionPremiums')
        )
      }

      const totals: IncomeCategory = {
        Description: 'Total',
        Last12Months: 0,
        Next12Months: 0,
        id: 'total',
        YTD: 0
      }

      incomeData = incomeData.filter((item) => !belowTheLine.includes(item.id))

      const totalData: IncomeCategory = incomeData.reduce(
        (acc, item) => ({
          ...acc,
          Last12Months: (acc.Last12Months || 0) + (item.Last12Months || 0),
          Next12Months: (acc.Next12Months || 0) + (item.Next12Months || 0),
          YTD: (acc.YTD || 0) + (item.YTD || 0)
        }),
        totals
      )

      incomeData.push(totalData)
    }

    return incomeData
  }, [data, includeOptionPremiums, isOptionsPremiumsAvailable])

  const handleCheckboxChange = useCallback((e: any) => {
    setIncludeOptionPremiums(e.target.checked)
  }, [])

  return (
    <>
      {isOptionsPremiumsAvailable && (
        <div css={classes.checkboxContainer}>
          <div>
            <IndeterminateCheckbox
              id="optionPremiumsLabelCheckbox"
              className="checkbox"
              checked={includeOptionPremiums}
              onChange={handleCheckboxChange}
            />
          </div>
          <label
            htmlFor="optionPremiumsLabelCheckbox"
            css={classes.checkboxLabel}
          >
            Include Option Premiums
          </label>
        </div>
      )}
      <div css={classes.incomeTable}>
        <DataTable columns={columns} data={tableData} />
      </div>
      {!!principalData?.length && (
        <div css={classes.incomeTable}>
          <table
            css={{
              width: '100%',
              tableLayout: 'fixed',
              borderCollapse: 'collapse'
            }}
          >
            <tbody>
              {principalData.map((item) => (
                <tr
                  key={item.Description}
                  css={{
                    fontSize: 12
                  }}
                >
                  <td css={{ width: 172 }}>{item.Description}</td>
                  <td align="right">
                    {item.YTD ? (
                      <USD
                        value={item.YTD}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td align="right">
                    {item.Last12Months ? (
                      <USD
                        value={item.Last12Months}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td align="right">
                    {item.Next12Months ? (
                      <USD
                        value={item.Next12Months}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {includeOptionPremiums && (
        <div css={classes.disclaimerContainer}>
          <button css={classes.disclaimerButton} onClick={onLinkClicked}>
            Click here for more information on your projected income.
          </button>
          {showModal && (
            <Modal
              isOpen={showModal}
              onDismiss={onDismissModal}
              styles={{ main: { display: 'flex' } }}
            >
              <IconButton
                styles={{
                  root: { position: 'absolute', right: 24, top: 24 }
                }}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup modal"
                onClick={onDismissModal}
              />
              <IncomeDisclamer />
            </Modal>
          )}
        </div>
      )}
    </>
  )
}

export default ProjectedIncomeTable
