import axios from 'axios'
import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers, Reducer } from 'redux'
import { call, cancelled, put, select, takeEvery } from 'typed-redux-saga'
import { createAction } from 'typesafe-actions'
import {
  getMarginRateRequests,
  IMarginRateRequest,
  MarginRateRequestStatusEnum
} from '../../../../../../../../api/dynamics'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../../../../../api/odata'
import { IOdataRequest } from '../../../../../../../../api/odata.types'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { marginRateRequestPostActions } from '../../../../../../../../features/MarginRateAdjustment/store/marginRateRequestsPost'
import { IOdataListChunkPayload } from '../../../../../../../../features/OdataList/common/IOdataListDataActions'
import { IOdataListDataState } from '../../../../../../../../features/OdataList/common/IOdataListDataState'
import { IOdataListUiState } from '../../../../../../../../features/OdataList/common/IOdataListUiState'
import { convertColumnTypeToFilterType } from '../../../../../../../../features/OdataList/common/service'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../../../../../../../../features/OdataList/common/types'
import { createOdataListDataStore } from '../../../../../../../../features/OdataList/store/odataListDataStore'
import { createOdataListUiStore } from '../../../../../../../../features/OdataList/store/odataListUiStore'
import { IOdataResult } from '../../../../../../../../shared/contracts/IOdataResult'
import { isNotNullOrFalse } from '../../../../../../../../shared/gaurds'
import { AppState } from '../../../../../../../../store'
import { getDynamicsApiOptions } from '../../../../../../../../store/shared/sagas'

export type MarginRateRequestListColumnName =
  | 'Household Name'
  | 'Accounts'
  | 'Rate'
  | 'Rate Type'
  | 'Justification'
  | 'Status'
  | 'Last Modified'
  | 'Created'

export interface IMarginRateRequestListColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IMarginRateRequest> {
  name: MarginRateRequestListColumnName
}

const defaultColumn: Partial<IMarginRateRequestListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const marginRateRequestListColumns: IMarginRateRequestListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Household Name',
      dataPath: 'rcm_householdname',
      type: 'string',
      width: 200,
      searchFields: ['rcm_householdname'],
      getValue: ({ rcm_householdname }) => rcm_householdname
    },
    {
      ...defaultColumn,
      name: 'Accounts',
      dataPath: 'rcm_accounts',
      type: 'string',
      width: 80,
      searchFields: ['rcm_accounts'],
      getValue: ({ rcm_accounts }) => rcm_accounts
    },
    {
      ...defaultColumn,
      name: 'Status',
      dataPath: 'rcm_status',
      type: 'string',
      width: 100,
      facetable: true,
      getValue: ({ rcm_status }) => rcm_status
    },
    {
      ...defaultColumn,
      name: 'Rate Type',
      dataPath: 'rcm_ratetype',
      type: 'string',
      width: 100,
      facetable: true,
      getValue: ({ rcm_ratetype }) => rcm_ratetype
    },
    {
      ...defaultColumn,
      name: 'Rate',
      dataPath: 'rcm_rate',
      type: 'number',
      width: 100,
      getValue: ({ rcm_rate }) => rcm_rate
    },
    {
      ...defaultColumn,
      name: 'Justification',
      dataPath: 'rcm_justification',
      type: 'string',
      width: 400,
      getValue: ({ rcm_justification }) => rcm_justification
    },
    {
      ...defaultColumn,
      name: 'Last Modified',
      dataPath: 'modifiedon',
      type: 'date',
      width: 150,
      getValue: ({ modifiedon }) => modifiedon
    },
    {
      ...defaultColumn,
      name: 'Created',
      dataPath: 'createdon',
      type: 'date',
      width: 150,
      getValue: ({ createdon }) => createdon
    }
  ]

const fetchMarginRateRequests = function* (
  request: IOdataRequest,
  chunks?: IOdataResult<IMarginRateRequest>[]
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getDynamicsApiOptions)
  const [last] = chunks?.slice(-1) || []
  const next = last?.['@odata.nextLink']

  try {
    if (next) {
      const fetchRequests = () =>
        axios
          .get<IOdataResult<IMarginRateRequest>>(next, {
            headers: {
              Authorization: `Bearer ${apiOptions.accessToken}`,
              Prefer: [
                !!request.top && `odata.maxpagesize=${request.top}`,
                `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
              ]
                .filter(isNotNullOrFalse)
                .join(',')
            }
          })
          .then((x) => x.data)
      return yield* call(fetchRequests)
    }
    const result = yield* call(getMarginRateRequests, apiOptions, request)
    return result
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

const rootListSelector = (state: AppState) =>
  state.modules.advisory.modules.accounts.modules.marginRateRequests.features
    .marginRateRequestList

const dataStore = createOdataListDataStore(
  '@modules/@advisory/@modules/@marginRateRequest',
  fetchMarginRateRequests,
  flow(rootListSelector, ({ data }) => data)
)
export const {
  actions: marginRateRequestListDataActions,
  selectors: marginRateRequestListDataSelectors
} = dataStore
const uiFilters = keyBy(
  marginRateRequestListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }
      if (column.facetable && column.name === 'Rate Type') {
        return {
          ...base,
          facets: [
            { value: 'Existing' },
            { value: 'Suggested' },
            { value: 'Custom' }
          ]
        } as IListsFacetFilter
      }
      if (column.filterable && column.facetable) {
        return {
          ...base,
          facets: Object.entries(MarginRateRequestStatusEnum)
            .filter((key) => !(parseInt(key[0]) >= 0))
            .map(([key]) => ({ value: key }))
        } as IListsFacetFilter
      }

      return base
    }),
  ({ id }) => id
)
const convertStatusColumnFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0

  if (!hasValue || !dataPath) {
    return
  }

  return {
    and: [
      {
        operator: OdataFilterOperatorEnum.dynamicsin,
        value: Object.entries(MarginRateRequestStatusEnum)
          .filter(([key]) => values?.includes(key))
          .map(([, value]) => value as string),
        path: dataPath,
        type: 'number'
      }
    ]
  }
}
const convertRequestTypeColumnFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0
  if (!hasValue || !dataPath) {
    return
  }

  return {
    and: [
      {
        operator: OdataFilterOperatorEnum.eq,
        value: values?.[0],
        path: dataPath,
        type: 'string'
      }
    ]
  }
}

const uiStore = createOdataListUiStore({
  prefix:
    '@modules/@advisory/@modules/@marginRateRequest/@features/@marginRateRequestList',
  initialState: {
    columns: marginRateRequestListColumns,
    filters: uiFilters,
    sortBy: { direction: 'desc', name: 'Last Modified' }
  },
  rootSelector: flow(rootListSelector, (x) => x.ui),
  dataStore,
  onConvertToOdataFilter: (filter) => {
    const filterName = filter.name as MarginRateRequestListColumnName
    if (filterName !== 'Status') {
      return convertRequestTypeColumnFilterToOdataFilter(filter)
    }

    return convertStatusColumnFilterToOdataFilter(filter)
  }
})

export const {
  selectors: marginRateRequestListUiSelectors,
  actions: marginRateRequestListUiActions
} = uiStore
export const marginRateRequestListReducer: Reducer<{
  data: IOdataListDataState<IMarginRateRequest>
  ui: IOdataListUiState
}> = combineReducers({
  data: dataStore.reducer,
  ui: uiStore.reducer
})

const ADD =
  '@modules/@advisory/@modules/@marginRateRequest/@features/@marginRateRequestList/ADD'
const UPDATE =
  '@modules/@advisory/@modules/@marginRateRequest/@features/@marginRateRequestList/UPDATE'
const DELETE =
  '@modules/@advisory/@modules/@marginRateRequest/@features/@marginRateRequestList/DELETE'
export const marginRateRequestListUpdateActions = {
  add: createAction(ADD)<IMarginRateRequest>(),
  update: createAction(UPDATE)<IMarginRateRequest>(),
  delete: createAction(DELETE)<string>()
}

const marginRateAccountListUpdateSagas = [
  () =>
    takeEvery(
      marginRateRequestPostActions.success,
      function* (
        action: ReturnType<typeof marginRateRequestPostActions.success>
      ) {
        yield put(marginRateRequestListUpdateActions.add(action.payload))
      }
    ),
  () =>
    takeEvery(
      marginRateRequestListUpdateActions.add,
      function* (
        action: ReturnType<typeof marginRateRequestListUpdateActions.add>
      ) {
        const newItem = action.payload
        const chunks = yield* select(
          marginRateRequestListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        if (!chunks?.[0].value) {
          return
        }
        const itemsCopy = [...chunks[0].value]
        itemsCopy.unshift(newItem)
        const updateChunkPayload = {
          index: 0,
          result: { ...chunks[0], value: itemsCopy }
        }

        if (!updateChunkPayload) {
          return
        }

        yield put(
          marginRateRequestListDataActions.updateChunk(updateChunkPayload)
        )
      }
    ),
  () =>
    takeEvery(
      marginRateRequestListUpdateActions.update,
      function* (
        action: ReturnType<typeof marginRateRequestListUpdateActions.update>
      ) {
        const updatedItem = action.payload
        const chunks = yield* select(
          marginRateRequestListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        let updateChunkPayload:
          | IOdataListChunkPayload<IMarginRateRequest>
          | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) =>
              x.rcm_marginraterequestid === updatedItem?.rcm_marginraterequestid
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          itemsCopy.splice(itemIndex, 1, updatedItem)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          marginRateRequestListDataActions.updateChunk(updateChunkPayload)
        )
      }
    ),
  () =>
    takeEvery(
      marginRateRequestListUpdateActions.delete,
      function* (
        action: ReturnType<typeof marginRateRequestListUpdateActions.delete>
      ) {
        const chunks = yield* select(
          marginRateRequestListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        let updateChunkPayload:
          | IOdataListChunkPayload<IMarginRateRequest>
          | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) => x.rcm_marginraterequestid === action.payload
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          itemsCopy.splice(itemIndex, 1)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          marginRateRequestListDataActions.updateChunk(updateChunkPayload)
        )
      }
    )
]

export const marginRateRequestListSagas = [
  ...dataStore.sagas,
  ...uiStore.sagas,
  ...marginRateAccountListUpdateSagas
]
