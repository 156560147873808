import { StrictEffect } from '@redux-saga/core/effects'
import { sum } from 'lodash'
import { flow } from 'lodash/fp'
import { ForkEffect } from 'redux-saga/effects'
import { call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga'
import { ActionType, createReducer, Reducer } from 'typesafe-actions'
import { IOdataRequest } from '../../../api/odata.types'
import { IOdataResult } from '../../../shared/contracts/IOdataResult'
import { isNotNullOrUndefined } from '../../../shared/gaurds'
import {
  IOdataDataActions,
  IOdataListChunkPayload,
  OdataListDataRequest
} from '../common/IOdataListDataActions'
import { IOdataListDataSelectors } from '../common/IOdataListDataSelectors'
import { IOdataListDataState } from '../common/IOdataListDataState'
import { createActionWithPrefix, createEmptyActionWithPrefix } from './service'

export interface IOdataListDataStore<T, U> {
  actions: IOdataDataActions<T>
  reducer: Reducer<IOdataListDataState<T>, ActionType<IOdataDataActions<T>>>
  selectors: IOdataListDataSelectors<T, U>
  sagas: (() => SagaGenerator<never, ForkEffect<never>>)[]
}
export const createOdataListDataStore = <T, U>(
  prefix: string,
  getOdataResults: (
    request: IOdataRequest,
    chunks?: IOdataResult<T>[]
  ) => Generator<StrictEffect, IOdataResult<T>, unknown>,
  rootSelector: (state: U) => IOdataListDataState<T> | undefined
): IOdataListDataStore<T, U> => {
  const REQUESTED = '@features/@odataListData/REQUESTED'
  const COMPLETE = '@features/@odataListData/COMPLETE'
  const CHUNK = '@features/@odataListData/CHUNK'
  const LOAD_MORE = '@features/@odataListData/LOAD_MORE'
  const ERROR = '@features/@odataListData/ERROR'
  const UPDATE_CHUNK = '@features/@odataListData/UPDATE_CHUNK'

  const actions: IOdataDataActions<T> = {
    request: createActionWithPrefix(prefix, REQUESTED)<OdataListDataRequest>(),
    complete: createActionWithPrefix(prefix, COMPLETE)<OdataListDataRequest>(),
    chunk: createActionWithPrefix(prefix, CHUNK)<IOdataListChunkPayload<T>>(),
    loadMore: createEmptyActionWithPrefix(prefix, LOAD_MORE)(),
    error: createActionWithPrefix(prefix, ERROR)<Error>(),
    updateChunk: createActionWithPrefix(prefix, UPDATE_CHUNK)<
      IOdataListChunkPayload<T>
    >()
  }

  const initialState: IOdataListDataState<T> = { loading: true }
  const reducer = createReducer<
    IOdataListDataState<T>,
    ActionType<IOdataDataActions<T>>
  >(initialState)
    .handleAction(actions.request, (state, action) => ({
      ...state,
      request: action.payload,
      progress: 0,
      loading: true,
      error: undefined
    }))
    .handleAction(actions.loadMore, (state) => ({
      ...state,
      loading: true,
      error: undefined
    }))
    .handleAction(actions.complete, (state) => ({
      ...state,
      loading: false
    }))
    .handleAction(actions.chunk, (state, action) => {
      const isFirstChunk = action.payload.index === 0
      const chunks = [...(isFirstChunk ? [] : state.chunks || [])]
      chunks[action.payload.index] = action.payload.result
      const totalCount = isFirstChunk
        ? action.payload.result['@odata.count']
        : state.totalCount

      return {
        ...state,
        chunks,
        totalCount,
        progress: chunks
          .map((x) => x?.value?.length)
          .filter(isNotNullOrUndefined)
          .reduce((a, x) => a + x, 0)
      }
    })
    .handleAction(actions.error, (state, action) => ({
      ...initialState,
      error: action.payload
    }))
    .handleAction(actions.updateChunk, (state, action) => {
      const { index, result } = action.payload
      if (!state?.chunks?.length || index >= state.chunks.length) {
        return state
      }
      const chunks = [...state.chunks]
      chunks.splice(index, 1, result)
      return {
        ...state,
        chunks
      }
    })

  const selectors: IOdataListDataSelectors<T, U> = {
    getRequest: flow(rootSelector, (x) => x?.request),
    getChunks: flow(rootSelector, (x) => x?.chunks),
    getError: flow(rootSelector, (x) => x?.error),
    getIsLoading: flow(rootSelector, (x) => x?.loading),
    getProgress: flow(rootSelector, (x) => x?.progress),
    getTotalCount: flow(rootSelector, (x) => x?.totalCount)
  }

  const chunkSize = 200
  const initialChunkSize = 50

  const onRequest = function* (action: ReturnType<typeof actions.request>) {
    const odataRequest: IOdataRequest = {
      ...action.payload,
      top: initialChunkSize,
      count: true
    }

    try {
      const chunk = yield* call(getOdataResults, odataRequest)

      yield put(
        actions.chunk({
          index: 0,
          result: chunk
        })
      )
    } catch (e: any) {
      yield put(actions.error(e))
    }

    yield put(actions.complete(odataRequest))
  }

  const onLoadMore = function* () {
    const request = yield* select(selectors.getRequest)
    if (!request) {
      throw new Error('Invalid Request: loadMore called before request')
    }

    const chunks = yield* select(selectors.getChunks)
    if (!chunks?.length) {
      throw new Error('Invalid State: chunks are undefined')
    }

    const currentChunkIndex = chunks.length - 1

    const totalCount = yield* select(selectors.getTotalCount)

    const skip = sum(chunks.map(({ value }) => value?.length || 0))
    const top = chunkSize

    if (!totalCount || skip > totalCount) {
      yield* put(actions.complete(request))
      return
    }

    const odataRequest: IOdataRequest = {
      ...request,
      top,
      skip
    }

    try {
      const chunks = yield* select(selectors.getChunks)
      const chunk = yield* call(getOdataResults, odataRequest, chunks)

      yield* put(
        actions.chunk({
          index: currentChunkIndex + 1,
          result: chunk
        })
      )
    } catch (e: any) {
      yield* put(actions.error(e))
    }

    yield* put(actions.complete(odataRequest))
  }

  const sagas = [
    () => takeEvery(actions.loadMore, onLoadMore),
    () => takeEvery(actions.request, onRequest)
  ]

  return {
    actions,
    reducer,
    selectors,
    sagas
  }
}
