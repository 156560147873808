import { css, useTheme } from '@emotion/react'
import { Callout, DirectionalHint } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { INfsProfile } from 'api/datahub'
import { orderBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { SnackBar } from '../../components/shared/Snackbar'
import { Icon } from '../../features/Icons/Icon'
import { useRdot360_getRelatedNfsProfilesQuery } from '../../store/datahub'
import {
  useGetChildrenFromFolderQuery,
  useGetCollaborationGroupsQuery,
  useGetGroupRootDriveQuery
} from '../../store/graph'
import { useRdot360ClientContext } from '../../store/rdot360Context'
import { useRdot360AccountContext } from '../../store/rdot360Context/useRdot360AccountContext'
import { CollaborationTable } from './CollaborationTable/CollaborationTable'
import { useCollaborationUiState } from './CollaborationUIState'
import { CreateFolderDialog } from './CreateFolderDialog'
import { DeleteItemDialog } from './DeleteItemDialog'
import { ItemRenameDialog } from './ItemRename'
import { UploadItemDialog } from './UploadItemDialog'

const downloadUrlAsFile = (url: string, filename: string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = filename
  a.click()
}

export const CollaborationContainer: React.FC = () => {
  const { householdAccounts } = useRdot360AccountContext()
  const accountNumbers = useMemo(
    () => householdAccounts?.map((x) => x.id || ''),
    [householdAccounts]
  )
  const {
    setShowCreateFolder,
    menuItem,
    showUpload,
    setShowUpload,
    folderHierarchy,
    onHierarchyClicked,
    setDriveId,
    driveId,
    selectedCollaborator,
    itemToRename,
    setSelectedCollaborator
  } = useCollaborationUiState()
  const {
    data: NFSProfiles,
    isFetching: isProfilesFetching,
    error: nfsProfileError
  } = useRdot360_getRelatedNfsProfilesQuery(accountNumbers || [], {
    skip: !accountNumbers || !accountNumbers.length
  })
  const filteredProfiles = useMemo(
    () => NFSProfiles?.filter((x) => x.fullname),
    [NFSProfiles]
  )
  const { headOfHousehold } = useRdot360ClientContext()
  useEffect(() => {
    if (!filteredProfiles?.find((x) => x.aaduseroid)) {
      setSelectedCollaborator(undefined)
      return
    }
    const profile = filteredProfiles?.find(
      (x) =>
        x.wsportaluserid === headOfHousehold?.loginDetails?.[0]?.WealthscapeID
    )
    if (profile) {
      setSelectedCollaborator(profile?.aaduseroid)
      return
    }
    const ordered = orderBy(
      filteredProfiles?.filter((x) => x.lastlogindate),
      (x) => x.lastlogindate,
      'desc'
    )
    setSelectedCollaborator(ordered?.[0]?.aaduseroid || undefined)
  }, [filteredProfiles, headOfHousehold, setSelectedCollaborator])
  const {
    data: groups,
    isFetching,
    error: collaborationGroupError
  } = useGetCollaborationGroupsQuery(selectedCollaborator || skipToken)
  const noGroupError = useMemo(
    () =>
      selectedCollaborator && !groups?.length && !isFetching
        ? new Error('No collaboration groups found')
        : undefined,
    [groups?.length, isFetching, selectedCollaborator]
  )
  const firstGroupId = useMemo(() => groups?.[0]?.id, [groups])
  const {
    data: rootDrive,
    isFetching: isGroupFetching,
    error: rootDriveError
  } = useGetGroupRootDriveQuery(firstGroupId || skipToken)

  const selectedFolder = useMemo(
    () => folderHierarchy?.[folderHierarchy.length - 1]?.id,
    [folderHierarchy]
  )
  useEffect(() => {
    setDriveId(rootDrive?.[0]?.parentReference?.driveId)
  }, [rootDrive, setDriveId])
  const {
    data: childDrive,
    isFetching: isChildrenFetching,
    error: childDriveError
  } = useGetChildrenFromFolderQuery(
    { driveId: driveId, folderId: selectedFolder },
    {
      skip: !selectedFolder || !driveId
    }
  )

  const error: Error = useMemo(
    () =>
      (nfsProfileError as Error) ||
      (collaborationGroupError as Error) ||
      (rootDriveError as Error) ||
      (childDriveError as Error) ||
      noGroupError,
    [
      childDriveError,
      collaborationGroupError,
      nfsProfileError,
      noGroupError,
      rootDriveError
    ]
  )
  const filesToDisplay = useMemo(() => {
    if (error) {
      return []
    }
    if (childDrive) {
      return childDrive
    } else {
      return rootDrive || []
    }
  }, [childDrive, rootDrive, error])

  const itemInitialName = useMemo(
    () => filesToDisplay.find((x) => x.id === itemToRename)?.name,
    [filesToDisplay, itemToRename]
  )

  const shouldDisableButtons = useMemo(
    () => !groups || !groups.length || !!error,
    [error, groups]
  )

  const isLoading = useMemo(
    () =>
      isFetching || isGroupFetching || isChildrenFetching || isProfilesFetching,
    [isChildrenFetching, isFetching, isGroupFetching, isProfilesFetching]
  )

  const showBlankFolder = useMemo(
    () => !shouldDisableButtons && filesToDisplay.length === 0 && !isLoading,
    [filesToDisplay.length, isLoading, shouldDisableButtons]
  )

  return (
    <>
      <div css={{ height: '100%' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 20,
            alignItems: 'flex-end'
          }}
        >
          <DetailsNavigator />

          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20
              }}
            >
              <div css={{ width: '350px' }}>
                <div css={{ fontSize: '14px', fontWeight: 500 }}>
                  Document Collaborator
                </div>
                <CollaboratorDropdown NFSProfiles={filteredProfiles || []} />
              </div>
            </div>
          </div>
          <div
            css={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'flex-end',
              marginLeft: 'auto',
              gridColumnGap: 5
            }}
          >
            <button
              onClick={() => setShowCreateFolder(true)}
              css={buttonStyles.secondary}
              disabled={shouldDisableButtons}
            >
              Add folder
            </button>
            <button
              css={buttonStyles.secondary}
              onClick={() => setShowUpload(true)}
              disabled={shouldDisableButtons}
            >
              Upload file
            </button>
          </div>
        </div>
        <div css={{ display: 'flex', gridColumnGap: 5, padding: '20px 0' }}>
          {folderHierarchy.map((x, i) => (
            <>
              {i !== 0 && <div css={{ cursor: 'default' }}>{'>'}</div>}
              <div
                key={i}
                onClick={() => onHierarchyClicked(i)}
                css={(theme) => ({
                  fontSize: theme.size.lg,
                  fontWeight:
                    i + 1 === folderHierarchy.length
                      ? theme.fontWeights.demi
                      : theme.fontWeights.regular,
                  cursor: 'pointer'
                })}
              >
                {x?.name}
              </div>
            </>
          ))}
        </div>
        {isLoading && <IndeterminateProgressIndicator />}
        <CollaborationTable filesToDisplay={filesToDisplay} />
        {showBlankFolder && <BlankFolder />}
        {!selectedCollaborator && NFSProfiles?.length && (
          <SnackBar type="Info" message={'Please select a collaborator'} />
        )}
        {(error || !filesToDisplay.length) &&
          !showBlankFolder &&
          selectedCollaborator &&
          !isLoading && (
            <SnackBar
              type={error ? 'Failure' : 'Info'}
              message={
                error
                  ? (error as Error)?.message || 'An unknown error occurred'
                  : 'No data available'
              }
            />
          )}
      </div>
      <CreateFolderDialog
        driveId={driveId}
        folderId={selectedFolder}
        groupId={firstGroupId}
      />
      {showUpload && (
        <UploadItemDialog
          driveId={driveId}
          folderId={selectedFolder}
          groupId={firstGroupId}
        />
      )}
      {itemToRename && <ItemRenameDialog initialValue={itemInitialName} />}
      {menuItem?.id && <CollaborationItemMenu />}
    </>
  )
}

const MenuItem = css({
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
})

const CollaborationItemMenu: React.FC = () => {
  const { menuItem, setMenuItem, setShowDeleteWarning, setItemToRename } =
    useCollaborationUiState()
  const onDeleteClick = useCallback(() => {
    if (!menuItem?.id) {
      return
    }
    setShowDeleteWarning(true)
  }, [menuItem?.id, setShowDeleteWarning])
  const onRenameClick = useCallback(() => {
    setItemToRename(menuItem?.id)
  }, [menuItem, setItemToRename])
  const onDownloadClick = useCallback(() => {
    if (!menuItem?.['@microsoft.graph.downloadUrl'] || !menuItem?.name) {
      return
    }
    downloadUrlAsFile(
      menuItem?.['@microsoft.graph.downloadUrl'],
      menuItem?.name
    )
  }, [menuItem])
  const theme = useTheme()
  const isFolder = useMemo(() => !!menuItem?.folder, [menuItem?.folder])
  return (
    <Callout
      target={`#id${menuItem?.id}`}
      onDismiss={() => setMenuItem(undefined)}
      styles={{
        calloutMain: {
          borderRadius: '8px',
          border: `1px solid ${theme.colors.panelCalloutBorder}`,
          background: 'white',
          boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
          width: '170px'
        }
      }}
      isBeakVisible={false}
    >
      <div>
        {!isFolder && (
          <div css={MenuItem} onClick={onDownloadClick}>
            Download
          </div>
        )}
        <div css={MenuItem} onClick={onRenameClick}>
          Rename
        </div>
        <div
          css={() => [MenuItem, { color: theme.colors.secondaryRed1 }]}
          onClick={onDeleteClick}
        >
          Delete
        </div>
      </div>
      <DeleteItemDialog />
    </Callout>
  )
}

const CollaboratorDropdown: React.FC<{ NFSProfiles: INfsProfile[] }> = ({
  NFSProfiles
}) => {
  const keyContacts = useMemo(
    () => NFSProfiles.filter((x) => x.role === 'keycontact'),
    [NFSProfiles]
  )
  const clients = useMemo(
    () =>
      orderBy(
        NFSProfiles.filter((x) => x.role === 'Client'),
        [
          (x) => !!x.aaduseroid,
          (x) => (x.lastlogindate ? new Date(x.lastlogindate) : '')
        ],
        ['desc', 'desc']
      ),
    [NFSProfiles]
  )
  const thirdParties = useMemo(
    () => NFSProfiles.filter((x) => x.role !== 'Client' && x?.aaduseroid),
    [NFSProfiles]
  )
  const { selectedCollaborator, setSelectedCollaborator } =
    useCollaborationUiState()
  const calloutTarget = useId('CollaboratorDropdown')
  const [showDropdown, setShowDropdown] = useState(false)
  const theme = useTheme()
  const selected = useMemo(
    () =>
      NFSProfiles?.filter((x) => x.aaduseroid === selectedCollaborator)?.[0],
    [NFSProfiles, selectedCollaborator]
  )
  const onSelected = useCallback(
    (profile: INfsProfile) => {
      setSelectedCollaborator(profile?.aaduseroid)
      setShowDropdown(false)
    },
    [setSelectedCollaborator]
  )
  return (
    <>
      <div
        css={(theme) => ({
          width: '350px',
          height: '48px',
          backgroundColor: theme.colors.primaryWhite,
          borderRadius: '8px',
          border: `1px solid ${theme.colors.panelCalloutBorder}`,
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: NFSProfiles.length ? 'pointer' : 'default',
          opacity: NFSProfiles.length ? 1 : 0.5
        })}
        id={calloutTarget}
        onClick={() => NFSProfiles.length && setShowDropdown(true)}
      >
        <div
          css={(theme) => ({
            fontWeight: theme.fontWeights.medium,
            fontSize: theme.size.md
          })}
        >
          {selected?.fullname || 'Select Collaborator'}
        </div>
        <Icon type="ChevronDown" width={16} height={16} />
      </div>
      {showDropdown && (
        <Callout
          target={`#${calloutTarget}`}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomCenter}
          style={{ width: '350px' }}
          onDismiss={() => setShowDropdown(false)}
          styles={{
            calloutMain: {
              borderRadius: '8px',
              border: `1px solid ${theme.colors.panelCalloutBorder}`,
              background: 'white',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
              padding: '8px 0',
              display: 'flex',
              flexDirection: 'column',
              gridRowGap: '8px'
            },
            root: { borderRadius: '8px', zIndex: 3 }
          }}
          directionalHintFixed={true}
          preventDismissOnEvent={(ev) => {
            if (ev.type === 'scroll') {
              return true
            }
            return false
          }}
          doNotLayer={true}
        >
          {keyContacts.length ? (
            <>
              <div
                css={(theme) => ({
                  color: theme.colors.primaryDarkBlue,
                  fontSize: theme.size.sm,
                  fontWeight: theme.fontWeights.medium,
                  padding: '0 20px'
                })}
              >
                Head of House
              </div>
              <div>
                {keyContacts.map((x, i) => (
                  <div
                    key={i}
                    css={{
                      padding: '12px 20px',
                      display: 'flex',
                      gridColumnGap: 5,
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                      }
                    }}
                    onClick={() => onSelected(x)}
                  >
                    <Icon
                      type="HeadOfHousehold"
                      width={16}
                      height={16}
                      color={theme.colors.tertiaryBlue1}
                    />
                    <div
                      css={(theme) => ({
                        fontSize: theme.size.md,
                        fontWeight: theme.fontWeights.regular
                      })}
                    >
                      {x?.fullname}
                    </div>
                  </div>
                ))}
              </div>
              <div
                css={(theme) => ({
                  borderTop: `1px solid ${theme.colors.panelCalloutBorder}`,
                  width: '100%',
                  paddingBottom: '12px'
                })}
              />
            </>
          ) : null}
          <div
            css={(theme) => ({
              color: theme.colors.primaryDarkBlue,
              fontSize: theme.size.sm,
              fontWeight: theme.fontWeights.medium,
              padding: '0 20px'
            })}
          >
            Clients
          </div>
          <div>
            {clients?.map((x, i) => (
              <div
                key={i}
                css={{
                  padding: '12px 20px',
                  display: 'flex',
                  gridColumnGap: 5,
                  cursor: x?.aaduseroid ? 'pointer' : 'default',
                  opacity: x?.aaduseroid ? 1 : 0.5,
                  ':hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                  }
                }}
                onClick={() => onSelected(x)}
              >
                <Icon
                  type="Profile"
                  width={16}
                  height={16}
                  color={theme.colors.tertiaryBlue1}
                />
                <div
                  css={(theme) => ({
                    fontSize: theme.size.md,
                    fontWeight: theme.fontWeights.regular
                  })}
                >
                  {x?.fullname}
                </div>
              </div>
            ))}
          </div>
          {thirdParties.length ? (
            <>
              <div
                css={(theme) => ({
                  borderTop: `1px solid ${theme.colors.panelCalloutBorder}`,
                  width: '100%',
                  paddingBottom: '12px'
                })}
              />
              <div
                css={(theme) => ({
                  color: theme.colors.primaryDarkBlue,
                  fontSize: theme.size.sm,
                  fontWeight: theme.fontWeights.medium,
                  padding: '0 20px'
                })}
              >
                Interested Third Parties
              </div>
              <div>
                {thirdParties?.map((x, i) => (
                  <div
                    key={i}
                    css={{
                      padding: '12px 20px',
                      display: 'flex',
                      gridColumnGap: 5,
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                      }
                    }}
                    onClick={() => onSelected(x)}
                  >
                    <Icon
                      type="InterestedParties"
                      width={16}
                      height={16}
                      color={theme.colors.tertiaryBlue1}
                    />
                    <div
                      css={(theme) => ({
                        fontSize: theme.size.md,
                        fontWeight: theme.fontWeights.regular
                      })}
                    >
                      {x?.fullname}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </Callout>
      )}
    </>
  )
}

const BlankFolder: React.FC = () => {
  const { setShowUpload } = useCollaborationUiState()
  return (
    <div
      css={{
        backgroundColor: '#ffffff',
        border: '1px solid #EBEBEB',
        height: '550px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        css={{
          backgroundColor: '#F6FBFF',
          border: '1px dashed #D3D3D3',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '40px',
          gridRowGap: 15
        }}
      >
        <Icon type="FileUpload" height={30} width={30} />
        <div
          css={(theme) => ({
            color: theme.colors.tertiaryBlue1,
            fontWeight: theme.fontWeights.bolder,
            cursor: 'pointer'
          })}
          onClick={() => setShowUpload(true)}
        >
          Upload File
        </div>
        <div
          css={(theme) => ({
            width: '500px',
            fontSize: theme.size.lg,
            color: theme.colors.primaryDarkBlue
          })}
        >
          Only .pdf, .xls, .xlsx, .csv, .doc, .docx, .png, .jpg, .tif, .pages,
          .txt, .log, .ppt, .pptx, .numbers, .heic files with max size of 100
          MB.
        </div>
      </div>
    </div>
  )
}
