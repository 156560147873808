import { constructFilterQuery, constructOdataQuery } from 'api/odata'
import { IOdataRequest } from 'api/odata.types'
import axios from 'axios'
import { cloneDeep, flow, uniq } from 'lodash/fp'
import { combineReducers } from 'redux'
import { StrictEffect } from 'redux-saga/effects'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/gaurds'
import { getRockefellerApiOptions } from 'store/shared'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { getODataFacets } from '../api/getOdataFacets'
import { IOdataListDataState } from '../common/IOdataListDataState'
import { IOdataListUiState } from '../common/IOdataListUiState'
import { convertToOdataFilter } from '../common/service'
import { createOdataListDataStore } from './odataListDataStore'
import {
  IOdataListFacetResult,
  IOdataListFacetState,
  createOdataListFacetStore
} from './odataListFacetStore'
import { createOdataListUiStore } from './odataListUiStore'

export interface IODataListState<T> {
  data: IOdataListDataState<T>
  ui: IOdataListUiState
  facets: IOdataListFacetState<T>
}

export interface ICreateOdataListWithFacetsStoreOptions<T, U> {
  prefix: string
  initialState: IODataListState<T>
  rootSelector: (state: U) => IODataListState<T> | undefined
  getOdataResults: (
    request: IOdataRequest,
    chunks?: IOdataResult<T>[]
  ) => Generator<StrictEffect, IOdataResult<T>, unknown>
}

export const createOdataListWithFacetsStore = <T, U>(
  options: ICreateOdataListWithFacetsStoreOptions<T, U>
) => {
  const { prefix, initialState, rootSelector, getOdataResults } = options

  const dataStore = createOdataListDataStore(
    prefix,
    getOdataResults,
    flow(rootSelector, (x) => x?.data)
  )

  const uiStore = createOdataListUiStore({
    prefix,
    initialState: initialState.ui,
    rootSelector: flow(rootSelector, (x) => x?.ui),
    dataStore
  })

  const facetStore = createOdataListFacetStore({
    prefix,
    rootSelector: flow(rootSelector, (x) => x?.facets)
  })

  const {
    reducer: dataReducer,
    selectors: dataSelectors,
    actions: dataActions,
    sagas: dataSagas
  } = dataStore

  const {
    reducer: uiReducer,
    selectors: uiSelectors,
    actions: uiActions,
    sagas: uiSagas
  } = uiStore

  const {
    reducer: facetReducer,
    selectors: facetSelectors,
    actions: facetActions
  } = facetStore

  const actions = { dataActions, uiActions, facetActions }

  const reducer = combineReducers({
    data: dataReducer,
    ui: uiReducer,
    facets: facetReducer
  })

  const selectors = { dataSelectors, uiSelectors, facetSelectors }

  const onFacetRequest = function* (
    action: ReturnType<typeof actions.facetActions.request>
  ) {
    const filters = yield* select(uiSelectors.getFilters)
    const columns = yield* select(uiSelectors.getColumns)
    const filtersClone = cloneDeep(filters)
    filtersClone && delete filtersClone[action.payload.id]
    const odataFilters = Object.values(filtersClone || {})
      .filter(({ hasValue }) => hasValue)
      .map((filter) => convertToOdataFilter(filter))
      .filter(isNotNullOrUndefined)
    const filter = constructFilterQuery(odataFilters)
    const search = yield* select(uiSelectors.getSearchText)
    const searchFields = uniq(
      (columns || []).flatMap((x) => x.searchFields).filter(isNotNullOrEmpty)
    )
    const query =
      filter || search
        ? constructOdataQuery({
            filters: filter.length ? [filter] : undefined,
            search,
            searchFields
          })
        : ''
    const queryFilter = query.replace('$filter=', '')
    // eslint-disable-next-line import/no-named-as-default-member
    const source = axios.CancelToken.source()
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)
    const requestedProperty = columns?.find(
      (x) => x.name === action.payload.id
    )?.dataPath
    try {
      if (!requestedProperty) {
        throw new Error('Property not found')
      }
      const result = yield* call(
        getODataFacets,
        apiOptions,
        requestedProperty,
        queryFilter ? `filter(${queryFilter})` : ''
      )
      if (!result?.value) {
        throw new Error('No facets returned from service')
      }
      const facetRecord: Record<string, IOdataListFacetResult<T>[]> = {
        [action.payload.id]: result.value
      }
      yield put(facetActions.complete(facetRecord))
    } catch (e: any) {
      yield put(facetActions.error(e))
    }
  }

  const sagas = [
    ...dataSagas,
    ...uiSagas,
    () => takeLatest(facetActions.request, onFacetRequest),
    () =>
      takeLatest(
        [
          uiActions.updateFilters,
          uiActions.updateSearchText,
          uiActions.resetFilters
        ],
        function* () {
          yield put(facetActions.reset())
        }
      )
  ]

  return { actions, reducer, selectors, sagas }
}
