import { DefaultButton, Stack, Text } from '@fluentui/react'
import { push } from 'connected-react-router'
import { FeedbackButtonContainer } from 'features/Header/containers/FeedbackButton'
import { SettingsButtonContainer } from 'features/Header/containers/SettingsButton'
import { UserButtonContainer } from 'features/Header/containers/UserButton'
import { SearchContainer } from 'features/Search'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { HeaderWrapper } from './HeaderWrapper'

export interface IHeaderContainerProps {
  title?: string
  onGoHome?: () => void
}

export const HeaderContainer: React.FC<IHeaderContainerProps> = ({
  title,
  onGoHome
}) => {
  const dispatch = useDispatch()
  const onHomeClicked = useCallback(
    () => (onGoHome ? onGoHome() : dispatch(push('/'))),
    [dispatch, onGoHome]
  )

  return (
    <HeaderWrapper>
      <Stack
        horizontal={true}
        horizontalAlign="space-between"
        verticalFill={true}
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
      >
        <Stack.Item align="center">
          <Stack horizontal={true}>
            <DefaultButton
              onClick={onHomeClicked}
              styles={{
                root: {
                  padding: 5,
                  border: 0,
                  marginLeft: '10px',
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Text nowrap={true}>
                <b>{title || 'BOOK OF BUSINESS'}</b>
              </Text>
            </DefaultButton>
          </Stack>
        </Stack.Item>

        <Stack.Item grow={1} align="center">
          <SearchContainer />
        </Stack.Item>

        <Stack.Item>
          <Stack horizontal={true}>
            <FeedbackButtonContainer />
            <SettingsButtonContainer />
            <UserButtonContainer />
          </Stack>
        </Stack.Item>
      </Stack>
    </HeaderWrapper>
  )
}
