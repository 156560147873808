import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { USD } from 'shared/components/Formatting'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { IColumn } from '../shared'
import { GainLossDescriptionEnum } from './dataMapper'

const DescriptionCell: React.FC<{ value?: string }> = ({ value }) => {
  const navigate = useNavigate()
  const { setGrouping } = useInvestmentsTableStore()
  const onClick = useCallback(() => {
    if (
      value === GainLossDescriptionEnum.realizedGains ||
      value === GainLossDescriptionEnum.realizedLosses
    ) {
      navigate('../gains-and-losses')
    }
    if (
      value === GainLossDescriptionEnum.unrealizedGains ||
      value === GainLossDescriptionEnum.unrealizedLosses
    ) {
      setGrouping(['secid'])
      navigate('../investments')
    }
  }, [navigate, setGrouping, value])
  return (
    <div
      css={{
        color:
          value === GainLossDescriptionEnum.netRealized ||
          value === GainLossDescriptionEnum.netUnrealized
            ? 'black'
            : '#1D679D',
        cursor:
          value !== GainLossDescriptionEnum.netRealized &&
          value !== GainLossDescriptionEnum.netUnrealized
            ? 'pointer'
            : '',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
      onClick={onClick}
    >
      {value}
    </div>
  )
}

const columns: IColumn[] = [
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: {
      width: '100px',
      fontWeight: 'bold !important'
    },
    style: {
      color: '#1D679D'
    },
    width: '100px',
    format: (value) => <DescriptionCell value={value} />
  },
  {
    field: 'longTerm',
    align: 'right',
    headerName: 'Long Term',
    headerStyle: {
      width: '50px',
      fontWeight: 'bold !important'
    },
    width: '50px',
    format: (value) =>
      value != null ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  },
  {
    field: 'shortTerm',
    headerName: 'Short Term',
    align: 'right',
    headerStyle: {
      width: '50px',
      fontWeight: 'bold !important'
    },
    width: '50px',
    format: (value) =>
      value != null ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  },
  {
    field: 'total',
    headerName: 'Total',
    align: 'right',
    headerStyle: {
      width: '50px',
      fontWeight: 'bold !important'
    },
    width: '50px',
    format: (value) =>
      value != null ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  }
]

export default columns
