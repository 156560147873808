import { createColumnHelper } from '@tanstack/react-table'
import { IAccount } from 'api/account.types'
import { addDays, compareAsc, format } from 'date-fns'
import { FC, useCallback } from 'react'
import { FormattedNumber } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { USD } from 'shared/components/Formatting'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { AccountNumberCell } from '../../../components/shared/DetailTables/AccountNumberCell'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { HighlightSearchText } from '../../../components/shared/DetailTables/HighlightSearchText'
import { Icon } from '../../../features/Icons/Icon'
import { useDownloadAttachment } from '../../../hooks/useDownloadAttachment'
import { useLazyRetrieveCheckQuery } from '../../../store/rdot360AnalyticsApi'
import { IActivity } from '../../../store/types'
import { useActivityDetailUiState } from '../activityDetailsUiState'

const SymbolCusipCell: React.FC<{
  symbol?: string
  cusip?: string
  search?: string
}> = ({ symbol, cusip, search }) => {
  return (
    <div>
      <div
        css={(theme) => [
          detailTable.ellipsis,
          { color: theme.colors.tertiaryBlue1 }
        ]}
      >
        <HighlightSearchText text={symbol || ''} search={search} />
      </div>
      <div css={detailTable.ellipsis}>
        <HighlightSearchText text={cusip || ''} search={search} />
      </div>
    </div>
  )
}
const isPending = (activity: IActivity) =>
  (activity.dtsettle &&
    parseDateISOStringInLocalTimezone(activity.dtsettle) >
      new Date()) as boolean

const ActivityDateCell: React.FC<{
  dttrade?: string
  dtsettle?: string
}> = ({ dttrade, dtsettle }) => {
  const tradeDate = dttrade && parseDateISOStringInLocalTimezone(dttrade)
  const settleDate = dtsettle && parseDateISOStringInLocalTimezone(dtsettle)
  return (
    <div>
      {tradeDate && (
        <div css={detailTable.ellipsis}>{format(tradeDate, 'yyyy-MM-dd')}</div>
      )}
      {settleDate && settleDate > new Date() && (
        <div
          css={[detailTable.ellipsis, { fontSize: '10px' }]}
          title={`Settles on ${format(settleDate, 'yyyy-MM-dd')}`}
        >
          Settles on {format(settleDate, 'yyyy-MM-dd')}
        </div>
      )}
    </div>
  )
}

const useLazyRetrieveCheck = () => {
  const [triggerRetrieveCheck] = useLazyRetrieveCheckQuery()

  const trigger = useCallback(
    (accountList: string, dateRange: string, filterBy: string) => {
      const result = triggerRetrieveCheck(
        {
          AccountList: accountList,
          dateRange: dateRange,
          FilterBy: filterBy
        },
        false
      )
      result.unsubscribe()
      return result
    },
    [triggerRetrieveCheck]
  )

  return trigger
}

const DownloadAttachment: FC<{ data?: IActivity }> = ({ data }) => {
  const triggerRetrieveCheck = useLazyRetrieveCheck()
  const { downloadAttachment } = useDownloadAttachment()
  const { setIsDownloading } = useActivityDetailUiState()
  const handleDownloadClick = useCallback(async () => {
    setIsDownloading(true)
    const dateFormat = 'yyyy-MM-dd'
    const tradeDate =
      data?.dttrade && parseDateISOStringInLocalTimezone(data?.dttrade)

    const accountList = data?.acct || ''
    const dateRange =
      (tradeDate &&
        [
          'FromTo',
          format(tradeDate, dateFormat),
          format(addDays(tradeDate, 1), dateFormat)
        ].join(',')) ||
      ''
    const filterby = `CheckAmount,${data?.total},CheckNumber,${data?.checknumber}`
    const displayName = [
      accountList,
      tradeDate && format(tradeDate, dateFormat),
      Math.round(data?.total || 0)
    ]
      .filter(isNotNullOrUndefined)
      .join(' ')

    const { data: check } = await triggerRetrieveCheck(
      accountList,
      dateRange,
      filterby
    )

    const checkimage = check?.activities?.[0]?.checkimage

    setIsDownloading(false)
    if (!checkimage) {
      alert(
        'Check image is currently not available or not yet cleared for this transaction.'
      )
      return
    }

    downloadAttachment(checkimage, 'application/pdf', displayName)
  }, [data, downloadAttachment, setIsDownloading, triggerRetrieveCheck])

  return (
    <div
      title={'Click to download check image.'}
      css={{ display: 'flex', paddingLeft: '5px', justifyContent: 'center' }}
    >
      {data?.hasimage ? (
        <Icon
          type="Check"
          height={20}
          width={20}
          onClick={handleDownloadClick}
        />
      ) : (
        ''
      )}
    </div>
  )
}
const columnHelper = createColumnHelper<IActivity>()

export const constructColumns = (
  searchText?: string,
  accountLookup?: {
    [id: string]: IAccount | undefined
  }
) => [
  columnHelper.accessor((row) => row.dttrade, {
    id: 'tradeDate',
    cell: (props) => (
      <ActivityDateCell
        dtsettle={props.row.original.dtsettle}
        dttrade={props.row.original.dttrade}
      />
    ),
    header: (props) => (
      <HeaderContainer
        title="Activity Date"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 40,
    enableGlobalFilter: false,
    sortingFn: (a, b) =>
      isPending(a.original) && !isPending(b.original)
        ? 1
        : !isPending(a.original) && isPending(b.original)
        ? -1
        : compareAsc(
            parseDateISOStringInLocalTimezone(a.original.dttrade || ''),
            parseDateISOStringInLocalTimezone(b.original.dttrade || '')
          )
  }),
  columnHelper.accessor(
    (row) => {
      const { AccountNickname } = (row.acct && accountLookup?.[row.acct]) || {}

      return [AccountNickname, row.acct].filter(isNotNullOrUndefined).join('')
    },
    {
      id: 'accountNumber',
      cell: (props) => (
        <AccountNumberCell
          accountNumber={props.row.original.acct}
          search={searchText}
        />
      ),
      header: (props) => (
        <HeaderContainer
          title="Account"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 35
    }
  ),
  columnHelper.accessor((row) => row.trndesc, {
    id: 'description',
    cell: (props) => (
      <div css={detailTable.ellipsis} title={props.getValue()}>
        <HighlightSearchText
          text={props.getValue() || ''}
          search={searchText}
        />
      </div>
    ),
    header: (props) => (
      <HeaderContainer
        title="Description"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 125
  }),
  columnHelper.accessor((row) => row.trntype, {
    id: 'type',
    cell: (props) => (
      <div
        css={[detailTable.ellipsis, { display: 'flex' }]}
        title={props.getValue()}
      >
        <HighlightSearchText
          text={props.getValue() || ''}
          search={searchText}
        />
        <DownloadAttachment data={props.row.original} />
      </div>
    ),
    header: (props) => (
      <HeaderContainer title="Type" isSorted={props.column.getIsSorted()} />
    ),
    size: 40
  }),
  columnHelper.accessor((row) => `${row.secid} ${row.cusipnumber}`, {
    id: 'symbol/cusip',
    cell: (props) => (
      <SymbolCusipCell
        symbol={props.row.original.secid}
        cusip={props.row.original.cusipnumber}
        search={searchText}
      />
    ),
    header: (props) => (
      <HeaderContainer
        title="Symbol / CUSIP"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 40
  }),
  columnHelper.accessor((row) => row.units, {
    id: 'quantity',
    cell: (props) => (
      <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
        {props.getValue() != null ? (
          <FormattedNumber
            value={props.getValue() || 0}
            maximumFractionDigits={2}
            minimumFractionDigits={0}
          />
        ) : (
          '--'
        )}
      </div>
    ),
    header: (props) => (
      <HeaderContainer
        title="Quantity"
        rightAlign={true}
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 40,
    enableGlobalFilter: false
  }),
  columnHelper.accessor((row) => row.unitprice, {
    id: 'price',
    cell: (props) => (
      <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
        {props.getValue() != null ? (
          <USD
            value={props.getValue() || 0}
            fractionDigits={2}
            currencySign="standard"
          />
        ) : (
          '--'
        )}
      </div>
    ),
    header: (props) => (
      <HeaderContainer
        title="Price"
        rightAlign={true}
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 40,
    enableGlobalFilter: false
  }),
  columnHelper.accessor(
    (row) =>
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }).format(row.total || 0),
    {
      id: 'amount',
      cell: (props) => {
        const settleDate =
          props.row.original.dtsettle && new Date(props.row.original.dtsettle)
        return (
          <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
            <HighlightSearchText
              text={props.getValue() || ''}
              search={searchText}
            />
            {settleDate && settleDate > new Date() ? '*' : ''}
          </div>
        )
      },
      header: (props) => (
        <HeaderContainer
          title="Amount"
          rightAlign={true}
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40,
      sortingFn: (a, b) =>
        (a.original.total || 0) > (b.original.total || 0)
          ? -1
          : (a.original.total || 0) < (b.original.total || 0)
          ? 1
          : 0
    }
  )
]
