import styled from '@emotion/styled'
import {
  IContextualMenuItem,
  IContextualMenuProps,
  IconButton
} from '@fluentui/react'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../../features/Icons/Icon'

const DownArrowStyle = styled.div`
  flex-grow: 1;
  .ms-Button {
    float: right;
  }
  .ms-Button-flexContainer {
    .ms-Icon {
      margin: 0;
    }
  }
`
const DetailsNavigator: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const menuItems = useMemo(
    (): IContextualMenuItem[] => [
      {
        key: 'balance',
        text: 'Balances',
        onClick: () => {
          navigate('../balances')
        }
      },
      {
        key: 'investments',
        text: 'Investments',
        onClick: () => {
          navigate('../investments')
        }
      },
      {
        key: 'activity',
        text: 'Activity',
        onClick: () => {
          navigate('../activity')
        }
      },
      {
        key: 'income',
        text: 'Income',
        onClick: () => {
          navigate('../income')
        }
      },
      {
        key: 'gains-and-losses',
        text: 'Gain / Losses',
        onClick: () => {
          navigate('../gains-and-losses')
        }
      },
      {
        key: 'documents',
        text: 'Documents'
      },
      {
        key: 'collaboration',
        text: 'Collaboration'
      }
    ],
    [navigate]
  )

  const onBackButtonClick = useCallback(() => {
    navigate('..')
  }, [navigate])

  const filteredMenuItems = useMemo(
    () => menuItems.filter((item) => item.onClick),
    [menuItems]
  )
  const filteredMenuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      items: filteredMenuItems
    }),
    [filteredMenuItems]
  )

  const title = useMemo(
    () => menuItems.find((item) => location.pathname.includes(item.key))?.text,
    [location.pathname, menuItems]
  )

  return (
    <div css={{ display: 'flex' }}>
      <div css={{ display: 'flex', width: '222px', marginTop: '6px' }}>
        <div
          css={{
            cursor: 'pointer',
            marginRight: '18px',
            marginTop: '8px'
          }}
          onClick={onBackButtonClick}
        >
          <Icon type="ArrowLeft" height={12} width={12} />
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            css={{
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '12px',
              whiteSpace: 'nowrap'
            }}
          >
            Overview / {title}
          </div>
          <div
            css={{
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'uppercase',
              lineHeight: '20px',
              letterSpacing: '0.166667px',
              fontStyle: 'normal'
            }}
          >
            {title}
          </div>
        </div>
        <DownArrowStyle>
          <IconButton
            menuProps={filteredMenuProps}
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              color:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #13272B'
            }}
          />
        </DownArrowStyle>
      </div>
      <div>
        <hr
          css={{
            backgroundColor: 'gray',
            width: '1px',
            height: '101px',
            margin: '13px 0px 0px 21px'
          }}
        />
      </div>
    </div>
  )
}

export default DetailsNavigator
