import { Stack, Text } from '@fluentui/react'
import React from 'react'

export const SearchTotalDivider: React.FC<{
  title: string
  count: number
  total: number
  className: string
}> = ({ title, count, total, className }) => (
  <Stack
    horizontal={true}
    horizontalAlign="space-between"
    styles={{ root: { padding: '5px' } }}
    tokens={{ childrenGap: 5 }}
  >
    <Text variant="small">
      <b>{title}</b>
    </Text>
    <div className={className}>
      <div />
    </div>
    <Text variant="small">
      <b>
        {count} of {total}
      </b>
    </Text>
  </Stack>
)
