import { useMemo } from 'react'
import { GetChartEstimatedIncomeSummary } from '../../../store/types'

const useEstimatedIncomeChartData = (
  includeOptionPremiums: boolean,
  estimatedIncomeData?: GetChartEstimatedIncomeSummary
) => {
  const estimatedIncomeTotal = useMemo(() => {
    let data = 0

    if (estimatedIncomeData) {
      data = includeOptionPremiums
        ? estimatedIncomeData.dividend +
          estimatedIncomeData.interestReceived +
          estimatedIncomeData.optionPremiumsPaid +
          estimatedIncomeData.optionPremiumsReceived
        : estimatedIncomeData.dividend + estimatedIncomeData.interestReceived
    }

    return data
  }, [estimatedIncomeData, includeOptionPremiums])

  return { estimatedIncomeTotal }
}

export default useEstimatedIncomeChartData
