import { css, Theme, useTheme } from '@emotion/react'
import { ReactNode } from 'react'
import { USD } from 'shared/components/Formatting'
import useBalanceSummary from './useBlalanceSummary'

const valueFormatter = (value?: number, fractionDigits = 0): ReactNode => {
  return value ? (
    <>
      {value >= 0 ? '+' : ''}
      <USD
        value={value}
        fractionDigits={fractionDigits}
        currencySign="standard"
      />
    </>
  ) : (
    '--'
  )
}

const amountFormatter = (value?: number, fractionDigits = 0): ReactNode => {
  return value ? (
    <>
      <USD
        value={value}
        fractionDigits={fractionDigits}
        currencySign="standard"
      />
    </>
  ) : (
    <div>--</div>
  )
}

const useBalanceSummaryClassess = (theme: Theme) => ({
  detailTile: css({
    display: 'flex',
    backgroundColor: theme.colors.primaryWhite,
    boxShadow: '0px 0px 10px rgba(174, 174, 174, 0.1)',
    flexFlow: 'row wrap'
  }),
  tileTotalContainer: css({
    margin: 'auto 12px',
    textAlign: 'end'
  }),
  tileTotalLabel: css({
    fontSize: 18,
    fontWeight: '600',
    lineHeight: '20px',
    display: 'flex'
  }),
  totalChange: css({
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 1.5,
    display: 'flex',
    textAlign: 'end',
    float: 'right'
  }),
  amountChange: css({
    fontWeight: '600',
    width: 90,
    textAlign: 'right'
  }),
  blackColor: css({
    color: theme.colors.primaryBlack
  }),
  extraGreen1: css({
    color: theme.colors.extraGreen1
  }),
  hrLine: css({
    backgroundColor: theme.colors.tertiaryGray5,
    width: '1px',
    height: '98px',
    marginRight: 10
  }),
  balancesContainer1: css({
    marginTop: '8px',
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    display: 'flex',
    flexDirection: 'column'
  }),
  balanceLabel: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }),
  balancesValue: css({
    fontWeight: '600',
    width: 98,
    textAlign: 'right',
    color: 'black'
  }),
  balancesContainer2: css({
    fontSize: 12,
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '24px',
    marginTop: '6px',
    width: 266,
    '@media (max-width: 1330px)': {
      marginBottom: 10,
      marginLeft: 10
    }
  }),
  balancesChangeValue: css({
    fontWeight: '600',
    width: 98,
    textAlign: 'right'
  })
})

const BalanceSummaryDetails = () => {
  const {
    balanceSummaryCategoryAData,
    balanceSummaryCategoryBData,
    totalBalanceSummary
  } = useBalanceSummary()
  const balanceData: any = {}
  const theme = useTheme()
  const classess = useBalanceSummaryClassess(theme)

  return (
    <div css={classess.detailTile}>
      <div css={classess.tileTotalContainer}>
        <div css={classess.tileTotalLabel}>
          Total:
          <div css={{ marginLeft: 4 }}>
            {amountFormatter(totalBalanceSummary.amount)}
          </div>
        </div>
        <div>
          {totalBalanceSummary.change ? (
            <div
              css={[
                classess.totalChange,
                totalBalanceSummary.change > 0
                  ? classess.extraGreen1
                  : classess.blackColor
              ]}
            >
              {valueFormatter(totalBalanceSummary.change)}
            </div>
          ) : (
            '--'
          )}
        </div>
      </div>
      <div css={{ margin: 'auto' }}>
        <hr css={classess.hrLine} />
      </div>
      <div css={classess.balancesContainer1}>
        {balanceSummaryCategoryAData.map((item, index) => (
          <div key={index} css={classess.balanceLabel}>
            <span style={{ whiteSpace: 'nowrap' }}>{item.label}:</span>
            <div css={classess.balancesValue}>
              {amountFormatter(item.amount)}
            </div>
            <div
              css={[
                classess.amountChange,
                balanceData && item?.change && item?.change > 0
                  ? classess.extraGreen1
                  : classess.blackColor
              ]}
            >
              {valueFormatter(item.change)}
            </div>
          </div>
        ))}
      </div>
      <div css={{ margin: 'auto' }}>
        <hr
          css={[
            classess.hrLine,
            {
              marginLeft: 10
            }
          ]}
        />
      </div>
      <div css={classess.balancesContainer2}>
        {balanceSummaryCategoryBData.map((item: any, index: number) => (
          <div key={index} css={classess.balanceLabel}>
            <span style={{ whiteSpace: 'nowrap' }}>{item.label}:</span>
            <div css={classess.balancesChangeValue}>
              {amountFormatter(item.amount)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BalanceSummaryDetails
