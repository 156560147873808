export type CustodianType =
  | 'Rockefeller Accounts'
  | 'External Accounts'
  | 'Other Assets And Liabilities'

export const custodianTypes: CustodianType[] = [
  'Rockefeller Accounts',
  'External Accounts',
  'Other Assets And Liabilities'
]
