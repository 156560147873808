import { sagas as discretionDistributionSagas } from '../features/DiscretionaryDistribution/store/sagas'
import { sagas as newdistributionSagas } from '../features/NewDistribution/store/sagas'
import { sagas as accountsSagas } from '../features/TrustDetail/modules/Accounts/store/sagas'
import { sagas as BeneficiarySagas } from '../features/TrustDetail/modules/Beneficiaries/store/sagas'
import { sagas as dashboardTrustsAumSummarySagas } from '../features/TrustDetail/modules/DashBoard/AUM/store/sagas'
import { sagas as dashboardClientSummary } from '../features/TrustDetail/modules/DashBoard/Clients/store/sagas'
import { sagas as dashboardSagas } from '../features/TrustDetail/modules/DashBoard/store/sagas'
import { sagas as grantorSagas } from '../features/TrustDetail/modules/Grantors/store/sagas'
import { sagas as trusteesSagas } from '../features/TrustDetail/modules/Trustee/store/sagas'
import { sagas as trustInfoSagas } from '../features/TrustDetail/modules/TrustInfo/store/sagas'
export const sagas = [
  ...discretionDistributionSagas,
  ...newdistributionSagas,
  ...trustInfoSagas,
  ...trusteesSagas,
  ...grantorSagas,
  ...BeneficiarySagas,
  ...accountsSagas,
  ...dashboardSagas,
  ...dashboardClientSummary,
  ...dashboardTrustsAumSummarySagas
]
