import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useDownloadAttachment } from '../../../hooks/useDownloadAttachment'
import { IRetrieveDocumentListDocumentItem } from '../../../store/documentsApi/IRetrieveDocumentListResponse'
import { useLazyRetrieveDocumentQueryForSelectedAccounts } from '../../../store/rdot360Context/useRdot360DocumentsContext'
import { documentFilterActions } from '../DocumentFilter/store'

export const DownloadAttachment: React.FC<{
  data: IRetrieveDocumentListDocumentItem
  virtualTable: string
}> = ({ data, virtualTable }) => {
  const dispatch = useDispatch()
  const { downloadAttachment } = useDownloadAttachment()
  const [triggerRetrieveDocument] =
    useLazyRetrieveDocumentQueryForSelectedAccounts()
  const { setIsDownloading } = documentFilterActions
  const handleDownloadClick = useCallback(async () => {
    dispatch(setIsDownloading(true))
    const { data: download } = await triggerRetrieveDocument({
      VirtualTable: virtualTable,
      PrimaryType: data.PrimaryType,
      PrimaryValue: data.PrimaryValue,
      ObjectId: data.ObjectId
    })
    dispatch(setIsDownloading(false))
    downloadAttachment(
      download?.ContentStream || '',
      data.ContentStreamType,
      data.DisplayName
    )
  }, [
    virtualTable,
    data,
    dispatch,
    downloadAttachment,
    setIsDownloading,
    triggerRetrieveDocument
  ])

  return (
    <div
      css={{
        color: '#1D679D',
        cursor: 'pointer'
      }}
      onClick={handleDownloadClick}
    >
      {data.DisplayName}
    </div>
  )
}
