import { combineReducers } from 'redux'
import { preferenceUserReducer, preferenceUserSagas } from './preferenceUser'
import { rdotUserReducer, rdotUserSagas } from './rdotUser'

export const userReducer = combineReducers({
  rdot: rdotUserReducer,
  preference: preferenceUserReducer
})

export const userSagas = [...rdotUserSagas, ...preferenceUserSagas]
