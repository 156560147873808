import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const PhilanthropyContainer = lazy(() => import('./PhilanthropyContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/philanthropy/*',
    private: true,
    component: PhilanthropyContainer
  },
  name: 'Philanthropy'
}

export default moduleDefinition
export { default as philanthropySagas } from './store/sagas'
export * from './store/reducers'
