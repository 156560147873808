import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  ILaunchClientOnlineActionPayload,
  navigationActions
} from 'store/ui/actions'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IRelatedLogin } from '../../api/profile'
import { AppState } from '../../store'

const OPEN_PANEL = '@feature/@relatedParties/OPEN_PANEL'
const CLOSE_PANEL = '@feature/@relatedParties/CLOSE_PANEL'
const REQUEST = '@feature/@relatedParties/REQUEST'
const SUCCESS = '@feature/@relatedParties/SUCCESS'
const FAILURE = '@feature/@relatedParties/FAILURE'

export interface IRelatedPartyDataRequestActionPayload {
  displayName?: string
  idType: 'account' | 'wealthscapeId'
  id: string
  launchParameters?: Record<string, string>
}

export const relatedPartyPanelActions = {
  openPanel: createAction(OPEN_PANEL)<IRelatedPartyDataRequestActionPayload>(),
  closePanel: createAction(CLOSE_PANEL)()
}

export const relatedPartyDataActions = {
  request: createAction(REQUEST)<IRelatedPartyDataRequestActionPayload>(),
  success: createAction(SUCCESS)<IRelatedLogin[]>(),
  failure: createAction(FAILURE)<Error>()
}

export type ClientMenuActionTypes =
  | ActionType<typeof relatedPartyPanelActions>
  | ActionType<typeof relatedPartyDataActions>

export interface IRelatedPartyPanelState {
  isPanelOpen: boolean
  isLoading: boolean
  relatedParties: IRelatedLogin[]
  currentRequest?: IRelatedPartyDataRequestActionPayload
  error?: Error
}

const initialState: IRelatedPartyPanelState = {
  isPanelOpen: false,
  isLoading: false,
  relatedParties: []
}

export const relatedPartyPanelReducer = createReducer<
  IRelatedPartyPanelState,
  ClientMenuActionTypes
>(initialState)
  .handleAction(relatedPartyDataActions.request, (state, action) => ({
    ...state,
    isLoading: true,
    currentRequest: action.payload,
    relatedParties: [],
    error: undefined
  }))
  .handleAction(relatedPartyDataActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    relatedParties: action.payload
  }))
  .handleAction(relatedPartyDataActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    relatedParties: [],
    error: action.payload
  }))
  .handleAction(relatedPartyPanelActions.openPanel, (state) => ({
    ...state,
    isPanelOpen: true
  }))
  .handleAction(relatedPartyPanelActions.closePanel, (state) => ({
    ...state,
    isPanelOpen: false
  }))

export const getRelatedParties = (state: AppState) =>
  state.features.relatedPartyPanel.relatedParties

export const getIsRelatedPartiesLoading = (state: AppState) =>
  state.features.relatedPartyPanel.isLoading

export const getIsRelatedPartyPanelOpen = (state: AppState) =>
  state.features.relatedPartyPanel.isPanelOpen

export const getCurrentRelatedPartyRequest = (state: AppState) =>
  state.features.relatedPartyPanel.currentRequest

export const useRelatedPartyNavigation = () => {
  const dispatch = useDispatch()
  const launchClientOnline = useCallback(
    (payload: ILaunchClientOnlineActionPayload) => {
      if (!payload.wealthscapeId) {
        return
      }
      dispatch(navigationActions.launchClientOnline(payload))
    },
    [dispatch]
  )

  const openRelatedPartyPanel = useCallback(
    (request: IRelatedPartyDataRequestActionPayload) => {
      dispatch(relatedPartyPanelActions.openPanel(request))
    },
    [dispatch]
  )

  return {
    launchClientOnline,
    openRelatedPartyPanel
  }
}
