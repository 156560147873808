import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { PaymentListResponse } from '../store/types'

export const fetchPaymentList = (
  params: {
    role: string
    fromDate?: string
    toDate?: string
    useTrnDate?: string
  },
  options: IApiOptions
) => {
  let url = `${options.apiRoot}/payment/api/payment/GetPaymentList?role=${params.role}`
  if (params?.fromDate) {
    url =
      url +
      `&useTrnDate=${params.useTrnDate || 'N'}&useDateRange=Y&fromDate=${
        params?.fromDate
      }&toDate=${params?.toDate}`
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<PaymentListResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
