import axios from 'axios'
import { IApiOptions } from '../shared/contracts/IApiOptions'

export interface IDonor {
  annualMinimum?: number
  donationsYtd?: number
  paidDonations?: number
  plannedDonations?: number
  numSignatures?: number
  donorType?: string
  founded?: string
  fullName?: string
  grantMakers?: IGrantMaker[]
  grantManagers?: IGrantManager[]
  grants?: IPhilanthropyGrant[]
  id?: string
  is501c3?: boolean
  partyid?: string
  pmtHubOrgId?: string
  profile?: string
  purpose?: string
  rcmEntityNumber?: string
  docusignManager?: string
  isNfsClient?: boolean
  shortName?: string
  trustees?: IGrantTrustee[]
}

export interface IGrantManager {
  email: string
  name: string
}

export interface IGrantTrustee {
  email: string
  name: string
}

export interface IGrantMaker {
  allocation: number
  donationsYtd: number
  email: string
  name: string
  partyid: string
}

export interface IGetDonorsResponse {
  count: number
  donors: IDonor[]
}

export interface IGetTrusteesResponse {
  count: number
  data: IGrantTrustee[]
}

export interface IPostDonorResponse {
  status: number
  data: IDonor[]
}

export const getDonors = (
  search: string,
  options: IApiOptions
): Promise<IGetDonorsResponse> => {
  const url = `${options.apiRoot}/grantmaking/donor?grantManager=${search}`
  return axios
    .get<IGetDonorsResponse>(`${url}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getDonorTrustees = (
  search: string,
  options: IApiOptions
): Promise<IGetTrusteesResponse> => {
  const url = `${options.apiRoot}/grantmaking/trustees?client=${search}`
  return axios
    .get<IGetTrusteesResponse>(`${url}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const postDonor = (
  donor: IDonor,
  options: IApiOptions
): Promise<IPostDonorResponse> => {
  const url = `${options.apiRoot}/grantmaking/donor`
  return axios
    .post<IPostDonorResponse>(`${url}`, donor, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export interface IPhilanthropyGrant {
  amount?: number
  charity?: IPhilanthropyCharity
  createdDate?: string
  disbursements?: IPhilanthropyDisbursement[]
  donorName?: string
  envelopeId?: string
  grantMakerEmail?: string
  grantMakerName?: string
  grantMakerPartyId?: string
  grantManagers?: IGrantManager[]
  id?: string
  isVendorPmtHubSetup?: boolean
  isVendorPmtInstructions?: boolean
  lastUpdate?: string
  partyid?: string
  pmtHubBillId?: string
  pmtHubOrgId?: string
  pmtHubVendorId?: string
  purpose?: string
  rcmEntityNumber?: string
  recipientEin?: string
  recipientName?: string
  state?: string
  trusteeApprovals?: IPhilanthropyTrusteeApproval[]
  urgent?: boolean
  verificationTime?: string
  verificationTrigger?: number
}

export interface IPhilanthropyDisbursement {
  date: string
  method: string
}

export interface IPhilanthropyTrusteeApproval {
  timeStamp: string
  email: string
  state: string
  name: string
}

export interface IPhilanthropyCharity {
  contact_phone: string
  subsection_code: string
  parent_orgs: string
  profile_level: string
  form_type: string
  address_line_1: string
  msa: string
  state: string
  also_known_as: string
  foundation_code: string
  zip: string
  bmf_assets: string
  organization_name: string
  contact_email: string
  ntee_code: string
  mission: string
  website_url: string
  public_report: string
  bridge_id: string
  city: string
  contact_name: string
  form990_total_expenses: string
  bmf_gross_receipts: string
  county: string
  ein: string
  logo_url: string
  lat_long: string
  contact_title: string
  organization_id: string
  address_line_2: string
  number_of_employees: string
  form990_total_assets: string
  properties: string
  form990_total_revenue: string
}

export interface IGetGrantsResponse {
  count: number
  grandTotal: number
  donationsYtd: number
  grants: IPhilanthropyGrant[]
}

export interface IPostGrantResponse {
  status: number
  data: IDonor[]
}

export const getGrants = (
  search: string,
  options: IApiOptions
): Promise<IGetGrantsResponse> => {
  const url = `${options.apiRoot}/grantmaking/grant?grantManager=${search}`
  return axios
    .get<IGetGrantsResponse>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const postGrant = (
  grant: IPhilanthropyGrant,
  options: IApiOptions
): Promise<IPostGrantResponse> => {
  const url = `${options.apiRoot}/grantmaking/grant`
  return axios
    .post<IPostGrantResponse>(`${url}`, grant, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export interface IProfileUser {
  profileId?: string
  partyid?: string
  wsportaluserid?: string
  firstname?: string
  lastname?: string
  fullname?: string
  primaryemail?: string
  loginid?: string
  accounts?: IProfileAccount[]
}

export interface IProfileAccount {
  number?: string
  key?: string
  accountregistration?: string
  regtypecode?: string
  repcode?: string
  nickname?: string
  role?: string
  stakeholder?: string
  managedaccount?: string
  eDelivery?: string
  displaynickname?: string
  accountssourcetypecd?: string
  accountclassification?: string
  isiraccount?: boolean
  ismargin?: boolean
}

export const getGrantMakers = (
  name: string,
  options: IApiOptions
): Promise<IProfileUser[]> => {
  const url = `${options.apiRoot}/profileapi/api/ClientInformation/GetClientInfo?name=${name}`
  return axios
    .get<IProfileUser[]>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}
