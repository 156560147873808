import { Icon, Stack, Text } from '@fluentui/react'
import React, { memo } from 'react'
import { IAccount } from '../../../api/account.types'
import { USD } from '../../../shared/components/Formatting'
import { ConnectedMaskedHighlight, Highlight } from './Highlight'

export interface IAccountSearchResultsProps {
  className?: string
  accounts: IAccount[]
  onAccountClick: (account: IAccount) => void
  query?: string
}

export const AccountSearchResults: React.FC<IAccountSearchResultsProps> = memo(
  ({ className, accounts, onAccountClick, query }) => {
    return (
      <table className={className}>
        <thead>
          <tr>
            <td />
            <td>
              <Text variant="small">
                <Stack horizontal={true} horizontalAlign="space-between">
                  <b>
                    ENTITY NAME / <br /> SHORT NAME
                  </b>
                  <b>REP</b>
                </Stack>
              </Text>
            </td>
            <td>
              <Text variant="small">
                <b>AUS</b>
              </Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {accounts.map((x) => {
            const onClick = () => onAccountClick(x)
            const {
              id,
              CustodyAccount,
              LegalEntityName,
              registrationtype,
              AccountNickname,
              Shortname,
              ClientAdvisorID,
              gfoCustodyAccount
            } = x
            return (
              <tr key={id} onClick={onClick}>
                <td style={{ verticalAlign: 'top' }}>
                  <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                    <Stack.Item verticalFill={true} align="center">
                      <Icon iconName="BankSolid" />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Stack
                        horizontal={true}
                        tokens={{ childrenGap: 3 }}
                        verticalAlign="center"
                      >
                        <Text nowrap={true} block={true} variant="medium">
                          <ConnectedMaskedHighlight
                            text={CustodyAccount || ''}
                            highlight={query}
                          />
                        </Text>
                        {registrationtype && (
                          <Text nowrap={true} block={true} variant="small">
                            ({registrationtype})
                          </Text>
                        )}
                      </Stack>
                      {AccountNickname && (
                        <Text nowrap={true} block={true} variant="small">
                          <Highlight
                            text={AccountNickname || ''}
                            highlight={query}
                          />
                        </Text>
                      )}
                      {gfoCustodyAccount && (
                        <Text nowrap={true} block={true} variant="small">
                          <Highlight
                            text={gfoCustodyAccount || ''}
                            highlight={query}
                          />
                        </Text>
                      )}
                    </Stack.Item>
                  </Stack>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                  >
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Text nowrap={true} block={true}>
                        <Highlight
                          text={LegalEntityName || ''}
                          highlight={query}
                        />
                      </Text>
                      <Text nowrap={true} block={true} variant="small">
                        <Highlight text={Shortname || ''} highlight={query} />
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text nowrap={true} block={true} variant="small">
                        {ClientAdvisorID}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Text variant="small">
                    <USD value={x?.AccountKPIs?.AccountTotal || 0} />
                  </Text>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)
