import { FC, useCallback, useMemo, useState } from 'react'
import {
  Dropdown,
  IndeterminateProgressIndicator,
  ISelectableOption
} from '../../../components/shared'
import { IDropdownOption } from '../../../components/shared/Dropdown/Dropdown'
import { SnackBar } from '../../../components/shared/Snackbar'
import TileHeading, {
  BaseHeaderProps,
  ViewType
} from '../../../components/TileHeading'
import { useRdot360Preferences } from '../../../hooks'
import {
  useGetActivitySummaryGroupQuery,
  useGetActivitySummaryQuery
} from '../../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../../store/rdot360Context'
import { ActivitySummaryChart } from './ActivitySummaryChart'
import { ActivitySummaryTable } from './ActivitySummaryTable'
import { Last10Activities } from './Last10Activities'

const viewTypePreference = 'activityTile'
type ActivityTableType = 'summary' | 'last10'

const AccountActivity: FC<BaseHeaderProps> = ({ onExpand }) => {
  const { getPreference, setPreference } = useRdot360Preferences<{
    [key: string]: ViewType
  }>(viewTypePreference, ViewType.table)
  const [activityTableType, setActivityTableType] =
    useState<ActivityTableType>('summary')

  const viewType = getPreference(viewTypePreference)
  const handleViewTypeChange = useCallback(
    (value: ViewType) => {
      setPreference({ [viewTypePreference]: value })
      if (value === ViewType.graph) {
        setActivityTableType('summary')
      }
    },
    [setPreference]
  )
  const filterOptions: ISelectableOption[] = [
    { key: '10', text: 'Last 10 Days' },
    { key: '30', text: 'Last 30 Days' }
  ]
  const [selectedOption, setSelectedOption] = useState<ISelectableOption>(
    filterOptions?.[0]
  )

  const onFilterChange = useCallback((ev: any, option?: IDropdownOption) => {
    if (!option) {
      return
    }
    setSelectedOption(option)
  }, [])

  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const {
    data: summaryData,
    isFetching: isSummaryFetching,
    error: summaryError,
    isUninitialized: isSummaryUninitialized
  } = useGetActivitySummaryGroupQuery(
    {
      contextAccounts: apiContextAccounts,
      range: selectedOption.key as string
    },
    {
      skip:
        !apiContextAccounts ||
        !apiContextAccounts.length ||
        (activityTableType !== 'summary' && viewType !== ViewType.graph)
    }
  )
  const {
    data: last10Data,
    isFetching: isLast10Fetching,
    error: last10Error,
    isUninitialized: last10Uninitialized
  } = useGetActivitySummaryQuery(apiContextAccounts, {
    skip:
      !apiContextAccounts ||
      !apiContextAccounts.length ||
      activityTableType !== 'last10'
  })

  const isError = useMemo(
    () => !!summaryError || !!last10Error,
    [last10Error, summaryError]
  )
  const errorMessage =
    (summaryError as Error)?.message ||
    (last10Error as Error)?.message ||
    'An unknown Error Occurred'

  return (
    <>
      <div
        css={{
          position: 'relative',
          flexDirection: 'column',
          display: 'flex',
          height: '100%'
        }}
      >
        <div
          css={{
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            fontWeight: 500,
            flex: '0 0 32px'
          }}
        >
          <TileHeading
            viewType={viewType}
            onViewtypeChange={handleViewTypeChange}
            onExpand={onExpand}
            graphIconType="chart"
          >
            Activity
          </TileHeading>
        </div>

        {isError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar message={errorMessage} type="Failure" />
          </div>
        )}

        <div
          css={{
            paddingBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {viewType === ViewType.table ? (
            <div>
              <button
                css={
                  activityTableType === 'summary'
                    ? {
                        padding: '5px 0 5px 0',
                        width: '80px',
                        border: 'none',
                        backgroundColor: 'white',
                        borderBottomStyle: 'solid',
                        cursor: 'pointer'
                      }
                    : {
                        padding: '5px 0 5px 0',
                        width: '80px',
                        border: 'none',
                        backgroundColor: 'white',
                        borderBottomStyle: 'none',
                        cursor: 'pointer'
                      }
                }
                onClick={() => setActivityTableType('summary')}
              >
                Summary
              </button>
              <button
                css={
                  activityTableType === 'last10'
                    ? {
                        padding: '5px 0 5px 0',
                        width: '125px',
                        border: 'none',
                        backgroundColor: 'white',
                        borderBottomStyle: 'solid',
                        cursor: 'pointer'
                      }
                    : {
                        padding: '5px 0 5px 0',
                        width: '125px',
                        border: 'none',
                        backgroundColor: 'white',
                        borderBottomStyle: 'none',
                        cursor: 'pointer'
                      }
                }
                onClick={() => setActivityTableType('last10')}
              >
                Last 10 Activities
              </button>
            </div>
          ) : (
            <div />
          )}
          <div>
            {activityTableType === 'summary' ? (
              <Dropdown
                options={filterOptions}
                defaultSelectedOption={selectedOption}
                onChange={onFilterChange}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {viewType === ViewType.graph && (
          <div className="test" css={{ flexGrow: 1 }}>
            <ActivitySummaryChart
              data={summaryData}
              dateRange={selectedOption.text}
              days={selectedOption.key as number}
            />
          </div>
        )}
        {viewType === ViewType.table && (
          <div css={{ flexGrow: 1, minHeight: 0, maxHeight: '250px' }}>
            {activityTableType === 'summary' ? (
              <ActivitySummaryTable
                data={summaryData}
                dateRange={selectedOption.text}
                isLoading={isSummaryFetching}
                isUninitialized={isSummaryUninitialized}
              />
            ) : (
              <Last10Activities
                data={last10Data}
                isUninitialized={last10Uninitialized}
                isLoading={isLast10Fetching}
              />
            )}
          </div>
        )}
      </div>

      {(isSummaryFetching || isLast10Fetching) && (
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}

export default AccountActivity
