import {
  CellContext,
  HeaderContext,
  Row,
  createColumnHelper
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { AccountNumberCell } from './../../../../../Rdot360/components/shared/DetailTables/AccountNumberCell'

const columnHelper = createColumnHelper<any>()

const numberSorting = (a: any, b: any, columnId: string) => {
  const sortValue = a.getValue(columnId) > b.getValue(columnId) ? 1 : -1

  return sortValue
}

const dateSorting = (a: any, b: any, columnId: string) => {
  const sortValue =
    new Date(a.getValue(columnId)) > new Date(b.getValue(columnId)) ? 1 : -1

  return sortValue
}

const getDefaultValue = (value: any) => (value != null ? '--' : '')

const renderNumberField = (props: CellContext<any, any>) => {
  return (
    <div css={{ textAlign: 'right' }}>
      {props.getValue() ? (
        <FormattedNumber
          value={props.getValue()}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
          currencySign="accounting"
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const renderAmountField = (props: CellContext<any, any>) => {
  return (
    <div>
      {props.getValue() ? (
        <USD
          value={props.getValue()}
          fractionDigits={2}
          currencySign="standard"
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const renderDateField = (props: CellContext<any, any>) => {
  return (
    <div>
      {props.getValue() ? (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={props.getValue()}
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const SumFooter: React.FC<HeaderContext<any, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return (
    <div css={{ textAlign: 'right', fontWeight: 'bold' }}>
      {column.id === 'quantity' ? (
        <FormattedNumber
          value={total}
          currencySign="accounting"
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
      ) : (
        <USD value={total} fractionDigits={2} currencySign={'standard'} />
      )}
    </div>
  )
}

const renderExpnadCollapseIcon = (row: Row<any>) => {
  const icon = row.subRows.length ? (
    <Icon type="Add" width={14} height={14} color="black" />
  ) : (
    ''
  )

  return (
    <div css={{ width: 14, height: 14, margin: '0 12px' }}>
      {row.getIsExpanded() ? (
        <Icon type="Subtract" width={14} height={14} color="black" />
      ) : (
        icon
      )}
    </div>
  )
}

const getColumns = (searchText: string) => [
  columnHelper.accessor((row) => row.description, {
    id: 'description',
    header: 'Symbol / Description',
    enableGlobalFilter: true,
    cell: (props: any) => {
      const { row } = props
      return props.getValue() ? (
        <div
          {...{
            onClick: row.getToggleExpandedHandler()
          }}
          css={{
            cursor: row.subRows.length ? 'pointer' : '',
            display: 'flex'
          }}
        >
          {renderExpnadCollapseIcon(row)}
          <div
            css={{
              marginLeft: 8,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0
            }}
          >
            <div>
              <HighlightSearchText
                text={row.original.secName}
                search={searchText}
              />
            </div>

            <div
              css={{
                color: '#676767',
                fontWeight: 400,
                lineHeight: '1.5',
                fontSize: 12,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              <HighlightSearchText
                text={props.getValue()}
                search={searchText}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )
    },
    footer: 'Total',
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.account, {
    id: 'account',
    header: 'Account',
    enableGlobalFilter: true,
    cell: (props) => {
      return (
        <div>
          {props.getValue() ? (
            <AccountNumberCell
              accountNumber={props.getValue()}
              search={searchText}
            />
          ) : (
            getDefaultValue(props.getValue())
          )}
        </div>
      )
    },
    minSize: 50,
    size: 250
  }),
  columnHelper.accessor((row) => row.quantity, {
    id: 'quantity',
    header: 'Quantity',
    sortingFn: numberSorting,
    cell: renderNumberField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.acquisitionDate, {
    id: 'acquisitionDate',
    header: 'Acquisition Date',
    sortingFn: dateSorting,
    cell: renderDateField,
    enableGlobalFilter: false,
    minSize: 50,
    size: 130
  }),
  columnHelper.accessor((row) => row.acquisitionPrice, {
    id: 'acquisitionPrice',
    header: 'Acquisition Price',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.acquisitionCost, {
    id: 'acquisitionCost',
    header: 'Acquisition Cost',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.liquidationDate, {
    id: 'liquidationDate',
    header: 'Liquidation Date',
    sortingFn: dateSorting,
    cell: renderDateField,
    enableGlobalFilter: false,
    minSize: 50,
    size: 130
  }),
  columnHelper.accessor((row) => row.liquidationAmount, {
    id: 'liquidationAmount',
    header: 'Net Proceeds',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.shortTermGainLoss, {
    id: 'shortTermGainLoss',
    header: 'Short-Term Gain/Loss',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.longTermGainLoss, {
    id: 'longTermGainLoss',
    header: 'Long-Term Gain/Loss',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  }),
  columnHelper.accessor((row) => row.totalGainLoss, {
    id: 'totalGainLoss',
    header: 'Total Gain/Loss',
    sortingFn: numberSorting,
    cell: renderAmountField,
    enableGlobalFilter: false,
    footer: SumFooter,
    minSize: 50,
    size: 150
  })
]

export default getColumns
