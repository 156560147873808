import {
  IColumn,
  SelectionMode,
  Selection,
  Text,
  Stack,
  IObjectWithKey
} from '@fluentui/react'
import { ELIGIBLE_ACCOUNT_TYPES } from 'features/MarginRateAdjustment/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IAccount } from '../../api/account.types'
import { IHousehold } from '../../api/household.types'
import { DataTable } from '../../shared/components/DataTable'
import { TextCell } from '../Lists/core/components/TextCell'
import { AccountNumberCell } from '../Lists/shared/AccountNumberCell'
import { marginRateMoreAccountsActions } from './store/householdAccountsDetails'

const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

const canSelectAccount = (item: IAccount) =>
  !!item.registrationtype &&
  ELIGIBLE_ACCOUNT_TYPES.includes(item.registrationtype) &&
  item.CustodianName === 'nfs'

export const MarginRateAccountList: React.FC<{
  accounts?: (IAccount & IObjectWithKey)[]
  loading?: boolean
}> = ({ accounts, loading }) => {
  const [selectedItems, setSelectedItems] = useState<IAccount[] | undefined>()
  const dispatch = useDispatch()
  const selection = useMemo(
    () =>
      new Selection({
        canSelectItem: canSelectAccount,
        onSelectionChanged: () => {
          const items: IAccount[] = selection.getSelection() as any
          setSelectedItems(items)
        },
        items: accounts,
        getKey: (item) => item.id || ''
      }),
    [accounts]
  )

  useEffect(() => {
    dispatch(
      marginRateMoreAccountsActions.setAllSelectedAccounts(selectedItems)
    )
  }, [selectedItems, dispatch])

  const columns: IColumn[] = useMemo(
    () => [
      {
        ...defaultColumn,
        name: 'Account',
        key: 'account',
        maxWidth: 160,
        minWidth: 140,
        onRender: (account: IAccount) => (
          <AccountNumberCell account={account} menu={null} />
        )
      },
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        maxWidth: 160,
        minWidth: 140,
        onRender: ({ LegalEntityName }: IAccount) => (
          <TextCell>{LegalEntityName}</TextCell>
        )
      },
      {
        ...defaultColumn,
        key: 'marginAgreement',
        maxWidth: 120,
        minWidth: 90,
        name: 'Margin Agreement / Rate',
        onRender: ({ marginAgreement, marginInterestRate }: IAccount) => (
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 5 }}
            verticalAlign="center"
          >
            <Text>{marginAgreement}</Text>
            {(marginInterestRate || marginInterestRate === 0) && (
              <Text
                variant="small"
                nowrap={true}
                styles={{ root: { fontWeight: 'bold' } }}
              >
                ({marginInterestRate.toFixed(2)}%)
              </Text>
            )}
          </Stack>
        )
      },
      {
        ...defaultColumn,
        key: 'employeeIndicator',
        maxWidth: 30,
        name: 'Employee Indicator',
        onRender: ({ employeeCode }: IHousehold) => (
          <TextCell>{employeeCode}</TextCell>
        )
      }
    ],
    []
  )
  return (
    <DataTable
      columns={columns}
      items={accounts}
      enableShimmer={loading}
      shimmerLines={5}
      selectionMode={SelectionMode.multiple}
      selection={selection}
    />
  )
}
