import { Link } from '@fluentui/react'
import {
  CellContext,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { IAccount } from 'api/account.types'
import { keyBy, sumBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { taxStatusLookup } from '../../shared/taxStatus'
import { useRdot360AccountContext } from '../../store/rdot360Context/useRdot360AccountContext'
import {
  EnhancedBalanceDetailResponseValue,
  useRdot360BalancesContext
} from '../../store/rdot360Context/useRdot360BalancesContext'
import { AccountSelectorAccount } from './AccountSelectorAccount'
import { AccountSelectorGroup } from './AccountSelectorGroup'
import {
  accountSelectorColumnNames,
  useAccountSelectorTableStore
} from './store'
import { accountSelectorStyles } from './styles'
import { CustodianType, custodianTypes } from './types'

interface IAccountSelectorAccount {
  id: string
  custodianType: CustodianType
  placeholder?: boolean
  account?: IAccount
  balance?: EnhancedBalanceDetailResponseValue
}

const empty: IAccountSelectorAccount[] = []

const GroupCell: React.FC<CellContext<IAccountSelectorAccount, unknown>> = ({
  row
}) => {
  const title =
    row.groupingColumnId && row.getValue<string>(row.groupingColumnId)
  const { selectedIdsLookup, setSelectedIds } = useAccountSelectorTableStore()
  const selectedSubRows =
    row?.subRows?.filter(
      (x) => selectedIdsLookup[x.id] && !x.original.placeholder
    ) || 0

  const subRows = row?.subRows?.filter((x) => !x.original.placeholder)
  const isAllSelected =
    !!selectedSubRows.length && selectedSubRows.length === subRows.length
  const isSomeSelected =
    !isAllSelected &&
    !!selectedSubRows.length &&
    selectedSubRows.length !== subRows.length

  const value = sumBy(
    selectedSubRows,
    ({ getValue }) => getValue<number>(accountSelectorColumnNames.value) || 0
  )

  const change = sumBy(
    selectedSubRows,
    ({ getValue }) =>
      getValue<number>(accountSelectorColumnNames.changeValue) || 0
  )

  const onRowSelectionChange = useCallback(() => {
    const selection = subRows.reduce(
      (a, x) => ({ ...a, [x.id]: !isAllSelected }),
      {} as Record<string, boolean>
    )

    const newSelection = Object.entries({
      ...selectedIdsLookup,
      ...selection
    })
      .filter(([, value]) => value)
      .map(([key]) => key)

    setSelectedIds(newSelection)
  }, [isAllSelected, selectedIdsLookup, setSelectedIds, subRows])

  if (!row.groupingColumnId) {
    return <></>
  }

  if (
    row.groupingColumnId === accountSelectorColumnNames.custodianType &&
    title !== 'Rockefeller Accounts'
  ) {
    return (
      <div>
        <div
          css={[
            accountSelectorStyles.flexColumn,
            {
              justifyContent: 'space-between',
              grow: 1
            }
          ]}
        >
          <div css={[accountSelectorStyles.groupTitle]}>{title}</div>
          <div
            css={[accountSelectorStyles.comingSoon, { alignSelf: 'center' }]}
          >
            COMING SOON
          </div>
        </div>
      </div>
    )
  }

  return (
    <AccountSelectorGroup
      title={title}
      checked={isAllSelected}
      indeterminate={isSomeSelected}
      onChange={onRowSelectionChange}
      selectedSubrowsCount={selectedSubRows.length}
      subrowsCount={subRows?.length || 0}
      groupBalance={value}
      groupBalanceChange={change}
      isExpanded={row.getIsExpanded()}
      toggleExpanded={row.getToggleExpandedHandler()}
    />
  )
}

const AccountCell: React.FC<CellContext<IAccountSelectorAccount, unknown>> = ({
  row
}) => {
  const { account, balance } = row.original
  const { mask } = useMasking()
  const nickname = mask(
    account?.AccountNickname || account?.Shortname,
    'nickname'
  )
  const accountNumber = mask(account?.CustodyAccount, 'account')
  const value = balance?.netWorth
  const change = balance?.netWorthChange
  const { selectedIdsLookup, setSelectedIds } = useAccountSelectorTableStore()
  const checked = !!selectedIdsLookup[row.id]
  const onRowSelectionChange = useCallback(() => {
    const newSelection = Object.entries({
      ...selectedIdsLookup,
      [row.id]: !checked
    })
      .filter(([, value]) => value)
      .map(([key]) => key)

    setSelectedIds(newSelection)
  }, [checked, row.id, selectedIdsLookup, setSelectedIds])
  return (
    <AccountSelectorAccount
      checked={checked}
      onChange={onRowSelectionChange}
      nickname={nickname}
      accountNumber={accountNumber}
      registrationDesc={account?.registrationDesc}
      registrationtype={account?.registrationtype}
      balance={value}
      change={change}
    />
  )
}

const getColumnDefs = (): ColumnDef<IAccountSelectorAccount>[] => [
  {
    header: accountSelectorColumnNames.accountNumber,
    enableHiding: true,
    enableGrouping: false,
    accessorFn: ({ account }) => account?.CustodyAccount,
    cell: AccountCell,
    aggregatedCell: GroupCell
  },
  {
    header: accountSelectorColumnNames.custodianType,
    enableHiding: true,
    enableGrouping: true,
    aggregationFn: 'unique',
    accessorFn: ({ custodianType }) => custodianType
  },
  {
    header: accountSelectorColumnNames.taxable,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.taxstatus &&
      (taxStatusLookup[account.taxstatus] || account.taxstatus)
  },
  {
    header: accountSelectorColumnNames.legalEntityId,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.LegalEntityName
  },
  {
    header: accountSelectorColumnNames.accountRegistration,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.registrationDesc || account?.registrationtype
  },
  {
    header: accountSelectorColumnNames.ausClass,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.accounttype
  },
  {
    header: accountSelectorColumnNames.value,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'sum',
    accessorFn: ({ balance }) => balance?.netWorth || 0
  },
  {
    header: accountSelectorColumnNames.changeValue,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'sum',
    accessorFn: ({ balance }) => balance?.netWorthChange || 0
  }
]

const noVisible = Object.entries(accountSelectorColumnNames).reduce(
  (a, [key]) => ({ ...a, [key]: false }),
  {} as VisibilityState
)

const defaultVisibilityState: VisibilityState = {
  ...noVisible,
  [accountSelectorColumnNames.accountNumber]: true
}

export const AccountSelector: React.FC = () => {
  const { accounts, selectedAccountIds } = useRdot360AccountContext()
  const { householdAccountBalances } = useRdot360BalancesContext()
  const [expanded, setExpanded] = useState<ExpandedState>(true)
  const columns = useMemo(getColumnDefs, [])
  const { grouping, setGrouping, sorting, setSorting, setSelectedIds } =
    useAccountSelectorTableStore()

  const data = useMemo(() => {
    const [firstGroup] = grouping
    const isGroupedByCustodianType =
      firstGroup === accountSelectorColumnNames.custodianType

    const placeholders: IAccountSelectorAccount[] = isGroupedByCustodianType
      ? custodianTypes
          .filter(
            (x) =>
              !(householdAccountBalances.length && x === 'Rockefeller Accounts')
          )
          .map((x) => ({
            custodianType: x,
            id: x,
            placeholder: true
          }))
      : !accounts?.length
      ? [
          {
            custodianType: 'Rockefeller Accounts',
            id: 'empty',
            placeholder: true
          }
        ]
      : []

    const balanceLookup = keyBy(
      householdAccountBalances,
      (x) => x.accountaumber || ''
    )

    const selectorAccounts =
      accounts?.map(
        (x): IAccountSelectorAccount => ({
          id: x.id || '',
          custodianType: 'Rockefeller Accounts',
          account: x,
          balance: balanceLookup[x.id || '']
        })
      ) || empty
    return [...selectorAccounts, ...placeholders]
  }, [accounts, grouping, householdAccountBalances])

  const dataLookup = useMemo(() => keyBy(data, ({ id }) => id), [data])

  useEffect(() => {
    const filteredSelection = selectedAccountIds.filter((x) => dataLookup[x])
    if (filteredSelection.length === selectedAccountIds.length) {
      return
    }

    setSelectedIds(filteredSelection)
  }, [dataLookup, selectedAccountIds, setSelectedIds])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      columnVisibility: defaultVisibilityState
    },
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    manualPagination: true,
    autoResetExpanded: false,
    getRowId: (originalRow) => originalRow.id,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  const groupRows = table.getPreExpandedRowModel().rows
  const isAllExpanded = table.getIsAllRowsExpanded()

  return (
    <div>
      <div
        css={{
          marginBottom: '5px',
          cursor: 'pointer',
          justifyContent: 'flex-end',
          display: 'flex'
        }}
      >
        <Link
          onClick={(e) => {
            table.toggleAllRowsExpanded()
            e.preventDefault()
          }}
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Link>
      </div>
      {groupRows.map((groupRow) => {
        const [groupCell] = groupRow.getVisibleCells()
        const isExpanded = groupRow.getCanExpand() && groupRow.getIsExpanded()
        const subRows = isExpanded
          ? groupRow.subRows.filter((x) => !x.original.placeholder)
          : []
        return (
          <div key={groupRow.id} css={[accountSelectorStyles.container]}>
            <div
              css={[
                accountSelectorStyles.groupItem,
                isExpanded &&
                  subRows.length &&
                  accountSelectorStyles.expandedGroupItem
              ]}
            >
              {flexRender(
                groupCell.column.columnDef.aggregatedCell,
                groupCell.getContext()
              )}
            </div>
            {isExpanded && !!subRows.length && (
              <div css={[accountSelectorStyles.itemsContainer]}>
                {subRows.map((row) => {
                  const [cell] = row.getVisibleCells()
                  return (
                    <div key={row.id} css={[accountSelectorStyles.accountItem]}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
