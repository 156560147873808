import { MessageBarType } from '@fluentui/react'
import { flow, uniq } from 'lodash'
import { createSelector } from 'reselect'
import {
  DynamicsUserRolesEnum,
  saga_getCurrentSystemuser
} from 'store/user/dynamicsUser'
import { call, put, race, select, take, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  MarginRateRequestStatusEnum,
  IMarginRateRequest,
  getMarginRateRequests
} from '../../../api/dynamics'
import { IGraphApiSendMailRequest, sendMail } from '../../../api/graph'
import { IHousehold } from '../../../api/household.types'
import { isNotNullOrUndefined } from '../../../shared/gaurds'
import {
  getDynamicsApiOptions,
  getMicrosoftGraphApiOptions
} from '../../../store/shared/sagas'
import { getIsEnvironmentProduction } from '../../../store/system'
import { getRdotUsername } from '../../../store/user/selectors'
import { pushNotification } from '../../Notifications'
import { generateMarginRateEmail } from '../MarginRateEmail'
import {
  marginRateMoreAccountsActions,
  getMoreAccountsFromHouseholdId,
  getMarginRateRequestType
} from './householdAccountsDetails'
import { getAccountsResult } from './householdAccountsFetch'
import {
  getHouseholdsResult,
  householdDetailsFetchActions
} from './householdDetailsFetch'
import {
  getMarginRateEmailContent,
  marginRateEmailActions
} from './marginRateEmailContents'
import { marginRateRequestPostActions } from './marginRateRequestsPost'
import { getMarginRateAdjustmentFeatureState } from './shared'

const OPEN = '@features/@marginRateAdjustment/@marginRatePanel/OPEN'
const CLOSE = '@features/@marginRateAdjustment/@marginRatePanel/CLOSE'
const SUBMIT = '@features/@marginRateAdjustment/@marginRatePanel/SUBMIT'
const FAILURE = '@features/@marginRateAdjustment/@marginRatePanel/FAILURE'

export const marginRatePanelActions = {
  open: createAction(OPEN)<string | undefined>(),
  close: createAction(CLOSE)(),
  submit: createAction(SUBMIT)(),
  failure: createAction(FAILURE)<Error>()
}
export type MarginRatePanelActionTypes = ActionType<
  typeof marginRatePanelActions
>

export interface IMarginRatePanelState {
  isOpen?: boolean
  loading?: boolean
  error?: Error
}

const initialState: IMarginRatePanelState = {}

export const marginRatePanelReducer = createReducer<
  IMarginRatePanelState,
  MarginRatePanelActionTypes
>(initialState)
  .handleAction(marginRatePanelActions.open, (state, action) => ({
    ...initialState,
    isOpen: true,
    currentAccountId: action.payload
  }))
  .handleAction(marginRatePanelActions.close, () => ({
    ...initialState,
    isOpen: false
  }))
  .handleAction(marginRatePanelActions.submit, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(marginRatePanelActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.marginRatePanel
)

const onClose = function* () {
  yield put(marginRateMoreAccountsActions.reset())
  yield put(marginRateEmailActions.setMarginRate(undefined))
  yield put(marginRateEmailActions.setJustification(''))
}

export const getIsMarginRatePanelOpen = flow(rootSelector, (x) => x?.isOpen)

export const getIsMarginRatePanelLoading = flow(rootSelector, (x) => x?.loading)

export const getMarginRatePanelError = flow(rootSelector, (x) => x?.error)

export interface IMarginRatePanelHousehold extends IHousehold {
  suggestedRate?: number
}
export const getSelectedHousehold = createSelector(
  [getHouseholdsResult],
  (household) =>
    household
      ? ({
          ...household,
          suggestedRate: getSuggestedRate(household?.householdKPI?.AumTotal)
        } as IMarginRatePanelHousehold)
      : undefined
)

export const getSuggestedRate = (aum: number | undefined) => {
  if (!aum) {
    return undefined
  }
  if (aum < 2000000) {
    return 3.5
  } else if (aum <= 4999999 && aum >= 2000000) {
    return 2.5
  } else if (aum <= 9999999 && aum >= 5000000) {
    return 2.0
  } else if (aum >= 10000000) {
    return 1.5
  } else {
    return undefined
  }
}

export const getExistingRates = createSelector(
  [getAccountsResult],
  (householdAccounts) => {
    const rates = householdAccounts
      ?.map((account) => account.marginInterestRate)
      ?.filter((x) => isNotNullOrUndefined(x))
    return uniq(rates)
  }
)

export const getMarginEmailRecipients = function* () {
  const requestType = yield* select(getMarginRateRequestType)
  const isProd = yield* select(getIsEnvironmentProduction)
  const currentUsername = yield* select(getRdotUsername)
  const systemuser = yield* call(saga_getCurrentSystemuser)
  const isCustom = requestType === 'Custom'
  const isApprover =
    systemuser?.systemuserroles_association?.some(({ name }) =>
      [DynamicsUserRolesEnum.MarginRateRequestApprover].includes(
        name as DynamicsUserRolesEnum
      )
    ) || false

  if (!isProd) {
    return [currentUsername].filter(isNotNullOrUndefined)
  }

  if (isCustom && !isApprover) {
    return [
      'mbrakey@rockco.com',
      'pgranite@rockco.com',
      'wmlendingoperations@rockco.com'
    ]
  }

  return ['wmlendingoperations@rockco.com']
}

const onSubmit = function* () {
  try {
    const currentUsername = yield* select(getRdotUsername)
    const rateInfo = yield* select(getMarginRateEmailContent)
    const accounts = yield* select(getMoreAccountsFromHouseholdId)
    const selectedHousehold = yield* select(getSelectedHousehold)
    const requestType = yield* select(getMarginRateRequestType)
    const recipients = yield* call(getMarginEmailRecipients)

    const filteredAccounts = accounts?.filter(
      (account) => account.marginInterestRate !== rateInfo?.rate
    )
    if (filteredAccounts?.length === 0) {
      throw new Error('All accounts selected already have requested rate.')
    }

    if (!recipients.length || !currentUsername) {
      throw new Error('Unable to discover destination for confirmation email')
    }

    const dynamicsApiOptions = yield* call(getDynamicsApiOptions)
    const existingRequest = yield* call(
      getMarginRateRequests,
      dynamicsApiOptions,
      {
        top: 1,
        filters: [
          `rcm_householdid eq '${selectedHousehold?.householdId}'`,
          `Microsoft.Dynamics.CRM.In(PropertyName='rcm_status',PropertyValues=[${[
            MarginRateRequestStatusEnum.REQUESTED,
            MarginRateRequestStatusEnum.PROCESSING,
            MarginRateRequestStatusEnum.APPROVED
          ]
            .map((x) => `'${x}'`)
            .join(',')}])`
        ]
      }
    )

    if (existingRequest.value?.length) {
      throw new Error('A pending request exists for this household')
    }

    const allAccounts = filteredAccounts?.map((x) => x.id)?.join(',')
    const request: IMarginRateRequest = {
      rcm_householdid: selectedHousehold?.householdId,
      rcm_householdname: selectedHousehold?.householdName,
      rcm_accounts: allAccounts,
      rcm_rate: rateInfo?.rate,
      rcm_ratetype: requestType,
      rcm_justification: rateInfo?.justification,
      rcm_status:
        requestType === 'Existing' || requestType === 'Suggested'
          ? MarginRateRequestStatusEnum.APPROVED
          : MarginRateRequestStatusEnum.REQUESTED
    }
    yield put(marginRateRequestPostActions.request(request))
    const { success, failure } = yield* race({
      success: take(marginRateRequestPostActions.success),
      failure: take(marginRateRequestPostActions.failure)
    })
    if (failure || !success) {
      yield put(
        marginRatePanelActions.failure(
          failure?.payload || new Error('An unknown error occurred')
        )
      )
      return
    }
    const subject = `Margin Rate Request for ${selectedHousehold?.householdName}`
    const body = yield* call(generateMarginRateEmail, {
      selectedHousehold: selectedHousehold,
      accounts: accounts,
      rate: rateInfo?.rate,
      justification: rateInfo?.justification,
      requestType: requestType
    })
    const emailRequest: IGraphApiSendMailRequest = {
      message: {
        toRecipients: recipients.map((x) => ({ emailAddress: { address: x } })),
        ccRecipients: [{ emailAddress: { address: currentUsername } }],
        body: {
          contentType: 'HTML',
          content: body
        },
        subject
      }
    }

    const graphApiOptions = yield* call(getMicrosoftGraphApiOptions)
    yield* call(sendMail, graphApiOptions, emailRequest)
  } catch (e: any) {
    yield put(marginRatePanelActions.failure(e))
    return
  }

  yield call(pushNotification, {
    message: 'Successfully submitted request.',
    type: MessageBarType.success
  })
  yield put(marginRatePanelActions.close())
}

const onOpenMarginRateAdjustmentPanel = function* (
  action: ReturnType<typeof marginRatePanelActions.open>
) {
  if (!action.payload) {
    return
  }

  yield put(householdDetailsFetchActions.request(action.payload))
}

export const marginRatePanelSagas = [
  () => takeLatest(marginRatePanelActions.close, onClose),
  () => takeLatest(marginRatePanelActions.submit, onSubmit),
  () => takeLatest(marginRatePanelActions.open, onOpenMarginRateAdjustmentPanel)
]
