import { memo } from 'react'

export const HighlightSearchText: React.FC<{
  text?: string
  search?: string
}> = memo(({ text = '', search = '' }) => {
  const searchText = search.replaceAll('$', '\\$').replaceAll('\\', '\\\\')
  const splitText = search.length
    ? text.split(new RegExp(`(${searchText})`, 'gi'))
    : [text]
  return (
    <>
      {splitText.map((x, i) =>
        x.toLowerCase() === search?.toLowerCase() ? (
          <mark key={i}>{x}</mark>
        ) : (
          <span key={i}>{x}</span>
        )
      )}
    </>
  )
})
