import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

export const buttonStyles = {
  primary: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.primaryDarkBlue,
      border: `1px solid ${theme.colors.primaryDarkBlue}`,
      borderRadius: '8px',
      padding: '8px 12px',
      color: theme.colors.primaryWhite,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: theme.colors.extraBlue1
      },
      ':disabled': {
        backgroundColor: theme.colors.tertiaryGray3,
        border: `1px solid ${theme.colors.tertiaryGray3}`,
        cursor: 'default'
      }
    }),
  secondary: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.primaryWhite,
      borderRadius: '8px',
      padding: '8px 12px',
      color: theme.colors.primaryDarkBlue,
      border: `1px solid ${theme.colors.primaryDarkBlue}`,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: theme.colors.extraGray
      },
      ':disabled': {
        color: theme.colors.tertiaryGray3,
        border: `1px solid ${theme.colors.tertiaryGray3}`,
        cursor: 'default',
        backgroundColor: theme.colors.primaryWhite
      }
    })
}

export const PillButton = styled.button({
  borderRadius: '11.5px',
  border: '0px',
  padding: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  background: '#bbddee',
  color: '#40504E',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px'
})

export const TabButton: React.FC<{
  text: string
  selected?: boolean
  onClick?: () => void
}> = ({ text, selected, onClick }) => {
  return (
    <button
      onClick={onClick}
      css={{
        background: 'none',
        border: 0,
        borderBottom: selected ? 'solid #13272B 1px' : 0,
        fontSize: '16px',
        lineHeight: '22px',
        opacity: selected ? 1 : 0.7,
        padding: '10px',
        cursor: 'pointer'
      }}
    >
      {text}
    </button>
  )
}
