import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export type IClientInvitePanelType = 'invite' | 'reinvite' | 'resend'

const { actions, reducer } = createSlice({
  name: 'clientInvitePanel',
  initialState: {} as IClientInvitePanelState,
  reducers: {
    open: (state, action: PayloadAction<string>) => ({
      ...state,
      isOpen: true,
      selectedWealthscapeId: action.payload
    }),
    close: () => ({
      isOpen: false,
      selectedWealthscapeId: undefined,
      type: undefined,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setType: (state, action: PayloadAction<IClientInvitePanelType>) => ({
      ...state,
      type: action.payload
    })
  }
})

export { reducer as clientInvitePanelReducer }

const rootSelector = (state: AppState) =>
  state.features.clientInvite.clientInvitePanel

const getIsClientInvitePanelOpen = flow(rootSelector, (x) => x.isOpen)
const getClientInvitePanelError = flow(rootSelector, (x) => x.error)
const getSelectedWealthscapeId = flow(
  rootSelector,
  (x) => x.selectedWealthscapeId
)
const getPanelType = flow(rootSelector, (x) => x.type)

export interface IClientInvitePanelState {
  isOpen?: boolean
  error?: Error
  selectedWealthscapeId?: string
  type?: IClientInvitePanelType
}

export const useClientInvitePanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(
    (id: string, type: IClientInvitePanelType) => {
      dispatch(actions.setType(type))
      dispatch(actions.open(id))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const selectedWealthscapeId = useSelector(getSelectedWealthscapeId)

  const isOpen = useSelector(getIsClientInvitePanelOpen)

  const error = useSelector(getClientInvitePanelError)

  const panelType = useSelector(getPanelType)

  return {
    close,
    open,
    selectedWealthscapeId,
    setError,
    isOpen,
    error,
    panelType
  }
}
