import { css, useTheme } from '@emotion/react'
import { Dropdown, IDropdownOption } from '@fluentui/react'
import DetailsNavigator from 'modules/Advisory/modules/Rdot360/components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useHoldingsApiUtil } from 'modules/Advisory/modules/Rdot360/store/holdingsApi'
import { useTasApiUtil } from 'modules/Advisory/modules/Rdot360/store/tasApi'
import { useCallback, useMemo } from 'react'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import {
  investmentsTableColumnNames,
  useInvestmentsTableStore
} from '../InvestmentsTable'

const viewByOptions: IDropdownOption<any>[] = [
  { key: investmentsTableColumnNames.assetClass, text: 'Asset Class' },
  { key: investmentsTableColumnNames.secid, text: 'Investment' },
  { key: investmentsTableColumnNames.accountNumber, text: 'Accounts' }
]

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: 20
  }),
  dropDownContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  dropDown: css({
    marginTop: '8px',
    '.ms-Dropdown-title': {
      width: 175,
      height: 48,
      borderRadius: 8,
      borderColor: '#AFB1B6',
      paddingTop: 8
    },
    '.ms-Dropdown-caretDownWrapper': {
      top: 8
    }
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginLeft: 10,
    marginBottom: 10
  }),
  searchBox: css({
    marginLeft: 'auto'
  })
})

export const InvestmentsMenu: React.FC = () => {
  const { searchText, setSearchText } = useInvestmentsDetailsUIState()
  const { grouping, setGrouping } = useInvestmentsTableStore()
  const [firstGrouping] = grouping || []

  const onGroupingChange = useCallback(
    (e?: unknown, option?: IDropdownOption<any>) => {
      if (!option?.key) {
        return
      }
      switch (option?.key) {
        case investmentsTableColumnNames.assetClass:
          setGrouping([
            investmentsTableColumnNames.assetClass,
            investmentsTableColumnNames.secid
          ])
          break
        default:
        case investmentsTableColumnNames.secid:
          setGrouping([investmentsTableColumnNames.secid])
          break
        case investmentsTableColumnNames.productType:
          setGrouping([
            investmentsTableColumnNames.productType,
            investmentsTableColumnNames.secid
          ])
          break
        case investmentsTableColumnNames.accountNumber:
          setGrouping([
            investmentsTableColumnNames.accountNumber,
            investmentsTableColumnNames.productType
          ])
          break
      }
    },
    [setGrouping]
  )
  const { invalidateTags: holdingsInvalidateTags } = useHoldingsApiUtil()
  const { invalidateTags: tasLotsInvalidateTags } = useTasApiUtil()
  const clearCache = useCallback(() => {
    holdingsInvalidateTags(['positionByCategory'])
    tasLotsInvalidateTags(['openTaxLotsByAccountAndSecurity'])
  }, [holdingsInvalidateTags, tasLotsInvalidateTags])

  const classes = useMemo(() => getClasses(), [])

  const theme = useTheme()

  return (
    <div css={classes.container}>
      <DetailsNavigator />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          flexGrow: 1
        }}
      >
        <div
          style={{
            fontSize: '10px',
            fontWeight: 300,
            marginTop: 6
          }}
        >
          Total values may differ from more timely amounts displayed in Balances
          and the Account Selector due to pending activity and the timing of
          data updates. This will be addressed in coming months.
        </div>
        <div css={[classes.container, { flexGrow: 1 }]}>
          <div css={classes.dropDownContainer}>
            <div css={{ fontSize: '14px', fontWeight: 500 }}>View By</div>
            <div css={classes.dropDown}>
              <Dropdown
                options={viewByOptions}
                selectedKey={firstGrouping}
                onChange={onGroupingChange}
              />
            </div>
          </div>
          <div css={classes.searchBox}>
            <Searchbox searchText={searchText} onChange={setSearchText} />
          </div>
          <div css={classes.iconStyle}>
            <Icon
              type="Refresh"
              width={24}
              height={24}
              onClick={clearCache}
              color={theme.colors.extraBlue2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
