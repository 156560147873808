import { keyBy } from 'lodash'
import { amountFormatter } from 'modules/Advisory/modules/Rdot360/components/shared/Formatters'
import { ICategoryPostionResponseValue } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import { FC } from 'react'
import { FormattedNumber } from 'react-intl'
import assetTaxonomy from './../../../../../../../../metadata/asset-taxonomy.json'

const InvestmentsChartLegendDetails: FC<{
  data: ICategoryPostionResponseValue
}> = ({ data }) => {
  return (
    <div
      css={{
        diplay: 'flex',
        flexBasis: 0,
        height: '45px',
        maxWidth: '300px',
        minWidth: '180px'
      }}
    >
      <div css={{ display: 'flex' }}>
        <div
          css={{
            maxWidth: '10px',
            width: '10px',
            height: '10px',
            backgroundColor: `#${
              keyBy(assetTaxonomy, (x) => x.description)[
                data?.assetcat || 'Others'
              ]?.color ||
              keyBy(assetTaxonomy, (x) => x.description)['Others']?.color
            }`,
            flex: '0.1'
          }}
        />
        <div css={{ flex: '0.9', marginLeft: '10px', fontSize: '14px' }}>
          {data?.assetcat === 'Cash' ? 'Cash and Equivalents' : data?.assetcat}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          marginTop: '5px',
          marginLeft: '20px',
          width: '180px'
        }}
      >
        <div css={{ flex: '0.7', fontWeight: 'bold' }}>
          {amountFormatter(data?.mktval || 0)}
        </div>
        <div
          css={{
            flex: '0.3',
            justifyContent: 'end',
            display: 'flex'
          }}
        >
          <FormattedNumber
            value={data?.mktvalpct || 0}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
          %
        </div>
      </div>
    </div>
  )
}

export default InvestmentsChartLegendDetails
