import { ActionType, createAction } from 'typesafe-actions'
import { ITop10PositionItem } from '../../../../../api/common.types'
import { IListsUiState } from '../../../../../features/Lists/core/contracts/IListsUIState'
import { IDashboardRouteState } from '../shared/IDashboardRouteState'
import { ListTypes } from '../shared/types'
import { AllocationType } from './types'

const SET_SELECTED_PRODUCT_ALLOCATIONS =
  '@modules/@dashboard/ADD_SELECTED_PRODUCT_ALLOCATION'
const SET_SELECTED_ASSET_ALLOCATIONS =
  '@modules/@dashboard/SET_SELECTED_ASSET_ALLOCATION'

const SET_SELECTED_POSITIONS = '@modules/@dashboard/SET_SELECTED_POSITION'
export const TOGGLE_NET_NEW_MONEY_SELECTED =
  '@modules/@dashboard/TOGGLE_NET_NEW_MONEY_SELECTED'
export const TOGGLE_NET_NEW_ASSETS_SELECTED =
  '@modules/@dashboard/TOGGLE_NET_NEW_ASSETS_SELECTED'
export const SET_ALLOCATION_TYPE = '@modules/@dashboard/SET_ALLOCATION_TYPE'
export const SET_LIST_STATE = '@modules/@dashboard/SET_LIST_STATE'

export const dashboardActions = {
  setSelectedProductAllocations: createAction(SET_SELECTED_PRODUCT_ALLOCATIONS)<
    string[] | undefined
  >(),
  setSelectedAssetAllocations: createAction(SET_SELECTED_ASSET_ALLOCATIONS)<
    string[] | undefined
  >(),
  setSelectedPositions: createAction(SET_SELECTED_POSITIONS)<
    ITop10PositionItem[] | undefined
  >(),
  toggleNetNewMoneySelected: createAction(TOGGLE_NET_NEW_MONEY_SELECTED)(),
  toggleNetNewAssetsSelected: createAction(TOGGLE_NET_NEW_ASSETS_SELECTED)(),
  setAllocationType: createAction(SET_ALLOCATION_TYPE)<AllocationType>(),
  setListState: createAction(SET_LIST_STATE)<{
    listType: ListTypes
    state: IListsUiState
  }>()
}

const NAVIGATE_TO_DASHBOARD = '@modules/@dashboard/NAVIGATE_TO_DASHBOARD'

export const dashboardNavigationActions = {
  navigateToDashboard: createAction(NAVIGATE_TO_DASHBOARD)<
    IDashboardRouteState | undefined
  >()
}

export type DashboardActionTypes = ActionType<typeof dashboardActions>
