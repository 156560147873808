import { Checkbox, Stack, Text } from '@fluentui/react'
import React, { useCallback } from 'react'

export const DelinkingListItem: React.FC<{
  account: string
  addAccount: (account: string) => void
  removeAccount: (account: string) => void
  isChecked: boolean
}> = ({ account, addAccount, removeAccount, isChecked }) => {
  const onChange = useCallback(
    (ev?: any, checked?: boolean): void => {
      if (checked) {
        addAccount(account)
      } else {
        removeAccount(account)
      }
    },
    [account, addAccount, removeAccount]
  )
  return (
    <Stack horizontal={true} tokens={{ childrenGap: 5 }} verticalAlign="center">
      <Checkbox onChange={onChange} checked={isChecked} />
      <Text>{account}</Text>
    </Stack>
  )
}
