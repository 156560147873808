import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { intersectionWith, sum, sumBy, keyBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useBalancesApiUtil, useGetBalancesDetailQuery } from '../balancesApi'
import { IBalanceDetailResponseValueItem } from '../balancesApi/IBalanceDetailResponse'
import { useRdot360HouseholdAccountsApiContext } from './apiContext'
import { useRdot360AccountContext } from './useRdot360AccountContext'

const useBalancesApiHouseholdAccountsBaseRequest = () => {
  const { apiContextAccounts, apiKey } = useRdot360HouseholdAccountsApiContext()
  return apiContextAccounts?.length
    ? {
        accounts: apiContextAccounts,
        contextId: apiKey
      }
    : false
}

const useGetBalancesDetailForHouseholdAccounts = () => {
  const request = useBalancesApiHouseholdAccountsBaseRequest()
  return useGetBalancesDetailQuery(request || skipToken)
}

export interface IRdot360BalanceSummary {
  totalAccountValue?: number
  totalAccountValueChange?: number
}

type BalancePropsToAggregate = keyof Pick<
  IBalanceDetailResponseValueItem,
  | 'annuity'
  | 'annuitychange'
  | 'availablemarginbalance'
  | 'availablemarginbalancechange'
  | 'availablewithdraw'
  | 'cashbuyingpower'
  | 'cashmarginbuyingpower'
  | 'cashbalance'
  | 'cashbalancechange'
  | 'cashmoneyaccounts'
  | 'cashmoneyaccountschange'
  | 'moneyaccountvalue'
  | 'moneyaccountvaluechange'
  | 'fixedincomeaccruedinterest'
  | 'fixedincomeaccruedinterestchange'
  | 'totalaccountvalue'
  | 'totalaccountvaluechange'
  | 'pricedinvestments'
  | 'pricedinvestmentschange'
  | 'pendingactivity'
  | 'othercreditdebit'
  | 'cashmarginavailabletowithdraw'
  | 'marginandoutstandingbalance'
  | 'outstandingbalancechange'
  | 'marginbalancechange'
>

const balancePropsToAggregate: BalancePropsToAggregate[] = [
  'annuity',
  'annuitychange',
  'availablemarginbalance',
  'availablemarginbalancechange',
  'availablewithdraw',
  'cashbuyingpower',
  'cashmarginbuyingpower',
  'cashbalance',
  'cashbalancechange',
  'cashmoneyaccounts',
  'cashmoneyaccountschange',
  'moneyaccountvalue',
  'moneyaccountvaluechange',
  'fixedincomeaccruedinterest',
  'fixedincomeaccruedinterestchange',
  'totalaccountvalue',
  'totalaccountvaluechange',
  'pricedinvestments',
  'pricedinvestmentschange',
  'pendingactivity',
  'othercreditdebit',
  'cashmarginavailabletowithdraw',
  'marginandoutstandingbalance',
  'outstandingbalancechange',
  'marginbalancechange'
]

export type EnhancedBalanceDetailResponseValue = ReturnType<
  typeof mapToNewBalance
>
const mapToNewBalance = (balance: IBalanceDetailResponseValueItem) => {
  const totalAssets = sum([
    balance.totalaccountvalue,
    balance.fixedincomeaccruedinterest
  ])
  const totalLiabilities = balance.marginandoutstandingbalance || 0
  const totalLiabilitiesChange = sum([
    balance.marginbalancechange,
    balance.outstandingbalancechange
  ])
  const cash = sum([balance.cashmoneyaccounts, balance.othercreditdebit])
  const cashChange = 0
  const totalAssetsChange = sum([balance.totalaccountvaluechange, cashChange])
  const netWorth = sum([totalAssets, totalLiabilities])
  const netWorthChange = sum([totalAssetsChange, totalLiabilitiesChange])
  const availableToWithdraw =
    balance.cashmarginavailabletowithdraw || balance.availablewithdraw || 0
  const availableToInvest =
    balance.cashmarginbuyingpower || balance.cashbuyingpower || 0

  return {
    ...balance,
    pricedinvestmentschange: 0,
    cash,
    cashChange,
    totalAssets,
    totalAssetsChange,
    totalLiabilities,
    totalLiabilitiesChange,
    netWorth,
    netWorthChange,
    availableToWithdraw,
    availableToInvest
  }
}

const getBalanceSummary = (balances: IBalanceDetailResponseValueItem[]) => {
  const [first] = balances || []
  const summary = balancePropsToAggregate.reduce(
    (a, prop) => ({ ...a, [prop]: sumBy(balances, (x) => x?.[prop] || 0) }),
    {} as Pick<IBalanceDetailResponseValueItem, BalancePropsToAggregate>
  )

  const newBalance = mapToNewBalance(summary)

  return {
    ...newBalance,
    asofdate: first?.asofdate
  }
}

export const useRdot360BalancesContext = () => {
  const {
    isFetching,
    data: balancesResponse,
    error,
    refetch,
    isError
  } = useGetBalancesDetailForHouseholdAccounts()
  const { selectedAccountIds, accountLookup } = useRdot360AccountContext()
  const { invalidateTags } = useBalancesApiUtil()

  const householdAccountBalances = useMemo(
    () =>
      balancesResponse?.accountbalances
        ?.filter((x) => x.accountaumber && accountLookup[x.accountaumber])
        .map(mapToNewBalance) || [],
    [accountLookup, balancesResponse?.accountbalances]
  )

  const selectedAccountBalances = useMemo(
    () =>
      intersectionWith(
        householdAccountBalances,
        selectedAccountIds,
        (a, b) => a.accountaumber === b
      ),
    [householdAccountBalances, selectedAccountIds]
  )

  const selectedBrokerageAccounts = useMemo(
    () =>
      selectedAccountBalances?.filter(
        (x) =>
          x?.accountaumber &&
          accountLookup[x?.accountaumber]?.accounttype === 'Brokerage'
      ),
    [accountLookup, selectedAccountBalances]
  )
  const cashInSelectedBrokerageAccounts = useMemo(
    () => sumBy(selectedBrokerageAccounts, (x) => x.cash),
    [selectedBrokerageAccounts]
  )

  const selectedAccountBalanceSummary = useMemo(
    () => getBalanceSummary(selectedAccountBalances),
    [selectedAccountBalances]
  )
  const householdAccountBalanceSummary = useMemo(
    () => getBalanceSummary(householdAccountBalances),
    [householdAccountBalances]
  )

  const invalidateCache = useCallback(
    () => invalidateTags(['balancesDetail']),
    [invalidateTags]
  )

  const balanceLookup = useMemo(
    () =>
      keyBy(householdAccountBalances, (x) => x.accountaumber || '') as Record<
        string,
        (typeof householdAccountBalances)[0] | undefined
      >,
    [householdAccountBalances]
  )

  return {
    isFetching,
    isError,
    refetch,
    error,
    selectedAccountBalances,
    selectedAccountBalanceSummary,
    householdAccountBalances,
    householdAccountBalanceSummary,
    invalidateCache,
    cashInSelectedBrokerageAccounts,
    balanceLookup
  }
}
