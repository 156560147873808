import { FC, useMemo } from 'react'
import { GetGainLossesIncomeSummaryResponse } from '../../store/types'
import { mapGainLossIncome } from './dataMapper'
import GainLossCustomTableTop from './GainLossCustomTableTopSection'
import {
  StyledTableWrapperTop,
  StyledTableWrapperBottom
} from './GainLossesIncomeTable.styled'
import columns from './GainLossesIncomeTableColumns'
import GainLossessCustomTableBottom from './GainLossessCustomTableBottomSection'

const GainLossesIncometable: FC<{
  data?: GetGainLossesIncomeSummaryResponse
}> = ({ data }) => {
  const gainLossData = useMemo(() => {
    if (data) {
      return mapGainLossIncome(data)
    } else {
      return {
        realizedGainTableTypeList: [
          {
            description: 'Realized Gains',
            longTerm: null,
            shortTerm: null,
            total: null
          },
          {
            description: 'Realized Losses',
            longTerm: null,
            shortTerm: null,
            total: null
          },
          {
            description: 'Net Realized (current year)',
            longTerm: null,
            shortTerm: null,
            total: null
          }
        ],
        unrealizedGainTableTypeList: [
          {
            description: 'Unrealized Gains',
            longTerm: null,
            shortTerm: null,
            total: null
          },
          {
            description: 'Unrealized Losses',
            longTerm: null,
            shortTerm: null,
            total: null
          },
          {
            description: 'Net Unrealized (cumulative)',
            longTerm: null,
            shortTerm: null,
            total: null
          }
        ]
      }
    }
  }, [data])
  return (
    <>
      <StyledTableWrapperTop>
        <GainLossCustomTableTop
          columns={columns}
          data={gainLossData?.realizedGainTableTypeList || []}
        />
      </StyledTableWrapperTop>
      <StyledTableWrapperBottom>
        <GainLossessCustomTableBottom
          columns={columns}
          data={gainLossData?.unrealizedGainTableTypeList || []}
        />
      </StyledTableWrapperBottom>
    </>
  )
}

export default GainLossesIncometable
