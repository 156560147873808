import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  cusip = 'cusip',
  ticker = 'ticker',
  isin = 'isin',
  sedol = 'sedol',
  securitydescription = 'securitydescription',
  l1description = 'l1description',
  l2description = 'l2description',
  l3description = 'l3description',
  l4description = 'l4description',
  sector = 'sector',
  assettype = 'assettype',
  assetsubtype = 'assetsubtype',
  nfsSecurityTypeDescription = 'nfsSecurityTypeDescription',
  rockitSecurityTypeDescription = 'rockitSecurityTypeDescription',
  nwSecurityType = 'nwSecurityType',
  saSecurityType = 'saSecurityType',
  source = 'source'
}

export const SecurityColumnIds = { ...ColumnIdsEnum }
export type SecurityColumnIds = typeof SecurityColumnIds

export const SecurityColumnDefinitions: Record<string, IColumnDefinition> = {
  [SecurityColumnIds.cusip]: {
    id: SecurityColumnIds.cusip,
    name: 'CUSIP',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'cusip'
  },
  [SecurityColumnIds.ticker]: {
    id: SecurityColumnIds.ticker,
    name: 'TICKER',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ticker'
  },
  [SecurityColumnIds.isin]: {
    id: SecurityColumnIds.isin,
    name: 'ISIN',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'isin'
  },
  [SecurityColumnIds.sedol]: {
    id: SecurityColumnIds.sedol,
    name: 'SEDOL',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'sedol'
  },
  [SecurityColumnIds.securitydescription]: {
    id: SecurityColumnIds.securitydescription,
    name: 'SECURITY DESC',
    type: 'string',
    width: 400,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'securitydescription'
  },
  [SecurityColumnIds.l1description]: {
    id: SecurityColumnIds.l1description,
    name: 'LEVEL1',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l1description'
  },
  [SecurityColumnIds.l2description]: {
    id: SecurityColumnIds.l2description,
    name: 'LEVEL2',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l2description'
  },
  [SecurityColumnIds.l3description]: {
    id: SecurityColumnIds.l3description,
    name: 'LEVEL3',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l3description'
  },
  [SecurityColumnIds.l4description]: {
    id: SecurityColumnIds.l4description,
    name: 'LEVEL4',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l4description'
  },
  [SecurityColumnIds.sector]: {
    id: SecurityColumnIds.sector,
    name: 'SECTOR',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'sector'
  },
  [SecurityColumnIds.assettype]: {
    id: SecurityColumnIds.assettype,
    name: 'ASSET TYPE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'assettype'
  },
  [SecurityColumnIds.assetsubtype]: {
    id: SecurityColumnIds.assetsubtype,
    name: 'ASSET SUB TYPE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'assetsubtype'
  },
  [SecurityColumnIds.nfsSecurityTypeDescription]: {
    id: SecurityColumnIds.nfsSecurityTypeDescription,
    name: 'NFS SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'nfsSecurityTypeDescription'
  },
  [SecurityColumnIds.rockitSecurityTypeDescription]: {
    id: SecurityColumnIds.rockitSecurityTypeDescription,
    name: 'ROCKIT SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'rockitSecurityTypeDescription'
  },
  [SecurityColumnIds.nwSecurityType]: {
    id: SecurityColumnIds.nwSecurityType,
    name: 'NW SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'nwSecurityType'
  },
  [SecurityColumnIds.saSecurityType]: {
    id: SecurityColumnIds.saSecurityType,
    name: 'SA SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'saSecurityType'
  },
  [SecurityColumnIds.source]: {
    id: SecurityColumnIds.source,
    name: 'SOURCE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'source'
  }
}
