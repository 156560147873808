import { createColumnHelper } from '@tanstack/react-table'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import {
  DateCell,
  TextCell,
  USDCell,
  WidgetTable
} from '../../../features/WidgetTable/WidgetTable'
import { IActivity, IActivitySummary } from '../../../store/types'

export const Last10Activities: React.FC<{
  data?: IActivitySummary
  isUninitialized?: boolean
  isLoading?: boolean
}> = ({ data, isUninitialized, isLoading }) => {
  const last10ActivitiesData = useMemo(() => {
    const sortedActivites = orderBy(data?.activities, (x) => x.dtentry, 'desc')
    return sortedActivites?.slice(0, 10)
  }, [data])

  const columnHelper = createColumnHelper<IActivity>()

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ trndesc }) => trndesc, {
        id: 'description',
        cell: (props) => <TextCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'left' }}>Description</div>,
        minSize: 180
      }),
      columnHelper.accessor(({ trntype }) => trntype, {
        id: 'activityType',
        cell: (props) => <TextCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'left' }}>Activity Type</div>,
        size: 90
      }),
      columnHelper.accessor(({ total }) => total, {
        id: 'amount',
        cell: (props) => <USDCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'right' }}>Amount</div>,
        size: 65
      }),
      columnHelper.accessor(({ dttrade }) => dttrade, {
        id: 'activityDate',
        cell: (props) => <DateCell value={props.getValue()} />,
        header: () => <div css={{ textAlign: 'right' }}>Activity Date</div>,
        size: 95
      })
    ],
    [columnHelper]
  )

  return (
    <WidgetTable
      data={last10ActivitiesData}
      columns={columns}
      isUninitialized={isUninitialized}
      isLoading={isLoading}
    />
  )
}
