import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { IAccount } from 'api/account.types'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import {
  IGetRevenueSummaryRequest,
  INfsProfile,
  IRevenueSummaryItem
} from 'api/datahub'
import { IHousehold } from 'api/household.types'
import { subYears, format } from 'date-fns'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { constructQuery, datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer, AxiosBaseArgs } from 'store/api/shared'
import { apiConstants } from './apis'

const { cacheTime } = apiConstants

const formatDateForApi = (date: Date) =>
  format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)

type DatahubApiTagType = 'rdot360Context'
const datahubApiTags: DatahubApiTagType[] = ['rdot360Context']

const datahubWithRdot360Tags = datahubApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})
export const rdot360DatahubApi = datahubWithRdot360Tags.injectEndpoints({
  endpoints: (builder) => ({
    getIndividualsFromHousehold: builder.query<IClient[] | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdList/any(x: x/householdId eq '${id}')`,
          $count: true,
          select: [
            'LegalEntityName',
            'householdList',
            'contactdetails/emailid',
            'legalEntityType',
            'Account',
            'LegalEntityID',
            'LegalEntityName',
            'loginDetails'
          ],
          queryType: 'full'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_householdSearch: builder.query<
      ISearchResult<IHousehold> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IHousehold>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/households',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'Account',
            'noOfAccounts',
            'householdKPI',
            'Parties',
            'Advisors',
            'householdName',
            'householdId',
            'id'
          ],
          queryType: 'full',
          $top: 5,
          searchFields: 'householdName, Account, Parties/LegalEntityName'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    rdot360_clientSearch: builder.query<
      ISearchResult<IClient> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'LegalEntityName',
            'LegalEntityID',
            'ClientKPI/AumTotal',
            'loginDetails',
            'Account',
            'srcClientNumber',
            'householdList',
            'id'
          ],
          queryType: 'full',
          $top: 5,
          searchFields: ['LegalEntityName', 'Account', 'householdName']
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    rdot360_accountSearch: builder.query<
      ISearchResult<IAccount> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IAccount>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/accounts',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'CustodyAccount',
            'LegalEntityName',
            'LegalEntityID',
            'AccountKPIs/AccountTotal',
            'AccountNickname',
            'Shortname',
            'CustodianType',
            'CustodianName',
            'registrationtype',
            'gfoCustodyAccount',
            'id',
            'householdId',
            'registrationDesc'
          ],
          queryType: 'full',
          $top: 5,
          searchFields: [
            'CustodyAccount',
            'LegalEntityName',
            'AccountNickname',
            'Shortname',
            'householdName'
          ]
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getHouseholdFromId: builder.query<IHousehold | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/households',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdId eq '${id}'`,
          $count: true,
          select: [
            'noOfAccounts',
            'householdKPI',
            'Parties',
            'Advisors',
            'householdName',
            'householdId',
            'id'
          ],
          queryType: 'full',
          $top: 1
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value?.[0]
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    getAccountsFromHouseholdId: builder.query<IAccount[] | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IAccount>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/accounts',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdId eq '${id}'`,
          $count: true,
          $top: 1000,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'CustodyAccount',
            'LegalEntityName',
            'LegalEntityID',
            'AccountKPIs/AccountTotal',
            'AccountNickname',
            'Shortname',
            'CustodianType',
            'CustodianName',
            'registrationtype',
            'gfoCustodyAccount',
            'id',
            'householdId',
            'accountStatus',
            'CustodyAccount',
            'legalEntityType',
            'taxstatus',
            'accounttype',
            'registrationDesc',
            'accountId'
          ],
          queryType: 'full'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_getMonthlySummary: builder.query<
      IRevenueSummaryItem[] | undefined,
      IGetRevenueSummaryRequest
    >({
      query: ({
        tradeReps,
        recipientReps,
        catMode = 'L1',
        toDate = new Date(),
        fromDate = subYears(new Date(), 1)
      }) => ({
        url: 'revenue/GetMonthlySummary',
        method: 'post',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        },
        data: {
          tradeReps,
          recipientReps,
          CatMode: catMode,
          todate: formatDateForApi(toDate),
          fromdate: formatDateForApi(fromDate)
        }
      }),
      keepUnusedDataFor: cacheTime
    }),
    rdot360_getRelatedNfsProfiles: builder.query<
      INfsProfile[] | undefined,
      string[]
    >({
      query: (accounts) => ({
        url: 'nfsProfiles/GetRelatedNfsProfiles',
        method: 'post',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        },
        data: {
          request: {
            accounts
          }
        }
      }),
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<INfsProfile>) => x?.value
    }),
    getNFSProfile: builder.query<INfsProfile | undefined, string>({
      query: (id) => ({
        url: 'nfsProfiles',
        params: {
          $filter: `wsportaluserid eq '${id}'`,
          $top: 1,
          $select: ['profilejson', 'wsportaluserid', 'loginid'].join(',')
        },
        method: 'get',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<INfsProfile>) => x?.value?.[0]
    })
  })
})

export const {
  useGetIndividualsFromHouseholdQuery,
  useRdot360_householdSearchQuery,
  useRdot360_accountSearchQuery,
  useRdot360_clientSearchQuery,
  useGetHouseholdFromIdQuery,
  useLazyGetAccountsFromHouseholdIdQuery,
  useGetAccountsFromHouseholdIdQuery,
  useRdot360_getMonthlySummaryQuery,
  useRdot360_getRelatedNfsProfilesQuery,
  useGetNFSProfileQuery
} = rdot360DatahubApi

export const useDatahubApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DatahubApiTagType>[]) =>
      dispatch(rdot360DatahubApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
