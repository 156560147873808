import { partial } from 'lodash/fp'
import { createListsComponents } from '../core/containers/service'
import { createFilter } from '../shared/lists'
import { PositionColumnDefinitions } from './ColumnDefinitions'
import { PositionCell } from './PositionCell'
import { actions, selectors } from './store'

export * from './store'

export const createPositionListFilter = partial(createFilter, [
  PositionColumnDefinitions
])

export const PositionListComponents = createListsComponents(
  actions,
  selectors,
  PositionCell
)
