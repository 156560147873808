import { MessageBar } from '@fluentui/react'
import { groupBy, sumBy } from 'lodash'
import React from 'react'
import {
  IAUSHistoryItem,
  IAUSHistoryWithRepAndDate
} from '../../api/common.types'
import { ITimeSeriesChartSeries, TimeSeriesChart } from './TimeSeriesChart'

interface IAUSSummaryComponentProps {
  ausHistory: IAUSHistoryWithRepAndDate[]
}

const labelNameMap: { [key: string]: string } = {
  LoanOutstanding: 'LOANS',
  LoanOutstandingOutsideNfs: 'LOANS OUTSIDE NFS',
  AumManaged: 'MANAGED ASSETS',
  AumBrokerage: 'BROKERAGE ASSETS',
  Aua: 'AUA',
  HeldAway: 'HELD AWAY ASSETS',
  AdminReportingAssets: 'ADMIN REPORTING',
  annuity: 'ANNU & INS'
}

export const AUSSummaryComponent: React.FC<IAUSSummaryComponentProps> = ({
  ausHistory
}) => {
  const data: IAUSHistoryWithRepAndDate[] = Object.entries(
    groupBy(ausHistory, ({ timestamp }) => timestamp)
  ).map(([, value]) => {
    const [{ date, timestamp }] = value
    const LoanOutstanding = sumBy(value, (x) => x.LoanOutstanding || 0)
    const LoanOutstandingOutsideNfs = sumBy(
      value,
      (x) => x.LoanOutstandingOutsideNfs || 0
    )
    const AumBrokerage = sumBy(value, (x) => x.AumBrokerage || 0)
    const AumManaged = sumBy(value, (x) => x.AumManaged || 0)
    const annuity = sumBy(value, (x) => x.annuity || 0)
    const Aua = sumBy(value, (x) => x.Aua || 0)
    const HeldAway = sumBy(value, (x) => x.HeldAway || 0)
    const AdminReportingAssets = sumBy(
      value,
      (x) => x.AdminReportingAssets || 0
    )
    return {
      date,
      timestamp,
      LoanOutstanding,
      LoanOutstandingOutsideNfs,
      AumBrokerage,
      AumManaged,
      Aua,
      HeldAway,
      AdminReportingAssets,
      annuity
    }
  })

  const seriesTypes: (keyof Pick<
    IAUSHistoryItem,
    | 'LoanOutstanding'
    | 'LoanOutstandingOutsideNfs'
    | 'AumBrokerage'
    | 'AumManaged'
    | 'Aua'
    | 'HeldAway'
    | 'AdminReportingAssets'
    | 'annuity'
  >)[] = [
    'LoanOutstanding',
    'LoanOutstandingOutsideNfs',
    'AumBrokerage',
    'AumManaged',
    'Aua',
    'HeldAway',
    'AdminReportingAssets',
    'annuity'
  ]
  const series: ITimeSeriesChartSeries[] = seriesTypes
    .map((seriesType) => ({
      name: labelNameMap[seriesType],
      data: data
        .map((item) => ({
          date: item.date,
          value: item[seriesType] || 0
        }))
        .filter((x) => x.value !== 0)
    }))
    .filter(({ data }) => sumBy(data, (x) => x.value))

  return series.length ? (
    <TimeSeriesChart series={series} chartType="column" />
  ) : (
    <MessageBar>No Data Available</MessageBar>
  )
}
