import { FC, useCallback } from 'react'
import { USD } from 'shared/components/Formatting'
import { useRdot360Preferences } from '../../hooks'
import { useGetTop10HoldingsQueryForSelectedAccounts } from '../../store/rdot360Context/useRdot360HoldingsContext'
import { IndeterminateProgressIndicator } from '../shared/ProgressIndicator/IndeterminateProgressIndicator'
import TileHeading, { ViewType, BaseHeaderProps } from '../TileHeading'
import { HoldingsChart } from './HoldingsChart'
import HoldingsTable from './HoldingsTable'

const preferenceName = 'holdingsTile'
const Holdings: FC<BaseHeaderProps> = ({ onExpand }) => {
  const { getPreference, setPreference } = useRdot360Preferences<{
    [key: string]: ViewType
  }>(preferenceName, ViewType.graph)

  const viewType = getPreference(preferenceName)

  const handleViewTypeChange = useCallback(
    (value: ViewType) => {
      setPreference({ [preferenceName]: value })
    },
    [setPreference]
  )

  const {
    data: holdingsData,
    isLoading: isHoldingsLoading,
    isFetching: isHoldingsFetching,
    isUninitialized
  } = useGetTop10HoldingsQueryForSelectedAccounts()

  return (
    <>
      <div
        css={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TileHeading
          viewType={viewType}
          onViewtypeChange={handleViewTypeChange}
          graphIconType="chart"
          onExpand={onExpand}
        >
          INVESTMENTS
        </TileHeading>
        <div
          css={{
            display: 'flex',
            marginTop: '5px',
            fontSize: '12px',
            marginBottom: '8px',
            flex: '0 0 14px',
            gridColumnGap: 5
          }}
        >
          <div css={{ fontWeight: 300 }}>Top 10 Investments Total:</div>
          {holdingsData?.recentmktval ? (
            <USD value={holdingsData?.recentmktval} />
          ) : (
            '--'
          )}
        </div>
        <div css={{ flexGrow: 1, minHeight: 0 }}>
          {viewType === ViewType.graph && (
            <HoldingsChart data={holdingsData?.holdingdetails || []} />
          )}
          {viewType === ViewType.table && (
            <HoldingsTable
              data={holdingsData?.holdingdetails}
              isUninitialized={isUninitialized}
              isLoading={isHoldingsFetching}
            />
          )}
        </div>
        <div
          css={{
            fontSize: '10px',
            fontWeight: 300,
            marginTop: 6
          }}
        >
          Top 10 Investments excludes Cash and Equivalents, Annuities/Insurance,
          Loans and Other Assets/Liabilities.
        </div>
      </div>
      {(isHoldingsLoading || isHoldingsFetching) && (
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}

export default Holdings
