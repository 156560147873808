import {
  Callout,
  DefaultButton,
  FontSizes,
  IconButton,
  Link,
  makeStyles,
  Stack,
  Text
} from '@fluentui/react'
import { useClientInvitePanel } from 'features/ClientInvite/store/clientInvitePanel'
import React, { useCallback, useRef, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { isNotNullOrFalse } from 'shared/gaurds'
import { IRelatedLogin } from '../../api/profile'
import { ConnectedMasked } from '../../shared/components/MaskedText'
import { RelatedPartyMenu } from './RelatedPartyMenu'
import { useRelatedPartyNavigation } from './store'

const useClasses = makeStyles((theme) => ({
  relatedPartyButton: {
    padding: '5px',
    minHeight: '40px'
  },
  relatedPartyButtonEffects: {
    cursor: 'pointer',
    '&:hover, &.active': {
      backgroundColor: theme.palette.white,
      boxShadow: theme.effects.elevation4
    },
    '& button': {
      visibility: 'hidden'
    },
    '&:hover button, &.active button': {
      visibility: 'visible'
    }
  },
  relatedParty: {
    minWidth: 0,
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.themeLighter}`,
    borderBottom: 'none',
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.themeLighter}`
    }
  }
}))

export const RelatedPartyListItem: React.FC<{
  relatedLogin: IRelatedLogin
}> = ({ relatedLogin }) => {
  const firstName = (relatedLogin.firstname || '').trim()
  const lastName = (relatedLogin.lastname || '').trim()
  const businessName = (relatedLogin.businesstrustname || '').trim()
  const email = (relatedLogin.emailprimary || '').trim()
  const role = (relatedLogin.role || '').trim()
  const portalId = relatedLogin.portaluserid

  const displayName = businessName
    ? businessName
    : firstName === lastName
    ? lastName
    : `${firstName ? `${firstName} ` : ''}${lastName}`
  const [isCalloutVisible, setIsCalloutVisible] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useClasses()

  const { open } = useClientInvitePanel()
  const onInviteClicked = useCallback(
    (WealthscapeID: string) => {
      open(WealthscapeID || '', 'invite')
    },
    [open]
  )

  const { launchClientOnline } = useRelatedPartyNavigation()
  const onSimulateClientOnline = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      launchClientOnline({ wealthscapeId: portalId })
    },
    [launchClientOnline, portalId]
  )

  const toggleIsCalloutVisible = useCallback(
    () => setIsCalloutVisible(!isCalloutVisible),
    [isCalloutVisible]
  )

  return (
    <>
      <Stack
        verticalAlign="center"
        horizontal={true}
        horizontalAlign="space-between"
        styles={{ root: { minWidth: 0, maxWidth: '100%' } }}
        tokens={{ childrenGap: 5 }}
        className={[classes.relatedParty].filter(isNotNullOrFalse).join(' ')}
      >
        <div
          ref={containerRef}
          style={{ display: 'flex', flexGrow: 1, minWidth: 0 }}
          onClick={() => toggleIsCalloutVisible()}
          className={[
            classes.relatedPartyButton,
            classes.relatedPartyButtonEffects,
            isCalloutVisible && 'active'
          ]
            .filter(isNotNullOrFalse)
            .join(' ')}
        >
          <Stack
            horizontal={true}
            grow={1}
            horizontalAlign="space-between"
            verticalAlign="center"
            tokens={{ childrenGap: 4 }}
            styles={{ root: { minWidth: 0 } }}
          >
            <Stack styles={{ root: { minWidth: 0 } }}>
              <Stack
                horizontal={true}
                tokens={{ childrenGap: 5 }}
                styles={{ root: { minWidth: 0 } }}
              >
                <Text nowrap={true} block={true}>
                  <Link
                    href={`/clients/${portalId}`}
                    onClick={onSimulateClientOnline}
                    title={displayName}
                  >
                    <ConnectedMasked text={displayName} />
                  </Link>
                </Text>
                {role && (
                  <Text
                    variant="small"
                    styles={{ root: { fontWeight: 'bold' } }}
                  >
                    ({role})
                  </Text>
                )}
              </Stack>
              <Text
                nowrap={true}
                variant="small"
                styles={{ root: { maxWidth: '100%' } }}
              >
                {email}
              </Text>
            </Stack>

            <IconButton
              iconProps={{ iconName: 'ChevronDown' }}
              checked={isCalloutVisible}
            />
          </Stack>
        </div>

        <Stack.Item styles={{ root: { minWidth: '80px' } }}>
          {portalId && !relatedLogin.emailprimary && (
            <Stack.Item>
              <Text nowrap={true} block={true}>
                <DefaultButton
                  styles={{
                    root: {
                      padding: '3px 2px',
                      height: 'auto',
                      minWidth: 'auto'
                    },
                    label: { fontWeight: 'normal', fontSize: FontSizes.small }
                  }}
                  onClick={() => onInviteClicked(portalId || '')}
                >
                  Invite Now
                </DefaultButton>
              </Text>
            </Stack.Item>
          )}

          {relatedLogin.lastlogindate && (
            <Text nowrap={true} block={true}>
              <FormattedDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                value={new Date(relatedLogin.lastlogindate)}
              />
            </Text>
          )}
        </Stack.Item>
      </Stack>

      {isCalloutVisible && (
        <Callout
          onDismiss={() => setIsCalloutVisible(false)}
          target={containerRef}
          isBeakVisible={false}
          style={{ width: '200px' }}
        >
          <RelatedPartyMenu
            loginid={email}
            wsportaluserid={portalId}
            displayName={displayName}
            onItemClicked={() => setIsCalloutVisible(false)}
          />
        </Callout>
      )}
    </>
  )
}
