import { createReducer } from 'typesafe-actions'
import {
  ClientSettingsActions,
  fetchClientListActions,
  fetchClientSettingsDocActions,
  fetchClientSettingsListActions,
  setSelectedClientSettingActions,
  updatePaymentAccountActions,
  uploadLoaDocActions
} from './actions'
import { IClientSettings, IPaymentClient } from './types'

export interface IClientSettingsState {
  clientSettings?: {
    isLoading: boolean
    error?: Error
    list?: IClientSettings[]
  }
  selectedClientSetting?: IClientSettings
  clientSettingsDoc?: {
    isLoading: boolean
    error?: Error
    docContent?: string
  }
  clientList?: {
    isLoading: boolean
    error?: Error
    list?: IPaymentClient[]
  }
  updatePaymentAccountState?: {
    inProgress: boolean
    error?: Error
  }
  uploadLoaDocState?: {
    error?: Error
  }
}

export const clientSettingsReducer = createReducer<
  IClientSettingsState,
  ClientSettingsActions
>({})
  .handleAction(fetchClientSettingsListActions.request, (state) => ({
    ...state,
    clientSettings: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchClientSettingsListActions.success, (state, action) => ({
    ...state,
    clientSettings: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchClientSettingsListActions.failure, (state, action) => ({
    ...state,
    clientSettings: {
      ...state.clientSettings,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(setSelectedClientSettingActions.set, (state, action) => ({
    ...state,
    selectedClientSetting: action.payload
  }))
  .handleAction(fetchClientSettingsDocActions.request, (state) => ({
    ...state,
    clientSettingsDoc: {
      isLoading: true,
      error: undefined,
      docContent: undefined
    }
  }))
  .handleAction(fetchClientSettingsDocActions.success, (state, action) => ({
    ...state,
    clientSettingsDoc: {
      isLoading: false,
      error: undefined,
      docContent: action.payload
    }
  }))
  .handleAction(fetchClientSettingsDocActions.failure, (state, action) => ({
    ...state,
    clientSettingsDoc: {
      isLoading: false,
      error: action.payload,
      docContent: undefined
    }
  }))
  .handleAction(fetchClientListActions.request, (state) => ({
    ...state,
    clientList: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchClientListActions.success, (state, action) => ({
    ...state,
    clientList: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchClientListActions.failure, (state, action) => ({
    ...state,
    clientList: {
      ...state.clientList,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(updatePaymentAccountActions.request, (state) => ({
    ...state,
    updatePaymentAccountState: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updatePaymentAccountActions.success, (state) => ({
    ...state,
    updatePaymentAccountState: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(updatePaymentAccountActions.failure, (state, action) => ({
    ...state,
    updatePaymentAccountState: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(uploadLoaDocActions.request, (state) => ({
    ...state,
    uploadInvoice: {
      ...state.uploadLoaDocState,
      error: undefined
    }
  }))
  .handleAction(uploadLoaDocActions.failure, (state, action) => ({
    ...state,
    uploadInvoice: {
      ...state.uploadLoaDocState,
      error: action.payload
    }
  }))
