import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row
} from '@tanstack/react-table'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useDebounce } from 'shared/hooks/useDebounce'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { constants } from '../../../shared/theme'
import { useGetDetailActivitySummaryQuery } from '../../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../../store/rdot360Context'
import { useRdot360AccountContext } from '../../../store/rdot360Context/useRdot360AccountContext'
import { IActivity } from '../../../store/types'
import { useActivityDetailUiState } from '../activityDetailsUiState'
import { constructColumns } from './ActivityDetailsTableColumns'

const ActivityDetailsTable = () => {
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { dateRangeString, categories, searchText, setSearchText } =
    useActivityDetailUiState()
  const debouncedSearchText = useDebounce(searchText, 100)
  const { data } = useGetDetailActivitySummaryQuery(
    {
      contextAccounts: apiContextAccounts,
      categories: categories,
      dateRange: dateRangeString
    },
    {
      skip:
        !apiContextAccounts || !apiContextAccounts.length || !dateRangeString
    }
  )
  const { accountLookup } = useRdot360AccountContext()
  const columns = useMemo(
    () => constructColumns(debouncedSearchText, accountLookup),
    [accountLookup, debouncedSearchText]
  )
  const activity = useMemo(() => data?.activities || [], [data?.activities])
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'tradeDate', desc: true }
  ])
  const table = useReactTable({
    data: activity,
    columns,
    state: {
      sorting,
      globalFilter: debouncedSearchText
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onGlobalFilterChange: setSearchText,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const tableContainerOffsetRef = useRef(0)

  useLayoutEffect(() => {
    tableContainerOffsetRef.current = tableContainerRef.current?.offsetTop || 0
  }, [])

  const { rows } = table.getRowModel()

  const virtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 53,
    scrollMargin: tableContainerOffsetRef.current
  })
  const items = virtualizer.getVirtualItems()

  return (
    <HorizontalScrollContainer>
      <>
        <table
          css={[
            detailTable.detailTable,
            detailTable.leftRightPadding,
            {
              minWidth: '975px',
              position: 'sticky',
              top: constants.headerOffset,
              zIndex: 1
            }
          ]}
        >
          <thead>
            <tr>
              {table.getFlatHeaders().map((header) => {
                return (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      height: 0,
                      padding: 0,
                      margin: 0
                    }}
                  />
                )
              })}
            </tr>
            <tr>
              {table.getFlatHeaders().map((header) => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        css={{
                          cursor: 'pointer'
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          </thead>
        </table>
        <div
          ref={tableContainerRef}
          style={{
            position: 'relative',
            height: virtualizer.getTotalSize(),
            width: '100%'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              transform: `translateY(${
                items?.[0]?.start - virtualizer.options.scrollMargin
              }px)`
            }}
          >
            <table
              css={[
                detailTable.detailTable,
                detailTable.leftRightPadding,
                {
                  minWidth: '975px'
                }
              ]}
            >
              <thead>
                <tr>
                  {table.getFlatHeaders()?.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          height: 0,
                          padding: 0,
                          margin: 0
                        }}
                      />
                    )
                  })}
                </tr>
              </thead>

              <tbody>
                {items.map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<IActivity>
                  return (
                    <tr
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                      css={{ height: '50px' }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </HorizontalScrollContainer>
  )
}

export default ActivityDetailsTable
