import { useTheme } from '@emotion/react'
import { get } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { FC, ReactNode, useMemo, useState } from 'react'
import { getGainLossesIncomeDataTableClasses } from './GainLossessCustomizedDataTable.styles'

export interface IColumn {
  field: string
  headerName?: string
  sortable?: boolean
  className?: string
  width?: string
  align?: 'left' | 'right' | 'center'
  headerStyle?: object
  columnStyle?: object
  style?: object
  fixedColumn?: boolean
  format?: (value: any, row?: any) => ReactNode | string
  formatHeader?: () => ReactNode | string
}

export interface IDataTableProps {
  columns: IColumn[]
  data: any[]
}

const GainLossessCustomTableBottom: FC<IDataTableProps> = ({
  columns = [],
  data = []
}) => {
  const theme = useTheme()
  const classes = useMemo(
    () => getGainLossesIncomeDataTableClasses(theme),
    [theme]
  )
  const [tableData, setTableData] = useState<any[]>(data)
  useMemo(() => {
    setTableData(data)
  }, [data])

  return (
    <div css={[advisoryModuleStyles.fancyScroll, classes.container]}>
      <table css={classes.tableContainer}>
        <tbody>
          {tableData?.map((row, key) => (
            <tr key={key} css={classes.tableRow}>
              {columns.map(
                ({
                  field,
                  className,
                  width,
                  style,
                  columnStyle,
                  fixedColumn,
                  align = 'left',
                  format = (value) => value?.toString() || ''
                }) => (
                  <td
                    key={field}
                    className={className}
                    style={{
                      ...style,
                      backgroundColor:
                        row[columns[0].field] ===
                          'Net Realized (current year)' ||
                        row[columns[0].field] === 'Net Unrealized (cumulative)'
                          ? theme.colors.secondaryWhite2
                          : 'transparent'
                    }}
                    width={width}
                    align={align}
                    css={[
                      {
                        padding: '11px 5px',
                        fontWeight:
                          row[columns[0].field] ===
                            'Net Realized (current year)' ||
                          row[columns[0].field] ===
                            'Net Unrealized (cumulative)'
                            ? 'bold'
                            : '',
                        ...columnStyle
                      },
                      fixedColumn ? classes.fixedColumn : classes.staticColumn,
                      classes.column
                    ]}
                    title={
                      typeof get(row, field) === 'number'
                        ? get(row, field).toFixed(2)
                        : get(row, field)
                    }
                  >
                    {format(get(row, field), row)}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default GainLossessCustomTableBottom
