import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { BaseColumnDefinitions } from '../shared/BaseColumnDefinitions'
import { CommonColumnDefinitions } from '../shared/CommonColumnDefinitions'
import { CommonColumnIdsEnum } from '../shared/CommonColumnIdsEnum'

enum ColumnIdsEnum {
  accountNumber = 'accountNumber',
  accountManagedType = 'accountManagedType',
  accountRegistrationType = 'accountRegistrationType',
  accountStatus = 'accountStatus',
  accountTaxableStatus = 'accountTaxableStatus',
  accountModelName = 'accountModelName',
  accountModelType = 'accountModelType',
  mtdNetReturn = 'mtdNetReturn',
  ytdNetReturn = 'ytdNetReturn',
  qtdNetReturn = 'qtdNetReturn',
  rmdRemaining = 'rmdRemaining',
  estRMDAmount = 'estRMDAmount',
  retirementAccount = 'retirementAccount',
  lastUpdated = 'lastUpdated',
  revenueYTD = 'revenueYTD',
  revenueMTD = 'revenueMTD',
  revenueQTD = 'revenueQTD',
  revenueT12 = 'revenueT12',
  custodianName = 'custodianName',
  custodianType = 'custodianType',
  nickname = 'nickname',
  shortname = 'shortname',
  legalEntityId = 'legalEntityId',
  legalEntityType = 'legalEntityType',
  cashAvaliable = 'cashAvaliable',
  managerFeePercent = 'managerFeePercent',
  clientFeePercent = 'clientFeePercent',
  feeDebitAccount = 'feeDebitAccount',
  cashAvailableAfterNextFee = 'cashAvailableAfterNextFee',
  pendingClientFee = 'pendingClientFee',
  marginAgreement = 'marginAgreement',
  multipleMargin = 'multipleMargin',
  pendingManagedAccountFeeDebit = 'pendingManagedAccountFeeDebit',
  cashAvailableToWithdraw = 'cashAvailableToWithdraw',
  rockefellerFee = 'rockefellerFee',
  managerFee = 'managerFee',
  marginInterestRate = 'marginInterestRate'
}

export const AccountColumnIds = { ...ColumnIdsEnum, ...CommonColumnIdsEnum }
export type AccountColumnIds = typeof AccountColumnIds

export const AccountColumnDefinitions: Record<string, IColumnDefinition> = {
  ...CommonColumnDefinitions,
  [AccountColumnIds.legalEntityName]: {
    ...(BaseColumnDefinitions.legalEntityName as IColumnDefinition),
    select: ['LegalEntityName', 'srcClientNumber'],
    searchFields: ['LegalEntityName', 'srcClientNumber']
  },
  [AccountColumnIds.aum]: {
    ...(BaseColumnDefinitions.aum as IColumnDefinition),
    select: ['AccountKPIs/AumManaged', 'feeSummary/clientFeePercent', 'id'],
    type: 'number',
    dataPath: 'AccountKPIs/AumManaged'
  },
  [AccountColumnIds.aus]: {
    ...(BaseColumnDefinitions.aus as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/AccountTotal'
  },
  [AccountColumnIds.brokerageAssets]: {
    ...(BaseColumnDefinitions.brokerageAssets as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/AumBrokerage'
  },
  [AccountColumnIds.loansOutstanding]: {
    ...(BaseColumnDefinitions.loansOutstanding as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/LoanOutstanding'
  },
  [AccountColumnIds.purchasingPower]: {
    ...(BaseColumnDefinitions.purchasingPower as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/cashAvlToTrade'
  },
  [AccountColumnIds.netNewAssets]: {
    ...(BaseColumnDefinitions.netNewAssets as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/TOA'
  },
  [AccountColumnIds.netNewMoney]: {
    ...(BaseColumnDefinitions.netNewMoney as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/NetNewMoney'
  },
  [AccountColumnIds.annuity]: {
    ...(BaseColumnDefinitions.annuity as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/annuity'
  },
  [AccountColumnIds.accountNumber]: {
    id: AccountColumnIds.accountNumber,
    select: [
      'CustodyAccount',
      'VendorTamarac',
      'AccountNickname',
      'registrationtype',
      'CustodianType',
      'CustodianName',
      'gfoCustodyAccount',
      'accountStatus',
      'Shortname',
      'householdId',
      'marginInterestRate'
    ],
    searchFields: [
      'CustodyAccount',
      'AccountNickname',
      'gfoCustodyAccount',
      'Shortname'
    ],
    name: 'Account Number',
    type: 'string',
    width: 175,
    dataPath: 'CustodyAccount',
    searchable: true,
    sortable: true,
    filterable: true
  },
  [AccountColumnIds.accountManagedType]: {
    id: AccountColumnIds.accountManagedType,
    name: 'AUS Class',
    dataPath: 'accounttype',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountRegistrationType]: {
    id: AccountColumnIds.accountRegistrationType,
    name: 'Account Registration',
    dataPath: 'registrationtype',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountStatus]: {
    id: AccountColumnIds.accountStatus,
    name: 'Account Status',
    dataPath: 'accountStatus',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountTaxableStatus]: {
    id: AccountColumnIds.accountTaxableStatus,
    name: 'Taxable Status',
    dataPath: 'taxstatus',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountModelName]: {
    id: AccountColumnIds.accountModelName,
    name: 'Managed Model Name',
    dataPath: 'modelName',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountModelType]: {
    id: AccountColumnIds.accountModelType,
    name: 'Managed Model Type',
    dataPath: 'modelType',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.qtdNetReturn]: {
    id: AccountColumnIds.qtdNetReturn,
    name: 'QTD Performance',
    dataPath: 'AcctPerformance/QTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.mtdNetReturn]: {
    id: AccountColumnIds.mtdNetReturn,
    name: 'MTD Performance',
    dataPath: 'AcctPerformance/MTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.ytdNetReturn]: {
    id: AccountColumnIds.ytdNetReturn,
    name: 'YTD Performance',
    dataPath: 'AcctPerformance/YTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.rmdRemaining]: {
    id: AccountColumnIds.rmdRemaining,
    name: 'RMD Remaining',
    dataPath: 'RMD/estRMDAmountRemain',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.estRMDAmount]: {
    id: AccountColumnIds.estRMDAmount,
    name: 'RMD Amount',
    dataPath: 'RMD/estRMDAmount',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.lastUpdated]: {
    id: AccountColumnIds.lastUpdated,
    name: 'Last Updated Date',
    dataPath: 'LastUpdatedAt',
    type: 'date',
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.retirementAccount]: {
    id: AccountColumnIds.retirementAccount,
    name: 'Retirement Account',
    dataPath: 'retirementAccount',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.revenueMTD]: {
    id: AccountColumnIds.revenueMTD,
    name: 'MTD Revenue',
    dataPath: 'revenueDet/MTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueQTD]: {
    id: AccountColumnIds.revenueQTD,
    name: 'QTD Revenue',
    dataPath: 'revenueDet/QTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueT12]: {
    id: AccountColumnIds.revenueT12,
    name: 'T12 Revenue',
    dataPath: 'revenueDet/ttmrevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueYTD]: {
    id: AccountColumnIds.revenueYTD,
    name: 'YTD Revenue',
    dataPath: 'revenueDet/YTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.custodianName]: {
    id: AccountColumnIds.custodianName,
    name: 'Custodian Name',
    dataPath: 'CustodianName',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.custodianType]: {
    id: AccountColumnIds.custodianType,
    name: 'Custodian Type',
    dataPath: 'CustodianType',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.nickname]: {
    id: AccountColumnIds.nickname,
    name: 'Nickname',
    dataPath: 'AccountNickname',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.shortname]: {
    id: AccountColumnIds.shortname,
    name: 'Short Name',
    dataPath: 'Shortname',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.legalEntityId]: {
    id: AccountColumnIds.legalEntityId,
    select: ['LegalEntityID', 'LegalEntityName'],
    name: 'Legal Entity Id',
    dataPath: 'LegalEntityID',
    type: 'string',
    filterable: true,
    selectable: false
  },
  [AccountColumnIds.legalEntityType]: {
    id: AccountColumnIds.legalEntityType,
    name: 'Entity Type',
    dataPath: 'legalEntityType',
    width: 100,
    type: 'string',
    filterable: true,
    selectable: true,
    searchable: false,
    facetable: true
  },
  [AccountColumnIds.cashAvaliable]: {
    id: AccountColumnIds.cashAvaliable,
    name: 'Cash Avaliable',
    dataPath: 'AccountKPIs/cashAvlToTrade',
    width: 100,
    type: 'string',
    filterable: true,
    selectable: false,
    searchable: false
  },
  [AccountColumnIds.clientFeePercent]: {
    id: AccountColumnIds.clientFeePercent,
    name: 'Client Fee Percent',
    dataPath: 'feeSummary/clientFeePercent',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.managerFeePercent]: {
    id: AccountColumnIds.managerFeePercent,
    name: 'Manager Fee Percent',
    dataPath: 'feeSummary/managerFeePercent',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.feeDebitAccount]: {
    id: AccountColumnIds.feeDebitAccount,
    name: 'Alternate Account for Client Fee Debit',
    dataPath: 'feeSummary/debitAccount',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.cashAvailableAfterNextFee]: {
    id: AccountColumnIds.cashAvailableAfterNextFee,
    name: 'Remaining Cash After Managed Account Fee',
    dataPath: 'feeSummary/cashAfterFees',
    type: 'number',
    width: 140,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.pendingClientFee]: {
    id: AccountColumnIds.pendingClientFee,
    name: 'Pending Client Fee',
    dataPath: 'feeSummary/clientFee',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.cashAvailableToWithdraw]: {
    id: AccountColumnIds.cashAvailableToWithdraw,
    name: 'Cash Available To Withdraw',
    dataPath: 'AccountKPIs/cashAvlToWithDraw',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.rockefellerFee]: {
    id: AccountColumnIds.rockefellerFee,
    name: 'Pending Rockefeller Fee',
    dataPath: 'feeSummary/rockefellerFee',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.managerFee]: {
    id: AccountColumnIds.managerFee,
    name: 'Pending Manager Fee',
    dataPath: 'feeSummary/managerFee',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.pendingManagedAccountFeeDebit]: {
    id: AccountColumnIds.pendingManagedAccountFeeDebit,
    select: ['feeSummary/clientFee', 'feeSummary/totalPendingAccountDebit'],
    name: 'Total Pending Debit (Client Fee)',
    dataPath: 'feeSummary/totalPendingAccountDebit',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.marginAgreement]: {
    id: AccountColumnIds.marginAgreement,
    name: 'Margin Agreement',
    dataPath: 'marginAgreement',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 75
  },
  [AccountColumnIds.multipleMargin]: {
    id: AccountColumnIds.multipleMargin,
    name: 'Multiple Margin',
    dataPath: 'multipleMargin',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 160
  },
  [AccountColumnIds.marginInterestRate]: {
    id: AccountColumnIds.marginInterestRate,
    name: 'Custom Margin Interest Spread',
    dataPath: 'marginInterestRate',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  }
}
