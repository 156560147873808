import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const ProgressBarIndicator = styled.div({
  backgroundColor: 'rgb(209, 213, 219)',
  borderRadius: '8px',
  height: '3px',
  position: 'relative',
  overflow: 'hidden'
})

const ProgressBarIndicatorkeyframes = keyframes`
from {
    left: -50%;
}
to {
    left: 100%;
}
`

const ProgressBarProgress = styled.div({
  backgroundColor: 'rgb(59, 130, 246)',
  borderRadius: '8px',
  position: 'absolute',
  bottom: '0px',
  top: '0px',
  width: '50%',
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationName: ProgressBarIndicatorkeyframes
})

export const IndeterminateProgressIndicator = () => {
  return (
    <ProgressBarIndicator>
      <ProgressBarProgress />
    </ProgressBarIndicator>
  )
}
