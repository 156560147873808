import { SortDirection } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Icon, IconType } from '../../../features/Icons/Icon'

export const HeaderContainer: React.FC<{
  title?: string
  rightAlign?: boolean
  isSorted?: false | SortDirection
}> = ({ title, rightAlign, isSorted }) => {
  const iconType: IconType = useMemo(() => {
    switch (isSorted) {
      case 'asc':
        return 'SortColumnAsc'
      case 'desc':
        return 'SortColumnDesc'

      default:
        return 'SortColumn'
    }
  }, [isSorted])
  return (
    <div
      css={{
        display: 'flex',
        columnGap: 5,
        justifyContent: rightAlign ? 'end' : 'start'
      }}
    >
      {title}
      <div css={{ cursor: 'pointer', marginTop: '1px' }}>
        <Icon
          type={iconType}
          color="black"
          height={11}
          width={11}
          viewBox="0 0 12 12"
        />
      </div>
    </div>
  )
}
