import { MessageBar, Stack } from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { getAccountLinkingLinkedAccounts } from '../../store/accountLinkingValidation'
import { DelinkingListItem } from './DelinkingListItem'

export const DelinkingList: React.FC<{
  addAccount: (account: string) => void
  removeAccount: (account: string) => void
  delinkingAccounts?: string[]
}> = ({ addAccount, removeAccount, delinkingAccounts }) => {
  const linkedAccounts = useSelector(getAccountLinkingLinkedAccounts)

  return linkedAccounts.length ? (
    <Stack tokens={{ childrenGap: 15 }}>
      {linkedAccounts?.map((x, index) => (
        <DelinkingListItem
          account={x}
          addAccount={addAccount}
          removeAccount={removeAccount}
          isChecked={delinkingAccounts?.includes(x) || false}
          key={index}
        />
      ))}
    </Stack>
  ) : (
    <MessageBar>Select Client to View Linked Accounts</MessageBar>
  )
}
