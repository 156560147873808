import { combineReducers, Reducer } from 'redux'
import { dashboardReducer } from '../features/Dashboard/store/reducer'

import {
  IWireState,
  wireReducer
} from '../features/MoneyMovement/store/reducer'
export interface ITransferModuleState {
  dashboard: ReturnType<typeof dashboardReducer>
  wire: IWireState
}

export const transferReducer: Reducer<ITransferModuleState> = combineReducers({
  dashboard: dashboardReducer,
  wire: wireReducer
})
