import { IComboBoxOption } from '@fluentui/react'
import { format } from 'date-fns'
import { IPlanProvider, IRetirementProductCashReceipt } from '../../store/types'
import { IAllCashReceipt, IProductType } from './types'

export const filterCashReceiptsByText = (
  records: IAllCashReceipt[] | null | undefined,
  text: string | null | undefined
) => {
  let filtered: IAllCashReceipt[] = records || []
  if (records && text && Array.isArray(records)) {
    const lowerCaseText = text.toLowerCase()
    filtered = records.filter((p) => {
      if (
        p?.accountnumber?.toLowerCase()?.includes(lowerCaseText) ||
        p?.trantype?.toLowerCase()?.includes(lowerCaseText) ||
        format(new Date(p?.asofdate || ''), 'MM/dd/yyyy')?.startsWith(text) ||
        p?.amount?.toFixed(2)?.toString()?.startsWith(text) ||
        p?.accountname?.toLowerCase()?.startsWith(lowerCaseText) ||
        p?.totalsplitamount?.toFixed(2)?.toString()?.startsWith(text) ||
        p?.offsetaccount?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterCashReceiptsByProduct = (
  records: IAllCashReceipt[] | null | undefined,
  id: string | null | undefined
) => {
  let filtered: IAllCashReceipt[] = records || []
  if (records && id && Array.isArray(records)) {
    filtered = records.filter(
      (x) => x?.selectedproducttypeid?.includes(Number(id)) || false
    )
  }
  return filtered
}

export const filterCashReceiptsByPlanProvider = (
  records: IAllCashReceipt[] | null | undefined,
  id: string | null | undefined
) => {
  let filtered: IAllCashReceipt[] = records || []
  if (records && id && Array.isArray(records)) {
    filtered = records.filter((x) => x.planproviderid === Number(id))
  }
  return filtered
}

export const assignProviderOptions = (
  localAllCashReceipt: IAllCashReceipt[],
  products: IProductType[],
  planProviders: IPlanProvider[]
) => {
  const copyShallowLocal = localAllCashReceipt ? [...localAllCashReceipt] : []
  copyShallowLocal?.forEach((item, index) => {
    if (
      item.selectedproducttypeid &&
      item.selectedproducttypeid?.length === 1
    ) {
      const selectedproducttypeid = item.selectedproducttypeid[0]
      const producttype = products?.filter(
        (p) => p.producttypeid === selectedproducttypeid
      )

      if (producttype && producttype.length > 0) {
        const planProvidersOptions = planProviders
          ?.filter((p) => p.producttype === producttype?.[0].name?.toString())
          .map((x) => ({
            key: x.planproviderid || 0,
            text: x.providername || ''
          }))
        const newItem: IAllCashReceipt = {
          ...item,
          planProvidersOptions: planProvidersOptions
        }
        copyShallowLocal.splice(index, 1, newItem)
      } else {
        const planProvidersOptions = planProviders.map((x) => ({
          key: x.planproviderid || 0,
          text: x.providername || ''
        }))
        const newItem: IAllCashReceipt = {
          ...item,
          planProvidersOptions: planProvidersOptions
        }
        copyShallowLocal.splice(index, 1, newItem)
      }
    } else {
      const newItem: IAllCashReceipt = {
        ...item,
        planProvidersOptions: []
      }
      copyShallowLocal.splice(index, 1, newItem)
    }
  })

  return copyShallowLocal
}
export const getOriginalPendingCashReceipt = (
  receipt: IRetirementProductCashReceipt,
  receipts: IRetirementProductCashReceipt[]
) => {
  let original: IRetirementProductCashReceipt | undefined = undefined
  for (let i = 0; i < receipts?.length; i++) {
    if (receipts[i]?.cashreceiptid === receipt?.cashreceiptid) {
      original = receipts[i]
      break
    }
  }

  return original
}
export const getProductTypeName = (
  productTypes: IProductType[],
  selectedproducttypeid: number[]
) => {
  if (selectedproducttypeid?.length === 0) {
    return '-'
  } else if (
    selectedproducttypeid?.length &&
    selectedproducttypeid?.length > 1
  ) {
    return 'Multiple'
  } else {
    const product: IProductType[] = productTypes?.filter(
      (y) => y.producttypeid === selectedproducttypeid[0]
    )
    if (product.length === 1) {
      return product[0].name
    } else {
      return '-'
    }
  }
}
export const getPlanProviderName = (
  planProviderOptions: IComboBoxOption[],
  selectedproducttypeid: number[],
  planProviderid?: string
) => {
  if (selectedproducttypeid?.length === 0) {
    return '-'
  } else if (
    selectedproducttypeid?.length &&
    selectedproducttypeid?.length > 1
  ) {
    return 'Multiple'
  } else {
    const planProvider: IComboBoxOption[] = planProviderOptions?.filter(
      (y) => y.key.toString() === planProviderid
    )
    if (planProvider.length === 1) {
      return planProvider[0].text
    } else {
      return ''
    }
  }
}
export const getAsOfDate = (dateValue?: string) => {
  if (dateValue && dateValue !== null) {
    const dateObj = new Date(dateValue)
    return (
      dateObj.getMonth() +
      1 +
      '/' +
      dateObj.getUTCDate() +
      '/' +
      dateObj.getUTCFullYear()
    )
  } else {
    return ''
  }
}
