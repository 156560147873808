import { css, useTheme } from '@emotion/react'
import { useCallback, useState } from 'react'
import { Icon } from '../../features/Icons/Icon'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { Dropdown, Error } from '../shared'
import DetailsNavigator from '../shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../shared/DetailTables/Searchbox'
import { IDropdownOption } from '../shared/Dropdown/Dropdown'
import BalanceSummaryDetails from './BalanceSummaryDetails'
import { BalanceSummaryTable } from './BalanceSummaryTable'
import { balancesDetailTableGroupingColumnNames } from './BalanceSummaryTableColumns'

const options = Object.entries(balancesDetailTableGroupingColumnNames).map(
  ([, value]) => ({ key: value, text: value })
)

const useBalanceSummaryClassess = () => ({
  headerContainer: css({
    display: 'flex',
    alignItems: 'flex-end',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: 10
  }),
  viewByContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  viewByLabel: css({
    fontSize: '14px',
    fontWeight: 500
  }),
  viewByDropdown: css({
    fontSize: '14px',
    width: '246px',
    marginTop: '8px'
  }),
  searchBox: css({
    maxWidth: '246px',
    marginLeft: 'auto'
  }),
  refreshIcon: css({
    width: 24,
    height: 24,
    marginBottom: 14,
    cursor: 'pointer',
    marginLeft: 10
  }),
  subHeaderContainer: css({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end'
  })
})

const BalanceSummaryDetailView = () => {
  const {
    isError,
    refetch: refetchBalanceDetailsData,
    invalidateCache
  } = useRdot360BalancesContext()
  const { searchText, setSearchText } = useBalanceSummaryDetailsUiState()
  const [viewType, setViewType] = useState(options[0])

  const onViewTypeFilterChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setViewType(option as any)
      }
    },
    []
  )
  const classess = useBalanceSummaryClassess()
  const theme = useTheme()
  return (
    <div>
      <div css={classess.headerContainer}>
        <DetailsNavigator />
        <BalanceSummaryDetails />
        <div css={classess.subHeaderContainer}>
          <div css={classess.viewByContainer}>
            <div css={classess.viewByLabel}>View By</div>
            <div css={classess.viewByDropdown}>
              <Dropdown
                options={options}
                defaultSelectedOption={viewType}
                onChange={onViewTypeFilterChange}
              />
            </div>
          </div>
          <div css={classess.searchBox}>
            <Searchbox searchText={searchText} onChange={setSearchText} />
          </div>
          <div
            css={{
              width: 24,
              height: 24,
              marginBottom: 14,
              cursor: 'pointer',
              marginLeft: 10
            }}
          >
            <Icon
              type="Refresh"
              color={theme.colors.extraBlue2}
              onClick={invalidateCache}
            />
          </div>
        </div>
      </div>
      <div>
        <BalanceSummaryTable viewType={viewType?.key} />
        {isError && <Error refetch={refetchBalanceDetailsData} />}
      </div>
    </div>
  )
}

export default BalanceSummaryDetailView
