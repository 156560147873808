import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { BaseColumnDefinitions } from '../shared/BaseColumnDefinitions'
import { CommonColumnDefinitions } from '../shared/CommonColumnDefinitions'
import { CommonColumnIdsEnum } from '../shared/CommonColumnIdsEnum'

enum ColumnIdsEnum {
  wealthscapeId = 'wealthscapeId',
  invitedEmail = 'invitedEmail',
  isEnrolledInMFA = 'isEnrolledInMFA',
  isEnrolledInEauth = 'isEnrolledInEauth',
  wealthscapeEmail = 'wealthscapeEmail',
  wealthscapePhone = 'wealthscapePhone',
  dateOfBirth = 'dateOfBirth',
  lastLogin = 'lastLogin',
  lastUpdated = 'lastUpdated',
  srcClientNumber = 'srcClientNumber',
  pricedInvestments = 'pricedInvestments',
  taxState = 'taxState',
  familyGroupName = 'familyGroupName',
  clientAssociate = 'clientAssociate',
  accountant = 'accountant',
  entityType = 'entityType',
  cashBalances = 'cashBalances',
  revenueYTD = 'revenueYTD',
  revenueMTD = 'revenueMTD',
  revenueQTD = 'revenueQTD',
  revenueT12 = 'revenueT12',
  partyStatus = 'partyStatus',
  accreditedInvestor = 'accreditedInvestor',
  qualifiedClient = 'qualifiedClient',
  qualifiedPurchaser = 'qualifiedPurchaser',
  legalEntityId = 'LegalEntityID'
}

export const ClientColumnIds = { ...ColumnIdsEnum, ...CommonColumnIdsEnum }
export type ClientColumnIds = typeof ClientColumnIds

export const ClientColumnDefinitions: Record<string, IColumnDefinition> = {
  ...CommonColumnDefinitions,
  [ClientColumnIds.legalEntityName]: {
    ...(BaseColumnDefinitions.legalEntityName as IColumnDefinition),
    select: [
      'LegalEntityID',
      'LegalEntityName',
      'legalEntityType',
      'dateofBirth',
      'Account',
      'loginDetails',
      'investors',
      'srcClientNumber',
      'ClientAdvisorID',
      'contactdetails/emailid',
      'clientServices',
      'householdId'
    ],
    searchFields: ['LegalEntityName', 'srcClientNumber']
  },
  [ClientColumnIds.aum]: {
    ...(BaseColumnDefinitions.aum as IColumnDefinition),
    dataPath: 'ClientKPI/AumManaged'
  },
  [ClientColumnIds.aus]: {
    ...(BaseColumnDefinitions.aus as IColumnDefinition),
    dataPath: 'ClientKPI/AumTotal'
  },
  [ClientColumnIds.brokerageAssets]: {
    ...(BaseColumnDefinitions.brokerageAssets as IColumnDefinition),
    dataPath: 'ClientKPI/AumBrokerage'
  },
  [ClientColumnIds.loansOutstanding]: {
    ...(BaseColumnDefinitions.loansOutstanding as IColumnDefinition),
    dataPath: 'ClientKPI/LoanOutstanding'
  },
  [ClientColumnIds.purchasingPower]: {
    ...(BaseColumnDefinitions.purchasingPower as IColumnDefinition),
    dataPath: 'ClientKPI/cashAvlToTrade'
  },
  [ClientColumnIds.netNewAssets]: {
    ...(BaseColumnDefinitions.netNewAssets as IColumnDefinition),
    dataPath: 'ClientKPI/TOA'
  },
  [ClientColumnIds.netNewMoney]: {
    ...(BaseColumnDefinitions.netNewMoney as IColumnDefinition),
    dataPath: 'ClientKPI/NetNewMoney'
  },
  [ClientColumnIds.annuity]: {
    ...(BaseColumnDefinitions.annuity as IColumnDefinition),
    type: 'number',
    dataPath: 'ClientKPI/annuity'
  },
  [ClientColumnIds.wealthscapeId]: {
    id: ClientColumnIds.wealthscapeId,
    name: 'Wealthscape User Id',
    type: 'string',
    filterable: true,
    sortable: false,
    searchable: true,
    collectionPath: 'loginDetails',
    dataPath: 'WealthscapeID'
  },
  [ClientColumnIds.isEnrolledInEauth]: {
    id: ClientColumnIds.isEnrolledInEauth,
    name: 'Enrolled in eAuth',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'isEnrolledInEauth'
  },

  [ClientColumnIds.isEnrolledInMFA]: {
    id: ClientColumnIds.isEnrolledInMFA,
    name: 'Enrolled in MFA',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'isEnrolledInMFA'
  },
  [ClientColumnIds.invitedEmail]: {
    id: ClientColumnIds.invitedEmail,
    name: 'Online Id / Last Login',
    type: 'string',
    width: 220,
    filterable: true,
    sortable: false,
    searchable: true,
    collectionPath: 'loginDetails',
    dataPath: 'loginid',
    select: ['legalEntityType', 'loginDetails']
  },
  [ClientColumnIds.wealthscapeEmail]: {
    id: ClientColumnIds.wealthscapeEmail,
    name: 'Email',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 220,
    dataPath: 'contactdetails/emailid'
  },
  [ClientColumnIds.wealthscapePhone]: {
    id: ClientColumnIds.wealthscapePhone,
    name: 'Phone Number',
    type: 'string',
    width: 100,
    filterable: true,
    collectionPath: 'contactdetails/phones',
    dataPath: 'telNumber'
  },
  [ClientColumnIds.dateOfBirth]: {
    id: ClientColumnIds.dateOfBirth,
    name: 'Date of Birth',
    type: 'date-only',
    filterable: true,
    sortable: true,
    width: 80,
    dataPath: 'dateofBirth'
  },
  [ClientColumnIds.lastLogin]: {
    id: ClientColumnIds.lastLogin,
    name: 'Last Login',
    type: 'date',
    filterable: true,
    sortable: false,
    width: 80,
    collectionPath: 'loginDetails',
    dataPath: 'lastLogin'
  },
  [ClientColumnIds.lastUpdated]: {
    id: ClientColumnIds.lastUpdated,
    name: 'Last Updated Date',
    type: 'date',
    filterable: true,
    sortable: true,
    dataPath: 'LastUpdatedAt'
  },
  [ClientColumnIds.srcClientNumber]: {
    id: ClientColumnIds.srcClientNumber,
    name: 'Client Number',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: false,
    searchable: true,
    dataPath: 'srcClientNumber'
  },
  [ClientColumnIds.taxState]: {
    id: ClientColumnIds.taxState,
    name: 'Tax State',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: false,
    dataPath: 'TaxState'
  },
  [ClientColumnIds.familyGroupName]: {
    id: ClientColumnIds.familyGroupName,
    name: 'Family Group Name',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    dataPath: 'householdName'
  },
  [ClientColumnIds.clientAssociate]: {
    id: ClientColumnIds.clientAssociate,
    name: 'Client Associate',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    dataPath: 'ClientAssociate'
  },
  [ClientColumnIds.accountant]: {
    id: ClientColumnIds.accountant,
    name: 'Accountant',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    dataPath: 'ClientAccountant'
  },
  [ClientColumnIds.entityType]: {
    id: ClientColumnIds.entityType,
    name: 'Entity Type',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: false,
    searchable: false,
    facetable: true,
    dataPath: 'legalEntityType'
  },
  [ClientColumnIds.cashBalances]: {
    id: ClientColumnIds.cashBalances,
    name: 'Cash Balance',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: false,
    searchable: false,
    dataPath: 'ClientKPI/cashAvlToTrade'
  },
  [ClientColumnIds.revenueMTD]: {
    id: ClientColumnIds.revenueMTD,
    name: 'MTD Revenue',
    dataPath: 'revenueDet/MTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [ClientColumnIds.revenueQTD]: {
    id: ClientColumnIds.revenueQTD,
    name: 'QTD Revenue',
    dataPath: 'revenueDet/QTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [ClientColumnIds.revenueT12]: {
    id: ClientColumnIds.revenueT12,
    name: 'T12 Revenue',
    dataPath: 'revenueDet/ttmrevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [ClientColumnIds.revenueYTD]: {
    id: ClientColumnIds.revenueYTD,
    name: 'YTD Revenue',
    dataPath: 'revenueDet/YTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [ClientColumnIds.partyStatus]: {
    id: ClientColumnIds.partyStatus,
    name: 'Party Status',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'partyStatus'
  },
  [ClientColumnIds.accreditedInvestor]: {
    id: ClientColumnIds.accreditedInvestor,
    name: 'Accredited Investor',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'accreditedInvestor'
  },
  [ClientColumnIds.qualifiedClient]: {
    id: ClientColumnIds.qualifiedClient,
    name: 'Qualified Client',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'qualifiedClient'
  },
  [ClientColumnIds.qualifiedPurchaser]: {
    id: ClientColumnIds.qualifiedPurchaser,
    name: 'Qualified Purchaser',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'qualifiedPurchaser'
  },
  [ClientColumnIds.legalEntityId]: {
    id: ClientColumnIds.legalEntityId,
    name: 'Legal Entity ID',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: false,
    searchable: false,
    dataPath: 'LegalEntityID'
  }
}
