import { useMemo } from 'react'
import {
  GetChartEstimatedIncomeSummary,
  GetChartProjectedIncomeSummary
} from '../../../store/types'
import { IncomeType, getDates } from './util'

export type ChartDataItem = {
  name: string
  data: number[]
  colorIndex: number
  color?: string
}

export type ChartData = {
  chartData: ChartDataItem[]
  dates: string[]
  total: string[]
}

const useProjectedIncomeChartData = (
  includeOptionPremiums: boolean,
  incomeType: IncomeType,
  financialYear: number,
  data?: GetChartEstimatedIncomeSummary | GetChartProjectedIncomeSummary
) => {
  const chartData: ChartData = useMemo((): ChartData => {
    const interestDataList: number[] = []
    const dividendDataList: number[] = []
    const principalDataList: number[] = []
    const optionPremiumsPaidList: number[] = []
    const optionPremiumsReceivedList: number[] = []
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const dates: string[] = getDates(incomeType, financialYear)
    const total: string[] = []
    data?.incomeSubTotal &&
      dates?.forEach((date: string) => {
        const dateParts = date.split(' ')
        const monthMName = dateParts[0]
        const year = +dateParts[1]

        const val =
          data.incomeSubTotal.find(
            (item: any) =>
              months.indexOf(monthMName) + 1 ===
                +(item.monSeq || item.monseq) &&
              (incomeType === 'historical' ? year === item.year % 100 : true)
          ) || {}

        interestDataList.push(
          incomeType === 'historical'
            ? val.interestReceived || 0
            : val.interest || 0
        )
        dividendDataList.push(val.dividend || 0)
        principalDataList.push(val.principal || val.capGain || 0)
        optionPremiumsPaidList.push(val.optionPremiumsPaid || 0)
        optionPremiumsReceivedList.push(val.optionPremiumsReceived || 0)
        total.push(
          val.interestReceived ||
            0 + val.dividend ||
            0 + val.principal ||
            0 + val.optionPremiumsPaid ||
            0 + val.optionPremiumsReceived ||
            0
        )
      })

    const chartData: ChartDataItem[] = []
    const hasValue = (item: number) => !!item

    if (interestDataList.some(hasValue, 0)) {
      chartData.push({
        name: 'Interest',
        data: interestDataList,
        colorIndex: 0,
        color: '#45BEAA'
      })
    }

    if (dividendDataList.some(hasValue, 0)) {
      chartData.push({
        name: 'Dividends',
        data: dividendDataList,
        colorIndex: 1,
        color: '#3E8DDD'
      })
    }

    if (principalDataList.some(hasValue, 0)) {
      chartData.push({
        name: 'Principal',
        data: principalDataList,
        colorIndex: 2,
        color: '#7BC46C'
      })
    }

    if (includeOptionPremiums) {
      if (optionPremiumsPaidList.some(hasValue, 0)) {
        chartData.push({
          name: 'Option Premiums Paid',
          data: optionPremiumsPaidList,
          colorIndex: 3,
          color: '#C4E1B2'
        })
      }

      if (optionPremiumsReceivedList.some(hasValue, 0)) {
        chartData.push({
          name: 'Option Premiums Received',
          data: optionPremiumsReceivedList,
          colorIndex: 4,
          color: '#7BC46C'
        })
      }
    }

    return { chartData, dates, total }
  }, [incomeType, financialYear, data, includeOptionPremiums])

  return chartData
}

export default useProjectedIncomeChartData
