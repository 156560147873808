import { intersectionWith } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { call, put, takeLatest } from 'typed-redux-saga'
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer
} from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { ISearchResult } from '../../../api/common.types'
import { IHouseholdChangeRequest } from '../../../api/households'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import { isNotNullOrFalse, isNotNullOrUndefined } from '../../../shared/gaurds'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

const OPEN_PANEL = '@modules/@households/@changeRequestsDetail/@ui/OPEN_PANEL'
const CLOSE_PANEL = '@modules/@households/@changeRequestsDetail/@ui/CLOSE_PANEL'

export const changeRequestsDetailUiActions = {
  openPanel: createAction(OPEN_PANEL)<IHouseholdChangeRequest>(),
  closePanel: createAction(CLOSE_PANEL)()
}

export type ChangeRequestDetailActionTypes = ActionType<
  typeof changeRequestsDetailUiActions
>

export interface IChangeRequestDetailUiState {
  changeRequest?: IHouseholdChangeRequest
}

const changeRequestDetailUiInitialState: IChangeRequestDetailUiState = {}

const changeRequestDetailUiReducer = createReducer<
  IChangeRequestDetailUiState,
  ChangeRequestDetailActionTypes
>(changeRequestDetailUiInitialState)
  .handleAction(changeRequestsDetailUiActions.openPanel, (state, action) => ({
    ...state,
    changeRequest: action.payload
  }))
  .handleAction(changeRequestsDetailUiActions.closePanel, (state) => ({
    ...state,
    changeRequest: undefined
  }))

export interface IAccountRequestPayload {
  accounts?: string[]
  households?: string[]
}

const accountActions = createAsyncAction(
  '@modules/@households/@changeRequestsDetail/@accounts/REQUEST',
  '@modules/@households/@changeRequestsDetail/@accounts/SUCCESS',
  '@modules/@households/@changeRequestsDetail/@accounts/FAILURE'
)<IAccountRequestPayload, IAccount[], Error>()

const accountReducer = createAsyncReducer(accountActions)

export const changeRequestDetailReducer = combineReducers({
  ui: changeRequestDetailUiReducer,
  accounts: accountReducer
})

const rootSelector = (state: AppState) =>
  state.modules.households.changeRequestDetail

export const getChangeRequestDetailUiState = flow(rootSelector, (x) => x.ui)
export const getChangeRequestDetailUiItem = flow(
  getChangeRequestDetailUiState,
  (x) => x.changeRequest
)

export const {
  getError: getAccountsError,
  getIsLoading: getIsAccountsLoading,
  getResult: getAccountsResult
} = createAsyncSelectors(flow(rootSelector, (x) => x.accounts))

export const getChangeRequestRequestedAccounts = createSelector(
  [getChangeRequestDetailUiItem, getAccountsResult],
  (request, accounts) =>
    intersectionWith(
      accounts,
      request?.accounts || [],
      (a, b) => a.CustodyAccount === b.accountNumber
    )
)

export const getChangeRequestHouseholdAccounts = createSelector(
  [getChangeRequestDetailUiItem, getAccountsResult],
  (request, accounts) =>
    accounts?.filter((x) => x.householdId === request?.targetHouseholdId)
)

const fetchAccounts = function* (
  action: ReturnType<typeof accountActions.request>
) {
  try {
    const { accounts, households } = action.payload

    const result: ISearchResult<IAccount> = yield call(
      search,
      'account' as const,
      {
        orderBy: [
          { dataPath: 'AccountKPIs/AccountTotal', direction: 'desc' as const }
        ],
        filters: [
          {
            or: [
              !!accounts?.length && {
                operator: OdataFilterOperatorEnum.searchin,
                value: accounts,
                path: 'id',
                type: 'string' as const
              },
              !!households?.length && {
                operator: OdataFilterOperatorEnum.searchin,
                value: households,
                path: 'householdId',
                type: 'string' as const
              }
            ].filter(isNotNullOrFalse)
          }
        ]
      }
    )
    const items = result?.value

    if (!items) {
      throw new Error('Household not found')
    }

    yield put(accountActions.success(items))
  } catch (e: any) {
    yield put(accountActions.failure(e))
  }
}

export const changeRequestDetailSagas = [
  () =>
    takeLatest(
      changeRequestsDetailUiActions.openPanel,
      function* (
        action: ReturnType<typeof changeRequestsDetailUiActions.openPanel>
      ) {
        yield put(
          accountActions.request({
            accounts: action?.payload?.accounts
              ?.map(({ accountNumber }) => accountNumber)
              .filter(isNotNullOrUndefined),
            households: [action?.payload?.targetHouseholdId].filter(
              isNotNullOrUndefined
            )
          })
        )
      }
    ),
  () => takeLatest(accountActions.request, fetchAccounts)
]
