import { DetailsListLayoutMode, Stack, Text } from '@fluentui/react'
import { useDomainStore } from 'features/Domain/store/domain'
import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { PositionCell } from 'features/Lists/positions/PositionCell'
import { constants } from 'modules/Advisory/shared/theme'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { LoadingComponent } from 'shared/components/Loading'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { IListsUiState } from '../../../../../../../../features/Lists/core/contracts/IListsUIState'
import { actions, selectors } from './store'

const initialUiState: IListsUiState = {
  orderBy: {
    columnId: PositionColumnIds.capitalCommitment,
    direction: 'desc'
  }
}
const AIPositionListComponents = createListsComponents(
  actions,
  selectors,
  PositionCell
)

export const AIPositionList: React.FC = () => {
  const { setConfig } = actions.uiActions
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setConfig(initialUiState))
  }, [dispatch, setConfig])
  const { isFetching } = useDomainStore()

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <AIPositionListComponents.ListsSearch />
        <Stack.Item grow={1}>
          <AIPositionListComponents.ListsFilterStatusList />
        </Stack.Item>

        <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
          <AIPositionListComponents.ListsResultsCount /> Results
        </Text>
      </Stack>
      <Stack.Item>
        <Stack>
          <AIPositionListComponents.ListsCommandBar />
          <AIPositionListComponents.ListsProgress />
          {isFetching && <LoadingComponent />}
          <HorizontalScrollContainer>
            <AIPositionListComponents.ListsInfiniteScrollDataTable
              layoutMode={DetailsListLayoutMode.fixedColumns}
              stickyHeaderOffset={constants.headerHeight}
            />
          </HorizontalScrollContainer>
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
