import { format } from 'date-fns'
import { toLower } from 'lodash'
import { USD } from 'shared/components/Formatting'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { revenueTable } from './RevenueTable'
import { useRevenueTable } from './revenueTableState'

export const RevenueContainer: React.FC = () => {
  const { monthHeadings, tableItems, tableTotals } = useRevenueTable()
  const mostRecentClosedDate = new Date()
  const getOpenOrClosedForDate = (date?: Date) =>
    date && mostRecentClosedDate && date <= mostRecentClosedDate
      ? 'Closed'
      : 'Open'

  return (
    <div>
      <DetailsNavigator />
      <table css={revenueTable.table}>
        <thead>
          <tr>
            <th>
              <div css={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>Category</div>
              </div>
            </th>
            <th className="categoryTotal">Total</th>
            {monthHeadings?.map((month, index) => {
              const openOrClosed = getOpenOrClosedForDate(month)
              return (
                <th key={`month${index}`} className={toLower(openOrClosed)}>
                  <div style={{ fontWeight: 'bold' }}>
                    {format(month, `MMM ''yy`)}
                  </div>
                  <div style={{ fontWeight: 'normal' }}>{openOrClosed}</div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {tableItems?.map((item, index) => (
            <tr key={index}>
              {item.heading ? (
                <td>
                  <div style={{ fontWeight: 'bold' }}>{item.category}</div>
                </td>
              ) : (
                <td className={'category'}>
                  <div>{item.category}</div>
                </td>
              )}
              {!item.heading && item.total !== 0 ? (
                <td
                  className={'actionable categoryTotal'}
                  //onClick={() => onCategoryTotalClick(item.category)}
                >
                  <USD
                    currencySign={'accounting'}
                    value={item.total}
                    fractionDigits={0}
                  />
                </td>
              ) : (
                <td className={'categoryTotal'}>{item.heading ? '' : '--'}</td>
              )}
              {item.monthlyTotals.map((month, index2) =>
                tableTotals.monthlyTotals[index2] !== 0 ? (
                  <td
                    key={`${index}-${index2}`}
                    className={!item.heading ? 'actionable' : undefined}
                    //   onClick={() =>
                    //     !item.heading &&
                    //     monthHeadings &&
                    //     onMonthClick(item.category, monthHeadings[index2])
                    //   }
                  >
                    {item.heading ? (
                      ''
                    ) : (
                      <USD
                        currencySign={'accounting'}
                        value={month}
                        fractionDigits={0}
                      />
                    )}
                  </td>
                ) : (
                  <td key={`${index}-${index2}`}>--</td>
                )
              )}
            </tr>
          ))}
          <tr>
            <td className={'total'}>{tableTotals.category}</td>
            {tableTotals.total !== 0 ? (
              <td
                className={'total actionable categoryTotal'}
                //onClick={onTotalClick}
              >
                <USD
                  currencySign={'accounting'}
                  value={tableTotals.total}
                  fractionDigits={0}
                />
              </td>
            ) : (
              <td className={'total categoryTotal'}>--</td>
            )}
            {tableTotals.monthlyTotals.map((total, index) =>
              total !== 0 ? (
                <td
                  key={`total${index}`}
                  className={'total actionable'}
                  // onClick={() =>
                  //   monthHeadings && onMonthTotalClick(monthHeadings[index])
                  // }
                >
                  <USD
                    currencySign={'accounting'}
                    value={total}
                    fractionDigits={0}
                  />
                </td>
              ) : (
                <td className={'total'} key={`total${index}`}>
                  --
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
