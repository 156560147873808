import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IAccount } from 'api/account.types'
import { chunk, orderBy, range } from 'lodash'
import pLimit from 'p-limit'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { AppState } from 'store'
import { datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer, AxiosBaseArgs } from 'store/api/shared'

const nextGenCouncilAccountApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getNextGenCouncilAccounts: builder.query<IAccount[] | undefined, string[]>({
      queryFn: async (householdIds, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataResult<IAccount>, Error>

        const baseApiArgs: AxiosBaseArgs = {
          url: 'search/accounts',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          $orderby: 'id desc',
          select: [
            'AccountKPIs',
            'CustodyAccount',
            'id',
            'LegalEntityName',
            'LegalEntityID',
            'householdId',
            'ClientAdvisor',
            'ClientAdvisorID'
          ]
        }
        const householdChunks = chunk(householdIds, 20)

        const limit = pLimit(5)
        const householdResults = householdChunks.map(async (householdChunk) => {
          const peekTop = 50
          const chunkTop = 500
          const params = {
            ...baseApiParams,
            $filter: [
              `search.in(householdId, '${householdChunk.join('|')}', '|')`
            ]
          }

          const peek = (await baseQuery({
            ...baseApiArgs,
            params: { ...params, $top: peekTop, $count: true }
          })) as Response

          const count = peek.data?.['@odata.count'] || 0

          const numChunks = Math.ceil(Math.max(count - peekTop, 0) / chunkTop)
          const requests = range(0, numChunks).map(
            (i): [number, AxiosBaseArgs] => {
              const $skip = peekTop + i * chunkTop
              return [
                i,
                {
                  ...baseApiArgs,
                  params: {
                    ...params,
                    $top: Math.min(chunkTop, count - $skip),
                    $skip
                  }
                }
              ]
            }
          )

          const results = await Promise.all(
            requests.map(([i, x]) =>
              limit(async (): Promise<[number, Response]> => {
                const result = (await baseQuery(x)) as Response
                if (result.error) {
                  limit.clearQueue()
                }
                return [i, result]
              })
            )
          )

          const error = results.find(([, result]) => result.error)
          if (error) {
            return { error }
          }

          return {
            data: [
              peek.data?.value || [],
              ...orderBy(results, ([index]) => index, 'asc').map(
                ([, result]) => result.data?.value || []
              )
            ].flat()
          }
        })

        const results = await Promise.all(householdResults)
        const errorResponse = results.find((x) => x.error)
        if (errorResponse) {
          return { error: errorResponse.error }
        }

        const accounts = results
          .map((x) => x.data)
          .filter(isNotNullOrUndefined)
          .flat()

        return { data: accounts }
      }
    })
  })
})

const emptyArray: [] = []
export const { useGetNextGenCouncilAccountsQuery } = nextGenCouncilAccountApi
export const selectNexGenCouncilAccounts = (
  state: AppState,
  householdIds: string[]
) =>
  nextGenCouncilAccountApi.endpoints.getNextGenCouncilAccounts.select(
    householdIds
  )(state)?.data ?? emptyArray
