import { get } from 'lodash'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'

export interface IColumn {
  field: string
  headerName?: string
  sortable?: boolean
  className?: string
  width?: string
  align?: 'left' | 'right' | 'center'
  headerStyle?: object
  columnStyle?: object
  style?: object
  fixedColumn?: boolean
  format?: (value: any, row: any) => ReactNode | string
  formatHeader?: () => ReactNode | string
}

export interface IDataTableProps {
  columns: IColumn[]
  data: any[]
}

const DataTable: FC<IDataTableProps> = ({ columns = [], data = [] }) => {
  const [tableData, setTableData] = useState<any[]>(data)
  const [sortValue, setSortValue] = useState<string | boolean>(false)
  useMemo(() => {
    setTableData(data)
  }, [data])
  const [sortingFields, setSortingFields] = useState<{
    [key: string]: string | boolean
  }>({
    Security: 'desc'
  })

  const onSorting = useCallback(
    (field: string) => {
      const sortType = sortingFields[field]
      const tempData = [...tableData]
      const totalRow = tempData.splice(tempData.length - 1, 1)
      setSortValue(sortType)
      const sortedData = [...tempData].sort((a, b) => {
        if (typeof get(a, field) === 'number') {
          if (sortType === 'asc') {
            return get(a, field) - get(b, field)
          } else {
            return get(b, field) - get(a, field)
          }
        } else if (typeof get(a, field) === 'string') {
          if (sortType === 'asc') {
            return (get(a, field) as string).localeCompare(get(b, field))
          } else {
            return (get(b, field) as string).localeCompare(get(a, field))
          }
        } else {
          return 0
        }
      })
      if (sortType === 'asc') {
        setSortingFields({
          ...sortingFields,
          [field]: false
        })
      } else if (sortType === 'desc') {
        setSortingFields({
          ...sortingFields,
          [field]: 'asc'
        })
      } else if (!sortType) {
        setSortingFields({
          ...sortingFields,
          [field]: 'desc'
        })
      }
      sortedData.push(totalRow[0])
      setTableData(sortedData)
    },
    [sortingFields, tableData]
  )
  return (
    <table
      css={{
        height: '100%',
        width: '100%',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        position: 'relative',
        border: '1px solid #EBEBEB',
        '&>tbody>tr:nth-of-type(even)': {
          backgroundColor: '#FAFAFA'
        },
        '&>tbody>tr:nth-of-type(odd)': {
          backgroundColor: '#FFFFFF'
        }
      }}
    >
      <thead>
        <tr
          css={{
            'th:first-child': {
              paddingLeft: 24
            },
            'th:last-child': {
              paddingRight: 24
            }
          }}
        >
          {columns.map(
            ({
              field,
              headerName,
              className,
              sortable,
              headerStyle,
              columnStyle,
              fixedColumn,
              align = 'left',
              formatHeader
            }) => (
              <th
                key={field}
                className={className}
                css={{
                  ...headerStyle,
                  ...columnStyle,
                  cursor: sortable ? 'pointer' : 'default',
                  backgroundColor: '#DFE8EE',
                  padding: '8px 5px',
                  fontSize: 12,
                  position: 'sticky',
                  top: constants.headerOffsetPx,
                  zIndex: fixedColumn ? 1 : 0,
                  left: fixedColumn ? 0 : 'unset'
                }}
                align={align}
                onClick={() => {
                  if (sortable) {
                    onSorting(field)
                  }
                }}
              >
                {formatHeader ? (
                  formatHeader()
                ) : (
                  <div
                    css={{
                      display: 'flex',
                      justifyContent:
                        align === 'center'
                          ? 'center'
                          : align === 'right'
                          ? 'end'
                          : 'start'
                    }}
                  >
                    {headerName || field}
                    {sortable && <SortIndicator direction={sortValue as any} />}
                  </div>
                )}
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr
            key={index}
            css={[
              {
                borderBottom: '1px solid #D9D9D9',
                paddingRight: '4px',
                'td:first-child': {
                  paddingLeft: 24
                },
                'td:last-child': {
                  paddingRight: 24
                }
              }
            ]}
          >
            {columns.map(
              ({
                field,
                className,
                width,
                style,
                columnStyle,
                fixedColumn,
                align = 'left',
                format = (value: any) => value?.toString() || ''
              }) => (
                <td
                  key={field}
                  className={className}
                  width={width}
                  align={align}
                  css={{
                    padding: '8px 4px',
                    position: fixedColumn ? 'sticky' : 'static',
                    left: fixedColumn ? 0 : 'unset',
                    zIndex: fixedColumn ? 9 : 0,
                    ...columnStyle
                  }}
                  style={{
                    ...style,
                    background: row.Security === 'Total' ? '#CDE2F2' : 'unset',
                    position: row.Security === 'Total' ? 'sticky' : 'static',
                    bottom: '-1px',
                    zIndex: row.Security === 'Total' ? 1 : 0
                  }}
                  title={
                    typeof get(row, field) === 'number'
                      ? get(row, field).toFixed(2)
                      : get(row, field)
                  }
                >
                  {format(get(row, field), row)}
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
      {tableData.length === 0 && (
        <tfoot css={{ height: '100%' }}>
          <tr css={{ height: '100%' }}>
            <td
              colSpan={columns.length}
              css={{
                height: '100%',
                textAlign: 'center',
                background: ' #f0f0f0',
                border: '1px solid #D9D9D9'
              }}
            >
              No data available
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

export default DataTable
