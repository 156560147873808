import {
  Icon,
  IconButton,
  ITheme,
  Link,
  mergeStyleSets,
  Stack,
  Text
} from '@fluentui/react'
import { groupBy, keyBy } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd
} from 'react-sortable-hoc'
import {
  IWithClassesProps,
  withClasses
} from '../../../../shared/hoc/withClasses'
import { IColumnDefinition } from '../contracts/IColumnDefinition'
import { IColumnState } from '../contracts/IColumnState'

export interface IListsDataTableColumnEditorComponentProps {
  columnDefinitions: IColumnDefinition[]
  columnState: IColumnState[]
  onColumnChanged: (newState: IColumnState) => void
  enablePreviewColumns: boolean
}

const ListsDataTableColumnEditorComponent: React.FC<
  IListsDataTableColumnEditorComponentProps
> = ({
  columnDefinitions,
  columnState,
  onColumnChanged,
  enablePreviewColumns
}) => {
  const columnDefinitionMap = useMemo(
    () => keyBy(columnDefinitions, (x) => x.id),
    [columnDefinitions]
  )

  return (
    <Stack>
      {columnState.map((x, i) => {
        const columnDefinition = columnDefinitionMap[x.columnId]
        if (columnDefinition?.selectable === false) {
          return null
        }

        if (!columnDefinition) {
          return null
        }

        if (!enablePreviewColumns && columnDefinition.preview) {
          return null
        }

        const props = {
          columnDefinition,
          columnState: x,
          onColumnChanged
        }

        return x.selected ? (
          <SortableColumnEditorItem
            index={i}
            {...props}
            key={`${x.columnId}${x.selected}`}
          />
        ) : (
          <ThemedColumnEditorItem
            {...props}
            key={`${x.columnId}${x.selected}`}
          />
        )
      })}
    </Stack>
  )
}

interface IColumnEditorItemProps {
  columnDefinition: IColumnDefinition
  columnState: IColumnState
  onColumnChanged: (newState: IColumnState) => void
}

const visibleOnHoverClassName = 'visible-on-hover'

const getThemedClasses = (theme: ITheme) => {
  return mergeStyleSets({
    column: {
      borderBottom: `solid 1px ${theme.palette.neutralLighter}`,
      padding: '5px',
      selectors: {
        [`.${visibleOnHoverClassName}`]: {
          visibility: 'hidden'
        },
        ':hover, &.dragging': {
          backgroundColor: theme.semanticColors.bodyBackground,
          boxShadow: theme.effects.elevation8
        },
        [`:hover .${visibleOnHoverClassName}`]: {
          visibility: 'visible'
        }
      }
    }
  })
}

const ColumnEditorItem: React.FC<
  IColumnEditorItemProps &
    IWithClassesProps<ReturnType<typeof getThemedClasses>>
> = ({ columnDefinition, columnState, onColumnChanged, classes }) => {
  const onSearchClick = () =>
    onColumnChanged({
      ...columnState,
      includeInSearch: !columnState.includeInSearch
    })
  const onStickyClick = () =>
    onColumnChanged({
      ...columnState,
      sticky: !columnState.sticky,
      selected: true
    })
  const onAddOrRemove = () =>
    onColumnChanged({
      ...columnState,
      selected: !columnState.selected,
      sticky: false
    })

  return (
    <Stack horizontal={true} verticalAlign="center" className={classes.column}>
      {columnState.selected ? <DragHandle /> : <span />}

      <Stack.Item grow={1}>
        <Text block={true}>{columnDefinition.name}</Text>
        {columnDefinition.preview && (
          <Text
            variant="small"
            title="The information in this column may not be accurate"
          >
            <Link>Preview</Link>
          </Text>
        )}
      </Stack.Item>
      {columnDefinition.searchable && columnState.selected && (
        <IconButton
          title="Include results from this column when searching the list"
          checked={columnState.includeInSearch}
          iconProps={{
            iconName: 'Search'
          }}
          onClick={onSearchClick}
        />
      )}
      {columnState.selected && (
        <IconButton
          title="Pin this column to the left"
          checked={columnState.sticky}
          className={columnState.sticky ? '' : visibleOnHoverClassName}
          iconProps={{
            iconName: columnState.sticky ? 'PinnedSolid' : 'Pinned'
          }}
          onClick={onStickyClick}
        />
      )}
      <IconButton
        title={columnState.selected ? 'Remove this column' : 'Add this column'}
        className={visibleOnHoverClassName}
        iconProps={{
          iconName: columnState.selected ? 'Remove' : 'Add'
        }}
        onClick={onAddOrRemove}
      />
    </Stack>
  )
}

const ThemedColumnEditorItem: React.FC<IColumnEditorItemProps> = withClasses(
  getThemedClasses
)(ColumnEditorItem as any) as any

const DragHandle = SortableHandle(() => {
  return (
    <div
      title="Drag to reorder columns"
      style={{ width: '16px', height: '16px', cursor: 'row-resize' }}
    >
      <Icon
        iconName="GripperDotsVertical"
        styles={{ root: { cursor: 'inherit !important' } }}
      />
    </div>
  )
})
const SortableColumnEditorItem = SortableElement(ThemedColumnEditorItem)
const ListsDataTableColumnEditorComponentSortableContainer =
  SortableContainer<IListsDataTableColumnEditorComponentProps>(
    ListsDataTableColumnEditorComponent
  )

export const SortableListsDataTableColumnEditorComponent: React.FC<
  IListsDataTableColumnEditorComponentProps & {
    onColumnMoved: (
      column: IColumnState,
      oldIndex: number,
      newIndex: number
    ) => void
  }
> = (props) => {
  const {
    onColumnMoved,
    columnState,
    onColumnChanged,
    columnDefinitions,
    enablePreviewColumns
  } = props
  const onSortEnd = (indexOffset: number) => (sort: SortEnd) => {
    const { oldIndex, newIndex } = sort
    onColumnMoved(
      columnState[indexOffset + oldIndex],
      indexOffset + oldIndex,
      indexOffset + newIndex
    )
  }
  const container = useRef<HTMLDivElement>(null)

  const getGroupValue = (item: IColumnState) =>
    item.sticky ? 'Pinned' : item.selected ? 'Selected' : 'Unselected'
  const groups = groupBy(columnState, getGroupValue)
  const groupKeys = Object.keys(groups)

  const getHelperContainer = useCallback(() => {
    return container.current || document.body
  }, [container])

  return (
    <div ref={container} style={{ position: 'relative' }}>
      {groupKeys.map((groupKey, i) => {
        const group = groups[groupKey]
        const indexOffset = groupKeys
          .filter((x, index) => index < i)
          .map((x) => groups[x])
          .reduce((a, x) => a + x.length, 0)

        return (
          <Stack key={i}>
            <h3 style={{ padding: '10px 0 5px' }}>{groupKey} Columns</h3>
            <ListsDataTableColumnEditorComponentSortableContainer
              useDragHandle={true}
              lockAxis="y"
              helperClass="dragging"
              helperContainer={getHelperContainer}
              onSortEnd={onSortEnd(indexOffset)}
              onColumnChanged={onColumnChanged}
              columnDefinitions={columnDefinitions}
              columnState={group}
              enablePreviewColumns={enablePreviewColumns}
            />
          </Stack>
        )
      })}
    </div>
  )
}
