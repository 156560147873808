import { css, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { ISelectableOption, Toggle } from '@fluentui/react'
import { Dropdown } from 'modules/Advisory/modules/Rdot360/components/shared'
import DetailsNavigator from 'modules/Advisory/modules/Rdot360/components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { IDropdownOption } from 'modules/Advisory/modules/Rdot360/components/shared/Dropdown/Dropdown'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { IndeterminateCheckbox } from 'modules/Advisory/modules/Rdot360/shared/IndeterminateCheckbox'
import { useFinancialsApiUtil } from 'modules/Advisory/modules/Rdot360/store/rdot360AnalyticsApi'
import { FC, useMemo } from 'react'

export const getClasses = (theme: Theme) => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
    alignItems: 'flex-end',
    gap: 20,
    flexFlow: 'row wrap'
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  margin2: css({
    margin: '2px 6px'
  }),
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  dateRangeDropdown: css({
    width: 168,
    marginTop: '8px'
  }),
  iconContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    cursor: 'pointer'
  }),
  checkboxContainer: css({
    display: 'flex',
    marginBottom: 14,
    '@media (max-width: 1380px)': {
      marginRight: 20
    }
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  }),
  searchBoxContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px 10px',
    marginLeft: 'auto'
  })
})

const IncomeToggle = styled.div`
  .ms-Toggle {
    margin: auto 0;
    .ms-Toggle-background {
      background-color: #b0d6f1;
      width: 36px;
      height: 14px;
      border: 0;
      padding: 0;
      margin-top: 3px;
      .ms-Toggle-thumb {
        background: #2a769d;
        height: 16px;
        width: 16px;
      }
    }
  }
`

const currentYear = new Date().getFullYear()

const dateRangeOptions: ISelectableOption[] = [
  { key: `${currentYear}`, text: 'Last 12 Months' },
  { key: `${currentYear - 1}`, text: `${currentYear - 1}` },
  { key: `${currentYear - 2}`, text: `${currentYear - 2}` },
  { key: `${currentYear - 3}`, text: `${currentYear - 3}` },
  { key: `${currentYear - 4}`, text: `${currentYear - 4}` }
]

const ProjectedIncomeDetailsHeader: FC<{
  isEstimated: boolean
  isOptionsPremiumsAvailable: boolean
  selectedDataRange: string
  searchString: string
  onIncomeTypeChangeClick: (event: any) => void
  onHandleCheckboxChange: (event: any) => void
  includeOptionPremiums: boolean
  onSearchInputChange: (event: any) => void
  onDateRangeChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>,
    index?: number
  ) => void
}> = ({
  isEstimated,
  isOptionsPremiumsAvailable,
  selectedDataRange,
  searchString,
  onIncomeTypeChangeClick,
  onHandleCheckboxChange,
  includeOptionPremiums,
  onDateRangeChange,
  onSearchInputChange
}) => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { invalidateTags } = useFinancialsApiUtil()

  return (
    <div css={classes.container}>
      <DetailsNavigator />
      <div css={{ display: 'flex', marginBottom: 12 }}>
        <div
          css={[
            classes.margin2,
            isEstimated ? classes.primaryDarkBlue : classes.tertiaryGray1
          ]}
        >
          Historical
        </div>
        <IncomeToggle>
          <Toggle
            checked={isEstimated}
            onChange={onIncomeTypeChangeClick}
            role="checkbox"
          />
        </IncomeToggle>
        <div
          css={[
            classes.margin2,
            !isEstimated ? classes.primaryDarkBlue : classes.tertiaryGray1
          ]}
        >
          Projected
        </div>
      </div>
      {!isEstimated && (
        <>
          <div css={classes.dateRangeContainer}>
            <div>Date Range</div>
            <div css={classes.dateRangeDropdown}>
              <Dropdown
                disabled={isEstimated}
                options={dateRangeOptions}
                defaultSelectedOption={dateRangeOptions.find(
                  (item) => item.key === selectedDataRange
                )}
                onChange={onDateRangeChange}
              />
            </div>
          </div>
          {isOptionsPremiumsAvailable && (
            <div css={classes.checkboxContainer}>
              <IndeterminateCheckbox
                style={{ margin: '0 5px' }}
                id="optionPremiumsLabelCheckbox"
                className="checkbox"
                checked={includeOptionPremiums}
                onChange={onHandleCheckboxChange}
              />
              <div>Include Option Premiums</div>
            </div>
          )}
        </>
      )}
      <div css={classes.searchBoxContainer}>
        <Searchbox searchText={searchString} onChange={onSearchInputChange} />
        <div css={classes.iconContainer}>
          <Icon
            type="Refresh"
            width={24}
            height={24}
            onClick={() =>
              invalidateTags([
                'income',
                !isEstimated ? 'estimatedIncome' : 'projectedIncome'
              ])
            }
            color={theme.colors.extraBlue2}
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectedIncomeDetailsHeader
