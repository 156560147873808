import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { IClient } from '../../../api/client.types'
import { ISearchResult } from '../../../api/common.types'
import { searchUsers } from '../../../api/graph'
import {
  getDonors,
  getGrants,
  postDonor,
  IDonor,
  postGrant,
  IPhilanthropyGrant,
  IGetTrusteesResponse,
  getDonorTrustees,
  getGrantMakers
} from '../../../api/philanthropy'
import {
  search,
  getRockefellerApiOptions,
  getMicrosoftGraphApiOptions
} from '../../../store/shared/sagas'
import {
  donorDataActions,
  donorPostActions,
  grantDataActions,
  grantPostActions,
  donorSearchActions,
  trusteeSearchActions,
  grantManagerSearchActions,
  grantMakerSearchActions
} from './actions'

// Call to Grant Making API to retrieve donors managed by the logged in user
const fetchDonors = function* (
  action: ReturnType<typeof donorDataActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)
    const donors = yield* call(getDonors, action.payload, apiOptions)

    yield put(donorDataActions.success(donors))
  } catch (e: any) {
    yield put(donorDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Call to Grant Making API in order to create or save changes made to a donor
const saveDonor = function* (
  action: ReturnType<typeof donorPostActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)
    const donors = yield* call(postDonor, action.payload as IDonor, apiOptions)

    yield put(donorPostActions.success(donors))
  } catch (e: any) {
    yield put(donorPostActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Call to Grant Making API to retrieve grants managed by the logged in user
const fetchGrants = function* (
  action: ReturnType<typeof grantDataActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)
    const grants = yield* call(getGrants, action.payload, apiOptions)

    yield put(grantDataActions.success(grants))
  } catch (e: any) {
    yield put(grantDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Call to Grant Making API in order to save changes made to a grant
const saveGrant = function* (
  action: ReturnType<typeof grantPostActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)

    const grant = yield* call(
      postGrant,
      action.payload as IPhilanthropyGrant,
      apiOptions
    )

    yield put(donorPostActions.success(grant))
  } catch (e: any) {
    yield put(donorPostActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Search for grant managers (RCM employees) using graph user search
const searchGrantManagers = function* (
  action: ReturnType<typeof grantManagerSearchActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getMicrosoftGraphApiOptions, source.token)

    const users = yield* call(
      searchUsers,
      {
        searchString: action.payload,
        additionalFilters: [`userType eq 'Member'`]
      },
      apiOptions
    )

    yield put(grantManagerSearchActions.success(users || []))
  } catch (e: any) {
    yield put(grantManagerSearchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Search for grant makers (Client Online users) using the profile api
const searchGrantMakers = function* (
  action: ReturnType<typeof grantMakerSearchActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)
    const users = yield* call(getGrantMakers, action.payload, apiOptions)

    yield put(grantMakerSearchActions.success(users || []))
  } catch (e: any) {
    yield put(grantMakerSearchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

// Search for clients, used in the 'Donor Search' bar at the top of the donor panel
const searchDonors = function* (
  action: ReturnType<typeof donorSearchActions.request>
) {
  try {
    const results: ISearchResult<IClient> = yield call(
      search,
      'client' as const,
      {
        searchFields: ['LegalEntityName'],
        query: action.payload
      }
    )

    if (!results?.value) {
      throw new Error(`Client context is undefined for: ${action.payload}`)
    }

    yield put(donorSearchActions.success(results.value))
  } catch (e: any) {
    yield put(donorSearchActions.failure(e))
  }
}

// Retrieve donor trustees, used when a client is selected from the donor search
const fetchTrustees = function* (
  action: ReturnType<typeof trusteeSearchActions.request>
) {
  yield delay(300)
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions, source.token)

    const trustees: IGetTrusteesResponse = yield call(
      getDonorTrustees,
      action.payload,
      apiOptions
    )

    yield put(trusteeSearchActions.success(trustees))
  } catch (e: any) {
    yield put(trusteeSearchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export default [
  () => takeLatest(donorDataActions.request, fetchDonors),
  () => takeLatest(grantDataActions.request, fetchGrants),
  () => takeLatest(grantManagerSearchActions.request, searchGrantManagers),
  () => takeLatest(grantMakerSearchActions.request, searchGrantMakers),
  () => takeLatest(donorPostActions.request, saveDonor),
  () => takeLatest(grantPostActions.request, saveGrant),
  () => takeLatest(donorSearchActions.request, searchDonors),
  () => takeLatest(trusteeSearchActions.request, fetchTrustees)
]
