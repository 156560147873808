import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  SearchBox,
  Stack
} from '@fluentui/react'
import { sortBy } from 'lodash'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { getEnablePreviewPreference } from '../../../../store/user/selectors'
import { SortableListsDataTableColumnEditorComponent } from '../components/ListsDataTableColumnEditor'
import { IColumnState } from '../contracts/IColumnState'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'

const MemoListsDataTableColumnEditorComponent = memo(
  SortableListsDataTableColumnEditorComponent
)

export interface IConnectedListsDataTableColumnEditorPanelComponentProps {
  isOpen: boolean
  onDismiss: () => void
}

export const createConnectedListsDataTableColumnEditorPanelComponent = <
  T extends SearchResponseType
>(
  actions: IListsActions<T>,
  selectors: IListsSelectors<T>
) => {
  const ConnectedListsDataTableColumnEditorPanelComponent: React.FC<
    IConnectedListsDataTableColumnEditorPanelComponentProps
  > = ({ isOpen, onDismiss }) => {
    const { uiActions } = actions
    const { uiSelectors } = selectors
    const columnDefinitions = useSelector(uiSelectors.getColumnDefinitions)
    const columnDefinitionList = useMemo(
      () => Object.entries(columnDefinitions || {}).map(([, value]) => value),
      [columnDefinitions]
    )
    const columnState = useSelector(uiSelectors.getColumnState)

    const dispatch = useDispatch()

    const [modifiedColumns, setModifiedColumns] = useState<IColumnState[]>([])
    useEffect(() => {
      setModifiedColumns([...columnState])
    }, [columnState, isOpen])

    const onColumnChanged = useCallback(
      (newColumn: IColumnState) => {
        const newColumns = [...modifiedColumns]
        const columnIndex = newColumns.findIndex(
          (x) => x.columnId === newColumn.columnId
        )
        newColumns.splice(columnIndex, 1, newColumn)

        const forSorting = newColumns.map((x, i) => ({
          column: x,
          index: i
        }))

        const getSortValue = (item: { column: IColumnState; index: number }) =>
          item.index +
          (item.column.selected ? 0 : newColumns.length) +
          (item.column.sticky ? 0 : newColumns.length)

        const sortedNewColumns = forSorting
          .sort((a, b) => getSortValue(a) - getSortValue(b))
          .map((x) => x.column)
        setModifiedColumns(sortedNewColumns)
      },
      [modifiedColumns]
    )

    const onColumnMoved = useCallback(
      (column: IColumnState, oldPosition: number, newPosition: number) => {
        const newColumns = [...modifiedColumns]
        newColumns.splice(oldPosition, 1)
        newColumns.splice(newPosition, 0, { ...column })
        setModifiedColumns(newColumns)
      },
      [modifiedColumns]
    )

    const onApply = useCallback(() => {
      dispatch(uiActions.updateColumnState(modifiedColumns))
      onDismiss()
    }, [dispatch, modifiedColumns, onDismiss, uiActions])

    const onRenderFooterContent = useCallback(() => {
      return (
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={onApply}>Apply</PrimaryButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      )
    }, [onDismiss, onApply])

    const enablePreviewFeatures = useSelector(getEnablePreviewPreference)

    const [searchText, setSearchText] = useState<string | undefined>()
    const onSearchChange = useCallback((_: unknown, newValue?: string) => {
      setSearchText(newValue)
    }, [])

    const visibleColumns = useMemo(() => {
      const lowerCaseSearchText = (searchText || '').trim().toLowerCase()
      const filtered = lowerCaseSearchText
        ? columnDefinitionList?.filter(
            (x) =>
              [x.name]
                .map((x) => (x || '').trim().toLowerCase())
                .join(' | ')
                .indexOf(lowerCaseSearchText) >= 0
          )
        : columnDefinitionList

      return sortBy(filtered, (x) => x.name)
    }, [columnDefinitionList, searchText])

    return (
      <Panel
        isOpen={isOpen}
        type={PanelType.medium}
        onDismiss={onDismiss}
        headerText="Edit Columns"
        hasCloseButton={true}
        closeButtonAriaLabel="close"
        isBlocking={true}
        layerProps={{ eventBubblingEnabled: true }}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
        styles={{ content: { flexGrow: 1 } }}
      >
        <SearchBox
          placeholder="Filter Columns"
          value={searchText}
          onChange={onSearchChange}
          autoComplete="off"
        />
        <MemoListsDataTableColumnEditorComponent
          columnDefinitions={visibleColumns}
          columnState={modifiedColumns}
          onColumnChanged={onColumnChanged}
          onColumnMoved={onColumnMoved}
          enablePreviewColumns={enablePreviewFeatures}
        />
      </Panel>
    )
  }

  return ConnectedListsDataTableColumnEditorPanelComponent
}
