import { IDropdownOption } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { format, isFuture } from 'date-fns'
import { IFeeAccount } from 'modules/Fees/api/types/types'
import {
  Dictionary,
  IDataFilter,
  NoData,
  RangeType
} from 'shared/components/DataTableFilters/DataTableFilters'
import {
  PaymentMM,
  PaymentRow
} from '../../Dashboard/modules/Dashboard/store/types'
import { IStandingInstruction } from '../api/types'
import {
  IWireRequest,
  IWireInitiationForm,
  IPaymentVerificationForm,
  IMMValidateRequest,
  IPastPayment,
  IPaymentReviewRequest,
  IOMTApproverForm,
  ITransInfo,
  IFurtherCredit,
  IBeneficiaryDetails
} from '../store/types'

export const getPayTermText = (
  options: IDropdownOption[],
  key?: string
): string => {
  let payTermText = ''
  if (options && options.length > 0) {
    payTermText = options.filter((item) => item.key === key)?.[0]?.text
  }

  return payTermText ? payTermText : ''
}

export const formatPhoneNumber = (value: string | undefined): string => {
  if (!value) {
    return ''
  }
  if (value === 'Other') {
    return value
  }
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length
  if (cvLength < 4) {
    return currentValue
  }
  if (cvLength === 10) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }

  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, cvLength)}`
}

export const USDEquivalent = (
  amount?: string | number | undefined,
  rate?: string | number | undefined
) => {
  try {
    if (amount && rate) {
      return Number(
        parseFloat((Number(amount) * Number(rate))?.toString()).toFixed(2)
      )
    }
  } catch (e) {
    return undefined
  }
  return undefined
}

const getAmountForMMValidate = (wireData: IWireInitiationForm) => {
  if (wireData?.wireAmount?.accountCloseout) {
    return wireData?.senderDetails?.availablecashtowithdraw || 1
  }
  return wireData?.wireAmount?.currency !== 'USD'
    ? wireData?.wireAmount?.rate
      ? USDEquivalent(
          wireData?.wireAmount?.amount,
          wireData?.wireAmount?.rate
        ) || 1
      : 1 // if rate is empty, pass 1$ to mmvalidate as the api fails for 0$ usd
    : wireData?.wireAmount?.amount
    ? Number(wireData?.wireAmount?.amount)
    : 0
}

type createWireRequestProps = {
  wireInfo: IWireInitiationForm
  verificationInfo?: IPaymentVerificationForm
  status?: string
  ismarginpaperworkrequired?: boolean
}

export const createWireRequest = ({
  wireInfo,
  verificationInfo = {},
  status = 'DRFT',
  ismarginpaperworkrequired = false
}: createWireRequestProps) => {
  const request: IWireRequest = {
    mmInstID: wireInfo?.wirePaymentId,
    tranInfo: {
      SrvTrnID: 0,
      WireTypeCondition: wireInfo.wireType,
      availablecashtowithdraw:
        wireInfo?.senderDetails?.availablecashtowithdraw || undefined,
      UseExistingSI: wireInfo.wireAmount?.useExistingSI || false,
      StandingInstructionId: wireInfo.wireAmount?.standingInstructionId,
      IsThirdParty:
        wireInfo?.wireAmount?.recipientType === 'Third Party' ? true : false,
      BankAcctFromAcctId: wireInfo?.senderDetails?.account || '',
      BankAcctFromName: wireInfo?.senderDetails?.name || '',
      BeneficiaryBankRoutingType:
        wireInfo?.wireAmount?.country === 'US' ? 'A' : 'S',
      BeneficiaryBankRoutingId:
        wireInfo?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') || '',
      BeneficiaryBankName: wireInfo?.beneficiary?.bankName || '',
      BeneficiaryAcctId: wireInfo?.beneficiary?.account || '',
      BeneficiaryName: wireInfo?.beneficiary?.name || '',
      BeneficiaryNameAddrLine1: wireInfo?.beneficiary?.line1 || '',
      BeneficiaryNameAddrLine2: wireInfo?.beneficiary?.line2 || '',
      BeneficiaryNameAddrLine3: wireInfo?.beneficiary?.line3 || '',
      CreateNewSI: wireInfo?.beneficiary?.createNewSI || false,
      Ismarginpaperworkrequired: ismarginpaperworkrequired,
      InternalMessage: wireInfo?.beneficiary?.createNewSI
        ? wireInfo?.beneficiary?.maxDistAmt?.toString()
        : '',
      WireDisbursementDetail1: wireInfo?.details?.line1 || '',
      WireDisbursementDetail2: wireInfo?.details?.line2 || '',
      WireDisbursementDetail3: wireInfo?.details?.line3 || '',
      WireDisbursementDetail4: wireInfo?.details?.line4 || '',
      FurtherCredit1_AccountNumber: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.account || ''
        : '',
      FurtherCredit1_NameLine1: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.name || ''
        : '',
      FurtherCredit1_NameLine2: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line1 || ''
        : '',
      FurtherCredit1_NameLine3: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line2 || ''
        : '',
      FurtherCredit1_NameLine4: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line3 || ''
        : '',
      FurtherCredit2_AccountNumber: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.account || ''
        : '',
      FurtherCredit2_NameLine1: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.name || ''
        : '',
      FurtherCredit2_NameLine2: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line1 || ''
        : '',
      FurtherCredit2_NameLine3: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line2 || ''
        : '',
      FurtherCredit2_NameLine4: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line3 || ''
        : '',
      DestinationCountry: wireInfo?.wireAmount?.country || '',
      isfuturedated: false,
      isIRA: wireInfo?.senderDetails?.isRetirementAccount,
      reasoncode: wireInfo?.senderDetails?.isRetirementAccount
        ? wireInfo?.taxWithholdings?.retDistCode
        : undefined,
      reasondesc: wireInfo?.senderDetails?.isRetirementAccount
        ? wireInfo?.taxWithholdings?.retDistReason
        : undefined,
      TrnAmt: !wireInfo?.wireAmount?.accountCloseout
        ? parseFloat(wireInfo?.wireAmount?.amount || '0')
        : undefined,
      AMAacknowledgement: wireInfo?.taxWithholdings?.AMAacknowledgement,
      Memo: wireInfo?.details?.memo,
      TrnDateTime: wireInfo?.wireAmount?.tranDate
        ? format(wireInfo?.wireAmount?.tranDate, 'MM/dd/yyyy')
        : '',
      UseType9Income: wireInfo?.wireAmount?.useType9Income,
      AccountCloseout: wireInfo?.wireAmount?.accountCloseout,
      RecipientBankAcceptUSD:
        wireInfo?.wireAmount?.currency === 'USD' &&
        wireInfo?.wireAmount?.country !== 'US'
          ? wireInfo?.wireAmount?.acceptsUSD
          : undefined
    },
    expirationDate:
      wireInfo?.wireAmount?.tranDate &&
      isFuture(wireInfo?.wireAmount?.tranDate) &&
      wireInfo?.wireAmount?.expirationDate
        ? format(wireInfo?.wireAmount?.expirationDate, 'MM/dd/yyyy')
        : '',
    apprDetail: {
      instMethod: verificationInfo?.methodOfInstruction || '',
      instDate: verificationInfo?.dateOfInstruction
        ? format(verificationInfo?.dateOfInstruction, 'MM/dd/yyyy')
        : undefined,
      instPerson:
        verificationInfo?.authorizedPerson === 'Other'
          ? verificationInfo?.authorizedPersonCustom || ''
          : verificationInfo?.authorizedPerson || '',
      isEauth: verificationInfo?.clientAuthorization ? 'Y' : undefined,
      ClnDetail: verificationInfo?.clientAuthMethods?.includes('ClnDetail')
        ? 'Y'
        : undefined,
      ClnRelationship: verificationInfo?.clientAuthMethods?.includes(
        'ClnRelationship'
      )
        ? 'Y'
        : undefined,
      DoB: verificationInfo?.clientAuthMethods?.includes('DoB')
        ? 'Y'
        : undefined,
      Last4SSN: verificationInfo?.clientAuthMethods?.includes('Last4SSN')
        ? 'Y'
        : undefined,
      RecentAct: verificationInfo?.clientAuthMethods?.includes('RecentAct')
        ? 'Y'
        : undefined,
      inPerson: verificationInfo?.clientAuthMethods?.includes('inPerson')
        ? 'Y'
        : undefined,
      VoiceRcgn: verificationInfo?.clientAuthMethods?.includes('VoiceRcgn')
        ? 'Y'
        : undefined,
      PhoneNo:
        verificationInfo?.phoneNumber === 'Other'
          ? verificationInfo?.manualPhoneNo?.replace(/[^\d]/g, '') || ''
          : verificationInfo?.phoneNumber?.replace(/[^\d]/g, '') || '',
      PhoneSr: verificationInfo?.phoneNumberSource || '',
      instCallType:
        verificationInfo?.phoneNumber === 'Other'
          ? 'Other'
          : 'CustodianProvided',
      Purpose:
        verificationInfo?.purposeOfFund === 'Other'
          ? verificationInfo?.purposeOfFundCustom || ''
          : verificationInfo?.purposeOfFund || '',
      PWAcomment: verificationInfo?.additinalComment || '',
      insufFundSolution: wireInfo?.wireAmount?.hasInsufFund
        ? wireInfo?.wireAmount?.insufFundSolutionId
        : '',
      insufFund: wireInfo?.wireAmount?.hasInsufFund
        ? wireInfo?.wireAmount?.insufFundComments
        : '',
      authenticateEmployee: verificationInfo?.authenticateEmployee
    },
    intlMMinfo: {
      toCountry: wireInfo?.wireAmount?.country || '',
      toCurrency: wireInfo?.wireAmount?.currency || 'USD',
      xchngRate:
        wireInfo?.wireAmount?.currency !== 'USD'
          ? wireInfo?.wireAmount?.rate
          : undefined,
      USDAmount:
        wireInfo?.wireAmount?.currency !== 'USD'
          ? USDEquivalent(
              wireInfo?.wireAmount?.amount,
              wireInfo?.wireAmount?.rate
            )
          : wireInfo?.wireAmount?.amount
          ? Number(wireInfo?.wireAmount?.amount)
          : undefined
    },
    mmStatus: status,
    xferType: 'WD'
  }

  const taxWithholdings: ITransInfo = {
    canfedwithhold: request.tranInfo.isIRA
      ? wireInfo?.taxWithholdings?.fedTaxOption || undefined
      : undefined,
    canstatewithhold: request.tranInfo.isIRA
      ? wireInfo?.taxWithholdings?.stateTaxOption || undefined
      : undefined,
    fedwithholdpct: request.tranInfo.isIRA
      ? Number(wireInfo?.taxWithholdings?.fedTaxRate)
      : undefined,
    statewithholdpct: request.tranInfo.isIRA
      ? Number(wireInfo?.taxWithholdings?.stateTaxRate)
      : undefined,
    istrnamtincludetaxwithhold: request.tranInfo.isIRA
      ? !(wireInfo?.taxWithholdings?.includeTax || false)
      : false
  }
  request.tranInfo = { ...request.tranInfo, ...taxWithholdings }
  if (wireInfo.wireType === 'C') {
    request.tranInfo.BeneficiaryBankRoutingType = 'A'
    request.tranInfo.BeneficiaryAcctId = wireInfo.beneficiary?.swiftCode
  }
  return request
}

const updateMappingsBeforeValidate = (
  request: IMMValidateRequest,
  wireInfo: IWireInitiationForm
): IMMValidateRequest => {
  if (!wireInfo?.furtherCredit1?.hasFC && !wireInfo?.furtherCredit2?.hasFC) {
    return request
  }
  // updating field mapping if fc1 / fc2 are entered
  request.FurtherCredit1_AccountNumber = request?.toaccount?.accountnumber || ''
  request.FurtherCredit1_NameLine1 = wireInfo?.beneficiary?.name || ''
  if (!wireInfo?.furtherCredit2?.hasFC) {
    if (request.toaccount) {
      request.toaccount.accountnumber = wireInfo?.furtherCredit1?.account || ''
      request.toaccount.accountname = wireInfo?.furtherCredit1?.name || ''
    }
  } else {
    if (request.toaccount) {
      request.toaccount.accountnumber = wireInfo?.furtherCredit2?.account || ''
      request.toaccount.accountname = wireInfo?.furtherCredit2?.name || ''
    }
    request.FurtherCredit2_AccountNumber =
      wireInfo?.furtherCredit1?.account || ''
    request.FurtherCredit2_NameLine1 = wireInfo?.furtherCredit1?.name || ''
  }
  return request
}

const switchBene = (item: IStandingInstruction) => {
  let FFC1: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let FFC2: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let beneficiary: IBeneficiaryDetails = {
    bankIdentifierNumber: item?.routeId || '',
    bankName: item?.MMBeneficiaryAccount?.BankName || '',
    account:
      item?.DestinationCountry !== 'US' &&
      item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        ? item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        : item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber || '',
    swiftCode:
      item?.DestinationCountry !== 'US'
        ? item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        : item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber,
    name:
      item?.DestinationCountry !== 'US' &&
      item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        ? item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine1
        : item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
    line1: '',
    line2: '',
    line3: ''
  }

  if (item?.FurtherCreditAccountNumber2?.FurtherCreditAccountNumber) {
    FFC2 = {
      account: item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber,
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1,
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2,
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3,
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4,
      hasFC: true
    }

    FFC1 = {
      account:
        item?.FurtherCreditAccountNumber2?.FurtherCreditAccountNumber || '',
      name:
        item?.FurtherCreditAccountNumber2.FurtherCredit1NameLine?.NameLine1 ||
        '',
      line1:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine2 ||
        '',
      line2:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine3 ||
        '',
      line3:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine4 ||
        '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber,
      name: item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine
        ?.NameLine1,
      line1:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine2,
      line2:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine3,
      line3:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine4
    }
  } else if (item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber) {
    FFC1 = {
      account: item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber || '',
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2 || '',
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3 || '',
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4 || '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.FurtherCreditAccountNumber1.FurtherCreditAccountNumber,
      name: item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine
        ?.NameLine1,
      line1:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine2,
      line2:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine3,
      line3:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine4
    }
  } else {
    beneficiary = {
      ...beneficiary,
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2 || '',
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3 || '',
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4 || ''
    }
  }

  return { bene: beneficiary, FFC1: FFC1, FFC2: FFC2 }
}

export const prepareWireDataFromSI = (
  item: IStandingInstruction,
  clientName: string | undefined,
  isRetirementAccount: boolean | undefined
): IWireInitiationForm => {
  const { bene, FFC1, FFC2 } = switchBene(item)
  return {
    beneficiary: { ...bene },
    details: {
      line1:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail1 ||
        '',
      line2:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail2 ||
        '',
      line3:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail3 ||
        '',
      line4:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail4 ||
        ''
    },
    furtherCredit1: { ...FFC1 },
    furtherCredit2: { ...FFC2 },
    senderDetails: {
      account: item?.accountnumber || '',
      name: clientName || '',
      isRetirementAccount: isRetirementAccount
    },
    wireAmount: {
      currency: 'USD',
      country: item?.DestinationCountry || '',
      useExistingSI: true,
      useExistingPP: false,
      standingInstructionId: item?.standinginstructionid,
      recipientType: item?.isthirdparty ? 'Third Party' : 'First Party'
    }
  }
}

const switchBenePP = (item: IPastPayment) => {
  let FFC1: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let FFC2: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let beneficiary: IBeneficiaryDetails = {
    bankIdentifierNumber: item?.tranInfo?.beneficiaryBankRoutingId || '',
    bankName: item?.tranInfo?.beneficiaryBankName || '',
    account:
      item?.tranInfo?.destinationCountry !== 'US' &&
      item?.tranInfo?.furtherCredit1_AccountNumber
        ? item?.tranInfo?.furtherCredit1_AccountNumber
        : item?.tranInfo?.beneficiaryAcctId || '',
    swiftCode:
      item?.tranInfo?.destinationCountry !== 'US'
        ? item?.tranInfo?.furtherCredit1_AccountNumber
        : item?.tranInfo?.beneficiaryAcctId,
    name:
      item?.tranInfo?.destinationCountry !== 'US' &&
      item?.tranInfo?.furtherCredit1_AccountNumber
        ? item?.tranInfo?.furtherCredit1_NameLine1
        : item?.tranInfo?.beneficiaryName || '',
    line1: '',
    line2: '',
    line3: ''
  }

  if (item?.tranInfo?.furtherCredit2_AccountNumber) {
    FFC2 = {
      account: item?.tranInfo?.beneficiaryAcctId,
      name: item?.tranInfo?.beneficiaryName,
      line1: item?.tranInfo?.beneficiaryNameAddrLine1,
      line2: item?.tranInfo?.beneficiaryNameAddrLine2,
      line3: item?.tranInfo?.beneficiaryNameAddrLine3,
      hasFC: item?.tranInfo?.furtherCredit2_AccountNumber ? true : false
    }

    FFC1 = {
      account: item?.tranInfo?.furtherCredit2_AccountNumber || '',
      name: item?.tranInfo?.furtherCredit2_NameLine1 || '',
      line1: item?.tranInfo?.furtherCredit2_NameLine2 || '',
      line2: item?.tranInfo?.furtherCredit2_NameLine3 || '',
      line3: item?.tranInfo?.furtherCredit2_NameLine4 || '',
      hasFC: item?.tranInfo?.furtherCredit1_AccountNumber ? true : false
    }

    beneficiary = {
      ...beneficiary,
      account: item?.tranInfo?.furtherCredit1_AccountNumber,
      name: item?.tranInfo?.furtherCredit1_NameLine1,
      line1: item?.tranInfo?.furtherCredit1_NameLine2,
      line2: item?.tranInfo?.furtherCredit1_NameLine3,
      line3: item?.tranInfo?.furtherCredit1_NameLine4
    }
  } else if (item?.tranInfo?.furtherCredit1_AccountNumber) {
    FFC1 = {
      account: item?.tranInfo?.beneficiaryAcctId || '',
      name: item?.tranInfo?.beneficiaryName || '',
      line1: item?.tranInfo?.beneficiaryNameAddrLine1 || '',
      line2: item?.tranInfo?.beneficiaryNameAddrLine2 || '',
      line3: item?.tranInfo?.beneficiaryNameAddrLine3 || '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.tranInfo?.furtherCredit1_AccountNumber,
      name: item?.tranInfo?.furtherCredit1_NameLine1,
      line1: item?.tranInfo?.furtherCredit1_NameLine2,
      line2: item?.tranInfo?.furtherCredit1_NameLine3,
      line3: item?.tranInfo?.furtherCredit1_NameLine4
    }
  } else {
    beneficiary = {
      ...beneficiary,
      name: item?.tranInfo?.beneficiaryName || '',
      line1: item?.tranInfo?.beneficiaryNameAddrLine1 || '',
      line2: item?.tranInfo?.beneficiaryNameAddrLine2 || '',
      line3: item?.tranInfo?.beneficiaryNameAddrLine3 || ''
    }
  }

  return { bene: beneficiary, FFC1: FFC1, FFC2: FFC2 }
}
export const prepareWireDataFromPastPaymnet = (
  item: IPastPayment,
  clientName: string | undefined,
  account: string | undefined
): IWireInitiationForm => {
  const { bene, FFC1, FFC2 } = switchBenePP(item)
  return {
    beneficiary: { ...bene },
    details: {
      line1: item?.tranInfo?.wireDisbursementDetail1 || '',
      line2: item?.tranInfo?.wireDisbursementDetail2 || '',
      line3: item?.tranInfo?.wireDisbursementDetail3 || '',
      line4: item?.tranInfo?.wireDisbursementDetail4 || ''
    },
    furtherCredit1: { ...FFC1 },
    furtherCredit2: { ...FFC2 },
    senderDetails: {
      account: account || item?.tranInfo?.bankAcctFromAcctId || '',
      name: clientName || '',
      isRetirementAccount: item?.tranInfo?.isIRA
    },
    wireAmount: {
      currency: 'USD',
      country: item?.tranInfo?.destinationCountry || '',
      recipientType: item?.tranInfo?.isThirdParty
        ? 'Third Party'
        : 'First Party',
      useExistingPP: true,
      useExistingSI: false
    }
  }
}

export const filterPaymentsByFilterData = (
  feePlans: PaymentRow[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feePlans]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.searchText) {
          if (
            (x[key as keyof PaymentRow] as string)
              ?.toLowerCase()
              ?.includes(data?.searchText.toLocaleLowerCase())
          ) {
            return true
          }
        }
        if (key === 'action') {
          if (data.value === 'Show Actionable') {
            return [
              'edit',
              'review',
              'reprocess',
              'reject',
              'close',
              'updfedrefid',
              'disclose',
              'acknowledge'
            ].some((action) => x?.actions?.toLowerCase().includes(action))
          } else {
            return true
          }
        } else if ((data?.value as RangeType)?.rangeKey && key === 'amount') {
          const filterValue = data.value as RangeType
          const rangeKey = filterValue?.rangeKey
          const min = isNaN(parseFloat(filterValue?.minValue?.toString() || ''))
            ? null
            : parseFloat(filterValue?.minValue?.toString() || '')
          const max = isNaN(parseFloat(filterValue?.maxValue?.toString() || ''))
            ? null
            : parseFloat(filterValue?.maxValue?.toString() || '')
          const amt = parseFloat(x?.amount?.toString() || '0')
          switch (rangeKey) {
            case '=':
              return min && amt === min
            case '>':
              return min && amt > min
            case '<':
              return min && amt < min
            case 'customNumber':
              return min && max && amt >= min && amt <= max
            default:
              return false
          }
        } else if (data?.value && !(data?.value as RangeType)?.rangeKey) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof PaymentRow] as string | number) ||
              (key === 'assignToId' ? 'Unassigned' : NoData)
          )
        } else {
          if (data?.searchText) {
            return false
          }
          return true
        }
      })
    })
  }

  return filteredData
}

export const filterPaymentsBySearchText = (
  paymentList: PaymentRow[] | undefined,
  searchText: string | undefined
): PaymentRow[] | [] => {
  if (!searchText) {
    return paymentList || []
  }
  let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  filtered =
    paymentList?.filter((item) => {
      for (const objKey in item) {
        const key = objKey as keyof typeof item
        if (
          key !== 'rowData' &&
          item[key]?.toString()?.toLowerCase().includes(lowerCaseText)
        ) {
          return true
        }
      }
      return false
    }) || []
  return filtered
}

export const filterPastPaymentBySearchText = (
  paymentList: IPastPayment[],
  searchText: string
): IPastPayment[] | [] => {
  if (!searchText) {
    return paymentList
  }
  let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  filtered = paymentList.filter((item) => {
    if (
      item?.tranInfo?.beneficiaryBankRoutingId
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryBankName
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryAcctId
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryName
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.trnAmt?.toString().includes(lowerCaseText) ||
      item?.tranInfo?.trnDateTime
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.destinationCountry
        ?.toLocaleLowerCase()
        .includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  return filtered
}

export const filterStandingInstBySearchText = (
  paymentList: IStandingInstruction[],
  searchText: string
): IStandingInstruction[] | [] => {
  if (!searchText) {
    return paymentList
  }
  let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  filtered = paymentList.filter((item) => {
    if (
      item?.routeId?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.MMBeneficiaryAccount?.BankName?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.DestinationCountry?.toLocaleLowerCase().includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  return filtered
}

export const prepareWireDataForEdit = (
  paymentMM: PaymentMM | undefined
): IWireInitiationForm => {
  return {
    wirePaymentId: paymentMM?.mmInstID,
    paymentType: paymentMM?.tranType,
    executingCustodian: paymentMM?.tranInfo?.executingCustodian,
    docInfo: paymentMM?.docInfo,
    beneficiary: {
      bankIdentifierNumber: paymentMM?.tranInfo?.beneficiaryBankRoutingId || '',
      bankName: paymentMM?.tranInfo?.beneficiaryBankName || '',
      account: paymentMM?.tranInfo?.beneficiaryAcctId || '',
      swiftCode: paymentMM?.tranInfo?.beneficiaryAcctId || '',
      name: paymentMM?.tranInfo?.beneficiaryName || '',
      line1: paymentMM?.tranInfo?.beneficiaryNameAddrLine1 || '',
      line2: paymentMM?.tranInfo?.beneficiaryNameAddrLine2 || '',
      line3: paymentMM?.tranInfo?.beneficiaryNameAddrLine3 || '',
      createNewSI: paymentMM?.tranInfo?.createNewSI || false,
      maxDistAmt: paymentMM?.tranInfo?.internalMessage
        ? paymentMM?.tranInfo?.internalMessage
        : undefined
    },
    details: {
      memo: paymentMM?.tranInfo?.memo,
      line1: paymentMM?.tranInfo?.wireDisbursementDetail1 || '',
      line2: paymentMM?.tranInfo?.wireDisbursementDetail2 || '',
      line3: paymentMM?.tranInfo?.wireDisbursementDetail3 || '',
      line4: paymentMM?.tranInfo?.wireDisbursementDetail4 || ''
    },
    furtherCredit1: {
      account: paymentMM?.tranInfo?.furtherCredit1_AccountNumber || '',
      name: paymentMM?.tranInfo?.furtherCredit1_NameLine1 || '',
      line1: paymentMM?.tranInfo?.furtherCredit1_NameLine2 || '',
      line2: paymentMM?.tranInfo?.furtherCredit1_NameLine3 || '',
      line3: paymentMM?.tranInfo?.furtherCredit1_NameLine4 || '',
      hasFC: paymentMM?.tranInfo?.furtherCredit1_AccountNumber ? true : false
    },
    furtherCredit2: {
      account: paymentMM?.tranInfo?.furtherCredit2_AccountNumber || '',
      name: paymentMM?.tranInfo?.furtherCredit2_NameLine1 || '',
      line1: paymentMM?.tranInfo?.furtherCredit2_NameLine2 || '',
      line2: paymentMM?.tranInfo?.furtherCredit2_NameLine3 || '',
      line3: paymentMM?.tranInfo?.furtherCredit2_NameLine4 || '',
      hasFC: paymentMM?.tranInfo?.furtherCredit2_AccountNumber ? true : false
    },
    senderDetails: {
      account: paymentMM?.tranInfo?.bankAcctFromAcctId || '',
      name: paymentMM?.tranInfo?.bankAcctFromName,
      isRetirementAccount: paymentMM?.tranInfo?.isIRA,
      availablecashtowithdraw: paymentMM?.tranInfo?.availablecashtowithdraw
    },
    wireAmount: {
      country: paymentMM?.tranInfo?.destinationCountry || '',
      useExistingSI: paymentMM?.tranInfo?.useExistingSI,
      standingInstructionId: paymentMM?.tranInfo?.standingInstructionId,
      currency: paymentMM?.intlMMinfo?.toCurrency || 'USD',
      recipientType: paymentMM?.tranInfo?.isThirdParty
        ? 'Third Party'
        : 'First Party',
      amount: paymentMM?.tranInfo?.trnAmt?.toString() || '',
      rate: paymentMM?.intlMMinfo?.xchngRate,
      tranDate: paymentMM?.tranDate ? new Date(paymentMM?.tranDate) : undefined,
      expirationDate: paymentMM?.expirationDate
        ? new Date(paymentMM?.expirationDate)
        : undefined,
      useType9Income: paymentMM?.tranInfo?.useType9Income,
      accountCloseout: paymentMM?.tranInfo?.accountCloseout,
      acceptsUSD: paymentMM?.tranInfo?.recipientBankAcceptUSD,
      insufFundSolutionId: paymentMM?.apprDetail?.insufFundSolution,
      insufFundComments: paymentMM?.apprDetail?.insufFund
    },
    taxWithholdings: {
      retDistCode: paymentMM?.tranInfo?.reasoncode,
      retDistReason: paymentMM?.tranInfo?.reasondesc,
      fedTaxOption: paymentMM?.tranInfo?.canfedwithhold,
      stateTaxOption: paymentMM?.tranInfo?.canstatewithhold,
      fedTaxPct: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxPct: paymentMM?.tranInfo?.statewithholdpct,
      fedTaxRate: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxRate: paymentMM?.tranInfo?.statewithholdpct,
      includeTax: !(paymentMM?.tranInfo?.istrnamtincludetaxwithhold || false),
      AMAacknowledgement: paymentMM?.tranInfo?.amAacknowledgement
    }
  }
}

export const prepareAccountSearchItem = (
  accountData: IFeeAccount
): IAccount => {
  return {
    CustodyAccount: accountData.custodyAccount,
    LegalEntityName: accountData.legalEntityName,
    registrationtype: accountData.registrationtype,
    AccountNickname: accountData.accountNickname,
    Shortname: accountData.shortname,
    ClientAdvisorID: accountData.clientAdvisorID,
    gfoCustodyAccount: accountData.gfoCustodyAccount,
    id: accountData.id,
    retirementAccount: accountData.retirementAccount
  }
}

export const prepareDisbursmentApprovalForReview = (
  paymentMM: PaymentMM | undefined
): IPaymentVerificationForm => {
  return {
    methodOfInstruction: paymentMM?.apprDetail?.instMethod || '',
    dateOfInstruction: paymentMM?.apprDetail?.instDate
      ? new Date(paymentMM?.apprDetail?.instDate)
      : undefined,
    clientAuthMethods: getClientAuthMethodsArray(paymentMM),
    clientAuthorization: paymentMM?.apprDetail?.isEauth === 'Y',
    phoneNumber: paymentMM?.apprDetail?.phoneNo || '',
    manualPhoneNo: paymentMM?.apprDetail?.phoneNo || '',
    phoneNumberSource: paymentMM?.apprDetail?.phoneSr || '',
    additinalComment: paymentMM?.apprDetail?.pwAcomment || '',
    authorizedPerson: paymentMM?.apprDetail?.instPerson || '',
    authorizedPersonCustom: paymentMM?.apprDetail?.instPerson || '',
    purposeOfFund: paymentMM?.apprDetail?.purpose,
    purposeOfFundCustom: paymentMM?.apprDetail?.purpose,
    authenticateEmployee: paymentMM?.apprDetail?.authenticateEmployee
  }
}

const getClientAuthMethodsArray = (
  paymentMM: PaymentMM | undefined
): string[] => {
  const authMethods = []
  if (paymentMM?.apprDetail?.clnDetail === 'Y') {
    authMethods.push('ClnDetail')
  }
  // if (paymentMM?.apprDetail?.isEauth === 'Y') {
  //   authMethods.push('isEauth')
  // }
  if (paymentMM?.apprDetail?.doB === 'Y') {
    authMethods.push('DoB')
  }
  if (paymentMM?.apprDetail?.inPerson === 'Y') {
    authMethods.push('inPerson')
  }
  if (paymentMM?.apprDetail?.last4SSN === 'Y') {
    authMethods.push('Last4SSN')
  }
  if (paymentMM?.apprDetail?.recentAct === 'Y') {
    authMethods.push('RecentAct')
  }

  if (paymentMM?.apprDetail?.clnRelationship === 'Y') {
    authMethods.push('ClnRelationship')
  }
  if (paymentMM?.apprDetail?.voiceRcgn === 'Y') {
    authMethods.push('VoiceRcgn')
  }
  return authMethods
}

export const clientAuthMethods: {
  key: string
  text: string
  oltKey: string
}[] = [
  // { key: 'isEauth', text: 'E-Auth', oltKey: 'oltReviewEAuth' },
  { key: 'inPerson', text: 'In-Person', oltKey: 'oltReviewInPerson' },
  { key: 'DoB', text: 'Date of Birth', oltKey: 'oltReviewDOB' },
  {
    key: 'Last4SSN',
    text: 'Last 4 digits of SSN/TIN',
    oltKey: 'oltReviewLast4SSN'
  },
  {
    key: 'RecentAct',
    text: 'Recent Account Activity',
    oltKey: 'oltReviewAcctActivity'
  },
  {
    key: 'ClnDetail',
    text: 'Client Personal Details',
    oltKey: 'oltReviewClientDetail'
  },
  {
    key: 'ClnRelationship',
    text: 'Client Household Relationship Details',
    oltKey: 'oltReviewClientRelationship'
  },
  {
    key: 'VoiceRcgn',
    text: 'Voice Recognition',
    oltKey: 'oltReviewVocieRecognition'
  }
]

export const clientInfoReviewed: { key: string; text: string; name: string }[] =
  [
    {
      key: 'ICPDetails',
      text: 'Transaction Details',
      name: 'oltReviewICPDetail'
    },
    {
      key: 'ClientLOAReview',
      text: 'Client LOA / Review',
      name: 'oltReviewClientLOA'
    },
    {
      key: 'Phone#Source',
      text: 'Phone # Source',
      name: 'oltReviewPhoneSrcCheck'
    },
    {
      key: 'ClientDataChanges',
      text: 'Client Data Changes (30 Days)',
      name: 'oltReviewClientDataChange'
    },
    {
      key: 'InLineWithPlannedPriorAct',
      text: 'In line with planned / Prior Act',
      name: 'oltReviewAsPlanned'
    },
    {
      key: 'FraudReleatedRedFlags',
      text: 'Fraud releated red flags',
      name: 'oltReviewFraudRedFlag'
    }
  ]

export const generateOLTReviewRequest = (
  reviewRequest: IPaymentReviewRequest,
  formData: IOMTApproverForm
): IPaymentReviewRequest => {
  return {
    RCMTranRefId: reviewRequest.RCMTranRefId,
    QueueId: reviewRequest.QueueId,
    Status: reviewRequest.Status,
    Comment: reviewRequest.Comment,
    oltReviewBy: formData?.loggedInUser,
    oltReviewWaiveCallback: formData?.waiveCallback || false,
    oltReviewCallBackPhone:
      formData?.phoneNumber === 'Other'
        ? formData?.manualPhoneNo?.replace(/[^\d]/g, '') || ''
        : formData?.phoneNumber?.replace(/[^\d]/g, '') || '',
    oltReviewPhoneNumberSrc:
      formData?.phoneNumber === 'Other'
        ? formData?.phoneSource || ''
        : undefined,
    oltReviewCallBackDate: formData?.dateOfCallback
      ? format(formData?.dateOfCallback, 'MM/dd/yyyy')
      : undefined,
    oltReviewEAuth: formData?.clientAuthenticationMethods?.includes('isEauth')
      ? 'Y'
      : undefined,
    oltReviewInPerson: formData?.clientAuthenticationMethods?.includes(
      'inPerson'
    )
      ? 'Y'
      : undefined,
    oltReviewDOB: formData?.clientAuthenticationMethods?.includes('DoB')
      ? 'Y'
      : undefined,
    oltReviewLast4SSN: formData?.clientAuthenticationMethods?.includes(
      'Last4SSN'
    )
      ? 'Y'
      : undefined,
    oltReviewAcctActivity: formData?.clientAuthenticationMethods?.includes(
      'RecentAct'
    )
      ? 'Y'
      : undefined,
    oltReviewClientDetail: formData?.clientAuthenticationMethods?.includes(
      'ClnDetail'
    )
      ? 'Y'
      : undefined,
    oltReviewClientRelationship:
      formData?.clientAuthenticationMethods?.includes('ClnRelationship')
        ? 'Y'
        : undefined,
    oltReviewVocieRecognition: formData?.clientAuthenticationMethods?.includes(
      'VoiceRcgn'
    )
      ? 'Y'
      : undefined,
    oltReviewICPDetail: formData?.clientInformationInstructions?.includes(
      'ICPDetails'
    )
      ? 'Y'
      : undefined,
    oltReviewClientLOA: formData?.clientInformationInstructions?.includes(
      'ClientLOAReview'
    )
      ? 'Y'
      : undefined,
    oltReviewPhoneSrcCheck: formData?.clientInformationInstructions?.includes(
      'Phone#Source'
    )
      ? 'Y'
      : undefined,
    oltReviewClientDataChange:
      formData?.clientInformationInstructions?.includes('ClientDataChanges')
        ? 'Y'
        : undefined,
    oltReviewAsPlanned: formData?.clientInformationInstructions?.includes(
      'InLineWithPlannedPriorAct'
    )
      ? 'Y'
      : undefined,
    oltReviewFraudRedFlag: formData?.clientInformationInstructions?.includes(
      'FraudReleatedRedFlags'
    )
      ? 'Y'
      : undefined,
    oltReviewInstrProvidedBy:
      formData?.authorizedPerson === 'Other'
        ? formData?.authorizedPersonCustom || ''
        : formData?.authorizedPerson || ''
  }
}

export const getLocalDateTime = (value: string) => {
  const offset = new Date().getTimezoneOffset()
  const utcDate = new Date(value)
  return new Date(utcDate.setMinutes(utcDate.getMinutes() - offset))
}

export const getMMValidateReguestForHoldings = (
  account: string
): IMMValidateRequest => {
  return {
    moneymovementid: '1',
    ismodeone: true,
    fromaccount: {
      accountnumber: account,
      isira: true,
      accounttype: '',
      abanumber: '',
      isexternal: false,
      bankname: ''
    },
    toaccount: {
      accountnumber: '',
      isthirdparty: false,
      isira: false
    },
    iseft: false,
    isjnl: false,
    isira: true,
    iswire: true
  }
}

export const getMMValidateRequestForCountryList = (
  account: string
): IMMValidateRequest => {
  return {
    ismodeone: true,
    moneymovementid: guid(),
    fromaccount: {
      accountnumber: account || undefined,
      isexternal: false
    },
    toaccount: {
      accountnumber: '',
      isthirdparty: false,
      isira: false
    },
    iseft: false,
    isjnl: false,
    iswire: true,
    retriveCountryList: true,
    loi: false
  }
}

export const generateMMValidateRequestForWire = (
  wireData: IWireInitiationForm
): IMMValidateRequest => {
  const request: IMMValidateRequest = {
    ismodeone: false,
    moneymovementid: guid(),
    fromaccount: {
      accountnumber: wireData?.senderDetails?.account || undefined,
      isexternal: false,
      isira: wireData?.senderDetails?.isRetirementAccount || false
    },
    toaccount: {
      accountnumber: wireData?.beneficiary?.account || undefined,
      accountname: wireData?.beneficiary?.name || undefined,
      bankidtype: wireData?.wireAmount?.country === 'US' ? 'F' : 'S',
      abanumber:
        wireData?.wireAmount?.country === 'US'
          ? wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ||
            undefined
          : undefined,
      swiftcode:
        wireData?.wireAmount?.country !== 'US'
          ? wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ||
            undefined
          : undefined,
      bankname: wireData?.beneficiary?.bankName || undefined,
      isthirdparty:
        wireData?.wireAmount?.recipientType === 'Third Party' ? true : false,
      isexternal: true
    },
    BenNameAddrLine1: wireData?.beneficiary?.name || undefined,
    BenNameAddrLine2: wireData?.beneficiary?.line1 || undefined,
    BenNameAddrLine3: wireData?.beneficiary?.line2 || undefined,
    BenNameAddrLine4: wireData?.beneficiary?.line3 || undefined,
    FurtherCredit1_AccountNumber: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.account || undefined
      : undefined,
    FurtherCredit1_NameLine1: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.name || undefined
      : undefined,
    FurtherCredit1_NameLine2: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line1 || undefined
      : undefined,
    FurtherCredit1_NameLine3: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line2 || undefined
      : undefined,
    FurtherCredit1_NameLine4: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line3 || undefined
      : undefined,
    FurtherCredit2_AccountNumber: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.account || undefined
      : undefined,
    FurtherCredit2_NameLine1: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.name || undefined
      : undefined,
    FurtherCredit2_NameLine2: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line1 || undefined
      : undefined,
    FurtherCredit2_NameLine3: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line2 || undefined
      : undefined,
    FurtherCredit2_NameLine4: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line3 || undefined
      : undefined,
    wiredisbursementdetail1: wireData?.details?.line1 || undefined,
    wiredisbursementdetail2: wireData?.details?.line2 || undefined,
    wiredisbursementdetail3: wireData?.details?.line3 || undefined,
    wiredisbursementdetail4: wireData?.details?.line4 || undefined,
    OvernightCheck: false,
    amount: getAmountForMMValidate(wireData),
    closeout: wireData?.wireAmount?.accountCloseout ? 'Y' : undefined,
    destinationcountry: wireData?.wireAmount?.country || undefined,
    iscancel: false,
    isfuturedated: false,
    iseft: false,
    isira: wireData?.senderDetails?.isRetirementAccount,
    retdistreason:
      wireData?.senderDetails?.isRetirementAccount &&
      wireData?.taxWithholdings?.retDistReason
        ? [
            {
              reasoncode: wireData?.taxWithholdings?.retDistCode,
              reasondesc: wireData?.taxWithholdings?.retDistReason
            }
          ]
        : undefined,
    isjnl: false,
    AMAacknowledgement: wireData?.taxWithholdings?.AMAacknowledgement,
    iswire: true,
    loi: false,
    // source: 'branch',
    portalid: undefined,
    transactiondate: wireData?.wireAmount?.tranDate
      ? format(wireData?.wireAmount?.tranDate, 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    fedwithholdpct: wireData?.senderDetails?.isRetirementAccount
      ? Number(wireData?.taxWithholdings?.fedTaxRate)
      : undefined,
    statewithholdpct: wireData?.senderDetails?.isRetirementAccount
      ? Number(wireData?.taxWithholdings?.stateTaxRate)
      : undefined,
    istrnamtincludetaxwithhold: wireData?.senderDetails?.isRetirementAccount
      ? !(wireData?.taxWithholdings?.includeTax || false)
      : false
  }
  if (wireData.wireType === 'C') {
    if (request.toaccount) {
      request.toaccount.bankidtype = 'F'
      request.toaccount.swiftcode = wireData?.beneficiary?.swiftCode || ''
      request.toaccount.abanumber =
        wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ||
        undefined
      request.toaccount.accountnumber = wireData?.beneficiary?.swiftCode || ''
    }
  }

  return updateMappingsBeforeValidate(request, wireData)
}

export const guid = () => {
  const uuidFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  const nums = window.crypto.getRandomValues(
    new Uint8ClampedArray(uuidFormat.split(/[xy]/).length - 1)
  )
  let pointer = 0
  return uuidFormat.replace(/[xy]/g, function (c) {
    const r = nums[pointer++] % 16,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const checkObjectPropertiesHasData = (object: any) => {
  let result = false
  if (object) {
    const keys = Object.keys(object)
    const properyCheck = keys?.map((key) => {
      return (
        object[key] !== null && object[key] !== undefined && object[key] !== ''
      )
    })
    result = properyCheck.includes(true)
  }
  return result
}
export const truncateFFCFields = (
  value: string | undefined,
  len?: number | 34
) => {
  if (value) {
    return value.substring(0, len)
  }
  return ''
}

export const isValidCharacter = (value: string | undefined) => {
  const pattern = /[a-z A-Z 0-9 ? : , ` s \- ' .]/g
  if (value) {
    if (pattern.test(value.substring(value.length - 1))) {
      return value
    }
  }
  const newVal = value?.substring(0, value.length - 1)
  return newVal
}
