import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  makeStyles,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { startOfQuarter, addQuarters, format } from 'date-fns/fp'
import { isEqual, range, uniqBy } from 'lodash'
import { flow } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HouseholdFeeType, IHouseholdFee } from '../../../../api/households'
import { parseDateISOStringInLocalTimezone } from '../../../../shared'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { Separator } from '../../../../shared/components/Separator'
import { RdotUserRoleEnum } from '../../../../store/user/rdotUser'
import { getRdotUserRoles } from '../../../../store/user/selectors'
import {
  accountFetchActions,
  getAccountsResult,
  getIsAccountsLoading
} from '../../store/householdAccountsFetch'
import {
  getHouseholdDepartmentFetchResult,
  getIsHouseholdDepartmentFetchLoading,
  householdDepartmentFetchActions
} from '../../store/householdDepartmentsFetch'
import { getHouseholdResult } from '../../store/householdFetch'
import { HouseholdFeeAccountList } from './HouseholdFeeAccountList'
import {
  getHouseholdFeeDetails,
  getHouseholdFeePanelError,
  getIsHouseholdFeePanelLoading,
  getIsHouseholdFeePanelOpen,
  getIsHouseholdFeePanelUpdating,
  householdFeePanelActions
} from './store/householdFeePanel'

const useClasses = makeStyles((theme) => ({
  panelSection: {
    border: `solid 1px ${theme.palette.neutralQuaternaryAlt}`
  }
}))

const methodOfDebitOptions: IDropdownOption[] = [
  { key: 'Production', text: 'Production' },
  { key: 'BDA', text: 'BDA' },
  { key: 'Client', text: 'Client' }
]

const inputWidth = '250px'

export const HouseholdFeePanel: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsHouseholdFeePanelLoading)
  const isUpdating = useSelector(getIsHouseholdFeePanelUpdating)
  const isPanelOpen = useSelector(getIsHouseholdFeePanelOpen)
  const householdFee = useSelector(getHouseholdFeeDetails)
  const household = useSelector(getHouseholdResult)
  const error = useSelector(getHouseholdFeePanelError)
  const accounts = useSelector(getAccountsResult)
  const isAccountFetchLoading = useSelector(getIsAccountsLoading)
  const departments = useSelector(getHouseholdDepartmentFetchResult)
  const isDepartmentsLoading = useSelector(getIsHouseholdDepartmentFetchLoading)
  const classes = useClasses()
  const userRoles = useSelector(getRdotUserRoles)
  const canModifyFee = useMemo(
    () => userRoles?.includes(RdotUserRoleEnum.IEX_User),
    [userRoles]
  )

  const onDismiss = useCallback(() => {
    dispatch(householdFeePanelActions.closePanel())
  }, [dispatch])

  const [householdFeeCopy, setHouseholdFeeCopy] = useState<IHouseholdFee>()

  useEffect(() => {
    if (!householdFee) {
      return
    }

    setHouseholdFeeCopy({
      ...householdFee,
      chargeType: householdFee?.chargeType || 'Production'
    })
  }, [householdFee])

  useEffect(() => {
    if (isPanelOpen) {
      return
    }

    setHouseholdFeeCopy(undefined)
  }, [isPanelOpen])

  useEffect(() => {
    if (!isPanelOpen) {
      return
    }

    if (!householdFeeCopy?.householdId) {
      return
    }

    dispatch(accountFetchActions.request(householdFeeCopy?.householdId))
  }, [dispatch, householdFeeCopy?.householdId, isPanelOpen])

  const onHouseholdFeeTypeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      const newType = option?.key as HouseholdFeeType
      setHouseholdFeeCopy({
        ...householdFeeCopy,
        chargeType: newType,
        chargeAccount: householdFee?.chargeAccount,
        primaryChargeToDept: householdFee?.primaryChargeToDept,
        primaryChargeToRep: householdFee?.primaryChargeToRep
      })
    },
    [householdFee, householdFeeCopy]
  )
  const onNextChargeDateChanged = useCallback(
    (ev: any, option?: IDropdownOption) => {
      const newDate = option?.key as string
      setHouseholdFeeCopy({
        ...householdFeeCopy,
        HHNextBillingDate: newDate
      })
    },
    [householdFeeCopy]
  )

  useEffect(() => {
    if (
      householdFeeCopy?.chargeType === 'BDA' &&
      householdFeeCopy?.householdId
    ) {
      dispatch(
        householdDepartmentFetchActions.request(householdFeeCopy.householdId)
      )
    }
  }, [dispatch, householdFeeCopy?.chargeType, householdFeeCopy?.householdId])

  const onSelectedFeeAccountChanged = useCallback(
    (chargeAccount: string) => {
      setHouseholdFeeCopy({ ...householdFeeCopy, chargeAccount })
    },
    [householdFeeCopy]
  )

  const onPrimaryChargeToRepCodeChanged = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      setHouseholdFeeCopy({
        ...householdFeeCopy,
        primaryChargeToRep: option?.key as string
      })
    },
    [householdFeeCopy]
  )

  const onPrimaryChargeToDepartmentChanged = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      setHouseholdFeeCopy({
        ...householdFeeCopy,
        primaryChargeToDept: option?.key as string
      })
    },
    [householdFeeCopy]
  )
  const onAnnualHHFeeChange = useCallback(
    (ev?: any, val?: string) => {
      const float = parseFloat(val || '')

      setHouseholdFeeCopy({
        ...householdFeeCopy,
        annualHHFee: isNaN(float) ? undefined : float
      })
    },
    [householdFeeCopy]
  )

  const onSubmit = useCallback(() => {
    if (!householdFeeCopy) {
      return
    }
    dispatch(householdFeePanelActions.submit(householdFeeCopy))
  }, [dispatch, householdFeeCopy])

  const onRenderFooterContent = useCallback(() => {
    const canSubmit = householdFee && !isEqual(householdFee, householdFeeCopy)
    const isValid =
      (householdFeeCopy?.chargeType === 'Production' ||
        (householdFeeCopy?.chargeType === 'BDA' &&
          householdFeeCopy?.primaryChargeToDept) ||
        (householdFeeCopy?.chargeType === 'Client' &&
          householdFeeCopy?.chargeAccount)) &&
      (!canModifyFee ||
        (householdFeeCopy?.annualHHFee != null &&
          householdFeeCopy?.HHNextBillingDate != null))
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            An error occurred while submitting the request
            {error.message ? `: ${error.message}` : ''}
          </MessageBar>
        )}
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            onClick={onSubmit}
            disabled={!canSubmit || !isValid || isLoading || isUpdating}
          >
            Submit
          </PrimaryButton>

          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    )
  }, [
    canModifyFee,
    error,
    householdFee,
    householdFeeCopy,
    isLoading,
    isUpdating,
    onDismiss,
    onSubmit
  ])

  const activeAccounts = useMemo(
    () => accounts?.filter(({ accountStatus }) => accountStatus === 'Open'),
    [accounts]
  )

  const onRenderHeader = useCallback(
    () => (
      <div
        style={{
          alignSelf: 'flex-start',
          flexGrow: 1,
          padding: '0 0 5px 24px'
        }}
      >
        <Stack>
          <Text variant="xLarge" styles={{ root: { fontWeight: 'bold' } }}>
            Household Billing
          </Text>
          {!isLoading && (
            <Text variant="small">{household?.householdName}</Text>
          )}
        </Stack>
      </div>
    ),
    [household?.householdName, isLoading]
  )

  const repDropdownOptions = useMemo(
    () =>
      uniqBy(accounts, ({ ClientAdvisorID }) => ClientAdvisorID)
        .map(
          ({ ClientAdvisor, ClientAdvisorID }): IDropdownOption => ({
            key: ClientAdvisorID || '',
            text: `${ClientAdvisorID} - ${ClientAdvisor}`
          })
        )
        .filter(({ key }) => key),
    [accounts]
  )

  const departmentDropdownOptions = useMemo(
    () =>
      uniqBy(departments, ({ number }) => number)
        .map(
          ({ name, number }): IDropdownOption => ({
            key: number || '',
            text: `${number} - ${name}`
          })
        )
        .filter(({ key }) => key),
    [departments]
  )

  const chargeDateOptions = useMemo(
    () =>
      range(1, 6)
        .map((offset) => flow(startOfQuarter, addQuarters(offset))(new Date()))
        .map(
          (date): IDropdownOption => ({
            key: format('yyyy-MM-dd')(date),
            text: format('MM/dd/yyyy')(date)
          })
        ),
    []
  )

  return (
    <Panel
      onRenderHeader={onRenderHeader}
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      type={PanelType.custom}
      customWidth="500px"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
    >
      {(isLoading || isUpdating) && (
        <Overlay styles={{ root: { zIndex: 11 } }}>
          <LoadingComponent />
        </Overlay>
      )}
      <div style={{ marginTop: '20px' }}>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label required>Next Billing Cycle Charge Date</Label>
            <Dropdown
              disabled={!canModifyFee}
              styles={{ root: { width: inputWidth } }}
              selectedKey={
                householdFeeCopy?.HHNextBillingDate
                  ? flow(
                      parseDateISOStringInLocalTimezone,
                      format('yyyy-MM-dd')
                    )(householdFeeCopy?.HHNextBillingDate)
                  : ''
              }
              options={chargeDateOptions}
              onChange={onNextChargeDateChanged}
            />
          </Stack>
          <Stack>
            <Label required>Annual HH Fee</Label>
            <TextField
              type="number"
              disabled={!canModifyFee}
              styles={{ root: { width: inputWidth } }}
              placeholder="Enter Fee Amount (250)"
              value={`${householdFeeCopy?.annualHHFee ?? ''}`}
              onChange={onAnnualHHFeeChange}
            />
          </Stack>
          <Stack>
            <Label required>Select Billing Methodology</Label>
            <Dropdown
              styles={{ root: { width: inputWidth } }}
              selectedKey={householdFeeCopy?.chargeType}
              options={methodOfDebitOptions}
              onChange={onHouseholdFeeTypeChange}
            />
          </Stack>
          {householdFeeCopy?.chargeType === 'BDA' && (
            <>
              <Separator />
              <Stack>
                <Label required>Select Primary Billing Department</Label>
                <Stack styles={{ root: { width: inputWidth } }}>
                  <ProgressIndicator
                    progressHidden={!isDepartmentsLoading}
                    styles={{ itemProgress: { padding: 0, margin: 0 } }}
                  />
                  <Dropdown
                    selectedKey={householdFeeCopy?.primaryChargeToDept}
                    disabled={isDepartmentsLoading}
                    options={departmentDropdownOptions}
                    onChange={onPrimaryChargeToDepartmentChanged}
                  />
                </Stack>
              </Stack>
            </>
          )}
          {householdFeeCopy?.chargeType === 'Production' && (
            <>
              <Separator />
              <Stack>
                <Label>Select Primary Billing Rep</Label>
                <Dropdown
                  styles={{ root: { width: inputWidth } }}
                  selectedKey={householdFeeCopy?.primaryChargeToRep}
                  options={repDropdownOptions}
                  onChange={onPrimaryChargeToRepCodeChanged}
                />
              </Stack>
            </>
          )}
          {householdFeeCopy?.chargeType === 'Client' && (
            <>
              <Separator />
              <Stack>
                <Label required>Select Billing Account</Label>
                <div className={classes.panelSection}>
                  <HouseholdFeeAccountList
                    accounts={activeAccounts}
                    loading={isAccountFetchLoading}
                    selectedAccount={householdFeeCopy?.chargeAccount}
                    onSelectionChanged={onSelectedFeeAccountChanged}
                  />
                </div>
              </Stack>
            </>
          )}
        </Stack>
      </div>
    </Panel>
  )
}
