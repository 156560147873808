import axios from 'axios'
import { flow } from 'lodash/fp'
import { stringify } from 'query-string'
import { combineReducers } from 'redux'
import { call, cancelled, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IClient } from '../../api/client.types'
import { findContactsByEmailAddress, IContact } from '../../api/dynamics'
import { getAllEmails } from '../../shared/client'
import { IApiOptions } from '../../shared/contracts/IApiOptions'
import {
  IEnvironmentApiConfiguration,
  IEnvironmentAppConfigurations
} from '../../shared/services/environment/IEnvironmentConfiguration'
import { navigate } from '../../shared/services/navigation'
import { AppState } from '../../store'
import { tryAcquireAccessToken } from '../../store/shared/sagas'
import {
  getDynamicsCrmApiConfig,
  getEnvironmentConfigApps
} from '../../store/system'
import { IUserPreferences } from '../../store/user/preferenceUser'
import { getPreferenceUserPreferences } from '../../store/user/selectors'

const NAVIGATE_TO_CONTACT = '@features/@contact/NAVIGATE_TO_CONTACT'
const OPEN_CONTACT_IN_DYNAMICS = '@features/@contact/OPEN_CONTACT_IN_DYNAMICS'

export const contactNavigationActions = {
  navigateToContact: createAction(NAVIGATE_TO_CONTACT)<IClient | undefined>(),
  openContactInDynamics: createAction(OPEN_CONTACT_IN_DYNAMICS)<string>()
}

const SEARCH_CONTACTS_REQUESTED = '@features/@contact/SEARCH_CONTACTS_REQUESTED'
const SEARCH_CONTACTS_SUCCESS = '@features/@contact/SEARCH_CONTACTS_SUCCESS'
const SEARCH_CONTACTS_FAILURE = '@features/@contact/SEARCH_CONTACTS_FAILURE'

export const contactSearchActions = {
  request: createAction(SEARCH_CONTACTS_REQUESTED)<string[] | undefined>(),
  success: createAction(SEARCH_CONTACTS_SUCCESS)<IContact[] | undefined>(),
  failure: createAction(SEARCH_CONTACTS_FAILURE)<Error>()
}

export type ContactActionTypes =
  | ActionType<typeof contactNavigationActions>
  | ActionType<typeof contactSearchActions>

export interface IContactSearchState {
  loading?: boolean
  items?: IContact[]
  error?: Error
}

const initialState: IContactSearchState = {
  loading: false
}

const contactSearchReducer = createReducer<
  IContactSearchState,
  ContactActionTypes
>(initialState)
  .handleAction(contactSearchActions.request, (state) => {
    return {
      ...state,
      loading: true
    }
  })
  .handleAction(contactSearchActions.failure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload
    }
  })
  .handleAction(contactSearchActions.success, (state, action) => {
    return {
      ...state,
      items: action.payload,
      loading: false
    }
  })

const OPEN_CONTACT_PANEL = '@features/@contact/OPEN_CONTACT_PANEL'
const CLOSE_CONTACT_PANEL = '@features/@contact/CLOSE_CONTACT_PANEL'
const SUCCESS = '@features/@contact/SUCCESS'

export interface IOpenContactPanelActionPayload {
  title?: string
  contacts?: IContact[]
}

export const contactPanelActions = {
  open: createAction(OPEN_CONTACT_PANEL)<
    IOpenContactPanelActionPayload | undefined
  >(),
  success: createAction(SUCCESS)<IOpenContactPanelActionPayload | undefined>(),
  close: createAction(CLOSE_CONTACT_PANEL)()
}

export interface IContactPanelState extends IOpenContactPanelActionPayload {
  open?: boolean
  loading?: boolean
}

const contactPanelInitialState: IContactPanelState = {
  open: false,
  loading: false
}

const contactPanelReducer = createReducer<
  IContactPanelState,
  ActionType<typeof contactPanelActions>
>(contactPanelInitialState)
  .handleAction(contactPanelActions.open, (state, action) => ({
    ...state,
    open: true,
    loading: true,
    ...action.payload
  }))
  .handleAction(contactPanelActions.success, (state, action) => ({
    ...state,
    open: true,
    loading: false,
    ...action.payload
  }))
  .handleAction(contactPanelActions.close, (state) => ({
    ...state,
    open: false,
    title: undefined,
    contacts: undefined
  }))

const panelRootSelector = (state: AppState) => state.features.contact.panel
export const getContactPanelContacts = flow(
  panelRootSelector,
  (x) => x.contacts
)
export const getIsContactPanelOpen = flow(panelRootSelector, (x) => x.open)
export const getContactPanelTitle = flow(panelRootSelector, (x) => x.title)
export const getIsContactPanelLoading = flow(
  panelRootSelector,
  (x) => x.loading
)

export const contactReducer = combineReducers({
  search: contactSearchReducer,
  panel: contactPanelReducer
})

const findContacts = function* (emails: string[]) {
  const apiConfig: IEnvironmentApiConfiguration = yield select(
    getDynamicsCrmApiConfig
  )
  const accessToken: string = yield call(
    tryAcquireAccessToken,
    apiConfig.scopes
  )

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  const apiOptions: IApiOptions = {
    accessToken,
    apiRoot: apiConfig.root,
    cancelToken: source.token
  }

  try {
    const contacts: IContact[] = yield call(
      findContactsByEmailAddress,
      apiOptions,
      emails
    )
    return contacts
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

const fetchContacts = function* (
  action: ReturnType<typeof contactSearchActions.request>
) {
  if (!action?.payload?.length) {
    return
  }

  try {
    const contacts: IContact[] = yield call(findContacts, action.payload)
    yield put(contactSearchActions.success(contacts))
  } catch (e: any) {
    yield put(contactSearchActions.failure(e))
  }
}

const navigateToContact = function* (
  action: ReturnType<typeof contactNavigationActions.navigateToContact>
) {
  if (!action?.payload) {
    return
  }

  yield put(
    contactPanelActions.open({
      title: `Related Contacts for ${action.payload.LegalEntityName}`
    })
  )

  const emails = getAllEmails(action.payload)

  let contacts: IContact[] = []
  try {
    contacts = yield call(findContacts, emails)
  } catch (e: any) {
    yield put(contactSearchActions.failure(e))
  }

  yield put(
    contactPanelActions.success({
      title: `Related Contacts for ${action.payload.LegalEntityName}`,
      contacts
    })
  )
}

const openContactInDynamics = function* (
  action: ReturnType<typeof contactNavigationActions.openContactInDynamics>
) {
  const apps: IEnvironmentAppConfigurations = yield select(
    getEnvironmentConfigApps
  )
  const { url, params } = apps['dynamics-wealth-management']

  const qs = stringify({
    ...params,
    pagetype: 'entityrecord',
    etn: 'contact',
    id: action.payload
  })

  const preferences: IUserPreferences = yield select(
    getPreferenceUserPreferences
  )
  navigate(`${url}?${qs}`, { openInNewWindow: preferences.navigateInNewWindow })

  yield
}

export const contactSagas = [
  () => takeLatest(contactSearchActions.request, fetchContacts),
  () =>
    takeLatest(contactNavigationActions.navigateToContact, navigateToContact),
  () =>
    takeLatest(
      contactNavigationActions.openContactInDynamics,
      openContactInDynamics
    )
]
