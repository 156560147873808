import { css, Theme, useTheme } from '@emotion/react'
import { format, lastDayOfMonth, subDays } from 'date-fns'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNullOrEmpty } from 'shared/gaurds'
import { Dropdown } from '../../../components/shared'
import { buttonStyles } from '../../../components/shared/Buttons'
import { IDropdownOption } from '../../../components/shared/Dropdown/Dropdown'
import { inputClasses } from '../../../components/shared/Inputs'
import { Icon } from '../../../features/Icons/Icon'
import { useDownloadAttachment } from '../../../hooks/useDownloadAttachment'
import { useLazyRetrieveCombinedDocumentQueryForSelectedAccounts } from '../../../store/rdot360Context/useRdot360DocumentsContext'
import {
  documentFilterActions,
  getDateRange,
  getSelectedDateRangeDropDownOption,
  getIsDownloading,
  getDocumentSelection,
  getShowAllAccounts
} from './store'

export const getClasses = (theme: Theme) => ({
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    marginTop: 51,
    color: theme.colors.primaryBlack
  }),
  dateRangeDropdown: css({
    width: '184px',
    marginTop: '8px'
  }),
  iconContainer: css({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
    marginTop: 50
  }),
  iconStyleDisable: css({
    width: 24,
    height: 24,
    marginLeft: '20px',
    opacity: '0.5'
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginLeft: '20px'
  }),
  datePickerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    marginTop: 22,
    '& .ms-TextField-fieldGroup': {
      height: '100%',
      padding: 5,
      borderRadius: 8,
      width: '200px',
      marginRight: 8,
      marginLeft: 8
    }
  })
})

const statementDateRangeOptions: IDropdownOption[] = [
  { key: 'mostRecent', text: 'Most Recent' },
  {
    key: 'prior3Months',
    text: 'Prior 3 Months'
  },
  {
    key: 'prior6Months',
    text: 'Prior 6 Months'
  },
  {
    key: 'prior12Months',
    text: 'Prior 12 Months'
  },
  {
    key: 'currentYear',
    text: 'Current Year'
  },
  {
    key: 'selectMonth',
    text: 'Select Month/Year'
  }
]
const currentYear = new Date().getFullYear()

const taxDateRangeOptions: IDropdownOption[] = [
  { key: currentYear, text: `${currentYear}` },
  {
    key: currentYear - 1,
    text: `${currentYear - 1}`
  },
  {
    key: currentYear - 2,
    text: `${currentYear - 2}`
  },
  {
    key: currentYear - 3,
    text: `${currentYear - 3}`
  },
  {
    key: currentYear - 4,
    text: `${currentYear - 4}`
  },
  {
    key: currentYear - 5,
    text: `${currentYear - 5}`
  }
]

const documentRangeOptions: { [key: string]: IDropdownOption[] } = {
  Statements: statementDateRangeOptions,
  'Tax Documents': taxDateRangeOptions,
  Correspondence: statementDateRangeOptions,
  'Trade Confirmations': statementDateRangeOptions
}

const DocumentFilter: FC<{ documentTabType: string }> = ({
  documentTabType
}) => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  const dispatch = useDispatch()
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [dateRangeSelected, setDateRange] = useState<any>({})
  const { setIsDownloading } = documentFilterActions
  const selectedDropDownOption = useSelector(
    getSelectedDateRangeDropDownOption(documentTabType)
  )
  const selectedDateRange = useSelector(getDateRange(documentTabType))
  const isDownloading = useSelector(getIsDownloading)
  const { downloadAttachment } = useDownloadAttachment()
  const showAllAccounts = useSelector(getShowAllAccounts())

  const dispatchDateRange = useCallback(
    (documentTabType: string, dateRange: number) => {
      dispatch(
        documentFilterActions.setDateRange({
          documentTabType,
          selectBeginDate: format(
            new Date(year, month - dateRange),
            'MM/01/yyyy'
          ),
          selectedEndDate: format(
            lastDayOfMonth(new Date(year, month - 1)),
            'MM/dd/yyyy'
          )
        })
      )
    },
    [dispatch, month, year]
  )

  const handlDateRangeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      dispatch(
        documentFilterActions.setSelectedDateRangeDropDownOption({
          documentTabType,
          selectedOption: option
        })
      )

      if (documentTabType !== 'Tax Documents') {
        if (option) {
          switch (option?.key) {
            case 'mostRecent':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate: format(
                    subDays(new Date(), 30),
                    'MM/dd/yyyy'
                  ),
                  selectedEndDate: format(new Date(), 'MM/dd/yyyy')
                })
              )
              break
            case 'prior3Months':
              dispatchDateRange(documentTabType, 3)
              break
            case 'prior6Months':
              dispatchDateRange(documentTabType, 6)
              break
            case 'prior12Months':
              dispatchDateRange(documentTabType, 12)
              break
            case 'currentYear':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate: format(new Date(), '01/01/yyyy'),
                  selectedEndDate: format(new Date(), 'MM/dd/yyyy')
                })
              )
              break
          }
        }
      } else if (documentTabType === 'Tax Documents') {
        if (option) {
          dispatch(
            documentFilterActions.setDateRange({
              documentTabType,
              selectBeginDate: format(new Date(+option.key, 0), '01/01/yyyy'),
              selectedEndDate: format(
                lastDayOfMonth(new Date(+option.key, 11)),
                'MM/dd/yyyy'
              )
            })
          )
        }
      }
    },
    [dispatch, dispatchDateRange, documentTabType]
  )

  const handleApplyMonthSelection = useCallback(() => {
    const dateParts = selectedMonth.split('-')
    const dateSelected = new Date(+dateParts[0], +dateParts[1] - 1, 1)
    const startDate = format(dateSelected, 'MM/dd/yyyy')
    const endDate = format(
      new Date(dateSelected.getFullYear(), dateSelected.getMonth() + 1, 0),
      'MM/dd/yyyy'
    )
    dispatch(
      documentFilterActions.setDateRange({
        documentTabType,
        selectBeginDate: startDate,
        selectedEndDate: endDate
      })
    )
  }, [documentTabType, selectedMonth, dispatch])

  const [triggerCombinedDownload] =
    useLazyRetrieveCombinedDocumentQueryForSelectedAccounts()
  const selection = useSelector(getDocumentSelection)

  const handleDownloadClick = useCallback(async () => {
    if (!selection.length) {
      return
    }
    dispatch(setIsDownloading(true))

    const items = selection
      .map((x) => {
        const [first, ...rest] = x.split(':')
        return [first, rest?.join(':')].filter(isNotNullOrEmpty)
      })
      .filter((x) => x.length === 2)
      .map(([key, val]) => ({ [key]: val }))

    const { data } = await triggerCombinedDownload({
      VirtualTable: 'NF_STATEMENTS',
      PrimaryType: 'Account',
      PrimaryValue: items
    })

    downloadAttachment(
      data?.ContentStream || '',
      'application/pdf',
      'Combined Document'
    )
    dispatch(setIsDownloading(false))
  }, [
    dispatch,
    downloadAttachment,
    selection,
    setIsDownloading,
    triggerCombinedDownload
  ])

  const onMonthChange = useCallback((event: any) => {
    setSelectedMonth(event.target.value)
  }, [])

  const handleShowAllAccountsChange = useCallback(
    (event: any) => {
      dispatch(
        documentFilterActions.setShowAllAccounts({
          show: event.target.checked
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (!selectedDateRange.searchDateBegin) {
      switch (documentTabType) {
        case 'Statements':
          dispatchDateRange('Statements', 3)
          break
        case 'Tax Documents':
          dispatch(
            documentFilterActions.setDateRange({
              documentTabType: 'Tax Documents',
              selectBeginDate: format(new Date(), '01/01/yyyy'),
              selectedEndDate: format(new Date(), '12/31/yyyy')
            })
          )
          break
        case 'Correspondence':
          dispatchDateRange('Correspondence', 3)
          break
        case 'Trade Confirmations':
          dispatchDateRange('Trade Confirmations', 3)
          break
      }
    }
  }, [dispatch, dispatchDateRange, documentTabType, selectedDateRange])

  useEffect(() => {
    if (selectedDateRange.searchDateBegin && selectedDateRange.searchDateEnd) {
      setDateRange({
        startDate: format(
          new Date(selectedDateRange.searchDateBegin),
          'yyyy-MM-dd'
        ),
        endDate: format(new Date(selectedDateRange.searchDateEnd), 'yyyy-MM-dd')
      })
    }
  }, [
    documentTabType,
    selectedDateRange.searchDateBegin,
    selectedDateRange.searchDateEnd
  ])

  return (
    <>
      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div css={{ display: 'flex', alignItems: 'flex-end' }}>
          <label
            htmlFor="#showAllAccountsCheckbox"
            css={{ marginTop: 'auto', display: 'flex', alignItems: 'flex-end' }}
          >
            <input
              css={{
                cursor: 'pointer',
                padding: 0,
                margin: 0,
                marginRight: 8,
                height: 18,
                width: 18
              }}
              type="checkbox"
              id="showAllAccountsCheckbox"
              checked={showAllAccounts}
              onChange={handleShowAllAccountsChange}
            />
            Show all accounts
          </label>
        </div>
        <div css={classes.dateRangeContainer}>
          <div>Date Range</div>
          <div css={classes.dateRangeDropdown}>
            <Dropdown
              options={documentRangeOptions[documentTabType]}
              defaultSelectedOption={selectedDropDownOption}
              onChange={handlDateRangeChange}
            />
          </div>
        </div>
        {documentTabType !== 'Tax Documents' &&
          selectedDropDownOption.key === 'selectMonth' && (
            <div css={{ display: 'flex', flexDirection: 'row' }}>
              <div
                css={{
                  fontSize: 14,
                  margin: '76px 12px 0 12px'
                }}
              >
                <input
                  css={[inputClasses.input, { height: 42 }]}
                  value={selectedMonth}
                  onChange={onMonthChange}
                  type="month"
                  min={`${new Date().getFullYear() - 4}-01`}
                  max={format(new Date(), 'yyyy-MM')}
                />
              </div>
              <div css={{ marginTop: 76 }}>
                <button
                  css={[
                    buttonStyles.primary,
                    {
                      height: 42
                    }
                  ]}
                  onClick={handleApplyMonthSelection}
                  disabled={
                    !dateRangeSelected.startDate || !dateRangeSelected.endDate
                  }
                >
                  Apply
                </button>
              </div>
            </div>
          )}

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 34
          }}
        >
          <div css={classes.iconContainer}>
            <div
              css={
                !!selection.length && !isDownloading
                  ? classes.iconStyle
                  : classes.iconStyleDisable
              }
            >
              <Icon type="Download" onClick={handleDownloadClick} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentFilter
