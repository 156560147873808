import {
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps
} from '@fluentui/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { IClient, ILoginDetails } from '../../api/client.types'
import { getAllEmails, getLoginDetailEmails } from '../../shared/client'
import { IRelatedPartyDataRequestActionPayload } from '../RelatedPartyPanel/store'

interface IClientMenuProps {
  client: IClient
  onLaunchClientOnline: (wealthscapeId?: string) => void
  onRelatedPartiesSelected: (
    payload: IRelatedPartyDataRequestActionPayload
  ) => void
  onLaunchTeams: (loginId: string) => void
  onLaunchWealthscape: (path: string) => void
  onLaunchDynamics: (client: IClient) => void
  getContact: (email: string[]) => void
}

export const ClientMenu: React.FC<IClientMenuProps> = memo(
  ({
    client,
    onLaunchClientOnline,
    onLaunchTeams,
    onLaunchWealthscape,
    onRelatedPartiesSelected,
    onLaunchDynamics,
    getContact
  }) => {
    const loginDetails = client?.loginDetails?.filter((x) => x.WealthscapeID)
    const investors = client?.investors?.filter((x) => x.Investorindexno)
    const getSecondaryText = (loginDetail: ILoginDetails) =>
      loginDetails?.length
        ? loginDetail.loginid || loginDetail.WealthscapeID
        : undefined

    const emails = getLoginDetailEmails(client)
    const allEmails = getAllEmails(client)
    const { setHousehold } = useRdot360Context()
    const navigate = useNavigate()
    const navigateToRdot360 = useCallback(() => {
      const { householdId, LegalEntityID } = client
      if (!householdId || !LegalEntityID) {
        return
      }
      setHousehold({
        householdId,
        selectionType: 'client',
        selectionIds: [LegalEntityID]
      })
      navigate('/advisory/client')
    }, [client, navigate, setHousehold])

    const menuItems: IContextualMenuItem[] = [
      loginDetails?.length && {
        key: 'online',
        onClick: () => onLaunchClientOnline(),
        iconProps: {
          iconName: 'ArrowUpRight'
        },
        text: 'Simulate Client Online',
        subMenuProps: loginDetails?.length > 1 && {
          items: loginDetails?.map((x, i) => ({
            key: '' + x.WealthscapeID + i,
            onClick: () => {
              onLaunchClientOnline(x.WealthscapeID || '')
            },
            text: getSecondaryText(x)
          }))
        }
      },
      loginDetails?.length && {
        key: 'related-parties',
        onClick: () => {
          const firstLoginDetail = loginDetails?.[0]
          onRelatedPartiesSelected({
            idType: 'wealthscapeId',
            id: firstLoginDetail.WealthscapeID || '',
            displayName: client.LegalEntityName || 'Client'
          })
        },
        iconProps: {
          iconName: 'Relationship'
        },
        text: 'View Related Parties',
        subMenuProps: loginDetails?.length > 1 && {
          items: loginDetails?.map((x, i) => ({
            key: '' + x.WealthscapeID + i,
            onClick: () => {
              onRelatedPartiesSelected({
                idType: 'wealthscapeId',
                id: x.WealthscapeID || '',
                displayName:
                  (client.LegalEntityName || 'Client') +
                  ` (${getSecondaryText(x)})`
              })
            },
            text: getSecondaryText(x)
          }))
        }
      },
      emails?.length && {
        key: 'teams',
        onClick: () => {
          const firstEmail = emails?.[0]
          if (firstEmail) {
            onLaunchTeams(firstEmail)
          }
        },
        iconProps: {
          iconName: 'TeamsLogo'
        },
        text: 'Collaboration',
        subMenuProps: emails?.length > 1 && {
          items: emails.map((x, i) => ({
            key: '' + x + i,
            onClick: () => onLaunchTeams(x),
            text: x
          }))
        }
      },
      allEmails?.length && {
        key: 'dynamics',
        onClick: () => onLaunchDynamics(client),
        iconProps: {
          iconName: 'ContactInfo'
        },
        text: 'Dynamics Contact'
      },
      investors?.length && {
        key: 'nfs-client-summary',
        onClick: () => {
          const firstInvestor = investors[0]
          onLaunchWealthscape(
            `clients/positions?clientId=${firstInvestor.Investorindexno}` +
              `&clientType=0&clientName=${firstInvestor.Investorsearchname}`
          )
        },
        iconProps: {
          iconName: 'Financial'
        },
        text: 'Wealthscape Client Positions',
        subMenuProps: investors?.length > 1 && {
          items: investors
            ?.filter((x) => x.Investorsearchname)
            .map((x, i) => ({
              key: '' + x.Investorsearchname + i,
              onClick: () => {
                onLaunchWealthscape(
                  `clients/positions?clientId=${x.Investorindexno}` +
                    `&clientType=0&clientName=${x.Investorsearchname}`
                )
              },
              text: x.Investorsearchname
            }))
        }
      },
      allEmails?.length && {
        key: 'activity',
        onClick: () => {
          getContact(allEmails)
        },
        iconProps: {
          iconName: 'AccountActivity'
        },
        text: 'D365 Activity/Notes'
      },
      {
        key: 'launch-client-dashboard',
        onClick: navigateToRdot360,
        text: 'Client Dashboard'
      }
    ].filter((x) => x) as IContextualMenuItem[]

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems
    }

    return (
      <IconButton
        title="Client Menu"
        ariaLabel="Menu"
        disabled={false}
        checked={false}
        styles={{
          root: { visibility: menuItems.length > 1 ? 'visible' : 'hidden' }
        }}
        menuIconProps={{ iconName: 'MoreVertical' }}
        menuProps={menuProps}
      />
    )
  }
)
