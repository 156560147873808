import { css } from '@emotion/react'
import { range } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AssetsAllocation from '../../components/AssetsAllocation/AssetsAllocation'
import BalanceSummary from '../../components/Balances/BalanceSummary'
import ComingSoon from '../../components/ComingSoon/ComingSoon'
import GainLossesIncome from '../../components/GainLossesIncome/GainLossesIncome'
import Holdings from '../../components/Holdings'
import ProjectedIncome from '../../components/ProjectedIncome'
import { useInvestmentsTableStore } from '../Investments/features/InvestmentsTable'
import AccountActivity from './Activity/AccountActivity'

const classes = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: '564px',
    gap: 10,
    '@media (min-width: 1924px)': {
      gap: 12
    }
  }),
  tileContainer: css({
    position: 'relative',
    backgroundColor: '#fff',
    minWidth: '476px',
    height: '375px',
    flexBasis: 0,
    flexGrow: 1,
    ['&:hover']: {
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)'
    }
  }),
  tile: css({
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: 8
  }),
  invisible: css({
    height: '0px !important',
    visibility: 'hidden',
    marginTop: 0,
    marginBottom: 0
  })
}

const tiles = [
  {
    id: 'Balances',
    component: BalanceSummary,
    detailPath: 'balances'
  },
  {
    id: 'Investments',
    component: Holdings,
    detailPath: 'investments'
  },
  {
    id: 'Asset Allocation',
    component: AssetsAllocation,
    detailPath: 'investments'
  },
  {
    id: 'Activity',
    component: AccountActivity,
    detailPath: 'activity'
  },
  {
    id: 'Income',
    component: ProjectedIncome,
    detailPath: 'income'
  },
  {
    id: 'Gains & Losses',
    component: GainLossesIncome,
    detailPath: 'gains-and-losses'
  },
  {
    id: 'Revenue',
    component: () => <ComingSoon title={'Revenue'} />,
    detailPath: 'revenue',
    disableExpand: true
  },
  {
    id: 'Performance',
    component: () => <ComingSoon title={'Performance'} />,
    detailPath: 'performance',
    disableExpand: true
  },
  {
    id: 'Loans',
    component: () => <ComingSoon title={'Loans'} />,
    detailPath: 'loans',
    disableExpand: true
  }
]

const Rdot360TileView: React.FC = () => {
  const navigate = useNavigate()
  const { setGrouping } = useInvestmentsTableStore()

  const handleExpand = useCallback(
    (path: string, tileId: string) => {
      if (tileId === 'Asset Allocation') {
        setGrouping(['assetClass', 'secid'])
      }

      navigate(path)
    },
    [navigate, setGrouping]
  )

  return (
    <div css={classes.container}>
      {tiles.map((tile) => (
        <div key={tile.id} css={classes.tileContainer}>
          <div css={[classes.tile, advisoryModuleStyles.fancyScroll]}>
            <tile.component
              disableExpand={tile.disableExpand}
              onExpand={() => handleExpand(tile.detailPath, tile.id)}
            />
          </div>
        </div>
      ))}
      {range(0, tiles.length - 1).map((i) => (
        <div key={i} css={[classes.tileContainer, classes.invisible]} />
      ))}
    </div>
  )
}

export default Rdot360TileView
