import {
  CommandBar,
  ICommandBarItemProps,
  ITheme,
  mergeStyleSets
} from '@fluentui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { useClasses } from '../../../../shared/hooks/useClasses'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'
import { createConnectedListsDataTableColumnEditorPanelComponent } from './ListsDataTableColumnEditorPanel'

const getThemedClasses = (theme: ITheme) => {
  return mergeStyleSets({
    commandBar: {
      borderBottom: `solid 2px ${theme.palette.neutralLight}`
    }
  })
}

export const createConnectedListsCommandBar = <T extends SearchResponseType>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>
) => {
  const { exportActions } = listActions
  const { exportSelectors, dataSelectors } = listSelectors
  const ListsDataTableColumnEditorPanel =
    createConnectedListsDataTableColumnEditorPanelComponent(
      listActions,
      listSelectors
    )

  const ListsCommandBar: React.FC<{
    additionalItems?: ICommandBarItemProps[]
  }> = ({ additionalItems }) => {
    const classes = useClasses(getThemedClasses)
    const dispatch = useDispatch()
    const [isColumnPanelOpen, setIsColumnPanelOpen] = useState(false)
    const toggleColumnPanel = useCallback(() => {
      setIsColumnPanelOpen(!isColumnPanelOpen)
    }, [isColumnPanelOpen])
    const exporting = useSelector(exportSelectors.getIsExportLoading)
    const count = useSelector(dataSelectors.getTotalCount)

    const commandBarItems = useMemo(() => {
      const items: ICommandBarItemProps[] = [
        {
          key: 'columns',
          text: 'Edit Columns',
          iconProps: { iconName: 'DoubleColumnEdit' },
          onClick: () => toggleColumnPanel()
        },
        {
          key: 'export',
          text: exporting ? 'Exporting' : 'Export to Excel',
          iconProps: { iconName: exporting ? 'Download' : 'ExcelLogo' },
          disabled: exporting || count > 100000 || count <= 0,
          onClick: () => {
            dispatch(exportActions.request())
          }
        },
        ...(additionalItems || [])
      ]
      return items
    }, [exporting, count, additionalItems, toggleColumnPanel, dispatch])

    return (
      <>
        <CommandBar
          items={commandBarItems}
          className={classes.commandBar}
          styles={{
            root: {
              paddingLeft: 0
            },
            primarySet: {
              width: 'fit-content',
              flexGrow: 0,
              position: 'sticky',
              left: '24px'
            }
          }}
        />
        <ListsDataTableColumnEditorPanel
          isOpen={isColumnPanelOpen}
          onDismiss={toggleColumnPanel}
        />
      </>
    )
  }

  return ListsCommandBar
}
