import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { keys, sum } from 'lodash'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import { useRdot360AccountContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useGetPositionByCategoryQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360HoldingsContext'
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from '../../../../shared/tableStyles'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { ITableHeaderColumnSize } from '../../shared/ITableHeaderColumnSize'
import { OpenLotsTable } from '../OpenLotsTable'
import { getInvestmentsTableColumnDefs } from './InvestmentsTableColumns'
import { InvestmentsTableHeaderRow } from './InvestmentsTableHeaderRow'
import { investmentsTableColumnNames, useInvestmentsTableStore } from './store'
import { cellStyles } from './styles'

const empty: ICategoryPosition[] = []
type InvestmentsTableRow = Row<ICategoryPosition>

export const InvestmentsTable: React.FC = () => {
  const { data, isFetching } =
    useGetPositionByCategoryQueryForSelectedAccounts()
  const { accountLookup } = useRdot360AccountContext()
  const columns = useMemo(
    () => getInvestmentsTableColumnDefs(accountLookup),
    [accountLookup]
  )
  const { searchText, setSearchText } = useInvestmentsDetailsUIState()
  const debouncedSearchText = useDebounce(searchText, 100)

  const positions = useMemo(
    () =>
      data
        ?.flatMap((x) => x.invposacct)
        .flatMap((x) => x?.invposlist)
        .filter(isNotNullOrUndefined) || empty,
    [data]
  )

  const {
    sorting,
    setSorting,
    grouping,
    setGrouping,
    expanded,
    setExpanded,
    visibility,
    defaultExpanded,
    setDefaultExpanded
  } = useInvestmentsTableStore()

  const table = useReactTable({
    data: positions,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      columnVisibility: visibility,
      globalFilter: debouncedSearchText
    },
    autoResetExpanded: false,
    onGlobalFilterChange: setSearchText,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    manualPagination: true,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const rows = table.getPreExpandedRowModel().rows
  const headers = table.getFlatHeaders()
  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)
  const firstGroup = useMemo(() => {
    const [firstGroup] = grouping || []
    return firstGroup
  }, [grouping])

  useEffect(() => {
    if (!isFetching && defaultExpanded) {
      setTimeout(() => {
        setExpanded(defaultExpanded)
        setDefaultExpanded(undefined)
      }, 100)
    }
  }, [defaultExpanded, isFetching, setDefaultExpanded, setExpanded])

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const themedStyles = useRdot360ThemedTableStyles()
  const isAllFirstLevelExpanded = useMemo(
    () => keys(expanded).length && rows.every((x) => x.getIsExpanded()),
    [rows, expanded]
  )

  return (
    <div ref={tableRefContainer}>
      <div css={{ marginBottom: '5px' }}>
        <a
          onClick={(e) => {
            setExpanded(
              isAllFirstLevelExpanded
                ? {}
                : rows.reduce((a, x) => ({ ...a, [x.id]: true }), {})
            )
            e.preventDefault()
          }}
          href="#"
          css={{
            color: '#4C9DA8',
            cursor: 'pointer',
            marginBottom: 16
          }}
        >
          {isAllFirstLevelExpanded ? 'Collapse All' : 'Expand All'}
        </a>
      </div>
      {isFetching && <IndeterminateProgressIndicator />}
      <HorizontalScrollContainer>
        <div
          css={[
            rdot360TableStyles.headerContainer,
            themedStyles.headerContainer
          ]}
        >
          <table css={rdot360TableStyles.table}>
            <thead>
              <InvestmentsTableHeaderSizesRow sizes={getHeaderSizes()} />
              <InvestmentsTableHeaderRow table={table} />
            </thead>
          </table>
        </div>
        <div css={[themedStyles.bodyContainer]}>
          {firstGroup === investmentsTableColumnNames.secid && (
            <InvestmentsTableBody rows={rows} getHeaderSizes={getHeaderSizes} />
          )}
          {firstGroup !== investmentsTableColumnNames.secid && (
            <InvestmentsTableGroups
              rows={rows}
              depth={0}
              getHeaderSizes={getHeaderSizes}
            />
          )}
        </div>
        <div
          css={[
            rdot360TableStyles.headerContainer,
            themedStyles.headerContainer,
            { bottom: 0 }
          ]}
        >
          <table css={rdot360TableStyles.table}>
            <thead>
              <InvestmentsTableHeaderSizesRow sizes={getHeaderSizes()} />
            </thead>
            <tfoot>
              <tr
                css={[
                  rdot360TableStyles.bodyRow,
                  rdot360TableStyles.l2GroupRow,
                  themedStyles.totalRow
                ]}
              >
                {headers.map((header) => (
                  <td key={header.id} css={[cellStyles[header.column.id]]}>
                    {header.column.columnDef.footer
                      ? flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )
                      : null}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </HorizontalScrollContainer>
    </div>
  )
}

export const InvestmentsTableGroups: React.FC<{
  rows: InvestmentsTableRow[]
  depth: number
  getHeaderSizes: (depth?: number) => ITableHeaderColumnSize[]
}> = ({ rows, depth = 0, getHeaderSizes }) => {
  return (
    <div css={[rdot360TableStyles.groupsContainer]}>
      {rows.map((row) => {
        return (
          <div
            key={row.id}
            css={[
              rdot360TableStyles.groupSection,
              depth > 0 && rdot360TableStyles.l2GroupSection
            ]}
          >
            <InvestmentsTableBody
              rows={[row]}
              getHeaderSizes={getHeaderSizes}
              depth={depth}
            />
          </div>
        )
      })}
    </div>
  )
}

const InvestmentsTableBody: React.FC<{
  rows: InvestmentsTableRow[]
  getHeaderSizes: (depth?: number) => ITableHeaderColumnSize[]
  depth?: number
}> = ({ rows, getHeaderSizes, depth = 0 }) => {
  const sizes = getHeaderSizes(depth)
  const { grouping } = useInvestmentsTableStore()
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <table css={[rdot360TableStyles.table, themedStyles.tableBody]}>
      <thead>
        <InvestmentsTableHeaderSizesRow sizes={sizes} />
      </thead>
      <tbody>
        {rows.map((row) => {
          const isRowExpanded = row.getIsExpanded()
          const isRowSelected = row.getIsSelected()
          const canRowExpand = row.getCanExpand()
          const [firstSubRow] = row.subRows
          const hasSubRows = !!firstSubRow
          const isSecurityRow =
            row.groupingColumnId === investmentsTableColumnNames.secid ||
            (!grouping?.includes(investmentsTableColumnNames.secid) &&
              !row.groupingColumnId)
          const isSubTableSecurityRows =
            !!firstSubRow &&
            (firstSubRow.groupingColumnId ===
              investmentsTableColumnNames.secid ||
              (!grouping?.includes(investmentsTableColumnNames.secid) &&
                !firstSubRow.groupingColumnId))

          return (
            <Fragment key={row.id}>
              {isSecurityRow && <InvestmentsTableRow row={row} />}
              {!isSecurityRow && <InvestmentsTableGroupRow row={row} />}
              {((isRowExpanded && canRowExpand) || isRowSelected) && (
                <tr>
                  <td
                    colSpan={sizes.length}
                    css={[
                      rdot360TableStyles.subTableContainerCell,
                      themedStyles.subTableContainerCell
                    ]}
                  >
                    {hasSubRows && isSubTableSecurityRows && (
                      <InvestmentsTableBody
                        rows={row.subRows}
                        getHeaderSizes={getHeaderSizes}
                        depth={depth}
                      />
                    )}
                    {hasSubRows && !isSubTableSecurityRows && (
                      <InvestmentsTableGroups
                        rows={row.subRows}
                        depth={depth + 1}
                        getHeaderSizes={getHeaderSizes}
                      />
                    )}
                    {!hasSubRows && (
                      <div style={{ padding: '10px 8px' }}>
                        <OpenLotsTable
                          accountId={row.original?.acctkey}
                          secId={row.original?.secinfo?.unqid}
                          sizes={getHeaderSizes(depth + 1)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

const InvestmentsTableRow: React.FC<{ row: InvestmentsTableRow }> = ({
  row
}) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        themedStyles.securityRow
      ]}
    >
      {cells.map((cell) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

const InvestmentsTableGroupRow: React.FC<{ row: InvestmentsTableRow }> = ({
  row
}) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()
  const isL1GroupRow = row.depth === 0
  const isL2GroupRow = row.depth > 0
  const isGroupRow = isL1GroupRow || isL2GroupRow
  const isExpanded = row.getIsExpanded()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        isGroupRow && themedStyles.groupRow,
        isL1GroupRow && themedStyles.l1GroupRow,
        isL1GroupRow && rdot360TableStyles.l1GroupRow,
        isL2GroupRow && themedStyles.l2GroupRow,
        isL2GroupRow && rdot360TableStyles.l2GroupRow,
        isExpanded && themedStyles.groupRowExpanded
      ]}
    >
      {cells.map((cell) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {flexRender(
              cell.column.columnDef.aggregatedCell,
              cell.getContext()
            )}
          </td>
        )
      })}
    </tr>
  )
}

const getAdjustedHeaderSizes = (
  sizes: ITableHeaderColumnSize[],
  tableWidth: number,
  depth = 0,
  firstColumnOffset = 9,
  lastColumnOffset = 9
) => {
  const totalSize = sum(sizes.map(({ width }) => width))
  const ratio = tableWidth ? tableWidth / totalSize : 1

  return sizes.map(({ width, id }, i) => {
    const isFirst = i === 0
    const isLast = i === sizes.length - 1
    const depthAdjustment =
      isFirst || isLast
        ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
        : 0

    return { id, width: width * ratio - depthAdjustment }
  })
}

const InvestmentsTableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}
