import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { ready } from 'shared/services/auth'
import { IModuleDefinitionRouteProps } from '../../modules/types'
import {
  getPreferenceUserPreferences,
  getRdotUsername
} from '../../store/user/selectors'

export interface IPrivateRouteProps {
  username?: string
}

const useIsMsalReady = () => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    const run = async () => {
      await ready()
      setIsReady(true)
    }
    run()
  }, [])

  return isReady
}

export const PrivateRoute: React.FC<{
  routeProps: IModuleDefinitionRouteProps
}> = ({ routeProps }) => {
  const isMsalReady = useIsMsalReady()
  const username = useSelector(getRdotUsername)
  const preferences = useSelector(getPreferenceUserPreferences)
  const location = useLocation()
  const { private: isPrivateRoute = true } = routeProps
  if (!isMsalReady || (username && !preferences)) {
    return <div className="rdot-login-splash" />
  }

  const shouldRedirect = isPrivateRoute && !username
  return !shouldRedirect ? (
    <routeProps.component />
  ) : (
    <Navigate
      to="/login"
      replace
      state={{
        from: location
      }}
    />
  )
}
