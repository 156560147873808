import { IStyle, makeStyles, Stack } from '@fluentui/react'
import { IAdvisorDetail, IHousehold } from 'api/household.types'
import { parseISO, startOfMonth, subMonths } from 'date-fns'
import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAUSHistoryWithRepAndDate } from '../../../../../../../api/common.types'
import { AUSSummaryComponent } from '../../../../../../../features/Charts/AUSSummary'
import { USDCell } from '../../../../../../../features/Lists/core/components/USDCell'
import { IListsUiState } from '../../../../../../../features/Lists/core/contracts/IListsUIState'
import {
  actions,
  constructInitialPositionListColumnState,
  createPositionListFilter,
  PositionListComponents
} from '../../../../../../../features/Lists/positions'
import { PositionColumnIds } from '../../../../../../../features/Lists/positions/ColumnDefinitions'
import { PaletteComponent } from '../../../../../../../shared/components/Palette'
import { chartColors } from '../../../../../../../shared/services/theme'
import { createDashboardList } from '../../../../Dashboard/containers/DashboardList'
import {
  boardMemeberAccountsFetchActions,
  getBoardMemeberAccountsFetchResult
} from '../store/accountsFetch'

const initialColumns: (keyof PositionColumnIds)[] = [
  'cusip',
  'description',
  'costBasis',
  'tradedatequantity',
  'price',
  'marketvalue',
  'unrealizedgainloss',
  'securitytype',
  'securitySubtype',
  'accountNumber',
  'partyname'
]

const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialPositionListColumnState(initialColumns),
  filters: keyBy(
    [
      createPositionListFilter(PositionColumnIds.securitytype, {
        type: 'facet',
        values: ['AI', 'STRUCTURED PRODUCTS']
      })
    ],
    (x) => x.id
  ),
  orderBy: { columnId: PositionColumnIds.marketvalue, direction: 'desc' }
}

const DashboardPositionList = createDashboardList(
  PositionListComponents,
  actions.uiActions,
  defaultUiState
)

const PositionListContainer = () => {
  const dispatch = useDispatch()
  const { update } = actions.uiActions
  useEffect(() => {
    dispatch(update(defaultUiState))
  }, [dispatch, update])

  return <DashboardPositionList />
}

export const useBoardAccountTableStyles = makeStyles((theme) => {
  const boardAccountTable: IStyle = {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '0',
    'th, td': {
      padding: '2px 8px',
      textAlign: 'right',
      borderRight: `solid 2px ${theme.semanticColors.bodyBackground}`,
      borderLeft: `solid 2px ${theme.semanticColors.bodyBackground}`,
      borderTop: `solid 5px ${theme.semanticColors.bodyBackground}`,
      backgroundColor: `${theme.palette.neutralLighter}cc`
    },
    'tr td:first-child': {
      fontWeight: 'bold',
      textAlign: 'left'
    },
    'tfoot td': {
      borderTop: `solid 3px ${theme.semanticColors.bodyBackground}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'thead th': {
      borderBottom: `solid 3px ${theme.semanticColors.bodyBackground}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'tr.actionable:hover': {
      backgroundColor: `${chartColors[1]}99`
    },
    'tr.active': {
      backgroundColor: `${chartColors[1]}FF`,
      fontWeight: 'bold'
    }
  }
  return {
    boardAccountTable
  }
})

export const BoardAccountsView = () => {
  const classes = useBoardAccountTableStyles()
  const dispatch = useDispatch()
  const [selectedAccount, setSelectedAccount] = useState<IHousehold>()
  const { setFilter } = actions.uiActions
  useEffect(() => {
    dispatch(boardMemeberAccountsFetchActions.request())
  }, [dispatch])
  const accounts = useSelector(getBoardMemeberAccountsFetchResult)
  useEffect(() => {
    if (!accounts?.[0]) {
      return
    }
    setSelectedAccount(accounts[0])
  }, [accounts, dispatch, setFilter])
  const [ausHistoryWithDate, setAusHistoryWithDate] =
    useState<IAUSHistoryWithRepAndDate[]>()
  const onClick = (account: IHousehold) => {
    setSelectedAccount(account)
  }
  useEffect(() => {
    if (!selectedAccount) {
      return
    }
    dispatch(
      setFilter(
        createPositionListFilter(PositionColumnIds.householdId, {
          type: 'search',
          disableModifications: true,
          value: selectedAccount.householdId
        })
      )
    )
    const minDate = subMonths(startOfMonth(Date.now()), 11).getTime()
    setAusHistoryWithDate(
      selectedAccount.ausHistory
        ?.map((item) => {
          const date = flow(
            parseISO,
            startOfMonth,
            (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
          )(item.DateAsOf || '')

          return {
            ...item,
            date,
            timestamp: date.getTime()
          }
        })
        .sort((a, b) => a.timestamp - b.timestamp)
        .filter((a) => a.timestamp >= minDate) || []
    )
  }, [dispatch, selectedAccount, setFilter])
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <Stack.Item grow={1}>
          <PaletteComponent>
            <table className={classes.boardAccountTable}>
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Managed Assets</th>
                  <th>Brokerage Assets</th>
                  <th>Assets Under Supervision</th>
                  <th>T12 Revenue</th>
                  <th>Advisor Name</th>
                </tr>
              </thead>
              <tbody>
                {accounts?.map((acc, index) => (
                  <tr
                    onClick={() => {
                      onClick(acc)
                    }}
                    className={
                      acc === selectedAccount ? 'active' : 'actionable'
                    }
                    key={index}
                  >
                    <td>{acc.householdName}</td>
                    <td>
                      <USDCell value={acc.householdKPI?.AumManaged} />
                    </td>
                    <td>
                      <USDCell value={acc.householdKPI?.AumBrokerage} />
                    </td>
                    <td>
                      <USDCell value={acc.householdKPI?.AumTotal} />
                    </td>
                    <td>
                      <USDCell value={acc.revenue?.ttmrevenue} />
                    </td>
                    <td>
                      {acc.Advisors?.map((advisor: IAdvisorDetail, index) => (
                        <div key={index}>{advisor.ClientAdvisor}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaletteComponent>
        </Stack.Item>
        <PaletteComponent>
          <div style={{ minWidth: '600px' }}>
            <AUSSummaryComponent ausHistory={ausHistoryWithDate || []} />
          </div>
        </PaletteComponent>
      </Stack>
      <PaletteComponent>
        <PositionListContainer />
      </PaletteComponent>
    </Stack>
  )
}
