import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { FC, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/gaurds'
import { HighchartsComponent, highcharts } from 'shared/highcharts'
import useProjectedIncomeChartData, {
  ChartData
} from '../useProjectedIncomeChartData'
import { IncomeType } from '../util'

const getProjectedIncomeOptions = (data: ChartData): Highcharts.Options => ({
  chart: {
    type: 'column',
    zooming: {
      type: 'xy'
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: data.dates
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  tooltip: {
    formatter: function formatTooltip(this: any) {
      if (this.y >= 0) {
        return [
          `${this.x}`,
          `${this.series.name} : $${highcharts?.numberFormat(
            this.y,
            0,
            '.',
            ','
          )}`,
          `Total : $${highcharts?.numberFormat(this.total, 0, '.', ',')}`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      } else {
        return [
          `${this.x}`,
          `${this.series.name} : -$${highcharts?.numberFormat(
            this.y * -1,
            0,
            '.',
            ','
          )}`,
          `Total : -$${highcharts?.numberFormat(this.total * -1, 0, '.', ',')}`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      }
    }
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      borderRadius: 0,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    symbolRadius: 0
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 300
        }
      }
    ]
  },
  series: data.chartData as Highcharts.SeriesOptionsType[]
})
const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}
export const ProjectedIncomeDetailsChart: FC<{
  data: any
  includeOptionPremiums: boolean
  incomeType: IncomeType
  noDataMsg: boolean
}> = ({ data, incomeType, includeOptionPremiums, noDataMsg }) => {
  const financialYear = useMemo(() => new Date().getFullYear(), [])
  const chartData = useProjectedIncomeChartData(
    includeOptionPremiums,
    incomeType,
    financialYear,
    data
  )

  const projectedIncomeOptions = useMemo(
    () => getProjectedIncomeOptions(chartData),
    [chartData]
  )

  return (
    <>
      {projectedIncomeOptions.series?.length ? (
        <HighchartsComponent
          containerProps={containerProps}
          options={projectedIncomeOptions}
        />
      ) : (
        <>
          {!noDataMsg ? (
            <SnackBar type="Info" message="No data available" />
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}
