import { IDropdownOption } from '@fluentui/react'
import { IFetchWireInstructionResponse } from '../api/types'
import { textFieldRegEx, vendorNameFieldRegEx } from '../Instructions/Constants'
import {
  IInstruction,
  IWireInstruction,
  InstructionModeType
} from '../store/types'

const parseJson = (str: string): unknown => JSON.parse(str)

export const formatWireInstructionResponse = (
  res: IFetchWireInstructionResponse,
  orgid: string
): IInstruction => {
  const instruction: IInstruction = {}
  if (res) {
    instruction.AccountType = res.accountType
    instruction.Id = res.id
    instruction.Name = res.name
    instruction.AccNumber = res.accNumber
    instruction.PaymentTermId = res.paymentTermId
    instruction.Mode = InstructionModeType.UPDATE
    instruction.OrgId = orgid
    if (res.paymentInstruction) {
      const objPaymentInstr = parseJson(
        res.paymentInstruction
      ) as IWireInstruction
      instruction.wireInstruction = {
        BankAcctFromAcctId: objPaymentInstr.BankAcctFromAcctId,
        BankAcctFromAcctType: objPaymentInstr.BankAcctFromAcctType,
        BankAcctFromBankId: objPaymentInstr.BankAcctFromBankId,
        BankAcctFromBankIdType: objPaymentInstr.BankAcctFromBankIdType,
        BankAcctFromName: objPaymentInstr.BankAcctFromName,
        BeneficiaryAcctId: objPaymentInstr.BeneficiaryAcctId,
        BeneficiaryAcctType: objPaymentInstr.BeneficiaryAcctType,
        BeneficiaryBankName: objPaymentInstr.BeneficiaryBankName,
        BeneficiaryBankRoutingId: objPaymentInstr.BeneficiaryBankRoutingId,
        BeneficiaryBankRoutingType: objPaymentInstr.BeneficiaryBankRoutingType,
        BeneficiaryName: objPaymentInstr.BeneficiaryName,
        BeneficiaryNameAddrLine1: objPaymentInstr.BeneficiaryNameAddrLine1,
        BeneficiaryNameAddrLine2: objPaymentInstr.BeneficiaryNameAddrLine2,
        BeneficiaryNameAddrLine3: objPaymentInstr.BeneficiaryNameAddrLine3,
        BeneficiaryNameAddrLine4: objPaymentInstr.BeneficiaryNameAddrLine4,
        DestinationCountry: objPaymentInstr.DestinationCountry || 'US',
        DisplayName: objPaymentInstr.DisplayName,
        FurtherCredit1_AccountNumber:
          objPaymentInstr.FurtherCredit1_AccountNumber,
        FurtherCredit1_NameLine1: objPaymentInstr.FurtherCredit1_NameLine1,
        FurtherCredit1_NameLine2: objPaymentInstr.FurtherCredit1_NameLine2,
        FurtherCredit1_NameLine3: objPaymentInstr.FurtherCredit1_NameLine3,
        FurtherCredit1_NameLine4: objPaymentInstr.FurtherCredit1_NameLine4,
        FurtherCredit2_AccountNumber:
          objPaymentInstr.FurtherCredit2_AccountNumber,
        FurtherCredit2_NameLine1: objPaymentInstr.FurtherCredit2_NameLine1,
        FurtherCredit2_NameLine2: objPaymentInstr.FurtherCredit2_NameLine2,
        FurtherCredit2_NameLine3: objPaymentInstr.FurtherCredit2_NameLine3,
        FurtherCredit2_NameLine4: objPaymentInstr.FurtherCredit2_NameLine4,
        IsThirdParty: objPaymentInstr.IsThirdParty,
        WireDisbursementDetail1: objPaymentInstr.WireDisbursementDetail1,
        WireDisbursementDetail2: objPaymentInstr.WireDisbursementDetail2,
        WireDisbursementDetail3: objPaymentInstr.WireDisbursementDetail3,
        WireDisbursementDetail4: objPaymentInstr.WireDisbursementDetail4
      }
    } else {
      //in case we don't have instructions
      instruction.wireInstruction = {
        DestinationCountry: 'US'
      }
    }
  }
  return instruction
}

export const getPayTermText = (
  options: IDropdownOption[],
  key?: string
): string => {
  let payTermText = ''
  if (options && options.length > 0) {
    payTermText = options.filter((item) => item.key === key)?.[0]?.text
  }

  return payTermText ? payTermText : ''
}

export const validateSubmitInstructions = (
  instruction: IInstruction,
  paymentTerm: string
): { isValid: boolean; message?: string } => {
  const response = { isValid: true }
  // validate fields one by one

  if (!textFieldRegEx.test(instruction?.AccNumber || '')) {
    return {
      isValid: false,
      message: 'Invalid Account number, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (!vendorNameFieldRegEx.test(instruction?.Name || '')) {
    return {
      isValid: false,
      message: 'Invalid Name, we support A-Z 0-9 ? : ` . , &'
    }
  }

  if (
    (instruction?.wireInstruction?.BankAcctFromAcctId || '') === '' &&
    paymentTerm === 'NFS Wire'
  ) {
    return {
      isValid: false,
      message: 'Wire payment account number required'
    }
  }

  if ((instruction?.wireInstruction?.BeneficiaryAcctId || '') === '') {
    return {
      isValid: false,
      message: 'Beneficiary account number required'
    }
  }

  if (
    !textFieldRegEx.test(instruction?.wireInstruction?.BeneficiaryAcctId || '')
  ) {
    return {
      isValid: false,
      message:
        'Invalid beneficiary account number, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit1_AccountNumber || ''
    )
  ) {
    return {
      isValid: false,
      message:
        'Invalid Further credit 1 account number, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit1_NameLine1 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 1 name, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit1_NameLine2 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 1 line 1, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit1_NameLine3 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 1 line 2, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit1_NameLine4 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 1 line 3, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit2_AccountNumber || ''
    )
  ) {
    return {
      isValid: false,
      message:
        'Invalid Further credit 2 account number, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit2_NameLine1 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 2 name, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit2_NameLine2 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 2 line 1, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit2_NameLine3 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 2 line 2, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.FurtherCredit2_NameLine4 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Further credit 2 line 3, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.WireDisbursementDetail1 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Details line 1, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.WireDisbursementDetail2 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Details line 2, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.WireDisbursementDetail3 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Details line 3, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.WireDisbursementDetail4 || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Details line 4, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !vendorNameFieldRegEx.test(
      instruction?.wireInstruction?.BeneficiaryName || ''
    )
  ) {
    return {
      isValid: false,
      message: 'Invalid Beneficiary name, we support A-Z 0-9 ? : ` . , &'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.BeneficiaryNameAddrLine1 || ''
    )
  ) {
    return {
      isValid: false,
      message:
        'Invalid beneficiary address line 1, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.BeneficiaryNameAddrLine2 || ''
    )
  ) {
    return {
      isValid: false,
      message:
        'Invalid beneficiary address line 2, we support A-Z 0-9 ? : ` . ,'
    }
  }

  if (
    !textFieldRegEx.test(
      instruction?.wireInstruction?.BeneficiaryNameAddrLine3 || ''
    )
  ) {
    return {
      isValid: false,
      message:
        'Invalid beneficiary address line 3, we support A-Z 0-9 ? : ` . ,'
    }
  }

  return response
}
