import axios, { AxiosResponse } from 'axios'
import { IDragDropFile } from '../../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { ITrustDistributionRequest } from '../store/types'
import { toUTF8Array } from '../utilities/Utilities'
import {
  ITrustDiscretionaryDistributionInfoResponse,
  ITrustDiscretionaryDistributionDoc,
  ITrustDiscretionaryDistributionEditInfoResponse,
  IUpdateDistributionStatus
} from './types'

export const fetchTrustDiscretionaryDistributionInfoSvc = (
  entityId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/trustcompany/getTrustDiscretionaryDistributionInfo?rockItClientID=${entityId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITrustDiscretionaryDistributionInfoResponse | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchCRMTrustDiscretionaryDistributionInfoSvc = (
  entityId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/GetTrustDiscritionaryDetails?trustid=${entityId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITrustDiscretionaryDistributionInfoResponse | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const submitTrustDiscretionaryDistributionInfoSvc = (
  payload: ITrustDistributionRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/Add`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = toUTF8Array(JSON.stringify(payload))
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchTrustDiscretionaryDistributionEditSvc = (
  id: string,
  options: IApiOptions
): Promise<
  AxiosResponse<ITrustDiscretionaryDistributionEditInfoResponse | undefined>
> => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/distribution?id=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchDiscretionaryDistributionDocListSvc = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse<ITrustDiscretionaryDistributionDoc[] | undefined>> => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/DiscretionaryDocumentList?distributionId=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const createDocumentForDistribution = (
  id: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/getDiscretionaryById?id=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, null, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const createDocuSignEnvelop = (id: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/acctopngapi/api/AccountProfile/CreateDocusignEnvelope_DD?id=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updateDiscritionaryDistributionStatus = (
  data: IUpdateDistributionStatus,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/UpdateStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadAdditionalDistributionDocs = (
  file: IDragDropFile,
  email: string,
  distributionId: string,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/UploadDocument`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  data.append('DistributionId', distributionId)
  data.append('UserEmail', email)
  data.append('File', file.file)

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, file)
          }
        : undefined
    })
    .then((res) => res.data)
}

export const deleteAdditionalDocument = (
  docid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/DeleteDistributionDocument?docId=${docid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .delete(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
