import axios, { AxiosError } from 'axios'
import { uniq, uniqBy } from 'lodash'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IOdataResult } from '../shared/contracts/IOdataResult'
import { isNotNullOrFalse, isNotNullOrUndefined } from '../shared/gaurds'
import { IOdataRequest } from './odata.types'
import { escapeAndEncodeQuery } from './search'

export interface IBaseGraphResponse {
  ['@odata.context']: string
}

export interface IBaseGraphValueResponse<T> extends IBaseGraphResponse {
  value?: T
}

// https://graph.microsoft.com/v1.0/users?$filter=startswith(mail,'{{email}}')
// {
//     "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#users",
//     "value": [
//         {
//             "businessPhones": [],
//             "displayName": "Rags",
//             "givenName": null,
//             "jobTitle": null,
//             "mail": "rags111875@gmail.com",
//             "mobilePhone": null,
//             "officeLocation": null,
//             "preferredLanguage": null,
//             "surname": null,
//             "userPrincipalName": "rags111875_gmail.com#EXT#@rockco.onmicrosoft.com",
//             "id": "d4c34197-bbf0-44ad-ac86-7a8883567054"
//         }
//     ]
// }

export interface IGraphUser {
  businessPhones?: string[]
  displayName?: string
  givenName?: string
  jobTitle?: string
  mail?: string
  mobilePhone?: string
  officeLocation?: string
  preferredLanguage?: string
  surname?: string
  userPrincipalName?: string
  id?: string
  accountEnabled?: boolean
  department?: string
  userType?: string
  employeeId?: string
}

export interface IRockefellerGraphUser extends IGraphUser {
  // LexisNexis
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute1?: string | null
  // CRDNo
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute2?: string | null
  // WSID
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute3?: string | null
  // Service Codes (Roles)
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute4?: string | null
  // Hierarchy Codes (Office / Division)
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute5?: string | null
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute6?: string | null
  // Investor ID (external clients)
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute7?: string | null
  // PrimaryRepCode
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute8?: string | null
  // RepCodes
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute9?: string | null
  // Client Online Indicator
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute10?:
    | string
    | null
  // Employee Type
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute11?:
    | string
    | null
  // Title token
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute12?:
    | string
    | null
  // Org Level 1
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute13?:
    | string
    | null
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute14?:
    | string
    | null
  // 	8x8 Site Pairing
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute15?:
    | string
    | null
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_wWWHomePage?: string | null
  extension_7e3ca93bb8c14a58b6203b539f5ad15e_sAMAccountName?: string | null
}

export type IGraphUserResponse = IBaseGraphValueResponse<IGraphUser[]>

export function getUserWithEmail(
  email: string,
  options: IApiOptions
): Promise<IGraphUser | undefined> {
  return axios
    .get<IGraphUserResponse>(
      `${options.apiRoot}/v1.0/users?$filter=startswith(mail,'${email}')`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data?.value?.[0])
}

export function getGraphUser(
  options: IApiOptions,
  id: string,
  request?: IOdataRequest
): Promise<IRockefellerGraphUser | undefined> {
  const query = request ? `?${constructOdataQuery(request, undefined)}` : ''
  return axios
    .get<IGraphUser>(`${options.apiRoot}/v1.0/users/${id}${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export function getCurrentUser(
  options: IApiOptions
): Promise<IGraphUser | undefined> {
  return axios
    .get<IGraphUser>(`${options.apiRoot}/v1.0/me`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => x?.data)
}

// https://graph.microsoft.com/v1.0/users/{{user_id}}/joinedTeams?$select=id,displayName,webUrl
// {
//     "id": "0dd24ca9-6e19-4b97-9de3-4b1dc3f13afe",
//     "displayName": "RockCap Technology",
//     "webUrl": null
// }

export type IUserJoinedTeamsResponse = IBaseGraphValueResponse<
  IJoinedTeamSummary[]
>

export interface IJoinedTeamSummary {
  id?: string
  displayName?: string
  webUrl?: string
}
export function getJoinedTeamsForUser(
  id: string,
  options: IApiOptions
): Promise<IJoinedTeamSummary[] | undefined> {
  return axios
    .get<IUserJoinedTeamsResponse>(
      `${options.apiRoot}/v1.0/users/${id}/joinedTeams?$select=id,displayName,webUrl`,
      {
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
          ConsistencyLevel: 'eventual'
        }
      }
    )
    .then((x) => x?.data?.value)
}

// https://graph.microsoft.com/v1.0/teams/{{team_id}}?$select=webUrl
// {
//     "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#teams(webUrl)/$entity",
//     "webUrl": "https://teams.microsoft.com/l/team/19:5c255c96ba094982bc4acb1927c63b52%40thread.skype/conversations?groupId=f540129a-28fc-46c3-a114-6bad01637004&tenantId=74352aa5-d883-4e4a-8423-7862b342708e"
// }

export interface ITeamDetail extends IBaseGraphResponse {
  id?: string
  webUrl?: string
}
export function getTeamDetail(id: string, options: IApiOptions) {
  return axios
    .get<ITeamDetail | undefined>(`${options.apiRoot}/v1.0/teams/${id}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => {
      if (x.status === 404) {
        return undefined
      }
      return x?.data
    })
    .catch((e: AxiosError<{ error?: any }>) => {
      if (e.response?.status === 404) {
        return undefined
      }

      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function addOwnersToGroup(
  groupid: string,
  userids: string[],
  options: IApiOptions
) {
  return axios
    .patch<unknown>(
      `${options.apiRoot}/v1.0/groups/${groupid}`,
      {
        'owners@odata.bind': userids.map(
          (id) => `https://graph.microsoft.com/v1.0/directoryObjects/${id}`
        )
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function addMembersToGroup(
  groupid: string,
  userids: string[],
  options: IApiOptions
) {
  return axios
    .patch<unknown>(
      `${options.apiRoot}/v1.0/groups/${groupid}`,
      {
        'members@odata.bind': userids.map(
          (id) => `https://graph.microsoft.com/v1.0/directoryObjects/${id}`
        )
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function removeOwnerFromGroup(
  groupid: string,
  userid: string,
  options: IApiOptions
) {
  return axios
    .delete<unknown>(
      `${options.apiRoot}/v1.0/groups/${groupid}/owners/${userid}/$ref`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function removeMemberFromGroup(
  groupid: string,
  userid: string,
  options: IApiOptions
) {
  return axios
    .delete<unknown>(
      `${options.apiRoot}/v1.0/groups/${groupid}/members/${userid}/$ref`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function addOwnerToTeam(
  teamid: string,
  userid: string,
  options: IApiOptions
) {
  return axios
    .post<ITeamMember>(
      `${options.apiRoot}/v1.0/teams/${teamid}/members`,
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${userid}')`
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function removeUserFromTeam(
  teamid: string,
  membershipid: string,
  options: IApiOptions
) {
  return axios
    .delete<unknown>(
      `${options.apiRoot}/v1.0/teams/${teamid}/members/${membershipid}/$ref`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface ITeamMember {
  id: string
  roles: string[]
  displayName: string
  userId: string
  email: string
}
export function getTeamMembers(id: string, options: IApiOptions) {
  return axios
    .get<IBaseGraphValueResponse<ITeamMember[]>>(
      `${options.apiRoot}/v1.0/teams/${id}/members`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e: AxiosError<{ error?: any }>) => {
      if (e.response?.status === 404) {
        return undefined
      }

      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export type IGraphTeamMemberRole = 'owner' | 'guest' | undefined
export interface IGraphTeamMember {
  role?: IGraphTeamMemberRole
  oid?: string
}
export interface ICreateClientOnlineColloborationTeamRequest {
  name?: string
  description?: string
  users?: IGraphTeamMember[]
}
export async function createClientOnlineCollaborationGroup(
  options: IApiOptions,
  request: ICreateClientOnlineColloborationTeamRequest
) {
  const { description, name, users } = request
  const members = users?.map(
    ({ oid }) => `${options.apiRoot}/v1.0/users/${oid}`
  )
  const owners = users
    ?.filter(({ role }) => role === 'owner')
    .map(({ oid }) => `${options.apiRoot}/v1.0/users/${oid}`)
  return await axios
    .post<IUserGroup>(
      `${options.apiRoot}/v1.0/groups`,
      {
        classification: 'Client Collaboration',
        displayName: name,
        mailNickname: (name || '').replace(/\s/g, '_'),
        description: description,
        visibility: 'Private',
        groupTypes: ['Unified'],
        mailEnabled: true,
        securityEnabled: false,
        resourceBehaviorOptions: ['HideGroupInOutlook', 'WelcomeEmailDisabled'],
        'owners@odata.bind': owners?.length ? owners : undefined,
        'members@odata.bind': members?.length ? members : undefined
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function createTeamFromGroup(options: IApiOptions, groupid: string) {
  return axios
    .post<unknown>(
      `${options.apiRoot}/v1.0/teams`,
      {
        'template@odata.bind': `https://graph.microsoft.com/v1.0/teamsTemplates('standard')`,
        'group@odata.bind': `https://graph.microsoft.com/v1.0/groups('${groupid}')`
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function deleteGroup(options: IApiOptions, groupid: string) {
  return axios
    .delete<unknown>(`${options.apiRoot}/v1.0/groups/${groupid}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

// https://graph.microsoft.com/v1.0/users/{{user_id}}/memberOf?$select=id,resourceProvisioningOptions,classification,description

// {
//   "@odata.type": "#microsoft.graph.group",
//   "id": "0b2a00e4-53c4-444a-9e04-eca0f50b0cb9",
//   "classification": null,
//   "description": "Auto generated group, do not change"
// },

export interface IUserDrive {
  id: string
  name?: string
  webUrl?: string
  lastModifiedBy?: {
    users?: {
      email?: string
    }
  }
}

export type IGetUserDriveResponse = IBaseGraphValueResponse<IUserDrive>

export function getDriveDetails(
  userId: string | undefined,
  options: IApiOptions
): Promise<IUserDrive | undefined> {
  return axios
    .get<IUserDrive>(`${options.apiRoot}/v1.0/${userId}/drive`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        ConsistencyLevel: 'eventual'
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export interface IUserGroup {
  '@odata.type': string
  id: string
  classification: 'Client Collaboration'
  displayName?: string
  description?: string
  resourceProvisioningOptions?: string[]
}

export type IGetUserGroupsResponse = IBaseGraphValueResponse<IUserGroup[]>

export function getClientCollaborationGroupsForUser(
  userId: string,
  options: IApiOptions
): Promise<IUserGroup[] | undefined> {
  return axios
    .get<IGetUserGroupsResponse>(
      `${
        options.apiRoot
      }/v1.0/users/${userId}/memberOf/microsoft.graph.group?${[
        '$count=true',
        '$orderby=displayName',
        '$select=id,classification,displayName,description,resourceProvisioningOptions',
        `$filter=classification eq 'Client Collaboration'`
      ].join('&')}`,
      {
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
          ConsistencyLevel: 'eventual'
        }
      }
    )
    .then((x) => x?.data.value)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function getGroupsForUser(
  userId: string,
  options: IApiOptions
): Promise<IUserGroup[] | undefined> {
  return axios
    .get<IGetUserGroupsResponse>(
      `${
        options.apiRoot
      }/v1.0/users/${userId}/memberOf/microsoft.graph.group?${[
        '$count=true',
        '$orderby=displayName',
        '$select=id,classification,displayName,description,resourceProvisioningOptions'
      ].join('&')}`,
      {
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
          ConsistencyLevel: 'eventual'
        }
      }
    )
    .then((x) => x?.data.value)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IGraphGroupDetails {
  id?: string
  displayName?: string
  classification?: string
  description?: string
  groupTypes?: string[]
  onPremisesSyncEnabled?: boolean //not sure type
  proxyAddresses?: string[]
  mailNickname?: string
  resourceProvisioningOptions?: string[]
  creationOptions?: string[]
  mail?: string
  visibility?: string
  securityEnabled?: boolean
}

export type IGraphGroupDetailsResponse =
  IBaseGraphValueResponse<IGraphGroupDetails>

export function getGroupDetails(
  groupId: string | undefined,
  options: IApiOptions
): Promise<IGraphGroupDetails | undefined> {
  return axios
    .get<IGraphGroupDetails>(`${options.apiRoot}/v1.0/groups/${groupId}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        ConsistencyLevel: 'eventual'
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

//sharepoint - getSharepointSite
export interface ISharepointSiteDetails {
  id?: string
  displayName?: string
  description?: string
  webUrl?: string //this is the public page
  name?: string
}

export type ISharepointDetailsResponse =
  IBaseGraphValueResponse<ISharepointSiteDetails>

export function getSharepointSite(
  siteId: string | undefined,
  options: IApiOptions
): Promise<ISharepointSiteDetails | undefined> {
  return axios
    .get<ISharepointSiteDetails>(`${options.apiRoot}/v1.0/sites/${siteId}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        ConsistencyLevel: 'eventual'
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export interface IAppRoleAssignmentForUser {
  id: string
  creationTimestamp: string
  appRoleId: string
  principalDisplayName: string
  principalId: string
  principalType: string
  resourceDisplayName: string
  resourceId: string
}

export type IGetAppRollAssignmentsForUserResponse = IBaseGraphValueResponse<
  IAppRoleAssignmentForUser[]
>

export function getAppRoleAssignmentsForUser(
  userId: string,
  options: IApiOptions
): Promise<IAppRoleAssignmentForUser[] | undefined> {
  return axios
    .get<IGetAppRollAssignmentsForUserResponse>(
      `${options.apiRoot}/beta/users/${userId}/appRoleAssignments?$top=500`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data?.value)
}

export interface IServicePrincipal {
  id?: string
  appId?: string
  appDisplayName?: string
  displayName?: string
  homepage?: string
  info?: {
    logoUrl?: string
  }
}

export function getApplicationById(
  id: string,
  options: IApiOptions
): Promise<IServicePrincipal> {
  return axios
    .get<IServicePrincipal>(`${options.apiRoot}/v1.0/applications/${id}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => x?.data)
}

export type IGetDirectoryObjectsByIdResponse<T> = IBaseGraphValueResponse<T[]>

export function getDirectoryObjectsById<T>(
  ids: string[],
  types: string[],
  options: IApiOptions
): Promise<T[] | undefined> {
  return axios
    .post<IGetDirectoryObjectsByIdResponse<T>>(
      `${options.apiRoot}/beta/directoryObjects/getByIds`,
      {
        ids,
        types
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data?.value)
}

export const updateUser = (
  user: IRockefellerGraphUser,
  options: IApiOptions
) => {
  if (!user.id) {
    throw new Error('Invalid user argument: id is required')
  }
  return axios
    .patch<unknown>(
      `${options.apiRoot}/v1.0/users/${user.id}`,
      { ...user, id: undefined },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const deleteUser = (userId: string, options: IApiOptions) => {
  return axios
    .delete<unknown>(`${options.apiRoot}/v1.0/users/${userId}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` }
    })
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const searchUsers = (
  {
    searchString,
    additionalFilters
  }: { searchString: string; additionalFilters?: string[] },
  options: IApiOptions
): Promise<IGraphUser[] | undefined> => {
  const searchFields = [
    'displayName',
    'givenName',
    'surname',
    'mail',
    'userPrincipalName'
  ]

  const select: string[] = [
    'businessPhones',
    'displayName',
    'givenName',
    'employeeId',
    'jobTitle',
    'mail',
    'mobilePhone',
    'officeLocation',
    'preferredLanguage',
    'surname',
    'userPrincipalName',
    'id'
  ]

  const searchFilters = searchString
    ? searchFields.map((x) => `startswith(${x}, '${searchString}')`)
    : []

  const filters = [
    searchFilters && `(${searchFilters.join(' or ')})`,
    ...(additionalFilters || [])
  ]
    .filter(isNotNullOrFalse)
    .join(' and ')

  const path = `users?${[
    filters && `$filter=${filters}`,
    `$select=${select.join(',')}`,
    '$top=10'
  ]
    .filter(isNotNullOrFalse)
    .join('&')}`

  return axios
    .get<IBaseGraphValueResponse<IGraphUser[]>>(
      `${options.apiRoot}/v1.0/${path}`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data?.value)
}

const constructOdataQuery = (
  request: IOdataRequest,
  defaults?: IOdataRequest
) => {
  const {
    skip,
    top,
    filters,
    orderby,
    select,
    expand,
    search,
    searchFields,
    count
  } = request

  const allFilters = [...(filters || [])]

  const orderbyWithDefault = uniqBy(
    [...(orderby || []), ...(defaults?.orderby || [])].filter(
      isNotNullOrUndefined
    ),
    (x) => x.dataPath
  )

  const selectWithDefault = uniq(
    [...(select || []), ...(defaults?.select || [])].filter(
      isNotNullOrUndefined
    )
  )

  const expandWithDefault = [
    ...(expand || []),
    ...(defaults?.expand || [])
  ].filter(isNotNullOrUndefined)

  return [
    top != null && `$top=${top}`,
    skip != null && `$skip=${skip}`,
    count && '$count=true',
    !!selectWithDefault?.length && `$select=${selectWithDefault.join(',')}`,
    !!expandWithDefault?.length && `$expand=${expandWithDefault.join(',')}`,
    !!orderbyWithDefault?.length &&
      `$orderby=${orderbyWithDefault
        .map(
          ({ dataPath, direction }) =>
            `${dataPath}${direction ? ` ${direction}` : ''}`
        )
        .join(',')}`,
    !!allFilters?.length && `$filter=${allFilters.join(' and ')}`,
    !!searchFields?.length &&
      !!search &&
      `$search=${searchFields
        .map((x) => `"${x}:${escapeAndEncodeQuery(search)}"`)
        .join(' OR ')}`
  ]
    .filter(isNotNullOrFalse)
    .join('&')
}

//searching for all users both members and guests
export const searchGraphUsers = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const path = `users?${constructOdataQuery(request, undefined)}`

  return axios
    .get<IOdataResult<IGraphUser>>(`${apiRoot}/v1.0/${path}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ConsistencyLevel: 'eventual'
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export interface IGraphDrive {
  webUrl?: string
}
export const getRootOneDriveFolderForGroup = async (
  groupId: string,
  options: IApiOptions
) => {
  return await axios
    .get<IGraphDrive>(
      `${options.apiRoot}/v1.0/groups/${groupId}/drive/root?$select=webUrl`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const getRootOneDriveFileAsJson = async <T>(
  filename: string,
  options: IApiOptions
): Promise<T> => {
  const result = await axios
    .get<{ id: string; '@microsoft.graph.downloadUrl': string }>(
      `${options.apiRoot}/v1.0/me/drive/root:/${filename}`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)

  const files = await axios
    .get(result['@microsoft.graph.downloadUrl'])
    .then((x) => x.data)

  return files as T
}

export const updateRootOneDriveFile = (
  filename: string,
  content: string,
  options: IApiOptions
): Promise<unknown> => {
  return axios
    .put(
      `${options.apiRoot}/v1.0/me/drive/root:/${filename}:/content`,
      content,
      {
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
          'Content-Type': 'text/plain'
        }
      }
    )
    .then((x) => x?.data)
}

export interface IGuestUserInvitation {
  id?: string
  inviteRedeemUrl?: string
  invitedUserDisplayName?: string
  invitedUserEmailAddress?: string
  sendInvitationMessage?: boolean
  invitedUserMessageInfo?: {
    messageLanguage?: string
    ccRecipients?: {
      emailAddress?: {
        name?: null
        address?: null
      }
    }[]
    customizedMessageBody?: null
  }
  inviteRedirectUrl?: string
  status?: 'Completed' | string
  invitedUser?: { id?: string }
}

export const inviteGuestUser = (
  options: IApiOptions,
  invitation: IGuestUserInvitation
) => {
  return axios
    .post<IGuestUserInvitation>(
      `${options.apiRoot}/v1.0/invitations`,
      invitation,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IGraphApiSendMailAttachment {
  '@odata.type': '#microsoft.graph.fileAttachment'
  name: string
  contentType: string
  contentBytes: string
}

export interface IGraphApiSendMailRecipient {
  emailAddress: {
    address: string
  }
}

export interface IGraphApiSendMailMessage {
  subject: string
  body: {
    contentType: 'HTML' | 'Text'
    content: string
  }
  toRecipients: IGraphApiSendMailRecipient[]
  ccRecipients?: IGraphApiSendMailRecipient[]
  bccRecipients?: IGraphApiSendMailRecipient[]
  attachments?: IGraphApiSendMailAttachment[]
}
export interface IGraphApiSendMailRequest {
  message: IGraphApiSendMailMessage
  saveToSentItems?: boolean
}

export const sendMail = (
  options: IApiOptions,
  request: IGraphApiSendMailRequest
) => {
  return axios
    .post<IGuestUserInvitation>(
      `${options.apiRoot}/v1.0/me/sendMail`,
      request,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const searchEmployees = (
  { searchString }: { searchString: string },
  options: IApiOptions
): Promise<IGraphUser[] | undefined> => {
  const searchFields = [
    'displayName',
    'givenName',
    'surname',
    'mail',
    'jobTitle',
    'userPrincipalName',
    'department'
  ]

  const select: string[] = [
    'businessPhones',
    'displayName',
    'givenName',
    'employeeId',
    'jobTitle',
    'mail',
    'mobilePhone',
    'officeLocation',
    'preferredLanguage',
    'surname',
    'department',
    'userPrincipalName',
    'id',
    'userType',
    'accountEnabled'
  ]
  const searchFilters = searchString
    ? searchFields.map((x) => `${x}, ':${searchString}'`)
    : []

  const path = `users?${[searchFilters, `$select=${select.join(',')}`]
    .filter(isNotNullOrFalse)
    .join('&')}`

  return axios
    .get<IOdataResult<IGraphUser>>(`${options.apiRoot}/v1.0/${path}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      }
    })
    .then((x) => x?.data?.value)
}
