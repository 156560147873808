import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { IOdataListFacetSelectors } from '../common/IODataListFacetSelectors'

export interface ICreateOdataListUiStoreOptions<T, U> {
  prefix: string
  rootSelector: (state: U) => IOdataListFacetState<T> | undefined
}

export interface IOdataListsFacetRequestPayload {
  id: string
}

export type IOdataListFacetResult<T> = { [K in keyof T]?: string } & {
  count: number
}

export interface IOdataListFacetState<T> {
  items?: Record<string, IOdataListFacetResult<T>[]>
  isLoading?: boolean
  error?: Error
}

export const createOdataListFacetStore = <T, U>(
  options: ICreateOdataListUiStoreOptions<T, U>
) => {
  const { prefix, rootSelector } = options
  const initialState: IOdataListFacetState<T> = {}
  const slice = createSlice({
    name: prefix,
    initialState,
    reducers: {
      request(state, action: PayloadAction<IOdataListsFacetRequestPayload>) {
        const { id } = action.payload
        const newState = { ...state, isLoading: true, error: undefined }
        if (id && newState.items) {
          delete newState.items[id]
        }
        return newState
      },
      complete: (
        state,
        action: PayloadAction<Record<string, IOdataListFacetResult<T>[]>>
      ) => ({
        ...state,
        isLoading: false,
        items: { ...state.items, ...action.payload } as Record<
          string,
          IOdataListFacetResult<T>[]
        >
      }),
      error(state, action: PayloadAction<Error>) {
        state.error = action.payload
      },
      reset(state) {
        state.items = undefined
      }
    }
  })
  const selectors: IOdataListFacetSelectors<T, U> = {
    getItems: flow(rootSelector, (x) => x?.items),
    getError: flow(rootSelector, (x) => x?.error),
    getIsLoading: flow(rootSelector, (x) => x?.isLoading)
  }
  return { reducer: slice.reducer, actions: slice.actions, selectors }
}
