import { push } from 'connected-react-router'
import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { fetchPaymentList } from '../api/DashboardService'
import {
  fetchPaymentListDataActions,
  paymentDashboardNavigationActions
} from './actions'
import { PaymentRow } from './types'

function* handleFetchPaymentList(
  action: ReturnType<typeof fetchPaymentListDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(fetchPaymentList, action.payload, options)

    if (response?.rspStatus?.statusCode === '0') {
      const data = response?.rspn
      const paymentRows = data?.map(
        (x) =>
          ({
            refnum: x?.paymentMM?.pmmID,
            fedRefNumber: x?.paymentMM?.fedRefNumber,
            status: x?.paymentMM?.displayStatus,
            statusdescription: x?.paymentMM?.mmStatusDescription,
            account: x?.paymentMM?.tranInfo?.bankAcctFromAcctId,
            confirmNo: x?.paymentMM.confirmNo,
            payee:
              x?.paymentMM?.tranInfo?.furtherCredit2_NameLine1 ||
              x?.paymentMM?.tranInfo?.furtherCredit1_NameLine1 ||
              x?.paymentMM?.tranInfo?.beneficiaryName,
            transtype: x?.paymentMM?.tranType,
            clientname: x?.paymentMM?.clientName,
            amount: x?.paymentMM?.tranInfo?.trnAmt,
            processdate: x?.paymentMM?.tranDate,
            creationdate: x?.paymentMM?.createdOn,
            assignToId: x?.paymentMM?.assignToId,
            actions: x?.paymentMM?.nextAction,
            rowData: x
          } as PaymentRow)
      )
      yield put(
        fetchPaymentListDataActions.success({
          paymentList: paymentRows || [],
          statistics: response?.statistics || []
        })
      )
    } else {
      yield put(
        fetchPaymentListDataActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentListDataActions.failure(e))
  }
}

function* handleNavigateToEdit() {
  yield put(push('/transfers/wire/new'))
}

function* handleNavigateToView() {
  yield put(push('/transfers/wire/view'))
}

function* handleNavigateToUpdateFedRefNo() {
  yield put(push('/transfers/wire/updateFedRefNo'))
}
function* handleNavigateToUpdateDFDisclosure() {
  yield put(push('/transfers/wire/updateDFDisclosure'))
}
function* handleNavigateToUpdateDFAcknowledge() {
  yield put(push('/transfers/wire/updateDFAcknowledge'))
}

function* handleNavigateToReview() {
  yield put(push('/transfers/approval'))
}

export const sagas = [
  () => takeLatest(fetchPaymentListDataActions.request, handleFetchPaymentList),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToEdit,
      handleNavigateToEdit
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToView,
      handleNavigateToView
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateFedRef,
      handleNavigateToUpdateFedRefNo
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateDFAcknowledge,
      handleNavigateToUpdateDFAcknowledge
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateDFDisclosure,
      handleNavigateToUpdateDFDisclosure
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToReview,
      handleNavigateToReview
    )
]
