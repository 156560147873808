import { TagDescription } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  IActivity,
  ICdsBatchRequestItem,
  IContact,
  IDynamicsApiResult,
  INotes,
  ISystemUser
} from 'api/dynamics'
import { subDays } from 'date-fns'
import { stringify } from 'query-string'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createCdsBatchPayload, dynamicsApi } from 'store/api/dynamics'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'
import { apiConstants } from './apis'
import { ActivityPointerActivityMimeAttachment } from './types'

export interface ICustomAccountGroup {
  rcm_customaccountgroupid?: string
  rcm_name?: string
  _rcm_household_value?: string
  rcm_cag_rcm_financialaccount?: IFinancialAccount[]
}

export interface IFinancialAccount {
  rcm_accountnumber?: string
  rcm_cdmaccountid?: string
}

export interface IAddNotePayload {
  subject: string
  notetext: string
  'objectid_contact@odata.bind': string
  mimetype?: string
  filename?: string
  documentbody?: string
  isdocument?: boolean
}

export interface IAddTaskPayload {
  actualdurationminutes: number
  description: string
  subject: string
  'ownerid_task@odata.bind'?: string
  'regardingobjectid_contact_task@odata.bind': string
  prioritycode: number
  scheduledend: string
  statecode?: number
  statuscode?: number
  rcm_sendemail?: boolean
}
export interface IReassignTaskPayload {
  'ownerid_task@odata.bind'?: string
}

export interface ICreateInteractionPayload {
  aka_type: number
  subject: string
  scheduledend: string
  ram_sentiment?: number
  'regardingobjectid_contact_aka_meetingnote@odata.bind': string
  rpm_annualreview?: boolean
  rcm_city?: string
  rcm_stateprovince?: string
  rcm_countryregion?: string
  description?: string
  mimeType?: string
  filename?: string
  documentbody?: string
  isdocument?: boolean
}

export interface IInteraction {
  'aka_type@OData.Community.Display.V1.FormattedValue': string
  'scheduledend@OData.Community.Display.V1.FormattedValue': string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue': string
  subject: string
  description: string
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  activityid?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
}

export const InteractionTypeOptions = [
  'Email',
  'Phone Call',
  'Meeting - Onsite',
  'Meeting - Offsite',
  'Meal / Social',
  'Conference / Event',
  'Encounter',
  'Conference Call',
  'Finals Pitch',
  'Video Call'
] as const

export type IInteractionType = (typeof InteractionTypeOptions)[number]

export function convertInteractionTypeToFilter(type: IInteractionType) {
  switch (type) {
    case 'Email':
      return 964110005
    case 'Phone Call':
      return 964110003
    case 'Meeting - Onsite':
      return 964110000
    case 'Meeting - Offsite':
      return 964110001
    case 'Meal / Social':
      return 964110002
    case 'Conference / Event':
      return 804820001
    case 'Encounter':
      return 964110004
    case 'Conference Call':
      return 804820000
    case 'Finals Pitch':
      return 804820002
    case 'Video Call':
      return 412290001
  }
}

export function convertInteractionTypeToActivityFilter(
  type?: IInteractionType
) {
  switch (type) {
    case 'Email':
      return 'email'
    case 'Phone Call':
      return 'phonecall'
    default:
      return undefined
  }
}

export interface INoteCategory {
  categoryName?: string
  color?: string
}

const { cacheTime } = apiConstants

type DynamicsApiTagType =
  | 'rdot360'
  | 'Notes'
  | 'Tasks'
  | 'Interactions'
  | 'CAGs'
const datahubApiTags: DynamicsApiTagType[] = [
  'rdot360',
  'Notes',
  'Tasks',
  'Interactions',
  'CAGs'
]

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const dynamicsApiWithRdot360Tags = dynamicsApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})
export const rdot360DynamicsApi = dynamicsApiWithRdot360Tags.injectEndpoints({
  endpoints: (builder) => ({
    getContactsFromHousehold: builder.query<IContact[] | undefined, string>({
      query: (householdId) => ({
        url: [
          `/contacts?$filter=(rcm_contact_aka_rollup/any(o1:(o1/rcm_householdid eq '${householdId}')))`,
          `&$select=${[
            'firstname',
            'lastname',
            'nickname',
            'birthdate',
            'mobilephone',
            'telephone1',
            'telephone2',
            'emailaddress1',
            'contactid',
            'fullname',
            'address1_line1',
            'address1_line2',
            'address1_line3',
            'address1_city',
            'address1_stateorprovince',
            'address1_postalcode',
            'address1_country',
            'rpm_headofhousehold',
            'modifiedon',
            'rpm_dateofbirth',
            'address1_composite',
            'rcm_contacttype',
            'jobtitle',
            'rcm_secureid',
            'rcm_taxidtype',
            'rpm_headofhousehold',
            'familystatuscode',
            'gendercode',
            'rcm_rockcodbpartyid',
            '_aka_householdid_value',
            'rpm_employer'
          ].join(',')}`
        ].join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getSystemUsers: builder.query<
      ISystemUser[] | undefined,
      string | undefined
    >({
      query: (search) => ({
        url: '/systemusers',
        params: {
          $select: 'systemuserid, fullname',
          $filter: [
            search && `contains(fullname, '${search}')`,
            'systemuserid ne null',
            'fullname ne null'
          ]
            .filter(Boolean)
            .join(' and '),
          $top: 10
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    findContactsByEmailAddress: builder.query<IContact[] | undefined, string[]>(
      {
        query: (emailAddresses: string[]) => ({
          url: [
            `/contacts?`,
            `$filter=statecode eq 0 and (${emailAddresses
              .map((x) =>
                ['emailaddress1', 'emailaddress2']
                  .map((prop) => `${prop} eq '${encodeURIComponent(x)}'`)
                  .join(' or ')
              )
              .join(' or ')})`,
            `&$select=${[
              'firstname',
              'lastname',
              'birthdate',
              'mobilephone',
              'telephone1',
              'telephone2',
              'emailaddress1',
              'contactid',
              'fullname',
              'address1_line1',
              'address1_line2',
              'address1_line3',
              'address1_city',
              'address1_stateorprovince',
              'address1_postalcode',
              'address1_country',
              'rpm_headofhousehold',
              'modifiedon',
              'rpm_dateofbirth',
              'address1_composite',
              'rcm_contacttype',
              'jobtitle',
              'rcm_secureid',
              'rcm_taxidtype',
              'rpm_headofhousehold',
              'familystatuscode',
              'gendercode'
            ].join(',')}`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
          }
        }),
        transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
        providesTags: ['rdot360'],
        keepUnusedDataFor: cacheTime
      }
    ),
    getNotesByContacts: builder.query<
      INotes[] | undefined,
      {
        contactIds: string[]
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      query: ({ contactIds, order, searchText, last30Days }) => ({
        url: [
          `/annotations?$filter=Microsoft.Dynamics.CRM.In(PropertyName='objectid',PropertyValues=[${contactIds
            .map((x) => `'${x}'`)
            .join(',')}]) ${
            searchText
              ? `and (contains(notetext, '${searchText}') or contains(subject, '${searchText}'))`
              : ''
          } ${
            last30Days
              ? `and modifiedon ge '${subDays(new Date(), 29).toISOString()}'`
              : ''
          } &$expand=objectid_contact&$orderby=modifiedon ${order || 'desc'}
          &$select=${[
            'filename',
            'mimetype',
            'annotationid',
            'notetext',
            'objectid_contact',
            '_ownerid_value',
            'modifiedon',
            '_modifiedby_value',
            'objectid_contact',
            'subject',
            '_createdby_value',
            'createdon'
          ].join(',')}
          &$top=50`
        ]
          .filter(Boolean)
          .join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<INotes>) => x.value,
      providesTags: ['Notes', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getNoteAttachment: builder.query<INotes | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<INotes, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `annotations(${id})`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $select: ['filename', 'mimetype', 'documentbody']
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getTasksByContacts: builder.query<
      IActivity[] | undefined,
      {
        contactIds: string[]
        startDate?: string
        endDate?: string
        order?: 'asc' | 'desc'
        searchText?: string
      }
    >({
      query: ({ contactIds, startDate, endDate, order, searchText }) => ({
        url: '/tasks',
        params: {
          $filter: [
            `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])`,
            startDate && `scheduledend ge '${startDate}'`,
            endDate && `scheduledend le '${endDate}'`,
            searchText &&
              `(contains(description, '${searchText}') or contains(subject, '${searchText}'))`
          ]
            .filter(Boolean)
            .join(' and '),
          $orderby: `scheduledend ${order || 'desc'}`,
          $top: 50
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IActivity>) => x.value,
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getTaskCounts: builder.query<
      number | undefined,
      { contactIds: string[]; startDate?: string; endDate?: string }
    >({
      query: ({ contactIds, startDate, endDate }) => ({
        url: '/tasks',
        params: {
          $filter: [
            `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])`,
            startDate && `scheduledend ge '${startDate}'`,
            endDate && `scheduledend le '${endDate}'`
          ]
            .filter(Boolean)
            .join(' and '),
          $count: 'true',
          $top: 1
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IActivity>) =>
        x['@odata.count'],
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitiesByContacts: builder.query<
      IActivity[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      query: ({ contactIds, type, search, order }) => ({
        url: [
          `/activitypointers?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
            .map((x) => `'${x}'`)
            .join(',')}])${
            convertInteractionTypeToActivityFilter(type)
              ? ` and activitytypecode eq '${convertInteractionTypeToActivityFilter(
                  type
                )}'`
              : ''
          } ${
            search
              ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
              : ''
          } &$expand=regardingobjectid_contact($select=fullname),activity_pointer_activity_mime_attachment($select=activitymimeattachmentid,filename,mimetype)&$orderby=modifiedon ${
            order || 'desc'
          } &$top=50`
        ]
          .filter(Boolean)
          .join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IActivity>) => x.value,
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getEmailAttachment: builder.query<
      ActivityPointerActivityMimeAttachment | undefined,
      string
    >({
      queryFn: async (attachementId, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          ActivityPointerActivityMimeAttachment,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `activitymimeattachments(${attachementId})`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getInteractionsByContacts: builder.query<
      IInteraction[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      query: ({ contactIds, type, search, order }) => ({
        url: [
          `/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
            .map((x) => `'${x}'`)
            .join(',')}]) ${
            type && convertInteractionTypeToFilter(type)
              ? ` and aka_type eq ${convertInteractionTypeToFilter(type)}`
              : ''
          } ${
            search
              ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
              : ''
          } &$expand=regardingobjectid_contact($select=fullname)&$orderby=modifiedon ${
            order || 'desc'
          } &$top=50`
        ]
          .filter(Boolean)
          .join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IInteraction>) => x.value,
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getMostRecentInteractionByContacts: builder.query<
      IInteraction | undefined,
      string[]
    >({
      query: (contactIds: string[]) => ({
        url: [
          `/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
            .map((x) => `'${x}'`)
            .join(',')}])
            &$expand=regardingobjectid_contact&$orderby=createdon desc&$top=1`
        ]
          .filter(Boolean)
          .join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IInteraction>) => x.value?.[0],
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_addNote: builder.mutation<
      INotes,
      {
        notePayload: IAddNotePayload
        contacts: string[]
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      query: ({ notePayload }) => ({
        url: `/annotations`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: notePayload
      }),
      invalidatesTags: ['Notes']
    }),
    rdot360_editNote: builder.mutation<
      INotes,
      {
        notePayload: IAddNotePayload
        contacts: string[]
        id: string
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      query: ({ notePayload, id }) => ({
        url: `/annotations(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: notePayload
      }),
      invalidatesTags: ['Notes']
    }),
    rdot360_createTask: builder.mutation<
      IActivity,
      {
        taskPayload: IAddTaskPayload
        contacts: string[]
        startDate?: string
        endDate?: string
      }
    >({
      query: ({ taskPayload }) => ({
        url: `/tasks`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_reassignTask: builder.mutation<
      undefined,
      { id: string; taskPayload: IReassignTaskPayload }
    >({
      query: ({ id, taskPayload }) => ({
        url: `/tasks(${id})`,
        method: 'PATCH',
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_createInteraction: builder.mutation<
      IInteraction,
      ICreateInteractionPayload
    >({
      query: (payload) => ({
        url: `/aka_meetingnotes`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_getNoteCategories: builder.query<
      INoteCategory[] | undefined,
      undefined
    >({
      query: () => ({
        url: `/noteCategories`,

        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<INoteCategory>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_updateKeyContact: builder.mutation<
      IContact,
      {
        keyContact: boolean
        id: string
        householdId: string
      }
    >({
      query: ({ keyContact, id }) => ({
        url: `/contacts(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: { rpm_headofhousehold: keyContact }
      }),
      async onQueryStarted({ householdId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        dispatch(
          rdot360DynamicsApi.util.updateQueryData(
            'getContactsFromHousehold',
            householdId,
            (draft) => {
              const contactIndex = data?.contactid
                ? draft?.map((x) => x.contactid).indexOf(data?.contactid)
                : undefined
              contactIndex !== undefined && draft?.splice(contactIndex, 1, data)
            }
          )
        )
      }
    }),
    rdot360_getCustomAccountGroups: builder.query<
      ICustomAccountGroup[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: [
          `/rcm_customaccountgroups?$filter=rcm_household/rcm_householdid eq '${householdId}'`,
          '&$expand=rcm_cag_rcm_financialaccount'
        ].join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<ICustomAccountGroup>) =>
        x.value,
      providesTags: ['rdot360', 'CAGs'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_deleteCustomAccountGroup: builder.mutation<undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<undefined, Error>
        const response = (await baseQuery({
          url: `/rcm_customaccountgroups(${id})`,
          method: 'DELETE'
        })) as Response
        if (response.error) {
          return { error: response.error }
        }
        return { ...response, error: undefined }
      },
      invalidatesTags: ['CAGs']
    }),
    rdot360_createCustomAccountGroup: builder.mutation<
      any,
      {
        name: string
        householdId: string
        accountIds: string[]
      }
    >({
      queryFn: async (
        { name, householdId, accountIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<string, Error>
        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/rcm_customaccountgroups',
          method: 'POST',
          data: {
            rcm_name: name,
            'rcm_household@odata.bind': `/aka_rollups(rcm_householdid='${householdId}')`,
            statecode: 0,
            statuscode: 1
          },
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
          }
        }

        const createdGroup = (await baseQuery({
          ...baseApiArgs
        })) as { data?: ICustomAccountGroup }
        const createdGroupId = createdGroup?.data?.rcm_customaccountgroupid

        if (!createdGroupId) {
          return { error: new Error('Failed to create group') }
        }

        const requests = accountIds?.map(
          (x) =>
            ({
              method: 'POST',
              url: `/api/data/v9.0/rcm_customaccountgroups(${createdGroupId})/rcm_cag_rcm_financialaccount/$ref`,
              payload: {
                '@odata.context': '/api/data/v9.0/$metadata#$ref',
                '@odata.id': `rcm_financialaccounts(rcm_cdmaccountid='${x}')`
              }
            } as ICdsBatchRequestItem)
        )

        const { batchRequest, boundary } = createCdsBatchPayload(requests)

        const batchResponse = (await baseQuery({
          url: '/$batch',
          method: 'POST',
          headers: {
            'Content-Type': `multipart/mixed;boundary=${boundary}`,
            Accept: 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0'
          },
          data: batchRequest
        })) as Response

        if (batchResponse.error) {
          await baseQuery({
            url: `/rcm_customaccountgroups(${createdGroupId})`,
            method: 'DELETE'
          })
        }

        return batchResponse
      },
      invalidatesTags: ['CAGs']
    })
  })
})

export const {
  useFindContactsByEmailAddressQuery,
  useGetContactsFromHouseholdQuery,
  useGetNotesByContactsQuery,
  useRdot360_addNoteMutation,
  useGetTasksByContactsQuery,
  useGetInteractionsByContactsQuery,
  useRdot360_createTaskMutation,
  useGetTaskCountsQuery,
  useGetMostRecentInteractionByContactsQuery,
  useRdot360_getNoteCategoriesQuery,
  useRdot360_editNoteMutation,
  useGetActivitiesByContactsQuery,
  useLazyGetEmailAttachmentQuery,
  useRdot360_createInteractionMutation,
  useRdot360_updateKeyContactMutation,
  useRdot360_getCustomAccountGroupsQuery,
  useRdot360_deleteCustomAccountGroupMutation,
  useRdot360_createCustomAccountGroupMutation,
  useLazyGetNoteAttachmentQuery,
  useGetSystemUsersQuery,
  useRdot360_reassignTaskMutation
} = rdot360DynamicsApi

export const useDynamicsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DynamicsApiTagType>[]) =>
      dispatch(rdot360DynamicsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
