export type IncomeType = 'historical' | 'projected'

export const getDates = (
  incomeType: IncomeType,
  financialYear: number
): string[] => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dates: string[] = []
  const date = new Date()
  const currentYear = date.getFullYear()
  let currentMonth = date.getMonth()

  if (incomeType === 'historical') {
    currentMonth += 1
    for (let i = 0; i < 12; i++) {
      if (currentYear === financialYear) {
        dates.push(
          `${months[currentMonth % 12]} ${
            currentMonth < 12 ? (currentYear % 100) - 1 : currentYear % 100
          }`
        )

        currentMonth += 1
      } else {
        dates.push(`${months[i]} ${financialYear % 100}`)
      }
    }
  } else {
    for (let i = 0; i < 12; i++) {
      dates.push(
        `${months[currentMonth % 12]} ${
          currentMonth < 12 ? currentYear % 100 : (currentYear + 1) % 100
        }`
      )

      currentMonth += 1
    }
  }

  return dates
}
