import { rdot360Api } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { IProfileApiContext } from './IProfileApiContext'

const { cacheTime } = apiConstants

export const profileApi = rdot360Api.injectEndpoints({
  endpoints: (builder) => ({
    setContext: builder.mutation<
      unknown,
      { key: string; context: IProfileApiContext }
    >({
      query: ({ key, context }) => ({
        url: `profileapi/odata/context?key=${key}`,
        method: 'POST',
        data: [context]
      })
    }),
    getContext: builder.query<IProfileApiContext, { key: string }>({
      query: ({ key }) => `profileapi/odata/context('${key}')`,
      keepUnusedDataFor: cacheTime
    })
  })
})

export const { useSetContextMutation } = profileApi
