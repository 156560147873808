import { combineReducers } from 'redux'
import { createReducer, ActionType } from 'typesafe-actions'
import { IClient } from '../../../api/client.types'
import { IGraphUser } from '../../../api/graph'
import {
  IDonor,
  IPhilanthropyGrant,
  IGrantTrustee,
  IProfileUser
} from '../../../api/philanthropy'
import {
  donorDataActions,
  grantDataActions,
  donorPostActions,
  grantManagerSearchActions,
  PhilanthropyActionTypes,
  grantPostActions,
  donorSearchActions,
  trusteeSearchActions,
  grantMakerSearchActions
} from './actions'

export interface IPhilanthropyDonorDataState {
  items: IDonor[]
  isLoading: boolean
  error?: Error
}

const initialState: IPhilanthropyDonorDataState = {
  items: [],
  isLoading: false
}

export interface IPhilanthropyDonorPostState {
  status: number
  isLoading: boolean
  error?: Error
}

const initialPostState: IPhilanthropyDonorPostState = {
  status: 0,
  isLoading: false
}

export const donorPostReducer = createReducer<
  IPhilanthropyDonorPostState,
  PhilanthropyActionTypes
>(initialPostState)
  .handleAction(donorPostActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(donorPostActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    status: action.payload.status
  }))
  .handleAction(donorPostActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    status: 500,
    error: action.payload
  }))

export const donorDataReducer = createReducer<
  IPhilanthropyDonorDataState,
  PhilanthropyActionTypes
>(initialState)
  .handleAction(donorDataActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(donorDataActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    items: action.payload.donors
  }))
  .handleAction(donorDataActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    items: [],
    error: action.payload
  }))

export interface IPhilanthropyTrusteeState {
  items: IGrantTrustee[]
  isLoading: boolean
  error?: Error
}

const initialTrusteeState: IPhilanthropyTrusteeState = {
  items: [],
  isLoading: false
}

export const trusteeDataReducer = createReducer<
  IPhilanthropyTrusteeState,
  PhilanthropyActionTypes
>(initialTrusteeState)
  .handleAction(trusteeSearchActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(trusteeSearchActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    items: action.payload.data
  }))
  .handleAction(trusteeSearchActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    items: [],
    error: action.payload
  }))

export interface IPhilanthropygGrantDataState {
  items: IPhilanthropyGrant[]
  isLoading: boolean
  error?: Error
}

const initialDataGrantState: IPhilanthropygGrantDataState = {
  items: [],
  isLoading: false
}

function isVisible(element: IPhilanthropyGrant) {
  return element.state !== 'Draft' && element.state !== 'Cancelled Payment'
}

export const grantDataReducer = createReducer<
  IPhilanthropygGrantDataState,
  PhilanthropyActionTypes
>(initialDataGrantState)
  .handleAction(grantDataActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(grantDataActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    items: action.payload.grants.filter(isVisible)
  }))
  .handleAction(grantDataActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    items: [],
    error: action.payload
  }))

export interface IPhilanthropyGrantPostState {
  status: number
  isLoading: boolean
  error?: Error
}

const initialGrantPostState: IPhilanthropyGrantPostState = {
  status: 0,
  isLoading: false
}

export const grantPostReducer = createReducer<
  IPhilanthropyGrantPostState,
  PhilanthropyActionTypes
>(initialGrantPostState)
  .handleAction(grantPostActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(grantPostActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    status: action.payload.status
  }))
  .handleAction(grantPostActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    status: 500,
    error: action.payload
  }))

export interface IGrantMakerSearchState {
  items: IProfileUser[]
  isLoading: boolean
  error?: Error
}

const initialGrantMakerSearchState: IGrantMakerSearchState = {
  items: [],
  isLoading: false
}

export const grantMakerSearchDataReducer = createReducer<
  IGrantMakerSearchState,
  PhilanthropyActionTypes
>(initialGrantMakerSearchState)
  .handleAction(grantMakerSearchActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(grantMakerSearchActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    items: action.payload
  }))
  .handleAction(grantMakerSearchActions.clear, (state) => ({
    ...state,
    isLoading: false,
    items: []
  }))
  .handleAction(grantMakerSearchActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    items: [],
    error: action.payload
  }))

export interface IGrantManagerSearchState {
  items: IGraphUser[]
  isLoading: boolean
  error?: Error
}

const initialGrantManagerSearchState: IGrantManagerSearchState = {
  items: [],
  isLoading: false
}

export const grantManagerSearchDataReducer = createReducer<
  IGrantManagerSearchState,
  PhilanthropyActionTypes
>(initialGrantManagerSearchState)
  .handleAction(grantManagerSearchActions.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(grantManagerSearchActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    items: action.payload
  }))
  .handleAction(grantManagerSearchActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    items: [],
    error: action.payload
  }))

export interface IDonorSearchState {
  items?: IClient[]
  loading?: boolean
  error?: Error
}

const initialDonorSearchState: IDonorSearchState = {
  items: [],
  loading: false
}

export const donorSearchReducer = createReducer<
  IDonorSearchState,
  ActionType<typeof donorSearchActions>
>(initialDonorSearchState)
  .handleAction(donorSearchActions.success, (state, action) => ({
    ...state,
    items: action.payload
  }))
  .handleAction(donorSearchActions.clear, (state) => ({
    ...state,
    isLoading: false,
    items: []
  }))
  .handleAction(donorSearchActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))

const dataReducer = combineReducers({
  donors: donorDataReducer,
  donorPost: donorPostReducer,
  grants: grantDataReducer,
  grantPost: grantPostReducer,
  grantManagerSearch: grantManagerSearchDataReducer,
  grantMakerSearch: grantMakerSearchDataReducer,
  donorSearch: donorSearchReducer,
  trustees: trusteeDataReducer
})

export const philanthropyReducer = combineReducers({
  data: dataReducer
})
