import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { getPeerReviewModuleRootState } from './shared'

export interface IPeerReviewDashboardState {
  selectedTeam?: string
}
const initialState: IPeerReviewDashboardState = {}

export const {
  actions: peerReviewDashboardActions,
  reducer: peerReviewDashboardReducer
} = createSlice({
  name: '@modules/@advisory/@modules/@teams/@modules/@PeerReview/@peerReviewDashboard',
  initialState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      selectedTeam: action.payload
    })
  }
})

const getPeerReviewDashboardState = flow(
  getPeerReviewModuleRootState,
  ({ peerReviewDashboard }) => peerReviewDashboard
)

export const getSelectedTeam = flow(
  getPeerReviewDashboardState,
  ({ selectedTeam }) => selectedTeam
)
