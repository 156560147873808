import { useTheme } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { Icon } from '../features/Icons/Icon'

export type BaseHeaderProps = {
  disableExpand?: boolean
  onExpand?: () => void
}

export enum ViewType {
  graph = 'graph',
  pie = 'pie',
  table = 'table'
}
export type TileHeadingProps = BaseHeaderProps & {
  title?: string
  viewType?: ViewType
  children?: ReactNode
  showIcons?: boolean
  graphIconType?: string
  onViewtypeChange?: (view: ViewType) => void
}
const TileHeading: FC<TileHeadingProps> = ({
  title,
  children,
  showIcons = true,
  graphIconType,
  viewType,
  disableExpand,
  onViewtypeChange = () => {
    return
  },
  onExpand = () => {
    return
  }
}) => {
  {
    const theme = useTheme()
    return (
      <div
        css={{
          fontSize: '14px',
          lineHeight: '1.5',
          textTransform: 'uppercase',
          fontWeight: 500,
          display: 'flex',
          flex: '0 0 20px',
          justifyContent: 'space-between'
        }}
      >
        <div>{title || children}</div>
        {showIcons && (
          <div
            css={{
              display: 'flex',
              gap: 12,
              lineHeight: 1,
              paddingBottom: '5px'
            }}
          >
            {graphIconType === 'chart' && (
              <div css={{ opacity: ViewType.graph === viewType ? 1 : 0.5 }}>
                <Icon
                  type="GraphView"
                  width={16}
                  height={16}
                  color={theme.colors.tertiaryBlue1}
                  onClick={() => onViewtypeChange(ViewType.graph)}
                  title="Click to view chart"
                />
              </div>
            )}
            {graphIconType === 'pieIcon' && (
              <div css={{ opacity: ViewType.pie === viewType ? 1 : 0.5 }}>
                <Icon
                  type="ChartView"
                  width={16}
                  height={16}
                  color={theme.colors.tertiaryBlue1}
                  onClick={() => onViewtypeChange(ViewType.pie)}
                  title="Click to view graph"
                />
              </div>
            )}
            {graphIconType && (
              <div css={{ opacity: ViewType.table === viewType ? 1 : 0.5 }}>
                <Icon
                  type="TableView"
                  width={16}
                  height={16}
                  color={theme.colors.tertiaryBlue1}
                  onClick={() => onViewtypeChange(ViewType.table)}
                  title="Click to view table"
                />
              </div>
            )}
            {!disableExpand && (
              <Icon
                type="ExpandWidget"
                width={16}
                height={16}
                color={theme.colors.tertiaryBlue1}
                onClick={onExpand}
                title="View more"
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default TileHeading
