import { combineReducers } from 'redux'
import { accountSelectorFeatureReducer } from './AccountSelector'
import { deleteCustomGroupReducer } from './DeleteCustomAccountGroup'
import { sidePanelFeatureReducer } from './SidePanel'

export const rdot360FeaturesReducer = combineReducers({
  accountSelector: accountSelectorFeatureReducer,
  sidePanel: sidePanelFeatureReducer,
  deleteCustomGroup: deleteCustomGroupReducer
})
