import DetailsNavigator from '../../../components/shared/DetailsNavigator/DetailsNavigator'

export const PerfromanceDetailView: React.FC = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexFlow: 'row wrap',
        marginBottom: '16px'
      }}
    >
      <DetailsNavigator />
    </div>
  )
}
