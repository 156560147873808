import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotes } from 'api/dynamics'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'noteMenuCallout',
  initialState: {} as INoteMenuCalloutState,
  reducers: {
    show: (state, action: PayloadAction<{ target: string; note: INotes }>) => ({
      showCallout: true,
      targetId: action.payload.target,
      note: action.payload.note
    }),
    hide: () => ({
      showCallout: false,
      error: undefined,
      targetId: undefined,
      note: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as noteMenuCalloutReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes.noteMenuCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getPanelError = flow(rootSelector, (x) => x.error)
const getTargetId = flow(rootSelector, (x) => x.targetId)
const getNote = flow(rootSelector, (x) => x.note)

export interface INoteMenuCalloutState {
  showCallout?: boolean
  error?: Error
  targetId?: string
  note?: INotes
}

export const useNoteMenuCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(
    (id: string, note: INotes) => {
      dispatch(actions.show({ target: id, note: note }))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  const targetId = useSelector(getTargetId)

  const note = useSelector(getNote)

  return {
    hide,
    show,
    setError,
    showCallout,
    error,
    targetId,
    note
  }
}
