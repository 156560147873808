import { IColumnReorderOptions, Stack, Text } from '@fluentui/react'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IDataTableProps } from '../../shared/components/DataTable'
import { IListsFilter } from '../Lists/core/contracts/IListsFilter'
import { ListsFilterStatusList } from '../Lists/core/features/filter/components/ListsFilterStatusList'
import { IDataTableColumnDefinition, IDataTableSortBy } from './common/types'
import {
  DataTableWithMenu,
  IDataTableCellRenderProps
} from './components/DataTableWithMenu'
import { IDataListActions } from './contracts/IDataListActions'
import { IDataListColumnDefinition } from './contracts/IDataListColumnDefinition'
import { IDataListSelectors } from './contracts/IDataListSelectors'

export interface IDataListCellRenderProps<T = unknown> {
  item: T
  column: IDataListColumnDefinition<T>
}

export interface IDataListProps<T, U>
  extends Omit<IDataTableProps, 'columns' | 'items' | 'columnReorderOptions'> {
  actions: IDataListActions<T>
  selectors: IDataListSelectors<T, U>
  cell: React.FC<IDataListCellRenderProps<T>>
  hideDefaultHeader?: boolean
  secondaryHeader?: React.ReactElement<any, any>
}

export interface IDataListFilterStatusListProps<T, U> {
  actions: IDataListActions<T>
  selectors: IDataListSelectors<T, U>
}
export const DataListFilterStatusList = <T, U>({
  actions,
  selectors
}: PropsWithChildren<IDataListFilterStatusListProps<T, U>>): React.ReactElement<
  any,
  any
> | null => {
  const dispatch = useDispatch()
  const filters = useSelector(selectors.getFilters)

  const onFilterChange = useCallback(
    (filter: IListsFilter) =>
      dispatch(actions.updateFilters({ [filter.id]: filter })),
    [actions, dispatch]
  )

  const onFilterRemove = useCallback(
    (filter: IListsFilter) => {
      const { id, name, type } = filter
      dispatch(
        actions.updateFilters({
          [filter.id]: { id, name, type }
        })
      )
    },
    [actions, dispatch]
  )
  const onFilterClick = useCallback(
    (filter: IListsFilter) => {
      if (!(filter.type === 'facet' || filter.type === 'facet-search')) {
        return
      }

      dispatch(actions.updateFacet(filter.name))
    },
    [actions, dispatch]
  )

  return (
    <ListsFilterStatusList
      filters={filters || {}}
      onFilterChange={onFilterChange}
      onRemove={onFilterRemove}
      onFilterClick={onFilterClick}
    />
  )
}

export const DataList = <T, U>({
  actions,
  selectors,
  cell,
  secondaryHeader,
  hideDefaultHeader = false,
  ...rest
}: PropsWithChildren<IDataListProps<T, U>>): React.ReactElement<
  any,
  any
> | null => {
  const dispatch = useDispatch()
  const items = useSelector(selectors.getFilteredAndSortedItems)
  const itemsCount = useSelector(selectors.getItemsCount)
  const filters = useSelector(selectors.getFilters)
  const sortBy = useSelector(selectors.getSortBy)
  const columns = useSelector(selectors.getColumns)

  const onFilterChange = useCallback(
    (filter: IListsFilter) =>
      dispatch(actions.updateFilters({ [filter.id]: filter })),
    [actions, dispatch]
  )

  const onSortByChange = useCallback(
    (sortBy: IDataTableSortBy) => dispatch(actions.updateSort(sortBy)),
    [actions, dispatch]
  )

  const onColumnClick = useCallback(
    (column: IDataTableColumnDefinition) => {
      if (!column.facetable) {
        return
      }

      dispatch(actions.updateFacet(column.name))
    },
    [actions, dispatch]
  )

  const columnReorderOptions = useMemo(
    (): IColumnReorderOptions => ({
      frozenColumnCountFromEnd: 0,
      frozenColumnCountFromStart: 0,
      onColumnDrop: ({ draggedIndex, targetIndex }) => {
        const newColumns = [...(columns || [])]
        const dragged = newColumns.splice(draggedIndex, 1)
        newColumns.splice(targetIndex, 0, ...dragged)
        dispatch(actions.updateColumns(newColumns))
      }
    }),
    [actions, columns, dispatch]
  )

  const visibleColumns = useMemo(
    () => columns?.filter((x) => x?.hidden !== true),
    [columns]
  )

  return (
    <Stack>
      <Stack tokens={{ childrenGap: 5 }}>
        {!hideDefaultHeader && (
          <Stack
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 10 }}
          >
            <Stack.Item grow={1}>
              <DataListFilterStatusList
                actions={actions}
                selectors={selectors}
              />
            </Stack.Item>
            <Text styles={{ root: { fontWeight: 'bold' } }}>
              {items?.length || 0} of {itemsCount}
            </Text>
          </Stack>
        )}
        {secondaryHeader}
      </Stack>
      <DataTableWithMenu<T>
        columns={visibleColumns || []}
        items={items || []}
        sortBy={sortBy}
        onSortByChange={onSortByChange}
        filters={filters}
        onFilterChange={onFilterChange}
        cellComponent={cell as React.FC<IDataTableCellRenderProps<T>>}
        onColumnClick={onColumnClick}
        columnReorderOptions={columnReorderOptions}
        {...rest}
      />
    </Stack>
  )
}
