import axios, { CancelTokenSource } from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getDiscountShares, IDiscountShares } from '../../../../../api/datahub'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IOdataResult } from '../../../../../shared/contracts/IOdataResult'
import { isNotNullOrUndefined } from '../../../../../shared/gaurds'
import { AppState } from '../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'

export const discountSharingFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@bda/@discountSharing/REQUEST',
  '@modules/@advisory/@modules/@bda/@discountSharing/SUCCESS',
  '@modules/@advisory/@modules/@bda/@discountSharing/FAILURE'
)<number, IDiscountShares[] | undefined, Error>()

export const discountSharingFetchReducer = createAsyncReducer(
  discountSharingFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.discountSharing

export const {
  getError: getDiscountSharingFetchError,
  getIsLoading: getDiscountSharingFetchLoading,
  getResult: getDiscountSharingFetchResult
} = createAsyncSelectors(rootSelector)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchDiscountShares = function* (
  action: ReturnType<typeof discountSharingFetchActions.request>
) {
  const [apiOptions, source] = yield* call(getDatahubApiOptions)
  const pageSize = 500

  try {
    const discountShares = yield* call(
      getDiscountShares,
      apiOptions,
      new Date(Date.UTC(action.payload, 0, 1, 0, 0, 0, 0)),
      pageSize
    )

    const pages = [discountShares]
    let nextLink = discountShares['@odata.nextLink']

    const fetchNext = (nextLink: string) =>
      axios
        .get<IOdataResult<IDiscountShares>>(
          `${apiOptions.apiRoot}/datahub/D365Proxy/rcm_discountsharingorders${
            new URL(nextLink).search
          }`,
          {
            headers: {
              Authorization: `Bearer ${apiOptions.accessToken}`,
              Prefer: [
                `odata.maxpagesize=${pageSize}`,
                `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
              ].join(', ')
            }
          }
        )
        .then((x) => x.data)

    while (nextLink) {
      const next = yield* call(fetchNext, nextLink)
      pages.push(next)
      nextLink = next['@odata.nextLink']
    }

    const results =
      pages
        .map(({ value }) => value)
        .filter(isNotNullOrUndefined)
        .flat() || []

    yield put(discountSharingFetchActions.success(results))
  } catch (e: any) {
    yield put(discountSharingFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const discountSharingFetchSagas = [
  () => takeLatest(discountSharingFetchActions.request, fetchDiscountShares)
]
