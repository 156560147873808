import { FC } from 'react'

const Error: FC<{ refetch: () => void }> = ({ refetch }) => {
  return (
    <div css={{ padding: 16, fontSize: 16 }}>
      Error occured while fetching data.{' '}
      <a
        css={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: '#EF4264',
          padding: '0 16px'
        }}
        onClick={refetch}
      >
        Please try again
      </a>
    </div>
  )
}

export default Error
