import { IModuleDefinition } from 'modules/types'
import { IncomeContainer } from './IncomeContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/income/*',
    private: true,
    component: IncomeContainer
  },
  name: 'Income'
}

export default moduleDefinition
