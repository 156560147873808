import { combineReducers } from 'redux'
import { createNoteCalloutReducer } from './createNoteCallout'
import { noteCategoryCalloutReducer } from './noteCategoryCallout'
import { noteFilterCalloutReducer } from './noteFilterCallout'
import { noteFormReducer } from './noteForm'
import { noteMenuCalloutReducer } from './noteMenuCallout'
import { notesCalloutReducer } from './notesCallout'
import { noteSettingsCalloutReducer } from './noteSettingsCallout'
import { notesPanelReducer } from './notesPanel'
import { notesUiReducer } from './notesUi'

export const secondaryHeaderNotesReducer = combineReducers({
  notesPanel: notesPanelReducer,
  notesCallout: notesCalloutReducer,
  notesUi: notesUiReducer,
  createNoteCallout: createNoteCalloutReducer,
  noteMenuCallout: noteMenuCalloutReducer,
  noteCategoryCallout: noteCategoryCalloutReducer,
  noteSettingsCallout: noteSettingsCalloutReducer,
  noteFilterCallout: noteFilterCalloutReducer,
  noteForm: noteFormReducer
})
