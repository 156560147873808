import { Text } from '@fluentui/react'
import React from 'react'
import { USD } from '../../../../shared/components/Formatting'

export const USDCell: React.FC<{
  value?: number
  fractionDigits?: number
  currencySign?: 'standard' | 'accounting'
}> = ({ value, fractionDigits, currencySign }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2
  return value != null ? (
    <Text styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}>
      <USD
        currencySign={currencySign}
        value={value}
        fractionDigits={decimals}
      />
    </Text>
  ) : (
    <Text>--</Text>
  )
}
