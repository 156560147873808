import { Icon, Label, Stack, Text, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IClientInviteForm } from './ClientInvitePanel'

export const MoreEmailSettings: React.FC = () => {
  const register = useFormContext<IClientInviteForm>()
  const [expandSettings, setExpandSettings] = useState<boolean>(false)
  return (
    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { cursor: 'pointer' } }}>
      <Stack
        horizontal={true}
        onClick={() => {
          setExpandSettings(!expandSettings)
        }}
        tokens={{ childrenGap: 5 }}
      >
        <Text>More Email Settings</Text>
        <Icon iconName={expandSettings ? 'ChevronUp' : 'ChevronDown'} />
      </Stack>
      {expandSettings && (
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack>
            <Label required>From</Label>
            <Controller
              name="invite.from"
              control={register.control}
              rules={{ required: 'Enter From' }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    value={value || ''}
                    errorMessage={error && error.message}
                    onChange={(_e, name) => {
                      onChange(name)
                    }}
                  />
                )
              }}
            />
          </Stack>
          <Stack>
            <Label required>Invited By</Label>
            <Controller
              name="invite.invitedBy"
              control={register.control}
              rules={{ required: 'Enter Invited By' }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    value={value || ''}
                    errorMessage={error && error.message}
                    onChange={(_e, name) => {
                      onChange(name)
                    }}
                  />
                )
              }}
            />
          </Stack>
          <Stack>
            <Label required>Subject</Label>
            <Controller
              name="invite.subject"
              control={register.control}
              rules={{ required: 'Enter Subject' }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    value={value || ''}
                    errorMessage={error && error.message}
                    onChange={(_e, name) => {
                      onChange(name)
                    }}
                  />
                )
              }}
            />
          </Stack>
          <Stack>
            <Label required>Message</Label>
            <Controller
              name="invite.message"
              control={register.control}
              rules={{ required: 'Enter Message' }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    value={value || ''}
                    errorMessage={error && error.message}
                    multiline={true}
                    onChange={(_e, name) => {
                      onChange(name)
                    }}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
