import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IPerson, IPersonRequest } from '../store/types'

export const fetchPersonList = (
  options: IApiOptions,
  personReq: IPersonRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetDimensionValues?dimname=person&clientid=${personReq.pKid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPerson[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const addEditPerson = (
  options: IApiOptions,
  personReq: IPerson | undefined
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/InsertUpdateDimensionValue`
  ;`${options.apiRoot}/trustapi/api/VendorPayeeRule/GetDimensionValue?gldmvid=${personReq?.gldimensionvalueid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IPerson>(url, personReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
