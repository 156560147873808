import { USD } from 'shared/components/Formatting'
import { IColumn } from '../shared'

const columns: IColumn[] = [
  {
    field: 'Description',
    headerName: 'Description',
    headerStyle: {
      fontWeight: 'bold !important',
      width: 172
    }
  },
  {
    field: 'YTD',
    align: 'right',
    headerName: 'YTD',
    headerStyle: {
      fontWeight: 'bold !important'
    },
    format: (value) =>
      value ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  },
  {
    field: 'Last12Months',
    headerName: 'Last 12 Months',
    align: 'right',
    headerStyle: {
      fontWeight: 'bold !important'
    },
    format: (value) =>
      value ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  },
  {
    field: 'Next12Months',
    headerName: 'Next 12 Months',
    align: 'right',
    headerStyle: {
      fontWeight: 'bold !important'
    },
    format: (value) =>
      value ? (
        <USD value={value} fractionDigits={0} currencySign="standard" />
      ) : (
        '--'
      )
  }
]

export default columns
