import { parseISO, startOfMonth } from 'date-fns/fp'
import { sumBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { IAUSHistoryWithRepAndDate } from '../../../../../api/common.types'
import { AppState } from '../../../../../store'
import {
  getAdvisorContextAusHistory,
  getAdvisorContextItems,
  getAdvisorContextSortedRevenueHistory
} from '../../../../../store/context/advisor'
import { IBookTotals } from '../shared/types'

export const getDashboardState = (state: AppState) =>
  state.modules.advisory.modules.dashboard
export const getIsNetNewMoneySelected = flow(
  getDashboardState,
  (x) => x.isNetNewMoneySelected
)
export const getIsNetNewAssetsSelected = flow(
  getDashboardState,
  (x) => x.isNetNewAssetsSelected
)
export const getSelectedProductAllocations = flow(
  getDashboardState,
  (x) => x.selectedProductAllocations
)
export const getSelectedAssetAllocations = flow(
  getDashboardState,
  (x) => x.selectedAssetAllocations
)
export const getSelectedPositions = flow(
  getDashboardState,
  (x) => x.selectedPositions
)
export const getAllocationType = flow(
  getDashboardState,
  (x) => x.allocationType
)

export const getSortedAusHistory = createSelector(
  [getAdvisorContextAusHistory],
  (history): IAUSHistoryWithRepAndDate[] => {
    return (
      history
        ?.map((item) => {
          const date = flow(
            parseISO,
            startOfMonth,
            (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
          )(item.DateAsOf || '')

          return {
            ...item,
            date,
            timestamp: date.getTime()
          }
        })
        .sort((a, b) => a.timestamp - b.timestamp) || []
    )
  }
)

export const getTotals = createSelector(
  [getAdvisorContextItems, getAdvisorContextSortedRevenueHistory],
  (items, revenue): IBookTotals => {
    const totalAccounts = sumBy(items, (x) => x?.total?.noofaccounts || 0)
    const totalClients = sumBy(items, (x) => x?.total?.noofclient || 0)
    const totalHouseholds = sumBy(items, (x) => x?.total?.noofhousehold || 0)
    const aus = sumBy(items, (x) => x?.AdvisorKPI?.KPIs?.AumTotal || 0)
    const priorDayNewMoney = sumBy(
      items,
      (x) => x?.AdvisorKPI?.KPIs?.NetNewMoney || 0
    )
    const priorDayNewAssets = sumBy(items, (x) => x?.AdvisorKPI?.KPIs?.TOA || 0)
    const totalOrders = sumBy(
      items,
      (x) =>
        (x?.tradeActivity?.BuyCount || 0) + (x?.tradeActivity?.SellCount || 0)
    )
    const totalExternalAccounts = sumBy(
      items,
      (x) => x?.AdvisorKPI?.KPIs?.noOfExtAccounts || 0
    )
    const totalExternalAccountValue = sumBy(
      items,
      (x) => x?.AdvisorKPI?.KPIs?.extAccountAmount || 0
    )

    const revenueDate = revenue?.[revenue?.length - 1]?.date
    const lastMonth = revenue?.filter(
      (x) => x.date?.getTime() === revenueDate?.getTime()
    )

    const totalRevenue = lastMonth?.reduce(
      (a, x) => a + (x.revenueDet?.compRevenue || 0),
      0
    )

    return {
      totalHouseholds,
      totalAccounts,
      totalClients,
      aus,
      priorDayNewMoney,
      priorDayNewAssets,
      totalOrders,
      totalExternalAccounts,
      totalExternalAccountValue,
      totalRevenue,
      revenueDate
    }
  }
)

export const getDashboardListState = flow(getDashboardState, (x) => x.lists)
