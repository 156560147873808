import {
  addMonths,
  differenceInCalendarMonths,
  format,
  subYears
} from 'date-fns'
import { groupBy, orderBy, range, sum, sumBy } from 'lodash'
import { IEnhancedRevenueSummaryItem } from 'modules/Advisory/modules/Revenue/modules/Dashboard/features/RevenueSummaryTable/store/revenueSummaryFetch'
import { IRevenueTableItem } from 'modules/Advisory/modules/Revenue/modules/Dashboard/features/RevenueSummaryTable/store/revenueSummaryTable'
import { RevenueOrPayout } from 'modules/Advisory/modules/Revenue/modules/Dashboard/store/dashboard'
import { useMemo } from 'react'
import { useRdot360_getMonthlySummaryQuery } from '../../store/datahub'

export const useRevenueTable = () => {
  const { data: items } = useRdot360_getMonthlySummaryQuery({
    tradeReps: [
      '176',
      'R03',
      '170',
      '171',
      '172',
      '173',
      '174',
      '175',
      '536',
      '600',
      '676',
      '678',
      '679',
      '677',
      '661'
    ],
    catMode: 'L1',
    recipientReps: ['176', '170', '171', '172', '173', '174', '175']
  })
  const revOrPayout = 'revenue'
  const dateRangeDates = useMemo(
    () => [subYears(new Date(), 1), new Date()],
    []
  )
  const tableItems = useMemo(() => {
    const [startDateUtc, endDateUTC] = dateRangeDates || []

    if (!items?.length) {
      const blank = {
        category: '',
        total: 0,
        monthlyTotals: new Array(12).fill(0)
      }
      return new Array(8).fill(blank) as IRevenueTableItem[]
    }

    if (!startDateUtc) {
      throw new Error('No Date Range Found')
    }

    const startDateTz = new Date(
      startDateUtc.getUTCFullYear(),
      startDateUtc.getUTCMonth(),
      startDateUtc.getUTCDate()
    )
    const monthRange = endDateUTC
      ? differenceInCalendarMonths(endDateUTC, startDateUtc) + 1
      : 12

    return createTableItems(
      items,
      startDateTz,
      revOrPayout || 'revenue',
      monthRange
    )
  }, [dateRangeDates, items])
  const tableTotals = useMemo(() => {
    const tableTotal = {
      category: 'Total',
      total: 0,
      monthlyTotals: new Array(tableItems?.[0].monthlyTotals.length || 0).fill(
        0
      )
    }
    tableItems?.map((item) => {
      tableTotal.total += item.total
      item.monthlyTotals.map((month, index) => {
        tableTotal.monthlyTotals[index] += month
      })
    })
    return tableTotal
  }, [tableItems])
  const monthHeadings = useMemo(() => {
    const [startDateUtc, endDateUTC] = dateRangeDates || []

    if (!startDateUtc) {
      return
    }

    const startDateTz = new Date(
      startDateUtc.getUTCFullYear(),
      startDateUtc.getUTCMonth(),
      startDateUtc.getUTCDate()
    )

    const monthRange = endDateUTC
      ? differenceInCalendarMonths(endDateUTC, startDateUtc) + 1
      : 12

    return range(0, monthRange).map((x) => addMonths(startDateTz, x))
  }, [dateRangeDates])
  return {
    tableItems,
    tableTotals,
    monthHeadings
  }
}

const createTableItems = (
  items: IEnhancedRevenueSummaryItem[],
  startDate: Date,
  revOrPayout: RevenueOrPayout,
  monthRange: number
) => {
  const isRevenueSelected = revOrPayout === 'revenue'
  const periodFormat = 'yyyy-MM'
  const otherCategory = 'Uncategorized'
  const chartPeriods = range(0, monthRange)
    .map((x) => addMonths(startDate, x))
    .map((x) => format(x, periodFormat))
  const categoryGroups = groupBy(
    items,
    ({ category = otherCategory }) => category
  )

  const orderedCategoryGroups = orderBy(
    Object.entries(categoryGroups).map(
      ([categoryKey, categoryValues]): IRevenueTableItem => {
        const periodGroups = groupBy(categoryValues, ({ payrollPeriodDate }) =>
          format(new Date(payrollPeriodDate), periodFormat)
        )
        const totals = chartPeriods.map((period) => {
          const currentPeriod = periodGroups[period]
          return sumBy(
            currentPeriod,
            ({ compRevenue, payout }) =>
              (isRevenueSelected ? compRevenue : payout) || 0
          )
        })

        return {
          category: categoryKey || otherCategory,
          monthlyTotals: totals,
          total: sum(totals),
          parentCategory: categoryValues[0].parentCategory
        }
      }
    ),
    ({ total }) => total,
    'desc'
  )
  return orderedCategoryGroups
}
