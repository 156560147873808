import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { FC, useMemo, useState } from 'react'
import { useGetRealizedGainLossSummaryQuery } from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import {
  Dropdown,
  IndeterminateProgressIndicator,
  ISelectableOption
} from '../shared'
import { currentYear, yearRangeOptions } from '../shared/filterOptions'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps } from '../TileHeading'
import { getGainLossesIncomeClasses } from './GainLossesIncome.styles'
import GainLossesIncomeTable from './GainLossesIncomeTable'

const GainLossesIncome: FC<BaseHeaderProps> = ({ onExpand }) => {
  const classes = useMemo(() => getGainLossesIncomeClasses(), [])
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const [financialYear, setFinancialYear] = useState<any>(currentYear)
  const [selectedDateRange, setSelectedDateRange] = useState<any>(
    yearRangeOptions[0].key
  )

  const {
    data: gainLossesIncomeData,
    isLoading: isRealizedLoading,
    isFetching: isRealizedFetching,
    error: realizedError
  } = useGetRealizedGainLossSummaryQuery(
    !apiContextAccounts || apiContextAccounts.length === 0
      ? skipToken
      : { financialYear, accounts: apiContextAccounts }
  )

  const isError = useMemo(() => !!realizedError, [realizedError])
  const errorMessage =
    (realizedError as Error)?.message || 'An unknown Error Occurred'

  const onDateRangeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ISelectableOption<any>
  ) => {
    if (option) {
      setFinancialYear(option.key)
      setSelectedDateRange(option.key)
    }
  }

  return (
    <>
      <div css={classes.container}>
        <div css={{ paddingBottom: '5px' }}>
          <TileHeading onExpand={onExpand}>Gains / Losses</TileHeading>
        </div>
        <div>
          {financialYear === currentYear ? (
            ''
          ) : (
            <Dropdown
              options={yearRangeOptions}
              defaultSelectedOption={yearRangeOptions.find(
                (item) => item.key === selectedDateRange
              )}
              onChange={onDateRangeChange}
            />
          )}
        </div>
        {isError && (
          <div css={{ paddingBottom: '2px' }}>
            <SnackBar message={errorMessage} type="Failure" />
          </div>
        )}
        <>
          <GainLossesIncomeTable data={gainLossesIncomeData} />
        </>
        <div css={classes.note}>
          Rockefeller Accounts Only, as of previous close of business
        </div>
      </div>
      {(isRealizedLoading || isRealizedFetching) && (
        <div css={classes.progressContainer}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}

export default GainLossesIncome
