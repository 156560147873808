import { css } from '@emotion/react'

export const inputClasses = {
  input: css({
    border: '1px solid #AFB1B6',
    borderRadius: '8px',
    alignSelf: 'stretch',
    flexGrow: 1,
    width: '100%',
    padding: '10px'
  }),
  textArea: css({
    border: '1px solid #AFB1B6',
    borderRadius: '8px',
    alignSelf: 'stretch',
    width: '100%',
    padding: '10px'
  }),
  label: css({
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#06262D'
  })
}
