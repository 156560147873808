import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { ICategoryPostionResponseValue } from './ICategoryPostitionResponse'
import { ITop10HoldingResponseValue } from './ITop10HoldingResponse'

export interface IHoldingApiBaseValueResponse<T> {
  value: T[]
}

export interface IHoldingApiBaseRequest {
  accounts?: string[]
  contextId: string
}

export type PositionCategoryType = 'L1' | 'NFS' | 'P'

const { cacheTime } = apiConstants

type HoldingsApiTagType =
  | 'top10Holdings'
  | 'positionByCategory'
  | 'categoryPosition'
const holdingsApiTags: HoldingsApiTagType[] = [
  'top10Holdings',
  'positionByCategory',
  'categoryPosition'
]
const holdingsApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: holdingsApiTags
})

const getDefaultTags = (
  type: HoldingsApiTagType,
  req: IHoldingApiBaseRequest
): TagDescription<HoldingsApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const baseTransformResponse = <T>(response: IHoldingApiBaseValueResponse<T>) =>
  response?.value?.[0]

const baseTransformResponseArray = <T>(
  response: IHoldingApiBaseValueResponse<T>[]
) => response?.[0]?.value

const providesTags =
  (type: HoldingsApiTagType) =>
  (_: unknown, _1: unknown, req: IHoldingApiBaseRequest) =>
    getDefaultTags(type, req)

const holdingsApi = holdingsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTop10Holdings: builder.query<
      ITop10HoldingResponseValue | undefined,
      IHoldingApiBaseRequest
    >({
      query: ({ contextId }) => {
        return {
          url: "holdings/odata/Position/Top10Holding('',null)",
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse,
      providesTags: providesTags('top10Holdings')
    }),
    getPositionByCategory: builder.query<
      ICategoryPostionResponseValue[] | undefined,
      IHoldingApiBaseRequest & { category?: PositionCategoryType }
    >({
      query: ({ contextId, category }) => {
        return {
          url: `holdings/odata/Position/GetPositionByCategorySecurity('',null,${category})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponseArray,
      providesTags: providesTags('positionByCategory')
    }),
    getCategoryPosition: builder.query<
      ICategoryPostionResponseValue[] | undefined,
      IHoldingApiBaseRequest & { category: PositionCategoryType }
    >({
      query: ({ contextId, category }) => {
        return {
          url: `holdings/odata/Position/CategoryPosition('',null,${category})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponseArray,
      providesTags: providesTags('categoryPosition')
    })
  })
})

export const {
  useGetTop10HoldingsQuery,
  useGetPositionByCategoryQuery,
  useGetCategoryPositionQuery
} = holdingsApi

export const useHoldingsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<HoldingsApiTagType>[]) =>
      dispatch(holdingsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
