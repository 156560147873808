import { flow } from 'lodash/fp'
import { AppState } from '..'
import { getPreferenceUserPreferences } from '../user/selectors'
import { ThemeType } from './types'

export const getTheme = (state: AppState) => {
  return state.ui.theme
}

export const getThemeType = (state: AppState) => {
  return state.ui.themeType
}

// TODO - maybe this belongs in the user store?
export const getRepCodes = (state: AppState) => {
  return state.ui.repCodes
}

export const themePreferenceKey = 'theme'
export const getThemePreference = flow(
  getPreferenceUserPreferences,
  (x) => x?.[themePreferenceKey] as ThemeType
)
