import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IClient } from '../../api/client.types'
import { getMostRecentLoginDetails } from '../../shared/client'
import { navigationActions } from '../../store/ui/actions'
import { relatedPartyPanelActions } from '../RelatedPartyPanel/store'
import { LegalEntityNameCell } from './LegalEntityNameCell'
import { IClientMenuContainerProps } from '.'

export const LegalEntityNameCellContainer: React.FC<{
  client: IClient
  menu?: React.FC<IClientMenuContainerProps> | null
}> = ({ client, menu }) => {
  const dispatch = useDispatch()
  const onNameClicked = useCallback(
    (client: IClient) => {
      const { LegalEntityName, legalEntityType } = client
      const wealthscapeId = getMostRecentLoginDetails(
        client?.loginDetails
      )?.WealthscapeID

      if (!wealthscapeId) {
        return
      }
      if (legalEntityType === 'P' || legalEntityType === 'Individual') {
        dispatch(
          navigationActions.launchClientOnline({
            wealthscapeId,
            partyId: client.LegalEntityID
          })
        )
        return
      }

      dispatch(
        relatedPartyPanelActions.openPanel({
          id: wealthscapeId,
          idType: 'wealthscapeId',
          displayName: LegalEntityName
        })
      )
    },
    [dispatch]
  )

  return (
    <LegalEntityNameCell
      client={client}
      onNameClicked={onNameClicked}
      menu={menu}
    />
  )
}
