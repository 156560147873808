import {
  FontWeights,
  Icon,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from '@fluentui/react'
import React, { useState } from 'react'
import { IRelatedLogin } from '../../api/profile'
import { Separator } from '../../shared/components/Separator'
import { isNotNullOrEmpty } from '../../shared/gaurds'
import { RelatedPartyListItem } from './RelatedPartyListItem'
import { useRelatedPartyNavigation } from './store'

export interface IRelatedPartyComponent {
  relatedParties?: IRelatedLogin[]
  clientOnlineLaunchParameters?: Record<string, string>
}

export const RelatedPartyList: React.FC<IRelatedPartyComponent> = ({
  relatedParties = [],
  clientOnlineLaunchParameters
}) => {
  const [showMore, setShowMore] = useState(false)

  const validRelatedParties = relatedParties.filter(
    (x) =>
      [x.lastname, x.businesstrustname]
        .map((x) => x?.trim())
        .filter(isNotNullOrEmpty).length > 0
  )

  const otherRelatedParties = relatedParties.filter(
    (x) =>
      [x.lastname, x.businesstrustname]
        .map((x) => x?.trim())
        .filter(isNotNullOrEmpty).length === 0
  )

  const { launchClientOnline } = useRelatedPartyNavigation()

  if (validRelatedParties.length === 0 && otherRelatedParties.length === 0) {
    return (
      <MessageBar
        messageBarType={MessageBarType.info}
        isMultiline={true}
        dismissButtonAriaLabel="Close"
      >
        No Related Parties were found.
      </MessageBar>
    )
  }

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack.Item align="end">
        <Text>
          <b>Last Login Date</b>
        </Text>
      </Stack.Item>

      <Stack>
        {validRelatedParties.map((x, i) => (
          <RelatedPartyListItem key={i} relatedLogin={x} />
        ))}
      </Stack>
      {otherRelatedParties.length > 0 && (
        <Stack>
          <Stack
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
            onClick={() => setShowMore(!showMore)}
            styles={{
              root: {
                cursor: 'pointer',
                fontWeight: FontWeights.light,
                marginTop: '10px'
              }
            }}
          >
            <Text variant="small">More Related Ids</Text>
            {showMore ? (
              <Icon iconName="ChevronUp" title="Show Less" />
            ) : (
              <Icon iconName="ChevronDown" title="Show More" />
            )}
          </Stack>
          {showMore && <Separator />}
        </Stack>
      )}
      {showMore &&
        otherRelatedParties.map((x, i) => {
          const onLinkClicked = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            launchClientOnline({
              wealthscapeId: x.portaluserid,
              additionalParams: clientOnlineLaunchParameters
            })
          }
          const role = (x.role || '').trim()
          return (
            <Stack.Item key={i} style={{ padding: '5px' }}>
              <Stack>
                <Stack horizontal={true} horizontalAlign="space-between">
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 5, maxWidth: '250px' }}
                  >
                    <Link
                      href={`/clients/${x.portaluserid}`}
                      onClick={onLinkClicked}
                      title={x.portaluserid}
                    >
                      {x.portaluserid}
                    </Link>
                    <Text
                      variant="small"
                      styles={{ root: { fontWeight: 'bold' } }}
                    >
                      ({role})
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack.Item>
          )
        })}
    </Stack>
  )
}
