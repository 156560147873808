import { useMemo } from 'react'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'

interface BalanceSummaryData {
  label: string
  amount: number
  change?: number
  type?: string
  noStyle?: boolean
}

const useBalanceSummary = () => {
  const {
    selectedAccountBalanceSummary,
    isFetching,
    error,
    refetch,
    cashInSelectedBrokerageAccounts
  } = useRdot360BalancesContext()

  const balanceSummaryData = useMemo(() => {
    const data = selectedAccountBalanceSummary

    const mappedData: BalanceSummaryData[] = [
      {
        label: 'Cash',
        amount: data?.cash,
        change: data?.cashChange || 0,
        type: 'A'
      },
      {
        label: 'Priced Investments',
        amount: data?.pricedinvestments || 0,
        change: data?.pricedinvestmentschange || 0,
        type: 'A'
      },
      {
        label: 'Annuities and Insurance',
        amount: data?.annuity || 0,
        change: data?.annuitychange || 0,
        type: 'A'
      },
      {
        label: 'Other',
        amount: 0,
        change: 0,
        type: 'A'
      },
      {
        label: 'Accrued Income',
        amount: data?.fixedincomeaccruedinterest || 0,
        change: data?.fixedincomeaccruedinterestchange || 0,
        type: 'A'
      },
      {
        label: 'Loans',
        amount: data?.totalLiabilities || 0,
        change: data?.totalLiabilitiesChange || 0,
        type: 'A'
      },
      {
        label: 'Available to Withdraw',
        amount: data?.availableToWithdraw,
        change: undefined,
        type: 'B'
      },
      {
        label: 'Available to Invest',
        amount: data?.availableToInvest,
        change: undefined,
        type: 'B'
      },
      {
        label: 'Cash in Brokerage Account(s)',
        amount: cashInSelectedBrokerageAccounts,
        change: undefined,
        noStyle: true,
        type: 'B'
      }
    ]

    return mappedData
  }, [cashInSelectedBrokerageAccounts, selectedAccountBalanceSummary])

  const totalBalanceSummary = useMemo(
    () => ({
      label: 'Total',
      amount: selectedAccountBalanceSummary?.netWorth,
      change: selectedAccountBalanceSummary?.netWorthChange
    }),
    [selectedAccountBalanceSummary]
  )

  const filterData = (type: string) =>
    balanceSummaryData.filter((item) => item.type === type)

  return {
    isBalanceSummaryLoading: isFetching,
    isBalanceSummaryError: error,
    balanceSummaryRefetch: refetch,
    totalBalanceSummary,
    balanceSummaryData,
    balanceSummaryCategoryAData: filterData('A'),
    balanceSummaryCategoryBData: filterData('B')
  }
}

export default useBalanceSummary
