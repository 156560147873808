import { ITheme } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import { darkTheme, lightTheme } from '../../shared/services/theme'
import { uiActions, UIActionTypes } from './actions'
import { ThemeType } from './types'

interface IUIState {
  themeType: ThemeType
  theme: ITheme
  repCodes: string[]
}

const initialState: IUIState = {
  themeType: 'light',
  theme: lightTheme,
  repCodes: []
}

export const uiReducer = createReducer<IUIState, UIActionTypes>(
  initialState
).handleAction(uiActions.updateTheme, (state, action) => {
  const theme = action.payload === 'light' ? lightTheme : darkTheme
  return { ...state, theme, themeType: action.payload }
})
