import { parseISO } from 'date-fns'
import { keyBy, trim } from 'lodash'
import { flow } from 'lodash/fp'
import { parse } from 'query-string'
import { OrderByDirection } from '../../../api/odata.types'
import { notNullOrEmpty } from '../../../shared'
import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { IColumnState } from '../core/contracts/IColumnState'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter,
  IListsFilterProps,
  IListsFilterPropTypes,
  IListsNumberRangeFilter,
  IListsSearchFilter,
  NumberFilterTypes
} from '../core/contracts/IListsFilter'
import {
  IListsFilterDefinition,
  ListsFilterType
} from '../core/contracts/IListsFilterDefinition'
import { IListsOrderBy } from '../core/contracts/IListsOrderBy'
import { IListsUiState } from '../core/contracts/IListsUIState'

export const constructListsUiUrlSearch = (state: IListsUiState, key = '') => {
  const { columnState, orderBy, searchText, filters } = state

  const query: Record<string, string | undefined> = {}
  const getParamNameWithKey = (param: string) =>
    key ? `${param}:${key}` : param

  if (columnState && columnState.length) {
    const select = columnState
      .filter((x) => x.selected)
      .map((x) =>
        [
          x.columnId,
          x.sticky && 'sticky',
          x.includeInSearch && 'search',
          x.width
        ]
          .filter(Boolean)
          .join('+')
      )

    query[getParamNameWithKey('select')] = select.join(',')
  } else {
    query[getParamNameWithKey('select')] = undefined
  }

  query[getParamNameWithKey('$orderby')] = orderBy?.columnId
    ? `${orderBy.columnId}+${orderBy.direction}` // `
    : undefined

  query[getParamNameWithKey('search')] = searchText
    ? encodeURIComponent(searchText)
    : undefined

  if (filters) {
    query[getParamNameWithKey('$filter')] = Object.entries(filters)
      .map(([, value]) => {
        const { type, blankIndicator, hasValue, id } = value
        if (!hasValue) {
          return null
        }

        if (blankIndicator) {
          return [id, 'blank', blankIndicator].join('+')
        }

        let searchValue: string | null = null
        switch (type) {
          case 'date':
            searchValue = constructDateFilterForSearch(
              value as IListsDateRangeFilter
            )
            break
          case 'facet':
            searchValue = constructFacetFilterForSearch(
              value as IListsFacetFilter
            )
            break
          case 'number':
            searchValue = constructNumberFilterForSearch(
              value as IListsNumberRangeFilter
            )
            break
          case 'search':
            searchValue = constructSearchFilterForSearch(
              value as IListsSearchFilter
            )
            break
        }

        return searchValue
      })
      .filter(Boolean)
      .join(',')
  } else {
    query[getParamNameWithKey('$filter')] = undefined
  }

  return query
}

const constructDateFilterForSearch = (filter: IListsDateRangeFilter) => {
  const { id, range, from, to } = filter
  return [
    id,
    range,
    from != null || to != null
      ? [from ? from.toISOString() : '', to ? to.toISOString() : ''].join('..')
      : null
  ]
    .filter(Boolean)
    .join('+')
}

const constructNumberFilterForSearch = (filter: IListsNumberRangeFilter) => {
  const { id, filterType = 'range', min, max, value } = filter

  return [
    id,
    filterType,
    filterType === 'range' &&
      (min != null || max != null ? [min ?? '', max ?? ''].join('..') : null),
    filterType !== 'range' && value
  ]
    .filter((x) => x !== false && x != null)
    .join('+')
}

const constructSearchFilterForSearch = (filter: IListsSearchFilter) => {
  const { id, value } = filter
  return [id, escapeFilterQuery(value)].join('+')
}

const constructFacetFilterForSearch = (filter: IListsFacetFilter) => {
  const { id, values } = filter
  return [id, ...(values?.map((x) => encodeURIComponent(x)) || [])]
    .filter(notNullOrEmpty)
    .join('+')
}

const escapeFilterQuery = flow((x) => x || '', trim, encodeURIComponent)

export const parseListsUiStateFromUrlSearch = (
  querystring: string,
  filterDefinitions: Record<string, IListsFilterDefinition> = {},
  key = ''
) => {
  const getParamNameWithKey = (param: string) =>
    key ? `${param}:${key}` : param
  const parsedQuery = parse(querystring, { decode: false })
  const $select = parsedQuery[getParamNameWithKey('select')] as string
  const $orderby = parsedQuery[getParamNameWithKey('$orderby')] as string
  const $filter = parsedQuery[getParamNameWithKey('$filter')] as string
  const search = parsedQuery[getParamNameWithKey('search')] as string
  const orderbyParts = ($orderby || '').split('+').map(trim).filter(Boolean)

  const columnState: IColumnState[] = ($select || '')
    .split(',')
    .map(trim)
    .filter(Boolean)
    .map((x) => {
      const parts = x.split('+')
      const includeInSearch = parts.indexOf('search') >= 0
      const sticky = parts.indexOf('sticky') >= 0
      const width = parseInt(parts[parts.length - 1], 10)

      return {
        columnId: parts[0],
        includeInSearch,
        sticky,
        selected: true,
        width: isNaN(width) ? undefined : width
      }
    })
  const searchText = search || undefined
  const orderBy: IListsOrderBy | boolean = !!orderbyParts.length && {
    columnId: orderbyParts[0],
    direction: orderbyParts[1] as OrderByDirection
  }

  const filters: IListsFilter[] = ($filter || '')
    .split(',')
    .map(trim)
    .filter(Boolean)
    .map((x): IListsFilter | null => {
      const parts = x.split('+')
      const [id, ...args] = parts

      const filterDefinition = filterDefinitions[id]
      if (!filterDefinition) {
        return null
      }

      if (args[0] === 'blank' && args[1]) {
        return {
          ...filterDefinition,
          blankIndicator: args[1] as any,
          hasValue: true
        }
      }

      const { type } = filterDefinition
      let filterProps: IListsFilterPropTypes | null = null
      switch (type) {
        case 'number': {
          const [filterType, numberFilterValue] = args as [
            keyof typeof NumberFilterTypes,
            string
          ]

          if (filterType === 'range') {
            const [min, max] = (numberFilterValue || '')
              .split('..')
              .map(trim)
              .map(parseFloat)
              .map((n) => (isNaN(n) ? undefined : n))

            filterProps = {
              type: 'number',
              filterType,
              max,
              min
            }
          } else {
            const parsedNumberFilterValue = parseFloat(numberFilterValue)
            filterProps = {
              type: 'number',
              filterType,
              value: !isNaN(parsedNumberFilterValue)
                ? parsedNumberFilterValue
                : undefined
            }
          }
          break
        }
        case 'date': {
          const [range, fromto] = args
          const [from, to] = (fromto || '')
            .split('..')
            .map(trim)
            .filter(Boolean)
            .map((d) => parseISO(d))
            .filter((d) => !isNaN(d.getTime()) && d != null)

          filterProps = {
            type: 'date',
            range: decodeURIComponent(range || '') as any,
            from,
            to
          }
          break
        }
        case 'search': {
          const [value] = args
          filterProps = {
            type: 'search',
            value: decodeURIComponent(value || '')
          }
          break
        }
        case 'facet': {
          filterProps = {
            type: 'facet',
            values: (args || []).map(decodeURIComponent)
          }
          break
        }
      }

      return {
        ...filterDefinition,
        ...filterProps,
        hasValue: true
      }
    })
    .filter(Boolean) as IListsFilter[]

  const state: IListsUiState = {}
  if (orderBy) {
    state.orderBy = orderBy
  }
  if (searchText) {
    state.searchText = decodeURIComponent(searchText)
  }
  if (columnState.length) {
    state.columnState = columnState
  }
  if (filters.length) {
    state.filters = keyBy(filters, (x) => x.id)
  }

  return state
}

export const createFilter = (
  columnDefinitions: Record<string, IColumnDefinition>,
  columnId: string,
  filterProps: IListsFilterPropTypes & IListsFilterProps
): IListsFilter => ({
  ...columnDefinitions[columnId],
  hasValue: true,
  ...filterProps
})

const deriveFilterType = (
  columnDefinition: IColumnDefinition
): ListsFilterType => {
  const { type, facetable, searchable } = columnDefinition

  switch (type) {
    case 'date':
      return 'date'
    case 'date-only':
      return 'date-only'
    case 'number':
      return 'number'
    case 'string':
    case 'custom':
      if (facetable && searchable) {
        return 'facet-search'
      }
      if (facetable) {
        return 'facet'
      }

      return 'search'
  }
}

export const mapColumnsToFilterDefinitions = (
  columnDefinitions: Record<string, IColumnDefinition>
): Record<string, IListsFilterDefinition> => {
  const filterDefinitions: IListsFilterDefinition[] = Object.entries(
    columnDefinitions
  )
    .filter(([, value]) => value.filterable)
    .map(([id, value]) => ({
      id,
      name: value.name,
      type: deriveFilterType(value),
      collectionPath: value.collectionPath,
      dataPath: value.dataPath,
      featureFlag: value.featureFlag,
      mask: value.mask
    }))

  const additionalFilterDefinitions = Object.entries(columnDefinitions)
    .filter(([, value]) => value.filters?.length)
    .flatMap(([, value]) => value.filters) as IListsFilterDefinition[]

  return keyBy(
    [...filterDefinitions, ...additionalFilterDefinitions],
    (x) => x.id
  )
}

export const createDefaultColumnState = (
  columnDefinitions: Record<string, IColumnDefinition>,
  defaultColumns: string[],
  numStickyColumns = 0
) => {
  const columnPositionMap: { [key: string]: number } = defaultColumns.reduce(
    (a, x, i) => ({ ...a, [x]: i }),
    {}
  )

  const entries = Object.entries(columnDefinitions)

  const columnState: IColumnState[] = entries
    .map(([id, value]) => ({
      columnId: id,
      includeInSearch: value.searchable || false,
      selected: columnPositionMap[id] != null,
      sticky: false,
      width: value.width || 150
    }))
    .sort(
      (a, b) =>
        (columnPositionMap[a.columnId] ?? entries.length) -
        (columnPositionMap[b.columnId] ?? entries.length)
    )

  // i know.. a for loop
  for (let i = 0; i < numStickyColumns; i++) {
    columnState[i].sticky = true
  }

  return columnState
}
