import { get } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { SortIndicator } from '../../shared/SortIndicator'
export interface IColumn {
  field: string
  headerName?: string
  sortable?: boolean
  className?: string
  width?: string
  align?: 'left' | 'right' | 'center'
  headerStyle?: object
  columnStyle?: object
  style?: object
  fixedColumn?: boolean
  format?: (value: any, row?: any) => ReactNode | string
  formatHeader?: () => ReactNode | string
}

export interface IDataTableProps {
  columns: IColumn[]
  data: any[]
}

const GainLossCustomTableTop: FC<IDataTableProps> = ({
  columns = [],
  data = []
}) => {
  const [tableData, setTableData] = useState<any[]>(data)
  const [sortValue, setSortValue] = useState<string | boolean>('desc')
  useMemo(() => {
    setTableData(data)
  }, [data])
  const [sortingFields, setSortingFields] = useState<{
    [key: string]: string | boolean
  }>({})
  const [sortingColumnHeader, setSortingColumnHeader] = useState<string>('')

  const onSorting = useCallback(
    (field: string) => {
      const sortType = sortingFields[field]
      setSortValue(sortType)
      setSortingColumnHeader(field)
      const sortedData = [...tableData].sort((a, b) => {
        if (sortType === 'asc') {
          return get(a, field) - get(b, field)
        } else {
          return get(b, field) - get(a, field)
        }
      })
      if (sortType === 'asc') {
        setSortingFields({
          ...sortingFields,
          [field]: false
        })
      } else if (sortType === 'desc') {
        setSortingFields({
          ...sortingFields,
          [field]: 'asc'
        })
      } else if (!sortType) {
        setSortingFields({
          ...sortingFields,
          [field]: 'desc'
        })
      }

      setTableData(sortedData)
    },
    [sortingFields, tableData]
  )
  return (
    <div
      css={[
        advisoryModuleStyles.fancyScroll,
        { height: '100%', fontSize: '12px' }
      ]}
    >
      <table
        css={{
          width: '100%',
          tableLayout: 'fixed',
          borderCollapse: 'collapse',
          padding: '0px 10px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: '#f0f0f0'
        }}
      >
        <thead css={{ position: 'sticky', top: 0 }}>
          <tr>
            {columns.map(
              ({
                field,
                headerName,
                className,
                sortable,
                headerStyle,
                columnStyle,
                fixedColumn,
                align = 'left',
                formatHeader
              }) => (
                <th
                  key={field}
                  className={className}
                  css={{
                    ...headerStyle,
                    ...columnStyle,
                    cursor: sortable ? 'pointer' : 'default',
                    backgroundColor: '#eff3f6',
                    padding: '12px 5px',
                    fontSize: 12,
                    position: fixedColumn ? 'sticky' : 'static',
                    left: fixedColumn ? 0 : 'unset',
                    zIndex: fixedColumn ? 1 : 0
                  }}
                  align={align}
                  onClick={() => {
                    if (sortable) {
                      onSorting(field)
                    }
                  }}
                >
                  {formatHeader ? (
                    formatHeader()
                  ) : (
                    <div
                      css={{
                        display: 'flex',
                        justifyContent:
                          align === 'center'
                            ? 'center'
                            : align === 'right'
                            ? 'end'
                            : 'start'
                      }}
                    >
                      {headerName || field}
                      {sortable && (
                        <SortIndicator
                          direction={
                            field === sortingColumnHeader && (sortValue as any)
                          }
                        />
                      )}
                    </div>
                  )}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody
          css={{
            borderBottom: '2px solid #000000',
            borderBottomColor: data.length === 0 ? '#f0f0f0' : '#000000'
          }}
        >
          {tableData?.map((row, index) => (
            <tr
              key={index}
              css={{
                borderBottom: '1px solid #f0f0f0',
                paddingRight: '15px',
                paddingLeft: '15px'
              }}
            >
              {columns.map(
                ({
                  field,
                  className,
                  width,
                  style,
                  columnStyle,
                  fixedColumn,
                  align = 'left',
                  format = (value) => value?.toString() || ''
                }) => (
                  <td
                    key={field}
                    className={className}
                    style={{
                      ...style,
                      backgroundColor:
                        row[columns[0].field] === 'Total' ||
                        row[columns[0].field] ===
                          'Net Realized (current year)' ||
                        row[columns[0].field] === 'Net Unrealized (cumulative)'
                          ? '#eff3f6'
                          : 'transparent'
                    }}
                    width={width}
                    align={align}
                    css={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '12px',
                      padding: '11px 5px',
                      position: fixedColumn ? 'sticky' : 'static',
                      left: fixedColumn ? 0 : 'unset',
                      zIndex: fixedColumn ? 9 : 0,
                      fontWeight:
                        row[columns[0].field] ===
                          'Net Realized (current year)' ||
                        row[columns[0].field] === 'Net Unrealized (cumulative)'
                          ? 'bold'
                          : '',
                      background: fixedColumn ? '#ffffff' : 'unset',
                      ...columnStyle
                    }}
                    title={
                      typeof get(row, field) === 'number'
                        ? get(row, field).toFixed(2)
                        : get(row, field)
                    }
                  >
                    {format(get(row, field), row)}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
        {data.length === 0 && (
          <tfoot css={{ height: '100%' }}>
            <tr css={{ height: '100%' }}>
              <td
                css={{
                  height: '100%',
                  textAlign: 'center',
                  background: ' #f0f0f0',
                  border: '1px solid #f0f0f0'
                }}
                colSpan={columns.length}
              >
                No data available
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  )
}

export default GainLossCustomTableTop
