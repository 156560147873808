import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { intersectionWith, keyBy, uniq, uniqBy, xor } from 'lodash'
import { flow } from 'lodash/fp'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useGetCurrentSystemuserQuery } from 'store/api/dynamics'
import { getDynamicsUserBusinessunitId } from 'store/user/dynamicsUser'
import { IAdvisorRep } from '../../../api/dynamics'
import { isNotNullOrEmpty, isNotNullOrUndefined } from '../../../shared/gaurds'
import { AppState } from '../../../store'
import {
  getIsHomeOfficeUser,
  getIsInRoleAdvisoryOlt,
  getIsLoggedInUserJuniorAdvisor,
  getRdotUsername
} from '../../../store/user/selectors'
import { selectDomainRepsQueryResult, useGetDomainRepsQuery } from './domainApi'

export interface IPoolSplitRep {
  id: string
  name: string
  percentage: number
}

export interface IWithSplitVisibility {
  splits?: IPoolSplitRep[]
  personalRepsInSplit?: IPoolSplitRep[]
  userHasAccessToSplits?: boolean
  userIsMemberOfRep?: boolean
  userIsMemberOfRepTeam?: boolean
  userOwnsRep?: boolean
  isPersonalRep?: boolean
}

export interface ISetSelectedDomainPayload {
  advisorReps?: string[]
  accountReps?: string[]
}

export type IDomainState = ISetSelectedDomainPayload

const initialState: IDomainState = {
  advisorReps: [],
  accountReps: []
}

export const { actions: domainActions, reducer: selectedDomainReducer } =
  createSlice({
    name: '@features/@domain/@selectedDomain',
    initialState,
    reducers: {
      setSelectedDomain: (
        state,
        action: PayloadAction<ISetSelectedDomainPayload>
      ) => ({
        ...state,
        ...action.payload
      })
    }
  })

const rootBusinessunitIds = [
  '3eda00e9-ee86-e911-a81b-000d3a3b54ca',
  '54fb21ef-ee86-e911-a81b-000d3a3b54ca'
]

const rootSelector = (state: AppState) => state.features.domain.selectedDomain

export const selectSelectedAdvisorRepIds = flow(
  rootSelector,
  ({ advisorReps }) => advisorReps
)

export const selectSelectedAccountRepIds = flow(
  rootSelector,
  ({ accountReps }) => accountReps
)

const selectSelectedAdvisorRepIdsLookup = createSelector(
  selectSelectedAdvisorRepIds,
  (ids) => keyBy(ids)
)

const selectDomainReps = createSelector(
  selectDomainRepsQueryResult,
  ({ data }) => data
)

const emptyArray: [] = []
/**
 * @deprecated use useDomainStore
 */
export const selectValidDomainReps = createSelector(
  selectDomainReps,
  (reps) => {
    return (
      reps?.filter(({ rcm_OwningTeam }) =>
        [
          rcm_OwningTeam?.businessunitid?.businessunitid,
          rcm_OwningTeam?.businessunitid?.parentbusinessunitid?.businessunitid,
          rcm_OwningTeam?.businessunitid?.parentbusinessunitid
            ?.parentbusinessunitid?.businessunitid,
          rcm_OwningTeam?.businessunitid?.parentbusinessunitid
            ?.parentbusinessunitid?.parentbusinessunitid?.businessunitid
        ].some((x) => x && rootBusinessunitIds.includes(x))
      ) || emptyArray
    )
  }
)

const filterDomain = (domain?: IAdvisorRep[], reps?: string[]) =>
  domain?.length && reps?.length
    ? intersectionWith(domain, reps, (a, b) => a.rcm_repid === b)
    : emptyArray

const selectSelectedAccountReps = createSelector(
  selectDomainReps,
  selectSelectedAccountRepIds,
  filterDomain
)

const selectTeams = createSelector(selectValidDomainReps, (domain) => {
  return uniqBy(
    domain
      ?.map(({ rcm_OwningTeam }) => rcm_OwningTeam)
      .filter(isNotNullOrUndefined),
    ({ teamid }) => teamid
  )
})

const selectSelectedTeams = createSelector(
  [selectSelectedAccountReps],
  (accountReps = emptyArray) => {
    return uniq(
      accountReps
        .map(({ rcm_OwningTeam }) => rcm_OwningTeam?.teamid)
        .filter(isNotNullOrEmpty)
    )
  }
)

const selectDomainAdvisorReps = createSelector(
  selectValidDomainReps,
  (domain) =>
    domain?.length
      ? domain?.filter(({ rcm_PersonalRepFor }) => rcm_PersonalRepFor)
      : emptyArray
)

const selectUser = createSelector(
  getIsLoggedInUserJuniorAdvisor,
  getIsHomeOfficeUser,
  getIsInRoleAdvisoryOlt,
  getRdotUsername,
  (isJuniorAdvisor, isHomeOffice, isOlt, username) =>
    username
      ? {
          isJuniorAdvisor,
          isHomeOffice,
          isOlt,
          username
        }
      : undefined
)

/**
 * @deprecated use useDomainStore
 */
export const selectAdvisorRepsForUser = createSelector(
  selectValidDomainReps,
  selectUser,
  (domain, user) =>
    domain?.length && user?.username
      ? domain?.filter(
          ({ rcm_PersonalRepFor }) =>
            rcm_PersonalRepFor?.domainname?.toLowerCase() ===
            user?.username?.toLowerCase()
        )
      : emptyArray
)

/**
 * @deprecated use useDomainStore
 */
export const selectAdvisorRepsForTeam = createSelector(
  selectValidDomainReps,
  getDynamicsUserBusinessunitId,
  (domain, businessunitid) =>
    businessunitid
      ? domain?.filter(
          ({ rcm_PersonalRepFor, rcm_OwningTeam }) =>
            rcm_PersonalRepFor &&
            rcm_OwningTeam?.businessunitid?.businessunitid === businessunitid
        )
      : emptyArray
)

/**
 * @deprecated use useDomainStore
 */
export const selectAccountRepsWithSplitVisibility = createSelector(
  selectValidDomainReps,
  selectAdvisorRepsForUser,
  selectUser,
  getDynamicsUserBusinessunitId,
  (
    domain = emptyArray,
    userAdvisorReps = emptyArray,
    user,
    userBusinessunitid
  ): ({ rep: IAdvisorRep } & IWithSplitVisibility)[] =>
    user && userBusinessunitid
      ? domain.map((rep) => {
          const {
            rcm_repid = '',
            rcm_name = '',
            rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi = [],
            rcm_OwningTeam,
            rcm_PersonalRepFor
          } = rep
          const splits: IPoolSplitRep[] = [
            ...rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi.map(
              ({
                rcm_calc_personalrepid = '',
                rcm_calc_personalrepname = '',
                rcm_percentage
              }) => ({
                id: rcm_calc_personalrepid,
                name: rcm_calc_personalrepname,
                percentage: rcm_percentage
              })
            ),
            !rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi.length
              ? {
                  id: rcm_repid,
                  name: rcm_name,
                  percentage: 100
                }
              : undefined
          ].filter(
            (x: IPoolSplitRep | undefined): x is IPoolSplitRep =>
              !!x && x.id !== ''
          )

          const isPersonalRep = !!rcm_PersonalRepFor

          const personalRepsInSplit = intersectionWith(
            splits,
            userAdvisorReps,
            (a, b) => a.id === b.rcm_repid
          )

          const userIsMemberOfRep = !!personalRepsInSplit.length

          const userOwnsRep =
            userAdvisorReps.filter((rep) => rcm_repid === rep.rcm_repid)
              .length > 0

          const { isJuniorAdvisor, isOlt, isHomeOffice } = user
          const userHasAccessToSplits =
            userOwnsRep ||
            (!isJuniorAdvisor && (isOlt || isHomeOffice || userIsMemberOfRep))

          const userIsMemberOfRepTeam =
            !isOlt &&
            !isHomeOffice &&
            !!userBusinessunitid &&
            userBusinessunitid ===
              rcm_OwningTeam?.businessunitid?.businessunitid

          return {
            rep,
            splits,
            personalRepsInSplit,
            userHasAccessToSplits,
            userIsMemberOfRep,
            userIsMemberOfRepTeam,
            userOwnsRep,
            isPersonalRep
          }
        })
      : emptyArray
)

const selectIsFullDomainSelected = createSelector(
  selectValidDomainReps,
  selectSelectedAccountRepIds,
  (reps, selected) => reps.length === selected?.length
)

const selectAccountRepsWithSplitVisibilityLookup = createSelector(
  selectAccountRepsWithSplitVisibility,
  (reps) => keyBy(reps, ({ rep }) => rep.rcm_repid || '')
)

const filterAccountRepSplits = (
  reps: ({ rep: IAdvisorRep } & IWithSplitVisibility)[],
  selected: string[] = emptyArray
) => intersectionWith(reps, selected, (a, b) => a.rep.rcm_repid === b)
const selectSelectedAccountRepSplits = createSelector(
  selectAccountRepsWithSplitVisibility,
  selectSelectedAccountRepIds,
  filterAccountRepSplits
)
const selectSelectedAdvisorRepSplits = createSelector(
  selectAccountRepsWithSplitVisibility,
  selectSelectedAdvisorRepIds,
  filterAccountRepSplits
)

const selectUserHasAccessToAllSelectedAccountRepSplits = createSelector(
  selectSelectedAccountRepSplits,
  (reps) =>
    !!reps.length &&
    reps.every(({ userHasAccessToSplits }) => userHasAccessToSplits)
)

const selectUserHasAccessToAllSelectedAdvisorReps = createSelector(
  selectSelectedAdvisorRepSplits,
  (reps) =>
    !!reps.length &&
    reps.every(({ userHasAccessToSplits }) => userHasAccessToSplits)
)

/**
 * @deprecated use useDomainStore
 */
export const selectUserHasAccessToPayout = createSelector(
  selectUserHasAccessToAllSelectedAccountRepSplits,
  selectUserHasAccessToAllSelectedAdvisorReps,
  (
    userHasAccessToAllSelectedAccountRepSplits,
    userHasAccessToAllAdvisorReps
  ) => {
    if (
      userHasAccessToAllSelectedAccountRepSplits ||
      userHasAccessToAllAdvisorReps
    ) {
      return true
    }

    return false
  }
)

const useDomainContext = () => {
  const { isFetching: isDomainRepsFetching, error } = useGetDomainRepsQuery()
  const { isFetching: isSystemuserFetching } = useGetCurrentSystemuserQuery()
  const isFetching = isDomainRepsFetching || isSystemuserFetching

  const selectedAdvisorRepIds = useSelector(selectSelectedAdvisorRepIds)
  const selectedAccountRepIds = useSelector(selectSelectedAccountRepIds)
  const selectedAdvisorRepIdsLookup = useSelector(
    selectSelectedAdvisorRepIdsLookup
  )
  const domain = useSelector(selectValidDomainReps)
  const domainAdvisorReps = useSelector(selectDomainAdvisorReps)
  const teams = useSelector(selectTeams)
  const selectedAccountReps = useSelector(selectSelectedAccountReps)
  const selectedTeams = useSelector(selectSelectedTeams)
  const userAdvisorReps = useSelector(selectAdvisorRepsForUser)
  const teamAdvisorReps = useSelector(selectAdvisorRepsForTeam)
  const accountRepsWithSplitVisibility = useSelector(
    selectAccountRepsWithSplitVisibility
  )
  const accountRepsWithSplitVisibilityLookup = useSelector(
    selectAccountRepsWithSplitVisibilityLookup
  )
  const isFullDomainSelected = useSelector(selectIsFullDomainSelected)
  const userHasAccessToAllSelectedAccountRepSplits = useSelector(
    selectUserHasAccessToAllSelectedAccountRepSplits
  )
  const userHasAccessToAllSelectedAdvisorReps = useSelector(
    selectUserHasAccessToAllSelectedAdvisorReps
  )
  const userHasAccessToPayouts = useSelector(selectUserHasAccessToPayout)

  return {
    domain,
    domainAdvisorReps,
    selectedAdvisorRepIds,
    selectedAccountRepIds,
    selectedAdvisorRepIdsLookup,
    selectedAccountReps,
    teams,
    selectedTeams,
    userAdvisorReps,
    teamAdvisorReps,
    accountRepsWithSplitVisibility,
    accountRepsWithSplitVisibilityLookup,
    isFullDomainSelected,
    userHasAccessToAllSelectedAccountRepSplits,
    userHasAccessToAllSelectedAdvisorReps,
    userHasAccessToPayouts,
    isFetching,
    error: error as Error | undefined,
    rootBusinessunitIds
  }
}

const useDomainActions = () => {
  const dispatch = useDispatch()
  const {
    domain,
    selectedAccountRepIds,
    selectedAdvisorRepIds,
    accountRepsWithSplitVisibility,
    teamAdvisorReps,
    userAdvisorReps
  } = useDomainContext()

  const setSelectedDomain = useCallback(
    (selection: ISetSelectedDomainPayload) => {
      const request: ISetSelectedDomainPayload = {
        accountReps: selectedAccountRepIds,
        advisorReps: selectedAdvisorRepIds,
        ...selection
      }

      if (
        (selection.accountReps === undefined ||
          xor(selectedAccountRepIds, selection.accountReps).length === 0) &&
        (selection.advisorReps === undefined ||
          xor(selectedAdvisorRepIds, selection.advisorReps).length === 0)
      ) {
        return
      }

      const userHasAccessToRequestedAccountReps = intersectionWith(
        accountRepsWithSplitVisibility,
        request.accountReps || [],
        (a, b) => a.rep.rcm_repid === b
      ).every(({ userHasAccessToSplits }) => userHasAccessToSplits)

      const userHasAccessToSelectedAdvisorReps = intersectionWith(
        accountRepsWithSplitVisibility,
        request.advisorReps || [],
        (a, b) => a.rep.rcm_repid === b
      ).every(({ userHasAccessToSplits }) => userHasAccessToSplits)

      const allTeamAdvisorRepsAreSelected =
        xor(
          teamAdvisorReps?.map((x) => x.rcm_repid),
          request.advisorReps
        ).length === 0

      const allAdvisorReps = intersectionWith(
        accountRepsWithSplitVisibility,
        request.accountReps || [],
        (a, b) => a.rep.rcm_repid === b
      )
        .flatMap(({ splits }) => splits || [])
        .map(({ id }) => id)
        .filter(isNotNullOrEmpty)

      const allAdvisorRepsAreSelected =
        xor(allAdvisorReps, request.advisorReps).length === 0

      if (
        !userHasAccessToSelectedAdvisorReps &&
        !userHasAccessToRequestedAccountReps &&
        !allTeamAdvisorRepsAreSelected &&
        !allAdvisorRepsAreSelected
      ) {
        request.advisorReps = []
      }

      dispatch(domainActions.setSelectedDomain(request))
    },
    [
      accountRepsWithSplitVisibility,
      dispatch,
      selectedAccountRepIds,
      selectedAdvisorRepIds,
      teamAdvisorReps
    ]
  )

  const selectAllAccountReps = useCallback(() => {
    const accountReps = domain.map(({ rcm_repid }) => rcm_repid)
    setSelectedDomain({ accountReps, advisorReps: emptyArray })
  }, [domain, setSelectedDomain])

  const selectRepsForUser = useCallback(
    (username?: string) => {
      const personalReps = username
        ? accountRepsWithSplitVisibility
            ?.filter(
              (x) =>
                x.userHasAccessToSplits &&
                x.rep?.rcm_PersonalRepFor?.domainname &&
                x.rep.rcm_PersonalRepFor.domainname.toLowerCase() ===
                  username?.toLowerCase()
            )
            .map((x) => x.rep)
        : userAdvisorReps

      if (!personalReps?.length) {
        return
      }

      const advisorReps = personalReps.map(({ rcm_repid }) => rcm_repid)

      const accountReps = accountRepsWithSplitVisibility
        .filter(
          ({ splits }) =>
            intersectionWith(
              splits,
              personalReps,
              (a, b) => a.id === b.rcm_repid
            )?.length > 0
        )
        .map(({ rep }) => rep.rcm_repid)

      setSelectedDomain({
        accountReps,
        advisorReps
      })
    },
    [accountRepsWithSplitVisibility, setSelectedDomain, userAdvisorReps]
  )

  const selectRepsForTeam = useCallback(
    (businessunitid?: string) => {
      const accountRepsWithSplits = businessunitid
        ? accountRepsWithSplitVisibility.filter(
            (x) =>
              x.rep.rcm_OwningTeam?.businessunitid?.businessunitid ===
              businessunitid
          )
        : accountRepsWithSplitVisibility.filter(
            ({ userIsMemberOfRepTeam }) => userIsMemberOfRepTeam
          )

      const teamAdvisorReps = accountRepsWithSplits.filter(
        ({ isPersonalRep }) => isPersonalRep
      )

      const advisorReps = teamAdvisorReps
        .map(({ rep }) => rep.rcm_repid)
        .filter(isNotNullOrEmpty)

      const splitVisibleAccountReps = accountRepsWithSplitVisibility
        .filter(
          (x) =>
            intersectionWith(x.splits, advisorReps, (a, b) => a.id === b)
              .length > 0
        )
        .map((x) => x.rep.rcm_repid)

      const accountReps = uniq([
        ...splitVisibleAccountReps,
        ...accountRepsWithSplits
          .map(({ rep }) => rep.rcm_repid)
          .filter(isNotNullOrEmpty)
      ])

      setSelectedDomain({
        accountReps,
        advisorReps
      })
    },
    [accountRepsWithSplitVisibility, setSelectedDomain]
  )

  return {
    setSelectedDomain,
    selectAllAccountReps,
    selectRepsForUser,
    selectRepsForTeam
  }
}

export const useDomainStore = () => {
  const context = useDomainContext()
  const actions = useDomainActions()

  const {
    domain,
    isFetching,
    selectedAccountRepIds,
    userAdvisorReps,
    teamAdvisorReps
  } = context

  const { selectAllAccountReps, selectRepsForTeam, selectRepsForUser } = actions
  useEffect(() => {
    if (!domain.length || isFetching || selectedAccountRepIds?.length) {
      return
    }

    if (userAdvisorReps?.length) {
      selectRepsForUser()
      return
    }

    if (teamAdvisorReps?.length) {
      selectRepsForTeam()
      return
    }

    selectAllAccountReps()
  }, [
    domain,
    isFetching,
    selectAllAccountReps,
    selectRepsForTeam,
    selectRepsForUser,
    selectedAccountRepIds,
    teamAdvisorReps,
    userAdvisorReps
  ])

  return {
    ...context,
    ...actions
  }
}
