import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import {
  createProfileApiContextStore,
  createProfileApiContextSlice
} from '../profileApi'

const {
  slice: selectedAccountsApiContextSlice,
  apiKey: selectedAccountsApiContextKey
} = createProfileApiContextSlice('selected')
const { slice: householdAccountsApiContextSlice } =
  createProfileApiContextSlice('household')

export { selectedAccountsApiContextKey }

export const apiContextReducer = combineReducers({
  selected: selectedAccountsApiContextSlice.reducer,
  household: householdAccountsApiContextSlice.reducer
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.apiContext

const selectedSelector = createSelector(
  rootSelector,
  ({ selected }) => selected
)
const {
  useProfileApiContext: useRdot360SelectedAccountsApiContext,
  saga_watchContext: saga_watchSelectedContext
} = createProfileApiContextStore(
  selectedSelector,
  selectedAccountsApiContextSlice.actions
)

const householdSelector = createSelector(
  rootSelector,
  ({ household }) => household
)
const {
  useProfileApiContext: useRdot360HouseholdAccountsApiContext,
  saga_watchContext: saga_watchHouseholdContext
} = createProfileApiContextStore(
  householdSelector,
  householdAccountsApiContextSlice.actions
)

const apiContextSagas = [saga_watchSelectedContext, saga_watchHouseholdContext]

export {
  useRdot360HouseholdAccountsApiContext,
  useRdot360SelectedAccountsApiContext,
  apiContextSagas
}
