import { ProgressIndicator } from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { IListsSelectors } from '../store/selectors'

export const createConnectedListsProgess = <T extends SearchResponseType>(
  listSelectors: IListsSelectors<T>
) => {
  const ListsProgress: React.FC = () => {
    const { dataSelectors, uiSelectors } = listSelectors
    const loading = useSelector(dataSelectors.getIsLoading)
    const exporting = useSelector(
      listSelectors.exportSelectors.getIsExportLoading
    )
    const progress = useSelector(dataSelectors.getProgress)
    const listSize = useSelector(uiSelectors.getListSize)
    return loading || exporting ? (
      <ProgressIndicator
        barHeight={2}
        percentComplete={
          listSize && listSize > 50
            ? (progress || 0) / (listSize || 50) || 0.01
            : undefined
        }
        styles={{
          root: {
            width: '100%'
          },
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
    ) : (
      <div style={{ height: '2px' }} />
    )
  }
  return ListsProgress
}
