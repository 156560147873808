import { css } from '@emotion/react'

const fancyScroll = css({
  ['::-webkit-scrollbar']: {
    width: '5px',
    height: '5px',
    background: 'transparent'
  },
  ['::-webkit-scrollbar-track']: {
    background: '#D9D9D966',
    borderRadius: '5px'
  },
  ['::-webkit-scrollbar-thumb']: {
    background: '#D9D9D9dd',
    borderRadius: '5px'
  },
  ['&:hover']: {
    ['::-webkit-scrollbar-thumb']: {
      background: '#55555555'
    },
    ['::-webkit-scrollbar-thumb:hover']: {
      background: '#555'
    }
  },
  overflow: 'overlay'
})

export const advisoryModuleStyles = {
  fancyScroll
}
