import { MessageBarType } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import { createReducer } from 'typesafe-actions'
import {
  IAbaNumber,
  IWirePayment,
  ICurrencyCode,
  IStandingInstruction,
  IInsufFundSolution,
  IWireDocType,
  IWireSaveDocRequest
} from '../api/types'
import {
  WireActions,
  FetchAbaDetailActions,
  FetchSwiftCodeDetailActions,
  FetchWirePaymentActions,
  fetchCountriesListActions,
  fetchHolidaysActions,
  fetchPartyPhoneListActions,
  fetchAuthUserListActions,
  fetchCurrencyCodeListActions,
  fetchInsufficientFundSolListActions,
  fetchMethodOfInstructionListActions,
  createPaymentActions,
  updateFedRefNoActions,
  setFormDataActions,
  fetchPaymentAttensionActions,
  fetchPaymentStandingInstructionsActions,
  fetchPastPaymentsActions,
  reprocessTransactionActions,
  declineTransactionActions,
  closeTransactionActions,
  mmValidatePaymentActions,
  RiskAcknowledgementActions,
  processMMDeviceInfoActions,
  fetchPaymentRiskEvalResultActions,
  SubmitPaymentReviewActions,
  FetchPaymentApproveDetailsActions,
  fetchWireDocTypeListActions,
  uploadWireDocumentActions,
  fetchAccountBalanceActions,
  fetchForeignAccountBalanceActions,
  fetchWireTypeActions,
  clientSearchActions,
  accountSearchActions,
  deleteWireDocumentActions,
  SubmitOPSAssignmentActions,
  mmValidateAccountActions
} from './actions'
import {
  IAccountBalance,
  IAttensionResponse,
  IAuthUserList,
  ICountryList,
  IForeignCurrencyPosition,
  IgetWireTypeResponse,
  IHolidayItem,
  IMMDeviceInfoResponse,
  IMMValidateResponse,
  IPastPayment,
  IPaymentApproveDetailsResponse,
  IPaymentReviewResponse,
  IPaymentVerificationForm,
  IWireInitiationForm,
  IWireRiskAckRequest,
  IWireRiskAckResponse
} from './types'

export interface IWireState {
  abaDetail?: IAbaNumberState
  swiftCodeState?: ISwiftCodeState
  wirePayments?: IWirePaymentState
  countriesState?: IWireCountriesState
  holidayListState?: IWireHolidayListState
  reprocessState?: IReprocessState
  declineState?: IDeclineState
  closeState?: ICloseState
  partyPhoneListState?: IPartyPhoneListState
  authUserListState?: IAuthUserListState
  currencyListState?: ICurrencyListState
  insufFundSolListState?: IInsufFundSolListState
  accountBalanceState?: IAccountBalanceState
  foreignAccountBalanceState?: IForeignAccountBalanceState
  wireTypeState?: IWireTypeState
  clientSearchState?: IClientSearchState
  accountSearchState?: IAccountSearchState
  wireDocTypesState?: IWireDocTypesState
  wireUploadState?: IWireUploadState
  wireDocDeleteState?: IWireDocDeleteState
  methodOfInstructionsListState?: IMethodOfInstructionListState
  createPaymentState?: ICreatePaymentState
  updateFedRefNoState?: IUpdateFedRefState
  wireFormState?: IWireFormState
  paymentAttensionState?: IPaymentAttensionState
  wireStandingInstructionsListState?: IWirePaymentStandingInstructionState
  wirePastPaymentListState?: IWirePastPaymentListState
  mmValidateState?: IMMValidateState
  mmValidateAccountState?: IMMValidateState
  riskAckState?: IRiskAckState
  mmDeviceInfoState?: IMMDeviceInfoState
  paymentRiskEvaluationByRefIdState?: IPaymentRiskEvaluationByRefIdState
  submitPaymentReviewState?: ISubmitPaymentReviewState
  submitOPSAssignmentState?: ISubmitOPSAssignmentState
  paymentApproveDetailsState?: IPaymentApproveDetailsState
}

export interface IWireFormState {
  wire?: IWireInitiationForm
  verification?: IPaymentVerificationForm
  unsavedVerificationData?: IPaymentVerificationForm
}

export interface ICreatePaymentState {
  isLoading: boolean
  message?: string
  messageBarType?: MessageBarType
  error?: Error
}
export interface IUpdateFedRefState {
  isLoading: boolean
  updateFedRefSuccessful?: string
  error?: Error
}
export interface IMethodOfInstructionListState {
  isLoading: boolean
  instructions?: string[]
  error?: Error
}

export interface IAbaNumberState {
  isLoading: boolean
  abaNumber?: IAbaNumber
  error?: Error
}
export interface ISwiftCodeState {
  isLoading: boolean
  swiftCode?: IAbaNumber
  error?: Error
}

const initialState: IWireState = {}

export interface IWirePaymentState {
  isLoading: boolean
  payments?: IWirePayment[]
  error?: Error
}

export interface IWireHolidayListState {
  isLoading: boolean
  holidays?: IHolidayItem[]
  error?: Error
}

export interface IReprocessState {
  isLoading?: boolean
  isReprocessed?: string
  error?: Error
}
export interface IDeclineState {
  isLoading?: boolean
  isRequestDeclined?: string
  error?: Error
}

export interface ICloseState {
  isLoading?: boolean
  isRequestClosed?: string
  error?: Error
}

export interface IWireCountriesState {
  isLoading: boolean
  countries?: ICountryList[]
  error?: Error
}

export interface IPartyPhoneListState {
  isLoading: boolean
  phones?: string[]
  error?: Error
}

export interface IAuthUserListState {
  isLoading: boolean
  authUsers?: IAuthUserList[]
  error?: Error
}

export interface ICurrencyListState {
  isLoading: boolean
  currencies?: ICurrencyCode[]
  error?: Error
}

export interface IInsufFundSolListState {
  isLoading: boolean
  insufFundSolutions?: IInsufFundSolution[]
  error?: Error
}

export interface IWireDocTypesState {
  isLoading: boolean
  docTypes?: IWireDocType[]
  error?: Error
}

export interface IAccountBalanceState {
  isLoading: boolean
  acountBalanceData?: IAccountBalance
  error?: Error
}

export interface IForeignAccountBalanceState {
  isLoading?: boolean
  foreignAccountBalanceData?: IForeignCurrencyPosition
  error?: Error
}

export interface IWireTypeState {
  isLoading: boolean
  wireTypeData?: IgetWireTypeResponse
  error?: Error
}

export interface IClientSearchState {
  isLoading: boolean
  clientSearchList?: ISearchResult<IClient>
  error?: Error
}

export interface IAccountSearchState {
  isLoading: boolean
  accountSearchList?: ISearchResult<IAccount>
  error?: Error
}

export interface IWireUploadState {
  isLoading: boolean
  uploadData?: IWireSaveDocRequest
  error?: Error
}
export interface IWireDocDeleteState {
  isLoading: boolean
  deletedFile?: string
  error?: Error
}

export interface IPaymentAttensionState {
  isLoading: boolean
  attension?: IAttensionResponse
  error?: Error
}

export interface IWirePaymentStandingInstructionState {
  isLoading: boolean
  instructions?: IStandingInstruction[]
  error?: Error
}

export interface IWirePastPaymentListState {
  isLoading: boolean
  paymentList?: IPastPayment[]
  error?: Error
}

export interface IMMValidateState {
  isLoading: boolean
  validateResponse?: IMMValidateResponse
  error?: Error
}

export interface IMMDeviceInfoState {
  isLoading: boolean
  mmDeviceInfo?: IMMDeviceInfoResponse
  error?: Error
}

export interface ISubmitPaymentReviewState {
  isLoading?: boolean
  submitPaymentReviewResponse?: IPaymentReviewResponse
  error?: Error
}

export interface ISubmitOPSAssignmentState {
  isLoading?: boolean
  isOPSAssigned?: boolean
  error?: Error
}
export interface IPaymentApproveDetailsState {
  isLoading?: boolean
  paymentApproveDetailsResponse?: IPaymentApproveDetailsResponse
  error?: Error
}

export interface IRiskAckState {
  isLoading: boolean
  riskAckResponse?: IWireRiskAckResponse
  riskAckData?: IWireRiskAckRequest
  error?: Error
}

export interface IPaymentRiskEvaluationByRefIdState {
  isLoading: boolean
  attension?: IAttensionResponse
  error?: Error
}

export const wireReducer = createReducer<IWireState, WireActions>(initialState)
  .handleAction(FetchAbaDetailActions.request, (state) => ({
    ...state,
    abaDetail: {
      isLoading: true,
      error: undefined,
      abaNumber: undefined
    }
  }))
  .handleAction(FetchAbaDetailActions.success, (state, action) => ({
    ...state,
    abaDetail: {
      isLoading: false,
      error: undefined,
      abaNumber: action.payload
    }
  }))
  .handleAction(FetchAbaDetailActions.failure, (state, action) => ({
    ...state,
    abaDetail: {
      isLoading: false,
      error: action.payload,
      abaNumber: undefined
    }
  }))
  .handleAction(FetchAbaDetailActions.clear, (state) => ({
    ...state,
    abaDetail: undefined,
    abaNumber: undefined
  }))
  .handleAction(FetchSwiftCodeDetailActions.request, (state) => ({
    ...state,
    swiftCodeState: {
      isLoading: true,
      error: undefined,
      swiftCode: undefined
    }
  }))
  .handleAction(FetchSwiftCodeDetailActions.success, (state, action) => ({
    ...state,
    swiftCodeState: {
      isLoading: false,
      error: undefined,
      swiftCode: action.payload
    }
  }))
  .handleAction(FetchSwiftCodeDetailActions.failure, (state, action) => ({
    ...state,
    swiftCodeState: {
      isLoading: false,
      error: action.payload,
      swiftCode: undefined
    }
  }))
  .handleAction(FetchSwiftCodeDetailActions.clear, (state) => ({
    ...state,
    swiftCodeState: undefined
  }))
  .handleAction(RiskAcknowledgementActions.request, (state) => ({
    ...state,
    riskAckState: {
      ...state.riskAckState,
      isLoading: true,
      riskAckResponse: undefined,
      error: undefined
    }
  }))
  .handleAction(RiskAcknowledgementActions.success, (state, action) => ({
    ...state,
    riskAckState: {
      ...state.riskAckState,
      isLoading: false,
      riskAckResponse: action.payload,
      error: undefined
    }
  }))
  .handleAction(RiskAcknowledgementActions.failure, (state, action) => ({
    ...state,
    riskAckState: {
      ...state.riskAckState,
      isLoading: false,
      riskAckResponse: undefined,
      error: action.payload
    }
  }))
  .handleAction(RiskAcknowledgementActions.setRiskAck, (state, action) => ({
    ...state,
    riskAckState: {
      ...state.riskAckState,
      isLoading: false,
      riskAckData: action.payload
    }
  }))
  .handleAction(RiskAcknowledgementActions.clear, (state) => ({
    ...state,
    riskAckState: undefined
  }))
  .handleAction(FetchWirePaymentActions.request, (state) => ({
    ...state,
    wirePayments: {
      isLoading: true,
      error: undefined,
      payments: undefined
    }
  }))
  .handleAction(FetchWirePaymentActions.success, (state, action) => ({
    ...state,
    wirePayments: {
      isLoading: false,
      error: undefined,
      payments: action.payload
    }
  }))
  .handleAction(FetchWirePaymentActions.failure, (state, action) => ({
    ...state,
    wirePayments: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchHolidaysActions.request, (state) => ({
    ...state,
    holidayListState: {
      isLoading: true,
      error: undefined,
      holidays: undefined
    }
  }))
  .handleAction(fetchHolidaysActions.success, (state, action) => ({
    ...state,
    holidayListState: {
      isLoading: false,
      error: undefined,
      holidays: action.payload
    }
  }))
  .handleAction(fetchHolidaysActions.failure, (state, action) => ({
    ...state,
    holidayListState: {
      isLoading: false,
      error: action.payload,
      holidays: undefined
    }
  }))
  .handleAction(reprocessTransactionActions.request, (state) => ({
    ...state,
    reprocessState: {
      isLoading: true,
      isReprocessed: undefined,
      error: undefined
    }
  }))
  .handleAction(reprocessTransactionActions.success, (state) => ({
    ...state,
    reprocessState: {
      isLoading: false,
      isReprocessed: 'success',
      error: undefined
    }
  }))
  .handleAction(reprocessTransactionActions.failure, (state, action) => ({
    ...state,
    reprocessState: {
      isLoading: false,
      isReprocessed: 'failed',
      error: action.payload
    }
  }))
  .handleAction(reprocessTransactionActions.clear, (state) => ({
    ...state,
    reprocessState: {}
  }))
  .handleAction(declineTransactionActions.request, (state) => ({
    ...state,
    declineState: {
      isLoading: true,
      isRequestDeclined: undefined,
      error: undefined
    }
  }))
  .handleAction(declineTransactionActions.success, (state) => ({
    ...state,
    declineState: {
      isLoading: false,
      isRequestDeclined: 'success',
      error: undefined
    }
  }))
  .handleAction(declineTransactionActions.failure, (state, action) => ({
    ...state,
    declineState: {
      isLoading: false,
      isRequestDeclined: 'failed',
      error: action.payload
    }
  }))
  .handleAction(declineTransactionActions.clear, (state) => ({
    ...state,
    declineState: {}
  }))
  .handleAction(closeTransactionActions.request, (state) => ({
    ...state,
    closeState: {
      isLoading: true,
      isRequestClosed: undefined,
      error: undefined
    }
  }))
  .handleAction(closeTransactionActions.success, (state) => ({
    ...state,
    closeState: {
      isLoading: false,
      isRequestClosed: 'success',
      error: undefined
    }
  }))
  .handleAction(closeTransactionActions.failure, (state, action) => ({
    ...state,
    closeState: {
      isLoading: false,
      isRequestClosed: 'failed',
      error: action.payload
    }
  }))
  .handleAction(closeTransactionActions.clear, (state) => ({
    ...state,
    closeState: {}
  }))
  .handleAction(fetchCountriesListActions.request, (state) => ({
    ...state,
    countriesState: {
      isLoading: true,
      error: undefined,
      countries: undefined
    }
  }))
  .handleAction(fetchCountriesListActions.success, (state, action) => ({
    ...state,
    countriesState: {
      isLoading: false,
      error: undefined,
      countries: action.payload
    }
  }))
  .handleAction(fetchCountriesListActions.failure, (state, action) => ({
    ...state,
    countriesState: {
      isLoading: false,
      error: action.payload,
      countries: undefined
    }
  }))
  .handleAction(fetchCountriesListActions.clear, (state) => ({
    ...state,
    countriesState: undefined
  }))
  .handleAction(fetchPartyPhoneListActions.request, (state) => ({
    ...state,
    partyPhoneListState: {
      isLoading: true,
      phones: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchPartyPhoneListActions.success, (state, action) => ({
    ...state,
    partyPhoneListState: {
      isLoading: false,
      phones: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchPartyPhoneListActions.failure, (state, action) => ({
    ...state,
    partyPhoneListState: {
      isLoading: false,
      phones: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchPartyPhoneListActions.clear, (state) => ({
    ...state,
    partyPhoneListState: undefined
  }))
  .handleAction(fetchAuthUserListActions.request, (state) => ({
    ...state,
    authUserListState: {
      isLoading: true,
      authUsers: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchAuthUserListActions.success, (state, action) => ({
    ...state,
    authUserListState: {
      isLoading: false,
      authUsers: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchAuthUserListActions.failure, (state, action) => ({
    ...state,
    authUserListState: {
      isLoading: false,
      authUsers: [],
      error: action.payload
    }
  }))
  .handleAction(fetchAuthUserListActions.clear, (state) => ({
    ...state,
    authUserListState: undefined
  }))
  .handleAction(fetchCurrencyCodeListActions.request, (state) => ({
    ...state,
    currencyListState: {
      isLoading: true,
      currencies: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchCurrencyCodeListActions.success, (state, action) => ({
    ...state,
    currencyListState: {
      isLoading: false,
      currencies: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchCurrencyCodeListActions.failure, (state, action) => ({
    ...state,
    currencyListState: {
      isLoading: false,
      currencies: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchCurrencyCodeListActions.clear, (state) => ({
    ...state,
    currencyListState: undefined
  }))
  .handleAction(fetchInsufficientFundSolListActions.request, (state) => ({
    ...state,
    insufFundSolListState: {
      isLoading: true,
      insufFundSolutions: undefined,
      error: undefined
    }
  }))
  .handleAction(
    fetchInsufficientFundSolListActions.success,
    (state, action) => ({
      ...state,
      insufFundSolListState: {
        isLoading: false,
        insufFundSolutions: action.payload,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchInsufficientFundSolListActions.failure,
    (state, action) => ({
      ...state,
      insufFundSolListState: {
        isLoading: false,
        insufFundSolutions: undefined,
        error: action.payload
      }
    })
  )
  .handleAction(fetchWireDocTypeListActions.request, (state) => ({
    ...state,
    wireDocTypesState: {
      isLoading: true,
      docTypes: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchWireDocTypeListActions.success, (state, action) => ({
    ...state,
    wireDocTypesState: {
      isLoading: false,
      docTypes: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchWireDocTypeListActions.failure, (state, action) => ({
    ...state,
    wireDocTypesState: {
      isLoading: false,
      docTypes: undefined,
      error: action.payload
    }
  }))
  .handleAction(uploadWireDocumentActions.request, (state) => ({
    ...state,
    wireUploadState: {
      isLoading: true,
      uploadData: undefined,
      error: undefined
    }
  }))
  .handleAction(uploadWireDocumentActions.success, (state, action) => ({
    ...state,
    wireUploadState: {
      isLoading: false,
      uploadData: action.payload,
      error: undefined
    }
  }))
  .handleAction(uploadWireDocumentActions.failure, (state, action) => ({
    ...state,
    wireUploadState: {
      isLoading: false,
      uploadData: undefined,
      error: action.payload
    }
  }))
  .handleAction(uploadWireDocumentActions.clear, (state) => ({
    ...state,
    wireUploadState: undefined
  }))
  .handleAction(fetchWireTypeActions.request, (state) => ({
    ...state,
    wireTypeState: {
      isLoading: true,
      wireTypeData: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchWireTypeActions.success, (state, action) => ({
    ...state,
    wireTypeState: {
      isLoading: false,
      wireTypeData: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchWireTypeActions.failure, (state, action) => ({
    ...state,
    wireTypeState: {
      isLoading: false,
      wireTypeData: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchWireTypeActions.clear, (state) => ({
    ...state,
    wireTypeState: undefined
  }))

  .handleAction(fetchForeignAccountBalanceActions.request, (state) => ({
    ...state,
    foreignAccountBalanceState: {
      isLoading: true,
      foreignAccountBalanceData: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchForeignAccountBalanceActions.success, (state, action) => ({
    ...state,
    foreignAccountBalanceState: {
      isLoading: false,
      foreignAccountBalanceData: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchForeignAccountBalanceActions.failure, (state, action) => ({
    ...state,
    foreignAccountBalanceState: {
      isLoading: false,
      foreignAccountBalanceData: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchForeignAccountBalanceActions.clear, (state) => ({
    ...state,
    foreignAccountBalanceState: undefined
  }))
  .handleAction(fetchAccountBalanceActions.request, (state) => ({
    ...state,
    accountBalanceState: {
      isLoading: true,
      acountBalanceData: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchAccountBalanceActions.success, (state, action) => ({
    ...state,
    accountBalanceState: {
      isLoading: false,
      acountBalanceData: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchAccountBalanceActions.failure, (state, action) => ({
    ...state,
    accountBalanceState: {
      isLoading: false,
      acountBalanceData: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchAccountBalanceActions.clear, (state) => ({
    ...state,
    accountBalanceState: undefined
  }))
  .handleAction(clientSearchActions.request, (state) => ({
    ...state,
    clientSearchState: {
      ...state.clientSearchState,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.success, (state, action) => ({
    ...state,
    clientSearchState: {
      isLoading: false,
      clientSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.failure, (state, action) => ({
    ...state,
    clientSearchState: {
      isLoading: false,
      clientSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(clientSearchActions.clear, (state) => ({
    ...state,
    clientSearchState: undefined
  }))
  .handleAction(accountSearchActions.request, (state) => ({
    ...state,
    accountSearchState: {
      ...state.accountSearchState,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.success, (state, action) => ({
    ...state,
    accountSearchState: {
      isLoading: false,
      accountSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.failure, (state, action) => ({
    ...state,
    accountSearchState: {
      isLoading: false,
      accountSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(accountSearchActions.clear, (state) => ({
    ...state,
    accountSearchState: undefined
  }))
  .handleAction(updateFedRefNoActions.request, (state) => ({
    ...state,
    updateFedRefNoState: {
      isLoading: true,
      updateFedRefSuccessful: undefined,
      error: undefined
    }
  }))
  .handleAction(updateFedRefNoActions.success, (state, action) => ({
    ...state,
    updateFedRefNoState: {
      isLoading: false,
      updateFedRefSuccessful: action.payload,
      error: undefined
    }
  }))
  .handleAction(updateFedRefNoActions.failure, (state, action) => ({
    ...state,
    updateFedRefNoState: {
      isLoading: false,
      updateFedRefSuccessful: undefined,
      error: action.payload
    }
  }))
  .handleAction(updateFedRefNoActions.clear, (state) => ({
    ...state,
    updateFedRefNoState: undefined
  }))
  .handleAction(deleteWireDocumentActions.request, (state) => ({
    ...state,
    wireDocDeleteState: {
      isLoading: true,
      deletedFile: undefined,
      error: undefined
    }
  }))
  .handleAction(deleteWireDocumentActions.success, (state, action) => ({
    ...state,
    wireDocDeleteState: {
      isLoading: false,
      deletedFile: action.payload,
      error: undefined
    }
  }))
  .handleAction(deleteWireDocumentActions.failure, (state, action) => ({
    ...state,
    wireDocDeleteState: {
      isLoading: false,
      deletedFile: undefined,
      error: action.payload
    }
  }))
  .handleAction(deleteWireDocumentActions.clear, (state) => ({
    ...state,
    wireDocDeleteState: undefined
  }))
  .handleAction(fetchMethodOfInstructionListActions.request, (state) => ({
    ...state,
    methodOfInstructionsListState: {
      isLoading: true,
      instructions: undefined,
      error: undefined
    }
  }))
  .handleAction(
    fetchMethodOfInstructionListActions.success,
    (state, action) => ({
      ...state,
      methodOfInstructionsListState: {
        isLoading: false,
        instructions: action.payload,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchMethodOfInstructionListActions.failure,
    (state, action) => ({
      ...state,
      methodOfInstructionsListState: {
        isLoading: false,
        instructions: undefined,
        error: action.payload
      }
    })
  )
  .handleAction(fetchMethodOfInstructionListActions.clear, (state) => ({
    ...state,
    methodOfInstructionsListState: undefined
  }))
  .handleAction(createPaymentActions.request, (state) => ({
    ...state,
    createPaymentState: {
      isLoading: true,
      error: undefined,
      message: undefined,
      messageBarType: undefined
    }
  }))
  .handleAction(createPaymentActions.success, (state, action) => ({
    ...state,
    createPaymentState: {
      isLoading: false,
      error: undefined,
      message: 'Your payment has been created successfully.',
      messageBarType: MessageBarType.success
    },
    wireFormState: {
      ...state.wireFormState,
      wire: {
        ...state.wireFormState?.wire,
        wirePaymentId: action.payload.entityId,
        pmmId: action.payload.pmmId
      }
    }
  }))
  .handleAction(createPaymentActions.failure, (state, action) => ({
    ...state,
    createPaymentState: {
      isLoading: false,
      error: action.payload,
      message:
        'Error occured while creating your payment, please try after sometime.',
      messageBarType: MessageBarType.error
    }
  }))
  .handleAction(createPaymentActions.clear, (state) => ({
    ...state,
    createPaymentState: undefined
  }))
  .handleAction(setFormDataActions.setWireFormData, (state, action) => ({
    ...state,
    wireFormState: {
      ...state.wireFormState,
      wire: {
        ...state.wireFormState?.wire,
        ...action.payload
      }
    }
  }))
  .handleAction(setFormDataActions.clearFormData, (state) => ({
    ...state,
    wireFormState: undefined
  }))
  .handleAction(
    setFormDataActions.setDisbursmentApprovalViewData,
    (state, action) => ({
      ...state,
      wireFormState: {
        ...state.wireFormState,
        verification: action.payload
      }
    })
  )
  .handleAction(
    setFormDataActions.setUnsavedDisbursmentData,
    (state, action) => ({
      ...state,
      wireFormState: {
        ...state.wireFormState,
        unsavedVerificationData: action.payload
      }
    })
  )
  .handleAction(setFormDataActions.clearUnsavedDisbursmentData, (state) => ({
    ...state,
    wireFormState: {
      ...state.wireFormState,
      unsavedVerificationData: undefined
    }
  }))
  .handleAction(fetchPaymentAttensionActions.request, (state) => ({
    ...state,
    paymentAttensionState: {
      isLoading: true,
      error: undefined,
      attension: undefined
    }
  }))
  .handleAction(fetchPaymentAttensionActions.success, (state, action) => ({
    ...state,
    paymentAttensionState: {
      isLoading: false,
      error: undefined,
      attension: action.payload
    }
  }))
  .handleAction(fetchPaymentAttensionActions.clear, (state) => ({
    ...state,
    paymentAttensionState: undefined
  }))
  .handleAction(fetchPaymentAttensionActions.failure, (state, action) => ({
    ...state,
    paymentAttensionState: {
      isLoading: false,
      error: action.payload,
      attension: undefined
    }
  }))
  .handleAction(fetchPastPaymentsActions.request, (state) => ({
    ...state,
    wirePastPaymentListState: {
      isLoading: true,
      paymentList: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchPastPaymentsActions.success, (state, action) => ({
    ...state,
    wirePastPaymentListState: {
      isLoading: false,
      paymentList: action.payload.paymentList,
      error: undefined
    }
  }))
  .handleAction(fetchPastPaymentsActions.failure, (state, action) => ({
    ...state,
    wirePastPaymentListState: {
      isLoading: false,
      paymentList: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchPaymentStandingInstructionsActions.request, (state) => ({
    ...state,
    wireStandingInstructionsListState: {
      isLoading: true,
      instructions: undefined,
      error: undefined
    }
  }))
  .handleAction(
    fetchPaymentStandingInstructionsActions.success,
    (state, action) => ({
      ...state,
      wireStandingInstructionsListState: {
        isLoading: false,
        instructions: action.payload,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchPaymentStandingInstructionsActions.failure,
    (state, action) => ({
      ...state,
      wireStandingInstructionsListState: {
        isLoading: false,
        instructions: undefined,
        error: action.payload
      }
    })
  )
  .handleAction(mmValidateAccountActions.request, (state) => ({
    ...state,
    mmValidateAccountState: {
      isLoading: true,
      validateResponse: undefined,
      error: undefined
    }
  }))
  .handleAction(mmValidateAccountActions.success, (state, action) => ({
    ...state,
    mmValidateAccountState: {
      isLoading: false,
      validateResponse: action.payload,
      error: undefined
    }
  }))
  .handleAction(mmValidateAccountActions.failure, (state, action) => ({
    ...state,
    mmValidateAccountState: {
      isLoading: false,
      validateResponse: undefined,
      error: action.payload
    }
  }))
  .handleAction(mmValidateAccountActions.clear, (state) => ({
    ...state,
    mmValidateAccountState: undefined
  }))
  .handleAction(mmValidatePaymentActions.request, (state) => ({
    ...state,
    mmValidateState: {
      isLoading: true,
      validateResponse: undefined,
      error: undefined
    }
  }))
  .handleAction(mmValidatePaymentActions.preapproval_request, (state) => ({
    ...state,
    mmValidateState: {
      isLoading: true,
      validateResponse: undefined,
      error: undefined
    }
  }))
  .handleAction(mmValidatePaymentActions.success, (state, action) => ({
    ...state,
    mmValidateState: {
      isLoading: false,
      validateResponse: action.payload,
      error: undefined
    }
  }))
  .handleAction(mmValidatePaymentActions.failure, (state, action) => ({
    ...state,
    mmValidateState: {
      isLoading: false,
      validateResponse: undefined,
      error: action.payload
    }
  }))
  .handleAction(mmValidatePaymentActions.clear, (state) => ({
    ...state,
    mmValidateState: undefined
  }))
  .handleAction(processMMDeviceInfoActions.request, (state) => ({
    ...state,
    mmDeviceInfoState: {
      isLoading: true,
      mmDeviceInfo: undefined,
      error: undefined
    }
  }))
  .handleAction(processMMDeviceInfoActions.success, (state, action) => ({
    ...state,
    mmDeviceInfoState: {
      isLoading: false,
      mmDeviceInfo: action.payload,
      error: undefined
    }
  }))
  .handleAction(processMMDeviceInfoActions.failure, (state, action) => ({
    ...state,
    mmDeviceInfoState: {
      isLoading: false,
      mmDeviceInfo: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchPaymentRiskEvalResultActions.request, (state) => ({
    ...state,
    paymentRiskEvaluationByRefIdState: {
      isLoading: true,
      error: undefined,
      attension: undefined
    }
  }))
  .handleAction(fetchPaymentRiskEvalResultActions.success, (state, action) => ({
    ...state,
    paymentRiskEvaluationByRefIdState: {
      isLoading: false,
      error: undefined,
      attension: action.payload
    }
  }))
  .handleAction(fetchPaymentRiskEvalResultActions.failure, (state, action) => ({
    ...state,
    paymentRiskEvaluationByRefIdState: {
      isLoading: false,
      error: action.payload,
      attension: undefined
    }
  }))
  .handleAction(fetchPaymentRiskEvalResultActions.clear, (state) => ({
    ...state,
    paymentRiskEvaluationByRefIdState: undefined
  }))
  .handleAction(SubmitPaymentReviewActions.request, (state) => ({
    ...state,
    submitPaymentReviewState: {
      isLoading: true,
      submitPaymentReviewResponse: undefined,
      error: undefined
    }
  }))
  .handleAction(SubmitPaymentReviewActions.success, (state, action) => ({
    ...state,
    submitPaymentReviewState: {
      isLoading: false,
      submitPaymentReviewResponse: action.payload,
      error: undefined
    }
  }))
  .handleAction(SubmitPaymentReviewActions.failure, (state, action) => ({
    ...state,
    submitPaymentReviewState: {
      isLoading: false,
      submitPaymentRfalseeviewResponse: undefined,
      error: action.payload
    }
  }))
  .handleAction(SubmitPaymentReviewActions.clear, (state) => ({
    ...state,
    submitPaymentReviewState: {}
  }))
  .handleAction(SubmitOPSAssignmentActions.request, (state) => ({
    ...state,
    submitOPSAssignmentState: {
      isLoading: true,
      isOPSAssigned: undefined,
      error: undefined
    }
  }))
  .handleAction(SubmitOPSAssignmentActions.success, (state) => ({
    ...state,
    submitOPSAssignmentState: {
      isLoading: false,
      isOPSAssigned: true,
      error: undefined
    }
  }))
  .handleAction(SubmitOPSAssignmentActions.failure, (state, action) => ({
    ...state,
    submitOPSAssignmentState: {
      isLoading: true,
      isOPSAssigned: false,
      error: action.payload
    }
  }))
  .handleAction(SubmitOPSAssignmentActions.clear, (state) => ({
    ...state,
    submitOPSAssignmentState: undefined
  }))
  .handleAction(FetchPaymentApproveDetailsActions.request, (state) => ({
    ...state,
    paymentApproveDetailsState: {
      ...state?.paymentApproveDetailsState,
      paymentApproveDetailsResponse: undefined,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(FetchPaymentApproveDetailsActions.success, (state, action) => ({
    ...state,
    paymentApproveDetailsState: {
      isLoading: true,
      paymentApproveDetailsResponse: action.payload,
      error: undefined
    }
  }))
  .handleAction(FetchPaymentApproveDetailsActions.failure, (state, action) => ({
    ...state,
    paymentApproveDetailsState: {
      isLoading: true,
      paymentApproveDetailsResponse: undefined,
      error: action.payload
    }
  }))
  .handleAction(FetchPaymentApproveDetailsActions.clear, (state) => ({
    ...state,
    paymentApproveDetailsState: {}
  }))
