import { useTheme } from '@fluentui/react'
import { PropsWithChildren } from 'react'

const baseStyles: React.CSSProperties = {
  position: 'absolute',
  zIndex: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}
export const SimpleOverlay: React.FC<
  PropsWithChildren<{ style?: React.CSSProperties }>
> = ({ children }) => {
  const theme = useTheme()
  return (
    <div
      style={{
        ...baseStyles
      }}
    >
      <div
        style={{
          ...baseStyles,
          backgroundColor: `${theme.palette.white}66`
        }}
      />
      <div
        style={{
          ...baseStyles,
          zIndex: 2
        }}
      >
        {children}
      </div>
    </div>
  )
}
