import { ISelectableOption } from './Dropdown'

export const currentYear = new Date().getFullYear()

export const yearRangeOptions: ISelectableOption[] = [
  { key: `${currentYear}`, text: 'Current Year' },
  { key: `${currentYear - 1}`, text: `${currentYear - 1}` },
  { key: `${currentYear - 2}`, text: `${currentYear - 2}` },
  { key: `${currentYear - 3}`, text: `${currentYear - 3}` },
  { key: `${currentYear - 4}`, text: `${currentYear - 4}` },
  { key: `${currentYear - 5}`, text: `${currentYear - 5}` }
]
