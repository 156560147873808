import axios from 'axios'
import { IApiOptions } from '../shared/contracts/IApiOptions'

const apiPath = 'profileapi/api'

export interface IRelatedLogin {
  _id?: string
  aaduseroid?: string
  portaluserid?: string
  role?: string
  businesstrustname?: string
  firstname?: string
  lastname?: string
  emailprimary?: string
  accounts?: string
  lastlogindate?: string
}

// {{api_root}}/profileapi/api/GetRelatedLogins?portaluserid=6611000175
export const getRelatedLoginsByPortalId = (
  portalId: string,
  options: IApiOptions
): Promise<IRelatedLogin[]> => {
  const apiName = 'GetRelatedLogins'

  const url = `${options.apiRoot}/${apiPath}/${apiName}?portaluserid=${portalId}`

  return axios
    .get<IRelatedLogin[]>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getRelatedLoginsByAccount = (
  accountNumber: string,
  options: IApiOptions
): Promise<IRelatedLogin[]> => {
  const apiName = 'GetRelatedLogins'

  const url = `${options.apiRoot}/${apiPath}/${apiName}?accountnumber=${accountNumber}`

  return axios
    .get<IRelatedLogin[]>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}
