import {
  createApi,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react'
import {
  ISystemUser,
  IWhoAmIResponse,
  ICdsBatchRequestItem
} from 'api/dynamics'
import { flow } from 'lodash'
import { stringify } from 'query-string'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getDynamicsCrmApiConfig } from 'store/system'
import { v4 as uuidv4 } from 'uuid'
import { axiosBaseQuery } from './shared'

export const DynamicsApiSliceKey = 'api.dynamics'
export type DynamicsApiReducerState = {
  [DynamicsApiSliceKey]: ReturnType<typeof dynamicsApi.reducer>
}

export interface IDynamicsApiOdataRequest {
  $select?: string[]
  $expand?: string[]
}

const getDynamicsApiBaseUrl = (
  state: AppState,
  version: 'v9.1' | 'v9.0' | 'v9.2' = 'v9.2'
) => {
  const base = flow(getDynamicsCrmApiConfig, (x) => x?.root)(state)
  const url = new URL(`/api/data/${version}`, base)
  return url.href
}

const paramsSerializer = (params: IDynamicsApiOdataRequest) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const getDynamicsApiAuthToken = (state: AppState) => {
  const scopes = flow(getDynamicsCrmApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('No scopes provided in configuration for graph api')
  }

  return tryAcquireAccessToken(scopes)
}

export const dynamicsApi = createApi({
  reducerPath: DynamicsApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getDynamicsApiBaseUrl(state),
    getAuthToken: (state) => getDynamicsApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    getSystemUser: builder.query<
      ISystemUser,
      { systemuserId: string; params: IDynamicsApiOdataRequest }
    >({
      query: ({ systemuserId, params }) => ({
        url: `/systemusers(${systemuserId})`,
        params,
        paramsSerializer
      })
    }),
    getCurrentSystemuser: builder.query<ISystemUser, void>({
      queryFn: async function queryFn(arg, api, extraOptions, baseQuery) {
        const whoAmIResponse = await baseQuery('WhoAmI')

        if (whoAmIResponse.error) {
          return {
            error: whoAmIResponse.error as FetchBaseQueryError
          }
        }

        const systemuserId = (
          whoAmIResponse.data as IWhoAmIResponse | undefined
        )?.UserId

        if (!systemuserId) {
          return { error: new Error('Current user not found') }
        }

        const systemuserResponse = await baseQuery({
          url: `/systemusers(${systemuserId})`,
          params: {
            $select: [
              'systemuserid',
              'title',
              'fullname',
              'azureactivedirectoryobjectid',
              'domainname',
              'employeeid',
              'isdisabled',
              'lastname',
              'jobtitle',
              'organizationid',
              '_businessunitid_value'
            ],
            $expand: [
              'rcm_AdvisorRep_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
              'rcm_AdvisorRep_PersonalRepFor_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
              'rcm_AdvisorManager_Manager_SystemUser($select=rcm_isprimarysigner,rcm_isprincipal,rcm_advisormanagerid,statuscode,_rcm_businessunit_value)',
              'rcm_AdvisorAttributes_Advisor_SystemUser($select=rcm_wealthscapeinvestorid,rcm_crdnumber,rcm_advisorattributesid,_rcm_primaryrepcode_value)',
              'businessunitid($select=name,_parentbusinessunitid_value;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name)))',
              'systemuserroles_association($select=name)'
            ]
          } as IDynamicsApiOdataRequest,
          paramsSerializer
        })

        if (systemuserResponse.error) {
          return {
            error: systemuserResponse.error as FetchBaseQueryError
          }
        }

        const systemuser = systemuserResponse.data as ISystemUser

        return { data: systemuser }
      },
      keepUnusedDataFor: 60 * 60 * 24
    })
  })
})

export const {
  useGetCurrentSystemuserQuery,
  usePrefetch: useDynamicsApiPrefetch
} = dynamicsApi

export const useDynamicsUser = () => {
  return
}

export const createCdsBatchPayload = (requests: ICdsBatchRequestItem[]) => {
  const newline = '\r\n'
  const boundary = `batch_${uuidv4()}`

  return {
    batchRequest: [
      ...requests.map((x) =>
        [
          `--${boundary}`,
          'Content-Type: application/http',
          'Content-Transfer-Encoding:binary',
          '',
          [
            `${x.method} ${x.url} HTTP/1.1`,
            ...Object.entries(x.headers || {}).map(
              ([key, value]) => `${key}: ${value}`
            ),
            x.payload && 'Content-Type: application/json',
            x.payload && '',
            x.payload && `${JSON.stringify(x.payload)}`
          ]
            .filter((x) => x != null)
            .join(newline)
        ].join(newline)
      ),
      '',
      `--${boundary}--`,
      ''
    ].join(newline),
    boundary
  }
}
