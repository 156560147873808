import { IClient, ILoginDetails } from '../api/client.types'
import { notNullOrEmpty } from './strings'

export const getMostRecentLoginDetails = (loginDetails?: ILoginDetails[]) =>
  loginDetails?.sort(
    (a, b) => -(a.lastLogin || '').localeCompare(b.lastLogin || '')
  )?.[0]

export const getLoginDetailEmails = (client: IClient) =>
  client?.loginDetails?.map((x) => x.loginid).filter(notNullOrEmpty)

export const getAllEmails = (client: IClient) => {
  const emails = getLoginDetailEmails(client)

  const allEmails = [...(emails || []), client?.contactdetails?.emailid].filter(
    notNullOrEmpty
  )

  return allEmails
}
