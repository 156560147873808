import { ActionType, createAction } from 'typesafe-actions'
import { IClient } from '../../../api/client.types'
import { IGraphUser } from '../../../api/graph'
import {
  IGetDonorsResponse,
  IGetGrantsResponse,
  IPostDonorResponse,
  IDonor,
  IPhilanthropyGrant,
  IPostGrantResponse,
  IGetTrusteesResponse,
  IProfileUser
} from '../../../api/philanthropy'

const DONORS_REQUESTED = '@modules/@philanthropy/DONORS_REQUESTED'
const DONORS_SUCCESS = '@modules/@philanthropy/DONORS_SUCCESS'
const DONORS_FAILURE = '@modules/@philanthropy/DONORS_FAILURE'

export const donorDataActions = {
  request: createAction(DONORS_REQUESTED)<string>(),
  success: createAction(DONORS_SUCCESS)<IGetDonorsResponse>(),
  failure: createAction(DONORS_FAILURE)<Error>()
}

const DONOR_POST_REQUESTED = '@modules/@philanthropy/DONOR_POST_REQUESTED'
const DONOR_POST_SUCCESS = '@modules/@philanthropy/DONOR_POST_SUCCESS'
const DONOR_POST_FAILURE = '@modules/@philanthropy/DONOR_POST_FAILURE'

export const donorPostActions = {
  request: createAction(DONOR_POST_REQUESTED)<IDonor>(),
  success: createAction(DONOR_POST_SUCCESS)<IPostDonorResponse>(),
  failure: createAction(DONOR_POST_FAILURE)<Error>()
}

const GRANTS_REQUESTED = '@modules/@philanthropy/GRANTS_REQUESTED'
const GRANTS_SUCCESS = '@modules/@philanthropy/GRANTS_SUCCESS'
const GRANTS_FAILURE = '@modules/@philanthropy/GRANTS_FAILURE'

export const grantDataActions = {
  request: createAction(GRANTS_REQUESTED)<string>(),
  success: createAction(GRANTS_SUCCESS)<IGetGrantsResponse>(),
  failure: createAction(GRANTS_FAILURE)<Error>()
}

const GRANT_POST_REQUESTED = '@modules/@philanthropy/GRANT_POST_REQUESTED'
const GRANT_POST_SUCCESS = '@modules/@philanthropy/GRANT_POST_SUCCESS'
const GRANT_POST_FAILURE = '@modules/@philanthropy/GRANT_POST_FAILURE'

export const grantPostActions = {
  request: createAction(GRANT_POST_REQUESTED)<IPhilanthropyGrant>(),
  success: createAction(GRANT_POST_SUCCESS)<IPostGrantResponse>(),
  failure: createAction(GRANT_POST_FAILURE)<Error>()
}

const GRANTMANAGER_SEARCH_REQUESTED =
  '@modules/@philanthropy/GRANTMANAGER_SEARCH_REQUESTED'
const GRANTMANAGER_SEARCH_SUCCESS =
  '@modules/@philanthropy/GRANTMANAGER_SEARCH_SUCCESS'
const GRANTMANAGER_SEARCH_FAILURE =
  '@modules/@philanthropy/GRANTMANAGER_SEARCH_FAILURE'

export const grantManagerSearchActions = {
  request: createAction(GRANTMANAGER_SEARCH_REQUESTED)<string>(),
  success: createAction(GRANTMANAGER_SEARCH_SUCCESS)<IGraphUser[]>(),
  failure: createAction(GRANTMANAGER_SEARCH_FAILURE)<Error>()
}

const GRANTMAKER_SEARCH_REQUESTED =
  '@modules/@philanthropy/GRANTMAKER_SEARCH_REQUESTED'
const GRANTMAKER_SEARCH_SUCCESS =
  '@modules/@philanthropy/GRANTMAKER_SEARCH_SUCCESS'
const GRANTMAKER_SEARCH_CLEAR = '@modules/@philanthropy/GRANTMAKER_SEARCH_CLEAR'
const GRANTMAKER_SEARCH_FAILURE =
  '@modules/@philanthropy/GRANTMAKER_SEARCH_FAILURE'

export const grantMakerSearchActions = {
  request: createAction(GRANTMAKER_SEARCH_REQUESTED)<string>(),
  success: createAction(GRANTMAKER_SEARCH_SUCCESS)<IProfileUser[]>(),
  clear: createAction(GRANTMAKER_SEARCH_CLEAR)(),
  failure: createAction(GRANTMAKER_SEARCH_FAILURE)<Error>()
}

const DONOR_SEARCH_REQUEST = '@modules/@philanthropy/DONOR_SEARCH_REQUEST'
const DONOR_SEARCH_UPDATE = '@modules/@philanthropy/DONOR_SEARCH_UPDATE'
const DONOR_SEARCH_CLEAR = '@modules/@philanthropy/DONOR_SEARCH_CLEAR'
const DONOR_SEARCH_ERROR = '@modules/@philanthropy/DONOR_SEARCH_FAILURE'

export const donorSearchActions = {
  request: createAction(DONOR_SEARCH_REQUEST)<string>(),
  success: createAction(DONOR_SEARCH_UPDATE)<IClient[] | undefined>(),
  clear: createAction(DONOR_SEARCH_CLEAR)(),
  failure: createAction(DONOR_SEARCH_ERROR)<Error>()
}

const TRUSTEE_SEARCH_REQUEST = '@modules/@philanthropy/TRUSTEE_SEARCH_REQUEST'
const TRUSTEE_SEARCH_UPDATE = '@modules/@philanthropy/TRUSTEE_SEARCH_UPDATE'
const TRUSTEE_SEARCH_ERROR = '@modules/@philanthropy/TRUSTEE_SEARCH_FAILURE'

export const trusteeSearchActions = {
  request: createAction(TRUSTEE_SEARCH_REQUEST)<string>(),
  success: createAction(TRUSTEE_SEARCH_UPDATE)<IGetTrusteesResponse>(),
  failure: createAction(TRUSTEE_SEARCH_ERROR)<Error>()
}

export type PhilanthropyActionTypes =
  | ActionType<typeof donorDataActions>
  | ActionType<typeof grantDataActions>
  | ActionType<typeof grantManagerSearchActions>
  | ActionType<typeof grantMakerSearchActions>
  | ActionType<typeof donorPostActions>
  | ActionType<typeof grantPostActions>
  | ActionType<typeof donorSearchActions>
  | ActionType<typeof trusteeSearchActions>
