import axios from 'axios'
import { IDragDropFile } from '../../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  FetchRetirementPlanTransactionType,
  ICashAllocationWithIds,
  IFetchPlanToDistributeCash,
  ILookupFieldsResponse,
  IPlanAllocations,
  IPlanByProvider,
  IPlanProvider,
  IProductDocument,
  IProjectedAmount,
  IRetirementPlanReport,
  IRetirementPlanTransactionsResponse,
  IRetirementProductCashReceipt,
  IRetirementProductPlan
} from '../store/types'
import { showNumericPeriodFromDate } from '../store/utility'

const fetchRetirementProductPlansService = (
  monthyear: string,
  retirementPlanType: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlans?MonthYear=${monthyear}&Compensable=${retirementPlanType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRetirementProductPlan[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchRetirementPlanTransactionsService = (
  request: FetchRetirementPlanTransactionType,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementTransactions?retplanid=${request?.planid}&monthyear=${request?.selectedPeriod}&producttype=Retirement&numprevpriods=6`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRetirementPlanTransactionsResponse[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchRetirementPlanAllocationsService = (
  request: FetchRetirementPlanTransactionType,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetPlanAllocations?retplanid=${request?.planid}&monthyear=${request?.selectedPeriod}&producttype=Retirement&numprevpriods=6`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanAllocations[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchRetirementPlanTransactionsByClientService = (
  clientname: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlansByClientName?clientname=${clientname}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRetirementProductCashReceipt[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateRetirementCashReceiptsService = (
  selectedPlan: IRetirementProductPlan | undefined,
  changedCashAllocations: ICashAllocationWithIds[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UpdateRetirementTrans`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = changedCashAllocations.map((y) => {
    return {
      cashreceivedamount: y.cashallocatedamt,
      cashreceiptid: y.cashreceiptid,
      retirementplanid: parseFloat(selectedPlan?.retirementplanid || '0'),
      revenueid: y.revenueid,
      planproviderid: selectedPlan?.planproviderid || 0,
      period: showNumericPeriodFromDate(y.accrueddate || '')
    }
  })

  return axios
    .post<IRetirementPlanTransactionsResponse[] | undefined>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchProjectedAmountByPlanIdService = (
  planid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetCashReceiptProjections?retplanid=${planid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IProjectedAmount[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateProjectedAmountByPlanService = (
  projectedAmounts: IProjectedAmount[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateCashReceiptProjections`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, projectedAmounts, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const downloadD365ExcelService = (monthyear: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetD365Report?monthyear=${monthyear}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlanProvidersService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlanProviders`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanProvider[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdatePlanProviderService = (
  planProvider: IPlanProvider,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateRetirementPlanProviders`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, planProvider, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const viewStatementService = (
  req: { plan: IRetirementProductPlan; period: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/DownloadPlanDocument?retplanid=${req?.plan?.retirementplanid}&period=${req?.period}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlansToDistributeCashService = (
  cashReceipt: IRetirementProductCashReceipt,
  plan: IRetirementProductPlan | undefined,
  monthyear: string | undefined,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/trustapi/api/Retirement/DistributeCashToPlans?planproviderid=${
    plan?.planproviderid
  }&cashreceiptid=${cashReceipt?.cashreceiptid}&numprevpriods=6&monthyear=${
    monthyear || ''
  }&cashreceiptproviderid=${cashReceipt?.cashreceiptproviderid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFetchPlanToDistributeCash[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateCashReceiptToPlansService = (
  cashReceipt: IRetirementProductCashReceipt,
  selectedPlan: IRetirementProductPlan | undefined,
  changedCashAllocations: ICashAllocationWithIds[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UpdateRetirementTrans`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = changedCashAllocations.map((y) => {
    return {
      cashreceivedamount: y.cashallocatedamt,
      noncompensablecashreceivedamount: y.noncompensablecashcashallocatedamt,
      cashreceiptid: cashReceipt.cashreceiptid,
      retirementplanid: y.retirementplanid,
      revenueid: y.revenueid,
      noncompensablerevenueid: y.noncompensablerevenueid,
      planproviderid: selectedPlan?.planproviderid,
      cashreceiptproviderid: cashReceipt.cashreceiptproviderid,
      period: showNumericPeriodFromDate(y.accrueddate || '')
    }
  })

  return axios
    .post<IRetirementPlanTransactionsResponse[] | undefined>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const uploadProductDocumentService = (
  doc: IProductDocument,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/UploadPlanDocument`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('filecontents', doc.file.file)
  data.append('filename', doc.fileName)
  data.append('month', doc.monthYear)
  data.append('retirementplanid', doc.retirementplanid)
  data.append('documenttype', doc.documenttype)
  data.append('producttype', doc.producttype)

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, doc.file)
          }
        : undefined
    })
    .then((res) => res.data)
}

const fetchPlansByPlanProviderService = (
  planprovider: IPlanProvider,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetRetirementPlansByProvider?providerid=${planprovider.planproviderid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlanByProvider[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const closeMonthService = (period: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/CloseMonth`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = {
    period,
    productcode: 'RET',
    isclosed: true
  }

  return axios
    .post<void>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchRetirementPlanReportService = (
  period: string,
  producttype: number,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/RetirementPlanReport?producttypeid=${producttype}&monthyear=${period}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRetirementPlanReport[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updatePlanByproviderService = (
  plan: IPlanByProvider,
  provider: IPlanProvider,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/InsertUpdateRetirementPlan`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = {
    ...plan,
    planproviderid: provider.planproviderid
  }

  return axios
    .post<void>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchLookUpValuesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Retirement/GetMasterLookup`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ILookupFieldsResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export {
  fetchLookUpValuesService,
  fetchRetirementProductPlansService,
  fetchRetirementPlanTransactionsService,
  updateRetirementCashReceiptsService,
  fetchRetirementPlanTransactionsByClientService,
  fetchProjectedAmountByPlanIdService,
  updateProjectedAmountByPlanService,
  downloadD365ExcelService,
  fetchPlanProvidersService,
  insertUpdatePlanProviderService,
  viewStatementService,
  fetchPlansToDistributeCashService,
  updateCashReceiptToPlansService,
  uploadProductDocumentService,
  fetchPlansByPlanProviderService,
  closeMonthService,
  fetchRetirementPlanReportService,
  updatePlanByproviderService,
  fetchRetirementPlanAllocationsService
}
