import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ExpandedState,
  GroupingState,
  SortingState,
  Updater,
  VisibilityState
} from '@tanstack/react-table'
import { isFunction } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export const investmentsTableColumnNames = {
  accountNumber: 'accountNumber',
  nickname: 'nickname',
  assetClass: 'assetClass',
  productType: 'productType',
  secid: 'secid',
  description: 'Symbol / Description',
  quantity: 'Quantity',
  price: 'Price',
  priceChange: 'Price Change',
  value: 'Value',
  dayChange: 'Day Change',
  unrealized: 'Unrealized Gain/Loss',
  unitCost: 'Unit Cost',
  costBasis: 'Cost Basis',
  yield: 'Yield',
  accruedIncome: 'Accrued Income',
  asOf: 'As Of'
}

export type InvestmentsTableColumnNames =
  keyof typeof investmentsTableColumnNames

export interface IInvestmentsTableState {
  sorting?: SortingState
  grouping?: GroupingState
  expanded?: ExpandedState
  defaultExpanded?: ExpandedState
  visibility?: VisibilityState
}

const initialState = {
  sorting: [{ id: investmentsTableColumnNames.value, desc: true }],
  grouping: [
    investmentsTableColumnNames.accountNumber,
    investmentsTableColumnNames.assetClass
  ],
  expanded: {},
  defaultExpanded: undefined,
  visibility: {
    [investmentsTableColumnNames.accountNumber]: false,
    [investmentsTableColumnNames.nickname]: false,
    [investmentsTableColumnNames.assetClass]: false,
    [investmentsTableColumnNames.productType]: false,
    [investmentsTableColumnNames.secid]: false
  }
} as IInvestmentsTableState

export const {
  actions: investmentsTableActions,
  reducer: investmentsTableReducer
} = createSlice({
  name: '@modules/@rdot360/@modules/@investments/@features/@investmentsTable',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SortingState | undefined>) => ({
      ...state,
      sorting: action.payload
    }),
    setGrouping: (state, action: PayloadAction<GroupingState | undefined>) => ({
      ...state,
      grouping: action.payload
    }),
    setExpanded: (state, action: PayloadAction<ExpandedState | undefined>) => ({
      ...state,
      expanded: action.payload
    }),
    setDefaultExpanded: (
      state,
      action: PayloadAction<ExpandedState | undefined>
    ) => ({
      ...state,
      defaultExpanded: action.payload
    }),
    setVisibility: (
      state,
      action: PayloadAction<VisibilityState | undefined>
    ) => ({
      ...state,
      visibility: action.payload
    })
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.investments.features
    .investmentsTable

export const useInvestmentsTableStore = () => {
  const dispatch = useDispatch()
  const { sorting, grouping, visibility, expanded, defaultExpanded } =
    useSelector(rootSelector)

  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting || []) : updater
      dispatch(investmentsTableActions.setSorting(value))
    },
    [dispatch, sorting]
  )
  const setGrouping = useCallback(
    (updater: Updater<GroupingState>) => {
      const value = isFunction(updater) ? updater(grouping || []) : updater
      dispatch(investmentsTableActions.setGrouping(value))
    },
    [dispatch, grouping]
  )
  const setVisibility = useCallback(
    (updater: Updater<VisibilityState>) => {
      const value = isFunction(updater) ? updater(visibility || {}) : updater
      dispatch(investmentsTableActions.setVisibility(value))
    },
    [dispatch, visibility]
  )
  const setExpanded = useCallback(
    (updater: Updater<ExpandedState>) => {
      const value = isFunction(updater) ? updater(expanded || {}) : updater
      dispatch(investmentsTableActions.setExpanded(value))
    },
    [dispatch, expanded]
  )
  const setDefaultExpanded = useCallback(
    (value?: ExpandedState) => {
      dispatch(investmentsTableActions.setDefaultExpanded(value))
    },
    [dispatch]
  )

  return {
    sorting,
    setSorting,
    grouping,
    setGrouping,
    visibility,
    setVisibility,
    expanded,
    setExpanded,
    defaultExpanded,
    setDefaultExpanded
  }
}
