import { constants as advisoryConstants } from 'modules/Advisory/shared/theme'

const blueBarHeight = 128
const headerOffset = advisoryConstants.headerHeight + blueBarHeight
export const constants = {
  ...advisoryConstants,
  blueBarHeight,
  blueBarHeightPx: `${blueBarHeight}px`,
  headerOffset,
  headerOffsetPx: `${headerOffset}px`
}
