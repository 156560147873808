import { isEqual, trim } from 'lodash'

export const makeArrayFromString = (
  obj: string[] | string = [],
  delimiter = ','
) =>
  obj == null
    ? []
    : typeof obj === 'string'
    ? obj.split(delimiter).map(trim).filter(notNullOrEmpty)
    : obj

export const stringArraysAreEqual = (a: string[] = [], b: string[] = []) =>
  isEqual([...a].sort(), [...b].sort())

export const notNullOrEmpty = (x: string | undefined): x is string => {
  return x != null && x !== ''
}
