import axios, { AxiosError } from 'axios'
import { isNotNullOrEmpty } from 'shared/gaurds'
import { getStorageSettings } from './settings'
import { trackException } from './telemetry'

const registerExceptionInterceptor = () =>
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (axios.isCancel(error)) {
        return
      }

      let props = {}

      if ((error as AxiosError)?.isAxiosError !== undefined) {
        const axiosError = error as AxiosError
        props = {
          ...props,
          url: axiosError?.config ? axios.getUri(axiosError.config) : '',
          method: axiosError?.config?.method
        }
      }

      trackException(error, {
        source: 'axios-exception-interceptor',
        ...props
      })
      return Promise.reject(error)
    }
  )

const registerUseCdmV2SearchIndexesInterceptor = () => {
  const indexMap: Record<string, string> = {
    accounts: 'account-index-v1-swift',
    advisors: 'advisor-index-v1-swift',
    clients: 'client-index-v1-swift',
    households: 'household-index-v1-swift',
    positions: 'position-index-v1-swift'
  }

  return axios.interceptors.request.use((config) => {
    const useCdmV2SearchIndexes =
      getStorageSettings()?.useCdmV2SearchIndexes || false
    if (!useCdmV2SearchIndexes || !config || !config.headers) {
      return config
    }

    const url = axios.getUri(config)
    const parsed = new URL(url)
    const path = parsed.pathname?.toLowerCase()
    const [api, controller, index] = path.split('/').filter(isNotNullOrEmpty)
    if (
      api !== 'datahub' ||
      controller !== 'search' ||
      !index ||
      !indexMap[index]
    ) {
      return config
    }

    config.headers['datahub-api-index-name'] = indexMap[index]

    return config
  })
}

const registerAddVersionHeaderForRockcoApisInterceptor = () => {
  return axios.interceptors.request.use((config) => {
    const addVersionHeaderForRockcoApis =
      getStorageSettings()?.addVersionHeaderForRockcoApis || false
    if (!addVersionHeaderForRockcoApis || !config || !config.headers) {
      return config
    }

    const url = axios.getUri(config)
    const parsed = new URL(url)
    if (!parsed?.hostname?.toLowerCase()?.endsWith('rockco.com')) {
      return config
    }

    config.headers['version'] = 'v1'

    return config
  })
}

export const registerInterceptors = () => {
  registerAddVersionHeaderForRockcoApisInterceptor()
  registerExceptionInterceptor()
  registerUseCdmV2SearchIndexesInterceptor()
}
