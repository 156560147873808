import { css, Theme, useTheme } from '@emotion/react'
import React, { FC, ReactNode, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { TodaysChange } from '../../shared/TodaysChange'
import { amountFormatter } from '../shared/Formatters'
import { IndeterminateProgressIndicator } from '../shared/ProgressIndicator/IndeterminateProgressIndicator'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps } from '../TileHeading'
import useBalanceSummary from './useBlalanceSummary'

export const getBalanceSummaryClasses = (theme: Theme) => ({
  summaryHeader: css({
    fontSize: theme.size.md,
    fontWeight: 500,
    textTransform: 'uppercase',
    height: '32px'
  }),
  summaryTotal: css({
    fontSize: theme.size.lg1,
    alignSelf: 'flex-end'
  }),
  dottedLine: css({
    border: 'none',
    borderTop: `1px dotted ${theme.colors.extraGray1}`,
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryWhite,
    height: '1px'
  }),
  partitionLine: css({
    border: 'none',
    borderTop: `2px solid ${theme.colors.extraGray1}`,
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryWhite,
    height: '1px'
  }),
  dataLabel: css({
    width: '45%',
    fontSize: theme.size.sm
  }),
  amount: css({
    width: '30%',
    fontSize: theme.size.sm,
    textAlign: 'right'
  }),

  modifyLabel: css({
    color: theme.colors.tertiaryBlue1,
    textDecoration: 'underline',
    cursor: 'pointer'
  }),
  change: css({
    width: '27%',
    textAlign: 'right',
    fontSize: theme.size.sm
  }),
  positiveChange: css({
    color: theme.colors.extraGreen1
  }),
  red: css({
    color: theme.colors.secondaryRed2
  }),
  loader: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  totalChange: css({
    marginBottom: 8,
    color: theme.colors.extraGreen1
  }),
  headerRow: css({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '0 6px',
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: 6
  }),
  boldText: css({
    fontWeight: 'bold'
  }),
  totalRow: css({
    display: 'flex',
    margin: '8px 0',
    backgroundColor: '#eff3f6',
    padding: '8px 6px'
  }),
  extraGreen1: css({
    color: theme.colors.extraGreen1
  }),
  subRowContainer: css({
    display: 'flex',
    padding: '0 6px ',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })
})

const valueFormatter = (value?: number, fractionDigits = 0): ReactNode => {
  return value ? (
    <>
      {value >= 0 ? '+' : ''}
      <FormattedNumber
        value={value}
        style="currency"
        currency="USD"
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
      />
    </>
  ) : (
    '--'
  )
}

const BalanceSummary: FC<BaseHeaderProps> = ({ onExpand }) => {
  const theme = useTheme()
  const classes = useMemo(() => getBalanceSummaryClasses(theme), [theme])

  const {
    totalBalanceSummary,
    balanceSummaryData,
    isBalanceSummaryLoading,
    isBalanceSummaryError,
    balanceSummaryRefetch
  } = useBalanceSummary()

  const balanceSummaryError = useMemo(
    () => isBalanceSummaryError,
    [isBalanceSummaryError]
  )
  const tableErrorMessage =
    (isBalanceSummaryError as Error)?.message || 'An unknown Error Occurred'

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div css={{ flexGrow: 1 }}>
        <>
          <TileHeading onExpand={onExpand}>Balances</TileHeading>
          {balanceSummaryError && (
            <div css={{ paddingBottom: '5px' }}>
              <SnackBar
                message={tableErrorMessage}
                type="Failure"
                actionButtonLabel="click to retry"
                onActionButtonClicked={balanceSummaryRefetch}
              />
            </div>
          )}
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <div css={classes.headerRow}>
                <div css={[classes.amount, classes.boldText]}>
                  {"Day's change"}
                </div>
                <div css={[classes.amount, classes.boldText]}>Amount</div>
              </div>
              <hr css={classes.dottedLine} />
            </div>

            {balanceSummaryData.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <div>
                    {item.label === 'Available to Withdraw' ? (
                      <div css={classes.totalRow}>
                        <div css={[classes.dataLabel, classes.boldText]}>
                          Total
                        </div>
                        <div css={classes.amount}>
                          {amountFormatter(totalBalanceSummary.amount)}
                        </div>
                        <div
                          css={[
                            classes.change,
                            totalBalanceSummary.change > 0 &&
                              classes.extraGreen1,
                            { display: 'flex', justifyContent: 'flex-end' }
                          ]}
                        >
                          {!!totalBalanceSummary.change && <TodaysChange />}
                          {valueFormatter(totalBalanceSummary.change)}
                        </div>
                      </div>
                    ) : (
                      <hr css={classes.dottedLine} />
                    )}
                  </div>
                )}
                <div css={classes.subRowContainer}>
                  <div css={classes.dataLabel}>{item.label}</div>
                  <div css={[classes.amount]}>
                    {amountFormatter(item.amount)}
                  </div>
                  {item?.change === undefined ? (
                    <div css={[classes.change]} />
                  ) : (
                    <div
                      css={[
                        classes.change,
                        !item.noStyle &&
                          item.change > 0 &&
                          classes.positiveChange
                      ]}
                    >
                      {item.noStyle
                        ? amountFormatter(item.change)
                        : valueFormatter(item.change)}
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </>
      </div>
      <div
        css={{
          fontSize: '10px',
          fontWeight: 300,
          marginTop: '6px'
        }}
      >
        Cash excludes Money Market Funds, which are currently included in Priced
        Investments.
      </div>
      {isBalanceSummaryLoading && (
        <div css={classes.loader}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </div>
  )
}

export default BalanceSummary
