import { css, useTheme } from '@emotion/react'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { groupBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { Dropdown, ISelectableOption } from '../../components/shared'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import { yearRangeOptions } from '../../components/shared/filterOptions'
import { Icon } from '../../features/Icons/Icon'
import {
  useFinancialsApiUtil,
  useGetRealizedGainLossSecurityQuery
} from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import GainLossesDetailedViewTable from './feature/GainAndLossesDetail/GainLossesDetailViewTable'

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: 20
  }),
  iconStyle: css({
    width: 24,
    height: 46
  })
})
const GainLossesIncomeDetail: React.FC = () => {
  const [searchText, setSearchText] = useState('')

  const onSearchInputChange = (value?: string) => {
    setSearchText(value || '')
  }
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const [gridData, setGridData] = useState<any>([])

  const [financialYear, setFinancialYear] = useState<any>(
    yearRangeOptions[0].key
  )

  const onDateRangeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ISelectableOption<any>
  ) => {
    if (option) {
      setFinancialYear(option.key)
    }
  }

  const {
    data: gainLossesIncomeData,
    isLoading,
    isFetching,
    isUninitialized
  } = useGetRealizedGainLossSecurityQuery(
    !apiContextAccounts || apiContextAccounts.length === 0
      ? skipToken
      : { financialYear, accounts: apiContextAccounts }
  )

  useEffect(() => {
    const detailList: any = []
    let groupedDetailedList: any = []
    gainLossesIncomeData?.ITEMSUMMARY?.forEach((val) => {
      val.DETAILSUMMARY?.forEach((val2) => {
        val2.DETAIL?.forEach((val3) => {
          detailList.push(val3)
        })
      })
    })

    groupedDetailedList = groupBy(detailList, (val) => val.SECDESCRIPTION)
    const uniqueSecurityList = [
      ...new Set<string>(
        detailList?.map(
          (val: any): string => `${val.SECDESCRIPTION}:${val.SECNAME}`
        )
      )
    ].map((val: string) => {
      const securityNameParts = val.split(':')
      return {
        description: securityNameParts[0],
        secName: securityNameParts[1]
      }
    })

    const arrayList: any = []
    uniqueSecurityList?.forEach((val: any) => {
      const securityAccountList: any = getAccountGroupingData(
        groupedDetailedList,
        val
      )

      let quantity = 0
      let acquisitionCost = 0
      let liquidationAmount = 0
      let shortTermGainLoss = 0
      let longTermGainLoss = 0
      let totalGainLoss = 0

      securityAccountList?.forEach((val: any) => {
        quantity = quantity + val.quantity
        acquisitionCost = acquisitionCost + val.acquisitionCost
        liquidationAmount = liquidationAmount + val.liquidationAmount
        shortTermGainLoss = shortTermGainLoss + val.shortTermGainLoss
        longTermGainLoss = longTermGainLoss + val.longTermGainLoss
        totalGainLoss = totalGainLoss + val.totalGainLoss
      })

      arrayList.push({
        description: val.description,
        secName: val.secName,
        accounts: calculateGroupedAccountTotal(securityAccountList, val),
        quantity: quantity,
        acquisitionDate: null,
        acquisitionPrice: null,
        acquisitionCost: acquisitionCost,
        liquidationDate: null,
        liquidationPrice: null,
        liquidationAmount: liquidationAmount,
        shortTermGainLoss: shortTermGainLoss,
        longTermGainLoss: longTermGainLoss,
        totalGainLoss: totalGainLoss
      })
    })

    let shortTermGainLossOverallTotal = 0
    let longTermGainLossOverallTotal = 0
    let totalGainLossOverallTotal = 0
    arrayList?.forEach((record: any) => {
      shortTermGainLossOverallTotal =
        shortTermGainLossOverallTotal + record.shortTermGainLoss
      longTermGainLossOverallTotal =
        longTermGainLossOverallTotal + record.longTermGainLoss
      totalGainLossOverallTotal =
        totalGainLossOverallTotal + record.totalGainLoss
    })

    setGridData(arrayList)
  }, [gainLossesIncomeData])

  const getAccountGroupingData = (
    groupData: any,
    account: { description: string; secName: string }
  ) => {
    const securityAccountList: any = groupData[account.description]

    const groupedDataList =
      securityAccountList?.map((data: any) => ({
        description: account.description,
        secName: account.secName,
        quantity: data.NUMSHRS,
        acquisitionDate: data.DTAQD,
        acquisitionPrice: data.COSTBASIS / data.NUMSHRS,
        acquisitionCost: data.COSTBASIS,
        liquidationDate: data.DTSALE,
        liquidationPrice: undefined,
        liquidationAmount: data.STKBNDAMT,
        shortTermGainLoss: data.SHRTGAINLOSS,
        longTermGainLoss: data.LNGGAINLOSS,
        totalGainLoss: data.PROFIT,
        groupedAccountName: data.ACCT
      })) || []

    return groupedDataList
  }

  const calculateGroupedAccountTotal = (
    data: any,
    account: { description: string; secName: string }
  ) => {
    const uniqueAccountList = [
      ...new Set(data.map((val: any) => val.groupedAccountName))
    ]
    const updatedAccountList: any = []

    uniqueAccountList?.forEach((accountName: any) => {
      const selectedAccountList = data.filter(
        (value: any) => value.groupedAccountName === accountName
      )
      if (selectedAccountList.length > 0) {
        let totalQuantity = 0
        let totalAcquisitionCost = 0
        let totalLiquidationAmount = 0
        let totalGainLoss = 0
        let shortTermGainLoss = 0
        let longTermGainLoss = 0

        selectedAccountList?.forEach((value: any) => {
          totalQuantity = totalQuantity + value.quantity
          totalAcquisitionCost = totalAcquisitionCost + value.acquisitionCost
          totalLiquidationAmount =
            totalLiquidationAmount + value.liquidationAmount
          totalGainLoss = totalGainLoss + value.totalGainLoss
          shortTermGainLoss = shortTermGainLoss + value.shortTermGainLoss
          longTermGainLoss = longTermGainLoss + value.longTermGainLoss
          value.account = accountName
        })

        updatedAccountList.push({
          description: account.description,
          secName: account.secName,
          account: accountName,
          accounts: selectedAccountList,
          quantity: totalQuantity,
          acquisitionDate: null,
          acquisitionPrice: null,
          acquisitionCost: totalAcquisitionCost,
          liquidationDate: null,
          liquidationPrice: null,
          liquidationAmount: totalLiquidationAmount,
          shortTermGainLoss: shortTermGainLoss,
          longTermGainLoss: longTermGainLoss,
          totalGainLoss: totalGainLoss,
          groupedAccountName: accountName
        })
      }
    })
    return updatedAccountList
  }
  const classes = useMemo(() => getClasses(), [])
  const { invalidateTags } = useFinancialsApiUtil()
  const theme = useTheme()

  return (
    <>
      <div css={classes.container}>
        <DetailsNavigator />
        <div css={{ width: 160 }}>
          <div css={{ fontSize: 14, fontWeight: 500 }}>Year</div>
          <Dropdown
            options={yearRangeOptions}
            defaultSelectedOption={yearRangeOptions.find(
              (item) => item.key === financialYear
            )}
            onChange={onDateRangeChange}
          />
        </div>
        <div css={{ marginLeft: 'auto' }}>
          <Searchbox searchText={searchText} onChange={onSearchInputChange} />
        </div>
        <div
          css={{ width: 24, height: 46, display: 'flex', alignItems: 'center' }}
        >
          <Icon
            type="Refresh"
            width={24}
            height={24}
            onClick={() => invalidateTags(['gainandlosss'])}
            color={theme.colors.extraBlue2}
          />
        </div>
      </div>
      <div css={{ marginTop: 24 }}>
        <HorizontalScrollContainer>
          <GainLossesDetailedViewTable
            isLoading={isLoading || isFetching}
            isUninitialized={isUninitialized}
            data={gridData}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </HorizontalScrollContainer>
      </div>
    </>
  )
}

export default GainLossesIncomeDetail
