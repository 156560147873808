import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { getRdotUserRoles } from 'store/user/selectors'
import { ISearchResult, SearchResponseType } from '../../../../api/common.types'
import { AppState } from '../../../../store'
import { IColumnState } from '../contracts/IColumnState'
import { IListsUiState } from '../contracts/IListsUIState'
import {
  IListsDataState,
  IListsExportState,
  IListsFacetState,
  IListsState
} from './reducers'

const empty = [] as IColumnState[]

export interface IListsDataSelectors<T extends SearchResponseType> {
  getIsLoading: (state: AppState) => boolean
  getProgress: (state: AppState) => number
  getChunks: (state: AppState) => ISearchResult<T>[] | undefined
  getTotalCount: (state: AppState) => number
  getError: (state: AppState) => Error | undefined
}

export const createListsDataSelectors = <T extends SearchResponseType>(
  rootSelector: (state: AppState) => IListsDataState<T>
): IListsDataSelectors<T> => {
  return {
    getIsLoading: flow(rootSelector, (x) => x.loading || false),
    getProgress: flow(rootSelector, (x) => x.progress || 0),
    getChunks: flow(rootSelector, (x) => x.chunks),
    getTotalCount: flow(rootSelector, (x) => x.totalCount || 0),
    getError: flow(rootSelector, (x) => x.error)
  }
}

export type ListsUiSelectors = ReturnType<typeof createListsUiSelectors>
export const createListsUiSelectors = (
  rootSelector: (state: AppState) => IListsUiState
) => {
  return {
    getUiState: rootSelector,
    getSearchText: flow(rootSelector, (x) => x.searchText),
    getColumnDefinitions: createSelector(
      [getRdotUserRoles, flow(rootSelector, (x) => x.columnDefinitions)],
      (roles, columns) => {
        if (!columns) {
          return
        }

        const filteredColumns = Object.entries(columns)
          .filter(
            ([, item]) =>
              !item.featureFlag ||
              roles?.includes(item.featureFlag as RdotUserRoleEnum)
          )
          .map(([, value]) => value)

        return keyBy(filteredColumns, ({ id }) => id)
      }
    ),
    getFilterDefinitions: createSelector(
      [getRdotUserRoles, flow(rootSelector, (x) => x.filterDefinitions)],
      (roles, filters) => {
        if (!filters) {
          return
        }

        const filteredFilters = Object.entries(filters)
          .filter(
            ([, item]) =>
              !item.featureFlag ||
              roles?.includes(item.featureFlag as RdotUserRoleEnum)
          )
          .map(([, value]) => value)

        return keyBy(filteredFilters, ({ id }) => id)
      }
    ),
    getFilters: flow(rootSelector, (x) => x.filters),
    getColumnState: flow(rootSelector, (x) => x.columnState || empty),
    getListSize: flow(rootSelector, (x) => x.listSize),
    getOrderBy: flow(rootSelector, (x) => x.orderBy)
  }
}

export type ListExportSelectors = ReturnType<typeof createListExportSelectors>
export const createListExportSelectors = (
  rootSelector: (state: AppState) => IListsExportState
) => {
  return {
    getIsExportLoading: flow(rootSelector, (x) => x.loading || false),
    getExportProgress: flow(rootSelector, (x) => x.progress || 0),
    getExpoprtTotalCount: flow(rootSelector, (x) => x.totalCount || 0),
    getExportError: flow(rootSelector, (x) => x.error)
  }
}

export type ListsFacetSelectors = ReturnType<typeof createListsFacetSelectors>
export const createListsFacetSelectors = (
  rootSelector: (state: AppState) => IListsFacetState
) => {
  return {
    getFacetItems: flow(rootSelector, (x) => x.items),
    getIsLoading: flow(rootSelector, (x) => x.loading),
    getError: flow(rootSelector, (x) => x.error || [])
  }
}

export interface IListsSelectors<T extends SearchResponseType> {
  uiSelectors: ListsUiSelectors
  dataSelectors: IListsDataSelectors<T>
  facetSelectors: ListsFacetSelectors
  exportSelectors: ListExportSelectors
}

export const createListsSelectors = <T extends SearchResponseType>(
  rootSelector: (state: AppState) => IListsState<T>
): IListsSelectors<T> => {
  const uiSelector = flow(rootSelector, (state) => state.ui)
  const uiSelectors = createListsUiSelectors(uiSelector)

  const dataSelector = flow(rootSelector, (state) => state.data)
  const dataSelectors = createListsDataSelectors<T>(dataSelector)

  const facetSelector = flow(rootSelector, (state) => state.facet)
  const facetSelectors = createListsFacetSelectors(facetSelector)

  const exportSelector = flow(rootSelector, (state) => state.export)
  const exportSelectors = createListExportSelectors(exportSelector)

  return {
    uiSelectors,
    dataSelectors,
    facetSelectors,
    exportSelectors
  }
}
