import { TooltipHost } from '@fluentui/react'
import {
  CellContext,
  ColumnDef,
  HeaderContext,
  Row
} from '@tanstack/react-table'
import { IAccount } from 'api/account.types'
import { sum, sumBy } from 'lodash'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import {
  Icon,
  IconType
} from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { TodaysChange } from 'modules/Advisory/modules/Rdot360/shared/TodaysChange'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import { useRdot360AccountContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360AccountContext'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { ChangeCell } from '../../shared/ChangeCell'
import { investmentsTableColumnNames, useInvestmentsTableStore } from './store'

const DescriptionCellWrapper: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { searchText } = useInvestmentsDetailsUIState()
  const { row, cell } = props
  const { grouping = [] } = useInvestmentsTableStore()
  const isGrouped = row.getIsGrouped()
  const isExpanded = row.getIsExpanded()
  const canExpand = row.getCanExpand()
  const isSelected = row.getIsSelected()
  const isSecurityRow =
    row.groupingColumnId === investmentsTableColumnNames.secid ||
    (!grouping?.includes(investmentsTableColumnNames.secid) &&
      !row.groupingColumnId)
  const depth = row.depth
  const showAccountGroup =
    !isGrouped && grouping.includes(investmentsTableColumnNames.secid)
  const columnId =
    grouping?.[depth] ||
    (showAccountGroup
      ? investmentsTableColumnNames.accountNumber
      : cell.column.id)

  const { accountLookup } = useRdot360AccountContext()
  const value = row.getValue<string>(columnId)

  const isSymbol =
    columnId === investmentsTableColumnNames.description ||
    columnId === investmentsTableColumnNames.secid

  const isAccount = columnId === investmentsTableColumnNames.accountNumber

  const [expandIcon, collapseIcon]: [IconType, IconType] = isSecurityRow
    ? ['ChevronDown', 'ChevronUp']
    : ['Add', 'Subtract']

  const showExpand = (canExpand && !isExpanded) || (!canExpand && !isSelected)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{ cursor: 'pointer', width: '30px', minWidth: '30px' }}
        onClick={() =>
          row.groupingColumnId ? row.toggleExpanded() : row.toggleSelected()
        }
      >
        <Icon
          type={showExpand ? expandIcon : collapseIcon}
          height={12}
          width={12}
          color="black"
        />
      </div>
      <div css={{ flexGrow: 1, display: 'flex', minWidth: 0 }}>
        {isSymbol && <SymbolCell {...props} />}
        {isAccount && (
          <AccountCell
            account={value}
            contextAccount={accountLookup[value]}
            search={searchText}
          />
        )}
        {!isSymbol && !isAccount && <>{value}</>}
        {!isSymbol && !isAccount && isGrouped && (
          <div style={{ marginLeft: '5px' }}>({row.subRows.length})</div>
        )}
      </div>
    </div>
  )
}

const AccountCell: React.FC<{
  account: string
  contextAccount?: IAccount
  search?: string
}> = ({ account, contextAccount, search }) => {
  const { mask } = useMasking()
  const nicknameWithMask = mask(
    contextAccount?.AccountNickname || contextAccount?.Shortname,
    'nickname'
  )
  const accountNumberWithMask = mask(account, 'account')
  const title = [nicknameWithMask, accountNumberWithMask].join(' - ')
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      title={title}
    >
      <span>
        <HighlightSearchText
          text={nicknameWithMask || accountNumberWithMask}
          search={search}
        />
      </span>
      {nicknameWithMask && (
        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
          <HighlightSearchText text={accountNumberWithMask} search={search} />
        </span>
      )}
    </div>
  )
}

const SymbolCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row
}) => {
  const { searchText } = useInvestmentsDetailsUIState()
  const description = row.original.secinfo?.secname || ''

  return (
    <div css={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
      <div>
        <HighlightSearchText
          text={row.original.secinfo?.secid || ''}
          search={searchText}
        />
      </div>
      <div
        title={description}
        css={[rdot360TableStyles.nowrap, { flexGrow: 1 }]}
      >
        <HighlightSearchText text={description} search={searchText} />
      </div>
    </div>
  )
}

const canAggregate = (columnId: string, row: Row<ICategoryPosition>) =>
  row.groupingColumnId === investmentsTableColumnNames.secid ||
  !row.groupingColumnId ||
  [
    investmentsTableColumnNames.value,
    investmentsTableColumnNames.dayChange,
    investmentsTableColumnNames.unrealized,
    investmentsTableColumnNames.costBasis
  ].includes(columnId)

const AggregatedNumberCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { column, row } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <FormattedNumberCell {...props} />
}

const AggregatedPercentCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { column, row } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <FormattedPercentCell {...props} />
}

const AggregatedUSDCell: React.FC<CellContext<ICategoryPosition, unknown>> = (
  props
) => {
  const { row, column } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <USDCell {...props} />
}

const FormattedNumberCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  const fractionDigits = value ? 2 : 0
  return value != null ? (
    <FormattedNumber
      maximumFractionDigits={fractionDigits}
      minimumFractionDigits={fractionDigits}
      value={value}
    />
  ) : (
    <>--</>
  )
}

const FormattedPercentCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  const fractionDigits = value ? 2 : 0
  return value != null ? (
    <>
      <FormattedNumber
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
        value={value}
      />
      %
    </>
  ) : (
    <>--</>
  )
}

const AggregatedPriceChangeCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { row } = props
  const isValid =
    row.groupingColumnId === investmentsTableColumnNames.secid ||
    !row.groupingColumnId

  if (!isValid) {
    return null
  }

  return <ChangeCell {...props} />
}

const USDCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return value ? <USD value={value} currencySign="standard" /> : <>--</>
}

// const DateCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
//   getValue
// }) => {
//   const value = getValue<string>()
//   const date = new Date(value)
//   return isValid(date) ? (
//     <div>
//       <FormattedDate
//         day="2-digit"
//         month="2-digit"
//         year="numeric"
//         value={date}
//       />

//       <div>
//         <FormattedTime value={date} />
//       </div>
//     </div>
//   ) : (
//     <>--</>
//   )
// }

const UnrealizedCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row,
  getValue
}) => {
  const unrealized = getValue<number>() || 0
  const costbasis = row.getValue<number>(investmentsTableColumnNames.costBasis)

  const percent = costbasis && (unrealized / costbasis) * 100
  const isPositive = unrealized > 0
  const fractionDigits = unrealized ? 2 : 0

  return (
    <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
      <USD value={unrealized} currencySign="standard" />
      <span style={{ color: isPositive ? '#008000' : undefined }}>
        <FormattedNumber
          maximumFractionDigits={fractionDigits}
          minimumFractionDigits={fractionDigits}
          value={percent}
        />
        %
      </span>
    </div>
  )
}

const SumFooter: React.FC<HeaderContext<ICategoryPosition, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USD value={total} fractionDigits={2} currencySign={'standard'} />
}

export const getInvestmentsTableColumnDefs = (accountLookup: {
  [id: string]: IAccount | undefined
}): ColumnDef<ICategoryPosition>[] => [
  {
    id: investmentsTableColumnNames.accountNumber,
    header: investmentsTableColumnNames.accountNumber,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.acctnum
  },
  {
    id: investmentsTableColumnNames.nickname,
    header: investmentsTableColumnNames.nickname,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) =>
      accountLookup?.[item.acctnum || '']?.AccountNickname ||
      accountLookup?.[item.acctnum || '']?.Shortname
  },
  {
    id: investmentsTableColumnNames.assetClass,
    header: investmentsTableColumnNames.assetClass,
    enableGlobalFilter: false,
    aggregationFn: 'unique',
    accessorFn: (item) =>
      item.secinfo?.assetcat === 'Cash'
        ? 'Cash and Equivalents'
        : item.secinfo?.assetcat
  },
  {
    id: investmentsTableColumnNames.productType,
    header: investmentsTableColumnNames.productType,
    enableGlobalFilter: false,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.assetcat
  },
  {
    id: investmentsTableColumnNames.secid,
    header: investmentsTableColumnNames.secid,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.secid
  },
  {
    id: investmentsTableColumnNames.description,
    header: investmentsTableColumnNames.description,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.secname || item.secinfo?.secid,
    cell: DescriptionCellWrapper,
    aggregatedCell: DescriptionCellWrapper,
    size: 350,
    minSize: 50,
    footer: 'Total'
  },
  {
    id: investmentsTableColumnNames.quantity,
    header: investmentsTableColumnNames.quantity,
    enableGlobalFilter: false,
    aggregationFn: 'sum',
    accessorFn: (item) => item.shrqnts?.unit,
    cell: FormattedNumberCell,
    aggregatedCell: AggregatedNumberCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.price,
    header: investmentsTableColumnNames.price,
    enableGlobalFilter: false,
    aggregationFn: 'min',
    accessorFn: (item) => item.pricing?.rcntprice,
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.priceChange,
    header: investmentsTableColumnNames.priceChange,
    enableGlobalFilter: false,
    accessorFn: (item) =>
      item.pricing?.pricechg ||
      (item.pricing?.rcntprice || 0) - (item.pricing?.clsprice || 0),
    cell: ChangeCell,
    aggregationFn: 'min',
    aggregatedCell: AggregatedPriceChangeCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.value,
    header: investmentsTableColumnNames.value,
    enableGlobalFilter: false,
    accessorFn: (item) => item.mktvals?.rcntmktval,
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 170,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.dayChange,
    header: investmentsTableColumnNames.dayChange,
    enableGlobalFilter: false,
    accessorFn: (item) => item.mktvals?.mktvalchange,
    cell: ({ getValue }) => {
      const value = getValue<number>()
      return (
        <>
          {value != null ? (
            <div
              css={{
                color: value > 0 ? '#008000' : '',
                display: 'inline-flex'
              }}
            >
              <TodaysChange />
              <USD value={value} currencySign={'standard'} />
            </div>
          ) : (
            '--'
          )}
        </>
      )
    },
    aggregationFn: 'sum',
    aggregatedCell: ChangeCell,
    size: 100,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.unrealized,
    header: investmentsTableColumnNames.unrealized,
    size: 150,
    minSize: 50,
    enableGlobalFilter: false,
    accessorFn: (item) => item.unrealizedValue,
    cell: UnrealizedCell,
    aggregationFn: 'sum',
    aggregatedCell: UnrealizedCell,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.unitCost,
    header: investmentsTableColumnNames.unitCost,
    enableGlobalFilter: false,
    accessorFn: (item) => item.costbasis?.avgcostpershare,
    aggregationFn: (_columnId, leafRows) => {
      const costBasis = sumBy(
        leafRows,
        ({ getValue }) =>
          getValue<number | undefined>(investmentsTableColumnNames.costBasis) ||
          0
      )

      const quantity = sumBy(
        leafRows,
        ({ getValue }) =>
          getValue<number | undefined>(investmentsTableColumnNames.quantity) ||
          0
      )

      return costBasis && costBasis / quantity
    },
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.costBasis,
    header: investmentsTableColumnNames.costBasis,
    aggregationFn: 'sum',
    enableGlobalFilter: false,
    accessorFn: (item) => item.costbasis?.costbasis,
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 150,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.yield,
    header: investmentsTableColumnNames.yield,
    aggregationFn: 'min',
    enableGlobalFilter: false,
    accessorFn: (item) => item.secinfo?.yieldPct,
    cell: FormattedPercentCell,
    aggregatedCell: AggregatedPercentCell,
    size: 80,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.accruedIncome,
    header: () => {
      return (
        <TooltipHost content="Coming Soon">
          {investmentsTableColumnNames.accruedIncome}
        </TooltipHost>
      )
    },
    enableGlobalFilter: false,
    accessorFn: () => undefined,
    cell: () => null,
    aggregatedCell: () => null,
    size: 100,
    minSize: 50
  }
  // {
  //   id: investmentsTableColumnNames.asOf,
  //   header: () => {
  //     return (
  //       <TooltipHost content="Coming Soon">
  //         {investmentsTableColumnNames.asOf}
  //       </TooltipHost>
  //     )
  //   },
  //   enableGlobalFilter: false,
  //   accessorFn: () => undefined,
  //   cell: () => null,
  //   aggregatedCell: () => null,
  //   size: 100,
  //   minSize: 50
  // }
]
