import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { ICustomAccountGroup } from '../../store/dynamics'

export interface IDeleteCustomGroupState {
  groupToDelete?: ICustomAccountGroup
}

const initialState = {
  groupToDelete: undefined
} as IDeleteCustomGroupState

const deleteCustomGroupSlice = createSlice({
  name: '@modules/@rdot360/@features/@accountSelector',
  initialState,
  reducers: {
    setGroupToDelete: (
      state,
      action: PayloadAction<ICustomAccountGroup | undefined>
    ) => ({
      ...state,
      groupToDelete: action.payload
    })
  }
})

export const { reducer: deleteCustomGroupReducer } = deleteCustomGroupSlice
const { actions } = deleteCustomGroupSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.deleteCustomGroup

export const useDeleteCustomGroupStore = () => {
  const dispatch = useDispatch()
  const { groupToDelete } = useSelector(rootSelector)

  const setGroupToDelete = useCallback(
    (group?: ICustomAccountGroup) => {
      dispatch(actions.setGroupToDelete(group))
    },
    [dispatch]
  )
  return {
    groupToDelete,
    setGroupToDelete
  }
}
