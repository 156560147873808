import { USD } from 'shared/components/Formatting'
import { isNotNullOrEmpty } from 'shared/gaurds'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { TodaysChange } from '../../shared/TodaysChange'
import { accountSelectorStyles } from './styles'

export const AccountSelectorAccount: React.FC<{
  checked?: boolean
  onChange: () => void
  nickname?: string
  accountNumber?: string
  registrationDesc?: string
  registrationtype?: string
  balance?: number
  change?: number
}> = ({
  checked,
  onChange,
  nickname,
  accountNumber,
  registrationDesc,
  registrationtype,
  balance,
  change
}) => {
  const { mask } = useMasking()
  const accountNumberWithMask = mask(accountNumber, 'account')
  const nicknameWithMask = mask(nickname, 'nickname')
  return (
    <div css={[accountSelectorStyles.flexRow]}>
      <div style={{ marginLeft: '2px', marginRight: '8px' }}>
        <input
          type="checkbox"
          css={[accountSelectorStyles.checkbox]}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div
        css={[
          accountSelectorStyles.flexColumn,
          accountSelectorStyles.itemTitle
        ]}
      >
        <div
          css={{
            textAlign: 'justify',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all'
          }}
        >
          {nicknameWithMask || accountNumberWithMask}
        </div>
        {!nicknameWithMask && !!registrationDesc && (
          <div>{registrationDesc}</div>
        )}
        {nicknameWithMask && (
          <div>
            {[accountNumberWithMask, registrationtype]
              .filter(isNotNullOrEmpty)
              .join(' | ')}
          </div>
        )}
      </div>
      <div
        css={[
          accountSelectorStyles.flexColumn,
          {
            flexGrow: 1,
            '& > div': { marginLeft: 'auto' },
            paddingLeft: '5px'
          }
        ]}
      >
        <div css={[accountSelectorStyles.itemBalance]}>
          {balance != null ? (
            <USD value={balance} fractionDigits={0} currencySign="standard" />
          ) : (
            <>--</>
          )}
        </div>
        <div
          css={[
            accountSelectorStyles.itemBalanceChange,
            change != null &&
              change > 0 &&
              accountSelectorStyles.positiveBalanceChange,
            { display: 'flex' }
          ]}
        >
          {!!change && <TodaysChange />}
          {change != null ? (
            <USD value={change} fractionDigits={0} currencySign="standard" />
          ) : (
            <>--</>
          )}
        </div>
      </div>
    </div>
  )
}
