import { differenceInMonths, format, startOfMonth, subMonths } from 'date-fns'
import type { Workbook, Fill, Cell } from 'exceljs'
import { find, groupBy, keyBy, range, sum, sumBy } from 'lodash'
import { flow, lowerCase, snakeCase } from 'lodash/fp'
import { all, call, select } from 'typed-redux-saga'
import { IAdvisorDetailResult } from '../../../../../api/advisor.types'
import {
  IAUSHistoryItem,
  IRevenueHistoryItem,
  IWithDate
} from '../../../../../api/common.types'
import { IHousehold } from '../../../../../api/household.types'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../../api/odata'
import { saveBlobAsFile } from '../../../../../shared/downloads'
import {
  boldDarkFont,
  boldLightFont,
  fillDarkBlue,
  fillLightBlue,
  fillLightGreen,
  fillWhite,
  normalDarkFont,
  thinBorder,
  totalBorder
} from '../../../../../shared/export'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../../../../../shared/gaurds'
import { getSelectedDomainContextRepCodes } from '../../../../../store/context/domain'
import {
  getAllPagedOdataApiResults,
  getSearchApiResults,
  IPagedOdataApiResult
} from '../../../../../store/shared/sagas/odata'

const accountingFormat = '_("$"* #,##0_);_("$"* (#,##0);_("$"* "-"??_);_(@_)'
const lowerSnakeCase = flow(lowerCase, snakeCase)

const loadExceljs = async () => {
  const exceljs = await import('exceljs')
  return exceljs
}

export const buildAusRevenueCombinedReport = function* () {
  const exceljs = yield* call(loadExceljs)
  const reps = yield* select(getSelectedDomainContextRepCodes)

  const householdFilter: IOdataCollectionFilter = {
    filter: {
      and: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          value: reps,
          path: 'ClientAdvisorID',
          type: 'string'
        }
      ]
    },
    operator: OdataFilterCollectionOperatorEnum.any,
    path: 'Advisors'
  }

  const advisorFilter: OdataPropertyFilterGroup = {
    and: [
      {
        operator: OdataFilterOperatorEnum.searchin,
        value: reps,
        path: 'ClientAdvisorID',
        type: 'string'
      }
    ]
  }

  const { households, advisors } = yield* all({
    households: call(() =>
      getAllPagedOdataApiResults(
        {
          select: [
            'id',
            'householdId',
            'householdName',
            'householdKPI',
            'revenue',
            'revenueDetHistory'
          ] as (keyof IHousehold)[],
          filters: reps.length ? [householdFilter] : []
        },
        getSearchApiResults<IHousehold>('household')
      )
    ),
    advisors: call(() =>
      getAllPagedOdataApiResults(
        {
          select: [
            'id',
            'ClientAdvisor',
            'ClientAdvisorID',
            'AdvisorKPI',
            'revenueDetHistory'
          ] as (keyof IAdvisorDetailResult)[],
          filters: reps.length ? [advisorFilter] : []
        },
        getSearchApiResults<IAdvisorDetailResult>('advisor')
      )
    )
  })

  const workbook = new exceljs.Workbook()
  addRevenueSheet(workbook, advisors)
  addHouseholdSheet(workbook, households)
  addMonthlySheet(workbook, households)
  advisors
    .flatMap(({ result }) => result?.value)
    .filter(isNotNullOrUndefined)
    .sort(
      (a, b) =>
        (b?.AdvisorKPI?.KPIs?.AumTotal || 0) -
        (a?.AdvisorKPI?.KPIs?.AumTotal || 0)
    )
    .map((advisor) => addAssetsSheet(workbook, advisor))

  const base64 = yield* call(() =>
    workbook.xlsx.writeBuffer({
      base64: true
    } as any)
  )

  const blob = new Blob([base64], { type: 'application/octet-stream' })

  saveBlobAsFile(blob, 'AUS & Revenue.xlsx')
}

const getRevenueSummary = (history: IRevenueHistoryItem[]) => {
  const historyWithDate = history?.map(
    (item): IRevenueHistoryItem & IWithDate => {
      const date = flow(
        startOfMonth,
        (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
      )(new Date(item.revYear || 0, item.revMonth ? item.revMonth - 1 : 0, 1))
      return {
        date,
        timestamp: date.getTime(),
        ...item
      }
    }
  )
  const assetTypeGroups = groupBy(historyWithDate, ({ AssetType }) => AssetType)
  const dateGroups = groupBy(historyWithDate, ({ timestamp }) => timestamp)
  const allDates = Object.keys(dateGroups)
  const resultGroups: Record<string, Record<string, number>> = Object.entries(
    assetTypeGroups
  ).reduce((outer, [assetType, items]) => {
    const dateAssetTypeGroups = groupBy(items, ({ timestamp }) => timestamp)
    const results: Record<string, number> = allDates.reduce((a, timestamp) => {
      const dateTypeGroup = dateAssetTypeGroups[timestamp] || []
      const revenue = sumBy(
        dateTypeGroup,
        ({ revenueDet }) => revenueDet?.compRevenue || 0
      )
      a[timestamp] = revenue
      return a
    }, {} as any)

    outer[assetType] = {
      ...results
    }
    return outer
  }, {} as any)

  return {
    dates: allDates,
    assetTypes: Object.keys(assetTypeGroups),
    groups: resultGroups
  }
}

const addRevenueSheet = (
  workbook: Workbook,
  advisors: IPagedOdataApiResult<IAdvisorDetailResult>[]
) => {
  const revenue = advisors
    ?.flatMap(({ result }) => result?.value)
    .filter(isNotNullOrUndefined)
    .flatMap(({ revenueDetHistory }) => revenueDetHistory)
    .filter(isNotNullOrUndefined)

  const revenueSheet = workbook.addWorksheet('Revenue', {
    views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }]
  })

  const revenueSummary = getRevenueSummary(revenue)
  revenueSheet.columns = [
    { header: 'Revenue Type', key: 'Revenue Type', width: 25 },
    ...revenueSummary.dates
      .map((date) => parseInt(date, 10))
      .sort((a, b) => b - a)
      .map((date) => ({
        header: format(date, `MMM ''yy`),
        key: date?.toString(),
        width: 15,
        style: { numFmt: accountingFormat }
      }))
  ]

  revenueSheet.autoFilter = {
    from: {
      row: 1,
      column: 1
    },
    to: {
      row: 1,
      column: revenueSheet.columns.length
    }
  }

  Object.entries(revenueSummary.groups)
    .sort(([, a], [, b]) => sum(Object.values(a)) - sum(Object.values(b)))
    .map(([key, value]) => ({
      'Revenue Type': key,
      ...value
    }))
    .forEach((item) => {
      const row = revenueSheet.addRow(item)
      row.eachCell((cell) => {
        cell.fill = fillLightBlue
        cell.border = thinBorder
        cell.font = normalDarkFont
      })
    })

  const totalRow = revenueSheet.addRow([
    'Total',
    ...revenueSummary.dates.map(() => '')
  ])

  const annualizedRow = revenueSheet.addRow([
    'Annualized',
    ...revenueSummary.dates.map(() => '')
  ])

  revenueSheet.getColumn('A').eachCell((cell) => {
    cell.fill = fillWhite
    cell.font = boldDarkFont
    cell.border = thinBorder
    cell.alignment = { horizontal: 'right' }
  })

  revenueSheet.getRow(1).eachCell((cell) => {
    cell.fill = fillDarkBlue
    cell.font = boldLightFont
    cell.border = thinBorder
  })

  totalRow.eachCell((cell, i) => {
    if (i === 1) {
      cell.border = totalBorder
      return
    }
    const [col] = cell.$col$row.split('$').filter(isNotNullOrEmpty)
    cell.value = {
      formula: `sum(${col}2:${col}${revenueSummary.assetTypes.length + 1})`
    } as any

    cell.border = totalBorder
    cell.font = boldDarkFont
  })

  annualizedRow.eachCell((cell, i) => {
    if (i === 1) {
      cell.fill = fillDarkBlue
      cell.font = boldLightFont
      cell.border = thinBorder
      return
    }
    const [col] = cell.$col$row.split('$').filter(isNotNullOrEmpty)
    cell.value = {
      formula: `sum(${col}2:${col}${revenueSummary.assetTypes.length + 1}) * 12`
    } as any

    cell.fill = fillDarkBlue
    cell.border = thinBorder
    cell.font = boldLightFont
  })

  const currentRowCount = revenueSheet.rowCount
  const summarySectionRowOffset = 2
  const summarySectionStart = currentRowCount + summarySectionRowOffset
  const headers = [
    `${revenueSheet.columns[1].header} Revenue Summary`,
    'Category Total',
    'Percent of REV'
  ]

  const summaryRowHeader = revenueSheet.getRow(summarySectionStart)

  headers.forEach((header, i) => {
    const cell = summaryRowHeader.getCell(i + 1)
    if (i === 0) {
      cell.alignment = { horizontal: 'right' }
    }
    cell.value = header
    cell.fill = fillDarkBlue
    cell.font = boldLightFont
    cell.border = thinBorder
  })

  Object.entries(revenueSummary.groups)
    .sort(([, a], [, b]) => sum(Object.values(a)) - sum(Object.values(b)))
    .map(([key, value]) => ({
      'Revenue Type': key,
      ...value
    }))
    .forEach((item) => {
      const row = revenueSheet.addRow(item)
      row.eachCell((cell, i) => {
        if (i === 1) {
          cell.fill = fillWhite
          cell.font = boldDarkFont
          cell.border = thinBorder
          cell.alignment = { horizontal: 'right' }
        } else if (i < 4) {
          cell.fill = fillLightBlue
          cell.border = thinBorder
          cell.font = normalDarkFont
          if (i === 3) {
            cell.value = {
              formula: `${parseFloat(
                String(row.getCell(i - 1)?.value)
              )}/sum(B2:B${revenueSummary.assetTypes.length + 1})`
            } as any
            cell.numFmt = '0.00%'
          }
        } else {
          cell.value = ''
        }
      })
    })
}

const addMonthlySheet = (
  workbook: Workbook,
  households: IPagedOdataApiResult<IHousehold>[]
) => {
  const householdMonthlySheet = workbook.addWorksheet('Monthly T12 Rev By HH', {
    views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }]
  })

  const sortedDates = households
    .flatMap(({ result }) => result?.value)
    .filter(isNotNullOrUndefined)
    .filter((x) => x.revenueDetHistory && x.revenueDetHistory.length > 0)
    .flatMap((x) => x.revenueDetHistory)
    .map(
      (x) =>
        x?.revYear != null &&
        x?.revMonth != null &&
        new Date(x.revYear, x.revMonth - 1, 1).getTime()
    )
    .filter(isNotNullOrFalse)
    .sort((a, b) => a - b)

  const now = new Date()
  const minDate = sortedDates?.[0] || now
  const maxDate = sortedDates.at(-1) || now

  const totalMonths = differenceInMonths(maxDate, minDate) + 1
  const dates = range(0, totalMonths).map((x) => subMonths(maxDate, x))
  const houseHoldData = households
    .flatMap(({ result }) => result?.value)
    .filter(isNotNullOrUndefined)
    .filter((x) => x.revenueDetHistory && x.revenueDetHistory.length > 0)
    .map(({ householdName, revenueDetHistory }) => {
      const resultsWithDates = revenueDetHistory?.map((x) => ({
        date: new Date(x.revYear || 0, (x.revMonth && x.revMonth - 1) || 0, 1),
        revenue: x.revenueDet?.compRevenue
      }))
      const groupedByMonth = groupBy(
        resultsWithDates,
        (x) => `${x.date.getMonth()}-${x.date.getFullYear()}`
      )
      const revenueByMonth = Object.entries(groupedByMonth).map(
        ([, items]) => ({
          date: items[0].date,
          revenue: sumBy(items, (x) => x.revenue || 0)
        })
      )
      const t12ByDate = dates.map((t12Date) => ({
        date: t12Date,
        t12: sumBy(
          revenueByMonth.filter(
            (x) => x.date <= t12Date && x.date >= subMonths(t12Date, 11)
          ),
          (x) => x.revenue
        )
      }))

      return {
        t12ByDate,
        householdName
      }
    })

  householdMonthlySheet.columns = [
    { header: 'Household', key: 'Household', width: 25 },
    ...dates.map((x) => ({
      header: format(x, `MMM ''yy`),
      key: x?.toString(),
      width: 15,
      style: { numFmt: accountingFormat }
    }))
  ]

  householdMonthlySheet.autoFilter = {
    from: {
      row: 1,
      column: 1
    },
    to: {
      row: 1,
      column: householdMonthlySheet.columns.length
    }
  }
  householdMonthlySheet.getRow(1).eachCell((cell) => {
    cell.fill = fillDarkBlue
    cell.font = boldLightFont
    cell.border = thinBorder
  })

  houseHoldData.forEach((item) => {
    const row = householdMonthlySheet.addRow(['', ...dates.map(() => 0)])
    row.eachCell((cell, i) => {
      cell.border = thinBorder
      cell.font = normalDarkFont
      if (i === 1) {
        cell.value = item.householdName
        return
      }
      cell.value = item.t12ByDate[i - 2]?.t12 || 0
    })
  })
}

const addHouseholdSheet = (
  workbook: Workbook,
  households: IPagedOdataApiResult<IHousehold>[]
) => {
  const householdSheet = workbook.addWorksheet('T12 Revenue by HH', {
    views: [{ state: 'frozen', xSplit: 2, ySplit: 2 }]
  })

  const t12Buckets: {
    name: string
    range: [number, number | undefined]
    rangeId: number
    fill: Fill
  }[] = [
    {
      name: '$75,000 +',
      rangeId: 0,
      range: [75000, undefined],
      fill: fillLightGreen
    },
    {
      name: '$0-74,999',
      rangeId: 1,
      range: [0, 74999],
      fill: fillLightBlue
    }
  ]

  const t12BucketLookupByName = keyBy(t12Buckets, ({ name }) => name)
  const householdData = households
    .flatMap(({ result }) => result?.value)
    .filter(isNotNullOrUndefined)
    .map(({ householdId, householdName, revenue, householdKPI }) => {
      const t12Revenue = revenue?.ttmrevenue || 0
      const { name: bucket } =
        find(t12Buckets, ({ range: [min, max] }) =>
          [t12Revenue >= min, max === undefined || t12Revenue <= max].every(
            Boolean
          )
        ) || {}
      return {
        'Household Id': householdId || '',
        'Household Name': householdName || '',
        'T12 Revenue': revenue?.ttmrevenue || 0,
        'T12 Revenue Bucket': bucket,
        'Assets Under Supervision': householdKPI?.AumTotal || 0,
        'Managed Assets': householdKPI?.AumManaged || 0,
        'Brokerage Assets': householdKPI?.AumBrokerage || 0,
        'Loans Outstanding': householdKPI?.LoanOutstanding || 0,
        'Annuities & Insurance': householdKPI?.annuity || 0,
        'QTD Revenue': revenue?.QTDcompRevenue || 0,
        'QTD Revenue Annualized': ''
      }
    })
    .sort((a, b) => b['T12 Revenue'] - a['T12 Revenue'])

  const [firstHousehold] = householdData

  householdSheet.columns = [
    ...Object.keys(firstHousehold).map((name) => ({
      header: name,
      key: name,
      width: 18,
      style: { numFmt: accountingFormat }
    }))
  ]

  householdSheet.getColumn('Household Id').hidden = true
  householdSheet.getColumn('Household Name').width = 30
  householdSheet.autoFilter = {
    from: {
      row: 2,
      column: 1
    },
    to: {
      row: 2,
      column: householdSheet.columns.length
    }
  }
  householdSheet.getRow(1).eachCell((cell) => {
    cell.fill = fillDarkBlue
    cell.font = boldLightFont
    cell.border = thinBorder
  })

  const householdTotalRow = householdSheet.insertRow(
    1,
    Object.keys(firstHousehold)
  )

  householdTotalRow.eachCell((cell, i) => {
    const columnKey = householdSheet.getColumn(cell.col).key || ''
    cell.names = ['total', lowerSnakeCase(`total ${columnKey}`)]
    if (i < 3) {
      cell.numFmt = ''
      cell.value = 'Total'
      cell.font = boldDarkFont
      cell.border = totalBorder
      cell.fill = fillWhite
      return
    }

    cell.value = {
      formula: `sum(${lowerSnakeCase(columnKey)})`
    } as any

    cell.border = totalBorder
    cell.fill = fillWhite
    cell.font = boldDarkFont
  })

  householdData.forEach((item) => {
    const row = householdSheet.addRow(item)
    row.eachCell((cell, i) => {
      cell.fill = i < 3 ? fillWhite : fillLightBlue
      cell.border = thinBorder
      cell.font = normalDarkFont
      cell.name = lowerSnakeCase(householdSheet.getColumn(i)?.key || '')
    })

    const t12Revenue = row.getCell('T12 Revenue')
    t12Revenue.names = [
      ...t12Revenue.names,
      `t12bucket_${
        t12BucketLookupByName[item['T12 Revenue Bucket'] || '']?.rangeId
      }`
    ]
    const qtdRevenue = row.getCell('QTD Revenue')
    const annualized = row.getCell('QTD Revenue Annualized')

    annualized.value = {
      formula: `${qtdRevenue.$col$row} * 4`
    } as any
  })

  const lastRow = householdSheet.lastRow
  t12Buckets.forEach(({ name, fill }) => {
    householdSheet.addConditionalFormatting({
      ref: `$C3:$K${lastRow?.number}`,
      rules: [
        {
          priority: 1,
          type: 'expression',
          formulae: [`$D3="${name}"`],
          style: { fill }
        }
      ]
    })
  })
  const currentColumnCount = householdSheet.columnCount
  const summarySectionColumnOffset = 2
  const summarySectionRowOffset = 3
  const summaryColumnHeaders = [
    'T12 Bucket',
    'Portion of Revenue',
    'Percent of Revenue'
  ]

  summaryColumnHeaders.forEach((header, i) => {
    const cell = householdSheet
      .getRow(summarySectionRowOffset)
      .getCell(currentColumnCount + summarySectionColumnOffset + i)
    cell.value = header
    cell.style = { fill: fillWhite, font: boldDarkFont, border: thinBorder }
    const column = householdSheet.getColumn(cell.col)
    column.key = header
    column.width = 18
  })

  t12Buckets.forEach(({ name, rangeId, fill }, i) => {
    const row = householdSheet.getRow(summarySectionRowOffset + 1 + i)
    const items = [
      {
        value: name,
        font: boldDarkFont
      },
      {
        value: {
          formula: `sum(t12bucket_${rangeId})`
        },
        name: `t12bucket_total_${rangeId}`,
        numFmt: accountingFormat
      },
      {
        value: {
          formula: `t12bucket_total_${rangeId} / ${lowerSnakeCase(
            'total T12 Revenue'
          )}`
        },
        numFmt: '0%'
      }
    ]

    items.forEach(({ value, name, numFmt, font }, i) => {
      const cell = row.getCell(
        currentColumnCount + summarySectionColumnOffset + i
      )
      cell.value = value as any
      cell.style = { numFmt, fill, border: thinBorder, font }
      cell.name = name as any
    })
  })
}

const addAssetsSheet = (workbook: Workbook, advisor: IAdvisorDetailResult) => {
  const assetsSheet = workbook.addWorksheet(
    `${advisor.ClientAdvisorID} - ${advisor.ClientAdvisor}`.replace(
      /[\W_]+/g,
      ' '
    ),
    {
      views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }]
    }
  )

  const { ClientAdvisorID } = advisor

  const history = (advisor.AdvisorKPI?.ausHistory || []).sort(
    (a, b) =>
      new Date(b?.DateAsOf || '').getTime() -
      new Date(a?.DateAsOf || '').getTime()
  )

  assetsSheet.columns = [
    { header: 'AUS Category', key: 'AUS Category', width: 30 },
    ...history.map(({ DateAsOf }) => ({
      header: DateAsOf,
      key: DateAsOf,
      width: 15,
      style: { numFmt: accountingFormat }
    }))
  ]

  assetsSheet.autoFilter = {
    from: {
      row: 1,
      column: 1
    },
    to: {
      row: 1,
      column: assetsSheet.columns.length
    }
  }

  const firstColumn = assetsSheet.getColumn(1)
  firstColumn.values = [
    'AUS Category',
    'Loans Outstanding',
    'Loans Outstanding Outside Nfs',
    'Brokerage Assets',
    'Managed Assets',
    'Assets Held Away',
    'Annuities & Insurance',
    'Assets Under Supervision',
    '',
    'Net New Money',
    'Net New Assets'
  ]
  firstColumn.eachCell((cell) => {
    cell.alignment = { horizontal: 'right' }
    cell.font = boldDarkFont
  })

  history.forEach(
    (
      {
        AuSupervision = 0,
        AumBrokerage = 0,
        AumManaged = 0,
        HeldAway = 0,
        LoanOutstanding = 0,
        LoanOutstandingOutsideNfs = 0,
        NetNewMoney = 0,
        TOA = 0,
        annuity = 0,
        DateAsOf = ''
      },
      i
    ) => {
      const col = assetsSheet.getColumn(i + 2)
      col.values = [
        format(new Date(DateAsOf || ''), `MMM ''yy`),
        LoanOutstanding,
        LoanOutstandingOutsideNfs,
        AumBrokerage,
        AumManaged,
        HeldAway,
        annuity,
        AuSupervision,
        '',
        NetNewMoney,
        TOA
      ]
      col.eachCell((cell) => {
        cell.fill = fillLightBlue
        cell.border = thinBorder
        cell.font = normalDarkFont
        cell.numFmt = accountingFormat
      })
    }
  )

  assetsSheet.getRow(1).eachCell((cell) => {
    cell.fill = fillDarkBlue
    cell.font = boldLightFont
    cell.border = thinBorder
  })

  assetsSheet.getRow(8).eachCell((cell, i) => {
    if (i <= 1) {
      cell.border = totalBorder
      return
    }
    cell.fill = fillWhite
    cell.font = boldDarkFont
    cell.border = totalBorder
  })

  assetsSheet.getRows(9, 3)?.forEach((row) =>
    row.eachCell((cell, i) => {
      if (i <= 1) {
        return
      }
      cell.fill = fillWhite
      cell.font = normalDarkFont
      cell.border = thinBorder
    })
  )

  const [currentMonthHistory] = history

  if (currentMonthHistory) {
    const currentRowCount = assetsSheet.rowCount
    const summarySectionRowOffset = 2
    const summarySectionStart = currentRowCount + summarySectionRowOffset
    const headers = [
      `${format(
        new Date(currentMonthHistory.DateAsOf || ''),
        `MMM ''yy`
      )} AUS Summary`,
      'Category Total',
      'Percent of AUS'
    ]

    const summaryRowHeader = assetsSheet.getRow(summarySectionStart)

    headers.forEach((header, i) => {
      const cell = summaryRowHeader.getCell(i + 1)
      if (i === 0) {
        cell.alignment = { horizontal: 'right' }
      }
      cell.value = header
      cell.fill = fillDarkBlue
      cell.font = boldLightFont
      cell.border = thinBorder
    })

    const summaryItems = [
      ['Loans Outstanding', 'LoanOutstanding'],
      ['Loans Outstanding Outside Nfs', 'LoanOutstandingOutsideNfs'],
      ['Brokerage Assets', 'AumBrokerage'],
      ['Managed Assets', 'AumManaged'],
      ['Assets Held Away', 'HeldAway'],
      ['Annuities & Insurance', 'annuity'],
      ['Assets Under Supervision', 'AuSupervision']
    ]

    const rows: Partial<Cell>[][] = summaryItems.map(([title, key]) => [
      {
        value: title,
        style: { alignment: { horizontal: 'right' }, font: boldDarkFont }
      },
      {
        value: currentMonthHistory[key as keyof IAUSHistoryItem] || 0,
        name: `a_${ClientAdvisorID}_${key}`,
        style: {
          fill: fillLightBlue,
          border: thinBorder,
          font: normalDarkFont,
          numFmt: accountingFormat
        }
      },
      {
        value: {
          formula: `a_${ClientAdvisorID}_${key} / a_${ClientAdvisorID}_AuSupervision`
        } as any,
        style: {
          numFmt: '0%',
          fill: fillLightBlue,
          border: thinBorder,
          font: normalDarkFont
        }
      }
    ])

    rows.forEach((dataRow, i) => {
      const row = assetsSheet.getRow(summarySectionStart + i + 1)
      dataRow.forEach((dataCell, i) => {
        const cell = row.getCell(i + 1)
        cell.value = dataCell.value
        cell.name = dataCell.name as any
        cell.style = dataCell.style as any
      })
    })
  }
}
