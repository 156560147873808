import { selectAccountRepsWithSplitVisibility } from 'features/Domain/store/domain'
import { flow, intersectionWith, keyBy, orderBy, uniqBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  isNotNullOrEmpty,
  isNotNullOrUndefined
} from '../../../../../shared/gaurds'
import { AppState } from '../../../../../store'
import { IAdvisoryAccountRep, mapToContextBusinessUnit } from './service'

const SET_SELECTED =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@accountRepTree/SET_SELECTED'
const SET_COLLAPSED =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@accountRepTree/SET_COLLAPSED'
const SET_FILTER =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@accountRepTree/SET_FILTER'
const TOGGLE_SELECTED_ONLY =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@accountRepTree/TOGGLE_SELECTED_ONLY'
const RESET =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@accountRepTree/RESET'

export const accountRepTreeActions = {
  setSelected: createAction(SET_SELECTED)<string[] | undefined>(),
  setCollapsed: createAction(SET_COLLAPSED)<string[] | undefined>(),
  setFilter: createAction(SET_FILTER)<string | undefined>(),
  toggleSelectedOnly: createAction(TOGGLE_SELECTED_ONLY)(),
  reset: createAction(RESET)()
}

export interface IAccountRepTreeState {
  selected?: string[]
  collapsed?: string[]
  filter?: string
  showSelectedOnly?: boolean
}

const initialState: IAccountRepTreeState = {}

export const accountRepTreeReducer = createReducer<
  IAccountRepTreeState,
  ActionType<typeof accountRepTreeActions>
>(initialState)
  .handleAction(accountRepTreeActions.setSelected, (state, action) => ({
    ...state,
    selected: action.payload
  }))
  .handleAction(accountRepTreeActions.setCollapsed, (state, action) => ({
    ...state,
    collapsed: action.payload
  }))
  .handleAction(accountRepTreeActions.setFilter, (state, action) => ({
    ...state,
    filter: action.payload
  }))
  .handleAction(accountRepTreeActions.toggleSelectedOnly, (state) => ({
    ...state,
    showSelectedOnly: !state.showSelectedOnly
  }))
  .handleAction(accountRepTreeActions.reset, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.features.domain.features.repSelector.accountRepTree

const getAccountRepTreeSelectedReps = flow(
  rootSelector,
  ({ selected }) => selected
)

const getAccountRepTreeCollapsedNodes = flow(
  rootSelector,
  ({ collapsed }) => collapsed
)

const getAccountRepTreeFilter = flow(rootSelector, ({ filter }) => filter)

const getAccountRepTreeShowSelectedOnly = flow(
  rootSelector,
  ({ showSelectedOnly }) => showSelectedOnly
)

const getAdvisoryAccountReps = createSelector(
  [selectAccountRepsWithSplitVisibility],
  (domain) => {
    return domain?.map(
      ({ rep, ...rest }): IAdvisoryAccountRep => ({
        id: rep.rcm_repid,
        name: rep.rcm_name,
        team: mapToContextBusinessUnit(rep.rcm_OwningTeam?.businessunitid),
        office: mapToContextBusinessUnit(
          rep.rcm_OwningTeam?.businessunitid?.parentbusinessunitid
        ),
        division: mapToContextBusinessUnit(
          rep.rcm_OwningTeam?.businessunitid?.parentbusinessunitid
            ?.parentbusinessunitid
        ),
        ...rest
      })
    )
  }
)

const getOrderedAdvisoryAccountReps = createSelector(
  [getAdvisoryAccountReps],
  (reps) => orderBy(reps, ({ id }) => id)
)

const getAccountRepTreeFilteredReps = createSelector(
  [
    getAccountRepTreeFilter,
    getOrderedAdvisoryAccountReps,
    getAccountRepTreeSelectedReps,
    getAccountRepTreeShowSelectedOnly
  ],
  (searchText, orderedContextReps, selectedReps, showSelectedOnly) => {
    const reps = showSelectedOnly
      ? intersectionWith(
          orderedContextReps,
          selectedReps || [],
          (a, b) => a.id === b
        )
      : orderedContextReps
    const lowerCaseSearchText = searchText?.toLowerCase()
    return lowerCaseSearchText
      ? reps.filter(({ id, name, team, office, division, splits }) =>
          [
            id,
            name,
            team?.name,
            office?.name,
            division?.name,
            ...(splits?.map(({ id }) => id) || [])
          ]
            .filter(isNotNullOrEmpty)
            .some((x) => x.toLowerCase().indexOf(lowerCaseSearchText) >= 0)
        )
      : reps
  }
)

const getAccountRepTreeFilteredBusinessUnits = createSelector(
  [getAccountRepTreeFilteredReps],
  (filteredContextReps) => {
    const filteredBus = orderBy(
      uniqBy(
        filteredContextReps?.flatMap(({ team, office, division }) =>
          [team, office, division].filter(isNotNullOrUndefined)
        ),
        ({ id }) => id
      ),
      ({ name }) => name
    )
    return filteredBus
  }
)

export const getAccountRepTreeFilteredSelectedReps = createSelector(
  [getAccountRepTreeFilteredReps, getAccountRepTreeSelectedReps],
  (filtered, selected) =>
    intersectionWith(filtered, selected || [], (a, b) => a.id === b)
)

export const getUserHasAccessToAllAccountRepTreeSelectedAccountRepSplits =
  createSelector(
    [
      getAccountRepTreeFilteredSelectedReps,
      selectAccountRepsWithSplitVisibility
    ],
    (selected, splits) => {
      const selectedRepSplitVisibility = intersectionWith(
        splits,
        selected || [],
        (a, b) => a.rep.rcm_repid === b.id
      )

      return selectedRepSplitVisibility.every(
        ({ userHasAccessToSplits }) => userHasAccessToSplits
      )
    }
  )

export const useAccountRepTreeStore = () => {
  const selected = useSelector(getAccountRepTreeSelectedReps)
  const collapsed = useSelector(getAccountRepTreeCollapsedNodes)
  const filter = useSelector(getAccountRepTreeFilter)
  const filteredBusinessUnits = useSelector(
    getAccountRepTreeFilteredBusinessUnits
  )
  const filteredAccountReps = useSelector(getAccountRepTreeFilteredReps)

  const dispatch = useDispatch()

  const updateSelected = useCallback(
    (selected?: string[]) => {
      dispatch(accountRepTreeActions.setSelected(selected))
    },
    [dispatch]
  )

  const updateCollapsed = useCallback(
    (collapsed?: string[]) => {
      dispatch(accountRepTreeActions.setCollapsed(collapsed))
    },
    [dispatch]
  )

  const updateFilter = useCallback(
    (text?: string) => {
      dispatch(accountRepTreeActions.setFilter(text))
    },
    [dispatch]
  )

  const selectAllVisible = useCallback(
    () => updateSelected(filteredAccountReps.map(({ id }) => id)),
    [filteredAccountReps, updateSelected]
  )

  const clearAllSelected = useCallback(
    () => updateSelected([]),
    [updateSelected]
  )

  const collapseAllVisible = useCallback(
    () => updateCollapsed(filteredBusinessUnits?.map(({ id }) => id)),
    [filteredBusinessUnits, updateCollapsed]
  )
  const expandAll = useCallback(() => updateCollapsed([]), [updateCollapsed])

  const selectedLookup = useMemo(() => keyBy(selected), [selected])

  const showSelectedOnly = useSelector(getAccountRepTreeShowSelectedOnly)
  const toggleShowSelectedOnly = useCallback(() => {
    dispatch(accountRepTreeActions.toggleSelectedOnly())
  }, [dispatch])

  return {
    selected,
    selectedLookup,
    updateSelected,
    selectAllVisible,
    clearAllSelected,
    collapsed,
    updateCollapsed,
    collapseAllVisible,
    expandAll,
    filter,
    updateFilter,
    filteredBusinessUnits,
    filteredAccountReps,
    showSelectedOnly,
    toggleShowSelectedOnly
  }
}
