import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccount } from 'api/account.types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty, isNotNullOrFalse } from 'shared/gaurds'
import { AppState, useSelector } from 'store/shared'
import { useLazyGetAccountsFromHouseholdIdQuery } from '../datahub'
import {
  useRdot360HouseholdAccountsApiContext,
  useRdot360SelectedAccountsApiContext
} from './apiContext'

export interface IRdot360UiContextState {
  selectedHouseholdId?: string
  selectedAccounts: string[]
  includeClosedAccounts: boolean
}

export interface ISetSelectedHouseholdPayload {
  householdId: string
  selectionType?: 'account' | 'client' | 'household'
  selectionIds?: string[]
}

const initialState: IRdot360UiContextState = {
  selectedAccounts: [],
  includeClosedAccounts: false
}

const uiContextSlice = createSlice({
  name: '@features/@rdot360/@contextSlice',
  initialState,
  reducers: {
    setSelectedHouseholdId: (state, action: PayloadAction<string>) => {
      state.selectedHouseholdId = action.payload
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedAccounts = action.payload
    },
    setIncludeClosedAccounts: (state, action: PayloadAction<boolean>) => {
      state.includeClosedAccounts = action.payload
    }
  }
})

export const { reducer: rdot360ContextReducer } = uiContextSlice
const { actions } = uiContextSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.context

const getSelectedHouseholdId = createSelector(
  rootSelector,
  (x) => x.selectedHouseholdId
)
const getUiSelectedAccounts = createSelector(
  rootSelector,
  (x) => x.selectedAccounts
)
const getIncludeClosedAccounts = createSelector(
  rootSelector,
  (x) => x.includeClosedAccounts
)

const mapToApiContextAccounts = (accounts: IAccount[]) =>
  accounts
    .map(
      (x) =>
        !!x.id &&
        !!x.ClientAdvisorID && {
          number: x.id,
          repcode: x.ClientAdvisorID,
          custodian: x.CustodianName || 'nfs'
        }
    )
    .filter(isNotNullOrFalse)

export const useRdot360Context = () => {
  const dispatch = useDispatch()
  const includeClosedAccounts = useSelector(getIncludeClosedAccounts)
  const [fetchHouseholdAccounts] = useLazyGetAccountsFromHouseholdIdQuery()
  const { setContext: setApiContextSelectedAccounts } =
    useRdot360SelectedAccountsApiContext()

  const { setContext: setApiContextHouseholdAccounts } =
    useRdot360HouseholdAccountsApiContext()

  const setSelectedAccounts = useCallback(
    async (accounts: IAccount[]) => {
      const filteredAccounts = accounts.filter(
        (x) => includeClosedAccounts || x.accountStatus === 'Open'
      )
      const accountIds = filteredAccounts
        .map((x) => x.id)
        .filter(isNotNullOrEmpty)
      dispatch(actions.setSelectedAccounts(accountIds))
      await setApiContextSelectedAccounts(
        mapToApiContextAccounts(filteredAccounts)
      )
    },
    [dispatch, includeClosedAccounts, setApiContextSelectedAccounts]
  )

  const setHousehold = useCallback(
    async ({
      householdId,
      selectionType,
      selectionIds
    }: ISetSelectedHouseholdPayload) => {
      dispatch(actions.setSelectedHouseholdId(householdId))

      const { data } = await fetchHouseholdAccounts(householdId, true)
      const initialAccounts =
        data?.filter((x) => {
          if (
            !selectionType ||
            !selectionIds?.length ||
            selectionType === 'household'
          ) {
            return true
          }

          if (selectionType === 'client') {
            return x.LegalEntityID && selectionIds?.includes(x.LegalEntityID)
          }

          if (selectionType === 'account') {
            return x.id && selectionIds?.includes(x.id)
          }
        }) || []

      await Promise.all([
        setSelectedAccounts(initialAccounts),
        setApiContextHouseholdAccounts(mapToApiContextAccounts(data || []))
      ])
    },
    [
      dispatch,
      fetchHouseholdAccounts,
      setApiContextHouseholdAccounts,
      setSelectedAccounts
    ]
  )

  const setIncludeClosedAccounts = useCallback(
    (newIncludeClosedAccounts: boolean) => {
      dispatch(actions.setIncludeClosedAccounts(newIncludeClosedAccounts))
    },
    [dispatch]
  )

  const selectedHouseholdId = useSelector(getSelectedHouseholdId)
  const selectedAccounts = useSelector(getUiSelectedAccounts)

  return {
    setHousehold,
    selectedHouseholdId,
    setSelectedAccounts,
    selectedAccountIds: selectedAccounts,
    setIncludeClosedAccounts,
    includeClosedAccounts
  }
}
