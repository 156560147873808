import { useTheme } from '@emotion/react'
import { Callout } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { format } from 'date-fns'
import { sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Dropdown,
  IndeterminateProgressIndicator,
  ISelectableOption
} from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { Icon } from '../../features/Icons/Icon'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import {
  useFinancialsApiUtil,
  useGetDetailActivitySummaryQuery
} from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import { useRdot360AccountContext } from '../../store/rdot360Context/useRdot360AccountContext'
import ActivityDetailsTable from './ActivityDetailsTable/ActivityDetailsTable'
import { useActivityDetailUiState } from './activityDetailsUiState'

const activityDropdownOptions: ISelectableOption[] = [
  { key: 'BY', text: 'Buys' },
  { key: 'SL', text: 'Sells' },
  { key: 'DV', text: 'Dividends' },
  { key: 'IN', text: 'Interest' },
  { key: 'EX', text: 'Exchanges/Transfers' },
  { key: 'JN', text: 'Journal' },
  { key: 'FE', text: 'Fees' },
  { key: 'DP', text: 'Deposits' },
  { key: 'WD', text: 'Withdrawals' },
  { key: 'CA', text: 'Corporate Actions' },
  { key: 'ZZ', text: 'Others' }
]
export type IActivityDurationOptions =
  | 'Last 10 Days'
  | 'Last 30 Days'
  | 'Last 90 Days'
  | 'Year to Date'
  | 'Previous Year'
  | 'Custom Range'

type ActivitySelectableOption = { key: IActivityDurationOptions; text: string }
const durationDropdownOptions: ActivitySelectableOption[] = [
  { key: 'Last 10 Days', text: 'Last 10 Days' },
  { key: 'Last 30 Days', text: 'Last 30 Days' },
  { key: 'Last 90 Days', text: 'Last 90 Days' },
  { key: 'Year to Date', text: 'Year to Date' },
  { key: 'Previous Year', text: 'Previous Year' },
  { key: 'Custom Range', text: 'Custom Range' }
]

const ActivityDetailView = () => {
  const { invalidateTags } = useFinancialsApiUtil()
  const {
    dateSelection,
    setDateSelection,
    setCategories,
    categories,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    setCustomRange,
    searchText,
    setSearchText,
    dateRangeString,
    isDownloading
  } = useActivityDetailUiState()
  useEffect(() => {
    if (!categories || categories?.length === 0) {
      setCategories(
        sortBy(activityDropdownOptions, (x) => x.key).map(
          (x) => x.key as string
        )
      )
    }
  }, [categories, setCategories])
  const onApplyCategories = useCallback(
    (categories: ISelectableOption[]) => {
      setCategories(
        sortBy(categories, (x) => x.key).map((x) => x.key as string)
      )
    },
    [setCategories]
  )
  const selectedDateRange = useMemo(
    () => durationDropdownOptions.find((x) => x.key === dateSelection),
    [dateSelection]
  )
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { accounts } = useRdot360AccountContext()
  const { data, isFetching, error, isUninitialized } =
    useGetDetailActivitySummaryQuery(
      {
        contextAccounts: apiContextAccounts,
        categories: categories,
        dateRange: dateRangeString
      },
      {
        skip:
          !apiContextAccounts || !apiContextAccounts.length || !dateRangeString
      }
    )
  const noData = useMemo(
    () => !data?.activities?.length,
    [data?.activities?.length]
  )

  const selectedCategoryOptions = useMemo(
    () =>
      activityDropdownOptions.filter((x) =>
        categories?.includes(x.key as string)
      ),
    [categories]
  )
  const theme = useTheme()
  return (
    <div css={{ height: '100%' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 20,
          alignItems: 'flex-end'
        }}
      >
        <DetailsNavigator />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3
          }}
        >
          <div css={{ fontSize: '14px', fontWeight: 500 }}>Activity Type</div>
          <div
            css={{
              fontSize: '14px',
              lineHeight: '1.5',
              width: '185px'
            }}
          >
            <ActivityTypeSelector
              options={activityDropdownOptions}
              initialSelectedOptions={selectedCategoryOptions}
              onApplySelection={onApplyCategories}
            />
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3
          }}
        >
          <div css={{ fontSize: '14px', fontWeight: 500 }}>Date Range</div>
          <div
            css={{
              fontSize: '14px',
              lineHeight: '1.5',
              width: '185px'
            }}
          >
            <Dropdown
              options={durationDropdownOptions}
              defaultSelectedOption={selectedDateRange}
              onChange={(_, option) => {
                setDateSelection(option?.key as IActivityDurationOptions)
              }}
            />
          </div>
        </div>

        {dateSelection === 'Custom Range' && (
          <div
            css={{
              display: 'flex',
              flexWrap: 'nowrap',
              columnGap: 10,
              flexGrow: 1
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>Start Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '125px'
                }}
              >
                <input
                  css={[inputClasses.input, { height: '48px' }]}
                  type="date"
                  value={startDate}
                  onChange={(ev) => {
                    setStartDate(ev.target.value)
                  }}
                  max={endDate ? endDate : format(new Date(), 'yyyy-MM-dd')}
                />
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>End Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '125px'
                }}
              >
                <input
                  css={[inputClasses.input, { height: '48px' }]}
                  value={endDate}
                  onChange={(ev) => {
                    setEndDate(ev.target.value)
                  }}
                  type="date"
                  min={startDate}
                  max={format(new Date(), 'yyyy-MM-dd')}
                />
              </div>
            </div>
            <button
              css={[
                buttonStyles.primary,
                { height: '48px', width: '80px', marginTop: 20 }
              ]}
              onClick={setCustomRange}
              disabled={!startDate || !endDate}
            >
              Apply
            </button>
          </div>
        )}
        <div
          css={{
            display: 'flex',
            flexWrap: 'nowrap',
            columnGap: 10,
            marginTop: 20,
            marginLeft: 'auto'
          }}
        >
          <Searchbox
            searchText={searchText}
            onChange={setSearchText}
            title="Find within Description, Symbol/Cusip and Amount"
          />

          <div
            css={{
              display: 'flex',
              height: '48px',
              alignItems: 'center'
            }}
          >
            <div>
              <Icon
                type="Refresh"
                width={24}
                height={24}
                onClick={() => invalidateTags(['activity'])}
                color={theme.colors.extraBlue2}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          padding: '5px',
          paddingBottom: '10px'
        }}
      >
        {`${apiContextAccounts?.length || 0} / ${
          accounts?.length || 0
        } Accounts Selected`}
      </div>
      <div
        css={{
          padding: '5px',
          display: 'flex',
          gridColumnGap: 5,
          paddingBottom: '10px'
        }}
      >
        <div> (*) - Indicates a Pending Activity</div>
      </div>
      {(isFetching || isDownloading) && (
        <div css={{ width: '100%' }}>
          <IndeterminateProgressIndicator />
        </div>
      )}
      <ActivityDetailsTable />
      {(error || noData) && !isUninitialized && !isFetching ? (
        <SnackBar
          type={error ? 'Failure' : 'Info'}
          message={
            error
              ? (error as Error)?.message || 'An unknown error occurred'
              : 'No data available'
          }
        />
      ) : null}
    </div>
  )
}

export default ActivityDetailView

const ActivityTypeSelector: React.FC<{
  options: ISelectableOption[]
  initialSelectedOptions: ISelectableOption[]
  onApplySelection: (selectedOptions: ISelectableOption[]) => void
}> = ({ options, initialSelectedOptions, onApplySelection }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  useMemo(() => {
    setSelectedOptions(initialSelectedOptions)
  }, [initialSelectedOptions])
  const onSelectAll = useCallback(() => {
    setSelectedOptions(options)
  }, [options])
  const onDeselectAll = useCallback(() => {
    setSelectedOptions([])
  }, [])
  const onDeselect = useCallback(
    (option: ISelectableOption) => {
      setSelectedOptions(selectedOptions.filter((x) => x.key !== option.key))
    },
    [selectedOptions]
  )
  const onSelect = useCallback(
    (option: ISelectableOption) => {
      setSelectedOptions(sortBy([...selectedOptions, option], (x) => x.key))
    },
    [selectedOptions]
  )
  const dropdownId = useId('Callout')
  const dropdownRef: any = useRef()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const onApply = useCallback(() => {
    onApplySelection(selectedOptions)
    setShowDropdown(false)
  }, [onApplySelection, selectedOptions])
  const onDismiss = useCallback(() => {
    setSelectedOptions(initialSelectedOptions)
    setShowDropdown(false)
  }, [initialSelectedOptions])
  return (
    <>
      <div
        tabIndex={0}
        ref={dropdownRef}
        id={dropdownId}
        css={(theme) => ({
          border: '1px solid #AFB1B6',
          borderRadius: 8,
          padding: '12px',
          color: '#000000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          background: theme.colors.primaryWhite
        })}
        onClick={() => setShowDropdown(true)}
      >
        <div
          css={{
            maxWidth: '75%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {selectedOptions.length === options.length
            ? 'All'
            : selectedOptions.length === 1
            ? selectedOptions?.[0]?.text
            : `${selectedOptions.length} Selected`}
        </div>
        <Icon type="ChevronDown" width={16} height={16} />
      </div>
      {showDropdown && (
        <Callout
          target={`#${dropdownId}`}
          css={(theme) => ({
            border: '1px solid #AFB1B64D',
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 4,
            background: theme.colors.primaryWhite,
            width: `${dropdownRef.current.offsetWidth}px`,
            padding: '8px'
          })}
          isBeakVisible={false}
          onDismiss={() => setShowDropdown(false)}
        >
          <div
            css={{
              padding: '4px 0px',
              width: '100%',
              display: 'flex',
              gridColumnGap: 8
            }}
          >
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={onSelectAll}
            >
              Select All
            </div>
            {' | '}
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={onDeselectAll}
            >
              Deselect All
            </div>
          </div>
          {options.map((x, key) => {
            const isSelected = selectedOptions.includes(x)
            return (
              <div
                key={key}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '5px',
                  width: '100%',
                  cursor: 'pointer',
                  ':hover': {
                    background: '#eaeaea'
                  }
                }}
                onClick={() => {
                  isSelected ? onDeselect(x) : onSelect(x)
                }}
              >
                <IndeterminateCheckbox
                  checked={isSelected}
                  label={''}
                  readOnly={true}
                />
                <div css={{ paddingLeft: '10px' }}>{x.text}</div>
              </div>
            )
          })}
          <div
            css={{
              display: 'flex',
              paddingTop: '8px',
              paddingBottom: '4px',
              justifyContent: 'end',
              gridColumnGap: 5
            }}
          >
            <button
              css={buttonStyles.primary}
              onClick={onApply}
              disabled={selectedOptions.length === 0}
            >
              Apply
            </button>
            <button css={buttonStyles.secondary} onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </Callout>
      )}
    </>
  )
}
