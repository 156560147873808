import { useTheme } from '@emotion/react'
import { TooltipHost } from '@fluentui/react'
import { Icon } from '../features/Icons/Icon'
export const TodaysChange: React.FC = () => {
  const theme = useTheme()
  return (
    <TooltipHost content="Detailed Information Coming Soon">
      <div css={{ marginRight: '5px', cursor: 'pointer' }}>
        <Icon
          type="Info"
          width={12}
          height={12}
          color={theme.colors.tertiaryBlue1}
        />
      </div>
    </TooltipHost>
  )
}
