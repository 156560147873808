import { takeEvery } from 'redux-saga/effects'
import { takeLatest, call, put, select } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../../../store/user/selectors'
import {
  fetchClientListService,
  fetchClientSettingDocService,
  fetchClientSettingService,
  updatePaymentAccountService,
  uploadLoaDocService
} from '../api/ClientSettingsService'
import {
  fetchClientListActions,
  fetchClientSettingsDocActions,
  fetchClientSettingsListActions,
  updatePaymentAccountActions,
  uploadLoaDocActions
} from './actions'
import {
  IClientSettings,
  IClientSettingsDocRes,
  IPaymentClient,
  IUploadLoaDocRequest
} from './types'

function* handleFetchClientSettings() {
  try {
    const email = yield* select(getRdotUsername)
    const options = yield* call(getRockefellerApiOptions)
    const data: IClientSettings[] = yield call(() =>
      fetchClientSettingService(email || '', options)
    )
    yield put(fetchClientSettingsListActions.success(data))
  } catch (e: any) {
    yield put(fetchClientSettingsListActions.failure(e))
  }
}

function* handleFetchClientSettingsDoc(
  action: ReturnType<typeof fetchClientSettingsDocActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IClientSettingsDocRes = yield call(() =>
      fetchClientSettingDocService(action.payload, options)
    )

    if (data?.status?.code === '0') {
      yield put(
        fetchClientSettingsDocActions.success(data?.paymentloadoc || '')
      )
    } else {
      yield put(
        fetchClientSettingsDocActions.failure(new Error(data?.status?.text))
      )
    }
  } catch (e: any) {
    yield put(fetchClientSettingsDocActions.failure(e))
  }
}

function* handleFetchClientList(
  action: ReturnType<typeof fetchClientListActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IPaymentClient[] = yield call(() =>
      fetchClientListService(action.payload, options)
    )
    yield put(fetchClientListActions.success(data))
  } catch (e: any) {
    yield put(fetchClientListActions.failure(e))
  }
}

function* handleUpdatePaymentAccount(
  action: ReturnType<typeof updatePaymentAccountActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => updatePaymentAccountService(action.payload, options))
    yield put(updatePaymentAccountActions.success())
    const email = yield* select(getRdotUsername)
    const data: IClientSettings[] = yield call(() =>
      fetchClientSettingService(email || '', options)
    )
    yield put(fetchClientSettingsListActions.success(data))
  } catch (e: any) {
    yield put(updatePaymentAccountActions.failure(e))
  }
}

function* handleUploadLoaDoc(
  action: ReturnType<typeof uploadLoaDocActions.request>
) {
  const { clientid, file, onUploadSuccess, onUploadFail, onUploadProgress } =
    action.payload as IUploadLoaDocRequest
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() =>
      uploadLoaDocService(file, clientid || 0, options, onUploadProgress)
    )

    onUploadSuccess(file)
  } catch (e) {
    onUploadFail(file)
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchClientSettingsListActions.request,
      handleFetchClientSettings
    ),
  () =>
    takeLatest(
      fetchClientSettingsDocActions.request,
      handleFetchClientSettingsDoc
    ),
  () => takeLatest(fetchClientListActions.request, handleFetchClientList),
  () =>
    takeLatest(updatePaymentAccountActions.request, handleUpdatePaymentAccount),
  () => takeEvery(uploadLoaDocActions.request, handleUploadLoaDoc)
]
