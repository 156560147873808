import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GroupingState, SortingState, Updater } from '@tanstack/react-table'
import { isFunction } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export const accountSelectorColumnNames = {
  accountNumber: 'accountNumber',
  accountRegistration: 'accountRegistration',
  custodianType: 'custodianType',
  value: 'value',
  changeValue: 'changeValue',
  legalEntityId: 'legalEntityId',
  taxable: 'taxable',
  ausClass: 'ausClass'
}

export type AccountSelectorColumnNames = keyof typeof accountSelectorColumnNames

export interface IAccountSelectorFeatureState {
  sorting: SortingState
  grouping: GroupingState
  selectedIds: string[]
}

const initialState = {
  sorting: [{ id: accountSelectorColumnNames.value, desc: true }],
  grouping: [accountSelectorColumnNames.custodianType],
  selectedIds: [],
  includeClosedAccounts: false
} as IAccountSelectorFeatureState

const accountSelectorFeatureSlice = createSlice({
  name: '@modules/@rdot360/@features/@accountSelector',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SortingState | undefined>) => ({
      ...state,
      sorting: action.payload || []
    }),
    setGrouping: (state, action: PayloadAction<GroupingState | undefined>) => ({
      ...state,
      grouping: action.payload || []
    }),
    setSelection: (state, action: PayloadAction<string[] | undefined>) => ({
      ...state,
      selectedIds: action.payload || []
    }),
    resetTableState: () => ({
      ...initialState
    })
  }
})

export const { reducer: accountSelectorFeatureReducer } =
  accountSelectorFeatureSlice
const { actions } = accountSelectorFeatureSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.accountSelector

export const useAccountSelectorTableStore = () => {
  const dispatch = useDispatch()
  const { sorting, grouping, selectedIds } = useSelector(rootSelector)

  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting || []) : updater
      dispatch(actions.setSorting(value))
    },
    [dispatch, sorting]
  )
  const setGrouping = useCallback(
    (updater: Updater<GroupingState>) => {
      const value = isFunction(updater) ? updater(grouping || []) : updater
      dispatch(actions.setGrouping(value))
    },
    [dispatch, grouping]
  )

  const setSelectedIds = useCallback(
    (newSelection?: string[]) => {
      dispatch(actions.setSelection(newSelection))
    },
    [dispatch]
  )

  const selectedIdsLookup = useMemo(
    () =>
      selectedIds.reduce(
        (a, x) => ({ ...a, [x]: true }),
        {} as Record<string, boolean>
      ),
    [selectedIds]
  )

  const resetTableState = useCallback(() => {
    dispatch(actions.resetTableState())
  }, [dispatch])

  return {
    sorting,
    setSorting,
    grouping,
    setGrouping,
    selectedIds,
    setSelectedIds,
    selectedIdsLookup,
    resetTableState
  }
}
