import { isDefined } from 'shared/gaurds'
import { call, put, select, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getActiveTrust } from '../../TrustInfo/store/selectors'
import { fetchTrusteesListSvc } from '../api/trusteesService'
import { trusteesDataActions } from './actions'

function* handleGetTrusteesList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeTrust = yield* select(getActiveTrust)
    const activityIds = activeTrust?.account_rcm_trustees
      ?.map((x) => x.activityid || undefined)
      ?.filter(isDefined)
    const trustees = yield* call(() =>
      fetchTrusteesListSvc(activityIds || [], options)
    )

    yield put(trusteesDataActions.success(trustees || []))
  } catch (e) {
    yield put(trusteesDataActions.failure(e as Error))
  }
}

export const sagas = [
  () => takeEvery(trusteesDataActions.request, handleGetTrusteesList)
]
